export const sessionFormat = (session) => {
  let time = parseInt(session / 1000);
  let hour = parseInt(time / 3600);
  let minutes = parseInt((time - hour * 3600) / 60);
  let seconds = parseInt(time - hour * 3600 - minutes * 60);

  return `${hour ? hour + ` hours${minutes || seconds ? ", " : ""}` : ""} 
    ${minutes ? minutes + ` minutes${seconds ? ", " : ""}` : ""} 
    ${seconds ? seconds + ` seconds` : ""}`;
};

export const kycDateFormat = (kycDate) => {
  // ISO 8601 date-time string
  const isoString = `${kycDate}`;

  // Create a Date object from the ISO string
  const date = new Date(isoString);

  // Extract individual components
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const milliseconds = date.getMilliseconds().toString().padStart(3, "0");

  // Format the date and time in a human-readable format
  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  //   console.log(formattedDateTime); // Output: 2024-06-03 12:14:44.559
  return formattedDateTime;
};
