import admins from "../auth/permissions";

export const sideMenuAccess = (profile, access) => {
  return !!admins?.[profile?.level]?.[profile?.type]?.Authorized?.[access];
};

export const componentAccess = (level, type,access, keys) => {
  let accessArray =
    admins?.[level]?.[type]?.Authorized?.[access];
  return !!(accessArray || [])?.includes(keys);
};
