import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import * as actionCreator from "../../../store/action/index";
import { Box, Button, Typography } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { DeleteOutline } from "@material-ui/icons";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import PermIdentityTwoToneIcon from "@material-ui/icons/PermIdentityTwoTone";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "80%",
    maxWidth: 350,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
  },
  subTitle: {
    fontSize: 11,
    fontWeight: 600,
  },
  dropBoxContainer: {
    width: "96%",
    backgroundColor: "#144C59",
    borderRadius: 5,
    padding: 8,
    marginTop: 16,
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
  },
  fileContainer: {
    width: "96%",
    marginTop: 8,
  },
  fileBox: {
    padding: 8,
    marginTop: 4,
    width: "100%",
    backgroundColor: "#303030",
    border: "1px solid #8F8D8D",
    borderRadius: 5,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  statusLeftContainer: {
    display: "flex",
    alignItems: "center",
  },
  userIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#474646",
    borderRadius: 50,
    padding: 6,
  },
  userDate: {
    marginLeft: 5,
  },
  headName: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    fontWeight: 400,
  },
  subDetail: {
    fontSize: 8,
    fontWeight: 400,
  },
  status: {
    fontSize: 8,
    fontWeight: 400,
    borderRadius: 50,
    padding: "2px 6px",
    backgroundColor: "#DAF3F9",
    color: "#359DB6",
  },
  statusContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

const ApprovalStatus = (props) => {
  const { approvalDetail } = props;
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>Application Status</Typography>
      <Box className={classes.fileContainer}>
        <Box className={classes.fileBox}>
          <Box className={classes.statusLeftContainer}>
            <Box className={classes.userIcon}>
              <PermIdentityTwoToneIcon />
            </Box>
            <Box className={classes.userDate}>
              <Typography className={classes.headName}>User ID</Typography>
              <Typography className={classes.subDetail}>
                {approvalDetail?.profile_id || "NA"}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.statusLeftContainer}>
            <Box>
              <Typography className={classes.headName}>Request Date</Typography>
              <Typography className={classes.subDetail}>
                {moment(approvalDetail?.created_at)
                  .utc()
                  .format("Do MMMM YYYY") || "NA"}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.statusContainer}>
            <Typography className={classes.headName}>Status</Typography>
            <Typography
              className={classes.status}
              style={{
                backgroundColor:
                  approvalDetail.status == "Rejected"
                    ? "#f2a5a5"
                    : approvalDetail.status == "Cancelled"
                    ? "#dbb797"
                    : "#DAF3F9",
                color:
                  approvalDetail.status == "Rejected"
                    ? "#ed1313"
                    : approvalDetail.status == "Cancelled"
                    ? "#f07313"
                    : "#359DB6",
              }}
            >
              {approvalDetail?.status || "NA"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    approvalDetail: adminReducer.approvalDetail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalStatus);
