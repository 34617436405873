import * as actionTypes from "./actionTypes";
import axios from "axios";

import { API } from "../../asset/api";
import { setWithExpiry, getWithExpiry } from "../../asset/utility";
import { notify, closeSnackbar } from "./notifyAction";

const setLoading = (data) => {
  return {
    type: actionTypes.SET_LOADING,
    payload: data,
  };
};

const setError = (data) => {
  return {
    type: actionTypes.SET_ERROR,
    payload: data,
  };
};

const setAdmin = (data) => {
  return {
    type: actionTypes.SET_ADMIN,
    payload: data,
  };
};

const setFlag = (data) => {
  return {
    type: actionTypes.SET_FLAG,
    payload: data,
  };
};

export const ResetErrors = () => {
  return (dispatch, getState) => {
    dispatch(
      setError({
        error: null,
      })
    );
  };
};

const setRegUser = (data) => {
  return {
    type: actionTypes.SET_REG_USER,
    payload: data,
  };
};

export const confirmEmailToken = ({ token }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
  return (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    dispatch(setLoading({ status: true }));
    const reqBody = {
      token: token,
    };
    API.post("/ConfirmToken", JSON.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, user, error } = res.data;
        if (status) {
          dispatch(notify(message, "success"));
          dispatch(setRegUser({ user: user }));
        } else {
          dispatch(notify(message, "error"));
        }
        if (error) dispatch(setError({ error: error }));
        dispatch(setLoading({ status: false }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(setLoading({ status: false }));
      });
  };
};

const register = (data) => {
  return {
    type: actionTypes.REGISTER,
    payload: data,
  };
};

export const RegisterAsync = (user) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    dispatch(setLoading({ status: true }));
    const reqBody = user;
    API.post("/Register", JSON.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error } = res.data;
        if (status) {
          dispatch(notify(message, "success"));
          dispatch(register({ registered: status }));
        } else {
          dispatch(notify(message, "error"));
        }
        if (error) dispatch(setError({ error: error }));
        dispatch(setLoading({ status: false }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(setLoading({ status: false }));
      });
  };
};

const logIn = (data) => {
  return {
    type: actionTypes.LOGIN,
    payload: data,
  };
};

export const LogInAsync = (user) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    dispatch(setLoading({ status: true }));
    const reqBody = user;
    API.post("/Login", JSON.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, token, user } = res.data;
        dispatch(logIn({ status: status }));
        if (status) {
          setWithExpiry("token", token, 6);
          dispatch(setAdmin({ user: user }));

          if (message.length) dispatch(notify(message, "info"));
        } else {
          dispatch(notify(message, "error"));
        }
        if (error) dispatch(setError({ error: error }));
        dispatch(setLoading({ status: false }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(setLoading({ status: false }));
      });
  };
};

const isLogIn = (data) => {
  return {
    type: actionTypes.IS_LOG_IN,
    payload: data,
  };
};

export const IsLogInAsync = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    const reqBody = {
      token: storedToken,
    };
    API.post("/IsLoggedIn", JSON.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, user } = res.data;
        dispatch(isLogIn({ status: status }));
        if (status) {
          dispatch(setAdmin({ user: user }));
          if (message.length) dispatch(notify(message, "info"));
        }
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(isLogIn({ status: false }));
        dispatch(setError({ error: err }));
      });
  };
};

const logout = () => {
  return {
    type: actionTypes.LOGOUT,
  };
};

export const OnLogout = () => {
  return (dispatch, getState) => {
    dispatch(setLoading({ status: true }));
    localStorage.removeItem("token");
    dispatch(setFlag({flag:false}))
    dispatch(closeSnackbar());
    dispatch(logout());
  };
};
