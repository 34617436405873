import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ThemeButton from "../../../components/common/ThemeButton";
import {
 Typography,
 Grid,
 Box,
 Container,
 Paper,
 TextField,
 Switch,
 FormControlLabel,
 FormControl,
 Radio,
 RadioGroup,
 Divider,
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab/";
import * as actionCreator from "../../../store/action/index";
import { connect } from "react-redux";
import AddressInput from "../../../components/layout/addressInput";
import Geocode from "react-geocode";
import moment from "moment";

const key = process.env.REACT_APP_GOOGLE_API_KEY;

Geocode.setApiKey(key);
Geocode.setLocationType("ROOFTOP");

const useStyles = makeStyles((theme) => ({
 formControl4: { width: "95%" },
 comapanyInfo: {
  // borderRadius: 10,
  // marginTop: 32,
  height: "auto",
  width: "100%",
  // boxShadow: localStorage.getItem("dark")
  //  ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
  //  : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
 },
 companyInfoContainer: {
  padding: "5px 5px 1px 5px",
 },
 companyForm: {
  marginBottom: 14,
 },
 content: {
  display: "flex",
  justifyContent: "space-between",
  margin: 0,
 },
 banking: {},
 bankingContainer: {},
 main_acordian: {
  boxShadow: "-4px -4px 10px rgba(58, 58, 58, 0.7)",
  borderRadius: 10,
 },
 accordian: {
  background: theme.palette.background.default,
  boxShadow: "-4px -4px 10px rgba(58, 58, 58, 0.7)",
  borderRadius: 10,
 },
 accordianContainer: {
  display: "flex",
  justifyContent: "center",
 },
 formField: {
  marginBottom: 48,
 },
 accordianDetailsRoot: {
  display: "block",
 },
 formFieldHeading: {
  color: "#B8B8B8",
  fontWeight: 600,
  display: "flex",
  justifyContent: "space-between",
  fontSize: 18,
  marginBottom: 32,
 },
 text: {
  display: "flex",
  justifyContent: "center",
  fontSize: 16,
  fontWeight: 600,
  // color: "#FFFFFF",
 },
 root: {
  color: "white",
  fontSize: 14,
  top: -3,
 },
 rootInput: {
  display: "flex",
  alignItems: "center",
  color: "white",
  height: "50px",
 },
 // notchedOutline: {
 //   borderColor: "#DCDCDC",
 // },
 input: {
  // padding: "10px 14px",
 },
 textInput: {
  width: "100%",
 },
 main_acc: {
  display: "flex",
  flexDirection: "column",
  height: "fit-content",
  width: "100%",
  flexWrap: "wrap",
 },
 row1: {
  display: "flex",
  justifyContent: "space-between",
 },
 row2: {
  marginTop: "2rem",
  display: "flex",
  justifyContent: "space-around",
  [theme.breakpoints.down(985)]: {
   display: "flex",
   flexDirection: "column",
   alignItems: "center",
  },
 },

 formControl: {
  width: "45%",
  [theme.breakpoints.down(985)]: {
   width: "95%",
   marginTop: "1rem",
  },
 },
 formControl1: {
  width: "45%",
  [theme.breakpoints.down(985)]: {
   width: "95%",
   marginTop: "1rem",
  },
 },
 formControl2: {
  width: "45%",
  [theme.breakpoints.down(985)]: {
   width: "95%",
   marginTop: "0.5rem",
  },
 },
 formControl3: {
  width: "45%",
  [theme.breakpoints.down(985)]: {
   width: "95%",
   marginTop: "0.5rem",
  },
 },

 row3: {
  marginTop: "2rem",
  display: "flex",
  justifyContent: "space-around",
  [theme.breakpoints.down(985)]: {
   display: "flex",
   flexDirection: "column",
   alignItems: "center",
  },
 },
 row4: {
  marginTop: "2rem",
  display: "flex",
  justifyContent: "space-around",
 },
 roundedTextField: {
  borderRadius: "10px", // Adjust the border radius as needed
 },
 rootSwitch: {
  width: 100,
  height: 48,
  padding: 8,
 },
 switchBase: {
  padding: 11,
  color: "#ff6a00",
 },
 thumb: {
  width: 26,
  height: 26,
  backgroundColor: "#fff",
 },
 track: {
  background: "#C49C39",
  opacity: "1 !important",
  borderRadius: 20,
  position: "relative",
  "&:before, &:after": {
   display: "inline-block",
   position: "absolute",
   top: "50%",
   width: "50%",
   transform: "translateY(-50%)",
   color: "#fff",
   textAlign: "center",
  },
  "&:before": {
   content: '"Online"',
   left: 4,
   opacity: 0,
  },
  "&:after": {
   content: '"Offline"',
   right: 4,
  },
 },
 checked: {
  "&$switchBase": {
   color: "#185a9d",
   transform: "translateX(107.5%)",
   "&:hover": {
    backgroundColor: "rgba(24,90,257,0.08)",
   },
  },
  "& $thumb": {
   backgroundColor: "#fff",
  },
  "& + $track": {
   background: "linear-gradient(to right, #5FB794, #185a9d)",
   "&:before": {
    opacity: 1,
   },
   "&:after": {
    opacity: 0,
   },
  },
 },
}));

const MenuProps = {
 PaperProps: {
  style: {
   backgroundColor: localStorage.getItem("dark") ? "#fff" : "black",
  },
 },
};

const BusinessActivity = (props) => {
 const {
  location,
  businessData,
  editData,
  getBusinessActivities,
  getPmActivity,
  createPmActivity,
  setOpenCreateActivityPage,
  handleBusinessClose,
  openCreateActivityPage,
  setOpenEditActivityPage,
  openEditActivityPage,
  updatePmActivity,
 } = props;

 const classes = useStyles();

 const [data, setData] = useState({
  businessId: businessData?.business_id,
  isOnline: false,
  url: "",
  title: "",
  description: "",
  skills: [],
  categories: [],
  scheduledAt: "",
  rewards: "",
  educationPoints: "",
  ageGroup: [],
  location: {
   country: "",
   state: "",
   city: "",
   pincode: "",
   address: "",
   coordinates: [],
  },
  openingDate: "",
  closingDate: "",
  isMultiple: false,
  threshold: "",
 });

 //  const skills = ["React", "Golang", "Python", "Javascript"];
 //  const categories = ["Category1", "Category2", "Category3"];
 const age = ["Age 0-6", "Age 6-11", "Age 11-16"];
 const [isOn, setIsOn] = useState(
  location === "Edit" ? editData?.isOnline : false
 );

 const [checked, setChecked] = useState(!editData?.isOnline ? true : false);
 const [onlineActivityLink, setOnlineActivityLink] = useState(
  location === "Edit" ? editData?.url : ""
 );
 const [country, setCountry] = useState(
  location && location === "Edit" ? editData?.location?.country : ""
 );

 const [state, setState] = useState(
  location && location === "Edit" ? editData?.location?.state : ""
 );

 const [city, setCity] = useState(
  location && location === "Edit" ? editData?.location?.city : ""
 );

 const [pincode, setPincode] = useState(
  location && location === "Edit" ? editData?.location?.pincode : ""
 );

 const [newAddressField, setNewAddressField] = useState(
  location && location === "Edit" ? editData?.location?.address : ""
 );

 const [activityTitle, setActivityTitle] = useState(
  location === "Edit" ? editData?.title : ""
 );
 const [selectedSkills, setSelectedSkills] = useState(
  location === "Edit" ? editData?.skills : []
 );
 const [duration, setDuration] = useState(
  location === "Edit" ? editData?.duration : ""
 );
 const [selectedDate, setSelectedDate] = useState("");
 const [selectedTime, setSelectedTime] = useState("00:00");
 const [selectedCategory, setSelectedCategory] = useState(
  location === "Edit" ? editData?.categories : []
 );

 const ageGrp = editData?.ageGroup?.map((ageGroup, key) => {
  if (ageGroup === 1) {
   return "Age 0-6";
  } else if (ageGroup === 2) {
   return "Age 6-11";
  } else return "Age 11-16";
 });

 const [selectedAgeGroup, setSelectedAgeGroup] = useState(
  location === "Edit" ? ageGrp : []
 );
 const [rewards, setRewards] = useState(
  location === "Edit" ? editData?.rewards : null
 );
 const [educationPoints, setEducationPoints] = useState(
  location === "Edit" ? editData?.educationPoints : null
 );
 const [description, setDescription] = useState(
  location === "Edit" ? editData?.description : ""
 );
 //  const [coordinates, setCoordinates] = useState(
 //   location === "Edit"
 //    ? {
 //       lat: editData?.location?.coordinates[0],
 //       lng: editData?.location?.coordinates[1],
 //      }
 //    : { lat: 0, lng: 0 }
 //  );

 //  const [reqCoordinates, setReqCoordinates] = useState([]);

 const [startDate, setStartDate] = useState(
  location === "Edit"
   ? editData?.openingDate
     ? moment(editData?.openingDate).format("YYYY-MM-DD")
     : ""
   : ""
 );
 const [endDate, setEndDate] = useState(
  location === "Edit"
   ? editData?.closingDate
     ? moment(editData?.closingDate).format("YYYY-MM-DD")
     : ""
   : ""
 );

 const [startTime, setStartTime] = useState(
  location === "Edit"
   ? editData?.openingDate
     ? moment(editData?.openingDate).format("HH:mm")
     : "00:00"
   : ""
 );

 const [endTime, setEndTime] = useState(
  location === "Edit"
   ? editData?.closingDate
     ? moment(editData?.closingDate).format("HH:mm")
     : "00:00"
   : ""
 );

 const [email, setEmail] = useState(location === "Edit" ? editData?.email : "");
 const [phoneNumber, setPhoneNumber] = useState(
  location === "Edit" ? editData?.phone : ""
 );
 const [isMultiple, setIsMultiple] = useState(
  location && location === "Edit" ? (editData?.threshold ? true : false) : true
 );
 const [threshold, setThreshold] = useState(
  location === "Edit" ? editData?.threshold : ""
 );
 const [errorObj, setErrorObj] = useState({});

 const today = new Date().toISOString().split("T")[0];

 //  const handleDataFromAddress = (newAddress) => {
 //   setData((prevData) => ({
 //    ...prevData,
 //    location: {
 //     ...prevData.location,
 //     ...newAddress,
 //    },
 //   }));
 //  };

 const handleSwitchChange = () => {
  setIsOn(!isOn);
 };

 const handleOnlineActivityLinkChange = (event) => {
  const { name, value } = event.target;
  if (isOn) {
   setOnlineActivityLink(value);
  } else {
   setOnlineActivityLink("");
  }
  if (event.target.value == "") {
   setErrorObj({ ...errorObj, website: "Please enter URL" });
  }

  let websiteRegEx = /^(ftp|http|https):\/\/[^ "]+$/;

  if (!websiteRegEx.test(value.trim()) && value) {
   setErrorObj({ ...errorObj, website: "Please enter a valid website URL" });
  } else {
   if (errorObj.hasOwnProperty("website")) {
    let newErrorObj = { ...errorObj };
    delete newErrorObj["website"];
    setErrorObj(newErrorObj);
   }
  }
 };

 const handleActivityTitleChange = (event) => {
  setActivityTitle(event.target.value);
  if (event.target.value == "") {
   setErrorObj({ ...errorObj, title: "Please enter title" });
  } else {
   if (errorObj.hasOwnProperty("title")) {
    let newErrorObj = { ...errorObj };
    delete newErrorObj["title"];
    setErrorObj(newErrorObj);
   }
  }
 };

 const handleSkillsChange = (event) => {
  const { value } = event.target;
  const skillsArray = value.trim()
   ? value.split(",").map((skill) => skill.trim())
   : [];

  setSelectedSkills(skillsArray);
  if (value == "") {
   setErrorObj({ ...errorObj, skill: "Please add some skill" });
  } else {
   if (errorObj.hasOwnProperty("skill")) {
    let newErrorObj = { ...errorObj };
    delete newErrorObj["skill"];
    setErrorObj(newErrorObj);
   }
  }
 };

 const handleDurationChange = (event) => {
  const newDuration = parseFloat(event.target.value);
  if (!isNaN(newDuration)) {
   setDuration(newDuration);
  } else {
   setDuration("");
  }
  if (event.target.value == "") {
   setErrorObj({ ...errorObj, duration: "Please enter duration" });
  } else {
   if (errorObj.hasOwnProperty("duration")) {
    let newErrorObj = { ...errorObj };
    delete newErrorObj["duration"];
    setErrorObj(newErrorObj);
   }
  }
 };

 const handleEmail = (event) => {
  const { name, value } = event.target;
  setEmail(value);

  if (value == "") {
   setErrorObj({ ...errorObj, email: "Please enter email" });
  }

  let emailRegEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  if (!emailRegEX.test(value.trim()) && value) {
   setErrorObj({ ...errorObj, email: "please enter a valid email address" });
  } else {
   if (errorObj.hasOwnProperty("email")) {
    delete errorObj["email"];
   }
  }
 };

 const handlePhone = (event) => {
  const { name, value } = event.target;
  setPhoneNumber(value);
  if (event.target.value == "") {
   setErrorObj({ ...errorObj, phone: "Please enter phone number" });
  } else {
   if (errorObj.hasOwnProperty("phone")) {
    let newErrorObj = { ...errorObj };
    delete newErrorObj["phone"];
    setErrorObj(newErrorObj);
   }
  }
 };

 const handleCheckBoxChange = (event) => {
  setChecked(event.target.checked);
 };

 const handleStartDateChange = (event) => {
  const newDate = event.target.value;
  setStartDate(newDate);
  if (event.target.value == "") {
   setErrorObj({ ...errorObj, startDate: "Please enter proper start date" });
  } else {
   if (errorObj.hasOwnProperty("startDate")) {
    let newErrorObj = { ...errorObj };
    delete newErrorObj["startDate"];
    setErrorObj(newErrorObj);
   }
  }
 };

 const handleEndDateChange = (event) => {
  const newDate = event.target.value;
  setEndDate(newDate);
  if (event.target.value == "") {
   setErrorObj({ ...errorObj, startDate: "Please enter proper end date" });
  } else {
   if (errorObj.hasOwnProperty("endDate")) {
    let newErrorObj = { ...errorObj };
    delete newErrorObj["endDate"];
    setErrorObj(newErrorObj);
   }
  }
 };

 const handleStartTimeChange = (event) => {
  const newTime = event.target.value;
  setStartTime(newTime);
  if (event.target.value == "") {
   setErrorObj({ ...errorObj, startTime: "Please enter start time" });
  } else {
   if (errorObj.hasOwnProperty("startTime")) {
    let newErrorObj = { ...errorObj };
    delete newErrorObj["startTime"];
    setErrorObj(newErrorObj);
   }
  }
 };

 const handleEndTimeChange = (event) => {
  const newTime = event.target.value;
  setEndTime(newTime);
  if (event.target.value == "") {
   setErrorObj({ ...errorObj, endTime: "Please enter end time" });
  } else {
   if (errorObj.hasOwnProperty("endTime")) {
    let newErrorObj = { ...errorObj };
    delete newErrorObj["endTime"];
    setErrorObj(newErrorObj);
   }
  }
 };

 const handleCategoryChange = (event) => {
  const { value } = event.target;
  const categoryArray = value
   ? value.split(",").map((category) => category.trim())
   : [];
  setSelectedCategory(categoryArray);
  if (value == "") {
   setErrorObj({
    ...errorObj,
    category: "Please add some category",
   });
  } else {
   if (errorObj.hasOwnProperty("category")) {
    let newErrorObj = { ...errorObj };
    delete newErrorObj["category"];
    setErrorObj(newErrorObj);
   }
  }
 };

 const handleMultipleAgeGroupChange = (event, newValue) => {
  const selectedAgeGroups = newValue.map((value) => value);
  setSelectedAgeGroup(selectedAgeGroups);
  if (newValue == "") {
   setErrorObj({
    ...errorObj,
    ageGroup: "Please select age group",
   });
  } else {
   if (errorObj.hasOwnProperty("ageGroup")) {
    let newErrorObj = { ...errorObj };
    delete newErrorObj["ageGroup"];
    setErrorObj(newErrorObj);
   }
  }
 };

 const handleRewardsChange = (event) => {
  const newRewards = parseFloat(event.target.value);
  if (!isNaN(newRewards)) {
   setRewards(newRewards);
  } else {
   setRewards("");
  }

  if (event.target.value == "") {
   setErrorObj({ ...errorObj, reward: "Please enter reward points" });
  } else {
   if (errorObj.hasOwnProperty("reward")) {
    let newErrorObj = { ...errorObj };
    delete newErrorObj["reward"];
    setErrorObj(newErrorObj);
   }
  }
 };

 const handleEducationPoints = (event) => {
  const newPoints = parseFloat(event.target.value);
  if (!isNaN(newPoints)) {
   setEducationPoints(newPoints);
  } else {
   setEducationPoints("");
  }
  if (event.target.value == "") {
   setErrorObj({
    ...errorObj,
    educationPoints: "Please enter education points",
   });
  } else {
   if (errorObj.hasOwnProperty("educationPoints")) {
    let newErrorObj = { ...errorObj };
    delete newErrorObj["educationPoints"];
    setErrorObj(newErrorObj);
   }
  }
 };

 const handleAddressChange = (event) => {
  setNewAddressField(event.target.value);
  if (event.target.value == "") {
   setErrorObj({ ...errorObj, address: "Please enter address" });
  } else {
   if (errorObj.hasOwnProperty("address")) {
    let newErrorObj = { ...errorObj };
    delete newErrorObj["address"];
    setErrorObj(newErrorObj);
   }
  }
 };

 const handleDescriptionChange = (event) => {
  const newDescription = event.target.value;
  setDescription(newDescription);
  if (event.target.value == "") {
   setErrorObj({
    ...errorObj,
    description: "Please enter description",
   });
  } else {
   if (errorObj.hasOwnProperty("description")) {
    let newErrorObj = { ...errorObj };
    delete newErrorObj["description"];
    setErrorObj(newErrorObj);
   }
  }
 };

 const handleBack = () => {
  setOpenCreateActivityPage(!openCreateActivityPage);
  handleBusinessClose();
 };

 const handleEditBack = () => {
  setOpenEditActivityPage(!openEditActivityPage);
  handleBusinessClose();
 };

 const handleRadioChange = (event) => {
  setIsMultiple(event.target.value === "true");
 };

 const handleThreshold = (event) => {
  setThreshold(event.target.value);
  if (event.target.value == "") {
   setErrorObj({ ...errorObj, threshold: "Please enter value" });
  } else {
   if (errorObj.hasOwnProperty("threshold")) {
    let newErrorObj = { ...errorObj };
    delete newErrorObj["threshold"];
    setErrorObj(newErrorObj);
   }
  }
 };

 const onSave = () => {
  const ageGrpArray = selectedAgeGroup?.map((ageGroup, key) => {
   if (ageGroup === "Age 0-6") {
    return 1;
   } else if (ageGroup === "Age 6-11") {
    return 2;
   } else return 3;
  });

  const updatedData = {
   ...data,
   isOnline: isOn,
   url: onlineActivityLink ? onlineActivityLink : "",
   title: activityTitle,
   scheduledAt: selectedDate + "T" + selectedTime + ":00.000Z",
   skills: selectedSkills,
   description: description,
   rewards: rewards,
   educationPoints: educationPoints,
   ageGroup: ageGrpArray,
   categories: selectedCategory,
   openingDate: startDate + "T" + startTime + ":00.000Z",
   closingDate: endDate + "T" + endTime + ":00.000Z",
   email: email,
   phone: phoneNumber,
   duration: duration,
   isMultiple: isMultiple,
   threshold: parseInt(threshold),
   duration: duration,
   //  location: isOn
   //   ? null
   //   : {
   //      ...data?.location,
   //      address: newAddressField,
   //      coordinates: [coordinates?.lat, coordinates?.lng],
   //      pincode: parseInt(data?.location?.pincode),
   //     },
  };

  createPmActivity(updatedData).then((res) => {
   if (res.data.successMessage) {
    handleBack();
   }
  });
  setData(updatedData);
 };

 const OnEdit = () => {
  const editedData = {
   ...editData,
   createdBy: editData?.createdBy,
   id: editData?._id,
   isOnline: isOn,
   url: onlineActivityLink,
   title: activityTitle,
   scheduledAt: selectedDate + "T" + selectedTime + ":00.000Z",
   skills: selectedSkills,
   description: description,
   rewards: rewards,
   educationPoints: educationPoints,
   ageGroup: selectedAgeGroup,
   categories: selectedCategory,
   openingDate: startDate + "T" + startTime + ":00.000Z",
   closingDate: endDate + "T" + endTime + ":00.000Z",
   email: email,
   phone: phoneNumber,
   duration: duration,
   isMultiple: isMultiple,
   threshold: parseInt(threshold),
   duration: duration,
   //  location: isOn
   //   ? null
   //   : {
   //      ...editData?.location,
   //      coordinates: editData?.location?.coordinates
   //       ? editData?.location?.coordinates
   //       : [],
   //      address: newAddressField ? newAddressField : editData?.location?.address,
   //      pincode: parseInt(editData?.location?.pincode),
   //     },
  };

  updatePmActivity(editedData).then((res) => {
   if (res.data.successMessage) {
    getBusinessActivities();
    setOpenEditActivityPage(!openEditActivityPage);
    handleEditBack();
   }
  });
 };

 const locationData = {
  address: "dfsdffs",
  country: "India",
  city: "Bhubaneswar",
  pincode: 232445,
 };

 useEffect(() => {
  // setPageLocation("EditBusinessActivity");
  Geocode.fromAddress(`${locationData.country}, ${locationData.city}`)
   .then((response) => {
    const { lat, lng } = response.results[0].geometry.location;
   })
   .catch((error) => {
    console.error("Error:", error);
   });
 }, []);

 return (
  <>
   <form
    onSubmit={(e) => {
     if (Object.keys(errorObj).length) {
     }
     e.preventDefault();
     if (location === "Edit") {
      OnEdit();
     } else {
      onSave();
     }
    }}
   >
    <Box className={classes.comapanyInfo}>
     <Box className={classes.companyInfoContainer}>
      <Divider style={{ marginBottom: "1em" }} />
      <Container>
       <Grid container spacing={2}>
        {/* Choose online/offline */}
        <Grid item xs={12} md={12} direction="row">
         <div
          style={{
           display: "flex",
           flexDirection: "row",
           alignItems: "center",
           justifyContent: "flex-start",
          }}
         >
          <Switch
           classes={{
            root: classes.rootSwitch,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
           }}
           onChange={handleSwitchChange}
           checked={isOn}
          />
         </div>
         {isOn ? (
          <TextField
           required
           label="Mention your website link here"
           style={{ width: "100%" }}
           value={onlineActivityLink}
           onChange={handleOnlineActivityLinkChange}
           helperText={errorObj?.website}
           error={errorObj.hasOwnProperty("website")}
          />
         ) : null}
        </Grid>

        {/* Activity Title */}
        <Grid item xs={12} md={12}>
         <TextField
          required
          fullWidth
          label="Activity Title"
          variant="outlined"
          value={activityTitle}
          onChange={handleActivityTitleChange}
          helperText={errorObj?.title}
          error={errorObj.hasOwnProperty("title")}
         />
        </Grid>

        {/* Skills to learn */}
        {/* <Grid item xs={12} md={6}>
         <Autocomplete
          className={classes.cat_list}
          multiple
          id="tags-standard"
          options={skills}
          value={selectedSkills}
          onChange={handleMultipleSkillsChange}
          PaperComponent={({ children }) => (
           <Paper
            style={{
             backgroundColor: localStorage.getItem("dark") ? "#fff" : "#212121",
            }}
           >
            {children}
           </Paper>
          )}
          renderInput={(params) => (
           <TextField
            className={classes.menu}
            {...params}
            variant="outlined"
            label="Skills To Learn"
            name="skills_to_learn"
            position="relative"
            helperText={errorObj?.category}
            error={errorObj.hasOwnProperty("category")}
           />
          )}
         />
        </Grid> */}

        <Grid item xs={12} md={6}>
         <TextField
          fullWidth
          className={classes.menu}
          variant="outlined"
          label="Skills To Learn"
          value={selectedSkills}
          onChange={handleSkillsChange}
          helperText={errorObj?.skill}
          error={errorObj.hasOwnProperty("skill")}
         />
        </Grid>

        {/* Duration in hrs */}
        <Grid item xs={12} md={6}>
         <TextField
          required
          label="Duration in hrs"
          variant="outlined"
          fullWidth
          type="number"
          step="0.01"
          value={duration}
          onChange={handleDurationChange}
          helperText={errorObj?.duration}
          error={errorObj.hasOwnProperty("duration")}
         />
        </Grid>

        {/* Email */}
        <Grid item xs={12} md={6}>
         <TextField
          required={phoneNumber ? false : true}
          label="Email"
          variant="outlined"
          fullWidth
          type="email"
          value={email}
          onChange={handleEmail}
          helperText={errorObj?.email}
          error={errorObj.hasOwnProperty("email")}
         />
        </Grid>

        {/* Phone Number */}
        <Grid item xs={12} md={6}>
         <TextField
          required={email ? false : true}
          label="Phone Number"
          variant="outlined"
          fullWidth
          value={phoneNumber}
          onChange={handlePhone}
          type="number"
          helperText={errorObj?.phone}
          error={errorObj.hasOwnProperty("phone")}
         />
        </Grid>

        {/* address part */}
        {/* {!isOn ? (
         <>
          <Grid item xs={12}>
           <TextField
            fullWidth
            variant="outlined"
            label="Address"
            value={newAddressField}
            onChange={handleAddressChange}
            error={errorObj.hasOwnProperty("address")}
            helperText={errorObj?.address}
            className={classes.textField}
           />
          </Grid>

          <Grid item xs={12} md={12}>
           <Grid container>
            <Box style={{ marginTop: "1rem", width: "100%" }}>
             <AddressInput
              location={"businessActivity"}
              className={classes.ainpt}
              disableField={false}
              country={country}
              state={state}
              city={city}
              postcode={pincode}
              getChangedData={handleDataFromAddress}
              MenuProps={MenuProps}
              style={{
               backgroundColor: localStorage.getItem("dark")
                ? "#fff"
                : "#212121",
              }}
             />
            </Box>
           </Grid>
          </Grid>
         </>
        ) : null} */}

        {/* Activity Repeat Question */}
        <Grid
         item
         xs={12}
         md={4}
         style={{
          display: "flex",
          alignItems: "center",
         }}
        >
         <Typography>Do you want this activity to repeat?</Typography>
        </Grid>

        {/* Radio Buttons*/}
        <Grid item xs={12} md={4}>
         <FormControl>
          <RadioGroup
           row
           aria-label="position"
           name="position"
           value={isMultiple ? "true" : "false"}
           onChange={handleRadioChange}
          >
           <FormControlLabel
            value="true"
            control={<Radio color="primary" />}
            label="Yes"
           />
           <FormControlLabel
            value="false"
            control={<Radio color="primary" />}
            label="No"
           />
          </RadioGroup>
         </FormControl>
        </Grid>

        {/* Threshold */}

        {isMultiple ? (
         <Grid item xs={12} md={12} style={{ marginBottom: "1em" }}>
          <TextField
           required
           type="number"
           fullWidth
           variant="outlined"
           label="After how many days after they can take repeat..."
           value={threshold}
           onChange={handleThreshold}
           helperText={errorObj?.threshold}
           error={errorObj.hasOwnProperty("threshold")}
          />
         </Grid>
        ) : null}

        {/* Start Date */}
        <Grid item xs={12} md={6}>
         <TextField
          required
          onChange={handleStartDateChange}
          placeholder="Start Date"
          fullWidth
          value={startDate}
          id="startDate"
          variant="outlined"
          label="Start Date"
          type="date"
          className={classes.textField}
          InputLabelProps={{
           shrink: true,
           placeholder: "Select a date",
          }}
          inputProps={{ min: today }}
          helperText={errorObj?.startDate}
          error={errorObj.hasOwnProperty("startdate")}
         />
        </Grid>

        {/* Start Time */}
        <Grid item xs={12} md={6}>
         <TextField
          required
          value={startTime}
          onChange={handleStartTimeChange}
          fullWidth
          variant="outlined"
          id="startTime"
          label="Start Time"
          type="time"
          className={classes.textField}
          InputLabelProps={{
           shrink: true,
          }}
          inputProps={{
           step: 300, // 5 min
          }}
          helperText={errorObj?.startTime}
          error={errorObj.hasOwnProperty("startTime")}
         />
        </Grid>

        {/* End Date */}
        <Grid item xs={12} md={6}>
         <TextField
          required
          onChange={handleEndDateChange}
          value={endDate}
          placeholder="End Date"
          fullWidth
          id="endDate"
          variant="outlined"
          label="End Date"
          type="date"
          className={classes.textField}
          InputLabelProps={{
           shrink: true,
           placeholder: "Select a date",
          }}
          inputProps={{ min: today }}
          helperText={errorObj?.startDate}
          error={errorObj.hasOwnProperty("startdate")}
         />
        </Grid>

        {/* End Time */}
        <Grid item xs={12} md={6}>
         <TextField
          required
          value={endTime}
          onChange={handleEndTimeChange}
          fullWidth
          variant="outlined"
          id="endTime"
          label="End Time"
          type="time"
          className={classes.textField}
          InputLabelProps={{
           shrink: true,
          }}
          inputProps={{
           step: 300, // 5 min
          }}
          helperText={errorObj?.endTime}
          error={errorObj.hasOwnProperty("endTime")}
         />
        </Grid>

        {/* Category of Activity*/}
        {/* <Grid item xs={12} md={6}>
         <Autocomplete
          className={classes.cat_list}
          multiple
          id="tags-standard"
          options={categories}
          value={selectedCategory} // You can set the value to control the selected skills
          onChange={handleMultipleCategoryChange}
          PaperComponent={({ children }) => (
           <Paper
            style={{
             backgroundColor: localStorage.getItem("dark") ? "#fff" : "#212121",
            }}
           >
            {children}
           </Paper>
          )}
          renderInput={(params) => (
           <TextField
            className={classes.menu}
            {...params}
            variant="outlined"
            label="Category of Activity"
            name="Category of Activity"
            position="relative"
            helperText={errorObj?.categoryOfActivity}
            error={errorObj.hasOwnProperty("categoryOfActivity")}
           />
          )}
         />
        </Grid> */}

        <Grid item xs={12} md={6}>
         <TextField
          fullWidth
          className={classes.menu}
          variant="outlined"
          label="Categories"
          value={selectedCategory}
          onChange={handleCategoryChange}
          helperText={errorObj?.category}
          error={errorObj.hasOwnProperty("category")}
         />
        </Grid>

        {/* Age Group */}
        <Grid item xs={12} md={6}>
         <Autocomplete
          required
          className={classes.cat_list}
          multiple
          id="tags-standard"
          options={age}
          value={selectedAgeGroup}
          onChange={handleMultipleAgeGroupChange}
          PaperComponent={({ children }) => (
           <Paper
            style={{
             backgroundColor: localStorage.getItem("dark") ? "#fff" : "#212121",
            }}
           >
            {children}
           </Paper>
          )}
          renderInput={(params) => (
           <TextField
            className={classes.menu}
            {...params}
            variant="outlined"
            label="Age Group"
            name="Age Group"
            position="relative"
            helperText={errorObj?.ageGroup}
            error={errorObj.hasOwnProperty("ageGroup")}
           />
          )}
         />
        </Grid>

        {/* Rewards */}
        <Grid item xs={12} md={6}>
         <TextField
          fullWidth
          className={classes.roundedTextField}
          name="Rewards"
          variant="outlined"
          label="Rewards"
          type="number"
          step="0.01"
          style={{ borderRadius: "1px" }}
          value={rewards}
          onChange={handleRewardsChange}
          helperText={errorObj?.reward}
          error={errorObj.hasOwnProperty("reward")}
         />
        </Grid>
        <Grid item xs={12} md={6}>
         <TextField
          fullWidth
          className={classes.roundedTextField}
          name="EducationPoints"
          variant="outlined"
          label="Education Points"
          type="number"
          step="0.01"
          style={{ borderRadius: "1px" }}
          value={educationPoints}
          onChange={handleEducationPoints}
          helperText={errorObj?.educationPoints}
          error={errorObj.hasOwnProperty("educationPoints")}
         />
        </Grid>

        {/* Description */}
        <Grid item xs={12} md={12}>
         <TextField
          required
          className={classes.roundedTextField}
          fullWidth
          name="Description"
          variant="outlined"
          label="Description"
          type="text"
          multiline
          rows={4}
          style={{ borderRadius: "1px" }}
          value={description}
          onChange={handleDescriptionChange}
          helperText={errorObj?.description}
          error={errorObj.hasOwnProperty("description")}
         />
        </Grid>
       </Grid>
      </Container>

      <Grid item xs={12} style={{ textAlign: "center", marginTop: "2em" }}>
       {location === "Edit" ? (
        <ThemeButton
         title="SAVE"
         width="20%"
         margin="2%"
         //  onClick={OnEdit}
         disabled={Object.values(errorObj).length ? true : false}
        />
       ) : (
        <ThemeButton
         title="CREATE"
         width="20%"
         margin="2%"
         type="Submit"
         disabled={Object.values(errorObj).length ? true : false}
         //  onClick={onSave}
        />
       )}
      </Grid>
     </Box>
    </Box>
   </form>
  </>
 );
};

const mapStateToProps = ({ userReducer, authReducer }) => {
 return {};
};

const mapDispatchToProps = (dispatch) => {
 return {
  getBusinessActivities: (data) =>
   dispatch(actionCreator.getBusinessActivities(data)),
  createPmActivity: (data) => dispatch(actionCreator.createPmActivity(data)),
  updatePmActivity: (data) => dispatch(actionCreator.updatePmActivity(data)),
  getPmActivity: () => dispatch(actionCreator.getPmActivity()),
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessActivity);
