import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../../asset/utility";

const initialState = {
  loading: false,
  error: null,
  isLoggedIn: false,
  registered: false,
  checking: true,
  regUser: null,
};

//# HERE IS THE REDUCER OR CASE'S
const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_LOADING:
      return setLoading(state, payload);
    case actionTypes.SET_ERROR:
      return setError(state, payload);
    case actionTypes.SET_REG_USER:
      return setRegUser(state, payload);
    case actionTypes.REGISTER:
      return register(state, payload);
    case actionTypes.SET_FLAG:
      return setFlag(state, payload);
    case actionTypes.LOGIN:
      return logIn(state, payload);
    case actionTypes.IS_LOG_IN:
      return isLogIn(state, payload);
    case actionTypes.LOGOUT:
      return logout(state, payload);

    default:
      return state;
  }
};

/*--- CASES ARE HERE DEFINED */
// UTILITY FUNCTION CALCULATE UTILIZATION

const setLoading = (state, data) => {
  const { status } = data;
  return updateObject(state, {
    loading: status,
  });
};

const setError = (state, data) => {
  const { error } = data;
  return updateObject(state, {
    error: error || null,
  });
};

const setRegUser = (state, data) => {
  const { user } = data;
  return updateObject(state, {
    regUser: user,
  });
};

const register = (state, data) => {
  const { registered } = data;
  return updateObject(state, {
    registered: registered,
  });
};

const setFlag = (state, data) => {
  const { flag } = data;
  return updateObject(state, {
    flag: flag,
  });
};

const logIn = (state, data) => {
  const { status } = data;
  return updateObject(state, {
    isLoggedIn: status,
    regUser: null,
  });
};

const isLogIn = (state, data) => {
  const { status } = data;
  return updateObject(state, {
    isLoggedIn: status,
    checking: false,
    regUser: null,
  });
};

const logout = (state, data) => {
  //return updateObject(state, initialState);
  return updateObject(
    state,
    updateObject(initialState, {
      checking: false,
    })
  );
};

export default authReducer;
