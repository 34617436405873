import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import "./css/common.css";
import {
  Container,
  Grid,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import Logo from "../../components/layout/Logo1";

import * as actionCreator from "../../store/action/index";
import { updateObjectProperty } from "../../asset/utility";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const CustomButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    height: 50,
    width: "100%",
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    marginBottom: "20px",
    background: "#359DB6",
    "&:hover": {
      background: "#62c3db",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#8ab0b9",
  },
})((props) => <Button {...props} />);

const CustomInput = withStyles({
  root: {
    //background: "#eeededd7",
    width: "100%",
    marginTop: "10px",
    fontFamily: "Manrope",
    fontStyle: "normal",
  },
})((props) => <TextField {...props} />);

const LogIn = (props) => {
  const [user, setUser] = useState({
    id: null,
    password: null,
  });

  const { loading, ResetErrors, LogInAsync } = props;

  useEffect(() => {
    return () => {
      ResetErrors();
    };
  }, [ResetErrors]);

  const onInputValueChanged = (e) => {
    let name = e.target.name;
    let value =
      e.target.name === "id" ? e.target.value.toLowerCase() : e.target.value;
    // let userCopy = { ...user };
    // userCopy[name] = value;
    // setUser(userCopy);
    setUser(updateObjectProperty(user, name, value));
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    LogInAsync(user);
  };

  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <div className="cogito-container">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Logo />
          </Grid>
          <Grid item>
            <div className="cogito-text1">Admin Log In</div>
          </Grid>
        </Grid>
        <form onSubmit={formSubmitHandler} method="post">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomInput
                autoComplete="off"
                autosuggest="off"
                variant="outlined"
                name="id"
                type="email"
                placeholder="Email"
                required
                value={user?.id}
                onChange={onInputValueChanged}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                autoComplete="off"
                autosuggest="off"
                variant="outlined"
                name="password"
                type="password"
                placeholder="Password"
                required
                value={user?.password}
                onChange={onInputValueChanged}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomButton type="submit" disabled={loading}>
                Log In
              </CustomButton>
            </Grid>
          </Grid>
        </form>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </Container>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    loading: authReducer.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    LogInAsync: (data) => dispatch(actionCreator.LogInAsync(data)),
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
