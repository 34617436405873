import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import EditAdminProfile from "../../components/auth/editAdminProfile";

import * as actionCreator from "../../store/action/index";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(1),
		padding: theme.spacing(1),
		flexGrow: 1,
		[theme.breakpoints.up("sm")]: {
			paddingLeft: theme.spacing(8) + 1,
		},
		borderRadius: "15px",
		"&.MuiToolbar-root": {
			color: theme.palette.background.default,
		},
	},
}));

const Profile = (props) => {
	const classes = useStyles();
	const { ResetErrors, admin, getAdmin } = props;

	useEffect(() => {
		//if(!admin ) getAdmin();
		return () => {
			ResetErrors();
		};
	}, [ResetErrors]);

	return (
		<React.Fragment>
			{admin ? (
				<Grid
					container
					className={classes.root}
					direction="column"
					justify="center"
					alignItems="center"
				>
					<Grid item xs={12}>
						<EditAdminProfile profile={admin} />
					</Grid>
				</Grid>
			) : null}
		</React.Fragment>
	);
};
const mapStateToProps = ({ authReducer, adminReducer }) => {
	return {
		loadings: adminReducer.loadings,
		admin: adminReducer.admin,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		ResetErrors: () => dispatch(actionCreator.ResetErrors()),
		getAdmin: () => dispatch(actionCreator.getAdmin()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
