import {
  TextField,
  Grid,
  Container,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab/";
import { useParams } from "react-router";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actionCreator from "../../../../../store/action";
// import ThemeButton from "../../common/button";
import Spinner from "../../../../../components/layout/Spinner/Spinner";
import { setWithExpiry, getWithExpiry } from "../../../../../asset/utility";
import { API } from "../../../../../asset/api";
const CustomInput = withStyles({
  root: {
    //background: "#eeededd7",
    borderRadius: 8,
    border: 0,
    width: "100%",
    fontFamily: "Manrope",
  },
})((props) => <TextField {...props} />);

const CustomButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#359DB6",
    "&:hover": {
      background: "#62c3db",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#8ab0b9",
  },
})((props) => <Button {...props} />);

const SuccessButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#10ca73",
    "&:hover": {
      background: "#87f891",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#758876",
  },
})((props) => <Button {...props} />);

function NonCogitoCompany(props) {
  const { registerCompany, loadings, notify } = props;

  const [numberOfDirectors, setNumberOfDirectors] = useState("");
  const [isAuthorizedToRegister, setIsAuthorizedToRegister] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [companyRegistrationNumber, setCompanyRegistartionNumber] = useState();
  const [countryOfRegistration, setCountryOfRegistration] = useState();
  const [numberOfSharholders, setNumberOfShareholders] = useState("");
  const [errorText, setErrorText] = useState();
  const [countries, setCountries] = useState(null);
  const [id, setId] = useState(useParams().id);

  const showCountries = () => {
    let storedToken = getWithExpiry("token");
    API.post(
      "/getCountries",
      {},
      {
        headers: {
          token: storedToken,
        },
        withCredentials: false,
      }
    )
      .then((res) => {
        const { status, successMessage, errorMessage, countries, error } =
          res.data;
        if (status) {
          setCountries(countries);
          res.successMessage = successMessage;
        } else {
          res.errorMessage = errorMessage;
        }
      })
      .catch((err) => {
        notify(err?.response?.data?.errorMessage, "error");
      });
  };

  useEffect(() => {
    showCountries();
  }, [countries == null]);

  const onNonCogitoCompanysave = () => {
    if (
      !numberOfDirectors ||
      !companyName ||
      !companyRegistrationNumber ||
      !countryOfRegistration ||
      !numberOfSharholders
    ) {
      setErrorText("*All fields are mandatory");
    } else if (numberOfDirectors > 1 && isAuthorizedToRegister === false) {
      setErrorText("*Please do confirm the authorization to register");
    } else if (
      numberOfDirectors &&
      companyName &&
      companyRegistrationNumber &&
      countryOfRegistration &&
      numberOfSharholders &&
      (Number(numberOfDirectors) === 1 || isAuthorizedToRegister)
    ) {
      let data = {
        is_cogito_company: false,
        company_name: companyName,
        country_of_registration: countryOfRegistration,
        registration_number: companyRegistrationNumber,
        number_of_directors: Number(numberOfDirectors),
        number_of_shareholders: Number(numberOfSharholders),
        is_authorized_to_register: isAuthorizedToRegister,
      };

      let comData = {
        id: id,
        data: data,
      };
      registerCompany(comData);
    }
  };

  const changedValue = (e) => {
    if (e.target.name == "number_of_directors") {
      setNumberOfDirectors(e.target.value);
    } else if (e.target.name == "number_of_shareholders") {
      setNumberOfShareholders(e.target.value);
    }
  };

  return (
    <Container style={{ marginTop: "5%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            label="Company Name"
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Company Registration Number"
            type="text"
            onChange={(e) => setCompanyRegistartionNumber(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Autocomplete
            name="Country of Registration"
            onChange={(e, value) => setCountryOfRegistration(value)}
            options={countries ? countries.map((country) => country.name) : []}
            noOptionsText={"No country"}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Country"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="number_of_shareholders"
            variant="outlined"
            label="Number of shareholders"
            type="text"
            onChange={changedValue}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="number_of_directors"
            variant="outlined"
            label="Number of Directors"
            type="text"
            onChange={changedValue}
          />
        </Grid>
        {numberOfDirectors > 1 ? (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={isAuthorizedToRegister}
                  onChange={(e) =>
                    setIsAuthorizedToRegister(!isAuthorizedToRegister)
                  }
                />
              }
              label="I have the required authorization to register the company"
            />
          </Grid>
        ) : null}
        {errorText ? (
          <Grid item xs={12} style={{ textAlign: "center", color: "#359DB6" }}>
            <Typography variant="body2"> {errorText}</Typography>
          </Grid>
        ) : null}
        <Grid
          item
          xs={12}
          style={{ textAlign: "center", marginBottom: "1rem" }}
        >
          <CustomButton
            style={{
              paddingLeft: "1.5rem",
              paddingRight: "1.5rem",
              marginRight: "1rem",
            }}
            onClick={props.onBack}
          >
            Back
          </CustomButton>

          <CustomButton
            style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
            onClick={onNonCogitoCompanysave}
            disabled={
              numberOfDirectors.length == 0 ||
              numberOfSharholders.length == 0 ||
              numberOfDirectors < 1 ||
              numberOfSharholders < 1
            }
          >
            Save
          </CustomButton>
        </Grid>
      </Grid>
      {loadings.registerCompany ? <Spinner /> : null}
    </Container>
  );
}

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerCompany: (data) => dispatch(actionCreator.registerCompany(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NonCogitoCompany);
