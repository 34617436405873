import React, { forwardRef, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import * as d3 from "d3";
import HtmlParser from "react-html-parser";

const useStyles = makeStyles(() => ({
  container: {
    padding: "0.5rem 0.5rem",
    // display: "flex",
    margin: "auto",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    overflowX: "scroll",
  },
}));

function BarChart(props) {
  const { height, width, data, bottomText, yBarText } = props;
  const classes = useStyles();

  // For each arc, use the path generator
  const MARGIN = { top: 30, right: 30, bottom: 20, left: 50 };

  const yScale = React.useMemo(() => {
    return d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.y)])
      .range([height - MARGIN.bottom, MARGIN.top]);
  }, [data, height, width]);

  const xScale = React.useMemo(() => {
    return d3
      .scaleBand()
      .domain(
        d3.groupSort(
          data,
          ([d]) => -d.y,
          (d) => d.x
        )
      )
      .range([MARGIN.left, width - MARGIN.right])
      .padding(0.1);
  }, [data, height, width]);

  // Create the SVG container.
  const svg = d3
    .create("svg")
    .attr("width", width)
    .attr("height", height)
    .attr("viewBox", [0, 0, width, height])
    .attr("style", "max-width: 100%; height: auto;");

  // Add a rect for each bar.
  svg
    .append("g")
    .attr("fill", "steelblue")
    .selectAll()
    .data(data)
    .join("rect")
    .attr("x", (d) => xScale(d.x))
    .attr("y", (d) => yScale(d.y))
    .attr("height", (d) => yScale(0))
    .attr("width", xScale.bandwidth());

  // Add the x-axis and label.
  svg
    .append("g")
    .attr("transform", `translate(0,${height - MARGIN.bottom})`)
    .call(d3.axisBottom(xScale).tickSizeOuter(0));

  // Add the y-axis and label, and remove the domain line.
  svg
    .append("g")
    .attr("transform", `translate(${MARGIN.left},0)`)
    .call(d3.axisLeft(yScale).tickFormat((y) => y))
    .call((g) => g.select(".domain").remove())
    .call((g) =>
      g
        .append("text")
        .attr("x", -MARGIN.left)
        .attr("y", 10)
        .attr("fill", "currentColor")
        .attr("text-anchor", "start")
        .text(yBarText)
    );

  // Return the SVG element.
  const svgElement = svg.html() || "";

  console.log(svgElement);

  return (
    <Box className={classes.container}>
      <svg width={width} height={height} style={{ display: "inline-block" }}>
        {HtmlParser(svgElement)}
      </svg>
      {bottomText ? bottomText : null}
    </Box>
  );
}

BarChart.defaultProps = {
  height: 300,
  width: 1200,
  yBarText: "",
  data: [
    // { x: 100, y: 90 },
    // { x: 101, y: 12 },
    // { x: 110, y: 50 },
    // { x: 120, y: 30 },
  ],
  bottomText: "",
};

export default BarChart;
