import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Button } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import SmallSpinner from "../common/smallSpinner";

const useStyles = makeStyles((theme) => ({
 buttonContainer: {
  fontSize: 18,
  margin: "auto",
  height: 48,
  borderRadius: 8,
  color: "#fff",
  background: "#075062",
  boxShadow: "none",
  "&:hover": {
   background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
   boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
  },
 },
}));

const CustomButtons = ({ activeStep, allChecked, setActiveStep, loading }) => {
 const handleBack = () => {
  setActiveStep(activeStep - 1);
 };
 const classes = useStyles();
 return (
  <Grid
   item
   container
   xs={12}
   justifyContent="center"
   style={{
    marginBottom: isMobile && activeStep === 1 ? "3vh" : "auto",
    marginTop:
     isMobile && (activeStep === 1 || activeStep === 2) ? "2rem" : "auto",
   }}
  >
   <Box
    style={{
     display: "flex",
     justifyContent: "space-evenly",
     width: isMobile ? "145%" : "80%",
    }}
   >
    <Grid item xs={4} sm={3}>
     <Button
      className={classes.buttonContainer}
      variant="contained"
      fullWidth
      onClick={handleBack}
      disabled={activeStep === 1}
      style={{
       marginRight: isMobile ? "7rem" : "null",
      }}
     >
      Previous
     </Button>
    </Grid>

    <Grid item xs={4} sm={3}>
     <Button
      className={classes.buttonContainer}
      type="submit"
      variant="contained"
      fullWidth
      disabled={!allChecked}
     >
      {activeStep !== 3 && "Next"}
      {activeStep === 3 && (loading ? <SmallSpinner /> : "Submit")}
     </Button>
    </Grid>
   </Box>
  </Grid>
 );
};

export default CustomButtons;
