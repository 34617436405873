import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreator from "../../store/action/index";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { forwardRef, useEffect } from "react";
import { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
// import { updateObjectProperty } from "../../../../asset/utility";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "flex",
    position: "absolute",
    right: 10,
    top: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const NoteModal = (props) => {
  const classes = useStyles();
  const { modalOPen, setModalOpen, updateCbcNote, rowData } = props;
  console.log({ rowData });
  const [note, setNote] = useState("");
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={true}
      onClose={() => setModalOpen(!modalOPen)}
    >
      <DialogContent
        style={{
          width: "30vw",
          minHeight: "200px",
          position: "relative",
        }}
      >
        <Grid
          item
          xs={12}
          container
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexDirection: "column",
          }}
        >
          <Grid
            item
            xs={12}
            className={classes.modalContainer}
            onClick={(e) => {
              e.stopPropagation();
              setModalOpen(false);
            }}
          >
            <CloseIcon />
          </Grid>
          <TextField
            variant="outlined"
            type="text"
            value={note}
            name="note"
            label="Note"
            onChange={(e) => setNote(e.target.value)}
            fullWidth
            style={{ marginTop: "20px" }}
          />
          <Button
            style={{
              background: "#359DB6",
              margin: "0.5rem",
            }}
            onClick={() => {
              updateCbcNote({
                profileId: rowData?.profile_id,
                transferId: rowData?.id,
                note: note,
              });
              setModalOpen(false);
            }}
          >
            Submit
          </Button>
        </Grid>
        {/* <Grid item xs={12}>
          {rowData?.note ? (
            <>
              <Typography>Previous Note:</Typography>
              <Typography>{rowData?.note}</Typography>
            </>
          ) : null}
        </Grid> */}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    bankAccount: adminReducer.bankAccount,
    user: adminReducer.user,
    cbcTransfers: adminReducer.cbcTransfers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCbcNote: (data) => dispatch(actionCreator.updateCbcNote(data)),
    getcbcbanktransfers: (data) =>
      dispatch(actionCreator.getcbcbanktransfers(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteModal);
