export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";
export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_REG_USER = "SET_REG_USER";
export const REGISTER = "REGISTER";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGOUTID = "LOGOUTID";
export const IS_LOG_IN = "IS_LOG_IN";
export const SET_ADMIN = "SET_ADMIN";
export const SET_ADMIN_LOADING = "SET_ADMIN_LOADING";
export const SET_COMPANY_LOADING = "SET_COMPANY_LOADING";
export const SET_BUSINESSES_LOADING = "SET_BUSINESSES_LOADING";
export const SET_ADMINS = "SET_ADMINS";
export const SET_COMPANY = "SET_COMPANY";
export const SET_BUSINESSES = "SET_BUSINESSES";
export const SET_STATUS = "SET_STATUS";
export const SET_FLAG = "SET_FLAG";
export const SET_SPONSOR_LIST = "SET_SPONSOR_LIST";
export const SET_USER = "SET_USER";
export const SET_USERS = "SET_USERS";
export const SET_KYC_DATA = "SET_KYC_DATA";
export const SET_KYC_DOCS_DATA = "SET_KYC_DOCS_DATA";
export const SET_KYC_IMAGE_DATA = "SET_KYC_IMAGE_DATA";
export const SET_KYC_IMAGE_DATAB = "SET_KYC_IMAGE_DATAB";
export const SET_KYC_IMAGE_DATAS = "SET_KYC_IMAGE_DATAS";
export const SET_ANNOUNCEMENTS = "SET_ANNOUNCEMENTS";
export const SET_ANNOUNCEMENT = "SET_ANNOUNCEMENT";
export const SET_ASSECTS_TYPE_DATA = "SET_ASSECTS_TYPE_DATA";
export const SET_APPROVAL_DETAIL = "SET_APPROVAL_DETAIL";
export const SET_APPROVAL_LOADING = "SET_APPROVAL_LOADING";
export const SET_APPROVALS = "SET_APPROVALS";
export const SET_ASSET = "SET_ASSET";
export const SET_INSTRUMENT = "SET_INSTRUMENT";
export const SET_USER_ACCOUNT = "SET_USER_ACCOUNT";
export const SET_ASSETS = "SET_ASSETS";
export const SET_INSTRUMENTS = "SET_INSTRUMENTS";
export const SET_NEWSLETTERS = "SET_NEWSLETTERS";
export const SET_TASKS = "SET_TASKS";
export const SET_ADMIN_REDUCER_VALUE = "SET_ADMIN_REDUCER_VALUE";
export const SET_ULM_QUESTION = "SET_ULM_QUESTION";
export const SET_OD_TYPE = "SET_OD_TYPE";
export const SET_BANK_ACCOUNT = "SET_BANK_ACCOUNT";
export const ADD_RECOMMENDS = "ADD_RECOMMENDS";
export const SET_STOCKS = "SET_STOCKS";
export const SET_USER_STOCKS = "SET_USER_STOCKS";
export const SET_CBC_TRANSFERS = "SET_CBC_TRANSFERS";
export const SET_USER_STOCK_TRANSACTIONS = "SET_USER_STOCK_TRANSACTION";
export const SET_CRYPTOPIA_FORM = "SET_CRYPTOPIA_FORM";
export const SET_CRYPTOPIA_FORM_TOTAL_COUNT = "SET_CRYPTOPIA_FORM_TOTAL_COUNT";
export const SET_COGITO_STATS = "SET_COGITO_STATS";
export const SET_CRYPTOPIA_STATS = "SET_CRYPTOPIA_STATS";
export const SET_CR_USERS = "SET_CR_USERS";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_BUSINESS_ACTIVITY = "SET_BUSINESS_ACTIVITY";
export const SET_BUSINESS = "SET_BUSINESS";
export const SET_PM_ACTIVITY = "SET_PM_ACTIVITY";
export const SET_STOCKS_CATEGORY = "SET_STOCKS_CATEGORY";
export const SET_STOCK_TEMPLATE = "SET_STOCK_TEMPLATE";
export const SET_PROSPECTUS = "SET_PROSPECTUS";
export const SET_STOCK_ORDER_BOOK = "SET_STOCK_ORDER_BOOK";
export const SET_STOCK_DOC = "SET_STOCK_DOC";
