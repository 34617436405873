import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Box, Dialog, Typography } from "@material-ui/core";
import {
  Edit,
  DeleteForeverOutlined,
  CloudUploadOutlined,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import clsx from "clsx";
import { FileUploader } from "react-drag-drop-files";
import { updateObjectProperty } from "../../../asset/utility";
import InputFieldAnnouncementTemplate from "./InputFieldAnnouncementTemplate";
import moment from "moment";
import { TextField } from "@material-ui/core";
import BulletpointFieldAnnouncementTemplate from "./BulletpointFieldAnnouncementTemplate";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "2rem",
    display: "flex",
    justifyContent: "center",
  },
  subContainer: {
    width: "80%",
    minWidth: 800,
    padding: "1rem",
  },
  toolsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.3rem 0.5rem",
  },
  addToolBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  keywordsContainer: {},
  keywordsTextField: { minWidth: 235 },
  buttonStyle: {
    backgroundColor: "rgb(53, 157, 182)",
    color: "#fff",
    fontSize: 10,
    fontWeight: 500,
    margin: "0 2px",
    textTransform: "capitalize",
  },
  submitButtonStyle: {
    fontSize: 14,
  },
  submitContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem",
  },
  previewContainer: {
    border: "1px solid #fff",
    padding: "1rem 1rem 3rem 1rem",
    backgroundColor: "#fff",
    minHeight: 300,
    borderRadius: 3,
  },
  uploadImageContainer: {
    display: "flex",
    justifyContent: "center",
  },
  imageContainer: {
    position: "relative",
  },
  deleteImageIcon: {
    position: "absolute",
    top: 5,
    right: 0,
    color: "#aaa",
  },
  authorContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dialogContainer: {
    padding: 26,
  },
  confirmTextStyle: {
    fontSize: 21,
    fontWeight: 600,
    marginBottom: 50,
  },
  dialogButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelDialogButtonStyle: {
    backgroundColor: "rgb(125, 125, 125)",
    color: "#fff",
    fontSize: 14,
    fontWeight: 500,
    margin: "0 0.5rem",
    textTransform: "capitalize",
  },
  confirmDialogButtonStyle: {
    backgroundColor: "rgb(53, 157, 182)",
    color: "#fff",
    fontSize: 14,
    fontWeight: 500,
    margin: "0 0.5rem",
    textTransform: "capitalize",
  },
}));

function AnnouncementTemplatePage(props) {
  const { submitButtonText, onSubmit, modifiedData, type } = props;

  const isCreate = type != "UPDATE";

  const classes = useStyles();

  const dynamicContentStyle = {
    lineHeight: "25px",
    marginTop: 0,
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    color: "rgb(35, 31, 32)",
    marginBottom: "1rem",
    fontSize: "1.3rem",
  };

  const dynamicParagraphBody = {
    text: "",
    type: "para",
    placeholder: "Empty Paragraph",
    style: {},
  };

  const dynamicSubTitleBody = {
    text: "",
    type: "subTitle",
    placeholder: "Empty Subtitle",
    style: {
      color: "#ff7300",
      fontWeight: "bold",
    },
  };

  const individualBulletList = {
    text: "",
    type: null,
    placeholder: "Empty Bullet Point",
    style: {},
  };

  const dynamicBulletPointBody = {
    text: [],
    type: "bulletPoint",
    bulletType: "",
    style: {},
  };

  const dynamicBreakBody = {
    type: "break",
    placeholder: "",
    style: {},
  };

  const initialState = {
    title: {
      text: "Enter Title Here",
      type: "title",
      placeholder: "Empty Title",
      style: {
        fontFamily: "Open Sans",
        color: "rgb(56, 40, 35)",
        fontWeight: 600,
        fontSize: "2rem",
      },
    },
    authorName: {
      text: "Cogito",
      type: "author",
      placeholder: "Empty Author",
      style: {
        fontFamily: "Open Sans",
        color: "#8F8D8D",
        fontWeight: 300,
        fontSize: "1rem",
        fontStyle: "italic",
      },
    },
    pic: null,
    body: [],
    keywords: "",
  };

  const [announcementData, setAnnouncementData] = useState(initialState);
  const [confirm, setConfirm] = useState(false);

  const imageUploaderRef = useRef();
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];

  const handleImageChange = (e) => {
    let imageSrc = URL.createObjectURL(e);
    let newState = { pic: imageSrc, selectedImageObject: e };
    setAnnouncementData((prevData) => ({ ...prevData, ...newState }));
  };

  const clearImageHandle = () => {
    let newState = { pic: "", selectedImageObject: null };
    setAnnouncementData((prevData) => ({ ...prevData, ...newState }));
  };

  const handleDataUpdate = (value, index) => {
    setAnnouncementData((prevData) => {
      let update = {};
      if (index == "title") {
        update.title = {
          ...prevData.title,
          text: value,
        };
      } else if (index == "authorName") {
        update.authorName = {
          ...prevData.authorName,
          text: value,
        };
      }
      return {
        ...prevData,
        ...update,
      };
    });
  };

  const handleUpdateState = (key, value) => {
    setAnnouncementData((prevData) => {
      let update = {};
      update[key] = value;
      return {
        ...prevData,
        ...update,
      };
    });
  };

  const handleCustomDataUpdate = (value, index, key) => {
    setAnnouncementData((prevData) => {
      let update = prevData.body || [];
      update[index][key ? key : "text"] = value;
      return {
        ...prevData,
        body: [...update],
      };
    });
  };

  const addNewField = (type) => {
    setAnnouncementData((prevData) => {
      let update = prevData.body || [];

      if (type == "subTitle") {
        update.push({ ...dynamicSubTitleBody, id: new Date().getTime() });
      } else if (type == "para") {
        update.push({ ...dynamicParagraphBody, id: new Date().getTime() });
      } else if (type == "bullet") {
        update.push({ ...dynamicBulletPointBody, id: new Date().getTime() });
      }
      update.push({ ...dynamicBreakBody, id: new Date().getTime() + 10 });
      return {
        ...prevData,
        body: [...update],
      };
    });
  };

  const deleteCustomField = (index) => {
    setAnnouncementData((prevData) => {
      let update =
        prevData?.body?.filter((_, i) => i !== index && i !== index + 1) || [];

      return {
        ...prevData,
        body: [...update],
      };
    });
  };

  const onSuccessCallBack = () => {
    setAnnouncementData(initialState);
  };

  const submitAnnouncement = () => {
    let subtitleStyle = `<Text style="color:#ff7300;font-weight:bold;">`;
    let paraStyle = `<Text>`;
    let bulletStyle = `<Text>`;
    let endTag = `</Text>`;
    let body = "";

    (announcementData?.body || []).map((data) => {
      if (data.type == "break") {
        body += "<br /><br />";
      } else if (data.type == "para") {
        body += paraStyle + data.text + endTag;
      } else if (data.type == "subTitle") {
        body += subtitleStyle + data.text + endTag;
      } else if (data.type == "bulletPoint") {
        let li = "";
        for (let i = 0; i < data.text?.length; i++) {
          li += `<li>${data.bulletType ? "&nbsp;" : ""}${
            data.text?.[i]?.text
          }</li>`;
        }
        body +=
          bulletStyle +
          `<ol ${
            data.bulletType
              ? `style="list-style-type:'${data.bulletType}';"`
              : ""
          }/>${li}</ol>` +
          endTag;
        console.log();
      }
    });

    let announcement = {
      title: announcementData.title.text,
      body: body,
      author_name: announcementData.authorName.text,
      is_archived: false,
      keywords:
        announcementData.keywords.split(",")?.filter((data) => data) || [],
      uploadPic: announcementData.selectedImageObject,
    };
    onSubmit(announcement, onSuccessCallBack);
  };

  useEffect(() => {
    if (modifiedData) {
      setAnnouncementData((data) => {
        data.title.text = modifiedData?.title || "";
        data.authorName.text = modifiedData?.author || "";
        data.keywords = modifiedData?.keywords || "";
        data.pic = modifiedData?.pic;
        data.body =
          modifiedData?.body?.map((data) => {
            if (data.type == "break") {
              return dynamicBreakBody;
            } else if (data.type == "subTitle") {
              return { ...dynamicSubTitleBody, text: data.text };
            } else if (data.type == "para") {
              return { ...dynamicParagraphBody, text: data.text };
            } else if (data.type == "bulletPoint") {
              let body = dynamicBulletPointBody;
              body.bulletType = data.bulletType;
              body.text =
                data.text?.map((d) => ({ ...individualBulletList, text: d })) ||
                [];

              return { ...body };
            }
          }) || [];
        return { ...data };
      });
    }
  }, [modifiedData]);
  return (
    <Box className={classes.container}>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={confirm}
        onClose={() => {
          setConfirm(false);
        }}
      >
        <Box className={classes.dialogContainer}>
          <Typography className={classes.confirmTextStyle}>
            {`Are you sure you want to ${
              isCreate ? "submit" : "update"
            } the announcement?`}
          </Typography>
          <Box className={classes.dialogButtonContainer}>
            <Button
              className={classes.cancelDialogButtonStyle}
              onClick={() => {
                setConfirm(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className={classes.confirmDialogButtonStyle}
              onClick={() => {
                submitAnnouncement();
                setConfirm(false);
              }}
            >
              {`${isCreate ? "Confirm" : "Update"}`}
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Box className={classes.subContainer}>
        <Box className={classes.toolsContainer}>
          <Box className={classes.keywordsContainer}>
            <TextField
              value={announcementData.keywords}
              onChange={(e) => {
                handleUpdateState("keywords", e.target.value);
              }}
              variant="outlined"
              size="small"
              label="Comma seperated keywords"
              className={classes.keywordsTextField}
            />
          </Box>
          <Box className={classes.addToolBox}>
            <Button
              className={classes.buttonStyle}
              onClick={() => addNewField("subTitle")}
            >
              Add Subtitle
            </Button>
            <Button
              className={classes.buttonStyle}
              onClick={() => addNewField("para")}
            >
              Add Paragraph
            </Button>
            <Button
              className={classes.buttonStyle}
              onClick={() => addNewField("bullet")}
            >
              Add Bulletpoint
            </Button>
          </Box>
        </Box>
        <Box className={classes.previewContainer}>
          {/* Image Upload Section */}
          {announcementData?.pic ? (
            <Box className={classes.imageContainer}>
              <img
                src={
                  announcementData?.selectedImageObject
                    ? announcementData.pic
                    : `${process.env.REACT_APP_SPACE_LINK}/${announcementData.pic}`
                }
                style={{
                  width: "100%",
                }}
              />
              <Button
                onClick={clearImageHandle}
                className={classes.deleteImageIcon}
              >
                <DeleteForeverOutlined />
              </Button>
            </Box>
          ) : (
            <Box className={classes.uploadImageContainer}>
              <FileUploader
                multiple={false}
                handleChange={handleImageChange}
                name="Image"
                types={fileTypes}
                label={"Announcement image"}
                ref={imageUploaderRef}
                maxSize={0.95}
              />
            </Box>
          )}
          {/* Image Upload Section End */}

          {/* Title */}
          <br />
          <InputFieldAnnouncementTemplate
            data={announcementData.title}
            customContainerStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            index="title"
            handleUpdate={handleDataUpdate}
          />
          {/* Title End */}

          {/* Author and Date */}
          <br />
          <Box
            className={classes.authorContainer}
            style={{
              fontFamily: "Open Sans",
              color: "#8F8D8D",
              fontWeight: 300,
              fontSize: "1rem",
              fontStyle: "italic",
            }}
          >
            <Box>
              <InputFieldAnnouncementTemplate
                data={announcementData.authorName}
                customContainerStyle={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                index="authorName"
                handleUpdate={handleDataUpdate}
              />
            </Box>
            <span>&nbsp; | &nbsp; </span>
            <Box>{moment(new Date()).format("MMMM DD, YYYY")}</Box>
          </Box>
          {/* Author and Date End */}
          <br />
          {/* Dynamic Content */}
          <p style={{ ...dynamicContentStyle }}>
            {announcementData?.body?.map((data, index) => {
              if (data.type == "break") {
                return <br key={data.id} />;
              }
              if (data.type == "bulletPoint") {
                return (
                  <BulletpointFieldAnnouncementTemplate
                    data={data}
                    index={index}
                    key={data.id}
                    handleUpdate={handleCustomDataUpdate}
                    handleDelete={deleteCustomField}
                  />
                );
              }
              return (
                <InputFieldAnnouncementTemplate
                  data={data}
                  index={index}
                  key={data.id}
                  handleUpdate={handleCustomDataUpdate}
                  handleDelete={deleteCustomField}
                />
              );
            })}
          </p>
          {/* Dynamic Content End */}
        </Box>
        <Box className={classes.submitContainer}>
          <Button
            className={clsx(classes.submitButtonStyle, classes.buttonStyle)}
            onClick={() => setConfirm(true)}
            disabled={
              !announcementData?.title?.text ||
              !announcementData?.pic ||
              !announcementData?.authorName?.text ||
              // !announcementData?.selectedImageObject ||
              !announcementData?.body?.length ||
              announcementData?.body?.filter(
                (data) =>
                  (data.type != "bulletPoint" && data?.text == "") ||
                  (data.type == "bulletPoint" &&
                    data?.text &&
                    data?.text?.filter((list) => list.text == "")?.length)
              )?.length
            }
          >
            {submitButtonText}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

const mapStateToProps = ({ adminReducer }) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementTemplatePage);
