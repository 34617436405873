import React, { useState, useEffect } from "react";
import { Typography, Grid, Button, Box, ButtonBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actionCreator from "../../../../store/action/index";
import Avatar from "@material-ui/core/Avatar";
import { getUserData } from "../../../../utils/methods/userData";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2, 0),
  },
  name: {
    fontSize: 16,
    fontWeight: 500,
  },
  condition: {
    fontSize: 13,
    fontWeight: 400,
  },
  commentStatus: {
    display: "flex",
    justifyContent: "space-between",
  },
  commentHide: {
    fontSize: 14,
    fontWeight: 500,
    color: "grey",
  },
  commentView: {
    fontSize: 14,
    fontWeight: 600,
    color: "#359DB6",
  },
  completedText: {
    fontSize: 14,
    fontWeight: 600,
    color: "#3BD065",
  },
  inprogressText: {
    fontSize: 14,
    fontWeight: 600,
    color: "#C49C39",
  },
  commentContainer: {
    padding: "0 1.5rem",
  },
  profileImageStyle: {
    height: "2rem",
    borderRadius: 50,
    overflow: "hidden",
  },
  profileIconStyle: {
    fontSize: "2.2rem",
  },
}));
const Condition = (props) => {
  const classes = useStyles();
  const { conditionData, users } = props;

  const [commentStatus, setCommentStatus] = useState(false);

  let userData = getUserData(conditionData?.user?.ProfileID, users);

  return (
    <Box className={classes.container}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={1}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {userData?.profile_pic ? (
            <img
              src={`${process.env.REACT_APP_SPACE_LINK}/${userData?.profile_pic}`}
              className={classes.profileImageStyle}
            />
          ) : (
            <AccountCircleIcon className={classes.profileIconStyle} />
          )}
        </Grid>

        <Grid item container xs={11}>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              className={classes.name}
            >{`${conditionData?.user?.FirstName} ${conditionData?.user?.LastName}(${conditionData?.type})`}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item container xs={11}>
          <Grid item xs={12}>
            <Typography className={classes.condition}>
              {conditionData?.condition}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.commentStatus}>
              <Typography
                className={
                  commentStatus || !conditionData?.comments?.length
                    ? classes.commentHide
                    : classes.commentView
                }
              >
                {conditionData?.comments?.length ? (
                  <ButtonBase onClick={() => setCommentStatus(!commentStatus)}>
                    {commentStatus ? "Hide Comments" : "View Comments"}
                  </ButtonBase>
                ) : (
                  "No Comments"
                )}
              </Typography>
              <Typography
                className={
                  conditionData?.is_done
                    ? classes.completedText
                    : classes.inprogressText
                }
              >
                {conditionData?.is_done ? "Completed" : "Yet to be done"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.commentContainer}>
              <Grid container>
                {commentStatus &&
                  conditionData?.comments?.map((data, index) => {
                    let userCommented = getUserData(data?.author, users);
                    return (
                      <Grid key={index} item container xs={12}>
                        <Grid
                          item
                          xs={1}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {userCommented?.profile_pic ? (
                            <img
                              src={`${process.env.REACT_APP_SPACE_LINK}/${userCommented?.profile_pic}`}
                              className={classes.profileImageStyle}
                            />
                          ) : (
                            <AccountCircleIcon
                              className={classes.profileIconStyle}
                            />
                          )}
                        </Grid>
                        <Grid item container xs={11}>
                          <Grid
                            item
                            xs={12}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography className={classes.name}>
                              {`${userCommented?.firstName} ${userCommented?.lastName}`}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item container xs={11}>
                          <Grid item xs={12}>
                            <Typography className={classes.condition}>
                              {data?.comment}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    users: adminReducer.users,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Condition);
