import MaterialTable from "material-table";
import React, { useEffect, useState, forwardRef, useRef } from "react";
import * as actionCreator from "../../../../store/action/index";
import { connect } from "react-redux";
import {
 Delete,
 Edit,
 DeleteOutline,
 Clear,
 FilterList,
 FirstPage,
 ChevronRight,
 AccountCircle,
 AddBox,
 ArrowDownward,
 Check,
 ChevronLeft,
 LastPage,
 Remove,
 SaveAlt,
 Search,
 ViewColumn,
} from "@material-ui/icons";

import {
 Button,
 Dialog,
 DialogActions,
 DialogTitle,
 Grid,
 makeStyles,
 useTheme,
 withStyles,
} from "@material-ui/core";
import moment from "moment/moment";
import CreateBusinessactivity from "../../../dashboard/businessPage/CreateBusinessactivity";
import Modal from "../../../../components/common/modal";

const CustomButton = withStyles({
 root: {
  borderRadius: 8,
  justifySelf: "right",
  border: 0,
  color: "white",
  fontStyle: "normal",
  background: "#359DB6",
  "&:hover": {
   background: "#62c3db",
  },
 },
 label: {
  textTransform: "capitalize",
 },
 disabled: {
  background: "#8ab0b9",
 },
})((props) => <Button {...props} />);

const useStyles = makeStyles((theme) => ({
 button: {
  margin: "0 0 1% 0",
 },
 container: {
  width: "100%",
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  flexGrow: 1,
  // [theme.breakpoints.up("sm")]: {
  //  paddingLeft: theme.spacing(8) + 1,
  // },
  borderRadius: "15px",
  "&.MuiToolbar-root": {
   color: theme.palette.background.default,
  },
  "& .MuiTable-root": {
   "& .MuiTableRow-root": {
    "&:nth-child(even)": {
     background: theme.palette.background.paper,
    },
    "&:nth-child(odd)": {
     background: theme.palette.background.default,
    },
   },
  },
 },
}));

const tableIcons = {
 Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
 Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
 Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
 Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
 DetailPanel: forwardRef((props, ref) => (
  <AccountCircle {...props} ref={ref} />
 )),
 Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
 Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
 Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
 FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
 LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
 NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
 PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
 ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
 Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
 SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
 ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
 ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const PMBusinessActivityTable = (props) => {
 const theme = useTheme();
 const classes = useStyles(theme);
 const [open, setOpen] = React.useState(false);
 const [selectedRow, setSelectedRow] = useState({});
 const [modalName, setModalName] = useState("");
 const [editDialogOpen, setEditDialogOpen] = useState(false);
 const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
 const [openEditActivityPage, setOpenEditActivityPage] = useState(false);
 const {
  getBusinessActivities,
  businessActivities,
  pmActivityApproval,
  deletePmActivity,
  getPmActivity,
  notify,
 } = props;

 //  useEffect(() => {
 //   if (pMTasks?.tasks?.length > 0) {
 //    setRows([
 //     ...pMTasks?.tasks?.map((data) => {
 //      data.isActive = data.isActive === undefined ? true : data.isActive;
 //      return data;
 //     }),
 //    ]);
 //   }
 //  }, []);

 useEffect(() => {
  getBusinessActivities();
 }, [getBusinessActivities]);

 //  const pmActivity = (data) => {
 //   pmActivityApproval({
 //    id: selectedRow._id,
 //    note: data.note,
 //    status: data.status,
 //   });
 //  };

 //  const handleClose = () => {
 //   setOpen(false);
 //   setModalName("");
 //   setSelectedRow({});
 //  };

 const handleBusinessClose = () => {
  setOpen(false);
  setEditDialogOpen(false);
  setEditDialogOpen(false);
 };

 const handleOpenEditModal = (rowData) => {
  setSelectedRow(rowData);
  setEditDialogOpen(true);
 };
 const handleOpenDeleteModal = (rowData) => {
  setSelectedRow(rowData);
  setDeleteDialogOpen(true);
 };

 const handleClose = () => {
  setDeleteDialogOpen(false);
 };

 var CurrentDate = moment();

 return (
  <>
   {/* <TaskModal
      title={modalName}
      rowData={selectedRow}
      onClose={handleClose}
      // createTask={createTask}
      pmActivity={pmActivity}
      open={open}
    /> */}
   <Grid container>
    <Grid item xs={12} className={classes.container}>
     {businessActivities?.length > 0 ? (
      <MaterialTable
       icons={tableIcons}
       title="Business Activities"
       columns={[
        { title: "Task Id", field: "_id", editable: "never" },
        {
         title: "Created By",
         field: "createdBy",
         editable: "never",
        },
        {
         title: "Activity Name",
         field: "title",
         editable: "never",
        },
        {
         title: "Reward",
         field: "rewards",
         editable: "never",
        },
        {
         title: "Category",
         field: "categories",
         editable: "never",
         render: (rowData) => rowData?.categories.join(", "),
        },
        {
         title: "Skills",
         field: "skills",
         editable: "never",
         render: (rowData) => rowData?.skills.join(", "),
        },
        // {
        //  title: "Address",
        //  field: "address",
        //  render: (rowData) =>
        //   rowData?.location
        //    ? `${rowData.location.address}, ${rowData.location.city}, ${rowData.location.country}`
        //    : "-",
        //  editable: "never",
        // },
        {
         title: "Age group",
         field: "ageGroup",
         editable: "never",
        },
        {
         title: "Status",
         field: "status",
         lookup: {
          WFA: "WFA",
          REJECTED: "REJECTED",
          SCHEDULED: "SCHEDULED",
         },
        },
       ]}
       data={businessActivities}
       actions={[
        {
         icon: () => {
          return <Edit />;
         },
         tooltip: "Edit",
         onClick: (event, rowData) => {
          if (CurrentDate.format() > rowData?.openingDate) {
           notify("Activity scheduled time has past", "warning");
          } else handleOpenEditModal(rowData);
         },
        },
        {
         icon: () => {
          return <Delete />;
         },
         tooltip: "Delete",
         onClick: (event, rowData) => {
          if (CurrentDate.format() > rowData?.openingDate) {
           notify("Activity scheduled time has past", "warning");
          } else handleOpenDeleteModal(rowData);
         },
        },
       ]}
      />
     ) : null}
    </Grid>
   </Grid>

   <Dialog open={editDialogOpen} onClose={handleBusinessClose}>
    <DialogTitle>Edit Business Activity</DialogTitle>

    <CreateBusinessactivity
     location="Edit"
     editData={selectedRow}
     setOpenEditActivityPage={setOpenEditActivityPage}
     openEditActivityPage={openEditActivityPage}
     handleBusinessClose={handleBusinessClose}
    />
   </Dialog>

   {deleteDialogOpen && (
    <Modal
     component={"deleteActivity"}
     open={deleteDialogOpen}
     onClose={handleClose}
     title={"Are you sure you want to delete this activity?"}
     buttonName={"Delete"}
     onClickFn={() => {
      deletePmActivity({
       id: selectedRow?._id,
      })
       .then((res) => {
        const { status, successMessage, errorMessage, error } = res.data;
        if (status) {
         setDeleteDialogOpen(false);
         notify(successMessage || "Activity deleted successfully.", "success");
         getBusinessActivities();
        } else {
         setDeleteDialogOpen(false);
         notify(errorMessage || "Failed to delete business activity.", "error");
        }
       })
       .catch((err) => {
        setDeleteDialogOpen(false);
       });
     }}
    />
   )}
  </>
 );
};
const mapStateToProps = ({ adminReducer }) => {
 return {
  loadings: adminReducer.loadings,
  pMTasks: adminReducer.pMTasks,
  businessActivities: adminReducer.businessActivities,
 };
};
const mapDispatchToProps = (dispatch) => {
 return {
  getBusinessActivities: (data) =>
   dispatch(actionCreator.getBusinessActivities(data)),
  deletePmActivity: (data) => dispatch(actionCreator.deletePmActivity(data)),
  pmActivityApproval: (data) =>
   dispatch(actionCreator.pmActivityApproval(data)),
  getPmActivity: () => dispatch(actionCreator.getPmActivity()),
  notify: (message, varient) =>
   dispatch(actionCreator.notify(message, varient)),
 };
};

export default connect(
 mapStateToProps,
 mapDispatchToProps
)(PMBusinessActivityTable);
