import MaterialTable from "material-table";
import React, { useEffect, useState, forwardRef } from "react";
import * as actionCreator from "../../../../store/action/index";
import { connect } from "react-redux";
import {
  Edit,
  DeleteOutline,
  Clear,
  FilterList,
  FirstPage,
  ChevronRight,
} from "@material-ui/icons";
import {
  AccountCircle,
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  makeStyles,
  useTheme,
  withStyles,
} from "@material-ui/core";
import QuestionModal from "./QuestionModal";
import moment from "moment";

const CustomButton = withStyles({
  root: {
    borderRadius: 8,
    justifySelf: "right",
    border: 0,
    color: "white",
    fontStyle: "normal",
    background: "#359DB6",
    "&:hover": {
      background: "#62c3db",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#8ab0b9",
  },
})((props) => <Button {...props} />);

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0 0 1% 0",
  },
  container: {
    width: "100%",
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    flexGrow: 1,
    // [theme.breakpoints.up("sm")]: {
    //  paddingLeft: theme.spacing(8) + 1,
    // },
    borderRadius: "15px",
    "&.MuiToolbar-root": {
      color: theme.palette.background.default,
    },
    "& .MuiTable-root": {
      "& .MuiTableRow-root": {
        "&:nth-child(even)": {
          background: theme.palette.background.paper,
        },
        "&:nth-child(odd)": {
          background: theme.palette.background.default,
        },
      },
    },
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <AccountCircle {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const ULMQuiz = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [modalName, setModalName] = useState("");
  const {
    createULMQuizQuestion,
    deleteULMQuestion,
    ulmQuestionList,
    updateULMQuestion,
  } = props;

  const createQuestion = (data) => {
    createULMQuizQuestion(data);
  };

  const editQuestion = (data) => {
    updateULMQuestion({
      id: selectedRow.id,
      ...data,
    });
  };

  const handleOpenAddQuestionModal = () => {
    setModalName("Add Question");
    setOpen(true);
  };

  const handleOpenDeleteModal = (rowData) => {
    setSelectedRow(rowData);
    setModalName("Delete Question");
    setOpen(true);
  };

  const handleClose = () => {
    console.log("close");
    setOpen(false);
    setModalName("");
    setSelectedRow({});
  };

  const handleOpenEditModal = (rowData) => {
    setSelectedRow(rowData);
    setModalName("Edit Question");
    setOpen(true);
  };

  const handleDelete = () => {
    deleteULMQuestion({ id: selectedRow.id });
  };
  return (
    <>
      <QuestionModal
        title={modalName}
        rowData={selectedRow}
        onClose={handleClose}
        createTask={createQuestion}
        deleteTask={handleDelete}
        editTask={editQuestion}
        open={open}
      />

      <Grid container>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <CustomButton
            className={classes.button}
            onClick={handleOpenAddQuestionModal}
          >
            Add Question
          </CustomButton>
        </Grid>
        <Grid item xs={12} className={classes.container}>
          {ulmQuestionList?.length > 0 ? (
            <MaterialTable
              icons={tableIcons}
              title="User Learning Module Questions"
              columns={[
                { title: "Id", field: "id", filtering: false },
                { title: "Question", field: "question", filtering: false },
                { title: "Answer", field: "answer", filtering: false },
                {
                  title: "Option",
                  field: "option",
                  filtering: false,
                  render: (rowData) => {
                    return (
                      <>
                        {(rowData?.option || []).map((data, index) => (
                          <div style={{ lineBreak: "anywhere" }}>
                            {`${index + 1}. ${data}`}
                          </div>
                        ))}
                      </>
                    );
                  },
                  filtering: false,
                },
                { title: "Module", field: "module", filtering: false },
                { title: "Submodule", field: "submodule", filtering: false },
              ]}
              data={ulmQuestionList}
              actions={[
                {
                  icon: () => {
                    return <Edit />;
                  },
                  tooltip: "Edit",
                  onClick: (event, rowData) => {
                    handleOpenEditModal(rowData);
                  },
                },
                {
                  icon: () => <DeleteOutline />,
                  tooltip: "Delete Question",
                  onClick: (event, rowData) => handleOpenDeleteModal(rowData),
                },
              ]}
              options={{
                filtering: true,
                search: true,
                sorting: false,
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    ulmQuestionList: adminReducer.ulmQuestionList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateULMQuestion: (data) =>
      dispatch(actionCreator.updateULMQuestion(data)),
    createULMQuizQuestion: (data) =>
      dispatch(actionCreator.createULMQuizQuestion(data)),
    deleteULMQuestion: (data) =>
      dispatch(actionCreator.deleteULMQuestion(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ULMQuiz);
