import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import * as actionCreator from "../../store/action/index";

const UpdateEmail = (props) => {
  let { token, oldemail } = useParams();


    

  const [token1, setToken] = useState(token);
  const [oldemail1, setOldEmail] = useState(oldemail);
  const { history, resetAdminEmailLink } = props;


  useEffect(() => {
    resetAdminEmailLink({
      token: token1,
      oldemail: oldemail1,
    });
    history.push("/login");
  }, []);

  return <></>;
};

const mapStateToProps = ({ authReducer }) => {
  return {
    loading: authReducer.loading,
    error: authReducer.error,
    errorMessage: authReducer.errorMessage,
    successMessage: authReducer.successMessage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
    resetAdminEmailLink: (data) =>
      dispatch(actionCreator.resetAdminEmailLink(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEmail);
