import MaterialTable from "material-table";
import React, { forwardRef } from "react";

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";

import { Grid, Typography } from "@material-ui/core";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const UserStockList = (props) => {
  const { userStocks } = props;
  const columns = [
    // {
    //   title: "Stock ID",
    //   field: "StockId",
    //   render: (rowData) => rowData?.stockId || "",
    //   editable: false,
    // },
    {
      title: "Stock Name",
      field: "stockName",
      render: (rowdata) => rowdata?.stockName,
      searchable: true,
    },
    {
      title: "Stock Logo",
      field: "stockLogo",
      render: (rowdata) => (
        <img
          src={`${process.env.REACT_APP_SPACE_LINK}/${rowdata?.stockLogo}`}
          style={{ width: 70, height: 70, objectFit: "cover" }}
        />
      ),
      editable: false,
    },

    // {
    //   title: "Stock Category",
    //   field: "stock_category",
    //   render: (rowdata) => rowdata.stock_category?.join(", ") || "No Category",
    // },

    {
      title: "Quantity Owned",
      field: "quantity",
      render: (rowdata) => rowdata?.quantity || "-",
    },

    {
      title: "Average Invested",
      field: "avgInvested",
      render: (rowdata) => rowdata?.avgInvested || "-",
    },

    {
      title: "Stock Price CB75CO25",
      field: "stockPriceInCB75CO25",
      render: (rowdata) => {
        const price = rowdata?.currentPrice?.find(
          (stock) => stock.currencyName === "CB75CO25"
        );
        return price ? price.currentPrice : "-";
      },
    },
    {
      title: "Stock Price CB50CO50",
      field: "stockPriceInCB50CO50",
      render: (rowdata) => {
        const price = rowdata?.currentPrice?.find(
          (stock) => stock.currencyName === "CB50CO50"
        );
        return price ? price.currentPrice : "-";
      },
    },
    {
      title: "Stock Price CB25CO75",
      field: "stockPriceInCB25CO75",
      render: (rowdata) => {
        const price = rowdata?.currentPrice?.find(
          (stock) => stock.currencyName === "CB25CO75"
        );
        return price ? price.currentPrice : "-";
      },
    },
    {
      title: "Stock Price COG100",
      field: "stockPriceInCOG100",
      render: (rowdata) => {
        const price = rowdata?.currentPrice?.find(
          (stock) => stock.currencyName === "COG100"
        );
        return price ? price.currentPrice : "-";
      },
    },
    {
      title: "Stock Price CBC100",
      field: "stockPriceInCBC100",
      render: (rowdata) => {
        const price = rowdata?.currentPrice?.find(
          (stock) => stock.currencyName === "CBC100"
        );
        return price ? price.currentPrice : "-";
      },
    },
  ];
  return (
    <>
      {userStocks ? (
        <MaterialTable
          icons={tableIcons}
          title={"User Portfolio"}
          options={{
            filtering: false,
            toolbar: true,
            sorting: true,
            paging: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 15],
            emptyRowsWhenPaging: false,
            maxBodyHeight: 1450,
            padding: "default",
            search: true,
          }}
          columns={columns}
          data={userStocks}
        />
      ) : (
        <Grid item xs={12} style={{ textAlign: "center", paddingTop: "5%" }}>
          <Typography>No Stocks</Typography>
        </Grid>
      )}
    </>
  );
};
export default UserStockList;
