import React, { useState, useEffect, forwardRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  AccountCircle,
  Clear,
  DeleteOutline,
  Refresh,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import MaterialTable from "material-table";
import SearchBar from "material-ui-search-bar";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import * as actionCreator from "../../store/action/index";
import CustomizedDialog from "./CustomizedDialog";
import Spinner from "../layout/Spinner/Spinner";

import EditAdminProfile1 from "./editAdminProfile1";
import { componentAccess } from "../common/method";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <AccountCircle {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  // detailPanel: {
  // 	padding: theme.spacing(),
  // },
}));

const AdminTable = (props) => {
  const classes = useStyles();
  const {
    loadings,
    admin,
    admins,
    getAllAdmins,
    updateAdmin,
    sendAdminDeleteRequest,
  } = props;
  const [rows, setRows] = useState(null);
  const [searched, setSearched] = useState("");
  const [deactive, setDeactive] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [openDialog, setDialogOpen] = React.useState(false);
  const [rowData, setRowdata] = React.useState(null);

  useEffect(() => {
    if (!admins.length) getAllAdmins({});
    setRows(admins);
  }, [admins, getAllAdmins]);

  const requestSearch = (searchedVal) => {
    if (searchedVal.length == 0) {
      setDeactive(true);
      setRows(admins);
    } else {
      const filteredRows =
        rows?.filter((row) => {
          return row.email.toLowerCase().includes(searchedVal.toLowerCase());
        }) || [];
      setRows(filteredRows);
    }
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
    setRows(admins);
  };

  const activeHandler = () => {
    setDeactive(false);
  };

  const handleClickOpen = (rowData) => {
    setOpen(true);
    setRowdata(rowData);
  };

  const handleMainDialogOpen = (rowData) => {
    setDialogOpen(true);
    setRowdata(rowData);
  };

  const handleAgree = () => {
    sendAdminDeleteRequest({
      email: rowData.email,
      archive: true,
    });
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setDialogOpen(false);
  };

  return (
    <React.Fragment>
      {!admins ? null : (
        <>
          {/* <Grid item>
						<SearchBar
							disabled={deactive}
							value={searched}
							onClick={activeHandler}
							onChange={(searchVal) => requestSearch(searchVal)}
							onCancelSearch={() => cancelSearch()}
							style={{ marginBottom: "0.5rem", width: "20%" }}
						/>
					</Grid> */}

          {!loadings.getAdmins ? (
            <MaterialTable
              icons={tableIcons}
              title="Admins"
              columns={[
                { title: "Email", field: "email" },
                {
                  title: "Level",
                  field: "level",
                  lookup: {
                    1: "Level 1",
                    2: "Level 2",
                    3: "Level 3",
                    4: "Level 4",
                  },
                },
                {
                  title: "Type",
                  field: "type",
                  lookup: {
                    Cogito: "Cogito",
                    Dev: "Dev",
                    "Foundation Investor": "Foundation Investor",
                    Finance: "Finance",
                    Legal: "Legal",
                    "Help Desk": "Help Desk",
                    "Content Creator": "Content Creator",
                  },
                },
                {
                  title: "Admin Status",
                  field: "active",
                  lookup: { false: "Not-Active", true: "Active" },
                },
                {
                  title: "Last Login",
                  field: "activity_time.last_login",
                  type: "datetime",
                  editable: "never",
                },
                {
                  title: "Registration Time",
                  field: "activity_time.registration_time",
                  type: "datetime",
                  editable: "never",
                },
              ]}
              data={rows}
              detailPanel={[
                {
                  tooltip: "Profile Details",
                  render: (rowData) => {
                    return (
                      <Grid
                        container
                        className={classes.root}
                        direction="column"
                        alignItems="center"
                        style={{ padding: "1rem" }}
                      >
                        <Grid item>
                          <EditAdminProfile1 profile={rowData} />
                        </Grid>
                      </Grid>
                    );
                  },
                },
              ]}
              actions={[
                {
                  icon: () => {
                    return <Refresh />;
                  },
                  tooltip: "Refresh Data",
                  isFreeAction: true,
                  onClick: () => getAllAdmins({}),
                },

                (rowData) => ({
                  disabled:
                    rowData.email === admin.email ||
                    rowData.level === 1 ||
                    !componentAccess(
                      admin?.level,
                      admin?.type,
                      "Admins",
                      "deleteAdmin"
                    ),

                  icon: () => <DeleteOutline />,
                  tooltip: "Delete Admin",
                  onClick: (event, rowData) => handleClickOpen(rowData),
                }),

                (rowData) => ({
                  disabled: !componentAccess(
                    admin?.level,
                    admin?.type,
                    "Admins",
                    "editAdminDetails"
                  ),
                  icon: () => <Edit />,
                  tooltip: "Edit Admin",
                  onClick: (event, rowData) => handleMainDialogOpen(rowData),
                }),
              ]}
              options={{
                filtering: false,
                pageSize: 20,
                pageSizeOptions: [10, 20, 50],
                //doubleHorizontalScroll:true,
                emptyRowsWhenPaging: false,
                padding: "dense",
                columnsButton: true,
                search: true,
              }}
            />
          ) : (
            <Spinner />
          )}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {`Do you want to delete ${rowData?.email} as admin?`}
            </DialogTitle>

            <DialogActions>
              <Button
                variant="contained"
                onClick={handleClose}
                style={{
                  backgroundColor: "#359DB6",
                  color: "#FFFFFF",
                }}
              >
                No
              </Button>
              <Button
                variant="contained"
                onClick={handleAgree}
                style={{
                  backgroundColor: "#359DB6",
                  color: "#FFFFFF",
                }}
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          <CustomizedDialog
            open={openDialog}
            data={rowData}
            parentMethod={handleClose}
          />
        </>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    admins: adminReducer.admins,
    admin: adminReducer.admin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllAdmins: (data) => dispatch(actionCreator.getAllAdmins(data)),
    updateAdmin: (data) => dispatch(actionCreator.updateAdmin(data)),
    sendAdminDeleteRequest: (data) =>
      dispatch(actionCreator.sendAdminDeleteRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminTable);
