import React, { useState, useEffect, useRef, forwardRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import Spinner from "../../components/layout/Spinner/Spinner";
import * as actionCreator from "../../store/action/index";
import { connect } from "react-redux";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import CustomizedDialog from "../../pages/dashboard/component/CustomizedKycDialog";
import { API } from "../../asset/api";
import { getWithExpiry } from "../../asset/utility";
import { Box, Grid, MenuItem, Select } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
 detailPanel: {
  padding: theme.spacing(3),
 },
 root: {
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  flexGrow: 1,
  [theme.breakpoints.up("sm")]: {
   paddingLeft: theme.spacing(8) + 1,
  },
  borderRadius: "15px",
  "&.MuiToolbar-root": {
   //color: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
   color: theme.palette.background.default,
  },
  "& .MuiTable-root": {
   "& .MuiTableRow-root": {
    "&:nth-child(even)": {
     background: theme.palette.background.paper,
    },
    "&:nth-child(odd)": {
     background: theme.palette.background.default,
    },
   },
  },
 },
 modal: {},
 customTableButton: {
  display: "flex",
  justifyContent: "flex-end",
  padding: "2px 0px",
 },
 inputStyle: {
  //background: "#eeededd7",
  borderRadius: 8,
  border: 0,
  fontFamily: "Manrope",
 },
}));

const Kyc = (props) => {
 const { notify } = props;

 const classes = useStyles();
 const [loading, setLoading] = useState(false);
 //  const [data, setData] = useState({});
 const [open, setOpen] = useState(null);

 const [openDialog, setDialogOpen] = React.useState(false);
 const [rowData, setRowdata] = React.useState(null);
 const [rowId, setRowId] = useState(null);
 const [pageSize, setPageSize] = useState(10);
 const [totalCount, setTotalCount] = useState(null);
 const [records, setRecords] = useState([]);
 const [search, setSearch] = useState(false);
 const [statusVal, setStatusVal] = useState(null);
 const tableRef = useRef();
 const firstLoad = useRef(true);

 useEffect(() => {
  if (totalCount >= 0) {
   if (![10, 20, 50, 100].includes(pageSize)) {
    setPageSize(totalCount);
   }
  }
 }, [totalCount]);

 useEffect(() => {
  if (!firstLoad.current) {
   tableRef.current.onQueryChange({ page: 0 });
  }
  firstLoad.current = false;
 }, [statusVal]);

 const getFormData = (request) =>
  new Promise((resolve, reject) => {
   let token = getWithExpiry("token");
   let body = {
    page: request.page,
    search: request.search,
    limit: request.pageSize,
    id: "",
    profileId: "",
    status: statusVal,
    docType: "",
   };
   API.post(`/getKyc`, JSON.stringify(body), {
    headers: {
     token: token,
    },
    withCredentials: false,
   })
    .then((res) => {
     if (res.data.status) {
      if (res.data.data != null) {
       let filteredData = res.data.data.filter((item) => item._id == rowId);

       let convertedObject = {};
       filteredData.forEach((item) => {
        let keys = Object.keys(item);
        keys.forEach((key) => {
         convertedObject[key] = item[key];
        });
       });

       resolve({
        data: res?.data?.data,
        page: request.page,
        totalCount: res?.data?.totalCount || 0,
        pageSize: request?.pageSize,
        rowData: convertedObject,
        search: request.search,
       });
      } else {
       resolve({
        data: [],
        page: 0,
        totalCount: 0,
        search: request.search,
       });
      }
     } else {
      reject();
     }
    })
    .catch((err) => {
     setLoading(false);
     reject();
     notify(
      err?.response?.data?.message || "Couldn't fetch users try again",
      "error"
     );
    });
  });

 const columns = [
  {
   title: "User Name",
   field: "userName",
   align: "center",
   searchable: true,
   filtering: false,
  },
  {
   title: "First Name",
   field: "firstName",
   align: "center",
   searchable: true,
   filtering: false,
  },
  {
   title: "Last Name",
   field: "lastName",
   align: "center",
   searchable: true,
   filtering: false,
  },
  {
   title: "Status",
   field: "status",
   align: "center",
   searchable: true,
   filtering: true,
   filterComponent: () => (
    <Select
     style={{ width: "100px" }}
     value={statusVal}
     variant={"standard"}
     onChange={(e) => {
      setStatusVal(e.target.value);
     }}
    >
     <MenuItem key={0} value={null}>
      None
     </MenuItem>
     <MenuItem key={1} value={"underprocess"}>
      Under Process
     </MenuItem>
     <MenuItem key={2} value={"declined"}>
      Declined
     </MenuItem>
     <MenuItem key={3} value={"deleted"}>
      Deleted
     </MenuItem>
     <MenuItem key={4} value={"completed"}>
      Completed
     </MenuItem>
    </Select>
   ),
  },
  {
   title: "Document Type",
   field: "docType",
   align: "center",
   searchable: true,
   filtering: false,
  },
  {
   title: "Number of Docs",
   field: "docs",
   align: "center",
   searchable: true,
   filtering: false,
   render: (rowData) => {
    return rowData?.docs && rowData?.docs?.length;
   },
  },
  {
   title: "Number of Pictures",
   field: "userVerificationPic",
   align: "center",
   searchable: true,
   filtering: false,
   render: (rowData) => {
    return rowData?.userVerificationPic && rowData?.userVerificationPic?.length;
   },
  },
 ];

 const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),

  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
   <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
 };

 const handleClose = () => {
  setOpen(false);
  setDialogOpen(false);
  setRowdata(null);
 };

 const handleMainDialogOpen = (rowData) => {
  setDialogOpen(true);
  setRowdata(rowData);
  setRowId(rowData?._id);
 };

 const newDataCB = (filteredData) => {
  setRowdata(filteredData);
 };

 const updateTable = () => {
  tableRef.current.onQueryChange();
 };

 return (
  <Box className={classes.root}>
   <div className={classes.root1}>
    <Grid>
     <Grid item xs={12}>
      {!loading ? (
       <>
        <MaterialTable
         icons={tableIcons}
         title="Kyc Details"
         columns={columns}
         tableRef={tableRef}
         onChangeRowsPerPage={setPageSize}
         options={{
          pageSize: pageSize,
          debounceInterval: 500,
          pageSizeOptions: !search
           ? [10, 20, 50, 100, { value: totalCount, label: "All" }]
           : [10, 20, 50, 100],
          exportAllData: true,
          exportButton: {
           csv: true,
           pdf: false,
          },
          emptyRowsWhenPaging: false,
          maxBodyHeight: 450,
          columnsButton: true,
          showFirstLastPageButtons: false,
          search: true,
          padding: "dense",
          filtering: true,
          exportCsv: (columns, data) => {
           console.log(columns, data);
           const exportData = data.map((row) => ({
            "User Name": row?.userName,
            "First Name": row?.firstName,
            "Last Name": row?.lastName,
            Status: row?.status,
            "Document Type": row?.docType,
            "Number of Docs":
             row?.docs && row?.docs?.length > 0 ? row?.docs?.length : "-",
            "Number of Pictures":
             row?.userVerificationPic && row?.userVerificationPic?.length > 0
              ? row?.userVerificationPic?.length
              : "-",
           }));

           const csvColumns = columns.map((col) => col.title);

           const csvData = exportData.map((row) =>
            csvColumns.map((col) => row[col])
           );

           csvData.unshift(csvColumns);

           const csvContent = csvData.map((row) => row.join(",")).join("\n");

           const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
           });

           const link = document.createElement("a");
           if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "export.csv");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
           }
          },
         }}
         actions={[
          (rowData) => ({
           icon: () => <Edit />,
           tooltip: "Edit Admin",
           onClick: (event, rowData) => {
            handleMainDialogOpen(rowData);
           },
          }),
         ]}
         data={(query) =>
          getFormData(query)
           .then((result) => {
            if (result?.data) {
             setTotalCount(result.totalCount);
             setRecords(result.data);
             setSearch(result?.search?.length > 0);
             return result;
            }
           })
           .catch((err) => {
            console.log(err);
           })
         }
        />
       </>
      ) : (
       <Spinner />
      )}
     </Grid>

     {openDialog ? (
      <CustomizedDialog
       open={openDialog}
       data={rowData}
       parentMethod={handleClose}
       newDataCB={newDataCB}
       updateTable={updateTable}
      />
     ) : null}
    </Grid>
   </div>
  </Box>
 );
};

const mapStateToProps = ({ adminReducer }) => {
 return {
  admin: adminReducer.admin,
  cryptopiaData: adminReducer.cryptopiaData,
  cryptopiaAgreementCount: adminReducer.cryptopiaAgreementCount,
  crUsersData: adminReducer.crUsersData,
  loadings: adminReducer.loadings,
  currentPage: adminReducer.currentPage,
 };
};

const mapDispatchToProps = (dispatch) => {
 return {
  logout: () => dispatch(actionCreator.OnLogout()),
  notify: (message, varient) =>
   dispatch(actionCreator.notify(message, varient)),
  getCryptopiaAgreementForm: (data) =>
   dispatch(actionCreator.getCryptopiaAgreementForm(data)),
  setCurrentPage: (data) => dispatch(actionCreator.setCurrentPage(data)),
  deleteCryptopiaAgreementForm: (data) =>
   dispatch(actionCreator.deleteCryptopiaAgreementForm(data)),
  getCRUsers: (data) => dispatch(actionCreator.getCRUsers(data)),
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(Kyc);
