import MaterialTable from "material-table";
import React, { forwardRef } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import jsPDF from "jspdf";
import "jspdf-autotable";

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";

import { Grid, Typography } from "@material-ui/core";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const UserStockTxns = (props) => {
  const { userStockTxns, title, setOrderStatus, orderStatus } = props;
  const columns = [
    {
      title: "Timestamp",
      field: "createdAt",
      render: (rowData) => new Date(rowData.createdAt).toLocaleString(),
    },
    { title: "Stock Name", field: "stockName", searchable: true },
    {
      title: "Stock Logo",
      field: "stockLogo",
      render: (rowData) => (
        <img
          src={`${process.env.REACT_APP_SPACE_LINK}/${rowData.stockLogo}`}
          alt="Stock Logo"
          style={{ width: 50 }}
        />
      ),
    },
    {
      title: "Is Buy",
      field: "isBuy",
      render: (rowData) => (rowData.isBuy ? "Yes" : "No"),
    },
    { title: "Order Status", field: "orderStatus" },
    { title: "Order Type", field: "orderType" },
    {
      title: "Buyer/Seller Username",
      field: "userName",
      render: (rowData) => (
        <span
          style={{ width: 50, color: rowData?.isBuy ? "#28A745" : "#DC3545" }}
        >
          {rowData.userName}
        </span>
      ),
    },
    { title: "Executed Quantity", field: "executedQuantity" },
    { title: "Quantity", field: "quantity" },
    { title: "Stock Price", field: "stockPrice" },
    { title: "Wallet Name", field: "walletName", searchable: true },
    {
      title: "Current Value",
      field: "currentValue",
      render: (rowData) => rowData.currentValue ?? "N/A",
    },

    // { title: "Parent Stock Tx Id", field: "parentStockTxId" },
    // { title: "Profile Id", field: "profileId" },

    // { title: "Stock Id", field: "stockId" },

    // { title: "Stock Transaction Id", field: "stockTransactionId" },
    // { title: "Wallet Tx Id", field: "walletTxId" },
  ];
  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: localStorage.getItem("dark") ? "#fff" : "#1a1a1a",
        marginTop: "43px",
      },
    },
  };
  return (
    <>
      <Grid
        item
        xs={12}
        style={{ marginTop: 20, marginRight: "50px", textAlign: "right" }}
      >
        <InputLabel id="account">Order Status</InputLabel>

        <Select
          labelId="orderStatus"
          id="orderStatus"
          style={{ width: 160 }}
          MenuProps={MenuProps}
          value={
            orderStatus
            // txnType === "GetCogTransactions" ?
            //   accountTypeId === 2 ? "GetCogTransactions/2" : "GetCogTransactions/1"
            // : txnType
          }
          onChange={(e) => setOrderStatus(e.target.value)}
        >
          <MenuItem
            style={{ backgroundColor: "#262625" }}
            value={"All"}
            name={2}
          >
            All
          </MenuItem>
          <MenuItem
            style={{ backgroundColor: "#262625" }}
            value="completed"
            name={2}
          >
            completed
          </MenuItem>
          <MenuItem
            style={{ backgroundColor: "#262625" }}
            value="inprogress"
            name={3}
          >
            inprogress
          </MenuItem>
          <MenuItem
            style={{ backgroundColor: "#262625" }}
            value="cancelled"
            name={4}
          >
            cancelled
          </MenuItem>
          <MenuItem
            style={{ backgroundColor: "#262625" }}
            value="partial_cancelled"
            name={5}
          >
            partial_cancelled
          </MenuItem>
          <MenuItem
            style={{ backgroundColor: "#262625" }}
            value="partial_fulfilled"
            name={5}
          >
            partial_fulfilled
          </MenuItem>
        </Select>
      </Grid>

      {userStockTxns ? (
        <MaterialTable
          icons={tableIcons}
          title={title}
          options={{
            filtering: false,
            toolbar: true,
            sorting: true,
            paging: true,
            pageSize: 10,
            pageSizeOptions: [10, 20, 25],
            emptyRowsWhenPaging: false,
            maxBodyHeight: 1450,
            padding: "default",
            search: true,
            exportAllData: true,
            exportButton: true,
            columnsButton: true,
          }}
          columns={columns}
          data={userStockTxns}
          // data={activeTab === 0 ? userStocks : null}
        />
      ) : (
        <Grid item xs={12} style={{ textAlign: "center", paddingTop: "5%" }}>
          <Typography>No Stock Txns</Typography>
        </Grid>
      )}
    </>
  );
};
export default UserStockTxns;
