import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Box, Button, makeStyles, TextField } from "@material-ui/core";
import parse from "html-react-parser";
import PreviewModal from "../../../components/common/PreviewModal";
import * as actionCreator from "../../../store/action/index";
import Spinner from "../../../components/layout/Spinner/Spinner";
import NewsLettersList from "./NewsLettersList";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8) + 1,
    },
    borderRadius: "15px",
    "&.MuiToolbar-root": {
      //color: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
      color: theme.palette.background.default,
    },
    "& .MuiTable-root": {
      "& .MuiTableRow-root": {
        "&:nth-child(even)": {
          background: theme.palette.background.paper,
        },
        "&:nth-child(odd)": {
          background: theme.palette.background.default,
        },
      },
    },
  },
  newsLetterContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonRow: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    margin: "10px 0",
  },
  inputRow: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  previewRow: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "10px 0",
  },
}));

const NewsLetter = (props) => {
  const classes = useStyles();

  const { sendNewsLetter, loadings } = props;

  const [openModal, setOpenModal] = React.useState(false);
  const [template, setTemplate] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [fromAddress, setFromAddress] = React.useState("");

  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleChange = (e) => {
    setTemplate(e.target.value);
  };
  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handleFromAddressChange = (e) => {
    setFromAddress(e.target.value);
  };
  const handleEmails = () => {
    const emailData = {
      template,
      subject,
      fromAddress,
    };
    sendNewsLetter(emailData, setTemplate);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.newsLetterContainer}>
        <Box className={classes.buttonRow}>
          <Button
            disabled={!template || loadings?.newsLetter}
            style={{ backgroundColor: "#359DB6" }}
            onClick={handleEmails}
          >
            Send Email
          </Button>
        </Box>
        <Box className={classes.inputRow}>
          {loadings?.newsLetter ? (
            <>
              <Spinner />
            </>
          ) : (
            <>
              <TextField
                label="From Address"
                variant="outlined"
                style={{
                  width: "100%",
                  marginBottom: "2em",
                }}
                value={fromAddress}
                onChange={handleFromAddressChange}
              />
              <TextField
                label="Subject"
                variant="outlined"
                style={{
                  width: "100%",
                  marginBottom: "2em",
                }}
                value={subject}
                onChange={handleSubjectChange}
              />
              <TextField
                label="Paste Newsletter template here"
                variant="outlined"
                style={{ width: "100%" }}
                multiline
                rows={5}
                rowsMax={12}
                value={template}
                onChange={handleChange}
              />
            </>
          )}
        </Box>
        <Box className={classes.previewRow}>
          <Button
            disabled={!template || loadings?.newsLetter}
            style={{ backgroundColor: "#359DB6" }}
            onClick={handleOpen}
          >
            Preview
          </Button>
          <PreviewModal
            open={openModal}
            title={"Preview Newsletter"}
            handleClose={handleClose}
            element={
              <div
                style={{
                  marginTop: 10,
                  maxHeight: "80vh",
                  overflowY: "scroll",
                }}
              >
                {parse(`${template}`)}
              </div>
            }
          />
        </Box>
      </Box>
      <NewsLettersList />
    </Box>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendNewsLetter: (data, setTemplate) =>
      dispatch(actionCreator.sendNewsLetter(data, setTemplate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsLetter);
