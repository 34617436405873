import React, { useEffect, useRef, useState } from "react";
import { Box, Button, TextField } from "@material-ui/core";
import { Edit, DeleteForeverOutlined } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ReactHtmlParser from 'react-html-parser';
 
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    position: "relative",
  },
  editIconButton: {
    // position: "absolute",
    top: 0,
    right: "1rem",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  closeIconButton: {
    // position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
  },
  iconStyle: {
    color: "#aaa",
  },
  inputFieldStyle: {
    width: "80%",
  },
}));

function InputFieldAnnouncementTemplate(props) {
  const {
    data,
    handleUpdate,
    handleDelete,
    index,
    customContainerStyle,
    isUpdate,
  } = props;

  const classes = useStyles();

  const [editMode, setEditMode] = useState();

  const handleEditMode = () => {
    setEditMode(!editMode);
  };
  return (
    <Box
      className={classes.container}
      style={{
        ...(customContainerStyle || { display: "flex", alignItems: "center" }),
      }}
    >
      {editMode && isUpdate ? (
        <TextField
          value={data.text}
          className={clsx(
            classes.inputFieldStyle,
            "announcemenrtTenplateInput"
          )}
          onChange={(e) => {
            handleUpdate(e.target.value, index);
          }}
          focused={editMode}
          placeholder={data.placeholder}
        />
      ) : (
        <text style={{ ...data.style }}>{ReactHtmlParser(data.text || data.placeholder)}</text>
      )}
      {editMode ? (
        <Button className={classes.closeIconButton}>
          <CloseIcon className={classes.iconStyle} onClick={handleEditMode} />
        </Button>
      ) : (
        <Box className={classes.editIconButton}>
          {handleUpdate && isUpdate ? (
            <Button
              onClick={handleEditMode}
              style={{ maxWidth: 25, minWidth: 0 }}
            >
              <Edit className={classes.iconStyle} />
            </Button>
          ) : null}
          {handleDelete && isUpdate ? (
            <Button
              className={classes.deleteIconButton}
              onClick={() => handleDelete(index)}
              style={{ maxWidth: 25, minWidth: 0 }}
            >
              <DeleteForeverOutlined className={classes.iconStyle} />
            </Button>
          ) : null}
        </Box>
      )}
    </Box>
  );
}
InputFieldAnnouncementTemplate.defaultProps = {
  isUpdate: true,
};
export default InputFieldAnnouncementTemplate;
