import React, { forwardRef, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import * as d3 from "d3";

const useStyles = makeStyles(() => ({
  container: {
    padding: "0.5rem 0.5rem",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  labelContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "1rem 0",
  },
  dataPoint: {
    display: "flex",
    margin: "0 0.5rem",
  },
  colourLabel: {
    height: "1rem",
    width: "1rem",
    marginRight: "0.3rem",
    border: "0.1rem solid #ddd",
  },
  label: {
    color: "#fff",
    fontSize: "0.7rem",
    fontWeight: 600,
  },
}));

// const PieChartProps = {
//   width: 300,
//   height: 300,
//   data: [
//     { name: "Opted", value: 200 },
//     { name: "Not Opted", value: 800 },
//   ],
// };

const colors = [
  "#e0ac2b",
  "#e85252",
  "#6689c6",
  "#9a6fb0",
  "#a53253",
  "#69b3a2",
];
// const colors = [
//     "#6F61C0",
//     "#A084E8",
//     "#8BE8E5",
//     "#D5FFE4",
//   ];

function PieChart(props) {
  const { height, width, data, innerRadius, padAngle } = props;
  const [hover, setHover] = useState(-1);

  // For each arc, use the path generator
  const arcs = React.useMemo(() => {
    const pieGenerator = d3.pie().value((d) => d.value);

    const pie = pieGenerator(data);

    const arcPathGenerator = d3.arc();

    return pie.map((p, index) => {
      const slice = arcPathGenerator({
        innerRadius: innerRadius,
        outerRadius: width / 2,
        startAngle: p.startAngle,
        endAngle: p.endAngle,
        padAngle: padAngle,
      });

      return <path d={slice} fill={colors[index % colors.length]} />;
    });
  }, [data]);

  const classes = useStyles();

  var total = 0;
  (data || []).map((dataPoint) => {
    total += dataPoint.value;
  });

  return (
    <Box className={classes.container}>
      <Box className={classes.labelContainer}>
        {data.map((dataPoint, index) => {
          return (
            <Box className={classes.dataPoint}>
              <Box
                className={classes.colourLabel}
                style={{ backgroundColor: colors[index] }}
              ></Box>
              <Typography
                className={classes.label}
              >{`${dataPoint.name} (${dataPoint.value}${total?` i.e. ${(dataPoint.value*100/total).toFixed(3)}%`:""})`}</Typography>
            </Box>
          );
        })}
      </Box>
      <svg width={width} height={height} style={{ display: "inline-block" }}>
        <g transform={`translate(${width / 2}, ${height / 2})`}>
          {arcs.map((data, index) => (
            <g
              key={index}
              style={{ opacity: hover == -1 || hover == index ? 1 : 0.5 }}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(-1)}
            >
              {data}
            </g>
          ))}
        </g>
      </svg>
    </Box>
  );
}

PieChart.defaultProps = {
  height: 250,
  width: 250,
  data: [],
  innerRadius: 0,
  padAngle: 0.001,
};

export default PieChart;
