import * as React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "50vw",
  backgroundColor: "#303030",
  boxShadow: 24,
  display: "flex",
  justifyContent: "center",
  padding: 4,
};

function PreviewModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper style={style}>
            <Grid item xs={12} container spacing={2}>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <Typography
                  variant="h6"
                  style={{ color: "#359DB6", fontSize: 20 }}
                >
                  {props.title}
                </Typography>
                <Button style={{ position: "absolute", right: 0, top: 5 }}>
                  <CloseIcon onClick={props.handleClose} />
                </Button>
              </Grid>

              <Grid item xs={12} style={{ textAlign: "center", padding: 15 }}>
                {props.element}
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
}

export default PreviewModal;
