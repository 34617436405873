import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import MaterialTable from "material-table";
import moment from "moment";
import { forwardRef, useRef } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { API } from "../../../../asset/api";
import { getWithExpiry, updateObject } from "../../../../asset/utility";
import * as actionCreator from "../../../../store/action/index";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { TablePagination } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Styles from "../../../../components/styles";

const Transaction = (props) => {
  const classes = Styles();
  const theme = useTheme();

  const [activeTransaction, setActiveTransaction] = useState(null);
  const [referenceTransaction, setReferenceTransaction] = useState(null);
  const [txnType, setTxnType] = useState("GetCogTransactions");
  const [accountTypeId, setAccountTypeId] = useState(1);
  const [id, setId] = useState(useParams().id || null);
  const [activeSection, setActiveSection] = useState("TRANSACTION");

  const [cursor, setCursor] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);

  const { notify } = props;
  const getRefTransaction = (id, accountTypeId, transactionId) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      profileId: id,
      accountTypeId: Number(accountTypeId),
      transactionId: transactionId,
    };
    API.post("/GetRefTransaction", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, transaction, errorMessage, successMessage, error } =
          res.data;
        if (status) {
          //updateObjectProperty(activeTransaction,"reference_transaction_details",transaction);
          setReferenceTransaction(transaction);
        } else {
          notify(errorMessage, "error");
        }
      })
      .catch((err) => {
        notify(err?.response?.data?.errorMessage, "error");
      });
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setActiveTransaction(null);
    setReferenceTransaction(null);
  };

  useEffect(() => {
    if (activeSection === "TRANSACTION") {
      setTotalCount(0);
      setCursor([]);
      setPage(0);
      tableRef.current.onQueryChange();
    }
  }, [txnType, accountTypeId]);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <AccountCircle {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const columns = [
    {
      title: "Timestamp",
      field: "time_stamp",
      align: "center",
      type: "datetime",
      searchable: true,
    },
    {
      title: "Username",
      field: "user_name",
      align: "center",
      sorting: false,
      searchable: true,
      hidden: txnType == "GetEuroRebates" ? true : false,
    },
    {
      title: "Credit",
      field: "isCredit",
      align: "center",
      sorting: false,
      searchable: true,
      type: "numeric",
    },
    {
      title: "Debit",
      field: "isDebit",
      align: "center",
      sorting: false,
      searchable: true,
    },
    {
      title: "Current Balance",
      field: "current_balance",
      align: "center",
      sorting: false,
      searchable: true,
      type: "numeric",
    },
  ];

  const setTransactionType = (value) => {
    let valueArr = value.split("/", 2);
    setTxnType(valueArr[0]);
    setAccountTypeId(Number(valueArr[1]));
  };
  const tableRef = useRef();

  const rowClick = (e, rowData) => {
    setActiveTransaction(rowData);
    if (accountTypeId == 1) {
      if (
        rowData.transaction_type_id == 8 ||
        rowData.transaction_type_id == 11
      ) {
        getRefTransaction(id, 3, rowData.transaction_id);
      }
    } else if (accountTypeId == 3) {
      if (
        rowData.reference_transaction_id != undefined &&
        (rowData.transaction_type_id == 1 || rowData.transaction_type_id == 2)
      ) {
        getRefTransaction(id, 1, rowData.reference_transaction_id);
      }
    }
    handleOpen();
  };
  const rowClick1 = (e, rowData) => {
    window.open(`${window.location.origin}/user/${rowData.profile_id}`);
  };

  return (
    <React.Fragment>
      <Grid container className={classes.listroot1}>
        <Grid item xs={11}>
          <InputLabel id="account">Account Type</InputLabel>
          <Select
            labelId="account"
            id="account"
            style={{ width: 160 }}
            value={
              txnType === "GetCogTransactions"
                ? accountTypeId === 2
                  ? "GetCogTransactions/2"
                  : accountTypeId === 1
                  ? "GetCogTransactions/1"
                  : "GetCogTransactions/5"
                : txnType
            }
            onChange={(e) => setTransactionType(e.target.value)}
          >
            <MenuItem value="GetCogTransactions/1" name={1}>
              COG Transaction
            </MenuItem>
            <MenuItem value="GetCogTransactions/2" name={2}>
              COG Investments
            </MenuItem>
            <MenuItem value="GetEuroTransactions">Euro Transactions</MenuItem>
            <MenuItem value="GetEuroRebates">Tax Rebate</MenuItem>
            <MenuItem value="GetCogTransactions/5" name={5}>
              Cash Backed Cog
            </MenuItem>
          </Select>
          <MaterialTable
            tableRef={tableRef}
            icons={tableIcons}
            title="Transactions"
            columns={columns}
            options={{
              exportAllData: true,
              exportButton: true,
              // doubleHorizontalScroll: true,
              emptyRowsWhenPaging: false,
              maxBodyHeight: 450,
              columnsButton: true,
              showFirstLastPageButtons: false,
              search: true,
            }}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                />
              ),
            }}
            data={(query) =>
              new Promise((resolve, reject) => {
                setPage(query.page);
                let body = {
                  data: {
                    profileId: id,
                    cursor: query.page === 0 ? "" : cursor[query.page - 1],
                    sort: query.orderDirection,
                    page_size: query.pageSize,
                    filters: {},
                    start_date: null,
                    end_date: null,
                    account_type_id: accountTypeId,
                  },
                };
                let token = getWithExpiry("token");
                API.post(`/${txnType}`, JSON.stringify(body), {
                  headers: {
                    token: token,
                  },
                  withCredentials: false,
                })
                  .then((res) =>
                    res.data.status === true ? res.data.transactions : {}
                  )
                  .then((res) => {
                    if (res.transaction_data != null) {
                      res.transaction_data.map((transaction) => {
                        transaction.time_stamp = moment(
                          transaction.time_stamp
                        ).format("YYYY-MM-DD HH:mm:ss");

                        setCursor(cursor.concat(res.cursor));

                        if (res.total_count != 0) {
                          setTotalCount(res.total_count);
                        }

                        if (transaction.is_debit === true) {
                          transaction.isDebit = transaction.amount;
                          transaction.isCredit = "-";
                        } else {
                          transaction.isCredit = transaction.amount;
                          transaction.isDebit = "-";
                        }
                        return res.transaction_data;
                      });
                    } else res.transaction_data = [];
                    resolve({
                      data: res.transaction_data,
                      page: query.page > 1 && page == 0 ? 0 : query.page,
                      totalCount:
                        res.total_count > 0 ? res.total_count : totalCount,
                    });
                  })
                  .catch((err) => {
                    notify(
                      err?.response?.data?.errorMessage ||
                        "Failed to Fetch Transaction Data",
                      "error"
                    );
                  });
              })
            }
            onRowClick={(e, rowData) => rowClick(e, rowData)}
          />
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        // aria-labelledby="parent-modal-title"
        // aria-describedby="parent-modal-description"
        className={classes.modal}
        style={{ color: "black" }}
      >
        <DialogTitle
          style={{ backgroundColor: theme.palette.background.default }}
        >
          Transaction Details
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {activeTransaction ? (
              <Grid item container>
                <Grid item xs={6}>
                  Transaction Id
                </Grid>
                <Grid item xs={6}>
                  {activeTransaction?.transaction_id}
                </Grid>
                <Grid item xs={6}>
                  Details
                </Grid>
                <Grid item xs={6}>
                  {activeTransaction?.user_name || "cogito"}
                </Grid>
                <Grid item xs={6}>
                  Date and Time
                </Grid>
                <Grid item xs={6}>
                  {activeTransaction?.time_stamp}
                </Grid>
                <Grid item xs={6}>
                  Reference
                </Grid>
                <Grid item xs={6}>
                  {activeTransaction?.note ? activeTransaction?.note : "-"}
                </Grid>
                <Grid item xs={6}>
                  Amount
                </Grid>
                <Grid item xs={6}>
                  {activeTransaction?.amount}
                </Grid>
                <Grid item xs={6}>
                  Flow
                </Grid>
                <Grid item xs={6}>
                  {activeTransaction?.is_debit ? "Debit" : "Credit"}
                </Grid>
                <Grid item xs={6}>
                  Current Balance
                </Grid>
                <Grid item xs={6}>
                  {activeTransaction?.current_balance}
                </Grid>
              </Grid>
            ) : null}
            {referenceTransaction ? (
              <Grid item container>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginBottom: 10,

                    padding: "1rem 0 1rem 0",
                    width: "100%",
                  }}
                >
                  <strong>
                    Reference{" "}
                    {referenceTransaction?.transaction_type_id == 1
                      ? "Tax"
                      : referenceTransaction?.transaction_type_id == 8
                      ? "Cog"
                      : null}{" "}
                    Transaction Details
                  </strong>
                </Grid>
                <Grid item xs={6}>
                  Transaction Id
                </Grid>
                <Grid item xs={6}>
                  {referenceTransaction?.transaction_id}
                </Grid>
                <Grid item xs={6}>
                  Details
                </Grid>
                <Grid item xs={6}>
                  {referenceTransaction?.user_name}
                </Grid>
                <Grid item xs={6}>
                  Date And Time
                </Grid>
                <Grid item xs={6}>
                  {moment(referenceTransaction?.time_stamp).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </Grid>
                <Grid item xs={6}>
                  Amount
                </Grid>
                <Grid item xs={6}>
                  {referenceTransaction?.amount}
                </Grid>
                <Grid item xs={6}>
                  Flow
                </Grid>
                <Grid item xs={6}>
                  {referenceTransaction?.is_debit ? "Debit" : "Credit"}
                </Grid>
                <Grid item xs={6}>
                  Current Balance
                </Grid>
                <Grid item xs={6}>
                  {referenceTransaction?.current_balance}
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            style={{
              border: "2px solid #359DB6",
              borderRadius: "10px",
              width: "6rem",
              height: "2rem",
              backgroundColor: theme.palette.background.default,
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    admin: adminReducer.admin,

    user: adminReducer.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
    getUser: (data) => dispatch(actionCreator.getUser(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
