import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as actionCreator from "../../store/action/index";
import Spinner from "../../components/layout/Spinner/Spinner";
import opennModal from "../../components/layout/opennModal";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Typography,
  Container,
  Button,
  Paper,
  Divider,
  TextField,
  Input,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import {
  Edit,
  DeleteForeverOutlined,
  CloudUploadOutlined,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import TransitionsModal from "../../components/common/modal";
import { getWithExpiry } from "../../asset/utility";
import { API } from "../../asset/api";
import styles from "../../components/styles";
import NewsLetter from "./system/NewsLetter";
import PocketMoneyTable from "./system/pocketMoneyTasks/PocketMoneyTable";
import TabPanel from "../../components/common/TabPanel";
import ULMQuiz from "./system/userLearningModuleQuizTable/ULMQuiz";
import BusinessActivity from "./system/businessActivity/businessActivity";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8) + 1,
    },
    borderRadius: "15px",
    "&.MuiToolbar-root": {
      //color: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
      color: theme.palette.background.default,
    },
    "& .MuiTable-root": {
      "& .MuiTableRow-root": {
        "&:nth-child(even)": {
          background: theme.palette.background.paper,
        },
        "&:nth-child(odd)": {
          background: theme.palette.background.default,
        },
      },
    },
  },
  paper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3),
    borderRadius: 10,
    marginTop: 10,
    // backgroundColor: "#359Db6",
    boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.5)",
  },
}));
function System(props) {
  const {
    announcements,
    getAdminAnnouncements,
    createAnnouncement,
    removeAnnouncement,
    updateAnnouncement,
    notify,
    loadings,
    getNewsLetters,
    getPocketMoneyTasks,
    getULMQuestions,
  } = props;
  const history = useHistory();
  const [archivedAnn, setArchivedAnn] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [announcementToEdit, setAnnoucementToEdit] = useState(null);
  const [annToEditTitle, setAnnToEditTitle] = useState("");
  const [annToEditDesc, setAnnToEditDesc] = useState("");
  const [annToEditArchived, setAnnToEditArchived] = useState("");
  const [pageNums, setPageNums] = useState([]);
  const [page, setPage] = useState(1);
  const [uploadPic, setUploadPic] = useState(null);
  const [announcementToUploadPic, setAnnouncementToUploadPic] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const classes = styles();

  const ref = useRef();
  let announcementReqData = {
    archived: archivedAnn,
    page: page,
    // limit: 4,
  };

  useEffect(() => {
    getAdminAnnouncements(announcementReqData);
  }, [archivedAnn, page]);

  //Modal functions

  const handleClose = () => {
    setOpenModal(false);
    setAnnoucementToEdit(null);
  };

  //Remove announcement

  const removeAnnouncementModal = (announcementId) => {
    setAnnoucementToEdit(announcementId);
    setModalTitle("Confirm");
    setOpenModal(true);
  };

  const removeAnnouncementCall = () => {
    removeAnnouncement(announcementToEdit);
    setOpenModal(false);
  };

  //Make an archived announement Live

  const makeAnnouncementLive = (announcementId) => {
    setAnnoucementToEdit(announcementId);
    setModalTitle("Make Announcement Live");
    setOpenModal(true);
  };

  const makeAnnouncementLiveCall = () => {
    let data = {
      announcementId: announcementToEdit,
      updates: {
        is_archived: false,
      },
      getArchivedAnn: true,
    };
    updateAnnouncement(data);
    setOpenModal(false);
  };

  // create announcement

  const createAnnouncementModal = () => {
    setModalTitle("Create Announcement");
    setOpenModal(true);
  };

  const onAnnouncementSave = (title, desc, authorName) => {
    let announcement = {
      title: title,
      body: desc,
      author_name: authorName,
      is_archived: false,
    };
    createAnnouncement(announcement);
    setOpenModal(false);
  };

  // Update Announcement

  const onAnnouncementUpdateModal = (annDetails) => {
    let data = annDetails.split("&");
    setAnnoucementToEdit(data[0]);
    setAnnToEditTitle(data[1]);
    setAnnToEditDesc(data[2]);
    setAnnToEditArchived(data[3]);
    setModalTitle("Update Announcement");
    setOpenModal(true);
  };

  const onAnnouncementUpdate = (title, body) => {
    let data = {
      announcementId: announcementToEdit,
      updates: {},
      getArchivedAnn: annToEditArchived === "true" ? true : false,
    };
    if (title != annToEditTitle) {
      data.updates["title"] = title;
    }
    if (body != annToEditDesc) {
      data.updates["body"] = body;
    }
    updateAnnouncement(data);
    setAnnoucementToEdit(null);
    setAnnToEditTitle("");
    setAnnToEditDesc("");
    setAnnToEditArchived("");
    setOpenModal(false);
  };

  // set announcement ID and pic to be uploaded

  const onPicSelect = (pic, announcementId) => {
    if (pic.size > 1000000) {
      notify("The Image should not be more than 1MB", "error");
      ref.current.value = null;
    }
    setUploadPic(pic);
    setAnnouncementToUploadPic(announcementId);
  };

  const onPicDelete = () => {
    ref.current.value = null;
    setUploadPic(null);
    setAnnouncementToUploadPic(null);
  };

  // API call for pic upload

  const uploadSelectedPic = () => {
    return new Promise((resolve, reject) => {
      let storedToken = getWithExpiry("token");

      let data = new FormData();
      data.append("id", announcementToUploadPic);
      data.append("pic", uploadPic);
      data.append("token", storedToken);

      API.post("/uploadAnnouncementPic", data, {
        "Content-Type": "multipart/form-data",
      })
        .then((res) => {
          if (res.status) {
            notify("Pic uploaded successfully", "success");
            onPicDelete();
            getAdminAnnouncements(announcementReqData);
          }
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  };

  const onRemovePic = (announcement_id) => {
    let storedToken = getWithExpiry("token");
    let body = {
      announcement_id: announcement_id,
    };
    API.post("/removeAnnouncementPic", JSON.stringify(body), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, errorMessage, succesMessage } =
          res.data;
        if (status) {
          getAdminAnnouncements(announcementReqData);
          notify("Pic successfully removed", "success");
        }
      })
      .catch((err) => {
        notify(
          err.response.data.message ||
            "Coudn't delete the pic. please try again",
          "error"
        );
      });
  };

  function ModalElement() {
    const [announcementDesc, setAnnouncementDesc] = useState(annToEditDesc);
    const [announcementTitle, setAnnouncementTitle] = useState(annToEditTitle);
    const [announcementAuthor, setAuthorName] = useState();
    switch (modalTitle) {
      case "Make Announcement Live":
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="subtitle1">
                Are you sure you want to make this announcement live ?
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                onClick={() => makeAnnouncementLiveCall()}
                style={{ backgroundColor: "#8a8a8a" }}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        );

      case "Confirm":
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="subtitle1">
                Are you sure you want to delete this announcement ?
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                onClick={() => removeAnnouncementCall()}
                style={{ backgroundColor: "#707070" }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        );

      case "Create Announcement":
        return (
          <Grid container spacing={2} style={{ paddingBottom: "2rem" }}>
            <Grid item xs={12} style={{ textAlign: "center", height: "20%" }}>
              <Input
                type="text"
                fullWidth
                placeholder="Title"
                onChange={(e) => setAnnouncementTitle(e.currentTarget.value)}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center", height: "70%" }}>
              <TextField
                id="filled-multiline-flexible"
                label="Description"
                multiline
                rows={9}
                fullWidth
                onChange={(e) => setAnnouncementDesc(e.currentTarget.value)}
              />
            </Grid>

            <Grid item style={{ height: "20%" }}>
              <Input
                type="text"
                placeholder="Author"
                onChange={(e) => setAuthorName(e.currentTarget.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ textAlign: "right", height: "10%", paddingTop: "1rem" }}
            >
              <Button
                disabled={
                  announcementDesc.length == 0 ||
                  announcementTitle.length == 0 ||
                  (announcementDesc.length == 0 &&
                    announcementTitle.length == 0)
                }
                onClick={() =>
                  onAnnouncementSave(
                    announcementTitle,
                    announcementDesc,
                    announcementAuthor
                  )
                }
                style={{ backgroundColor: "#359Db6" }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        );

      case "Update Announcement":
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Input
                type="text"
                fullWidth
                placeholder="Title"
                value={announcementTitle}
                onChange={(e) => setAnnouncementTitle(e.currentTarget.value)}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <TextField
                id="filled-multiline-flexible"
                label="Description"
                multiline
                rows={9}
                fullWidth
                value={announcementDesc}
                onChange={(e) => setAnnouncementDesc(e.currentTarget.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ textAlign: "right", marginBottom: "1rem" }}
            >
              <Button
                disabled={
                  announcementDesc.length == 0 || announcementTitle.length == 0
                }
                onClick={() =>
                  onAnnouncementUpdate(announcementTitle, announcementDesc)
                }
                style={{ backgroundColor: "#359Db6" }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        );
    }
  }

  useEffect(() => {
    let numOfPages = Math.ceil(announcements?.total_count / 4);
    let pages = [];

    for (let i = 1; i <= numOfPages; i++) {
      pages.push(i);
    }
    setPageNums(pages);
  }, [announcements?.total_count]);

  function ModalElement() {
    const [announcementDesc, setAnnouncementDesc] = useState(annToEditDesc);
    const [announcementTitle, setAnnouncementTitle] = useState(annToEditTitle);
    const [announcementAuthor, setAuthorName] = useState();
    switch (modalTitle) {
      case "Make Announcement Live":
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="subtitle1">
                Are you sure you want to make this announcement live ?
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                onClick={() => makeAnnouncementLiveCall()}
                style={{ backgroundColor: "#8a8a8a" }}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        );

      case "Confirm":
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="subtitle1">
                Are you sure you want to delete this announcement ?
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                onClick={() => removeAnnouncementCall()}
                style={{ backgroundColor: "#707070" }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        );

      case "Create Announcement":
        return (
          <Grid container spacing={2} style={{ paddingBottom: "2rem" }}>
            <Grid item xs={12} style={{ textAlign: "center", height: "20%" }}>
              <Input
                type="text"
                fullWidth
                placeholder="Title"
                onChange={(e) => setAnnouncementTitle(e.currentTarget.value)}
              />
            </Grid>

            <Grid item xs={12} style={{}}>
              <Input
                fullWidth
                type="text"
                placeholder="Author"
                onChange={(e) => setAuthorName(e.currentTarget.value)}
              />
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center", height: "70%" }}>
              <TextField
                id="filled-multiline-flexible"
                label="Description"
                multiline
                rows={9}
                fullWidth
                onChange={(e) => setAnnouncementDesc(e.currentTarget.value)}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{ textAlign: "right", height: "10%", paddingTop: "1rem" }}
            >
              <Button
                disabled={
                  announcementDesc.length == 0 ||
                  announcementTitle.length == 0 ||
                  (announcementDesc.length == 0 &&
                    announcementTitle.length == 0)
                }
                onClick={() =>
                  onAnnouncementSave(
                    announcementTitle,
                    announcementDesc,
                    announcementAuthor
                  )
                }
                style={{ backgroundColor: "#359Db6" }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        );

      case "Update Announcement":
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Input
                type="text"
                fullWidth
                placeholder="Title"
                value={announcementTitle}
                onChange={(e) => setAnnouncementTitle(e.currentTarget.value)}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <TextField
                id="filled-multiline-flexible"
                label="Description"
                multiline
                rows={9}
                fullWidth
                value={announcementDesc}
                onChange={(e) => setAnnouncementDesc(e.currentTarget.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ textAlign: "right", marginBottom: "1rem" }}
            >
              <Button
                disabled={
                  announcementDesc.length == 0 || announcementTitle.length == 0
                }
                onClick={() =>
                  onAnnouncementUpdate(announcementTitle, announcementDesc)
                }
                style={{ backgroundColor: "#359Db6" }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        );
    }
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleActiveTab = (event, value) => {
    setActiveTab(value);
  };

  useEffect(() => {
    let numOfPages = Math.ceil(announcements?.total_count / 4);
    let pages = [];

    for (let i = 1; i <= numOfPages; i++) {
      pages.push(i);
    }
    setPageNums(pages);
  }, [announcements?.total_count]);

  useEffect(() => {
    setPage(1);
  }, [archivedAnn]);

  const onUpdateAnnouncement = (id) => {
    history.push(`/system/updateannouncement/${id}`);
  };
  return (
    <React.Fragment>
      <Grid
        xs={9}
        container
        spacing={1}
        style={{ maxWidth: "90%", margin: "5%  auto auto 7%" }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h5"
            style={{
              color: "#359DB6",
              // textDecoration: "underline",
              fontWeight: "bold",
            }}
            className={"system-nav"}
          >
            <Tabs value={activeTab} onChange={handleActiveTab}>
              <Tab
                label={
                  archivedAnn ? "Archived Announcements" : "Live Announcements"
                }
                {...a11yProps(0)}
              />
              <Tab
                label="News Letter"
                {...a11yProps(1)}
                onClick={() => getNewsLetters()}
              />
              <Tab
                label="Pocket Money Tasks"
                {...a11yProps(2)}
                onClick={() => getPocketMoneyTasks()}
              />
              <Tab
                label="User Learning Module Quiz"
                {...a11yProps(3)}
                onClick={() => getULMQuestions()}
              />
              <Tab
                label="Business Activity"
                {...a11yProps(4)}
                // onClick={() => getULMQuestions()}
              />
            </Tabs>
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <TabPanel value={activeTab} index={0}>
            <Grid item xs={12} style={{ textAlign: "end" }}>
              {!archivedAnn ? (
                <Button
                  style={{ backgroundColor: "#359DB6", color: "white" }}
                  onClick={() => {
                    history.push("/system/createannouncement");
                  }}
                >
                  Create Announcement
                </Button>
              ) : null}
              <Button
                style={{
                  backgroundColor: "#359DB6",
                  color: "white",
                  marginLeft: "10px",
                }}
                onClick={() => setArchivedAnn(!archivedAnn)}
              >
                {archivedAnn ? "Live Announcements" : "Archived Announcements"}
              </Button>
            </Grid>

            {loadings?.announcements ? (
              <Spinner />
            ) : (
              announcements?.announcements?.map((item) => {
                return (
                  <Grid
                    item
                    container
                    xs={12}
                    spacing={1}
                    style={{
                      textAlign: "center",
                      // borderBottom: "1px solid grey",
                      width: "100%",
                    }}
                  >
                    <Grid
                      className={classes.annContainner}
                      xs={12}
                      style={{
                        width: "100%",
                      }}
                    >
                      <Grid item container spacing={1} xs={4}>
                        <Grid
                          style={{
                            width: "90%",
                            marginTop: "20%",
                          }}
                        >
                          {item.pic ? (
                            <>
                              <Grid item xs={12}>
                                <Typography variant="body1">
                                  {item.pic}
                                  <DeleteForeverOutlined
                                    id={item.id}
                                    style={{
                                      color: "#359DB6",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) =>
                                      onRemovePic(e.currentTarget.id)
                                    }
                                  />
                                </Typography>
                              </Grid>
                              {/* <Grid item xs={1} style={{ marginRight: "20px" }}> */}

                              {/* </Grid> */}
                            </>
                          ) : (
                            <>
                              <Grid item xs={12}>
                                <input
                                  style={{ color: "#359DB6" }}
                                  accept="image/png, image/jpeg"
                                  type="file"
                                  name={item.id}
                                  ref={ref}
                                  onChange={(e) =>
                                    onPicSelect(
                                      e.target.files[0],
                                      e.target.name
                                    )
                                  }
                                />
                              </Grid>
                              {uploadPic ? (
                                <Grid item xs={12}>
                                  <CloudUploadOutlined
                                    style={{
                                      color: "#359DB6",
                                      cursor: "pointer",
                                    }}
                                    onClick={uploadSelectedPic}
                                  />
                                  <DeleteForeverOutlined
                                    style={{
                                      color: "#359DB6",
                                      cursor: "pointer",
                                    }}
                                    onClick={onPicDelete}
                                  />
                                </Grid>
                              ) : null}
                            </>
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          flexDirection: "column",
                        }}
                        xs={8}
                      >
                        <Grid item>
                          <Grid item>
                            <Typography
                              variant="body1"
                              style={{
                                marginTop: "1rem",
                                fontSize: "1.2rem",
                                fontWeight: 600,
                              }}
                            >
                              {item.title}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          style={{
                            // wordBreak: "break-all",
                            textAlign: "left",
                            paddingTop: "1rem",
                            height: "20vh",
                            overflowY: "scroll",
                          }}
                        >
                          <Typography variant="body1" style={{}}>
                            {item.body}{" "}
                          </Typography>
                        </Grid>

                        <Grid className={classes.actionContainer}>
                          <Grid item>
                            <Button
                              name={`${item.id}&${item.title}&${item.body}&${item.is_archived}`}
                              onClick={
                                (e) => onUpdateAnnouncement(item.id)
                                // onAnnouncementUpdateModal(e.currentTarget.name)
                              }
                            >
                              <Edit
                                cursor="pointer"
                                style={{
                                  color: "#359DB6",
                                }}
                              />
                            </Button>
                          </Grid>
                          <Grid item>
                            {!item.is_archived ? (
                              <Button
                                name={item.id}
                                onClick={(e) =>
                                  removeAnnouncementModal(e.currentTarget.name)
                                }
                              >
                                <DeleteForeverOutlined
                                  cursor="pointer"
                                  style={{ color: "beige" }}
                                />
                              </Button>
                            ) : (
                              <Button
                                style={{ backgroundColor: "#359DB6" }}
                                name={item.id}
                                onClick={(e) =>
                                  makeAnnouncementLive(e.currentTarget.name)
                                }
                              >
                                Live
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            )}

            {!loadings.announcement && archivedAnn ? (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item container xs={12} spacing={1} justify="center">
                  {pageNums.map((item) => {
                    return (
                      <Grid item>
                        <Button
                          id={item}
                          size="small"
                          disabled={page == Number(item) ? true : false}
                          style={{
                            backgroundColor:
                              Number(item) != page ? "#359DB6" : "grey",
                            borderRadius: "10%",
                          }}
                          onClick={(e) => setPage(Number(e.currentTarget.id))}
                        >
                          {item}
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
              </>
            ) : null}
          </TabPanel>
          <TabPanel
            value={activeTab}
            index={1}
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            <Grid item xs={12} spacing={1} justify="center">
              <NewsLetter />
            </Grid>
          </TabPanel>
          <TabPanel
            value={activeTab}
            index={2}
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            <Grid item xs={12} spacing={1}>
              <PocketMoneyTable />
            </Grid>
          </TabPanel>
          <TabPanel
            value={activeTab}
            index={3}
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            <Grid item xs={12} spacing={1}>
              <ULMQuiz />
            </Grid>
          </TabPanel>
          <TabPanel
            value={activeTab}
            index={4}
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            <Grid item xs={12} spacing={1}>
              <BusinessActivity />
            </Grid>
          </TabPanel>
        </Grid>
        {/* </Grid> */}
        {/* </Paper> */}
      </Grid>

      <TransitionsModal
        open={openModal}
        title={modalTitle}
        handleClose={handleClose}
        element={<ModalElement />}
      />
    </React.Fragment>
  );
}

const mapStateToProps = ({ adminReducer }) => {
  return {
    announcements: adminReducer.announcements,
    loadings: adminReducer.loadings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAdminAnnouncements: (data) =>
      dispatch(actionCreator.getAdminAnnouncements(data)),
    createAnnouncement: (data) =>
      dispatch(actionCreator.createAnnouncement(data)),
    removeAnnouncement: (data) =>
      dispatch(actionCreator.removeAnnouncement(data)),
    updateAnnouncement: (data) =>
      dispatch(actionCreator.updateAnnouncement(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
    getNewsLetters: () => dispatch(actionCreator.getNewsLetters()),
    getPocketMoneyTasks: (data) =>
      dispatch(actionCreator.getPocketMoneyTasks(data)),
    getULMQuestions: (data) => dispatch(actionCreator.getULMQuestions(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(System);
