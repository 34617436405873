import React from "react";
import { Paper } from "@material-ui/core";
import CustomTabs from "./Tabs/index";
import UserDetails from "./userDetails";
import UserActivity from "./userActivity";
import KycDetails from "./kyc";
import Styles from "../../../../components/styles";
import UserBankAccount from "./userBankAccount";
import UserCbcTransfers from "./userCbcTransfers";

function BankAccountInfo() {
 const classes = Styles();
 const headings = ["User Bank Account", "User Cbc Transfers"];
 const contents = [<UserBankAccount />, <UserCbcTransfers />];
 return (
  <>
   {/* <Paper className={classes.paper} elevation={5}> */}
   <CustomTabs headings={headings} contents={contents} />
   {/* </Paper> */}
  </>
 );
}

export default BankAccountInfo;
