import MaterialTable from "material-table";
import React, { useEffect, useState, forwardRef } from "react";
import * as actionCreator from "../../../../store/action/index";
import { connect } from "react-redux";
import {
 Edit,
 DeleteOutline,
 Clear,
 FilterList,
 FirstPage,
 ChevronRight,
} from "@material-ui/icons";
import {
 AccountCircle,
 AddBox,
 ArrowDownward,
 Check,
 ChevronLeft,
 LastPage,
 Remove,
 SaveAlt,
 Search,
 ViewColumn,
} from "@material-ui/icons";

import {
 Button,
 Dialog,
 DialogActions,
 DialogTitle,
 Grid,
 makeStyles,
 useTheme,
 withStyles,
} from "@material-ui/core";
import TaskModal from "./TaskModal";

const CustomButton = withStyles({
 root: {
  borderRadius: 8,
  justifySelf: "right",
  border: 0,
  color: "white",
  fontStyle: "normal",
  background: "#359DB6",
  "&:hover": {
   background: "#62c3db",
  },
 },
 label: {
  textTransform: "capitalize",
 },
 disabled: {
  background: "#8ab0b9",
 },
})((props) => <Button {...props} />);

const useStyles = makeStyles((theme) => ({
 button: {
  margin: "0 0 1% 0",
 },
 container: {
  width: "100%",
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  flexGrow: 1,
  // [theme.breakpoints.up("sm")]: {
  //  paddingLeft: theme.spacing(8) + 1,
  // },
  borderRadius: "15px",
  "&.MuiToolbar-root": {
   color: theme.palette.background.default,
  },
  "& .MuiTable-root": {
   "& .MuiTableRow-root": {
    "&:nth-child(even)": {
     background: theme.palette.background.paper,
    },
    "&:nth-child(odd)": {
     background: theme.palette.background.default,
    },
   },
  },
 },
}));

const tableIcons = {
 Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
 Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
 Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
 Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
 DetailPanel: forwardRef((props, ref) => (
  <AccountCircle {...props} ref={ref} />
 )),
 Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
 Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
 Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
 FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
 LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
 NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
 PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
 ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
 Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
 SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
 ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
 ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const PocketMoneyTable = (props) => {
 const theme = useTheme();
 const classes = useStyles(theme);
 const [open, setOpen] = React.useState(false);
 const [selectedRow, setSelectedRow] = useState({});
 const [modalName, setModalName] = useState("");
 const {
  createPocketMoneyTask,
  deletePocketMoneytasks,
  pMTasks,
  updatePocketMoneytasks,
 } = props;

 //  useEffect(() => {
 //   if (pMTasks?.tasks?.length > 0) {
 //    setRows([
 //     ...pMTasks?.tasks?.map((data) => {
 //      data.isActive = data.isActive === undefined ? true : data.isActive;
 //      return data;
 //     }),
 //    ]);
 //   }
 //  }, []);

 const createTask = (data) => {
  createPocketMoneyTask(data);
 };

 const editTask = (data) => {
  updatePocketMoneytasks({
   id: selectedRow.id,
   taskName: data.taskName,
   reward: data.reward,
   category: data.category,
   frequency: data.frequency,
   isActive: data.taskStatus,
  });
 };

 const handleOpenAddTaskModal = () => {
  setModalName("Add Task");
  setOpen(true);
 };

 const handleOpenDeleteModal = (rowData) => {
  setSelectedRow(rowData);
  setModalName("Delete Task");
  setOpen(true);
 };

 const handleOpenEditModal = (rowData) => {
  setSelectedRow(rowData);
  setModalName("Edit Task");
  setOpen(true);
 };

 const handleClose = () => {
  setOpen(false);
  setModalName("");
  setSelectedRow({});
 };

 const handleDelete = () => {
  deletePocketMoneytasks({ id: selectedRow.id });
 };
 return (
  <>
   <TaskModal
    title={modalName}
    rowData={selectedRow}
    onClose={handleClose}
    createTask={createTask}
    deleteTask={handleDelete}
    editTask={editTask}
    open={open}
   />

   <Grid container>
    <Grid item xs={12} style={{ textAlign: "right" }}>
     <CustomButton className={classes.button} onClick={handleOpenAddTaskModal}>
      Add Task
     </CustomButton>
    </Grid>
    <Grid item xs={12} className={classes.container}>
     {pMTasks?.tasks?.length > 0 ? (
      <MaterialTable
       icons={tableIcons}
       title="Pocket Money Tasks"
       columns={[
        { title: "Task Id", field: "id", filtering: false },
        { title: "Task Name", field: "taskName", filtering: false },
        { title: "Reward", field: "reward", filtering: false },
        { title: "Category", field: "category" },
        { title: "Frequency", field: "frequency" },
        {
         title: "Active",
         field: "isActive",
        },
       ]}
       data={pMTasks?.tasks}
       actions={[
        {
         icon: () => {
          return <Edit />;
         },
         tooltip: "Edit",
         onClick: (event, rowData) => {
          handleOpenEditModal(rowData);
         },
        },
        {
         icon: () => <DeleteOutline />,
         tooltip: "Delete Task",
         onClick: (event, rowData) => handleOpenDeleteModal(rowData),
        },
       ]}
       options={{
        filtering: true,
        search: true,
        sorting: false,
       }}
      />
     ) : null}
    </Grid>
   </Grid>
  </>
 );
};
const mapStateToProps = ({ adminReducer }) => {
 return {
  loadings: adminReducer.loadings,
  pMTasks: adminReducer.pMTasks,
 };
};
const mapDispatchToProps = (dispatch) => {
 return {
  updatePocketMoneytasks: (data) =>
   dispatch(actionCreator.updatePocketMoneytasks(data)),
  createPocketMoneyTask: (data) =>
   dispatch(actionCreator.createPocketMoneyTask(data)),
  deletePocketMoneytasks: (data) =>
   dispatch(actionCreator.deletePocketMoneytasks(data)),
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(PocketMoneyTable);
