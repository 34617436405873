import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { forwardRef } from "react";
import { useParams } from "react-router-dom";
import { getWithExpiry } from "../../../../asset/utility";
import { API } from "../../../../asset/api";
import * as actionCreator from "../../../../store/action/index";

import AddBox from "@material-ui/icons/AddBox";
import Refresh from "@material-ui/icons/Refresh";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Spinner from "../../../../components/layout/Spinner/Spinner";

const useStyles = makeStyles((theme) => ({
 root: {
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  flexGrow: 1,
  borderRadius: "15px",
  "&.MuiToolbar-root": {
   //color: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
   color: theme.palette.background.default,
  },
  "& .MuiTable-root": {
   "& .MuiTableRow-root": {
    "&:nth-child(even)": {
     background: theme.palette.background.paper,
    },
    "&:nth-child(odd)": {
     background: theme.palette.background.default,
    },
   },
  },
  [theme.breakpoints.up("sm")]: {
   paddingLeft: theme.spacing(8) + 1,
  },
 },
 customTableButton: {
  display: "flex",
  justifyContent: "flex-end",
  padding: "2px 0px",
 },
 inputStyle: {
  //background: "#eeededd7",
  borderRadius: 8,
  border: 0,
  fontFamily: "Manrope",
 },
}));

const BusinessPage = (props) => {
 const { notify, getBusinesses, businesses } = props;
 const classes = useStyles();
 const [businessList, setBusinessList] = useState([]);
 const [open, setOpen] = React.useState(false);
 const [rowData, setRowdata] = React.useState(null);
 const [openDialog, setDialogOpen] = React.useState(false);
 const [loading, setLoading] = useState(false);

 useEffect(() => {
  if (businessList.length === 0) {
   getBusinessDirectory();
  }
 }, []);

 let params = useParams();
 useEffect(() => {
  setBusinessList(
   businesses.filter((business) => {
    return business?.business?.profile_id == params?.id;
   })
  );
 }, [businesses]);

 const getBusinessDirectory = () => {
  getBusinesses();
 };

 const handleClickOpen = (rowData) => {
  setOpen(true);
  setRowdata(rowData);
 };

 const handleAgree = () => {
  setOpen(false);
  let business_id = rowData.business.business_id;
  let profile_id = rowData.business.profile_id;
  return new Promise((resolve, reject) => {
   let storedToken = getWithExpiry("token");
   let reqBody = {
    business_id: business_id,
    profile_id: profile_id,
   };
   API.post("/RemoveBusiness", JSON.stringify(reqBody), {
    headers: {
     token: storedToken,
    },
    withCredentials: false,
   })
    .then((res) => {
     resolve(res.data);
     getBusinessDirectory();
     notify(res.data.successMessage, "success");
    })
    .catch((err) => {
     reject(err?.response?.data);
     notify(err.data.errorMessage, "error");
    });
  });
 };

 const handleClose = () => {
  setOpen(false);
  setDialogOpen(false);
 };

 const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
   <AccountCircle {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
   <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
 };

 const columns = [
  {
   title: "Id",
   field: "business.business_id",
   align: "center",
   searchable: true,
  },
  {
   title: "Username",
   field: "user_name",
   align: "center",
   searchable: true,
  },
  {
   title: "Name",
   field: "business.name",
   align: "center",
   searchable: true,
  },
  {
   title: "Email",
   field: "business.email",
   align: "center",
   sorting: false,
   searchable: true,
  },
  {
   title: "Category",
   field: "business.industry_category",
   render: (rowData) =>
    rowData.business.industry_category
     ? rowData.business.industry_category.join(", ")
     : "-",
   align: "center",
   sorting: false,
   searchable: true,
  },
  {
   title: "Website",
   field: "business.website",
   render: (rowData) =>
    rowData.business.website ? rowData.business.website : "-",
   align: "center",
   sorting: false,
   searchable: true,
  },

  {
   title: "Phone Number",
   field: "business.phone",
   align: "center",
   sorting: false,
   searchable: true,
  },
  {
   title: "Location",
   field: "business.address_details.address",
   render: (rowData) =>
    rowData.business.address_details
     ? rowData.business.address_details.address
       ? rowData.business.address_details.address + ", "
       : "" + rowData.business.address_details.city
       ? rowData.business.address_details.city + ", "
       : "" + rowData.business.address_details.state
       ? rowData.business.address_details.state + ", "
       : "" + rowData.business.address_details.country
       ? rowData.business.address_details.country + ", "
       : "" + rowData.business.address_details.pincode
     : "-",
   align: "center",
   sorting: false,
   searchable: true,
  },
 ];

 const tableRef = useRef();

 const rowClick = (e, rowData) => {
  window.open(
   `${window.location.origin}/business/${rowData.business.business_id}/${rowData.business.profile_id}`
  );
 };

 return (
  <Grid
   container
   className={classes.root}
   spacing={3}
   style={{ paddingLeft: "0px" }}
  >
   <Grid item xs={12}>
    {!loading ? (
     <MaterialTable
      icons={tableIcons}
      title="Business Directory"
      columns={columns}
      options={{
       pageSize: 10,
       pageSizeOptions: [10, 25, 50, 100, 200],
       exportAllData: true,
       exportButton: true,
       emptyRowsWhenPaging: false,
       // maxBodyHeight: 450,
       columnsButton: true,
       showFirstLastPageButtons: false,
       search: true,
       padding: "dense",
      }}
      actions={[
       {
        icon: () => {
         return <Refresh />;
        },
        tooltip: "Refresh Data",
        isFreeAction: true,
        onClick: () => getBusinessDirectory(),
       },

       (rowData) => ({
        icon: () => <DeleteOutline />,
        tooltip: "Delete Business",
        onClick: (event, rowData) => handleClickOpen(rowData),
       }),

       // (rowData) => ({
       //   icon: () => <Edit />,
       //   tooltip: "Edit Business",
       //   onClick: (event, rowData) => handleMainDialogOpen(rowData),
       // }),
      ]}
      onRowClick={(e, rowData) => rowClick(e, rowData)}
      data={businessList}
     />
    ) : (
     <Spinner />
    )}

    <Dialog
     open={open}
     onClose={handleClose}
     aria-labelledby="alert-dialog-title"
     aria-describedby="alert-dialog-description"
    >
     <DialogTitle id="alert-dialog-title">
      {`Do you want to delete ${rowData?.business?.name} from business list?`}
     </DialogTitle>

     <DialogActions>
      <Button
       variant="contained"
       onClick={handleClose}
       style={{
        backgroundColor: "#359DB6",
        color: "#FFFFFF",
       }}
      >
       No
      </Button>
      <Button
       variant="contained"
       onClick={handleAgree}
       style={{
        backgroundColor: "#359DB6",
        color: "#FFFFFF",
       }}
       autoFocus
      >
       Yes
      </Button>
     </DialogActions>
    </Dialog>
   </Grid>
  </Grid>
 );
};

const mapStateToProps = ({ adminReducer }) => {
 return {
  businesses: adminReducer.businesses,
 };
};
const mapDispatchToProps = (dispatch) => {
 return {
  notify: (message, varient) =>
   dispatch(actionCreator.notify(message, varient)),
  getBusinesses: () => dispatch(actionCreator.getBusinesses()),
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPage);
