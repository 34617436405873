import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography, Divider } from "@material-ui/core";
import Styles from "../../../../components/styles";
import { connect } from "react-redux";
import { Modal, Backdrop, Fade } from "@material-ui/core/";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import * as actionCreator from "../../../../store/action/index";
import { kycDateFormat } from "../../../../utils/timeformats/sessionFormat";
import { capitalizeFirstLetter } from "../../../../utils/numberFormattor/currencyFormator";
import { element } from "prop-types";
const Kyc = (props) => {
  const classes = Styles();
  const theme = useTheme();

  const {
    getUserKycData,
    // getKycDocsData,
    // getKycImageData,
    // getKycImageDataS,
    // getKycImageDataB,
    notify,
    user,
    kycData,
    kycDocsData,
    kycImageDataS,
    kycImageDataB,
    kycImageData,
  } = props;

  console.log({ kycData });
  const [activeSection, setActiveSection] = useState("USER");
  //  const [showImage, setShowImage] = useState(true);
  //  const [showImage1, setShowImage1] = useState(true);
  const [mOpen, setMOpen] = useState(false);
  const [nOpen, setNOpen] = useState(false);
  const [bOpen, setBOpen] = useState(false);

  const mHandleClose = () => {
    setMOpen(false);
  };

  const bHandleClose = () => {
    setBOpen(false);
  };
  const nHandleClose = () => {
    setNOpen(false);
  };

  const getUserKycInfo = () => {
    console.log({ user });
    if (user?.kyc?.status === false) {
      if (kycData) {
        // show info
      } else {
        notify("User didn't initiate his kyc", "info");
      }
    } else {
      if (user?.kyc?.status == true && user?.kyc?.id && kycData === null) {
        notify("User has not started re-KYC procedure", "info");
      } else if (
        user?.kyc?.status == true &&
        user?.kyc?.id &&
        kycData !== null
      ) {
        // show info
      } else {
        console.log("...");
      }
    }
  };

  useEffect(() => {
    getUserKycInfo();
  }, []);

  const [openDocModalIndex, setOpenDocModalIndex] = useState(null);
  const [openModalIndex, setOpenModalIndex] = useState(null);

  const handleDocModalOpen = (index) => {
    setOpenDocModalIndex(index);
  };

  const handleDocModalClose = () => {
    setOpenDocModalIndex(null);
  };

  const handleOpen = (index) => {
    setOpenModalIndex(index);
  };

  const handleClose = () => {
    setOpenModalIndex(null);
  };

  const getFileType = (url) => {
    const fileExtension = url.split(".").pop().toLowerCase();
    return fileExtension;
  };

  //  const getUserKycInfo = () => {
  //   if (user?.kyc?.status === false) {
  //    notify("User didn't initiate his kyc", "info");
  //   } else {
  //    if (user?.kyc?.status == true && user?.kyc?.id != null) {
  //     let kycId = user?.kyc?.id;
  //     getKycData(kycId);
  //    } else if (user?.kyc?.status == true && user?.kyc?.id == null) {
  //     notify("User kyc incomplete", "info");
  //    } else {
  //     console.log("...");
  //    }
  //   }
  //  };

  //  useEffect(() => {
  //   let kycId = user?.kyc?.id;
  //   if (kycData) {
  //    getKycDocsData(kycId);
  //   }
  //  }, [kycData]);

  //  useEffect(() => {
  //   if (kycDocsData?.IDENTITY?.imageIds) {
  //    if (kycDocsData?.IDENTITY?.imageIds?.length > 1) {
  //     let inspection_id = kycData?.inspectionId;
  //     const data = { image: kycDocsData?.IDENTITY?.imageIds[0], inspection_id };
  //     getKycImageData(data);

  //     const data1 = { image: kycDocsData?.IDENTITY.imageIds[1], inspection_id };
  //     getKycImageDataB(data1);
  //    } else {
  //     let inspection_id = kycData?.inspectionId;
  //     const data3 = { image: kycDocsData?.IDENTITY?.imageIds[0], inspection_id };
  //     getKycImageData(data3);
  //    }
  //   }

  //   if (kycDocsData?.SELFIE?.imageIds) {
  //    let inspection_id = kycData?.inspectionId;
  //    const dataS = {
  //     image: kycDocsData?.SELFIE?.imageIds[0],
  //     inspection_id: inspection_id,
  //    };
  //    getKycImageDataS(dataS);
  //   }
  //  }, [kycDocsData]);

  //  useEffect(() => {
  //   if (kycImageData || kycImageDataB) {
  //    setShowImage(true);
  //   }
  //  }, [kycImageData, kycImageDataB]);

  //  useEffect(() => {
  //   if (kycImageDataS) {
  //    setShowImage1(true);
  //   }
  //  }, [kycImageDataS]);

  return (
    <React.Fragment>
      {kycData ? (
        <Grid item container id="kyc">
          <Grid item container xs={12} alignItems="center" spacing={2}>
            <Grid
              item
              container
              xs={12}
              className={classes.resultContainer}
              style={{ padding: "2rem" }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  container
                  xs={12}
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h5" align="left">
                      {`${user?.firstName}'s KYC Info`}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: 10, marginBottom: 10 }}
                  >
                    <Divider />
                  </Grid>
                </Grid>
                <Grid item container xs={12} alignItems="center" spacing={2}>
                  <>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="h6" align="left">
                        Kyc Id
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        align="left"
                      >
                        {kycData?._id}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Typography variant="h6" align="left">
                        Kyc Status
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        align="left"
                        style={{
                          color:
                            kycData?.status === "completed"
                              ? "green"
                              : kycData?.status === "underprocess"
                              ? "#ff7300"
                              : "red",
                        }}
                      >
                        {capitalizeFirstLetter(kycData?.status)}
                      </Typography>
                    </Grid>

                    {kycData?.note && (
                      <>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="h6" align="left">
                            Note
                          </Typography>
                        </Grid>
                        <Grid container item xs={12} sm={8}>
                          <Grid item xs={12}>
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              align="left"
                            >
                              {kycData?.note}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} sm={4}>
                      <Typography variant="h6" align="left">
                        DocType
                      </Typography>
                    </Grid>
                    <Grid container item xs={12} sm={8}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          align="left"
                        >
                          {kycData?.docType}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Typography variant="h6" align="left">
                        User Id
                      </Typography>
                    </Grid>
                    <Grid container item xs={12} sm={8}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          align="left"
                        >
                          {kycData?.profileId}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="h6" align="left">
                        Kyc Created At
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        align="left"
                      >
                        {kycDateFormat(kycData?.date)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="h6" align="left">
                        First Name
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        align="left"
                      >
                        {kycData?.firstName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="h6" align="left">
                        Last Name
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        align="left"
                      >
                        {kycData?.lastName}
                      </Typography>
                    </Grid>

                    {/* User Uploaded Docs */}
                    <>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h6" align="left">
                          User Docs
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={8}>
                        {kycData?.docs?.map((element, index) => {
                          const fileType = getFileType(element.url);
                          const file = `${process.env.REACT_APP_SPACE_LINK}/${element.url}`;

                          return (
                            <React.Fragment>
                              {fileType === "pdf" ? (
                                <>
                                  <iframe
                                    src={file}
                                    title="user_uploaded_doc"
                                    width="100%"
                                    height="400"
                                    style={{ border: "none" }}
                                  ></iframe>
                                </>
                              ) : (
                                <>
                                  <button
                                    style={{
                                      padding: 0,
                                      cursor: "pointer",
                                      marginRight: "0.5rem",
                                    }}
                                    onClick={() => handleDocModalOpen(index)}
                                  >
                                    {
                                      <img
                                        width="150px"
                                        height="100px"
                                        src={`${file}`}
                                      />
                                    }
                                  </button>
                                  <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    className={classes.modal}
                                    open={openDocModalIndex == index}
                                    onClose={() => handleDocModalClose()}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                      timeout: 500,
                                    }}
                                  >
                                    <Fade in={openDocModalIndex === index}>
                                      <div className={classes.paper1}>
                                        {
                                          <img
                                            width="500px"
                                            height="500px"
                                            src={`${file}`}
                                          />
                                        }
                                      </div>
                                    </Fade>
                                  </Modal>
                                </>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </Grid>
                    </>

                    {/* user Pics */}
                    <>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h6" align="left">
                          User Pics
                        </Typography>
                      </Grid>

                      {/* {console.log(
                        0,
                        `https://cogito.sgp1.cdn.digitaloceanspaces.com/${kycData?.userVerificationPic[0]?.url}`
                      )} */}
                      <Grid xs={12} sm={8} style={{ padding: "8px" }}>
                        {kycData?.userVerificationPic.map((element, index) => {
                          return (
                            <Grid
                              key={index}
                              container
                              item
                              xs={12}
                              style={{
                                margin: "1rem 0",
                              }}
                            >
                              <Grid item xs={12} sm={4}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  align="left"
                                >
                                  <button
                                    style={{ padding: 0, cursor: "pointer" }}
                                    onClick={() => handleOpen(index)}
                                  >
                                    <img
                                      width="150px"
                                      height="100px"
                                      src={`${process.env.REACT_APP_SPACE_LINK}/${element.url}`}
                                      // src={`https://cogito.sgp1.cdn.digitaloceanspaces.com/${element?.url}`}
                                      alt="Verification"
                                    />
                                  </button>
                                </Typography>
                              </Grid>

                              <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                className={classes.modal}
                                open={openModalIndex === index}
                                onClose={handleClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                  timeout: 500,
                                }}
                              >
                                <Fade in={openModalIndex === index}>
                                  <div className={classes.paper1}>
                                    <img
                                      width="500px"
                                      height="500px"
                                      src={`${process.env.REACT_APP_SPACE_LINK}/${element.url}`}
                                      // src={`https://cogito.sgp1.cdn.digitaloceanspaces.com/${element?.url}`}
                                      alt="Verification"
                                    />
                                  </div>
                                </Fade>
                              </Modal>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </>
                  </>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            // background: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Typography variant="h6" align="center">
            No Kyc Found
          </Typography>
        </Grid>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    user: adminReducer.user,
    kycData: adminReducer.kycData ? adminReducer.kycData[0] : null,
    kycDocsData: adminReducer.kycDocsData,
    kycImageData: adminReducer.kycImageData,
    kycImageDataB: adminReducer.kycImageDataB,
    kycImageDataS: adminReducer.kycImageDataS,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
    getAdmin: () => dispatch(actionCreator.getAdmin()),
    getUser: (data) => dispatch(actionCreator.getUser(data)),
    getUserKycData: (data) => dispatch(actionCreator.getUserKycData(data)),
    // getKycImageData: (data) => dispatch(actionCreator.getKycImageData(data)),
    // getKycImageDataB: (data) => dispatch(actionCreator.getKycImageDataB(data)),
    // getKycImageDataS: (data) => dispatch(actionCreator.getKycImageDataS(data)),
    // getKycDocsData: (data) => dispatch(actionCreator.getKycDocsData(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Kyc);
