import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";

import AdminTable from "../../components/auth/adminTable";
import RequestAdmin from "../../components/auth/requestAdmin";

import * as actionCreator from "../../store/action/index";

const useStyles = makeStyles((theme) => ({
 root: {
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  flexGrow: 1,
  [theme.breakpoints.up("sm")]: {
   paddingLeft: theme.spacing(8) + 1,
  },
  borderRadius: "15px",
  "&.MuiToolbar-root": {
   //color: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
   color: theme.palette.background.default,
  },
  "& .MuiTable-root": {
   "& .MuiTableRow-root": {
    "&:nth-child(even)": {
     background: theme.palette.background.paper,
    },
    "&:nth-child(odd)": {
     background: theme.palette.background.default,
    },
   },
  },
 },
 customTableButton: {
  display: "flex",
  justifyContent: "flex-end",
  padding: "2px 0px",
 },
 inputStyle: {
  //background: "#eeededd7",
  borderRadius: 8,
  border: 0,
  fontFamily: "Manrope",
 },
}));

const Admins = (props) => {
 const classes = useStyles();
 const { loadings, ResetErrors } = props;

 useEffect(() => {
  return () => {
   ResetErrors();
  };
 }, [ResetErrors]);

 return (
  <React.Fragment>
   <Grid
    container
    className={classes.root}
    spacing={1}
    style={{ maxWidth: "100%" }}
   >
    <Grid item xs={12}>
     <RequestAdmin />
    </Grid>

    <Grid item xs={12}>
     <AdminTable />
    </Grid>
   </Grid>
  </React.Fragment>
 );
};
const mapStateToProps = ({ adminReducer }) => {
 return {
  loadings: adminReducer.loadings,
 };
};
const mapDispatchToProps = (dispatch) => {
 return {
  ResetErrors: () => dispatch(actionCreator.ResetErrors()),
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admins);
