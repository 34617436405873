import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { useDispatch } from "react-redux";
import { uploadStockDoc, getStockDoc, notify } from "../../store/action";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress"; // Import loader
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: "100%",
    maxWidth: "960px",
    backgroundColor: "rgba(255, 255, 255, 0.1)", // Slight transparent background for glass effect
    color: "white",
    padding: "20px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    borderRadius: "12px",
    backdropFilter: "blur(10px)", // Glassmorphism blur effect
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", // Subtle shadow
    border: "1px solid rgba(255, 255, 255, 0.2)", // Light border
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    width: "90px",
    height: "90px",
    backgroundColor: "rgba(255, 255, 255, 0.25)", // Semi-transparent for glass effect
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "20px",
    border: "2px solid rgba(255, 255, 255, 0.2)", // Light border
    backdropFilter: "blur(10px)", // Blur for the avatar as well
  },
  avatarInitials: {
    color: "white",
    fontSize: "24px",
    fontWeight: "bold",
    height: "60px",
    width: "60px",
    borderRadius: "50%",
  },
  businessInfo: {
    display: "flex",
    flexDirection: "column",
  },
  businessName: {
    fontSize: "28px",
    fontWeight: "bold",
    margin: 0,
  },
  introSection: {
    marginTop: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.1)", // Glassmorphism background for section
    padding: "20px",
    borderRadius: "12px",
    backdropFilter: "blur(10px)",
    border: "1px solid rgba(255, 255, 255, 0.2)",
  },
  introHeading: {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
  },
  introText: {
    fontSize: "18px",
    lineHeight: "1.6",
    marginTop: "15px",
    color: "#eee", // Light text for visibility
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    marginTop: "30px",
  },
  tablesGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "20px",
    flex: 1,
  },
  categoryItem: {
    backgroundColor: "rgba(255, 255, 255, 0.1)", // Glassmorphism effect for tables
    color: "white",
    padding: "20px",
    borderRadius: "12px",
    minHeight: "150px",
    textAlign: "center",
    backdropFilter: "blur(10px)", // Blur effect
    border: "1px solid rgba(255, 255, 255, 0.2)",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  },
  categoryTable: {
    width: "100%",
    color: "white",
    borderSpacing: "0",
    "& th, td": {
      padding: "10px",
      borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
    },
    "& th": {
      fontWeight: "bold",
      textAlign: "left",
    },
    "& td": {
      textAlign: "left",
    },
  },
  librarySection: {
    flex: "0 0 300px",
    backgroundColor: "rgba(255, 255, 255, 0.1)", // Glassmorphism effect
    color: "white",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "12px",
    backdropFilter: "blur(10px)", // Glass effect for the library section
    border: "1px solid rgba(255, 255, 255, 0.2)",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    overflowY: "auto",
  },
  libraryHeading: {
    fontSize: "22px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  documentList: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
    flexGrow: 1,
    overflowY: "auto",
  },
  documentItem: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0",
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)", // Light border for glass effect
  },
  documentIcon: {
    width: "30px",
    height: "30px",
    backgroundColor: "rgba(255, 255, 255, 0.3)", // Semi-transparent background
    borderRadius: "4px",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  documentDetails: {
    display: "flex",
    flexDirection: "column",
    flex: 1, // Allow the details section to grow and take up remaining space
    overflow: "hidden", // Hide any overflow beyond the container width
    whiteSpace: "normal", // Ensure that the text wraps onto the next line
    wordBreak: "break-word", // Break long words if necessary
  },
  documentName: {
    fontSize: "16px",
    fontWeight: "bold",
    wordWrap: "break-word", // Ensures long words wrap within the container
    overflow: "hidden", // Hide any overflow content
    textOverflow: "ellipsis", // Optionally, add ellipsis for overflowed text
  },
  documentDate: {
    fontSize: "14px",
    color: "#ccc", // Light text for glass effect
  },
  addButton: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "white",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginBottom: "10px",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.4)",
    },
  },
}));

const HeaderComponent = ({
  editable,
  headerContent,
  onHeaderContentChange,
  backgroundColor,
  stocks,
  stockCategories,
  stockOrderbook,
  stockDocs,
  rowdata,
}) => {
  const classes = useStyles();
  const [tableHeight, setTableHeight] = useState(0);
  const [loading, setLoading] = useState(false); // Loader state

  const tableGridRef = useRef(null);
  const fileInputRef = useRef(null);

  const dispatch = useDispatch(); // Use useDispatch to trigger actions

  useEffect(() => {
    if (tableGridRef.current) {
      // Calculate height of the two tables dynamically
      setTableHeight(tableGridRef.current.offsetHeight);
    }
  }, []);

  const handleTextChange = (e, key) => {
    onHeaderContentChange(key, e.target.innerText);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && rowdata?.stockId) {
      setLoading(true); // Start loader
      dispatch(uploadStockDoc(file, rowdata.stockId))
        .then((res) => {
          console.log("Document uploaded successfully:", res);
          dispatch(getStockDoc({ stockId: rowdata.stockId })); // Fetch updated stockDocs
          dispatch(notify("Document uploaded successfully", "success")); // Correctly dispatch notification
        })
        .catch((err) => {
          console.error("Error uploading document:", err);
          dispatch(notify("Error uploading document", "error"));
        })
        .finally(() => {
          setLoading(false); // Stop loader after API call
        });
    } else {
      console.error("Stock ID is missing or no file selected.");
    }
  };

  const stockPrices = stocks?.[0]?.stockPrice || [];

  return (
    <div className={classes.headerContainer} style={{ backgroundColor }}>
      <div className={classes.headerLeft}>
        {/* Avatar */}
        <div className={classes.avatar}>
          <img
            src={headerContent.businessLogoSrc}
            alt="Logo"
            className={classes.avatarInitials}
          />
        </div>
        {/* Business Name */}
        <div className={classes.businessInfo}>
          <h1
            className={classes.businessName}
            contentEditable={editable}
            suppressContentEditableWarning={true}
            onBlur={(e) => handleTextChange(e, "businessName")}
          >
            {headerContent.businessName}
          </h1>
        </div>
      </div>

      {/* Business Introduction Section */}
      <div className={classes.introSection}>
        <h2
          className={classes.introHeading}
          contentEditable={editable}
          suppressContentEditableWarning={true}
          onBlur={(e) => handleTextChange(e, "businessIntroTitle")}
        >
          {headerContent.businessIntroTitle}
        </h2>
        <p
          className={classes.introText}
          contentEditable={editable}
          suppressContentEditableWarning={true}
          onBlur={(e) => handleTextChange(e, "businessIntroText")}
        >
          {headerContent.businessIntroText}
        </p>
      </div>

      {/* Flex container for Tables and Library */}
      <div className={classes.contentContainer}>
        {/* Tables Grid */}
        <div className={classes.tablesGrid} ref={tableGridRef}>
          {/* First Table: Stock Prices */}
          <div className={classes.categoryItem}>
            <table className={classes.categoryTable}>
              <thead>
                <tr>
                  <th>Currency Name</th>
                  <th>Current Price</th>
                </tr>
              </thead>
              <tbody>
                {stockPrices.map((price, index) => (
                  <tr key={index}>
                    <td>{price.currencyName}</td>
                    <td>€ {price.currentPrice}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Second Table: Categories */}
          <div className={classes.categoryItem}>
            <table className={classes.categoryTable}>
              <thead>
                <tr>
                  <th>Categories</th>
                </tr>
              </thead>
              <tbody>
                {stockCategories?.map((category, index) => (
                  <tr key={index}>
                    <td>{category.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Third Table: Order Book */}
          <div className={classes.categoryItem}>
            <table className={classes.categoryTable}>
              <thead>
                <tr>
                  <th colSpan="2">Order Book</th>
                </tr>
              </thead>
              <tbody>
                {/* Buy Section */}
                <tr>
                  <td colSpan="2" style={{ fontWeight: "bold" }}>
                    Buy Orders
                  </td>
                </tr>
                <tr>
                  <td>Quantity:</td>
                  <td>{stockOrderbook?.[0]?.buyQuantity || 0}</td>
                </tr>
                <tr>
                  <td>Value:</td>
                  <td>€ {stockOrderbook?.[0]?.buyValue?.toFixed(2) || 0}</td>
                </tr>

                {/* Sell Section */}
                <tr>
                  <td colSpan="2" style={{ fontWeight: "bold" }}>
                    Sell Orders
                  </td>
                </tr>
                <tr>
                  <td>Quantity:</td>
                  <td>{stockOrderbook?.[0]?.sellQuantity || 0}</td>
                </tr>
                <tr>
                  <td>Value:</td>
                  <td>€ {stockOrderbook?.[0]?.sellValue?.toFixed(2) || 0}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Fourth Table: Quantity Information */}
          <div className={classes.categoryItem}>
            <table className={classes.categoryTable}>
              <tbody>
                <tr>
                  <td>Total Quantity: {stocks?.[0]?.totalQuantity}</td>
                </tr>
                <tr>
                  <td>Assigned Quantity: {stocks?.[0]?.assignedQuantity}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Library Section */}
        <div className={classes.librarySection} style={{ height: tableHeight }}>
          {/* "+" Add Button */}
          {/* <div
            className={classes.addButton}
            onClick={() => fileInputRef.current.click()}
          >
            <AddIcon />
          </div> */}

          {/* Hidden file input */}
          {/* <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          /> */}

          <h3 className={classes.libraryHeading}>Library</h3>
          {loading ? (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          ) : (
            <ul className={classes.documentList}>
              {stockDocs?.length ? (
                stockDocs
                  ?.filter((doc, index) => {
                    const extension = doc?.name?.split(".").pop().toLowerCase();
                    return ![
                      "jpg",
                      "jpeg",
                      "png",
                      "gif",
                      "bmp",
                      "svg",
                      "webp",
                      "mp4",
                      "mov",
                      "avi",
                      "mkv",
                      "mp3",
                      "wav",
                      "flac",
                    ].includes(extension);
                  })
                  ?.map((doc) => (
                    <li key={doc._id} className={classes.documentItem}>
                      <div className={classes.documentIcon}>
                        <InsertDriveFileIcon style={{ color: "white" }} />
                      </div>
                      <div className={classes.documentDetails}>
                        <span className={classes.documentName}>{doc.name}</span>
                      </div>
                    </li>
                  ))
              ) : (
                <Typography>No Docs Found</Typography>
              )}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
