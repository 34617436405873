import React from "react";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Divider } from "@material-ui/core";
import * as actionCreator from "../../../../store/action/index";
import Styles from "../../../../components/styles";

const Nodes = (props) => {
	const classes = Styles();
	const theme = useTheme();
	const { user } = props;

	return (
		<React.Fragment>
			<Grid item sm={12} md={8} id="nodes">
				<Paper className={classes.paper} elevation={5}>
					<Grid container spacing={2}>
						<Grid item container xs={12} justify="space-between" alignItems="center">
							<Grid item>
								<Typography variant="h5" align="left">
									Nodes
								</Typography>
							</Grid>
							<Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
								<Divider />
							</Grid>
						</Grid>
						{user.nodes &&
							user.nodes?.map((node) => {
								return (
									<Grid item container xs={12} alignItems="center" spacing={2}>
										<Grid item xs={12} sm={4}>
											<Typography variant="h6" align="left">
												Node ID
											</Typography>
										</Grid>
										<Grid item xs={12} sm={8}>
											<Typography variant="body2" color="textSecondary" align="left">
												{node._id}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={4}>
											<Typography variant="h6" align="left">
												Referral Code
											</Typography>
										</Grid>
										<Grid item xs={12} sm={8}>
											<Typography variant="body2" color="textSecondary" align="left">
												{node.referral_code}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={4}>
											<Typography variant="h6" align="left">
												Node Type
											</Typography>
										</Grid>
										<Grid item xs={12} sm={8}>
											<Typography variant="body2" color="textSecondary" align="left">
												{node.node_type}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={4}>
											<Typography variant="h6" align="left">
												Sponsored By
											</Typography>
										</Grid>
										<Grid item xs={12} sm={8}>
											<Typography variant="body2" color="textSecondary" align="left">
												{node.sponsored_by}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={4}>
											<Typography variant="h6" align="left">
												Sponsored List ID
											</Typography>
										</Grid>
										<Grid item xs={12} sm={8}>
											<Typography variant="body2" color="textSecondary" align="left">
												{node.sponsored_list_id}
											</Typography>
										</Grid>
									</Grid>
								);
							})}
					</Grid>
				</Paper>
			</Grid>
		</React.Fragment>
	);
};
const mapStateToProps = ({ adminReducer }) => {
	return {
		loadings: adminReducer.loadings,
		user: adminReducer.user,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getUser: (data) => dispatch(actionCreator.getUser(data)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Nodes);
