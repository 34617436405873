import {
  Grid,
  Typography,
  makeStyles,
  useTheme,
  Button,
} from "@material-ui/core";
import {
  AccountCircle,
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import MaterialTable from "material-table";
import React, { forwardRef, useReducer, useState } from "react";
import * as actionCreator from "../../../../store/action/index";
import { connect } from "react-redux";
import { useEffect } from "react";
import NoteAddIcon from "@material-ui/icons/NoteAdd";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <AccountCircle {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    marginTop: theme.spacing(1),
    flexGrow: 1,
    // padding: "1% 0% 2% 3.6%",
    // [theme.breakpoints.up("sm")]: {
    //   paddingLeft: theme.spacing(8) + 1,
    // },
    borderRadius: "15px",
    "&.MuiToolbar-root": {
      color: theme.palette.background.default,
    },
    "& .MuiTable-root": {
      "& .MuiTableRow-root": {
        "&:nth-child(even)": {
          background: theme.palette.background.paper,
        },
        "&:nth-child(odd)": {
          background: theme.palette.background.default,
        },
      },
    },
  },
}));

const UserCBCApproval = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const {
    user,
    getcbcbanktransfers,
    approveCbcTransfer,
    declineCbcTransfer,
    markSellAsCompleted,
    cbcTransfers,
    bankAccount,
  } = props;

  const [rowData, setRowData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    getcbcbanktransfers(user?._id);
  }, []);

  return (
    <>
      <div style={{ maxWidth: "100%" }}>
        <Grid container>
          <Grid item xs={12} className={classes.container}>
            <MaterialTable
              icons={tableIcons}
              columns={[
                {
                  title: "Profile Id",
                  field: "profile_id",
                  editable: "never",
                },
                {
                  title: "Bank Name",
                  field: "bank_account_id",
                  editable: "never",
                  render: (rowData) => (
                    <Typography>
                      {
                        bankAccount?.filter((account) => {
                          return account.id === rowData.bank_account_id;
                        })[0]?.bankName
                      }
                    </Typography>
                  ),
                },
                {
                  title: "Transfer Id",
                  field: "id",
                  editable: "never",
                },
                {
                  title: "Is Business Account",
                  field: "is_business_account",
                  editable: "never",
                },
                {
                  title: "Transaction Type",
                  field: "is_buy",
                  editable: "never",
                  render: (rowData) => (
                    <Typography>{rowData?.is_buy ? "Buy" : "Sell"}</Typography>
                  ),
                },
                {
                  title: "Amount",
                  field: "amount",
                  editable: "never",
                },
                {
                  title: "Status",
                  field: "status",
                  render: (rowData) => (
                    <Typography>
                      {rowData.status === "IN_PROGRESS"
                        ? "IN_PROGRESS"
                        : rowData.status}
                    </Typography>
                  ),
                },
                {
                  title: "Note",
                  field: "note",
                  render: (rowData) => (
                    <>
                      {rowData?.note ? (
                        <Typography
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {rowData?.note}
                        </Typography>
                      ) : null}
                      <Button
                        style={{
                          color: "#359DB6",
                          background: "#fff",
                          textTransform: "none",
                          width: "100px",
                          fontSize: "0.6rem",
                        }}
                        onClick={(e) => {
                          setRowData(rowData);
                          e.stopPropagation();
                          setModalOpen(true);
                        }}
                      >
                        Add Note
                        <NoteAddIcon />
                      </Button>
                    </>
                  ),
                },
                {
                  title: "Action",
                  render: (rowData) => {
                    return (
                      <>
                        {rowData?.is_buy === false &&
                        rowData?.status === "IN_PROGRESS" ? (
                          <Grid
                            item
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Button
                              style={{
                                color: "#fff",
                                background: "#359DB6",
                                textTransform: "none",
                                width: "100px",
                              }}
                              onClick={() => {
                                markSellAsCompleted({
                                  userId: user?._id,
                                  profileId: rowData?.profile_id,
                                  transferId: rowData?.id,
                                });
                              }}
                            >
                              Mark Done
                            </Button>
                          </Grid>
                        ) : (rowData?.is_buy === false &&
                            rowData?.status === "BANK_TRANSFER_COMPLETED") ||
                          (rowData?.is_buy === true &&
                            rowData?.status === "BANK_TRANSFER_COMPLETED") ? (
                          <>
                            <Grid
                              item
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <Button
                                style={{
                                  color: "#fff",
                                  background: "green",
                                  textTransform: "none",
                                  marginBottom: "10px",
                                  width: "100px",
                                }}
                                onClick={() => {
                                  approveCbcTransfer({
                                    profile_id: rowData?.profile_id,
                                    transferId: rowData?.id,
                                  }).then((res) => {
                                    if (res.successMessage) {
                                      getcbcbanktransfers(user?._id);
                                    }
                                  });
                                }}
                              >
                                Approve
                              </Button>
                              <Button
                                style={{
                                  color: "#fff",
                                  background: "red",
                                  textTransform: "none",
                                  width: "100px",
                                }}
                                onClick={() => {
                                  declineCbcTransfer({
                                    profile_id: rowData?.profile_id,
                                    transferId: rowData?.id,
                                  }).then((res) => {
                                    if (res.successMessage) {
                                      getcbcbanktransfers(user?._id);
                                    }
                                  });
                                }}
                              >
                                Decline
                              </Button>
                            </Grid>
                          </>
                        ) : rowData?.is_buy === true &&
                          rowData?.status === "IN_PROGRESS" ? (
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              color: "#359DB6",
                              textAlign: "center",
                            }}
                          >
                            Waiting for user approval
                          </Typography>
                        ) : null}
                      </>
                    );
                  },
                },
              ]}
              data={cbcTransfers}
              options={{
                sorting: false, // Disable row sorting on click
                // ... other options
              }}
              title="CBC Transfers"
              //  editable={{
              //   onRowUpdate: (newData, oldData) => {
              //    return new Promise((resolve, reject) => {
              //     if (
              //      (oldData.status === "APPROVED" &&
              //       newData.status === "IN_PROGRESS") ||
              //      (oldData.status === "IN_PROGRESS" &&
              //       newData.status === "APPROVED") ||
              //      (oldData.status === "DECLINED" &&
              //       newData.status === "IN_PROGRESS") ||
              //      (oldData.status === "APPROVED" && newData.status === "DECLINED") ||
              //      (oldData.status === "APPROVED" &&
              //       newData.status === "BANK_TRANSFER_COMPLETED")
              //     ) {
              //      notify("Invalid request", "warning");
              //     } else if (
              //      oldData.status === "BANK_TRANSFER_COMPLETED" &&
              //      newData.status === "APPROVED"
              //     ) {
              //      approveCbcTransfer({
              //       profile_id: oldData?.profile_id,
              //       transferId: newData?.id,
              //      }).then((res) => {
              //       if (res.successMessage) {
              //        getcbcbanktransfers();
              //       }
              //      });
              //     } else if (
              //      (oldData.status === "IN_PROGRESS" ||
              //       oldData.status === "BANK_TRANSFER_COMPLETED") &&
              //      newData.status === "DECLINED"
              //     ) {
              //      declineCbcTransfer({
              //       profile_id: oldData?.profile_id,
              //       transferId: newData?.id,
              //      }).then((res) => {
              //       if (res.successMessage) {
              //        getcbcbanktransfers();
              //       }
              //      });
              //     }
              //     resolve();
              //    });
              //   },
              //  }}
              onRowClick={(event, rowData) => setRowData(rowData)}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    bankAccount: adminReducer.bankAccount,
    user: adminReducer.user,
    cbcTransfers: adminReducer.cbcTransfers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getBankAccount: (data) => dispatch(actionCreator.getBankAccount(data)),
    getcbcbanktransfers: (data) =>
      dispatch(actionCreator.getcbcbanktransfers(data)),
    approveCbcTransfer: (data) =>
      dispatch(actionCreator.approveCbcTransfer(data)),
    declineCbcTransfer: (data) =>
      dispatch(actionCreator.declineCbcTransfer(data)),
    markSellAsCompleted: (data) =>
      dispatch(actionCreator.markSellAsCompleted(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCBCApproval);
