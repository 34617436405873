import { Grid, makeStyles, useTheme } from "@material-ui/core";
import {
 AccountCircle,
 AddBox,
 ArrowDownward,
 Check,
 ChevronLeft,
 ChevronRight,
 Clear,
 DeleteOutline,
 Edit,
 FilterList,
 FirstPage,
 LastPage,
 Remove,
 SaveAlt,
 Search,
 ViewColumn,
} from "@material-ui/icons";
import MaterialTable from "material-table";
import React, { forwardRef, useState } from "react";
import * as actionCreator from "../../store/action/index";
import { connect } from "react-redux";
import { useEffect } from "react";
const tableIcons = {
 Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
 Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
 Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
 Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
 DetailPanel: forwardRef((props, ref) => (
  <AccountCircle {...props} ref={ref} />
 )),
 Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
 Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
 Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
 FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
 LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
 NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
 PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
 ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
 Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
 SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
 ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
 ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
 container: {
  width: "100%",
  marginTop: theme.spacing(1),
  padding: "1% 0% 2% 3.6%",
  flexGrow: 1,
  [theme.breakpoints.up("sm")]: {
   paddingLeft: theme.spacing(8) + 1,
  },
  borderRadius: "15px",
  "&.MuiToolbar-root": {
   color: theme.palette.background.default,
  },
  "& .MuiTable-root": {
   "& .MuiTableRow-root": {
    "&:nth-child(even)": {
     background: theme.palette.background.paper,
    },
    "&:nth-child(odd)": {
     background: theme.palette.background.default,
    },
   },
  },
 },
}));
const BankAccounts = (props) => {
 const theme = useTheme();
 const classes = useStyles();
 const { getBankAccount, updateBankAccount, bankAccount, notify } = props;
 const [selectedRow, setSelectedRow] = useState({});

 useEffect(() => {
  getBankAccount();
 }, []);

 const rowClick = (e, rowData) => {
  window.open(`${window.location.origin}/user/${rowData?.userProfile}`);
 };

 const bankAccountUpdate = (data) => {
  setSelectedRow(data);

  updateBankAccount({
   id: data?.id,
   status: data?.status,
  });
 };

 return (
  <>
   <div style={{ maxWidth: "100%" }}>
    <Grid container>
     <Grid item xs={12} className={classes.container}>
      <MaterialTable
       icons={tableIcons}
       columns={[
        {
         title: "User profile",
         field: "userProfile",
         editable: "never",
        },
        {
         title: "Bank Name",
         field: "bankName",
         editable: "never",
        },
        {
         title: "Account Name",
         field: "accountName",
         editable: "never",
        },
        {
         title: "User Name",
         field: "userName",
         editable: "never",
        },
        {
         title: "Bank Address",
         field: "bankAddress",
         editable: "never",
        },
        {
         title: "Account Number",
         field: "bankAccountNumber",
         editable: "never",
        },
        {
         title: "Swift Code",
         field: "swiftCode",
         editable: "never",
        },
        {
         title: "Status",
         field: "status",
         lookup: {
          VERIFIED: "VERIFIED",
          DECLINED: "DECLINED",
          PENDING: "PENDING",
         },
        },
       ]}
       data={bankAccount?.reverse()}
       title="Bank Accounts"
       editable={{
        onRowUpdate: (newData, oldData) => {
         return new Promise((resolve, reject) => {
          if (
           (oldData.status === "VERIFIED" && newData.status === "PENDING") ||
           (oldData.status === "DECLINED" && newData.status === "PENDING")
          ) {
           notify("Invalid request", "warning");
          } else {
           updateBankAccount({
            id: oldData?.id,
            status: newData?.status,
           });
          }
          resolve();
         });
        },
       }}
       onRowClick={(e, rowData) => rowClick(e, rowData)}
      />
     </Grid>
    </Grid>
   </div>
  </>
 );
};

const mapStateToProps = ({ adminReducer }) => {
 return {
  loadings: adminReducer.loadings,
  getAccount: adminReducer.getAccount,
  bankAccount: adminReducer.bankAccount,
 };
};
const mapDispatchToProps = (dispatch) => {
 return {
  getBankAccount: () => dispatch(actionCreator.getBankAccount()),
  updateBankAccount: (data) => dispatch(actionCreator.updateBankAccount(data)),
  notify: (message, varient) =>
   dispatch(actionCreator.notify(message, varient)),
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(BankAccounts);
