import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as actionCreator from "../../store/action/index";
import { Box, Grid, Typography } from "@material-ui/core";
import {
  Edit,
  DeleteForeverOutlined,
  CloudUploadOutlined,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import AnnouncementTemplatePage from "../../components/common/announcement/AnnouncementTemplatePage";
import { Button } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "90%",
    margin: "5% auto auto 7%",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  backButtonContainer: {
    textAlign: "right",
  },
  backButtonStyle: {
    color: "rgb(53, 157, 182)",
  },
  titleStyle: {
    color: "rgb(53, 157, 182)",
    fontWeight: 600,
    fontSize: 24,
  },
}));

function UpdateAnnouncement(props) {
  const {
    updateAnnouncement,
    loadings,
    notify,
    getAdminAnnouncementById,
    announcement,
    uploadAnnouncementPic,
    removeAnnouncementPic,
  } = props;

  const params = useParams();

  const classes = useStyles();

  const onSuccessUpdate = () => {
    history.push("/system");
  };

  const onUpdate = (data, onSuccessCallBack) => {
    let request = {};
    request.body = data.body;
    request.is_archived = data.is_archived;
    request.title = data.title;
    request.author_name = data.author_name;
    if (data?.keywords?.length) request.keywords = data.keywords;

    if (typeof data.uploadPic == "object") {
      if (announcement?.pic) {
        removeAnnouncementPic({ id: params.id });
      }
      uploadAnnouncementPic({
        announcement_id: params.id,
        uploadPic: data.uploadPic,
      });
    }

    updateAnnouncement({
      announcementId: params.id,
      updates: request,
      onSuccessUpdate: () => {
        if (onSuccessCallBack && typeof onSuccessCallBack == "function")
          onSuccessCallBack();
        onSuccessUpdate();
      },
    });
  };

  const [modifiedData, setModifiedData] = useState();

  const history = useHistory();

  useEffect(() => {
    if (announcement) {
      let body = (announcement.body || "").split(/<[\s]*br[\s]*\/*[\s]*>/);

      let bodyList = [];
      body = body
        ?.filter((data) => data)
        ?.map((data) => {
          let output = {};
          if (
            data.match(/<Text[\s]*style=".*"[\s]*>/) ||
            data.match(/<text[\s]*style=".*"[\s]*>/)
          ) {
            output.type = "subTitle";
          } else if (
            data.match(/^(<Text.*><ol.*\/>)/) ||
            data.match(/^(<text.*><ol.*\/*>)/)
          ) {
            output.type = "bulletPoint";
          } else {
            output.type = "para";
          }

          let text = data.match(/>(\n*.\n*)*</);

          if (output.type == "bulletPoint") {
            let style =
              text?.[0]
                ?.match(/<ol.*style="list-style-type:.*\/*>/)?.[0]
                ?.match(/:'.';/)?.[0]?.[2] || "";
            output.bulletType = style;
            let list = text?.[0]
              ?.match(/<li.*>(\n*.\n*)*<.*\/li>/)?.[0]
              ?.split(/(<li>)+|(<\/li>)+/)
              ?.filter((data) => data && data != "<li>" && data != "</li>");
            output.text = [];

            if (list?.length) {
              list.map((data) => {
                output.text.push(data.replace("&nbsp;", ""));
              });
            }
          } else {
            text = text?.[0];
            if (text) {
              text = text?.slice(1, text.length - 1);
            }
            output.text = text || "";
          }
          bodyList.push(output);
          bodyList.push({ type: "break" });
        });
      let keywords = "";
      if (announcement.keywords) {
        announcement.keywords.map((data) => {
          keywords += (keywords ? "," : "") + data;
        });
      }
      setModifiedData({
        title: announcement.title,
        author: announcement.author_name,
        keywords: keywords,
        pic: announcement.pic,
        body: bodyList,
      });
    }
  }, [announcement]);

  useEffect(() => {
    if (params?.id) {
      getAdminAnnouncementById({ id: params?.id });
    }
  }, [params?.id]);

  return (
    <React.Fragment>
      <Box className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.backButtonContainer}>
              <Button
                className={classes.backButtonStyle}
                onClick={() => {
                  history.push("/system");
                }}
              >
                Back
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.titleContainer}>
              <Typography className={classes.titleStyle}>
                Update Announcement
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <AnnouncementTemplatePage
              submitButtonText={"Update Announcement"}
              data={""}
              onSubmit={onUpdate}
              modifiedData={modifiedData}
              type={"UPDATE"}
            />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    announcement: adminReducer.announcement,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateAnnouncement: (data) =>
      dispatch(actionCreator.updateAnnouncement(data)),
    uploadAnnouncementPic: (data) =>
      dispatch(actionCreator.uploadAnnouncementPic(data)),
    getAdminAnnouncementById: (data) =>
      dispatch(actionCreator.getAdminAnnouncementById(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
    removeAnnouncementPic: (data) =>
      dispatch(actionCreator.removeAnnouncementPic(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAnnouncement);
