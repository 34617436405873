import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { Grid, Button, Paper, Divider, TextField } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { forwardRef } from "react";

import { getWithExpiry } from "../../asset/utility";
import { API } from "../../asset/api";

import * as actionCreator from "../../store/action/index";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Typography } from "@material-ui/core";
import { Container } from "@material-ui/core";
import Spinner from "../../components/layout/Spinner/Spinner";
import {
  componentAccess,
  sideMenuAccess,
} from "../../components/common/method";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8) + 1,
    },
    borderRadius: "15px",
    "&.MuiToolbar-root": {
      //color: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
      color: theme.palette.background.default,
    },
    "& .MuiTable-root": {
      "& .MuiTableRow-root": {
        "&:nth-child(even)": {
          background: theme.palette.background.paper,
        },
        "&:nth-child(odd)": {
          background: theme.palette.background.default,
        },
      },
    },
  },
}));

const CustomButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#359DB6",
    "&:hover": {
      background: "#62c3db",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#8ab0b9",
  },
})((props) => <Button {...props} />);

const CustomInput = withStyles({
  root: {
    //background: "#eeededd7",
    borderRadius: 8,
    border: 0,
    width: "100%",
    fontFamily: "Manrope",
  },
})((props) => <TextField {...props} />);

const Nodes = (props) => {
  const {
    admin,
    getAdmin,
    ResetErrors,
    allocateKeycontact,
    deAllocateKeycontact,
    notify,
  } = props;
  const [foundationNodes, setFoundationNodes] = useState(null);
  const [keyNodes, setKeyNodes] = useState(null);
  const [activeNodeId, setActiveNodeId] = useState(null);
  const [activeNodeStatus, setActiveNodeStatus] = useState(null);
  const [allocateUserEmail, setAllocateUserEmail] = useState(null);
  const [allocateUserReferralcode, setAllocateUserReferralcode] =
    useState(null);
  const [loading, setLoading] = useState(false);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),

    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const columns = [
    {
      title: "Id",
      field: "id",
      align: "center",
      searchable: true,
      editable: "never",
    },
    {
      title: "Active",
      field: "is_active",
      align: "center",
      sorting: false,
      searchable: true,
      lookup: { false: "Not-Active", true: "Active" },
    },
    {
      title: "Username",
      field: "user_name",
      align: "center",
      sorting: false,
      searchable: true,
      editable: "never",
    },
    {
      title: "Email",
      field: "email",
      align: "center",
      sorting: false,
      searchable: true,
      editable: "never",
    },
    {
      title: "Referral code",
      field: "referral_code",
      align: "center",
      sorting: false,
      searchable: true,
      editable: "never",
    },
    {
      title: "KYC status",
      field: "kyc_details.status",
      align: "center",
      sorting: false,
      searchable: true,
      editable: "never",
    },
    {
      title: "KYC Time",
      field: "kyc_details.time",
      align: "center",
      sorting: false,
      searchable: true,
      editable: "never",
      lookup: { null: "-" },
    },
  ];

  const getFoundationNodes = () => {
    setLoading(true);
    let token = getWithExpiry("token");
    API.post(
      `/GetFoundationInvestors`,
      {},
      {
        headers: {
          token: token,
        },
        withCredentials: false,
      }
    )
      .then((res) => {
        if (res.data.status) {
          setFoundationNodes(res.data.nodes);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notify(
          err.response.data.message || "Coudn't fetch nodes please try again",
          "error"
        );
      });
  };
  const tableRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    if (!admin) {
      getAdmin();
    }
    if (!foundationNodes && admin?.level === 1) {
      getFoundationNodes();
    } else if (admin?.node) {
      setActiveNodeId(admin?.node?._id);
      getKeycontactNodes(admin?.node?._id);
    }
    return () => {
      ResetErrors();
    };
  }, [ResetErrors, admin]);

  const updateFINodeStatus = ({ id, status }) => {
    //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

    let storedToken = getWithExpiry("token");
    const reqBody = {
      id: id,
      status: status,
    };

    API.post("/SetFoundationInvestorsStatus", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error } = res.data;
        notify(message, status ? "success" : "error");
        getFoundationNodes();
      })
      .catch((err) => {
        notify(err?.response?.data?.message || err.message, "error");
      });
  };

  const getKeycontactNodes = (id) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      id: id,
    };

    API.post("/GetKeyContactNodes", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, nodes } = res.data;
        if (status) setKeyNodes(nodes);
      })
      .catch((err) => {
        setKeyNodes(null);
        notify(
          err.response.data.message ||
            "Coudn't fetch key nodes please try again",
          "error"
        );
      });
  };

  const rowClick = (e, rowData) => {
    setActiveNodeStatus(rowData.kyc_details?.status);
    if (rowData?.id) {
      setActiveNodeId(rowData.id);
    }
    getKeycontactNodes(rowData?.id);
  };

  const allocateUser = () => {
    let user = {
      email: allocateUserEmail,
      type: "company",
      nodeType: "KEY_CONTACT",
      ref: allocateUserReferralcode,
    };
    allocateKeycontact(user);
    setAllocateUserReferralcode(null);
  };

  const deAllocate = (e) => {
    deAllocateKeycontact(e.currentTarget.name);
    getKeycontactNodes(activeNodeId);
  };
  // console.log(admin?.level, admin?.type, "Nodes");
  return (
    <Grid
      container
      className={classes.root}
      spacing={3}
      style={{ maxWidth: "100%" }}
    >
      {!loading ? (
        <Grid item xs={12}>
          <MaterialTable
            icons={tableIcons}
            title="Nodes"
            columns={columns}
            options={{
              pageSize: 8,
              pageSizeOptions: [4, 8, 16],
              exportAllData: true,
              exportButton: true,
              emptyRowsWhenPaging: false,
              columnsButton: true,
              showFirstLastPageButtons: false,
              search: true,
              padding: "dense",
            }}
            onRowClick={(e, rowData) => rowClick(e, rowData)}
            data={foundationNodes ? foundationNodes : []}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  var status = newData.is_active === "true" ? true : false;
                  updateFINodeStatus({
                    id: oldData.id,
                    status: status,
                  });
                  resolve();
                }),
            }}
          />

          {keyNodes ? (
            <React.Fragment>
              <Paper className={classes.paper} elevation={5}>
                <Container>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5" align="left">
                        Key Contacts of {activeNodeId}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ marginTop: 5, marginBottom: 10 }}
                    >
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h6" align="left">
                        Referral Code
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h6" align="left">
                        Username
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h6" align="left">
                        Is Allocated
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h6" align="left"></Typography>
                    </Grid>
                    {keyNodes.map((node) => {
                      return (
                        <Grid item container key={node.referral_code}>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            style={{ paddingLeft: "2%" }}
                          >
                            {node.referral_code}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            style={{ paddingLeft: "2%" }}
                          >
                            {node.is_allocated ? node.user_name : "-"}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            style={{ paddingLeft: "2%" }}
                          >
                            {node.is_allocated ? "True" : "False"}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            style={{ paddingLeft: "2%" }}
                          >
                            {allocateUserReferralcode === node.referral_code ? (
                              <>
                                <CustomInput
                                  variant="outlined"
                                  type="email"
                                  name="email"
                                  label="Email"
                                  required
                                  onChange={(e) =>
                                    setAllocateUserEmail(e.target.value)
                                  }
                                />
                                <CustomButton
                                  onClick={allocateUser}
                                  disabled={allocateUserEmail === null}
                                >
                                  Save
                                </CustomButton>
                                <CustomButton
                                  onClick={(e) =>
                                    setAllocateUserReferralcode(null)
                                  }
                                >
                                  Back
                                </CustomButton>
                              </>
                            ) : null}
                            {allocateUserReferralcode !== node.referral_code ? (
                              node.is_allocated ? (
                                <CustomButton
                                  name={node.referral_code}
                                  onClick={(e) => deAllocate(e)}
                                >
                                  De-Allocate
                                </CustomButton>
                              ) : (
                                <CustomButton
                                  name={node.referral_code}
                                  onClick={(e) =>
                                    setAllocateUserReferralcode(
                                      e.currentTarget.name
                                    )
                                  }
                                  disabled={
                                    allocateUserReferralcode !== null ||
                                    activeNodeStatus === false
                                  }
                                >
                                  Allocate
                                </CustomButton>
                              )
                            ) : null}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Container>
              </Paper>
            </React.Fragment>
          ) : null}
        </Grid>
      ) : (
        <Spinner />
      )}
    </Grid>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    admin: adminReducer.admin,
    admins: adminReducer.admins,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
    getAdmin: () => dispatch(actionCreator.getAdmin()),

    allocateKeycontact: (user) =>
      dispatch(actionCreator.allocateKeycontact(user)),
    deAllocateKeycontact: (ref) =>
      dispatch(actionCreator.deAllocateKeycontact(ref)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Nodes);
