import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { getWithExpiry } from "../../asset/utility";
import { API } from "../../asset/api";
import * as actionCreator from "../../store/action/index";
import { useParams } from "react-router";

import Spinner from "../layout/Spinner/Spinner";

const useStyles = makeStyles((theme) => ({
  image: {
    borderRadius: 10,
    height: 150,
    width: 150,
  },
}));

const QRComponent = (props) => {
  const classes = useStyles();
  const { notify } = props;
  const [id, setId] = useState(useParams().id || null);
  const [user, setUser] = useState(null);
  const [imageLink, setImageLink] = useState("");
  const [loadImage, setLoadImage] = useState(false);

  const getUser = (id) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      id: id,
    };
    API.post("/GetUserDetails", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, user } = res.data;
        if (status) {
          setUser(user);
        } else {
          setUser(null);
        }
      })
      .catch((err) => {
        console.log("axios error:- ", err);
      });
  };

  const getQrCode = (id) => {
    let storedToken = getWithExpiry("token");
    setLoadImage(true);
    const reqBody = {
      id: id,
    };
    API.post("/GetQrCode", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, errorMessage, successMessage, error, image } = res.data;
        if (status) {
          setImageLink(`${process.env.REACT_APP_SPACE_LINK}/${image}`);
        } else {
          notify(
            "Couldn't get the QR code...Please refresh the page.",
            "error"
          );
        }
        setLoadImage(false);
      })
      .catch((err) => {
        notify("Couldn't get the QR code...Please refresh the page.", "error");
        //notify(err?.response?.data?.errorMessage, "error");
        setLoadImage(false);
      });
  };

  useEffect(() => {
    if (!user) getUser(id);
    getQrCode(id);
  }, []);

  return (
    <React.Fragment>
      {loadImage ? (
        <Spinner />
      ) : (
        <img className={classes.image} src={imageLink} alt="QR Code" />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    // profile: userReducer.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QRComponent);
