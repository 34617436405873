import { Typography, Grid, Link, Paper, Divider } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { fontFamily } from "@material-ui/system";

const useStyles = makeStyles((theme) => ({
  paper: {
    background: theme.palette.background.default,
    borderRadius: 10,
    padding: theme.spacing(4),
    textAlign: "left",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgb(58 58 58 / 70%), 4px 4px 10px rgb(0 0 0 / 70%) ",
    marginTop: "2.5rem",
  },

  rowName: {
    color: "#359DB6",
  },
  heading: {
    color: "#359DB6",
    textAlign: "left",
    fontWeight: "bolder",
  },
}));

function CompanyRegisteredInfo(props) {
  const classes = useStyles();
  const { companies } = props;
  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.heading}>
            Company Details
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "3%", marginBottom: "3%" }}>
          <Divider />
        </Grid>
        {companies[0].is_cogito_company ? (
          <>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.rowName}>
                Company Type
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Cogito Company</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.rowName}>
                Company Ownership
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                {companies[0].is_privately_held
                  ? "Privately Held"
                  : "Multinational Company"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.rowName}>
                Company Name
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                {companies[0].company_name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.rowName}>
                Trust Name
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                {companies[0].trust_details.trust_name}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.rowName}>
                Company Type
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Non-Cogito Company</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.rowName}>
                Company Name
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                {companies[0].company_name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.rowName}>
                Country of registration
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                {companies[0].country_of_registration}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.rowName}>
                Company registration number
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                {companies[0].registration_number}
              </Typography>
            </Grid>
          </>
        )}
        {companies && companies[0].documents?.INCORPORATION_CERTIFICATE ? (
          <>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" align="left">
                Documents
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle1">
                <Link
                  style={{ color: "#359DB6" }}
                  href={`${process.env.REACT_APP_SPACE_LINK}/${companies[0].documents.INCORPORATION_CERTIFICATE}`}
                >
                  Incorporation certificate
                </Link>
              </Typography>
            </Grid>
          </>
        ) : null}
      </Grid>
    </Paper>
  );
}

export default CompanyRegisteredInfo;
