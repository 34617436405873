import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import MuiPhoneNumber from "material-ui-phone-number";
import GoogleMaps from "../../../components/layout/googleMap";
import AddressInput from "../../../components/layout/addressInput";
import Geocode from "react-geocode";
import { withStyles, styled } from "@material-ui/core/styles";
import {
 Box,
 Button,
 Grid,
 Paper,
 Typography,
 Divider,
 TextField,
} from "@material-ui/core";
import { API } from "../../../asset/api";
import FormControl from "@material-ui/core/FormControl";
import { getWithExpiry, updateObject } from "../../../asset/utility";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import plus_icon from "../../../asset/images/plus.png";
import no_pics from "../../../asset/images/no_pics.png";
import delete_icon from "../../../asset/images/delete.png";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { validateField } from "../../../asset/businessUtility";
import * as actionCreator from "../../../store/action/index";
import Styles from "../../../components/styles";
import StaffList from "../staffList";
import StaticMap from "../StaticMap";
import no_address from "../../../asset/images/modal/no_address_listed.svg";
import { googleMapUrl } from "../../../utils/googleMaps/googleMapUrl";
import { componentAccess } from "../../../components/common/method";
import BusinessActivity from "./BusinessActivity";
const data = [
 "Advertising & Media",
 "Auto Sales & Service",
 "Business Services",
 "Computers & Telecommunications",
 "Construction & Building Services",
 "Education",
 "Entertainment",
 "Non-Profit Organizations",
 "Finance & Insurance",
 "Health & Fitness",
 "Home & Garden",
 "Hospitality",
 "Legal",
 "Personal Services & Care",
 "Real Estate",
 "Restaurants",
 "Shopping & Retail",
 "Sports & Recreation",
 "Transportation",
];

const CusTextField = styled(TextField)(() => ({
 "& fieldset": {
  borderRadius: "10px",
 },
}));

const CustomSwitch = withStyles({
 switchBase: {
  color: "#FF7300",
  "&$checked": {
   color: "#FF7300",
  },
  "&$checked + $track": {
   backgroundColor: "#FF7300",
  },
 },
 checked: {},
 track: {},
})((props) => <Switch {...props} />);

const BusinessData = (props) => {
 const classes = Styles();
 const host = `${process.env.REACT_APP_API_LINK}/ref/`;
 const { admin, notify, activeSection, getBusiness, business, getUserDetails } = props;
 const [id, setId] = useState(useParams().id || null);
 const [profileId, setProfileId] = useState(useParams().profileId || null);
 //  const [id, setId] = useState(useParams().id || null);
 const [businessDetails, setbusinessDetails] = useState();

 const [address_details, setAddress_details] = useState();

 const [currLogo, setCurrLogo] = useState({
  logo: null,
 });
 const [business_pics, setBusiness_pics] = useState(null);
 const [uploadFile, setUploadFile] = useState(null);
 const [uploadFileKey, setUploadFileKey] = useState(null);
 const [imageURLforLogo, setImageURLforLogo] = useState(null);
 const [imageURLforPic, setImageURLforPic] = useState(null);

 const [changes, setChanges] = useState({});
 const [inputChanges, seIinputChanges] = useState({});
 const [errorObj, setErrorObj] = useState({});
 const [activitytDetails, setActivityDetails] = useState();
 //for maps
 const locationCoordinate = address_details?.coordinates
  ? `${address_details.coordinates[1] || 0}x${
     address_details.coordinates[0] || 0
    }`
  : null;
 const locationLat = address_details?.coordinates
  ? address_details.coordinates[1] || 0
  : null;
 const locationLng = address_details?.coordinates
  ? address_details.coordinates[0] || 0
  : null;
 const locationIcon = address_details?.logo || null;
 var pic_counter = 0;

 const handleMultipleInputChange = (event, value) => {
  setbusinessDetails({
   ...businessDetails,
   ["industry_category"]: value,
  });
  setChanges({ ...changes, ["industry_category"]: value });
  setErrorObj(validateField("industry_category", value, errorObj));
 };
 const handleChange = (event) => {
  setbusinessDetails({
   ...businessDetails,
   [event.target.name]: event.target.value,
  });
  setChanges({ ...changes, [event.target.name]: event.target.value });
  setErrorObj(validateField(event.target.name, event.target.value, errorObj));
 };

 const handleDataFromAddress = (newchanges) => {
  let fieldChangePrefix = "address_details.";
  let finalChanges = { ...changes };
  let finalinputChanges = { ...inputChanges };
  for (const property in newchanges) {
   if (newchanges[property] != address_details[property]) {
    finalChanges[fieldChangePrefix + property] = newchanges[property];
    finalinputChanges[property] = newchanges[property];
    setErrorObj(validateField(property, newchanges[property], errorObj));
   } else {
    delete finalChanges[fieldChangePrefix + property];
    delete finalinputChanges[property];
   }
  }
  setChanges(finalChanges);
  seIinputChanges(finalinputChanges);
 };

 const handleSubmit = (e) => {
  e.preventDefault();

  const updateBusiness = async (id, update, profileId) => {
   let coordinates = null;

   if (update?.["address_details.pincode"]) {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);

    Geocode.setLocationType("ROOFTOP");

    await Geocode.fromAddress(update?.["address_details.pincode"]).then(
     (response) => {
      if (response?.results[0]?.geometry?.location) {
       coordinates = response?.results[0]?.geometry?.location;
      }
     },
     (error) => {
      console.error(error);
     }
    );
   }

   return new Promise((resolve, reject) => {
    let storedToken = getWithExpiry("token");
    let reqBody = {
     business_id: id,
     update: update,
     id: profileId,
     coordinates: coordinates,
    };
    API.post("/UpdateBusiness", JSON.stringify(reqBody), {
     headers: {
      token: storedToken,
     },
     withCredentials: false,
    })

     .then((res) => {
      const { status, successMessage, errorMessage, error } = res.data;

      if (status) {
       if (business?.is_hidden) {
        setTimeout(() => {
         window.open(`${window.location.origin}/businesses`, `_self`);
        }, 500);
       }
       getBusiness({ business_id: id });
       notify(successMessage, "success");
      } else {
       notify(errorMessage, "error");
      }
     })
     .catch((err) => {
      notify(err?.errorMessage, "error");
     });
   });
  };

  if (isEmpty(errorObj)) {
   if (!isEmpty(changes)) {
    updateBusiness(id, changes, profileId);
   } else {
    notify("Something went wrong", "error");
   }
  }
  setChanges({});
 };

 const removeBusinesspic = (profileId, id, key) => {
  let storedToken = getWithExpiry("token");
  const reqBody = {
   id: profileId,
   business_id: id,
   file: key,
  };
  API.post("/RemoveBusinesspic", JSON.stringify(reqBody), {
   headers: {
    token: storedToken,
   },
   withCredentials: false,
  })
   .then((res) => {
    const { status, successMessage, errorMessage, error } = res.data;
    if (status) {
     removeActiveBusinessPics(key);
     getBusiness();
     notify(successMessage, "success");
    } else {
     notify(errorMessage, "error");
    }
   })
   .catch((err) => {
    notify(err?.errorMessage, "error");
   });
 };

 const uploadBusinessPics = (id, profileId, key, file) => {
  return new Promise((resolve, reject) => {
   let storedToken = getWithExpiry("token");

   let data = new FormData();
   data.append("file", file);
   data.append("key", key);
   data.append("business_id", id);
   data.append("profile_id", profileId);
   data.append("token", storedToken);

   API.post("/UploadBusinessPics", data, {
    "Content-Type": "multipart/form-data",
   })
    .then((res) => {
     resolve(res.data);
    })
    .catch((err) => {
     reject(err?.response?.data);
    });
  });
 };

 const removeActiveBusinessPics = (key) => {
  let changes = {};
  if (key == "logo") {
   changes["logo"] = null;
  } else {
   let pics = business_pics;
   delete pics[key];
   changes["business_pics"] = pics;
   pic_counter = pic_counter - 1;
  }
  // updateActiveBusiness(changes);
 };

 const deleteHandler = (key) => {
  setImageURLforLogo(null);
  setImageURLforPic(null);
  setUploadFile(null);
  setUploadFileKey(null);
  removeBusinesspic(profileId, id, key);
 };

 const onFileChange = (e) => {
  if (Boolean(uploadFile) || Boolean(uploadFileKey)) {
   notify("Can upload one image at a time", "warning");
   // alert("Can upload one image at a time");
  } else if (e.target.files[0].size / 1024 / 1024 < 5) {
   let keyName = "pic";
   if (e.target.name == "pic") {
    setImageURLforPic(URL.createObjectURL(e.target.files[0]));
    let temp = e.target.files[0].name.split(".", 1);
    keyName = temp[0];
   } else {
    setImageURLforLogo(URL.createObjectURL(e.target.files[0]));
    keyName = e.target.name;
   }

   setUploadFile(e.target.files[0]);
   setUploadFileKey(keyName);
  } else {
   notify("Document Size should be less than 5 MB", "warning");
  }
 };

 const handleSubmit1 = (e) => {
  e.preventDefault();

  //disable save button and clear all values after a save
  setImageURLforLogo(null);
  setImageURLforPic(null);
  setUploadFile(null);
  setUploadFileKey(null);

  if (Boolean(uploadFile) || Boolean(uploadFileKey)) {
   uploadBusinessPics(id, profileId, uploadFileKey, uploadFile)
    .then((res) => {
     const { status, successMessage, errorMessage, image, error } = res;
     if (status) {
      getBusiness({ business_id: id });
      notify(successMessage, "success");
     } else {
      notify(errorMessage, "error");
     }
    })
    .catch((err) => {
     notify(err?.errorMessage, "error");
    });
  } else {
   notify("Something went wrong", "error");
  }
 };

//  useEffect(() => {
//   setbusinessDetails(business[0]);
//   setAddress_details(business[0]?.address_details);
//  }, [business]);
useEffect(() => {
  setbusinessDetails(business[0]);
  setAddress_details(business[0]?.address_details);
  getUserDetails(profileId);
  return () => {
    setAddress_details(null);
    setbusinessDetails(null);
  };
}, [business]);
useEffect(() => {
  if (business[0]?.business_pics || business[0]?.logo) {
    setBusiness_pics(businessDetails?.business_pics);
    setCurrLogo({ logo: businessDetails?.logo });
  }
}, [activeSection === "Business Images"]);
useEffect(() => {
  getBusiness({ business_id: id });
}, []);
 return (
  <React.Fragment>
   {activeSection === "Business Details" ? (
    <Grid item container xs={12} alignItems="center" spacing={2}>
     {/* <Paper className={classes.businessPaper} elevation={5}> */}
     <Grid item container xs={12} className={classes.resultContainer}>
      <Grid item container xs={12} justify="space-between" alignItems="center">
       <Grid item>
        <Typography variant="h5" align="left">
         Business Details
        </Typography>
       </Grid>

       <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
        <Divider />
       </Grid>
       <Grid container direction="column" className={classes.main}>
        <form type="post" onSubmit={handleSubmit}>
         <Grid item xs={12}>
          <Grid container direction="row" className={classes.first_row}>
           <Box>
            <h4 style={{ fontSize: "1rem", fontWeight: "400" }}>
             Add Information
            </h4>
           </Box>
           <Box className={classes.btn_box}>
            {/* <Button variant="outlined" className={classes.prev}>
                												Preview
              												</Button> */}
            {componentAccess(
             admin?.level,
             admin?.type,
             "Businesses",
             "updateBusinessDetails"
            ) ? (
             <>
              <Button
               variant="contained"
               className={classes.save}
               type="submit"
               disabled={
                isEmpty(changes) ||
                !(
                 isEmpty(errorObj) &&
                 businessDetails?.name?.length > 0 &&
                 // businessDetails?.website?.length > 0 &&
                 businessDetails?.industry_category?.length > 0 &&
                 businessDetails?.industry_category?.length < 4 &&
                 businessDetails?.email?.length > 0 &&
                 businessDetails?.phone?.length > 0 &&
                 businessDetails?.description?.length > 0
                )
               }
              >
               Save
              </Button>
             </>
            ) : null}
           </Box>
          </Grid>
         </Grid>

         {componentAccess(
          admin?.level,
          admin?.type,
          "Businesses",
          "updateBusinessDetails"
         ) ? (
          <Grid item xs={12}>
           <Grid container>
            <FormControlLabel
             control={
              <CustomSwitch
               checked={businessDetails?.is_hidden == true ? false : true}
               onChange={(e) =>
                handleChange({
                 target: {
                  name: e.target.name,
                  value: !Boolean(e.target.checked),
                 },
                })
               }
               name="is_hidden"
              />
             }
             label="Show in Directory"
            />
           </Grid>
          </Grid>
         ) : null}
         <Grid item xs={12}>
          <Grid container direction="column" className={classes.second_row}>
           <CusTextField
            id="business-name"
            label="Business Name"
            variant="outlined"
            style={{ marginBottom: "1rem" }}
            onChange={handleChange}
            name="name"
            value={businessDetails?.name ?? ""}
            error={errorObj.hasOwnProperty("name")}
            helperText={errorObj?.name}
            disabled={
             !componentAccess(
              admin?.level,
              admin?.type,
              "Businesses",
              "updateBusinessDetails"
             )
            }
           />
           <CusTextField
            id="website_link"
            label="Website Link..."
            variant="outlined"
            //type="url"
            style={{ marginBottom: "1rem" }}
            onChange={handleChange}
            name="website"
            value={businessDetails?.website ?? ""}
            error={errorObj.hasOwnProperty("website")}
            helperText={errorObj?.website}
            disabled={
             !componentAccess(
              admin?.level,
              admin?.type,
              "Businesses",
              "updateBusinessDetails"
             )
            }
           />

           <Autocomplete
            className={classes.cat_list}
            multiple
            id="tags-standard"
            options={data}
            value={businessDetails?.industry_category}
            onChange={handleMultipleInputChange}
            renderInput={(params) => (
             <CusTextField
              {...params}
              variant="outlined"
              label="Industry Category..."
              name="industry_category"
              position="relative"
              error={errorObj.hasOwnProperty("industry_category")}
              helperText={errorObj?.industry_category}
              disabled={
               !componentAccess(
                admin?.level,
                admin?.type,
                "Businesses",
                "updateBusinessDetails"
               )
              }
             />
            )}
           />

           <Grid item>
            <Grid
             container
             direction="row"
             className={classes.midrow}
             style={{ justifyContent: "left" }}
            >
             <div>
              <CusTextField
               id="email"
               label="Email Id"
               name="email"
               type="email"
               variant="outlined"
               onChange={handleChange}
               value={businessDetails?.email ?? ""}
               error={errorObj.hasOwnProperty("email")}
               helperText={errorObj?.email}
               className={classes.midrow1}
               disabled={
                !componentAccess(
                 admin?.level,
                 admin?.type,
                 "Businesses",
                 "updateBusinessDetails"
                )
               }
              />
             </div>
             <div>
              <MuiPhoneNumber
               // defaultCountry={"nz"}
               // required
               variant="outlined"
               name="phone"
               label="Phone Number"
               onChange={(value) =>
                handleChange({
                 target: {
                  name: "phone",
                  value: value,
                 },
                })
               }
               value={businessDetails?.phone ?? ""}
               error={errorObj.hasOwnProperty("phone")}
               helperText={errorObj?.phone}
               className={classes.midrow2}
               disabled={
                !componentAccess(
                 admin?.level,
                 admin?.type,
                 "Businesses",
                 "updateBusinessDetails"
                )
               }
              />
             </div>
            </Grid>
           </Grid>
           <CusTextField
            id="desc"
            label="Description..."
            variant="outlined"
            multiline
            rows={3}
            onChange={handleChange}
            name="description"
            value={businessDetails?.description ?? ""}
            error={errorObj.hasOwnProperty("description")}
            helperText={errorObj?.description}
            disabled={
             !componentAccess(
              admin?.level,
              admin?.type,
              "Businesses",
              "updateBusinessDetails"
             )
            }
           />
          </Grid>
         </Grid>
         {/* {profile?.type == "company" ? (
                          <Grid item xs={12}>
                            <Grid container direction="row">
                              <FormControlLabel
                                control={
                                  <CustomCheckBox
                                    checked={
                                      business?.link_with_company == true
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      handleChange({
                                        target: {
                                          name: e.target.name,
                                          value: Boolean(e.target.checked),
                                        },
                                      })
                                    }
                                    name="link_with_company"
                                    style={{ color: "#FF7300" }}
                                  />
                                }
                                label="Link with your company"
                              />
                            </Grid>
                          </Grid>
                        ) : null} */}
        </form>
       </Grid>
      </Grid>
     </Grid>
     {/* </Paper> */}
    </Grid>
   ) : null}
   {activeSection === "Business Images" ? (
    <Grid
     item
     container
     xs={12}
     alignItems="center"
     spacing={2}
     id="buss_images"
    >
     <Grid xs={12} className={classes.resultContainer} elevation={5}>
      <Grid container spacing={2}>
       <Grid item container xs={12} justify="space-between" alignItems="center">
        <Grid item>
         <Typography variant="h5" align="left">
          Business Images
         </Typography>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
         <Divider />
        </Grid>
        <Grid container direction="column" className={classes.main}>
         <form type="post" onSubmit={handleSubmit1}>
          <Grid item>
           <Grid container direction="row" className={classes.first_row}>
            <Box>
             <h4
              style={{
               fontSize: "1rem",
               fontWeight: "400",
              }}
             >
              Add Images
             </h4>
            </Box>
            <Box className={classes.btn_box}>
             <Button
              variant="contained"
              className={classes.save}
              type="submit"
              disabled={!(Boolean(imageURLforLogo) || Boolean(imageURLforPic))}
             >
              Save
             </Button>
            </Box>
           </Grid>
          </Grid>

          <Grid item>
           <Grid container direction="column" className={classes.second_row}>
            <Box>
             <h4
              style={{
               fontSize: "1rem",
               fontWeight: "400",
              }}
             >
              Business Logo
             </h4>

             {currLogo.logo !== null ? (
              <div className={classes.image_filled}>
               <img
                src={process.env.REACT_APP_SPACE_LINK + "/" + currLogo.logo}
                alt="main_business_logo"
                className={classes.image_size}
               />
               <img
                src={delete_icon}
                alt="delete_icon"
                style={{
                 position: "absolute",

                 top: "6rem",
                 left: "6rem",
                 cursor: "pointer",
                }}
                onClick={() => deleteHandler("logo")}
               />
              </div>
             ) : componentAccess(
                admin?.level,
                admin?.type,
                "Businesses",
                "updateBusinessDetails"
               ) ? (
              <FormControl>
               <input
                accept=".jpeg,.jpg,.png"
                style={{ display: "none", width: "100%" }}
                id="logo"
                name="logo"
                onChange={onFileChange}
                type="file"
               />
               <label htmlFor="logo">
                <div className={classes.empty_image}>
                 <img
                  src={imageURLforLogo ? imageURLforLogo : no_pics}
                  className={classes.image_size}
                 />
                 {Boolean(uploadFileKey) && uploadFileKey == "logo" ? (
                  <span
                   style={{
                    position: "absolute",
                    top: "3rem",
                    width: "6rem",
                    overflowWrap: "anywhere",
                    left: "1rem",
                   }}
                  >
                   {uploadFile?.name}
                  </span>
                 ) : (
                  <img
                   src={plus_icon}
                   alt="add Image"
                   style={{
                    position: "absolute",

                    top: "2rem",
                    left: "2.5rem",
                   }}
                  />
                 )}
                </div>
               </label>
              </FormControl>
             ) : (
              "No Logo added"
             )}
            </Box>
           </Grid>
          </Grid>

          <Grid item>
           <Grid container direction="column" className={classes.third_row}>
            <Box>
             <h4
              style={{
               fontSize: "1rem",
               fontWeight: "400",
              }}
             >
              Business Pics (Max 5)
             </h4>
             <Grid item>
              <Grid container direction="row" spacing={4}>
               {business_pics
                ? Object.keys(business_pics).map((key, index) => {
                   pic_counter = pic_counter + 1;
                   return (
                    <Grid item key={index}>
                     <div className={classes.image_filled}>
                      <img
                       src={
                        process.env.REACT_APP_SPACE_LINK +
                        "/" +
                        business_pics[key]
                       }
                       alt={business_pics[key]}
                       className={classes.image_size}
                      />

                      <img
                       src={delete_icon}
                       alt="delete_icon"
                       style={{
                        position: "absolute",

                        top: "6.4rem",
                        left: "6.3rem",
                        cursor: "pointer",
                       }}
                       onClick={() => deleteHandler(key)}
                      />
                     </div>
                    </Grid>
                   );
                  })
                : null}
               {pic_counter < 5 ? (
                <Grid item>
                 <FormControl>
                  <input
                   accept=".jpeg,.jpg,.png"
                   style={{
                    display: "none",
                    width: "100%",
                   }}
                   id="pic"
                   name="pic"
                   onChange={onFileChange}
                   type="file"
                  />
                  <label htmlFor="pic">
                   <div className={classes.empty_image}>
                    <img
                     src={imageURLforPic ? imageURLforPic : no_pics}
                     className={classes.image_size}
                    />
                    {Boolean(uploadFileKey) && uploadFileKey != "logo" ? (
                     <span
                      style={{
                       position: "absolute",
                       top: "8rem",
                       width: "6rem",
                       overflowWrap: "anywhere",
                       left: "1rem",
                      }}
                     >
                      {uploadFile?.name}
                     </span>
                    ) : (
                     <img
                      src={plus_icon}
                      alt="add Image"
                      style={{
                       position: "absolute",

                       top: "2rem",
                       left: "2.5rem",
                      }}
                     />
                    )}
                   </div>
                  </label>
                 </FormControl>
                </Grid>
               ) : null}
              </Grid>
             </Grid>
            </Box>
           </Grid>
          </Grid>
         </form>
        </Grid>
       </Grid>
      </Grid>
     </Grid>
    </Grid>
   ) : null}
   {activeSection === "Business Locations" ? (
    <Grid item container xs={12} alignItems="center" spacing={2}>
     <Grid
      xs={12}
      className={classes.resultContainer}
      id="buss_loc"
      elevation={5}
      style={{ paddingBottom: "3rem" }}
     >
      {/* <Paper className={classes.paper}  elevation={5}> */}
      <Grid container spacing={1}>
       <Grid item container xs={12} justify="space-between" alignItems="center">
        <Grid item>
         <Typography variant="h5" align="left">
          Business Locations
         </Typography>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
         <Divider />
        </Grid>

        <Grid container direction="column" className={classes.main}>
         <form type="post" onSubmit={handleSubmit}>
          <Grid item>
           <Grid container direction="row" className={classes.first_row}>
            <Box>
             <h4
              style={{
               fontSize: "1rem",
               fontWeight: "400",
              }}
             >
              What is your Business Location?
             </h4>
            </Box>
            {componentAccess(
             admin?.level,
             admin?.type,
             "Businesses",
             "updateBusinessDetails"
            ) ? (
             <Box className={classes.btn_box}>
              <Button
               variant="contained"
               className={classes.save}
               type="submit"
               disabled={!isEmpty(errorObj) || isEmpty(changes)}
              >
               Save
              </Button>
             </Box>
            ) : null}
           </Grid>
          </Grid>

          <Grid item>
           <Grid container direction="column">
            <Box>
             <GoogleMaps
              defaultAddress={address_details?.address}
              onValueChange={(value) =>
               handleDataFromAddress({ address: value })
              }
              error={errorObj.hasOwnProperty("address")}
              helperText={errorObj?.address}
              disabled={
               !componentAccess(
                admin?.level,
                admin?.type,
                "Businesses",
                "updateBusinessDetails"
               )
              }
             />
            </Box>
           </Grid>
          </Grid>

          <Grid item>
           <Grid container>
            <Box style={{ marginTop: "1rem", width: "100%" }}>
             <AddressInput
              disableField={false}
              country={address_details?.country}
              state={address_details?.state}
              city={address_details?.city}
              pincode={address_details?.pincode}
              getChangedData={handleDataFromAddress}
              // disabled={
              //   !componentAccess(
              //     admin?.level,
              //     admin?.type,
              //     "Businesses",
              //     "updateBusinessDetails"
              //   )
              // }
              // dataToParent={handleDataToParent}
             />
            </Box>
           </Grid>
           <Grid
            item
            xs={12}
            sm={5}
            xl={4}
            style={{
             background: localStorage.getItem("dark") ? "#1a1a1a" : "#000",
             borderRadius: 12,
             marginBottom: "1.2rem",
             color: "#fff",
             overflow: "hidden",
             marginLeft: "30%",
            }}
            fullwidth
           >
            {/* address Image or no_adress Image */}
            <Grid
             item
             container
             xs={12}
             justifyContent="center"
             component={Paper}
             className={classes.address}
            >
             {address_details?.coordinates ? (
              <a
               href={googleMapUrl(locationLat, locationLng, 18)}
               target="_blank"
              >
               <StaticMap
                size={"850x250"}
                coordinates={{
                 center: locationCoordinate,
                 businesses: [
                  {
                   coordinates: `${locationLat},${locationLng}`,
                   icon: locationIcon
                    ? `${process.env.REACT_APP_SPACE_KEY}/${locationIcon}`
                    : null,
                  },
                 ],
                }}
               />
              </a>
             ) : (
              <img src={no_address} alt="no_address" />
             )}
            </Grid>
           </Grid>
          </Grid>
         </form>
        </Grid>
       </Grid>
      </Grid>
     </Grid>
    </Grid>
   ) : null}
   {activeSection === "Business Staff" ? (
    <>
     <StaffList />
    </>
   ) : null}
   {activeSection == "Business Activity" ? (
    <Grid item container xs={12} alignItems="center">
     <Grid item xs={12} className={classes.resultContainer}>
      {/* {businessDetails?.isOptedForPmActivity } */}
      <BusinessActivity
       id={id}
       address={address_details}
       profileId={businessDetails?.profile_id}
       business={businessDetails}
      />
     </Grid>
    </Grid>
   ) : // </Grid>
   null}
  </React.Fragment>
 );
};
const mapStateToProps = ({ adminReducer }) => {
 return {
  loadings: adminReducer.loadings,
  admin: adminReducer.admin,
  business: adminReducer.business,
 };
};
const mapDispatchToProps = (dispatch) => {
 return {
  ResetErrors: () => dispatch(actionCreator.ResetErrors()),
  getAdmin: () => dispatch(actionCreator.getAdmin()),
  getBusiness: (data) => dispatch(actionCreator.getBusiness(data)),
  notify: (message, varient) =>
   dispatch(actionCreator.notify(message, varient)),
  getUserDetails: (data) => dispatch(actionCreator.getUser(data)),
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessData);
