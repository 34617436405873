import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={38}
    fill="none"
    {...props}
  >
    <path
      fill="#C9E5EC"
      d="M13.333 12a3.334 3.334 0 1 1 0 6.668 3.334 3.334 0 0 1 0-6.668ZM20 25.334H6.667v-1.667c0-2.217 4.45-3.334 6.666-3.334 2.217 0 6.667 1.117 6.667 3.334v1.666Zm13.333-15h-10v3.333h10v-3.333Zm0 6.666h-10v3.334h10V17Zm0 6.667h-10V27h10v-3.333Zm3.334-20H23.333V7h13.334v23.334H3.333V7h13.334V3.667H3.333A3.333 3.333 0 0 0 0 7v23.334a3.333 3.333 0 0 0 3.333 3.333h33.334A3.333 3.333 0 0 0 40 30.333V7a3.333 3.333 0 0 0-3.333-3.333ZM21.667 7h-3.334V.333h3.334V7Z"
    />
  </svg>
);
export default SvgComponent;
