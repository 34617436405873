import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Typography, Button } from "@material-ui/core";

const SignaturePad = ({ onSignatureSave }) => {
  const sigPad = useRef(null);

  const clear = () => {
    sigPad.current.clear();
  };

  const save = () => {
    if (onSignatureSave) {
      onSignatureSave(sigPad.current.toDataURL());
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography style={{ color: "#000" }}>Signature</Typography>
      <SignatureCanvas
        ref={sigPad}
        penColor="black"
        canvasProps={{
          width: 500,
          height: 200,
          className: "sigCanvas",
          style: { backgroundColor: "white", border: "1px solid #ddd" },
        }}
      />
      <button style={{ height: "40px" }} onClick={clear} id="clearSignButton">
        Clear
      </button>
      {/* <button onClick={save}>Save</button> */}
    </div>
  );
};

export default SignaturePad;
