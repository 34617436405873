import phone from "phone";

export const validEmail = new RegExp(
 "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
);

export const validateField = (field, value, error) => {
 let errorObj = error || {};
 if (field === "firstName" || field === "lastName" || field === "role") {
  if (value.trim().length !== 0 && !/^[A-Za-z ]+$/.test(value.trim())) {
   if (field === "firstName") {
    errorObj[field] = "Name should contain only alphabets";
   } else if (field === "lastName") {
    errorObj[field] = "Name should contain only alphabets";
   } else if (field === "role") {
    errorObj[field] = "Name should contain only alphabets";
   }
  } else {
   delete errorObj[field];
  }
 } else if (field === "password") {
  let passwordRegEX = /(?=.*\d).{8,}/;
  if (value.trim().length === 0) {
   errorObj[field] = "Password is required";
  } else if (!passwordRegEX.test(value.trim())) {
   errorObj[field] =
    "Password must contain at least 8 characters, including a number";
  } else {
   delete errorObj[field];
  }
 } else if (field === "userName") {
  let userIdRegEX = /^[a-z0-9]+$/i;
  if (value.trim().length === 0) {
   errorObj[field] = "User Name is required";
  } else if (!userIdRegEX.test(value.trim())) {
  } else {
   delete errorObj[field];
  }
 } else if (field === "phone") {
  let result = phone(value, "", true);
  if (value === "" || result.length) {
   delete errorObj[field];
  } else {
   errorObj[field] = "Enter correct phone number";
  }
 } else if (field === "dob") {
  let now = new Date().toISOString().slice(0, 10);
  if (value !== "" && value >= now) {
   errorObj[field] = "Date of birth cannot be future !!";
  } else {
   delete errorObj[field];
  }
 }
 // else if (field.includes("address_details")) {
 // if (value !== "" || value !== null) {
 //   if (field === "address_details.address") {
 //     // errorMessagesCopy.addressError = "";
 //     // validDataCopy.validAddress = true;
 //     delete errorObj[field];
 //   } else if (field === "address_details.country") {
 //     // errorMessagesCopy.countryError = "";
 //     // validDataCopy.validCountry = true;
 //     delete errorObj[field];
 //   } else if (field === "address_details.state") {
 //     // errorMessagesCopy.stateError = "";
 //     // validDataCopy.validState = true;
 //     delete errorObj[field];
 //   } else if (field === "address_details.city") {
 //     // errorMessagesCopy.cityError = "";
 //     // validDataCopy.validCity = true;
 //     delete errorObj[field];
 //   } else if (field === "address_details.pincode") {
 //     // errorMessagesCopy.pincodeError = "";
 //     // validDataCopy.validPincode = true;
 //     delete errorObj[field];
 //   }
 // } else {
 //   errorObj[field] = "This field is required!!!";
 // }
 // }
 else if (field === "address") {
  if (value.trim().length === 0) {
   errorObj[field] = "This field is required";
  } else {
   delete errorObj[field];
  }
  // } else if (field === "country") {
  //   if (value.trim().length === "") {
  //     errorObj[field] = "This field is required";
  //   } else {
  //     delete errorObj[field];
  //   }
  // } else if (field === "pincode") {
  //   if (value.trim().length === 0) {
  //     errorObj[field] = "This field is required";
  //   } else {
  //     delete errorObj[field];
  //   }
  // } else if (field === "name") {
  //   if (value.trim().length === 0) {
  //     errorObj[field] = "This field is required";
  //   }
  // else if (
  //   value.trim().length !== 0 &&
  //   !/^[A-Za-z ]+$/.test(value.trim())
  // ) {
  //   errorObj[field] = "Business name should only contain alphabets";
  // }
  // else {
  //   delete errorObj[field];
  // }
 } else if (field == "name") {
  if (value.trim().length === 0) {
   errorObj[field] = "This field is required";
  } else {
   delete errorObj[field];
  }
 } else if (field === "description") {
  if (value.trim().length === 0) {
   errorObj[field] = "This field is required";
  } else if (value.trim().length > 350) {
   errorObj[field] = "Max word length must be less than 350 characters!!!";
  } else {
   delete errorObj[field];
  }
 } else if (field === "email") {
  let emailRegEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  if (value.trim().length === 0) {
   errorObj[field] = "This field is required";
  } else if (!emailRegEX.test(value.trim())) {
   errorObj[field] = "please enter a valid email address";
  } else {
   delete errorObj[field];
  }
 } else if (field === "website") {
  let websiteRegex =
   /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  if (value.trim().length === 0) {
   errorObj[field] = "This field is required";
  } else if (!websiteRegex.test(value.trim())) {
   errorObj[field] = "please enter a valid website address";
  } else {
   delete errorObj[field];
  }
 } else if (field === "industry_category") {
  if (value.length === 0) {
   errorObj[field] = "This field is required";
  } else {
   delete errorObj[field];
  }
 }

 return errorObj;
};
