import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Divider,
  TextField,
  Select,
  MenuItem,
  Button,
  Dialog,
} from "@material-ui/core";
import {} from "@material-ui/icons";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import * as actionCreator from "../../store/action/index";
import { updateObjectProperty } from "../../asset/utility";
import { truncate } from "@sentry/utils";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    width: "35rem",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const CustomButton = withStyles({
  root: {
    borderRadius: 8,
    justifySelf: "right",
    border: 0,
    color: "white",
    //   fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#359DB6",
    "&:hover": {
      background: "#62c3db",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#8ab0b9",
  },
})((props) => <Button {...props} />);

const CustomInput = withStyles({
  root: {
    //background: "#eeededd7",
    borderRadius: 8,
    border: 0,
    width: "100%",
    fontFamily: "Manrope",
  },
})((props) => <TextField {...props} />);

const SuccessButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#10ca73",
    "&:hover": {
      background: "#87f891",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#758876",
  },
})((props) => <Button {...props} />);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3),
    borderRadius: 10,
  },
}));

const RequestAdmin = (props) => {
  const classes = useStyles();
  const { loadings, sendAdminRequest, admin } = props;
  const [user, setUser] = useState({
    email: "",
    level: 4,
    type: "Help Desk",
  });

  // useEffect(() => {
  //   sendAdminRequest({
  //       email : "",
  //       level : 4,
  //       type : "Help Desk",
  //   }){{
  // }, []);
  const [openAddAdmin, setOpenAddAdmin] = useState(false);

  const handleAddAdminOpen = () => {
    setOpenAddAdmin(true);
  };

  const handleAddAdminClose = () => {
    setOpenAddAdmin(false);
    setUser({
      email: "",
      level: 4,
      type: "Help Desk",
    });
  };

  const onUserValueChanged = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setUser(updateObjectProperty(user, name, value));
  };

  const onAddAdmin = (e) => {
    e.preventDefault();
    if (user.email?.length && user.level && user.type?.length) {
      sendAdminRequest(user);
    }

    handleAddAdminClose();
  };

  return (
    <React.Fragment>
      {/* <form onSubmit={onAddAdmin} method="post" style={{ width: "100%" }}> */}
      <Grid container spacing={2}>
        <Grid item xs={8}></Grid>

        <Grid
          item
          xs={4}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <CustomButton
            onClick={handleAddAdminOpen}
            // disabled={loadings.updateAdmin || !Boolean(profile)}
          >
            Add Admin
          </CustomButton>
        </Grid>

        {openAddAdmin ? (
          <>
            <form onSubmit={onAddAdmin} method="post" style={{ width: "100%" }}>
              <Dialog
                aria-labelledby="customized-dialog-title"
                open={handleAddAdminOpen}
                onClose={handleAddAdminClose}
              >
                <Grid>
                  <DialogTitle>Add Admin</DialogTitle>
                </Grid>
                {/* <Grid item xs={12} style={{ marginTop: 5, marginBottom: 10 }}>
									<Divider />
								</Grid> */}

                <DialogContent dividers>
                  <Grid>
                    <TextField
                      variant="outlined"
                      type="email"
                      name="email"
                      label="Email"
                      required
                      value={user.email}
                      onChange={onUserValueChanged}
                      fullWidth
                    />

                    <Select
                      id="demo-simple-select-outlined"
                      variant="outlined"
                      name="level"
                      fullWidth
                      value={user.level}
                      onChange={onUserValueChanged}
                      label="Level"
                      style={{ marginTop: "1rem", height: "2.5rem" }}
                    >
                      <MenuItem value={1}>Level 1</MenuItem>
                      <MenuItem value={2}>Level 2</MenuItem>
                      <MenuItem value={3}>Level 3</MenuItem>
                      <MenuItem value={4}>Level 4</MenuItem>
                    </Select>

                    <Select
                      id="demo-simple-select-outlined"
                      variant="outlined"
                      name="type"
                      fullWidth
                      value={user.type}
                      onChange={onUserValueChanged}
                      label="Type"
                      style={{ marginTop: "1rem", height: "2.5rem" }}
                    >
                      <MenuItem value={"Cogito"}>Cogito</MenuItem>
                      <MenuItem value={"Content Creator"}>
                        Content Creator
                      </MenuItem>
                      <MenuItem value={"Foundation Investor"}>
                        Foundation Investor
                      </MenuItem>
                      <MenuItem value={"Finance"}>Finance</MenuItem>
                      <MenuItem value={"Legal"}>Legal</MenuItem>
                      <MenuItem value={"Help Desk"}>Help Desk</MenuItem>
                    </Select>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <SuccessButton
                    type="submit"
                    variant="contained"
                    disabled={
                      !user.email?.length ||
                      !user.level ||
                      !user.type?.length ||
                      admin.level > 1 ||
                      loadings.sendAdminRequest
                    }
                    onClick={onAddAdmin}
                  >
                    Submit
                  </SuccessButton>
                </DialogActions>
              </Dialog>
            </form>
          </>
        ) : null}
      </Grid>
      {/* </form> */}
    </React.Fragment>
  );
};
const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    admin: adminReducer.admin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendAdminRequest: (data) => dispatch(actionCreator.sendAdminRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestAdmin);
