import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { forwardRef } from "react";

import { getWithExpiry } from "../../asset/utility";
import { API } from "../../asset/api";
import * as actionCreator from "../../store/action/index";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Spinner from "../../components/layout/Spinner/Spinner";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  detailPanel: {
    padding: theme.spacing(3),
  },
  root: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8) + 1,
    },
    borderRadius: "15px",
    "&.MuiToolbar-root": {
      //color: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
      color: theme.palette.background.default,
    },
    "& .MuiTable-root": {
      "& .MuiTableRow-root": {
        "&:nth-child(even)": {
          background: theme.palette.background.paper,
        },
        "&:nth-child(odd)": {
          background: theme.palette.background.default,
        },
      },
    },
  },
  modal: {},
  customTableButton: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "2px 0px",
  },
  inputStyle: {
    //background: "#eeededd7",
    borderRadius: 8,
    border: 0,
    fontFamily: "Manrope",
  },
}));

const Users = (props) => {
  const { notify } = props;
  const classes = useStyles();
  const firstLoad = useRef(true);
  const tableRef = useRef();
  const searchTimer = useRef(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const getUsers = (request) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      let token = getWithExpiry("token");
      let body = {
        page: request.page,
        search: search,
        limit: request.pageSize,
      };
      API.post(`/GetUsers`, JSON.stringify(body), {
        headers: {
          token: token,
        },
        withCredentials: false,
      })
        .then((res) => {
          if (res.data.status) {
            if (res.data.users != null) {
              resolve({
                data: res?.data?.users,
                page: request.page,
                totalCount: res?.data?.count,
              });
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          reject();
          notify(
            err?.response?.data?.message || "Couldn't fetch users try again",
            "error"
          );
        });
    });

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),

    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const columns = [
    {
      title: "Id",
      field: "_id",
      align: "center",
      searchable: true,
    },
    {
      title: "Sponser Id",
      field: "nodes[0].sponsored_by",
      align: "center",
      searchable: true,
    },
    {
      title: "Username",
      field: "userName",
      align: "center",
      sorting: false,
      searchable: true,
    },
    {
      title: "First Name",
      field: "firstName",
      align: "center",
      sorting: false,
      searchable: true,
    },
    {
      title: "Last Name",
      field: "lastName",
      align: "center",
      sorting: false,
      searchable: true,
    },
    {
      title: "Email",
      field: "email",
      align: "center",
      sorting: false,
      searchable: true,
    },

    {
      title: "Phone Number",
      field: "phone",
      align: "center",
      sorting: false,
      searchable: true,
    },
    {
      title: "KYC status",
      field: "kyc.status",
      align: "center",
      sorting: false,
      searchable: true,
      editable: "never",
    },
    {
      title: "Referral code",
      field: "nodes[0].referral_code",
      align: "center",
      sorting: false,
      searchable: true,
      editable: "never",
    },
  ];

  const rowClick = (e, rowData) => {
    window.open(`${window.location.origin}/user/${rowData._id}`);
  };

  useEffect(() => {
    if (!firstLoad.current) {
      if (searchTimer.current) {
        clearInterval(searchTimer.current);
      }
      searchTimer.current = setTimeout(() => {
        tableRef.current.onQueryChange({page:0});
      }, 1000);
    } else {
      firstLoad.current = false;
    }
    return () => {
      if (searchTimer.current) {
        clearInterval(searchTimer.current);
      }
    };
  }, [search]);

  return (
    <Grid
      container
      className={classes.root}
      spacing={3}
      style={{ maxWidth: "100%" }}
    >
      <Grid item xs={12} style={{ position: "relative" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "0.5rem",
          }}
        >
          <TextField
            label="Search"
            variant="outlined"
            size="midium"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <MaterialTable
          icons={tableIcons}
          title="Users"
          columns={columns}
          tableRef={tableRef}
          options={{
            pageSizeOptions: [10,50,100,200,500],
            exportAllData: true,
            exportButton: true,
            emptyRowsWhenPaging: false,
            maxBodyHeight: 450,
            columnsButton: true,
            showFirstLastPageButtons: false,
            search: false,
            padding: "dense",
          }}
          onRowClick={(e, rowData) => rowClick(e, rowData)}
          data={(query) => getUsers(query)}
        />
        {loading ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 10,
            }}
          >
            <Spinner />{" "}
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({}) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
