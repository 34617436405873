import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import * as actionCreator from "../../../store/action/index";
import curvedEdge from "../../../asset/images/curvedEdge.png";
import { Box, Step, StepContent, StepLabel, Stepper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  detailPanel: {
    padding: theme.spacing(3),
  },
  root: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8) + 1,
    },
    borderRadius: "15px",
    "&.MuiToolbar-root": {
      //color: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
      color: theme.palette.background.default,
    },
    "& .MuiTable-root": {
      "& .MuiTableRow-root": {
        "&:nth-child(even)": {
          background: theme.palette.background.paper,
        },
        "&:nth-child(odd)": {
          background: theme.palette.background.default,
        },
      },
    },
  },
  container: {
    minHeight: 50,
    borderRadius: 10,
    overflow: "hidden",
    width: "100%",
    backgroundColor: "#272727",
    display: "flex",
    justifyContent: "space-between",
  },
  leftContainer: {
    maxWidth: "300px",
    width: "40%",
    display: "flex",
  },
  leftContainerChild1: {
    maxWidth: 150,
    width: "100%",
    backgroundColor: "#144C59",
  },
  leftContainerChild2: {
    width: 150,
    backgroundImage: `url(${curvedEdge})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    position: "relative",
    right: 5,
  },
  rightContainer: {
    flexWrap: "wrap",
    flexGrow: 1,
  },
  stepper: {
    backgroundColor: "#272727",
    padding: 8,
  },
  modal: {},
  customTableButton: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "2px 0px",
  },
  inputStyle: {
    borderRadius: 8,
    border: 0,
    fontFamily: "Manrope",
  },
}));

const ApprovalHead = (props) => {
  const { approvalDetail } = props;
  const classes = useStyles();
  const steps =
    Object.keys(approvalDetail?.actions || {})
      ?.map((action) => ({
        ...approvalDetail?.actions[action],
        label:
          action == "contact_user"
            ? "Upload Documents"
            : action == "document_check"
            ? "Documents Check"
            : action == "update_process_fee"
            ? "Upload Euro Fees"
            : "Status",
      }))
      ?.sort((a, b) => a.index - b.index) || [];
  if (steps?.length) {
    steps.push({ label: "Status" });
  }
  const status = ["Approved", "Rejected", "Cancelled"];
  const actions =
    (approvalDetail &&
      Object.keys(approvalDetail?.actions)
        ?.map((action) => approvalDetail?.actions?.[action])
        ?.sort((a, b) => a.index - b.index)) ||
    [];
  const activeStep = status.includes(approvalDetail?.status)
    ? actions?.[actions.length - 1]?.index + 1 || 0
    : approvalDetail?.actions?.[
        approvalDetail?.level_assign_to?.active_action || ""
      ]?.index || 0;
  return (
    <Box className={classes.container}>
      <Box className={classes.leftContainer}>
        <Box className={classes.leftContainerChild1}></Box>
        <Box className={classes.leftContainerChild2}></Box>
      </Box>
      <Box className={classes.rightContainer}>
        {approvalDetail?.actions && (
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            className={classes.stepper}
          >
            {steps?.map((data) => (
              <Step key={data.label}>
                <StepLabel>{data.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    approvalDetail: adminReducer.approvalDetail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalHead);
