
import * as actionTypes from "./actionTypes";
import { API } from "../../asset/api";

import { setWithExpiry, getWithExpiry } from "../../asset/utility";
import { notify } from "./notifyAction";

const setBusinessesLoading = (data) => {
 return {
  type: actionTypes.SET_BUSINESSES_LOADING,
  payload: data,
 };
};

const setStatus = (data) => {
 return {
  type: actionTypes.SET_STATUS,
  payload: data,
 };
};

const setBusinesses = (data) => {
 return {
  type: actionTypes.SET_BUSINESSES,
  payload: data,
 };
};
const setBusiness = (data) => {
 return {
  type: actionTypes.SET_BUSINESS,
  payload: data,
 };
};

const setError = (data) => {
 return {
  type: actionTypes.SET_ERROR,
  payload: data,
 };
};

export const getBusinesses = () => {
 return async (dispatch, getState) => {
  let token = getWithExpiry("token");
  dispatch(
   setBusinessesLoading({
    getBusinesses: true,
   })
  );
  const reqBody = {
   filters: {},
  };
  API.post("/GetBusinessDirectoryAdmin", JSON.stringify(reqBody), {
   headers: {
    token: token,
   },
   withCredentials: false,
  })
   .then((res) => {
    const { status, successMessage, errorMessage, states, error, directory } =
     res.data;

    if (status) {
     if (directory != null) {
      dispatch(setBusinesses({ businesses: directory }));
     }
    } else {
     dispatch(notify("No businesses registered", "info"));
    }
    dispatch(
     setBusinessesLoading({
      getBusinesses: false,
     })
    );
    if (error) dispatch(setError({ error: error }));
   })
   .catch((err) => {
    dispatch(setError({ error: err }));
    dispatch(notify(err?.response?.data?.message || err.message, "error"));
    dispatch(
     setBusinessesLoading({
      getBusinesses: false,
     })
    );
   });
 };
};

export const getBusiness = (data) => {
 return async (dispatch, getState) => {
  let token = getWithExpiry("token");
  dispatch(
   setBusinessesLoading({
    getBusinesses: true,
   })
  );
  const reqBody = {
   profile_id: data?.profile_id || null,
   business_id: data?.business_id || null,
  };
  API.post("/GetBusinessesById", JSON.stringify(reqBody), {
   headers: {
    token: token,
   },
   withCredentials: false,
  })
   .then((res) => {
    const { status, successMessage, errorMessage, states, error, business } =
     res.data;

    if (status) {
     if (business != null) {
      // console.log(business);
      dispatch(setBusiness({ business: business }));
     }
    } else {
     dispatch(notify("No businesses registered", "info"));
    }
    dispatch(
     setBusinessesLoading({
      getBusiness: false,
     })
    );
    if (error) dispatch(setError({ error: error }));
   })
   .catch((err) => {
    dispatch(setError({ error: err }));
    dispatch(notify(err?.response?.data?.message || err.message, "error"));
    dispatch(
     setBusinessesLoading({
      getBusiness: false,
     })
    );
   });
 };
};

const setBusinessActivities = (data) => {
 return { type: actionTypes.SET_BUSINESS_ACTIVITY, payload: data };
};
export const getBusinessActivitiesPM = (data) => {
 return async (dispatch, getState) => {
  //# HERE MAKE ASYNC CALLS.
  let storedToken = getWithExpiry("token");
  let body = { createdBy: data?.business_id };

  // Add 'createdBy' and 'id' to the request body only if they are provided
  // if (createdBy !== null) {
  //  body.createdBy = createdBy;
  // }

  // if (id !== null) {
  //  body.id = id;
  // }

  API.post("/getPmActivity", JSON.stringify(body), {
   headers: {
    token: storedToken,
   },
   withCredentials: false,
  })
   .then((res) => {
    const { status, succesMessage, errorMessage, data, error } = res.data;
    if (status) {
     dispatch(setBusinessActivities(data));
    } else {
     dispatch(
      notify(errorMessage || "Failed to fetch pm business activities", "error")
     );
    }
   })
   .catch((err) => {
    dispatch(
     notify(
      err?.response?.data?.message || "Failed to fetch pm business activities.",
      "error"
     )
    );
   });
 };
};

export const createBusinessActivity = (data) => {
 return async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
   let storedToken = getWithExpiry("token");
   const reqBody = {
    businessId: data?.businessId,
    title: data.title,
    description: data.description,
    skills: data.skills,
    categories: data.categories,
    ageGroup: data.ageGroup,
    location: data.location,
    duration: parseInt(data.duration),
    email: data.email,
    phone: data.phone,
    openingDate: data.openingDate,
    closingDate: data.closingDate,
    isOnline: data?.isOnline,
    isMultiple: data.isMultiple,
    url: data?.url || "",
    // scheduledAt: data.scheduledAt,
    rewards: data?.rewards || 0,
    educationPoints: data?.educationPoints || 0,
    threshold: data.threshold,
   };
   API.post("/createPmActivity", JSON.stringify(reqBody), {
    headers: { token: storedToken },
    withCredentials: false,
   })
    .then((res) => {
     const { status, successMessage, errorMessage, error } = res.data;
     if (status) {
      resolve(res);
      dispatch(notify(successMessage, "success"));
      dispatch(getBusinessActivitiesPM({ businessId: data?.businessId }));
     } else {
      reject(res);
      dispatch(notify(errorMessage, "error"));
     }
     if (error) {
      reject(res);
      // dispatch(
      //  setStatus({
      //   error: error,
      //  })
      // );
     }
    })
    .catch((err) => {
     reject();
     dispatch();
     // setStatus({
     //   error: err?.response?.data.error || err,
     // })
     dispatch(
      notify(
       err?.response?.data?.errorMessage || "Please try again by refreshing..!",
       "error"
      )
     );
    });
  });
 };
};
export const updatePmActivity = (data) => {
 return (dispatch, getState) => {
  return new Promise((resolve, reject) => {
   let storedToken = getWithExpiry("token");
   const reqBody = {
    id: data?._id,
    businessId: data?.businessId,
    title: data?.title,
    description: data.description,
    skills: data.skills,
    categories: data.categories,
    ageGroup: data.ageGroup,
    location: data.location,
    duration: parseInt(data.duration),
    email: data.email,
    phone: data.phone,
    openingDate: data.openingDate,
    closingDate: data.closingDate,
    isOnline: data?.isOnline,
    isMultiple: data.isMultiple,
    url: data?.url || "",
    // scheduledAt: data.scheduledAt,
    rewards: data?.rewards || 0,
    educationPoints: data?.educationPoints || 0,
    threshold: data.threshold,
   };

   API.post("/updatePmActivity", JSON.stringify(reqBody), {
    headers: { token: storedToken },
    withCredentials: false,
   })
    .then(async (res) => {
     const { status, successMessage, errorMessage, error } = res.data;
     if (status) {
      resolve(res);
      dispatch(notify(successMessage, "success"));
      // dispatch(getBusinessActivitiesPM({ businessId: data?.businessId }));
     } else {
      reject(res);
      dispatch(notify(errorMessage, "error"));
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 };
};

export const createPmActivity = (data) => {
 return async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
   let storedToken = getWithExpiry("token");
   const reqBody = {
    businessId: data.businessId,
    isOnline: data.isOnline,
    url: data.url,
    title: data.title,
    scheduledAt: data.scheduledAt,
    skills: data.skills,
    description: data.description,
    rewards: data.rewards,
    educationPoints: data.educationPoints,
    ageGroup: data.ageGroup,
    categories: data.categories,
    location: data.location,
    duration: data.duration,
    threshold: data.threshold,
    isMultiple: data.isMultiple,
    openingDate: data.openingDate,
    closingDate: data.closingDate,
    email: data.email,
    phone: data.phone,
   };

   API.post("createPmActivity", JSON.stringify(reqBody), {
    headers: { token: storedToken },
    withCredentials: false,
   })
    .then((res) => {
     const { status, successMessage, errorMessage, error } = res.data;
     if (status) {
      resolve(res);
      dispatch(notify(successMessage, "success"));

      dispatch(getPmActivity());
     } else {
      reject(res);
      dispatch(notify(errorMessage, "error"));
     }
     if (error) {
      reject(res);
      // dispatch(
      //  setStatus({
      //   error: error,
      //  })
      // );
     }
    })
    .catch((err) => {
     reject();
     dispatch();
     // setStatus({
     //   error: err?.response?.data.error || err,
     // })
     dispatch(
      notify(
       err?.response?.data?.errorMessage || "Please try again by refreshing..!",
       "error"
      )
     );
    });
  });
 };
};
const setPmActivity = (data) => {
 return {
  type: actionTypes.SET_PM_ACTIVITY,
  payload: data,
 };
};

export const getPmActivity = (payload = null) => {
 return async (dispatch, getState) => {
  let token = getWithExpiry("token");
  dispatch(
   setBusinessesLoading({
    getPmActivity: true,
   })
  );

  API.post(
   "getPmActivity",
   {},
   {
    headers: {
     token: token,
    },
    withCredentials: false,
   }
  )
   .then((res) => {
    const { status, successMessage, errorMessage, data } = res;
    if (status) {
     dispatch(setPmActivity(data));
    } else {
     dispatch(notify(errorMessage, "error"));
    }
    dispatch(setBusinessesLoading({ getPmActivity: false }));
   })
   .catch((err) => {
    dispatch(
     setBusinessesLoading({
      getPmActivity: false,
     })
    );
    dispatch(
     setStatus({
      error: err?.response?.data.error || err,
     })
    );
    dispatch(
     notify(
      err?.response?.data?.errorMessage || "Please try again by refresh..!",
      "error"
     )
    );
   });
 };
};

export const deletePmActivity = ({ id = null }) => {
 return async (dispatch, getState) => {
  //# HERE MAKE ASYNC CALLS.
  return new Promise((resolve, reject) => {
   let storedToken = getWithExpiry("token");
   let body = {
    id,
   };

   API.post("deletePmActivity", JSON.stringify(body), {
    headers: {
     token: storedToken,
    },
    withCredentials: false,
   })
    .then((res) => {
     const { status, succesMessage, errorMessage, error } = res.data;

     if (status) {
      resolve(res);
     } else {
      reject(res);
     }
    })
    .catch((err) => {
     dispatch(
      notify(
       err?.response?.data?.message || "Failed to delete business activity.",
       "error"
      )
     );
    });
  });
 };
};
