import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LaunchIcon from "@material-ui/icons/Launch";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import * as actionCreator from "../../../store/action/index";
import { connect } from "react-redux";
import Smallspinner from "../../../components/common/smallSpinner";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Clear";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import FormControl from "@material-ui/core/FormControl";
import { Box } from "@material-ui/core";
import Coin from "../../../asset/images/jsxImage/coin";
import Switch from "@material-ui/core/Switch";
import { API } from "../../../asset/api";
import { getWithExpiry } from "../../../asset/utility";

const styles = (theme) => ({
  root: {
    margin: 0,
    width: "100%",
  },
  closeButton: {
    color: theme.palette.grey[500],
  },

  dialogButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelDialogButtonStyle: {
    backgroundColor: "rgb(125, 125, 125)",
    color: "#fff",
    fontSize: 14,
    fontWeight: 500,
    margin: "0 0.5rem",
    textTransform: "capitalize",
  },
  confirmDialogButtonStyle: {
    backgroundColor: "rgb(53, 157, 182)",
    color: "#fff",
    fontSize: 14,
    fontWeight: 500,
    margin: "0 0.5rem",
    textTransform: "capitalize",
  },
});

const CustomSwitch = withStyles({
  switchBase: {
    color: "primary",
    "&$checked": {
      color: "#359DB6",
    },
    "&$checked + $track": {
      backgroundColor: "#359DB6",
    },
  },
  checked: {},
  track: {},
})(Switch);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        margin: 0,
        width: "100%",
      }}
      {...other}
    >
      <Typography style={{ fontSize: 18, marginRight: "5rem" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function CustomizedCryptopiaDialogs(props) {
  const {
    submitUserCryptopiaForm,
    notify,
    deleteAdditionalCryptopiaDocs,
    getCryptopiaAgreementForm,
    newDataCB,
    data,
    updateCryptopiaCoinsStatus,
    updateTable,
  } = props;
  const [open, setOpen] = useState(props.open);
  const [rowData, setRowData] = useState(null);
  const [name, setName] = useState(null);
  const [userName, setUserName] = useState(null);
  const [email, setEmail] = useState(null);
  const [notes, setNotes] = useState(null);
  const [loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [targetId, setTargetId] = useState(null);
  const [targetUrl, setTargetUrl] = useState(null);
  const [dataStatus, setDataStatus] = useState(null);
  const [liquidatorSwitchStatus, setLiquidatorSwitchStatus] = useState(false);
  const [isMailSent, setIsMailSent] = useState(false);
  const [userSwitchStatus, setUserSwitchStatus] = useState(false);
  const [idx, setIdx] = useState(null);
  const [changes, setChanges] = useState(false);
  const [editableFiles, setEditableFiles] = useState([]);
  const [updatedFiles, setUpdatedFiles] = useState([]);
  const [coinNotes, setCoinNotes] = useState("");
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    if (data) {
      setRowData(data);
    }
  }, [data]);

  useEffect(() => {
    if (props.open && rowData) {
      if (
        liquidatorSwitchStatus != rowData?.isCoinReceivedByLiquidator ||
        userSwitchStatus != rowData?.isCoinTransferedToUser ||
        isMailSent != rowData?.isMailSent ||
        coinNotes !== rowData.note
      ) {
        setChanges(true);
      } else {
        setChanges(false);
      }
    }
  }, [
    liquidatorSwitchStatus,
    userSwitchStatus,
    isMailSent,
    props.open,
    coinNotes,
  ]);

  useEffect(() => {
    if (rowData) {
      setName(rowData?.name || "");
      setUserName(rowData?.username || "");
      setEmail(rowData?.cryptopiaEmail || "");
      setLiquidatorSwitchStatus(rowData?.isCoinReceivedByLiquidator);
      setUserSwitchStatus(rowData?.isCoinTransferedToUser);
      setIsMailSent(rowData?.isMailSent);
      setCoinNotes(rowData?.note);
    }
  }, [rowData]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    setUploadFiles([]);
    setRowData(null);
    setUploadFiles([]);
    setUpdatedFiles([]);
    setLoader(false);
    setFirstLoad(false);
    setEditableFiles([]);
    props.parentMethod();
  };

  const handleVerify = () => {
    setLoader(true);
    const reqBody = {
      formId: rowData?._id,
      note: notes,
    };
    submitUserCryptopiaForm(reqBody)
      .then((data) => {
        if (data) {
          setLoader(false);
          notify("Form Updated Successfully", "success");
          props.parentMethod();
        } else {
          notify(data, "error");
          setLoader(false);
          props.parentMethod();
        }
      })
      .catch((err) => {
        notify(err, "error");
        setLoader(false);
        props.parentMethod();
      });
  };

  const handleUpdate = () => {
    if (uploadFiles?.length > 0 && !changes) {
      setLoader(true);
      let storedToken = getWithExpiry("token");
      let data = new FormData();
      data.append("id", rowData?._id);

      data.append("token", storedToken);

      for (let i = 0; i < uploadFiles?.length; i++) {
        data.append("docs", uploadFiles[i]);
      }

      API.post("/uploadAdditionalCryptopiaDocs", data, {
        "Content-Type": "multipart/form-data",
      })
        .then((res) => {
          let { status, successMessage, errorMessage, error } = res.data;
          if (status) {
            setLoader(false);
            notify(successMessage, "success");
            setUploadFiles([]);
            setUpdatedFiles([]);
            setEditableFiles([]);
            updateTable();
          } else {
            setLoader(false);
            notify(errorMessage, "error");
          }
        })
        .catch((err) => {
          setLoader(false);
          notify(
            err?.message || "Coudn't upload file... please try again",
            "error"
          );
        });
    } else if (uploadFiles?.length < 1 && changes) {
      setLoader(true);
      let data = {
        id: rowData?._id,
        isCoinReceivedByLiquidator: liquidatorSwitchStatus,
        isCoinTransferedToUser: userSwitchStatus,
        isMailSent:isMailSent,
        note: coinNotes,
      };
      updateCryptopiaCoinsStatus(data)
        .then((res) => {
          let { status, successMessage, errorMessage, error } = res.data;
          if (status) {
            setLoader(false);
            notify(successMessage, "success");
            setChanges(false);
            updateTable();
          } else {
            setLoader(false);
            notify(errorMessage, "error");
            setChanges(false);
          }
        })
        .catch((err) => {
          setLoader(false);
          setChanges(false);
          notify(
            err?.message || "Coudn't upload file... please try again",
            "error"
          );
        });
    } else {
      setLoader(true);
      let storedToken = getWithExpiry("token");
      let data = new FormData();
      data.append("id", rowData?._id);

      data.append("token", storedToken);

      for (let i = 0; i < uploadFiles?.length; i++) {
        data.append("docs", uploadFiles[i]);
      }

      API.post("/uploadAdditionalCryptopiaDocs", data, {
        "Content-Type": "multipart/form-data",
      })
        .then((res) => {
          let { status, successMessage, errorMessage, error } = res.data;
          if (status) {
            setLoader(false);
            notify(successMessage, "success");
            setUploadFiles([]);
            setUpdatedFiles([]);
            setEditableFiles([]);
            updateTable();
          } else {
            setLoader(false);
            notify(errorMessage, "error");
          }
        })
        .catch((err) => {
          setLoader(false);
          notify(
            err?.message || "Coudn't upload file... please try again",
            "error"
          );
        });

      let anotherData = {
        id: rowData?._id,
        isCoinReceivedByLiquidator: liquidatorSwitchStatus,
        isCoinTransferedToUser: userSwitchStatus,
        note: coinNotes,
      };
      updateCryptopiaCoinsStatus(anotherData)
        .then((res) => {
          let { status, successMessage, errorMessage, error } = res.data;
          if (status) {
            notify(successMessage, "success");
            setChanges(false);
            updateTable();
          } else {
            setLoader(false);
            notify(errorMessage, "error");
            setChanges(false);
          }
        })
        .catch((err) => {
          setLoader(false);
          setChanges(false);
          notify(
            err?.message || "Coudn't upload file... please try again",
            "error"
          );
        });
    }
  };

  const onFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const totalSize = selectedFiles.reduce(
      (acc, file) => acc + file.size / 1024 / 1024,
      0
    );

    const totalUploadSize = uploadFiles.reduce(
      (acc, file) => acc + file.size / 1024 / 1024,
      0
    );

    if (totalSize + totalUploadSize > 5) {
      notify(
        "Total size of selected files and existing files should be less than 5 MB",
        "error"
      );
      return;
    }

    const newFiles = [...uploadFiles, ...selectedFiles];
    const newEditableFiles = Array(newFiles.length).fill(false);

    setUploadFiles(newFiles);
    setFirstLoad(true);
    setUpdatedFiles([...updatedFiles, ...selectedFiles]);
    setEditableFiles((prevEditableFiles) => [
      ...prevEditableFiles,
      ...newEditableFiles,
    ]);
  };

  const onDeleteAPIFileHandler = (id, url) => {
    setConfirm(true);
    setTargetId(id);
    setTargetUrl(url);
    setDataStatus("old");
    const fileInput = document.getElementById("file");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const onDeleteLocalFileHandler = (index) => {
    setIdx(index);
    setConfirm(true);
    setDataStatus("new");
    const fileInput = document.getElementById("file");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const removeHandler = () => {
    setBtnLoader(true);
    if (dataStatus == "old") {
      let data = {
        id: targetId,
        docs: [targetUrl],
      };
      deleteAdditionalCryptopiaDocs(data)
        .then((data) => {
          if (data) {
            setBtnLoader(false);
            notify("Docs deleted successfully", "success");
            setConfirm(false);

            updateTable();
          } else {
            notify(data, "error");
            setBtnLoader(false);
            setConfirm(false);
            updateTable();
          }
        })
        .catch((err) => {
          notify(err, "error");
          setBtnLoader(false);
          setConfirm(false);
          updateTable();
        });
    } else {
      if (idx >= 0 && idx < uploadFiles?.length) {
        const updatedData = [
          ...uploadFiles.slice(0, idx),
          ...uploadFiles.slice(idx + 1),
        ];
        setUploadFiles(updatedData);
        setBtnLoader(false);
        setConfirm(false);
      }
    }
  };

  const handleLiquidatorSwitchChange = () => {
    setLiquidatorSwitchStatus(!liquidatorSwitchStatus);
  };

  const handleUserSwitchChange = () => {
    setUserSwitchStatus(!userSwitchStatus);
  };

  const handleIsMailSentChange = () => {
    setIsMailSent(!isMailSent);
  };

  const handleEditClick = (index) => {
    setFirstLoad(false);
    setEditableFiles((prevEditableFiles) => {
      const updatedFiles = Array(prevEditableFiles.length).fill(false);
      updatedFiles[index] = true;
      return updatedFiles;
    });
  };

  const handleDisableAll = () => {
    const newEditableFiles = editableFiles.map(() => false);
    setEditableFiles(newEditableFiles);
  };

  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {rowData?.isVerified
            ? "Update Cryptopia Agreement"
            : "Generate Cryptopia Agreement"}
        </DialogTitle>
        <DialogContent dividers>
          {rowData ? (
            !rowData?.isVerified ? (
              <>
                <TextField
                  disabled={true}
                  variant="outlined"
                  margin="dense"
                  id="name"
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{ width: "100%" }}
                />
                <TextField
                  disabled={true}
                  variant="outlined"
                  margin="dense"
                  id="username"
                  label="User Name"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  style={{ width: "100%", marginTop: "1rem" }}
                />
                <TextField
                  disabled={true}
                  variant="outlined"
                  margin="dense"
                  id="email"
                  label="Cryptopia Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ width: "100%", marginTop: "1rem" }}
                />
                <TextField
                  id="outlined-multiline-static"
                  label="Notes"
                  multiline
                  rows={4}
                  value={notes}
                  variant="outlined"
                  onChange={(e) => setNotes(e.target.value)}
                  style={{ width: "100%", marginTop: "1rem" }}
                />
              </>
            ) : (
              <>
                <Grid
                  container
                  xs={12}
                  style={{
                    flexGrow: 1,
                    border: "1px dashed transparent",
                    borderColor: localStorage?.getItem("dark")
                      ? "#000"
                      : "#fff",
                    borderRadius: 2,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: "center", padding: "0.5rem" }}
                  >
                    <Typography>Upload Document</Typography>
                    <FormControl
                      style={{
                        width: "100%",
                      }}
                    >
                      <input
                        accept=".pdf,"
                        style={{ display: "none", width: "100%" }}
                        id="file"
                        name="file"
                        onChange={(e) => onFileChange(e)}
                        type="file"
                        multiple
                      />
                      <label htmlFor="file">
                        <IconButton
                          aria-label="upload picture"
                          component="span"
                        >
                          <CloudUploadIcon />
                        </IconButton>
                      </label>
                    </FormControl>
                  </Grid>
                </Grid>

                {rowData?.additionalDocs?.length > 0 ||
                uploadFiles?.length > 0 ? (
                  <Grid
                    container
                    item
                    xs={12}
                    style={{
                      maxHeight: "150px",
                      overflowY: "auto",
                      border: "1px solid transparent",
                      borderColor: localStorage?.getItem("dark")
                        ? "#000"
                        : "#fff",
                      marginTop: "1rem",
                      borderRadius: 5,
                    }}
                  >
                    {rowData?.additionalDocs?.map((file, index) => (
                      <Grid
                        item
                        xs={12}
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          overflow: "hidden",
                        }}
                      >
                        <Grid
                          item
                          xs={9}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            aria-label="upload picture"
                            component="span"
                            style={{ color: "#46C190" }}
                          >
                            <DoneIcon />
                          </IconButton>
                          <Typography
                            variant="body1"
                            display="block"
                            style={{ maxWidth: "60%", wordWrap: "break-word" }}
                          >
                            {file.docName}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <IconButton
                            aria-label="upload picture"
                            component="span"
                          >
                            <DeleteForeverIcon
                              onClick={() => {
                                onDeleteAPIFileHandler(
                                  rowData._id,
                                  file.docUrl
                                );
                              }}
                            />
                          </IconButton>
                          <IconButton
                            aria-label="upload picture"
                            component="span"
                          >
                            <LaunchIcon
                              onClick={() => {
                                const urlToOpen = `${process.env.REACT_APP_SPACE_LINK}/${file?.docUrl}`;
                                window.open(urlToOpen, "_blank");
                              }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}

                    {uploadFiles?.map((file, index) => (
                      <Grid
                        item
                        xs={12}
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          overflow: "hidden",
                        }}
                      >
                        <Grid
                          item
                          xs={editableFiles[index] ? 12 : 9}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {editableFiles.indexOf(true) == index || firstLoad ? (
                            <IconButton
                              aria-label="upload picture"
                              component="span"
                              style={{ color: "#46C190" }}
                              disabled={loader}
                            >
                              <EditIcon
                                onClick={() => handleEditClick(index)}
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              aria-label="upload picture"
                              component="span"
                              style={{ color: "#46C190", opacity: 0.3 }}
                              disabled={true}
                            >
                              <EditIcon
                                onClick={() => handleEditClick(index)}
                              />
                            </IconButton>
                          )}

                          {editableFiles[index] ? (
                            <>
                              <TextField
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                defaultValue={updatedFiles[index]?.name}
                                onChange={(e) => {
                                  const newFiles = [...updatedFiles];
                                  if (!newFiles[index]) {
                                    newFiles[index] = new File([], "", {
                                      type: "",
                                    });
                                  }
                                  newFiles[index] = new File(
                                    [newFiles[index]],
                                    e.target.value,
                                    {
                                      type: newFiles[index].type,
                                      lastModified:
                                        newFiles[index].lastModified,
                                    }
                                  );
                                  setUpdatedFiles(newFiles);
                                }}
                              />

                              <IconButton
                                aria-label="cancel"
                                component="span"
                                onClick={() => {
                                  handleDisableAll();
                                  setFirstLoad(true);
                                  setUpdatedFiles([...uploadFiles]);
                                }}
                                disabled={!updatedFiles[index]?.name.trim()}
                              >
                                <CancelIcon />
                              </IconButton>
                              <IconButton
                                aria-label="done"
                                component="span"
                                onClick={() => {
                                  if (updatedFiles[index]?.name.trim() !== "") {
                                    setUploadFiles(updatedFiles);
                                    handleDisableAll();
                                    setFirstLoad(true);
                                  }
                                }}
                                disabled={!updatedFiles[index]?.name.trim()}
                              >
                                <DoneIcon />
                              </IconButton>
                            </>
                          ) : (
                            <Typography
                              variant="body1"
                              display="block"
                              style={{
                                maxWidth: "60%",
                                wordWrap: "break-word",
                              }}
                            >
                              {file.name}
                            </Typography>
                          )}
                        </Grid>

                        {!editableFiles[index] ? (
                          <Grid
                            item
                            xs={3}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <IconButton
                              aria-label="upload picture"
                              component="span"
                              disabled={loader}
                            >
                              <DeleteForeverIcon
                                onClick={() => onDeleteLocalFileHandler(index)}
                              />
                            </IconButton>
                            <IconButton
                              aria-label="upload picture"
                              component="span"
                            >
                              <LaunchIcon style={{ opacity: 0 }} />
                            </IconButton>
                          </Grid>
                        ) : null}
                      </Grid>
                    ))}
                  </Grid>
                ) : null}

                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    border: "1px solid transparent",
                    borderColor: localStorage?.getItem("dark")
                      ? "#000"
                      : "#fff",
                    marginTop: "1rem",
                    borderRadius: 5,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      overflow: "hidden",
                    }}
                  >
                    <Grid
                      item
                      xs={10}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        aria-label="upload picture"
                        component="span"
                        style={{ color: "#46C190" }}
                      >
                        <Coin />
                      </IconButton>
                      <Typography variant="body1" display="block">
                        Coin Received By Liquidator
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <CustomSwitch
                        disabled={loader}
                        checked={liquidatorSwitchStatus}
                        onChange={() => handleLiquidatorSwitchChange()}
                        name="checkedB"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    border: "1px solid transparent",
                    borderColor: localStorage?.getItem("dark")
                      ? "#000"
                      : "#fff",
                    marginTop: "1rem",
                    borderRadius: 5,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      overflow: "hidden",
                    }}
                  >
                    <Grid
                      item
                      xs={10}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        aria-label="upload picture"
                        component="span"
                        style={{ color: "#46C190" }}
                      >
                        <Coin />
                      </IconButton>
                      <Typography variant="body1" display="block">
                        Final Cog Settlement Paid
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <CustomSwitch
                        disabled={loader}
                        checked={userSwitchStatus}
                        onChange={() => handleUserSwitchChange()}
                        name="checkedB"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    border: "1px solid transparent",
                    borderColor: localStorage?.getItem("dark")
                      ? "#000"
                      : "#fff",
                    marginTop: "1rem",
                    borderRadius: 5,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      overflow: "hidden",
                    }}
                  >
                    <Grid
                      item
                      xs={10}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        aria-label="upload picture"
                        component="span"
                        style={{ color: "#46C190" }}
                      >
                        <Coin />
                      </IconButton>
                      <Typography variant="body1" display="block">
                        Mail sent to liquidator
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <CustomSwitch
                        disabled={loader}
                        checked={isMailSent}
                        onChange={() => handleIsMailSentChange()}
                        name="checkedB"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <TextField
                  id="outlined-multiline-static"
                  label="Notes"
                  multiline
                  rows={4}
                  defaultValue={rowData?.note}
                  variant="outlined"
                  onChange={(e) => setCoinNotes(e.target.value)}
                  style={{ width: "100%", marginTop: "1rem" }}
                />
              </>
            )
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            style={{
              backgroundColor: "#359DB6",
              color: "#FFFFFF",
            }}
          >
            Close
          </Button>
          {rowData?.isVerified ? (
            <Button
              disabled={
                loader ||
                (uploadFiles?.length < 1 && !changes) ||
                editableFiles.some((value) => value == true)
              }
              onClick={() => handleUpdate()}
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#359DB6",
                color: "#FFFFFF",
                height: "35px",
                opacity:
                  (uploadFiles?.length < 1 && !changes) ||
                  editableFiles.some((value) => value == true)
                    ? 0.3
                    : 1,
              }}
            >
              {loader ? <Smallspinner /> : <Typography>Update</Typography>}
            </Button>
          ) : (
            <Button
              disabled={loader || !(notes?.length > 0)}
              onClick={() => handleVerify()}
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#359DB6",
                color: "#FFFFFF",
                height: "35px",
                opacity: notes?.length > 0 ? 1 : 0.3,
              }}
            >
              {loader ? <Smallspinner /> : <Typography> Verify</Typography>}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={confirm}
        onClose={() => {
          setConfirm(false);
        }}
      >
        <Box style={{ padding: 26 }}>
          <Typography
            style={{ fontSize: 19, fontWeight: 400, marginBottom: 50 }}
          >
            Are you sure you want to remove the uploaded document?
          </Typography>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{
                backgroundColor: "rgb(125, 125, 125)",
                color: "#fff",
                fontSize: 14,
                fontWeight: 500,
                margin: "0 0.5rem",
                textTransform: "capitalize",
              }}
              onClick={() => {
                setConfirm(false);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={btnLoader}
              style={{
                backgroundColor: "rgb(53, 157, 182)",
                color: "#fff",
                fontSize: 14,
                fontWeight: 500,
                margin: "0 0.5rem",
                textTransform: "capitalize",
                height: "35px",
              }}
              onClick={() => {
                removeHandler();
              }}
            >
              {btnLoader ? <Smallspinner /> : "Remove"}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({ adminReducer }) => {
  return {
    admin: adminReducer.admin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateAdmin: (data) => dispatch(actionCreator.updateAdmin(data)),
    updateAdminEmail: (data) => dispatch(actionCreator.updateAdminEmail(data)),
    submitUserCryptopiaForm: (data) =>
      dispatch(actionCreator.submitUserCryptopiaForm(data)),
    deleteAdditionalCryptopiaDocs: (data) =>
      dispatch(actionCreator.deleteAdditionalCryptopiaDocs(data)),
    updateCryptopiaCoinsStatus: (data) =>
      dispatch(actionCreator.updateCryptopiaCoinsStatus(data)),
    getCryptopiaAgreementForm: (data) =>
      dispatch(actionCreator.getCryptopiaAgreementForm(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizedCryptopiaDialogs);
