import React from "react";
import { Paper } from "@material-ui/core";
import CustomTabs from "./Tabs/index";
import UserDetails from "./userDetails";
import UserActivity from "./userActivity";
import KycDetails from "./kyc";
import Styles from "../../../../components/styles";
import * as actionCreator from "../../../../store/action/index";
import { connect } from "react-redux";
import { useEffect } from "react";

const UserInfo = ({ user, getUserKycData }) => {
  const classes = Styles();
  const headings = ["User Details", "User Activity", "Kyc Details"];
  const contents = [<UserDetails />, <UserActivity />, <KycDetails />];
  useEffect(() => {
    getUserKycData(user?._id);
  }, []);

  return (
    <>
      {/* <Paper className={classes.paper} elevation={5}> */}
      <CustomTabs headings={headings} contents={contents} />
      {/* </Paper> */}
    </>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    user: adminReducer.user,
    kycData: adminReducer.kycData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
    getAdmin: () => dispatch(actionCreator.getAdmin()),
    getUser: (data) => dispatch(actionCreator.getUser(data)),
    getUserKycData: (data) => dispatch(actionCreator.getUserKycData(data)),
    // getKycImageData: (data) => dispatch(actionCreator.getKycImageData(data)),
    // getKycImageDataB: (data) => dispatch(actionCreator.getKycImageDataB(data)),
    // getKycImageDataS: (data) => dispatch(actionCreator.getKycImageDataS(data)),
    // getKycDocsData: (data) => dispatch(actionCreator.getKycDocsData(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
