import React from "react";
import { TextField, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { propTypes } from "qrcode.react";

const useStyles = makeStyles({
  root: {
    color: "white",
    fontSize: 14,
    top: -3,
  },
  rootInput: {
    display: "flex",
    alignItems: "center",
    color: "white",
    height: "50px",
  },
  notchedOutline: {
    borderColor: "#DCDCDC",
  },
  input: {
    // padding: "10px 14px",
  },
  textInput: {
    width: "100%",
  },
});
const CommonSelect = ({
  error,
  helperText,
  onChange,
  title,
  defaultValue,
  options,
}) => {
  const classes = useStyles();
  return (
    <TextField
      label={title}
      defaultValue={defaultValue}
      InputLabelProps={{
        classes: {
          root: classes.root,
        },
      }}
      select
      margin="dense"
      InputProps={{
        classes: {
          root: classes.rootInput,
          icon: classes.icon,
          //focused: classes.notchedOutline,
          input: classes.input,
          notchedOutline: classes.notchedOutline,
        },
      }}
      className={classes.textInput}
      variant="outlined"
      error={error}
      helperText={helperText}
      onChange={onChange}
    >
      {options?.map((option, index) => {
        return (
          <MenuItem key={index} value={option.value}>
            {option.name}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default CommonSelect;
