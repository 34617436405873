import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import * as actionCreator from "../../../store/action/index";
import { connect } from "react-redux";
import Smallspinner from "../../../components/common/smallSpinner";
import { Select, MenuItem, InputLabel } from "@material-ui/core";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Autocomplete } from "@material-ui/lab";

const styles = (theme) => ({
  root: {
    margin: 0,
    width: "100%",
  },
  closeButton: {
    color: theme.palette.grey[500],
  },

  dialogButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelDialogButtonStyle: {
    backgroundColor: "rgb(125, 125, 125)",
    color: "#fff",
    fontSize: 14,
    fontWeight: 500,
    margin: "0 0.5rem",
    textTransform: "capitalize",
  },
  confirmDialogButtonStyle: {
    backgroundColor: "rgb(53, 157, 182)",
    color: "#fff",
    fontSize: 14,
    fontWeight: 500,
    margin: "0 0.5rem",
    textTransform: "capitalize",
  },
  inputBaseInput: {
    cursor: "pointer",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        margin: 0,
        width: "100%",
      }}
      {...other}
    >
      <Typography style={{ fontSize: 18, marginRight: "5rem" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function CustomizedKycDialog(props) {
  const { notify, data, updateTable, updateKyc } = props;

  const [open, setOpen] = useState(props.open);
  const [rowData, setRowData] = useState(null);
  const [userName, setUserName] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [documentType, setDocumentType] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [verificationPic, setVerificationPic] = useState(null);
  const [kycStatus, setKycStatus] = useState(null);
  const [notes, setNotes] = useState("");
  const [loader, setLoader] = useState(false);
  const [changes, setChanges] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [isFrontViewerOpen, setIsFrontViewerOpen] = useState(false);
  const [isBackViewerOpen, setIsBackViewerOpen] = useState(false);
  const [IsVerificationViewerOpen, setIsVerificationViewerOpen] =
    useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [value, setValue] = React.useState("");

  const predefinedTexts = [
    "Your KYC is approved",
    "Your KYC is declined",
    "Please reupload doc",
    "Please hold ID next to your face",
  ];

  useEffect(() => {
    setValue("");
  }, []);

  const getFileType = (url) => {
    const fileExtension = url.split(".").pop().toLowerCase();
    return fileExtension;
  };

  useEffect(() => {
    if (data) {
      setRowData(data);
    }
  }, [data]);

  useEffect(() => {
    if (props.open && rowData) {
      if (
        kycStatus !== rowData?.status &&
        notes !== "" &&
        notes !== rowData?.note
      ) {
        setChanges(true);
      } else {
        setChanges(false);
      }
    }
  }, [props.open, kycStatus, notes]);

  useEffect(() => {
    if (rowData) {
      setUserName(rowData?.userName || "");
      setFirstName(rowData?.firstName || "");
      setLastName(rowData?.lastName || "");
      setDocumentType(rowData?.docType || "");
      setDocuments(rowData?.docs || null);
      setVerificationPic(rowData?.userVerificationPic || null);
      setKycStatus(rowData?.status || null);
      setNotes(rowData?.note || "");
    }
  }, [rowData]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (event.target.value === "approved") {
      setNotes("Approved");
    } else {
      setNotes("Declined");
    }
  };

  const handleClose = () => {
    setRowData(null);
    setLoader(false);
    setFirstLoad(false);
    setChanges(false);
    setValue("");
    props.parentMethod();
  };

  const handleUpdate = () => {
    setLoader(true);
    let body = {
      id: rowData?._id,
      note: notes,
      isApproved: kycStatus == "completed" ? true : false,
      isDeleted: kycStatus == "deleted" ? true : false,
    };
    updateKyc(body)
      .then((res) => {
        if (res.data.status) {
          setLoader(false);
          notify(res.data.successMessage, "success");
          handleClose();
          updateTable();
        } else {
          setLoader(false);
          notify(res.data.errorMessage, "errro");
        }
      })
      .catch((err) => {
        setLoader(false);
        notify(
          err?.message || "Coudn't upload file... please try again",
          "error"
        );
      });
  };

  const handleFrontImageClick = (url) => {
    setIsFrontViewerOpen(true);
  };

  const handleBackImageClick = (url) => {
    setIsBackViewerOpen(true);
  };

  const handleVerificationImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsVerificationViewerOpen(true);
  };

  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Update Kyc Details
        </DialogTitle>
        <DialogContent dividers>
          <>
            <TextField
              margin="dense"
              id="username"
              label="User Name"
              value={userName}
              style={{
                width: "100%",
                marginTop: "1rem",
              }}
              inputProps={{ readOnly: true }}
            />
            <TextField
              margin="dense"
              id="fName"
              label="First Name"
              value={firstName}
              style={{ width: "100%", marginTop: "1rem" }}
              inputProps={{ readOnly: true }}
            />
            <TextField
              margin="dense"
              id="lname"
              label="Last Name"
              value={lastName}
              style={{ width: "100%", marginTop: "1rem" }}
              inputProps={{ readOnly: true }}
            />

            <TextField
              margin="dense"
              id="docType"
              label="Document Type"
              value={documentType}
              style={{ width: "100%", marginTop: "1rem" }}
              inputProps={{ readOnly: true }}
            />
            <Grid style={{ marginTop: "1.5rem" }}>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                Documents
              </Typography>

              {documents?.length > 0 ? (
                documents.map((item, index) => {
                  const fileType = getFileType(item.url);
                  const file = `${process.env.REACT_APP_SPACE_LINK}/${item.url}`;

                  if (documents.length === 1) {
                    return (
                      <div
                        key={index}
                        style={{
                          textAlign: "center",
                          marginTop: "1.5rem",
                          border: "0.1px solid #fff",
                          padding: "1rem",
                        }}
                      >
                        <Typography
                          style={{ marginBottom: "0.5rem", fontWeight: "bold" }}
                        >
                          Front Image
                        </Typography>

                        {!isFrontViewerOpen && fileType == "pdf" && (
                          <iframe
                            src={file}
                            title="Front Document"
                            width="100%"
                            height="400"
                            style={{ border: "none" }}
                          ></iframe>
                        )}
                        {!isFrontViewerOpen &&
                          ["png", "jpg", "jpeg"].includes(fileType) && (
                            <img
                              src={`${process.env.REACT_APP_SPACE_LINK}/${item.url}`}
                              alt={item.name}
                              style={{ maxWidth: "100%", maxHeight: "200px" }}
                              onClick={() => handleFrontImageClick()}
                            />
                          )}
                        {isFrontViewerOpen && (
                          <Lightbox
                            image={`${process.env.REACT_APP_SPACE_LINK}/${item.url}`}
                            title="Image Title"
                            onClose={() => setIsFrontViewerOpen(false)}
                          />
                        )}
                      </div>
                    );
                  } else if (documents.length === 2) {
                    return (
                      <div
                        key={index}
                        style={{
                          marginTop: "1rem",
                          border: "0.1px solid #fff",
                          textAlign: "center",
                          padding: "1rem",
                        }}
                      >
                        {index === 0 ? (
                          <div>
                            <Typography style={{ marginBottom: "0.5rem" }}>
                              Front Image
                            </Typography>
                            {!isFrontViewerOpen && fileType == "pdf" && (
                              <iframe
                                src={file}
                                width="100%"
                                height="400"
                                title="Front Document"
                                style={{ border: "none" }}
                              ></iframe>
                            )}
                            {!isFrontViewerOpen &&
                              ["png", "jpg", "jpeg"].includes(fileType) && (
                                <img
                                  src={`${process.env.REACT_APP_SPACE_LINK}/${item.url}`}
                                  alt={item.name}
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "200px",
                                  }}
                                  onClick={() => handleFrontImageClick()}
                                />
                              )}
                            {isFrontViewerOpen && (
                              <Lightbox
                                image={`${process.env.REACT_APP_SPACE_LINK}/${item.url}`}
                                onClose={() => setIsFrontViewerOpen(false)}
                              />
                            )}
                          </div>
                        ) : (
                          <div>
                            <Typography style={{ marginBottom: "0.5rem" }}>
                              Back Image
                            </Typography>
                            {!isBackViewerOpen && fileType == "pdf" && (
                              <iframe
                                src={file}
                                width="100%"
                                height="400"
                                title="Back Document"
                                style={{ border: "none" }}
                              ></iframe>
                            )}
                            {!isBackViewerOpen &&
                              ["png", "jpg", "jpeg"].includes(fileType) && (
                                <img
                                  src={`${process.env.REACT_APP_SPACE_LINK}/${item.url}`}
                                  alt={item.name}
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "200px",
                                  }}
                                  onClick={() => handleBackImageClick()}
                                />
                              )}

                            {isBackViewerOpen && (
                              <Lightbox
                                image={`${process.env.REACT_APP_SPACE_LINK}/${item.url}`}
                                onClose={() => setIsBackViewerOpen(false)}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <Typography
                  style={{ textAlign: "center", marginTop: "1.5rem" }}
                >
                  No Documents Provided
                </Typography>
              )}
            </Grid>
            <Grid style={{ marginTop: "2rem" }}>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                Verification Photos
              </Typography>
              {verificationPic?.length > 0 ? (
                verificationPic.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        textAlign: "center",
                        marginTop: "1.5rem",
                        marginTop: "1rem",
                        border: "0.1px solid #fff",
                        padding: "1rem",
                      }}
                    >
                      {!IsVerificationViewerOpen && (
                        <img
                          src={`${process.env.REACT_APP_SPACE_LINK}/${item.url}`}
                          alt={item.name}
                          style={{ maxWidth: "100%", maxHeight: "200px" }}
                          onClick={() => handleVerificationImageClick(index)}
                        />
                      )}
                      {IsVerificationViewerOpen &&
                        currentImageIndex === index && (
                          <Lightbox
                            image={`${process.env.REACT_APP_SPACE_LINK}/${item.url}`}
                            onClose={() => setIsVerificationViewerOpen(false)}
                          />
                        )}
                    </div>
                  );
                })
              ) : (
                <Typography
                  style={{ textAlign: "center", marginTop: "1.5rem" }}
                >
                  No Documents Provided
                </Typography>
              )}
            </Grid>
            <div style={{ width: "100%", marginTop: "1rem" }}>
              <InputLabel
                style={{ fontSize: "12px" }}
                id="demo-simple-select-label"
              >
                Kyc Status
              </InputLabel>
              <Select
                style={{ width: "100%" }}
                value={kycStatus}
                variant={"standard"}
                onChange={(e) => {
                  setKycStatus(e.target.value);
                }}
              >
                <MenuItem key={1} value={"declined"}>
                  Declined
                </MenuItem>
                <MenuItem key={2} value={"deleted"}>
                  Deleted
                </MenuItem>
                <MenuItem key={3} value={"completed"}>
                  Completed
                </MenuItem>
              </Select>
            </div>
            {rowData?.status == "underprocess" && (
              <Grid
                style={{
                  marginTop: "2rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Autocomplete
                  fullWidth
                  // value={{ value: props?.defaultValue }}
                  value={notes}
                  id="admin_notes"
                  name="admin_notes"
                  onChange={(e, value) => setNotes(value)}
                  options={predefinedTexts}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a note"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}
            <TextField
              id="outlined-multiline-static"
              label="Notes"
              multiline
              rows={4}
              value={notes}
              variant="outlined"
              onChange={(e) => setNotes(e.target.value)}
              style={{ width: "100%", marginTop: "1rem" }}
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            style={{
              backgroundColor: "#359DB6",
              color: "#FFFFFF",
            }}
          >
            Close
          </Button>

          <Button
            disabled={loader || !changes}
            onClick={() => handleUpdate()}
            type="submit"
            variant="contained"
            style={{
              backgroundColor: "#359DB6",
              color: "#FFFFFF",
              height: "35px",
              opacity: !changes ? 0.3 : 1,
            }}
          >
            {loader ? <Smallspinner /> : <Typography>Update</Typography>}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({ adminReducer }) => {
  return {
    admin: adminReducer.admin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateAdmin: (data) => dispatch(actionCreator.updateAdmin(data)),
    updateAdminEmail: (data) => dispatch(actionCreator.updateAdminEmail(data)),
    submitUserCryptopiaForm: (data) =>
      dispatch(actionCreator.submitUserCryptopiaForm(data)),
    deleteAdditionalCryptopiaDocs: (data) =>
      dispatch(actionCreator.deleteAdditionalCryptopiaDocs(data)),
    updateCryptopiaCoinsStatus: (data) =>
      dispatch(actionCreator.updateCryptopiaCoinsStatus(data)),
    getCryptopiaAgreementForm: (data) =>
      dispatch(actionCreator.getCryptopiaAgreementForm(data)),
    updateKyc: (data) => dispatch(actionCreator.updateKyc(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizedKycDialog);
