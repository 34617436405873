import * as React from "react";
import { useState } from "react";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  Divider,
  Fade,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { connect } from "react-redux";
import * as actionCreator from "../../store/action/index";
import Styles from "../../../src/components/styles/index";

const OpennModal = (props) => {
  const { admin, assignAdmin, getApproval, callBack } = props;
  const [open, setOpen] = React.useState(false);
  const [activeApproval, setActiveApproval] = useState(null);
  const handleOpen = () => setOpen(true);

  const classes = Styles();
  React.useEffect(() => {
    setOpen(props.open);
    setActiveApproval(props.open);
  }, [props.open]);

  const handleAssignBtn = () => {
    assignAdmin({
      adminId: admin?._id,
      approvalId: props.open?._id,
      adminLevel: admin?.level,
      adminType: admin?.type,
    }).then(() => {
      if (callBack) {
        callBack();
      }
      handleClose();
    });
  };
  const handleClose = () => {
    setOpen(false);
    //  getApproval({ adminType: admin?.type, adminLevel: admin?.level });
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={!!open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={!!open}>
          <Paper className={classes.openmodal}>
            <Grid item xs={12} container spacing={2}>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  height: "15%",
                  marginTop: "1rem",
                }}
              >
                <Typography variant="h6" style={{ color: "#359DB6" }}>
                  Approval Details
                </Typography>
                <Divider />
              </Grid>

              <Grid
                container
                spacing={1}
                style={{ marginTop: "20px", marginLeft: "120px" }}
              >
                {activeApproval ? (
                  <Grid item container>
                    <Grid item xs={6} style={{ marginTop: "10px" }}>
                      Approval Id
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: "10px" }}>
                      {activeApproval?._id}
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: "10px" }}>
                      Profile Id
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: "10px" }}>
                      {activeApproval?.profile_id}
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: "10px" }}>
                      Status
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: "10px" }}>
                      {activeApproval?.status}
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: "10px" }}>
                      Overdraft Id
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: "10px" }}>
                      {activeApproval?.req_details?.od_detail?.od_id}
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: "10px" }}>
                      Created At
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: "10px" }}>
                      {activeApproval?.created_at}
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
              <Grid style={{ marginTop: "20px", marginLeft: "300px" }}>
                <Button
                  onClick={handleAssignBtn}
                  color="secondary"
                  className={classes.openbutton}
                >
                  Assign
                </Button>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  className={classes.openbutton}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    approvals: adminReducer.approvals,
    admin: adminReducer.admin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllAdmins: (data) => dispatch(actionCreator.getAllAdmins(data)),
    getApproval: (data) => dispatch(actionCreator.getApproval(data)),
    assignAdmin: (data) => dispatch(actionCreator.assignAdmin(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpennModal);

// export default OpennModal;
