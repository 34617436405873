import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  makeStyles,
  useTheme,
  withStyles,
} from "@material-ui/core";
import React, { forwardRef, useEffect } from "react";

const CustomButton = withStyles({
  root: {
    borderRadius: 8,
    justifySelf: "right",
    border: 0,
    color: "white",
    fontStyle: "normal",
    background: "#359DB6",
    "&:hover": {
      background: "#62c3db",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#8ab0b9",
  },
})((props) => <Button {...props} />);

const SuccessButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#10ca73",
    "&:hover": {
      background: "#87f891",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#758876",
  },
})((props) => <Button {...props} />);

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    textAlign: "right",
  },
}));
const DeleteFormModal = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const onDelete = () => {
    props.deleteTask();
  };

  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={props?.open}
        onClose={props.onClose}
      >
        <Grid>
          <DialogTitle>{props.title}</DialogTitle>
          <Grid style={{ textAlign: "center", padding: "8px 15px" }}>
            <CustomButton
              onClick={onDelete}
            >
              Confirm
            </CustomButton>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default DeleteFormModal;
