import React from "react";
import { Box, Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  numberContainer: {
    width: "15%",
    backgroundColor: "#272727",
    borderRadius: 80,
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    borderRight: "2px solid #8F8D8D",
    position: "relative",
    left: 27,
  },
  innerNumberContainer: {
    backgroundColor: "#B5B5B5",
    borderRadius: 80,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: 30,
    height: 30,
    border: "2px solid #8F8D8D",
  },
  titleStyle: {
    fontSize: 16,
    color: "#DCDCDC",
    fontWeight: 600,
  },
  valueStyle: {
    fontSize: 14,
    color: "#DCDCDC",
    fontWeight: 400,
  },
  detailContainer: {
    width: "85%",
    padding: 12,
    backgroundColor: "#303030",
    borderRadius: 12,
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #8F8D8D",
    borderLeft: "unset",
    height: 40,
  },
  titleContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  valueContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
const UserDetailContainer = (props) => {
  const { index, title, value, onClick } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box className={clsx(classes.container)} onClick={onClick}>
      <Box className={clsx(classes.numberContainer)}>
        <Box className={clsx(classes.innerNumberContainer)}>
          <Typography className={clsx(classes.titleStyle)}>{index}</Typography>
        </Box>
      </Box>
      <Box className={clsx(classes.detailContainer)}>
        <Grid container style={{ width: "85%" }}>
          <Grid item xs={6} className={clsx(classes.titleContainer)}>
            <Typography className={clsx(classes.titleStyle)}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={6} className={clsx(classes.valueContainer)}>
            <Typography className={clsx(classes.valueStyle)}>
              {value}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

UserDetailContainer.defaultProps = {
  onClick: () => {},
};

const mapStateToProps = ({ adminReducer }) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetailContainer);
