import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, Box } from "@material-ui/core";
import moment from "moment";
import MaterialTable from "material-table";
import InputLabel from "@material-ui/core/InputLabel";
import * as actionCreator from "../../../../store/action/index";
import { forwardRef, useRef } from "react";
import clsx from "clsx";
import { TablePagination } from "@material-ui/core";
import { sessionFormat } from "../../../../utils/timeformats/sessionFormat";
import Search from "@material-ui/icons/Search";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Styles from "../../../../components/styles/index";
import {
  AccountCircle,
  AddBox,
  Check,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  Remove,
  SaveAlt,
  ViewColumn,
} from "@material-ui/icons";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

const UserActivity = (props) => {
  const classes = Styles();
  const { user } = props;

  const [registration_time, setRegistrationTime] = useState(null);
  const [activityLogs, setActivityLogs] = useState([]);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <AccountCircle {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const tableRef = useRef();

  useEffect(() => {
    const logs = [];
    (user?.activityLogs || []).map((log) => {
      let sortedActivity = (log?.activity || [])?.reverse();
      sortedActivity.map((session) => {
        logs.push({ ...session, channel: log?.channel || "" });
      });
    });

    setActivityLogs(logs);
  }, [user?.activityLogs]);

  return (
    <>
      <Grid item container xs={12} alignItems="center" spacing={2}>
        <Grid item container xs={12} className={classes.resultContainer}>
          <Grid container className={classes.listroot1}>
            <Grid item xs={11}>
              <Grid item>
                <Typography variant="h5" align="left">
                  User Activity
                </Typography>
              </Grid>
              <Box className={clsx(classes.flex, classes.justifyBetween)}>
                <Box className={classes.flex}>
                  {user?.registration_time ? (
                    <>
                      <InputLabel
                        id="registration_time"
                        style={{ marginRight: 5 }}
                      >
                        Registration Time
                      </InputLabel>
                      <Typography>
                        {moment(registration_time).format("DD-MM-YYYY hh:mm A")}
                      </Typography>
                    </>
                  ) : null}
                </Box>
                <Box className={classes.flex}>
                  {activityLogs?.length ? (
                    <>
                      <InputLabel
                        id="user_activityLogs"
                        style={{ marginRight: 5 }}
                      >
                        Last Login
                      </InputLabel>
                      <Typography>
                        {moment(activityLogs[0]?.loginTime).format(
                          "DD-MM-YYYY hh:mm A"
                        )}
                      </Typography>
                    </>
                  ) : null}
                </Box>
              </Box>

              <MaterialTable
                tableRef={tableRef}
                icons={tableIcons}
                title="User Activity"
                columns={[
                  {
                    title: "Login Time",
                    render: (rowData) =>
                      moment(rowData.loginTime).format("DD-MM-YYYY hh:mm A"),
                    align: "center",
                    type: "datetime",
                    sorting: false,
                    searchable: true,
                  },
                  {
                    title: "Logout Time",
                    render: (rowData) =>
                      moment(rowData.logoutTime).format("DD-MM-YYYY hh:mm A"),
                    align: "center",
                    type: "datetime",
                    sorting: false,
                    searchable: true,
                  },
                  {
                    title: "Session Time",
                    render: (rowData) => sessionFormat(rowData.session),
                    align: "center",
                    type: "datetime",
                    sorting: false,
                    searchable: true,
                  },
                  {
                    title: "Channel",
                    render: (rowData) => rowData.channel,
                    align: "center",
                    type: "text",
                    sorting: false,
                    searchable: true,
                  },
                ]}
                options={{
                  exportAllData: true,
                  exportButton: true,
                  // doubleHorizontalScroll: true,
                  emptyRowsWhenPaging: false,
                  maxBodyHeight: 450,
                  columnsButton: true,
                  showFirstLastPageButtons: false,
                  search: true,
                }}
                components={{
                  Pagination: (props) => (
                    <TablePagination
                      {...props}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                    />
                  ),
                }}
                data={activityLogs}
              />
            </Grid>
          </Grid>{" "}
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    admin: adminReducer.admin,
    user: adminReducer.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
    getAdmin: () => dispatch(actionCreator.getAdmin()),
    getUser: (data) => dispatch(actionCreator.getUser(data)),
    updateUser: (data) => dispatch(actionCreator.updateUser(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserActivity);
