import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Button,
  Typography,
  Divider,
  TextField,
} from "@material-ui/core";

import { UAParser } from "ua-parser-js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import * as actionCreator from "../../../../store/action/index";
import MenuItem from "@material-ui/core/MenuItem";
import Styles from "../../../../components/styles/index";
import { componentAccess } from "../../../../components/common/method";
const CustomInput = withStyles({
  root: {
    //background: "#eeededd7",
    borderRadius: 8,
    border: 0,
    width: "100%",
    fontFamily: "Manrope",
  },
})((props) => <TextField {...props} />);

const CustomButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#359DB6",
    "&:hover": {
      background: "#62c3db",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#8ab0b9",
  },
})((props) => <Button {...props} />);

const SuccessButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#10ca73",
    "&:hover": {
      background: "#87f891",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#758876",
  },
})((props) => <Button {...props} />);
const useStyles = makeStyles((theme) => ({
  resultContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8) + 1,
    },
    backgroundColor: theme.palette.background.default,
    marginLeft: "1rem",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
  },
}));
const Cbc = (props) => {
  const classes = useStyles();
  const { admin, loadings, notify, user, assestsTypeData, addCbc } = props;

  const [cbcMod, setCBCMod] = useState(false);
  const [cbcAmount, setCbcAmount] = useState(null);
  const [assetType, setAssetType] = useState(null);
  const [cbcFeevalue, setCbcFeevalue] = useState(null);

  const onCbcAmountChange = (e) => {
    setCbcAmount(e.target.value);
  };

  const onCbcFeeValueChange = (e) => {
    setCbcFeevalue(e.target.value);
  };

  const onCbcBack = () => {
    setCbcAmount(null);
    setAssetType(null);
    setCbcFeevalue(null);
    setCBCMod(!cbcMod);
  };
  const onCbcSave = () => {
    if (cbcAmount == "0") {
      notify("Please enter valid amount", "error");
    } else {
      addCbc({
        id: user?._id,
        amount: cbcAmount,
        feeValue: cbcFeevalue,
        assetType: assetType,
      });
    }

    setCbcAmount(null);
    setAssetType(null);
    setCbcFeevalue(null);
    setCBCMod(!cbcMod);
  };

  const assetHandleChange = (e) => {
    if (e.target.value == "Bank Guarantee") {
      setAssetType(1);
    } else if (e.target.value == "Government Bonds") {
      setAssetType(2);
    } else {
      setAssetType(3);
    }
  };

  return (
    <React.Fragment>
      <Grid item container>
        {/* <Paper className={classes.paper} id="cbc" elevation={5}> */}
        <Grid container spacing={1} className={classes.resultContainer}>
          <Grid
            item
            container
            xs={12}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5" align="left">
                CBC
              </Typography>
            </Grid>
            <Grid item>
              {cbcMod ? (
                <Grid container item spacing={1}>
                  <Grid item>
                    <CustomButton
                      onClick={onCbcBack}
                      disabled={loadings.updateCustomFee}
                    >
                      Back
                    </CustomButton>
                  </Grid>
                  <Grid item>
                    <SuccessButton
                      onClick={onCbcSave}
                      disabled={
                        !(
                          cbcAmount !== null &&
                          cbcAmount !== "" &&
                          user?.accounts
                        )
                      }
                    >
                      Save
                    </SuccessButton>
                  </Grid>
                </Grid>
              ) : componentAccess(
                  admin?.level,
                  admin?.type,
                  "Users",
                  "modifyBankingData"
                ) ? (
                <>
                  <CustomButton
                    onClick={() => setCBCMod(!cbcMod)}
                    disabled={
                      loadings.updateCustomFee ||
                      !Boolean(user?.accounts) ||
                      !componentAccess(
                        admin?.level,
                        admin?.type,
                        "Users",
                        "modifyBankingData"
                      )
                    }
                  >
                    Modify
                  </CustomButton>
                </>
              ) : null}
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
              <Divider />
            </Grid>
          </Grid>
          <Grid item container xs={12} alignItems="center" spacing={2}>
            {user?.accounts?.map((account) => {
              return (
                <React.Fragment>
                  {account?.account_type == "Cash Backed Cog" ? (
                    <>
                      <Grid item xs={12} sm={12}>
                        <Typography variant="h6" align="left">
                          {account.account_type}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography
                          variant="body1"
                          align="left"
                          style={{ color: "#359DB6" }}
                        >
                          Balance:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          align="left"
                        >
                          {account.balance}
                          {account.account_type == "Euro Transaction" ||
                          account.account_type == "Euro Rebate"
                            ? "€"
                            : "C"}
                        </Typography>
                      </Grid>

                      {account?.fee_details?.length != 0 ? (
                        <>
                          <Grid item xs={12} sm={12}>
                            <Typography variant="body1" align="left">
                              {account?.fee_details[0]?.fee_name}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant="body1"
                              align="left"
                              style={{ color: "#359DB6" }}
                            >
                              Max Tax:
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={8}>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              align="left"
                            >
                              {account?.fee_details[0]?.custom_value !== null
                                ? account?.fee_details[0]?.custom_value
                                : account?.fee_details[0]?.default_value}
                              %
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <Typography variant="body1" align="left">
                              {account?.fee_details[1]?.fee_name}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant="body1"
                              align="left"
                              style={{ color: "#359DB6" }}
                            >
                              Max Tax:
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={8}>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              align="left"
                            >
                              {account?.fee_details[1]?.custom_value !== null
                                ? account?.fee_details[1]?.custom_value
                                : account?.fee_details[1]?.default_value}
                              %
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <Typography variant="body1" align="left">
                              {account?.fee_details[2]?.fee_name}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant="body1"
                              align="left"
                              style={{ color: "#359DB6" }}
                            >
                              Max Tax:
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={8}>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              align="left"
                            >
                              {account?.fee_details[2]?.custom_value !== null
                                ? account?.fee_details[2]?.custom_value
                                : account?.fee_details[2]?.default_value}
                              %
                            </Typography>
                          </Grid>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </React.Fragment>
              );
            })}
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
            <Divider />
          </Grid>
          {cbcMod ? (
            <>
              <Grid container xs={12} spacing={1}>
                <Grid item xs={3}>
                  <Typography
                    style={{ marginTop: "0.5rem" }}
                    variant="h6"
                    align="left"
                  >
                    Amount :
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <CustomInput
                    variant="outlined"
                    value={cbcAmount}
                    label="Cbc Amount"
                    name="add_cbc_amount"
                    id="add_cbc_amount"
                    type="number"
                    onChange={onCbcAmountChange}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{ marginTop: "0.8rem" }}
                container
                xs={12}
                spacing={1}
              >
                <Grid item xs={3}>
                  <Typography
                    style={{ marginTop: "0.5rem" }}
                    variant="h6"
                    align="left"
                  >
                    Fee Value :
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <CustomInput
                    variant="outlined"
                    value={cbcFeevalue}
                    label="Fee Value"
                    name="Fee value"
                    id="fee_value"
                    type="number"
                    onChange={onCbcFeeValueChange}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={1}>
                <Grid item xs={3}>
                  <Typography
                    style={{ marginTop: "1.5rem" }}
                    variant="h6"
                    align="left"
                  >
                    Asset Name:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Asset Name</InputLabel>
                    <Select
                      style={{ marginTop: "1rem" }}
                      labelId="assetType"
                      options={assetType}
                      label="assetType"
                      onChange={assetHandleChange}
                      variant="outlined"
                    >
                      {assestsTypeData.map((asset) => {
                        return (
                          <MenuItem value={asset.asset_name}>
                            {asset.asset_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
        {/* </Paper> */}
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    admin: adminReducer.admin,
    user: adminReducer.user,
    assestsTypeData: adminReducer.assestsTypeData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
    getAdmin: () => dispatch(actionCreator.getAdmin()),
    getUser: (data) => dispatch(actionCreator.getUser(data)),
    getAssectsType: () => dispatch(actionCreator.getAssectsType()),
    updateUser: (data) => dispatch(actionCreator.updateUser(data)),
    updateUserDetails: (data) =>
      dispatch(actionCreator.updateUserDetails(data)),
    addCbc: (data) => dispatch(actionCreator.addCbc(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cbc);
