import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as actionCreator from "../../store/action/index";
import { Box, Grid, Typography } from "@material-ui/core";
import {
  Edit,
  DeleteForeverOutlined,
  CloudUploadOutlined,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import AnnouncementTemplatePage from "../../components/common/announcement/AnnouncementTemplatePage";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "90%",
    margin: "5% auto auto 7%",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  backButtonContainer: {
    textAlign: "right",
  },
  backButtonStyle: {
    color: "rgb(53, 157, 182)",
  },
  titleStyle: {
    color: "rgb(53, 157, 182)",
    fontWeight: 600,
    fontSize: 24,
  },
}));

function CreateAnnouncement(props) {
  const { createAnnouncement, loadings, notify } = props;

  const classes = useStyles();

  const onCreate = (data, onSuccessCallBack) => {
    createAnnouncement(data, onSuccessCallBack);
  };
  const history = useHistory();

  return (
    <React.Fragment>
      <Box className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.backButtonContainer}>
              <Button
                className={classes.backButtonStyle}
                onClick={() => {
                  history.push("/system");
                }}
              >
                Back
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.titleContainer}>
              <Typography className={classes.titleStyle}>
                Create Announcement
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <AnnouncementTemplatePage
              submitButtonText={"Create Announcement"}
              onSubmit={onCreate}
            />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createAnnouncement: (data, onSuccessCallBack) =>
      dispatch(actionCreator.createAnnouncement(data, onSuccessCallBack)),

    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAnnouncement);
