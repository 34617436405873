import React, { forwardRef, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as actionCreator from "../../../store/action/index";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Typography } from "@material-ui/core";
import StatsFrame from "./StatsFrame";
import PieChart from "./PieChart";
import LineChart from "./LineChart";
import moment from "moment";
import BarChart from "./BarChart";

const useStyles = makeStyles((theme) => ({
  zoomContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

function CrFormStats(props) {
  const { getCryptopiaFormStats, cryptopiaStats } = props;
  const classes = useStyles();

  const [crfSaleOptionPie, setCrfSaleOptionPie] = React.useState([]);
  const [crfGraph, setCrfGraph] = React.useState([]);
  const [crfBarGraph, setCrfBarGraph] = React.useState([]);
  const [barGraphSize, setBarGraphSize] = React.useState(1200);

  const submittedCount = cryptopiaStats?.userStat?.length || 0;
  const countryCount = cryptopiaStats?.countryStat?.length || 0;

  const submittedCoinValue = React.useMemo(() => {
    let value = 0;
    (cryptopiaStats?.userStat || []).map((userData) => {
      value += userData?.nzd_equivalent || 0;
    });
    return value;
  }, [cryptopiaStats]);

  useEffect(() => {
    getCryptopiaFormStats();
  }, []);

  const getDate = (date) => {
    let newDate = new Date(date);
    newDate.setHours(0);
    newDate.setMilliseconds(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    return newDate.getTime();
  };

  useEffect(() => {
    let crfSaleOptionData = { 0: 0, 1: 0 };
    let crfGraphData = [];

    (cryptopiaStats?.userStat || [])
      .sort(
        (a, b) =>
          new Date(a.date || 0).getTime() - new Date(b.date || 0).getTime()
      )
      .map((userData, index) => {
        crfSaleOptionData[userData?.saleOption || 0] += 1;
        crfGraphData.push({
          x: new Date(userData?.date).getTime(),
          y: index + 1,
        });
      });
    setCrfSaleOptionPie([
      { name: "Sale Option 1", value: crfSaleOptionData[0] },
      { name: "Sale Option 2", value: crfSaleOptionData[1] },
    ]);
    setCrfGraph(() => {
      if (crfGraphData?.length == 0) {
        return [];
      }
      let result = [{ x: getDate(crfGraphData[0].x), y: crfGraphData[0].y }];
      crfGraphData.map((date, index) => {
        if (index) {
          let a = getDate(date.x);
          let b = getDate(result[result.length - 1].x);
          if (a == b) {
            result[result.length - 1].y = date.y;
          } else {
            result.push({ x: a, y: date.y });
          }
        }
      });
      let currentDate = getDate(new Date());
      if (currentDate != result[result.length - 1].x) {
        result.push({ x: currentDate, y: result[result.length - 1].y });
      }
      return result;
    });
  }, [cryptopiaStats]);

  useEffect(() => {
    let barGraphData = [];
    if (cryptopiaStats?.countryStat?.length) {
      cryptopiaStats.countryStat.map((barData) => {
        barGraphData.push({
          x: barData?.country || "Others",
          y: barData?.applicants,
        });
      });
    }

    setCrfBarGraph(barGraphData);
  }, [cryptopiaStats]);

  const zoomIn = () => {
    setBarGraphSize((size) => size + 100);
  };

  const zoomOut = () => {
    setBarGraphSize((size) => (size > 100 ? size - 100 : 100));
  };

  return (
    <StatsFrame title={"Cryptopia Form"}>
      <Typography style={{ textAlign: "center" }}>
        Total Form Submitted: {submittedCount} from {countryCount} countries.
      </Typography>
      <Typography style={{ textAlign: "center" }}>
        Total Coins value matched with db{`(NZD)`}: {submittedCoinValue}.
      </Typography>
      {crfBarGraph?.length ? (
        <>
          <Box className={classes.zoomContainer}>
            <Button onClick={zoomIn}>Zoom In</Button>
            <Button onClick={zoomOut}>Zoom out</Button>
          </Box>
          <BarChart
            yBarText={"↑ Applicants"}
            data={crfBarGraph}
            width={barGraphSize}
          />
        </>
      ) : null}
      <Box style={{ display: "flex", justifyContent: "space-around" }}>
        <LineChart
          data={crfGraph}
          showX={false}
          bottomText={
            <Box>
              <Typography>
                {crfGraph?.length
                  ? `${moment(crfGraph[0].x).format("DD.M.YYYY")} - ${moment(
                      crfGraph[crfGraph.length - 1].x
                    ).format("DD.MM.YYYY")}`
                  : ""}
              </Typography>
              <Typography>Form Submission Timeline</Typography>
            </Box>
          }
        />
        <PieChart data={crfSaleOptionPie} width={170} height={170} />
      </Box>
    </StatsFrame>
  );
}

const mapStateToProps = ({ adminReducer }) => {
  return {
    cryptopiaStats: adminReducer.cryptopiaStats,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCryptopiaFormStats: () =>
      dispatch(actionCreator.getCryptopiaFormStats()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CrFormStats);
