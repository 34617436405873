import {
 Box,
 Button,
 Divider,
 Grid,
 Typography,
 makeStyles,
} from "@material-ui/core";
import {
 DeleteForever,
 EditAttributes,
 EditOutlined,
 Theaters,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import CreateActivityModal from "./createActivityModal";
import { connect } from "react-redux";
import * as actionCreator from "../../../store/action/index";
import moment from "moment/moment";
const useStyles = makeStyles((theme) => ({
 addActivityButton: {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  margin: "2% 0 4% 0",
  backgroundColor: "#359DB6",
 },
 dividerCss: {
  margin: "1% 0 4% 0",
  height: "2px",
 },
 timeAgeGroup: {
  color: "#ACACAC",
  fontSize: "0.8rem",
 },
 iconGrid: {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  paddingBottom: "2%",
 },
 deleteIcon: {
  color: "#FFB476",
 },
 editIcon: {
  color: "#76CEAB",
 },
 viewButton: {
  fontSize: "0.7rem",
  textAlign: "left",
  color: "#359DB6",
  [theme.breakpoints.down("xs")]: {
   fontSize: "0.5rem",
   textAlign: "center",
  },
  avatarStyle: {
   backgroundColor: "#359DB6 !important",
   [`&.MuiAvatar-root`]: {
    fontSize: "0.8rem",
   },
  },
 },
}));

const BusinessActivity = (props) => {
 const {
  getBusinessActivitiesPM,
  id,
  businessActivities,
  createBusinessActivity,
  profileId,
  updatePMActivity,
  business,
  address,
 } = props;
 const [openCreateModal, setOpenCreateModel] = useState(false);
 const [openEditModal, setOpenEditModal] = useState(false);
 const [acitvities, setActivities] = useState();
 const [editData, setEditData] = useState({});
 const [businessAddress, setBusinessAddress] = useState();

 const classes = useStyles();
 const handleOpenCreateActivity = () => {
  setOpenCreateModel(true);
 };
 const handleCloseCreateActivity = () => {
  setOpenCreateModel(false);
 };
 const handleCloseEditActivity = () => {
  setOpenEditModal(false);
 };
 useEffect(() => {
  getBusinessActivitiesPM({ business_id: id });
 }, []);
 useEffect(() => {
  if (businessActivities?.length) {
   const activity = businessActivities?.map((item) => {
    return item;
   });
   setActivities(activity);
  }
 }, [businessActivities]);
 const ageGroups = { 0: "0 - 5 years", 1: "6 - 10 years", 2: "11 - 16 years" };

 useEffect(() => {
  setBusinessAddress(address);
 }, []);
 return (
  <>
   <Grid container item xs={12}>
    <Grid item xs={10}>
     <Typography variant="h5" align="left">
      Business Activity
     </Typography>
    </Grid>
    {business?.optedActivity ? (
     <Grid item xs={2}>
      <Button
       onClick={handleOpenCreateActivity}
       className={classes.addActivityButton}
      >
       Create
      </Button>
     </Grid>
    ) : null}
    <Grid item xs={12}>
     <Divider />
    </Grid>
    <Grid container item spacing={2} style={{ paddingTop: "4%" }}>
     {business?.optedActivity ? (
      businessActivities.length ? (
       acitvities?.map((activity) => {
        return (
         <>
          <Grid item xs={8}>
           <Typography>{activity?.title}</Typography>
           <Box style={{ display: "flex", flexDirection: "row" }}>
            <Typography className={classes.timeAgeGroup}>
             {moment(activity?.created_at).format("dddd Do MMMM ")} |{" "}
            </Typography>
            <Typography className={classes.timeAgeGroup}>
             {" "}
             {activity?.ageGroup
              ?.map((age, key) => {
               return ageGroups[age];
              })
              .join(" , ")}
            </Typography>
           </Box>
          </Grid>
          <Grid item xs={2}>
           <Box>
            <Typography
             style={{
              color:
               activity?.status == "REJECTED"
                ? "red"
                : activity?.status == "SCHEDULED" ||
                  activity?.status == "Scheduled"
                ? "#76CEAB"
                : activity?.status == "WFA"
                ? "#FFB476"
                : activity?.status == "CLOSED "
                ? "#16BA44"
                : activity?.status == "ONGOING"
                ? "#FF7300"
                : null,
             }}
            >
             {activity?.status}
            </Typography>
           </Box>
          </Grid>
          <Grid item xs={2}>
           {/* <DeleteForever /> */}
           <EditOutlined
            onClick={(e) => {
             setOpenEditModal(true);
             const activityId = e.target.id;
             setEditData({
              ...editData,
              id: activityId,
              ...activity,
             });
            }}
           />
          </Grid>
          <Grid item xs={12}>
           <Box style={{ display: "flex", flexDirection: "row" }}>
            <Typography className={classes.timeAgeGroup}>
             <text style={{ color: "#e6e6e6" }}> Start: </text>
             {moment(activity?.openingDate).format("dddd Do MMMM ")}
             {"|"}
             <text style={{ color: "#e6e6e6" }}> End : </text>
             {moment(activity?.closingDate).format("dddd Do MMMM ")}
            </Typography>
           </Box>
          </Grid>
          <Grid item xs={12}>
           <Typography>Latest Comment:</Typography>
           <Typography>
            {" "}
            {activity?.logs?.length ? (
             <>
              {[activity?.logs]?.map(() => {
               const latestComment = activity?.logs;
               const latest = latestComment[latestComment.length - 1];
               return (
                <>
                 {/* {console.log("logs", activity?.logs)}
                   {console.log("latest", latest)} */}
                 <text
                  className={classes.timeAgeGroup}
                  style={{
                   fontSize: "0.9rem",
                   marginTop: "0.5%",
                  }}
                 >
                  {latest?.note}
                 </text>
                </>
               );
              })}
             </>
            ) : null}
           </Typography>
          </Grid>
          <Grid item xs={12}>
           <Divider />
          </Grid>
         </>
        );
       })
      ) : (
       <Grid item xs={12}>
        No activities created{" "}
       </Grid>
      )
     ) : (
      <Grid item xs={12}>
       Business has not opted for activity
      </Grid>
     )}
    </Grid>
   </Grid>
   <CreateActivityModal
    open={openCreateModal}
    handleClose={handleCloseCreateActivity}
    title={"Create Activity"}
    createBusinessActivity={createBusinessActivity}
    business_id={id}
    addressDetails={businessAddress}
    profileId={profileId}
   />
   <CreateActivityModal
    open={openEditModal}
    handleClose={handleCloseEditActivity}
    title={"Edit Activity"}
    // createBusinessActivity={createBusinessActivity}
    business_id={id}
    addressDetails={businessAddress}
    activityData={editData}
    updatePMActivity={updatePMActivity}
   />
  </>
 );
};
const mapStateToProps = ({ adminReducer }) => {
 return {
  businessActivities: adminReducer.businessActivities,
 };
};
const mapDispatchToProps = (dispatch) => {
 return {
  getBusinessActivitiesPM: (data) =>
   dispatch(actionCreator.getBusinessActivitiesPM(data)),
  createBusinessActivity: (data) =>
   dispatch(actionCreator.createBusinessActivity(data)),
  updatePMActivity: (data) => dispatch(actionCreator.updatePmActivity(data)),
 };
};
export default connect(mapStateToProps, mapDispatchToProps)(BusinessActivity);
