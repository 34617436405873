import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { API } from "../../../../asset/api";
import { getWithExpiry } from "../../../../asset/utility";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Button,
  Typography,
  TextField,
  Divider,
} from "@material-ui/core";
import * as actionCreator from "../../../../store/action/index";
import { updateObjectProperty } from "../../../../asset/utility";
import Styles from "../../../../components/styles";
import { currencyFormatter } from "../../../../utils/numberFormattor/currencyFormator";
import { Modal, Backdrop, Fade } from "@material-ui/core/";
import admins from "../../../../components/auth/permissions";
import { componentAccess } from "../../../../components/common/method";

const CustomInput = withStyles({
  root: {
    //background: "#eeededd7",
    borderRadius: 8,
    border: 0,
    width: "100%",
    fontFamily: "Manrope",
  },
})((props) => <TextField {...props} />);

const CustomButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#359DB6",
    "&:hover": {
      background: "#62c3db",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#8ab0b9",
  },
})((props) => <Button {...props} />);

const SuccessButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#10ca73",
    "&:hover": {
      background: "#87f891",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#758876",
  },
})((props) => <Button {...props} />);

const Banking = (props) => {
  // 1,2 for cog txns
  // 11 for cog investments
  // 6,7,8,9,10,12,13 for Euro txns
  // 3,4,5 for CBC

  const classes = Styles();
  const theme = useTheme();
  const {
    admin,
    loadings,
    getUser,
    updateCustomFee,
    updateOverdraftValue,
    notify,
    user,
  } = props;

  const [id, setId] = useState(useParams().id || null);
  const [overdraftFeeValue, setOverdraftFeeValue] = useState(null);
  const [prox, setProx] = useState(false);

  const [cogMod, setCogMod] = useState(false);
  const [cogInvMod, setCogInvMod] = useState(false);
  const [euroMod, setEuroMod] = useState(false);
  const [cbcBankMod, setCBCBankMod] = useState(false);

  const [activeOverdraft, setActiveOverdraft] = useState(null);
  const [activeOverdraftForEuroTxn, setActiveOverdraftForEuroTxn] =
    useState(null);
  const [isODactive, setIsODactive] = useState(false);
  const [open, setOpen] = useState({});

  const [inputFee1, setInputFee1] = useState(null);
  const [inputFee2, setInputFee2] = useState(null);
  const [inputFee3, setInputFee3] = useState(null);
  const [inputFee4, setInputFee4] = useState(null);
  const [inputFee5, setInputFee5] = useState(null);
  const [inputFee6, setInputFee6] = useState(null);
  const [inputFee7, setInputFee7] = useState(null);
  const [inputFee8, setInputFee8] = useState(null);
  const [inputFee9, setInputFee9] = useState(null);
  const [inputFee10, setInputFee10] = useState(null);
  const [inputFee11, setInputFee11] = useState(null);
  const [inputFee12, setInputFee12] = useState(null);
  const [inputFee13, setInputFee13] = useState(null);

  const [prevInputFee1, setPrevInputFee1] = useState(null);
  const [prevInputFee2, setPrevInputFee2] = useState(null);
  const [prevInputFee3, setPrevInputFee3] = useState(null);
  const [prevInputFee4, setPrevInputFee4] = useState(null);
  const [prevInputFee5, setPrevInputFee5] = useState(null);
  const [prevInputFee6, setPrevInputFee6] = useState(null);
  const [prevInputFee7, setPrevInputFee7] = useState(null);
  const [prevInputFee8, setPrevInputFee8] = useState(null);
  const [prevInputFee9, setPrevInputFee9] = useState(null);
  const [prevInputFee10, setPrevInputFee10] = useState(null);
  const [prevInputFee11, setPrevInputFee11] = useState(null);
  const [prevInputFee12, setPrevInputFee12] = useState(null);
  const [prevInputFee13, setPrevInputFee13] = useState(null);

  const [prevOverdraftValue, setPrevOverdaftValue] = useState(null);

  const [inputOverdraftValue, setInputOverdraftValue] = useState(null);

  const [accountStatus, setAccountStatus] = useState();

  const [errorMessages, setErrorMessages] = useState({
    feeError1: "",
    feeError2: "",
    feeError3: "",
    feeError4: "",
    feeError5: "",
    feeError6: "",
    feeError7: "",
    feeError8: "",
    feeError9: "",
    feeError10: "",
    feeError11: "",
    feeError12: "",
    feeError13: "",
    overdraftError: "",
    inputOverdraftError: "",
  });

  // test useEffect
  // useEffect(()=>{
  //   console.log(errorMessages.feeError1)
  // },[errorMessages.feeError1]);

  const [validData, setValidData] = useState({
    validFee1: false,
    validFee2: false,
    validFee3: false,
    validFee4: false,
    validFee5: false,
    validFee6: false,
    validFee7: false,
    validFee8: false,
    validFee9: false,
    validFee10: false,
    validFee11: false,
    validFee12: false,
    validFee13: false,
    validOverdraft: false,
  });

  const [overdraftFeeOpen, setOverdraftFeeOpen] = useState(false);

  const handleOpen = (id) => {
    setOpen({ [id]: true });
  };

  const handleClose = () => {
    setOpen({ [id]: false });
  };

  const toggleAccountStatus = (value) => {
    return async (dispatch, getState) => {
      //# HERE MAKE ASYNC CALLS.
      let storedToken = getWithExpiry("token");
      const reqBody = {
        profileId: user._id,
        status: value,
      };

      API.post("/SetUserAccountStatus", JSON.stringify(reqBody), {
        headers: {
          token: storedToken,
        },
        withCredentials: false,
      })
        .then((res) => {
          const { message } = res.data;
          notify(message, "success");
          getUser(id);
        })
        .catch((err) => {
          notify(err?.response?.data?.message || err.message, "error");
        });
    };
  };

  // setting up previous input fees
  useEffect(() => {
    if (user?.accounts) {
      user.accounts.map((account) => {
        if (account?.account_type === "Cog Transaction") {
          account.fee_details.map((fee) => {
            if (fee?.fee_type_id == 1) {
              setPrevInputFee1(fee.custom_value);
            }

            if (fee?.fee_type_id == 2) {
              setPrevInputFee2(fee.custom_value);
            }
          });
        }
      });
    }

    if (user?.accounts) {
      user.accounts.map((account) => {
        if (account?.account_type === "Cog Investment") {
          account.fee_details.map((fee) => {
            if (fee?.fee_type_id == 11) {
              setPrevInputFee11(fee.custom_value);
            }
          });
        }
      });
    }

    if (user?.accounts) {
      user.accounts.map((account) => {
        if (account?.account_type === "Euro Transaction") {
          account.fee_details.map((fee) => {
            if (fee?.fee_type_id == 6) {
              setPrevInputFee6(fee.custom_value);
            }

            if (fee?.fee_type_id == 7) {
              setPrevInputFee7(fee.custom_value);
            }

            if (fee?.fee_type_id == 8) {
              setPrevInputFee8(fee.custom_value);
            }

            if (fee?.fee_type_id == 9) {
              setPrevInputFee9(fee.custom_value);
            }

            if (fee?.fee_type_id == 10) {
              setPrevInputFee10(fee.custom_value);
            }

            if (fee?.fee_type_id == 12) {
              setPrevInputFee12(fee.custom_value);
            }

            if (fee?.fee_type_id == 13) {
              setPrevInputFee13(fee.custom_value);
            }
          });
        }
      });
    }

    if (user?.accounts) {
      user.accounts.map((account) => {
        if (account?.account_type === "Cash Backed Cog") {
          account.fee_details.map((fee) => {
            if (fee?.fee_type_id == 3) {
              setPrevInputFee3(fee.custom_value);
            }

            if (fee?.fee_type_id == 4) {
              setPrevInputFee4(fee.custom_value);
            }

            if (fee?.fee_type_id == 5) {
              setPrevInputFee5(fee.custom_value);
            }
          });
        }
      });
    }

    if (user?.accounts) {
      user.accounts.map((account) => {
        if (account?.account_type === "Cog Transaction") {
          // setPrevOverdaftValue(account?.overdraft_details?.overdraft_value);
          setPrevOverdaftValue(activeOverdraft?.overdraft_value);
        }
      });
    }
  }, [user, inputOverdraftValue]);

  useEffect(() => {
    if (inputOverdraftValue) {
      if (!inputOverdraftValue?.overdraft_fee_value) {
        setErrorMessages({
          ...errorMessages,
          inputOverdraftError: "OD Fee value is required!",
        });
      }
      // else if (inputOverdraftValue?.overdraft === prevOverdraftValue) {
      //   setErrorMessages({ ...errorMessages, inputOverdraftError: 'OD Value is same as previous OD' })
      // }
      else {
        setErrorMessages({ ...errorMessages, inputOverdraftError: "" });
      }
    }
    // else if (inputOverdraftValue === null && overdraftFeeValue !== null) {
    // else if (inputOverdraftValue === null) {
    //   setErrorMessages({ ...errorMessages, overdraftError: 'OD Value is same as previous OD' })
    // }
  }, [inputOverdraftValue]);

  // setting new inputfee to null, if its same as previous one
  useEffect(() => {
    if (inputFee1 == prevInputFee1) {
      setInputFee1(null);
    }

    if (inputFee2 == prevInputFee2) {
      setInputFee2(null);
    }

    if (inputFee3 == prevInputFee3) {
      setInputFee3(null);
    }

    if (inputFee4 == prevInputFee4) {
      setInputFee4(null);
    }

    if (inputFee5 == prevInputFee5) {
      setInputFee5(null);
    }

    if (inputFee6 == prevInputFee6) {
      setInputFee6(null);
    }

    if (inputFee7 == prevInputFee7) {
      setInputFee7(null);
    }

    if (inputFee8 == prevInputFee8) {
      setInputFee8(null);
    }

    if (inputFee9 == prevInputFee9) {
      setInputFee9(null);
    }
    if (inputFee10 == prevInputFee10) {
      setInputFee10(null);
    }

    if (inputFee11 == prevInputFee11) {
      setInputFee11(null);
    }
    if (inputFee12 == prevInputFee12) {
      setInputFee12(null);
    }
    if (inputFee13 == prevInputFee13) {
      setInputFee13(null);
    }
  }, [
    inputFee1,
    inputFee2,
    inputFee3,
    inputFee4,
    inputFee5,
    inputFee6,
    inputFee7,
    inputFee8,
    inputFee9,
    inputFee10,
    inputFee11,
    inputFee12,
    inputFee13,
  ]);

  // setting up account status and defaultOD value
  useEffect(() => {
    function getDefaultOverdraftValue(account) {
      return account?.overdraft_details?.map((overdraft) => {
        return Object.keys(overdraft).map((key, index) => {
          if (
            key == "is_active" &&
            overdraft[key] == false &&
            account?.overdraft_details?.length === 1
          ) {
            setActiveOverdraft(overdraft);
          } else if (key == "is_active" && overdraft[key] == true) {
            setIsODactive(true);
            setActiveOverdraft(overdraft);
          }
        });
      });
    }
    if (user && user.accounts) {
      user.accounts.map((account) => {
        if (cogMod && account.account_type === "Cog Transaction") {
          getDefaultOverdraftValue(account);
        } else if (euroMod && account.account_type === "Euro Transaction") {
          getDefaultOverdraftValue(account);
        }
        setAccountStatus(account.status);
      });
    }
  }, [user, cogMod, euroMod]);

  useEffect(() => {
    if (overdraftFeeValue !== null) {
      if (overdraftFeeValue.length == 0) {
        setOverdraftFeeValue(null);
      }
    }
  }, [overdraftFeeValue]);

  useEffect(() => {
    if (cogMod || euroMod || cogInvMod) {
      setOverdraftFeeOpen(true);
    }
  }, [cogMod, euroMod, cogInvMod]);

  const validateField = (field, value) => {
    let errorMessagesCopy = { ...errorMessages };
    let validDataCopy = { ...validData };
    if (field === "fees1") {
      if (value > 100 || value < 0 || value == "") {
        errorMessagesCopy.feeError1 = "Not a valid percentage";
        validDataCopy.validFee1 = false;
      } else {
        errorMessagesCopy.feeError1 = "";
        validDataCopy.validFee1 = true;
      }
    } else if (field === "fees2") {
      if (value > 100 || value < 0 || value == "") {
        errorMessagesCopy.feeError2 = "Not a valid percentage";
        validDataCopy.validFee2 = false;
      } else {
        errorMessagesCopy.feeError2 = "";
        validDataCopy.validFee2 = true;
      }
    } else if (field === "fees3") {
      if (value > 100 || value < 0 || value == "") {
        errorMessagesCopy.feeError3 = "Not a valid percentage";
        validDataCopy.validFee3 = false;
      } else {
        errorMessagesCopy.feeError3 = "";
        validDataCopy.validFee3 = true;
      }
    } else if (field === "fees4") {
      if (value > 100 || value < 0 || value == "") {
        errorMessagesCopy.feeError4 = "Not a valid percentage";
        validDataCopy.validFee4 = false;
      } else {
        errorMessagesCopy.feeError4 = "";
        validDataCopy.validFee4 = true;
      }
    } else if (field === "fees5") {
      if (value > 100 || value < 0 || value == "") {
        errorMessagesCopy.feeError5 = "Not a valid percentage";
        validDataCopy.validFee5 = false;
      } else {
        errorMessagesCopy.feeError5 = "";
        validDataCopy.validFee5 = true;
      }
    } else if (field === "fees6") {
      if (value > 100 || value < 0 || value == "") {
        errorMessagesCopy.feeError6 = "Not a valid percentage";
        validDataCopy.validFee6 = false;
      } else {
        errorMessagesCopy.feeError6 = "";
        validDataCopy.validFee6 = true;
      }
    } else if (field === "fees7") {
      if (value > 100 || value < 0 || value == "") {
        errorMessagesCopy.feeError7 = "Not a valid percentage";
        validDataCopy.validFee7 = false;
      } else {
        errorMessagesCopy.feeError7 = "";
        validDataCopy.validFee7 = true;
      }
    } else if (field === "fees8") {
      if (value > 100 || value < 0 || value == "") {
        errorMessagesCopy.feeError8 = "Not a valid percentage";
        validDataCopy.validFee8 = false;
      } else {
        errorMessagesCopy.feeError8 = "";
        validDataCopy.validFee8 = true;
      }
    } else if (field === "fees9") {
      if (value > 100 || value < 0 || value == "") {
        errorMessagesCopy.feeError9 = "Not a valid percentage";
        validDataCopy.validFee9 = false;
      } else {
        errorMessagesCopy.feeError9 = "";
        validDataCopy.validFee9 = true;
      }
    } else if (field === "fees10") {
      if (value > 100 || value < 0 || value == "") {
        errorMessagesCopy.feeError10 = "Not a valid percentage";
        validDataCopy.validFee10 = false;
      } else {
        errorMessagesCopy.feeError10 = "";
        validDataCopy.validFee10 = true;
      }
    } else if (field === "fees11") {
      if (value > 100 || value < 0 || value == "") {
        errorMessagesCopy.feeError11 = "Not a valid percentage";
        validDataCopy.validFee11 = false;
      } else {
        errorMessagesCopy.feeError11 = "";
        validDataCopy.validFee11 = true;
      }
    } else if (field === "fees12") {
      if (value > 100 || value < 0 || value == "") {
        errorMessagesCopy.feeError12 = "Not a valid percentage";
        validDataCopy.validFee12 = false;
      } else {
        errorMessagesCopy.feeError12 = "";
        validDataCopy.validFee12 = true;
      }
    } else if (field === "fees13") {
      if (value > 100 || value < 0 || value == "") {
        errorMessagesCopy.feeError13 = "Not a valid percentage";
        validDataCopy.validFee13 = false;
      } else {
        errorMessagesCopy.feeError13 = "";
        validDataCopy.validFee13 = true;
      }
    } else if (field) {
      if (!value) {
        errorMessagesCopy.overdraftError = "Enter a valid amount";
        validDataCopy.validOverdraft = false;
      } else {
        errorMessagesCopy.overdraftError = "";
        validDataCopy.validOverdraft = true;
      }
    }
    setErrorMessages(errorMessagesCopy);
    setValidData(validDataCopy);
  };

  // fee value 1,2 for cog txn
  const onFeeValueChange1 = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (user?.accounts) {
      user.accounts.map((account) => {
        if (account?.account_type === "Cog Transaction") {
          account.fee_details.map((fee) => {
            if (fee?.fee_type_id == 1) {
              if (fee.custom_value !== value) {
                setInputFee1(value);
              }
            }
          });
        }
      });
    }

    validateField(name, value);
  };

  const onFeeValueChange2 = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (user?.accounts) {
      user.accounts.map((account) => {
        if (account?.account_type === "Cog Transaction") {
          account.fee_details.map((fee) => {
            if (fee?.fee_type_id == 2) {
              if (fee.custom_value !== value) {
                setInputFee2(value);
              }
            }
          });
        }
      });
    }

    validateField(name, value);
  };

  // fee value 11 for cog inv
  const onFeeValueChange11 = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (user?.accounts) {
      user.accounts.map((account) => {
        if (account?.account_type === "Cog Investment") {
          account.fee_details.map((fee) => {
            if (fee?.fee_type_id == 11) {
              if (fee.custom_value !== value) {
                setInputFee11(value);
              }
            }
          });
        }
      });
    }

    validateField(name, value);
  };

  // fee value 3,4,5 for CBC
  const onFeeValueChange3 = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (user?.accounts) {
      user.accounts.map((account) => {
        if (account?.account_type === "Cash Backed Cog") {
          account.fee_details.map((fee) => {
            if (fee?.fee_type_id == 3) {
              if (fee.custom_value !== value) {
                setInputFee3(value);
              }
            }
          });
        }
      });
    }

    validateField(name, value);
  };

  const onFeeValueChange4 = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (user?.accounts) {
      user.accounts.map((account) => {
        if (account?.account_type === "Cash Backed Cog") {
          account.fee_details.map((fee) => {
            if (fee?.fee_type_id == 4) {
              if (fee.custom_value !== value) {
                setInputFee4(value);
              }
            }
          });
        }
      });
    }

    validateField(name, value);
  };

  const onFeeValueChange5 = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (user?.accounts) {
      user.accounts.map((account) => {
        if (account?.account_type === "Cash Backed Cog") {
          account.fee_details.map((fee) => {
            if (fee?.fee_type_id == 5) {
              if (fee.custom_value !== value) {
                setInputFee5(value);
              }
            }
          });
        }
      });
    }

    validateField(name, value);
  };

  // fee value 6,7,8,9,10 for euro txn
  const onFeeValueChange6 = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (user?.accounts) {
      user.accounts.map((account) => {
        if (account?.account_type === "Euro Transaction") {
          account.fee_details.map((fee) => {
            if (fee?.fee_type_id == 6) {
              if (fee.custom_value !== value) {
                setInputFee6(value);
              }
            }
          });
        }
      });
    }

    validateField(name, value);
  };

  const onFeeValueChange7 = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (user?.accounts) {
      user.accounts.map((account) => {
        if (account?.account_type === "Euro Transaction") {
          account.fee_details.map((fee) => {
            if (fee?.fee_type_id == 7) {
              if (fee.custom_value !== value) {
                setInputFee7(value);
              }
            }
          });
        }
      });
    }

    validateField(name, value);
  };

  const onFeeValueChange8 = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (user?.accounts) {
      user.accounts.map((account) => {
        if (account?.account_type === "Euro Transaction") {
          account.fee_details.map((fee) => {
            if (fee?.fee_type_id == 8) {
              if (fee.custom_value !== value) {
                setInputFee8(value);
              }
            }
          });
        }
      });
    }

    validateField(name, value);
  };

  const onFeeValueChange9 = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (user?.accounts) {
      user.accounts.map((account) => {
        if (account?.account_type === "Euro Transaction") {
          account.fee_details.map((fee) => {
            if (fee?.fee_type_id == 9) {
              if (fee.custom_value !== value) {
                setInputFee9(value);
              }
            }
          });
        }
      });
    }

    validateField(name, value);
  };

  const onFeeValueChange10 = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (user?.accounts) {
      user.accounts.map((account) => {
        if (account?.account_type === "Euro Transaction") {
          account.fee_details.map((fee) => {
            if (fee?.fee_type_id == 10) {
              if (fee.custom_value !== value) {
                setInputFee10(value);
              }
            }
          });
        }
      });
    }

    validateField(name, value);
  };

  const onFeeValueChange12 = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (user?.accounts) {
      user.accounts.map((account) => {
        if (account?.account_type === "Euro Transaction") {
          account.fee_details.map((fee) => {
            if (fee?.fee_type_id == 12) {
              if (fee.custom_value !== value) {
                setInputFee12(value);
              }
            }
          });
        }
      });
    }

    validateField(name, value);
  };

  const onFeeValueChange13 = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (user?.accounts) {
      user.accounts.map((account) => {
        if (account?.account_type === "Euro Transaction") {
          account.fee_details.map((fee) => {
            if (fee?.fee_type_id == 13) {
              if (fee.custom_value !== value) {
                setInputFee13(value);
              }
            }
          });
        }
      });
    }

    validateField(name, value);
  };

  const onOverdraftValueChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (prevOverdraftValue == e.target.value) {
      setInputOverdraftValue({ ...inputOverdraftValue, overdraft: null });
      // if (inputOverdraftValue === null) {
      setErrorMessages({
        ...errorMessages,
        overdraftError: "OD Value is same as previous OD",
      });
      // }
    } else {
      setInputOverdraftValue(
        updateObjectProperty(inputOverdraftValue, name, value)
      );
      validateField(name, value);
    }
  };

  const onOverdraftFeeValueChange = (e) => {
    // if (inputOverdraftValue == null) {
    let name = e.target.name;
    // let value = prevOverdraftValue;
    let value = e.target.value;
    setInputOverdraftValue(
      updateObjectProperty(inputOverdraftValue, name, value)
    );
    if (!inputOverdraftValue?.overdraft) {
      setErrorMessages({
        ...errorMessages,
        overdraftError: "OD Value is same as previous OD",
      });
    }
    // }

    // let name = e.target.name;
    // let value = e.target.value;
    setProx(true);
    setOverdraftFeeValue(value);
  };

  const onCogBack = () => {
    setOverdraftFeeValue(null);
    setCogMod(!cogMod);
    setInputOverdraftValue(null);
    setInputFee1(null);
    setInputFee2(null);
    setProx(false);
    setOverdraftFeeOpen(false);
    setErrorMessages({
      feeError1: "",
      feeError2: "",
      feeError3: "",
      feeError4: "",
      feeError5: "",
      feeError6: "",
      feeError7: "",
      feeError8: "",
      feeError9: "",
      feeError10: "",
      feeError11: "",
      feeError12: "",
      feeError13: "",
      overdraftError: "",
      inputOverdraftError: "",
    });
    setValidData({
      validFee1: false,
      validFee2: false,
      validFee3: false,
      validFee4: false,
      validFee5: false,
      validFee6: false,
      validFee7: false,
      validFee8: false,
      validFee9: false,
      validFee10: false,
      validFee11: false,
      validFee12: false,
      validFee13: false,
      validOverdraft: false,
    });
  };

  const onCogInvBack = () => {
    setOverdraftFeeValue(null);
    setCogInvMod(!cogInvMod);
    setInputOverdraftValue(null);
    setInputFee11(null);
    setProx(false);
    setOverdraftFeeOpen(false);
    setErrorMessages({
      feeError1: "",
      feeError2: "",
      feeError3: "",
      feeError4: "",
      feeError5: "",
      feeError6: "",
      feeError7: "",
      feeError8: "",
      feeError9: "",
      feeError10: "",
      feeError11: "",
      feeError12: "",
      feeError13: "",
      overdraftError: "",
      inputOverdraftError: "",
    });
    setValidData({
      validFee1: false,
      validFee2: false,
      validFee3: false,
      validFee4: false,
      validFee5: false,
      validFee6: false,
      validFee7: false,
      validFee8: false,
      validFee9: false,
      validFee10: false,
      validFee11: false,
      validFee12: false,
      validFee13: false,
      validOverdraft: false,
    });
  };

  const onEuroBack = () => {
    setEuroMod(!euroMod);
    setInputFee6(null);
    setInputFee7(null);
    setInputFee8(null);
    setInputFee9(null);
    setInputFee10(null);
    setInputFee12(null);
    setInputFee13(null);
    setProx(false);
    setOverdraftFeeOpen(false);
    setErrorMessages({
      feeError1: "",
      feeError2: "",
      feeError3: "",
      feeError4: "",
      feeError5: "",
      feeError6: "",
      feeError7: "",
      feeError8: "",
      feeError9: "",
      feeError10: "",
      feeError11: "",
      feeError12: "",
      feeError13: "",
      overdraftError: "",
      inputOverdraftError: "",
    });
    setValidData({
      validFee1: false,
      validFee2: false,
      validFee3: false,
      validFee4: false,
      validFee5: false,
      validFee6: false,
      validFee7: false,
      validFee8: false,
      validFee9: false,
      validFee10: false,
      validFee11: false,
      validFee12: false,
      validFee13: false,
      validOverdraft: false,
    });
  };

  const onCBCBackButton = () => {
    setCBCBankMod(!cbcBankMod);
    setInputFee3(null);
    setInputFee4(null);
    setInputFee5(null);
    setProx(false);
    setOverdraftFeeOpen(false);
    setErrorMessages({
      feeError1: "",
      feeError2: "",
      feeError3: "",
      feeError4: "",
      feeError5: "",
      feeError6: "",
      feeError7: "",
      feeError8: "",
      feeError9: "",
      feeError10: "",
      feeError11: "",
      feeError12: "",
      feeError13: "",
      overdraftError: "",
      inputOverdraftError: "",
    });
    setValidData({
      validFee1: false,
      validFee2: false,
      validFee3: false,
      validFee4: false,
      validFee5: false,
      validFee6: false,
      validFee7: false,
      validFee8: false,
      validFee9: false,
      validFee10: false,
      validFee11: false,
      validFee12: false,
      validFee13: false,
      validOverdraft: false,
    });
  };

  const onCogSave = () => {
    if (
      errorMessages?.feeError1?.length === 0 &&
      errorMessages?.feeError2?.length === 0 &&
      errorMessages?.feeError3?.length === 0 &&
      errorMessages?.feeError4?.length === 0 &&
      errorMessages?.feeError5?.length === 0 &&
      errorMessages?.feeError6?.length === 0 &&
      errorMessages?.feeError7?.length === 0 &&
      errorMessages?.feeError8?.length === 0 &&
      errorMessages?.feeError9?.length === 0 &&
      errorMessages?.feeError10?.length === 0 &&
      errorMessages?.feeError11?.length === 0 &&
      errorMessages?.feeError12?.length === 0 &&
      errorMessages?.feeError13?.length === 0 &&
      errorMessages?.overdraftError?.length === 0 &&
      errorMessages?.inputOverdraftError?.length === 0
    ) {
      if (inputOverdraftValue != null || overdraftFeeValue != null) {
        // let overdraftId = Object.keys(inputOverdraftValue)[0];
        // let overdraftValue = inputOverdraftValue[overdraftId];

        let overdraftId = activeOverdraft?.overdraft_id;
        let overdraftValue = inputOverdraftValue["overdraft"]
          ? inputOverdraftValue["overdraft"]
          : prevOverdraftValue;

        updateOverdraftValue({
          id: user?._id,
          overdraftId: overdraftId,
          overdraftValue: overdraftValue,
          overdraftFeeValue: overdraftFeeValue,
        });
        setInputOverdraftValue(null);
        setOverdraftFeeValue(null);
        setProx(false);
      }
      if (inputFee1 != null && inputFee1 != prevInputFee1) {
        updateCustomFee({
          id: user?._id,
          feeTypeId: 1,
          feeValue: inputFee1,
        });
        setInputFee1(null);
      }
      if (inputFee2 != null && inputFee2 != prevInputFee2) {
        updateCustomFee({
          id: user?._id,
          feeTypeId: 2,
          feeValue: inputFee2,
        });
        setInputFee2(null);
      }
    }
    onCogBack();
  };

  const onCogInvSave = () => {
    if (
      errorMessages?.feeError1?.length === 0 &&
      errorMessages?.feeError2?.length === 0 &&
      errorMessages?.feeError3?.length === 0 &&
      errorMessages?.feeError4?.length === 0 &&
      errorMessages?.feeError5?.length === 0 &&
      errorMessages?.feeError6?.length === 0 &&
      errorMessages?.feeError7?.length === 0 &&
      errorMessages?.feeError8?.length === 0 &&
      errorMessages?.feeError9?.length === 0 &&
      errorMessages?.feeError10?.length === 0 &&
      errorMessages?.feeError11?.length === 0 &&
      errorMessages?.feeError12?.length === 0 &&
      errorMessages?.feeError13?.length === 0 &&
      errorMessages?.overdraftError?.length === 0 &&
      errorMessages?.inputOverdraftError?.length === 0
    ) {
      if (inputOverdraftValue != null || overdraftFeeValue != null) {
        // let overdraftId = Object.keys(inputOverdraftValue)[0];
        // let overdraftValue = inputOverdraftValue[overdraftId];

        let overdraftId = activeOverdraft?.overdraft_id;
        let overdraftValue = inputOverdraftValue["overdraft"]
          ? inputOverdraftValue["overdraft"]
          : prevOverdraftValue;

        updateOverdraftValue({
          id: user?._id,
          overdraftId: overdraftId,
          overdraftValue: overdraftValue,
          overdraftFeeValue: overdraftFeeValue,
        });
        setInputOverdraftValue(null);
        setOverdraftFeeValue(null);
        setProx(false);
      }
      if (inputFee11 != null && inputFee11 != prevInputFee11) {
        updateCustomFee({
          id: user?._id,
          feeTypeId: 11,
          feeValue: inputFee11,
        });
        setInputFee11(null);
      }
    }
    onCogInvBack();
  };

  const onEuroSave = () => {
    if (
      errorMessages?.feeError1?.length === 0 &&
      errorMessages?.feeError2?.length === 0 &&
      errorMessages?.feeError3?.length === 0 &&
      errorMessages?.feeError4?.length === 0 &&
      errorMessages?.feeError5?.length === 0 &&
      errorMessages?.feeError6?.length === 0 &&
      errorMessages?.feeError7?.length === 0 &&
      errorMessages?.feeError8?.length === 0 &&
      errorMessages?.feeError9?.length === 0 &&
      errorMessages?.feeError10?.length === 0 &&
      errorMessages?.feeError11?.length === 0 &&
      errorMessages?.feeError12?.length === 0 &&
      errorMessages?.feeError13?.length === 0 &&
      errorMessages?.overdraftError?.length === 0
    ) {
      if (inputOverdraftValue != null || overdraftFeeValue != null) {
        // let overdraftId = Object.keys(inputOverdraftValue)[0];
        // let overdraftValue = inputOverdraftValue[overdraftId];

        let overdraftId = activeOverdraft?.overdraft_id;
        let overdraftValue = inputOverdraftValue["overdraft"]
          ? inputOverdraftValue["overdraft"]
          : prevOverdraftValue;

        updateOverdraftValue({
          id: user?._id,
          overdraftId: overdraftId,
          overdraftValue: overdraftValue,
          overdraftFeeValue: overdraftFeeValue,
        });
        setInputOverdraftValue(null);
        setOverdraftFeeValue(null);
        setProx(false);
      }

      if (inputFee6 != null && inputFee6 != prevInputFee6) {
        updateCustomFee({
          id: user?._id,
          feeTypeId: 6,
          feeValue: inputFee6,
        });
        setInputFee6(null);
      }
      if (inputFee7 != null && inputFee7 != prevInputFee7) {
        updateCustomFee({
          id: user?._id,
          feeTypeId: 7,
          feeValue: inputFee7,
        });
        setInputFee7(null);
      }
      if (inputFee8 != null && inputFee8 != prevInputFee8) {
        updateCustomFee({
          id: user?._id,
          feeTypeId: 8,
          feeValue: inputFee8,
        });
        setInputFee8(null);
      }
      if (inputFee9 != null && inputFee9 != prevInputFee9) {
        updateCustomFee({
          id: user?._id,
          feeTypeId: 9,
          feeValue: inputFee9,
        });
        setInputFee9(null);
      }
      if (inputFee10 != null && inputFee10 != prevInputFee10) {
        updateCustomFee({
          id: user?._id,
          feeTypeId: 10,
          feeValue: inputFee10,
        });
        setInputFee10(null);
      }
      if (inputFee12 != null && inputFee12 != prevInputFee12) {
        updateCustomFee({
          id: user?._id,
          feeTypeId: 12,
          feeValue: inputFee12,
        });
        setInputFee12(null);
      }
      if (inputFee13 != null && inputFee13 != prevInputFee13) {
        updateCustomFee({
          id: user?._id,
          feeTypeId: 13,
          feeValue: inputFee13,
        });
        setInputFee13(null);
      }
    }
    onEuroBack();
  };

  const onCBCBankSave = () => {
    if (
      errorMessages?.feeError1?.length === 0 &&
      errorMessages?.feeError2?.length === 0 &&
      errorMessages?.feeError3?.length === 0 &&
      errorMessages?.feeError4?.length === 0 &&
      errorMessages?.feeError5?.length === 0 &&
      errorMessages?.feeError6?.length === 0 &&
      errorMessages?.feeError7?.length === 0 &&
      errorMessages?.feeError8?.length === 0 &&
      errorMessages?.feeError9?.length === 0 &&
      errorMessages?.feeError10?.length === 0 &&
      errorMessages?.feeError11?.length === 0 &&
      errorMessages?.feeError12?.length === 0 &&
      errorMessages?.feeError13?.length === 0 &&
      errorMessages?.overdraftError?.length === 0
    ) {
      if (inputFee3 != null && inputFee3 != prevInputFee3) {
        updateCustomFee({
          id: user?._id,
          feeTypeId: 3,
          feeValue: inputFee3,
        });
        setInputFee3(null);
      }
      if (inputFee4 != null && inputFee4 != prevInputFee4) {
        updateCustomFee({
          id: user?._id,
          feeTypeId: 4,
          feeValue: inputFee4,
        });
        setInputFee4(null);
      }

      if (inputFee5 != null && inputFee5 != prevInputFee5) {
        updateCustomFee({
          id: user?._id,
          feeTypeId: 5,
          feeValue: inputFee5,
        });
        setInputFee5(null);
      }
    }
    onCBCBackButton();
  };

  const onFeeDefault = (e, inputFee, fnc) => {
    if (user) {
      if (inputFee == null) {
        updateCustomFee({
          id: user?._id,
          feeTypeId: e.currentTarget.value,
          feeValue: null,
        });
      }
    }
    fnc();
  };

  return (
    <React.Fragment>
      <Grid item container>
        {/* <Paper
          className={classes.paper}
          // style={{ width: theme.spacing(50) }}
          id="banking"
          elevation={5}
        > */}
        <Grid container spacing={1} style={{ marginLeft: "1rem" }}>
          <Grid
            item
            container
            xs={12}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5" align="left">
                Banking
              </Typography>
            </Grid>

            {/* block/unblock button */}
            <Grid item xs={2}>
              {componentAccess(
                admin?.level,
                admin?.type,
                "Users",
                "modifyBankingData"
              ) ? (
                <Button
                  onClick={toggleAccountStatus(!accountStatus)}
                  variant="contained"
                  style={{
                    color: "white",
                    backgroundColor:
                      accountStatus === false ? "#098029" : "#c43400",
                    borderRadius: "5px",
                  }}
                >
                  {accountStatus === false ? "Unblock" : "Block"}
                </Button>
              ) : null}
            </Grid>
          </Grid>

          <Grid item container xs={12} alignItems="center" spacing={2}>
            {user?.accounts?.map((account) => {
              return (
                <React.Fragment>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.resultContainer}
                  >
                    <Grid item xs={10} sm={10}>
                      <Typography variant="h6" align="left">
                        {account.account_type}
                      </Typography>
                    </Grid>

                    {/* header part with Account Name and modify button..... only for Cog txn, Euro Txn, CBC */}
                    <Grid item xs={2} sm={2}>
                      {account.account_type == "Cog Transaction" ? (
                        <>
                          {cogMod ? (
                            <>
                              {/* on modify button clicked */}
                              <Grid container item spacing={1}>
                                <Grid item>
                                  <CustomButton
                                    onClick={onCogBack}
                                    disabled={
                                      loadings.updateCustomFee ||
                                      loadings.updateOverdraftValue
                                    }
                                  >
                                    Back
                                  </CustomButton>
                                </Grid>
                                <Grid item>
                                  <SuccessButton
                                    onClick={onCogSave}
                                    disabled={
                                      !(
                                        errorMessages?.feeError1?.length ===
                                          0 &&
                                        errorMessages?.feeError2?.length ===
                                          0 &&
                                        errorMessages?.feeError3?.length ===
                                          0 &&
                                        errorMessages?.feeError4?.length ===
                                          0 &&
                                        errorMessages?.feeError5?.length ===
                                          0 &&
                                        errorMessages?.feeError6?.length ===
                                          0 &&
                                        errorMessages?.feeError7?.length ===
                                          0 &&
                                        errorMessages?.feeError8?.length ===
                                          0 &&
                                        errorMessages?.feeError9?.length ===
                                          0 &&
                                        errorMessages?.feeError10?.length ===
                                          0 &&
                                        errorMessages?.feeError11?.length ===
                                          0 &&
                                        errorMessages?.overdraftError
                                          ?.length === 0 &&
                                        errorMessages?.inputOverdraftError
                                          ?.length === 0 &&
                                        user?.accounts &&
                                        (inputFee1 ||
                                          inputFee2 ||
                                          inputFee3 ||
                                          inputFee4 ||
                                          inputFee5 ||
                                          inputFee6 ||
                                          inputFee7 ||
                                          inputFee8 ||
                                          inputFee9 ||
                                          inputFee10 ||
                                          inputFee11 ||
                                          inputOverdraftValue ||
                                          inputOverdraftValue !== null ||
                                          prox)
                                      )
                                    }
                                  >
                                    Save
                                  </SuccessButton>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <>
                              {/* on modify NOT clicked */}
                              {componentAccess(
                                admin?.level,
                                admin?.type,
                                "Users",
                                "modifyBankingData"
                              ) ? (
                                <Grid container item spacing={1}>
                                  <Grid item xs={2}>
                                    <CustomButton
                                      onClick={() => setCogMod(!cogMod)}
                                      disabled={
                                        loadings.updateCustomFee ||
                                        loadings.updateOverdraftValue ||
                                        !Boolean(user.accounts) ||
                                        !componentAccess(
                                          admin?.level,
                                          admin?.type,
                                          "Users",
                                          "modifyBankingData"
                                        )
                                      }
                                      align="right"
                                    >
                                      Modify
                                    </CustomButton>
                                  </Grid>
                                </Grid>
                              ) : null}
                            </>
                          )}
                        </>
                      ) : account.account_type == "Cog Investment" ? (
                        <>
                          {cogInvMod ? (
                            <>
                              {/* on modify button clicked */}
                              <Grid container item spacing={1}>
                                <Grid item>
                                  <CustomButton
                                    onClick={onCogInvBack}
                                    disabled={
                                      loadings.updateCustomFee ||
                                      loadings.updateOverdraftValue
                                    }
                                  >
                                    Back
                                  </CustomButton>
                                </Grid>
                                <Grid item>
                                  <SuccessButton
                                    onClick={onCogInvSave}
                                    disabled={
                                      !(
                                        errorMessages?.feeError1?.length ===
                                          0 &&
                                        errorMessages?.feeError2?.length ===
                                          0 &&
                                        errorMessages?.feeError3?.length ===
                                          0 &&
                                        errorMessages?.feeError4?.length ===
                                          0 &&
                                        errorMessages?.feeError5?.length ===
                                          0 &&
                                        errorMessages?.feeError6?.length ===
                                          0 &&
                                        errorMessages?.feeError7?.length ===
                                          0 &&
                                        errorMessages?.feeError8?.length ===
                                          0 &&
                                        errorMessages?.feeError9?.length ===
                                          0 &&
                                        errorMessages?.feeError10?.length ===
                                          0 &&
                                        errorMessages?.feeError11?.length ===
                                          0 &&
                                        errorMessages?.overdraftError
                                          ?.length === 0 &&
                                        errorMessages?.inputOverdraftError
                                          ?.length === 0 &&
                                        user?.accounts &&
                                        (inputFee1 ||
                                          inputFee2 ||
                                          inputFee3 ||
                                          inputFee4 ||
                                          inputFee5 ||
                                          inputFee6 ||
                                          inputFee7 ||
                                          inputFee8 ||
                                          inputFee9 ||
                                          inputFee10 ||
                                          inputFee11 ||
                                          inputOverdraftValue ||
                                          inputOverdraftValue !== null ||
                                          prox)
                                      )
                                    }
                                  >
                                    Save
                                  </SuccessButton>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <>
                              {/* on modify NOT clicked */}
                              {componentAccess(
                                admin?.level,
                                admin?.type,
                                "Users",
                                "modifyBankingData"
                              ) ? (
                                <Grid container item spacing={1}>
                                  <Grid item xs={2}>
                                    <CustomButton
                                      onClick={() => setCogInvMod(!cogInvMod)}
                                      disabled={
                                        loadings.updateCustomFee ||
                                        loadings.updateOverdraftValue ||
                                        !Boolean(user.accounts) ||
                                        !componentAccess(
                                          admin?.level,
                                          admin?.type,
                                          "Users",
                                          "modifyBankingData"
                                        )
                                      }
                                      align="right"
                                    >
                                      Modify
                                    </CustomButton>
                                  </Grid>
                                </Grid>
                              ) : null}
                            </>
                          )}
                        </>
                      ) : null}

                      {account.account_type == "Euro Transaction" ? (
                        <>
                          {euroMod ? (
                            <Grid container item spacing={1}>
                              <Grid item>
                                <CustomButton
                                  onClick={onEuroBack}
                                  disabled={
                                    loadings.updateCustomFee ||
                                    loadings.updateOverdraftValue
                                  }
                                >
                                  Back
                                </CustomButton>
                              </Grid>
                              <Grid item>
                                <SuccessButton
                                  onClick={onEuroSave}
                                  disabled={
                                    !(
                                      errorMessages?.feeError1?.length === 0 &&
                                      errorMessages?.feeError2?.length === 0 &&
                                      errorMessages?.feeError3?.length === 0 &&
                                      errorMessages?.feeError4?.length === 0 &&
                                      errorMessages?.feeError5?.length === 0 &&
                                      errorMessages?.feeError6?.length === 0 &&
                                      errorMessages?.feeError7?.length === 0 &&
                                      errorMessages?.feeError8?.length === 0 &&
                                      errorMessages?.feeError9?.length === 0 &&
                                      errorMessages?.feeError10?.length === 0 &&
                                      errorMessages?.feeError11?.length === 0 &&
                                      errorMessages?.feeError12?.length === 0 &&
                                      errorMessages?.feeError13?.length === 0 &&
                                      errorMessages?.overdraftError?.length ===
                                        0 &&
                                      user?.accounts &&
                                      (inputFee1 ||
                                        inputFee2 ||
                                        inputFee3 ||
                                        inputFee4 ||
                                        inputFee5 ||
                                        inputFee6 ||
                                        inputFee7 ||
                                        inputFee8 ||
                                        inputFee9 ||
                                        inputFee10 ||
                                        inputFee11 ||
                                        inputFee12 ||
                                        inputFee13 ||
                                        inputOverdraftValue ||
                                        prox)
                                    )
                                  }
                                >
                                  Save
                                </SuccessButton>
                              </Grid>
                            </Grid>
                          ) : (
                            <>
                              {componentAccess(
                                admin?.level,
                                admin?.type,
                                "Users",
                                "modifyBankingData"
                              ) ? (
                                <Grid container item spacing={1}>
                                  <Grid item xs={2}>
                                    <CustomButton
                                      onClick={() => setEuroMod(!euroMod)}
                                      disabled={
                                        loadings.updateCustomFee ||
                                        loadings.updateOverdraftValue ||
                                        !Boolean(user.accounts) ||
                                        !componentAccess(
                                          admin?.level,
                                          admin?.type,
                                          "Users",
                                          "modifyBankingData"
                                        )
                                      }
                                      align="right"
                                    >
                                      Modify
                                    </CustomButton>
                                  </Grid>
                                </Grid>
                              ) : null}
                            </>
                          )}
                        </>
                      ) : null}

                      {account.account_type == "Cash Backed Cog" ? (
                        <>
                          {cbcBankMod ? (
                            <Grid container item spacing={1}>
                              <Grid item>
                                <CustomButton
                                  onClick={onCBCBackButton}
                                  disabled={
                                    loadings.updateCustomFee ||
                                    loadings.updateOverdraftValue
                                  }
                                >
                                  Back
                                </CustomButton>
                              </Grid>
                              <Grid item>
                                <SuccessButton
                                  onClick={onCBCBankSave}
                                  disabled={
                                    !(
                                      errorMessages?.feeError1?.length === 0 &&
                                      errorMessages?.feeError2?.length === 0 &&
                                      errorMessages?.feeError3?.length === 0 &&
                                      errorMessages?.feeError4?.length === 0 &&
                                      errorMessages?.feeError5?.length === 0 &&
                                      errorMessages?.feeError6?.length === 0 &&
                                      errorMessages?.feeError7?.length === 0 &&
                                      errorMessages?.feeError8?.length === 0 &&
                                      errorMessages?.feeError9?.length === 0 &&
                                      errorMessages?.feeError10?.length === 0 &&
                                      errorMessages?.feeError11?.length === 0 &&
                                      errorMessages?.overdraftError?.length ===
                                        0 &&
                                      user?.accounts &&
                                      (inputFee1 ||
                                        inputFee2 ||
                                        inputFee3 ||
                                        inputFee4 ||
                                        inputFee5 ||
                                        inputFee6 ||
                                        inputFee7 ||
                                        inputFee8 ||
                                        inputFee9 ||
                                        inputFee10 ||
                                        inputFee11 ||
                                        inputOverdraftValue ||
                                        prox)
                                    )
                                  }
                                >
                                  Save
                                </SuccessButton>
                              </Grid>
                            </Grid>
                          ) : (
                            <>
                              {componentAccess(
                                admin?.level,
                                admin?.type,
                                "Users",
                                "modifyBankingData"
                              ) ? (
                                <>
                                  <Grid container item spacing={1}>
                                    <Grid item xs={2}>
                                      <CustomButton
                                        onClick={() =>
                                          setCBCBankMod(!cbcBankMod)
                                        }
                                        disabled={
                                          loadings.updateCustomFee ||
                                          loadings.updateOverdraftValue ||
                                          !Boolean(user.accounts) ||
                                          !componentAccess(
                                            admin?.level,
                                            admin?.type,
                                            "Users",
                                            "modifyBankingData"
                                          )
                                        }
                                        align="right"
                                      >
                                        Modify
                                      </CustomButton>
                                    </Grid>
                                  </Grid>
                                </>
                              ) : null}
                            </>
                          )}
                        </>
                      ) : null}
                    </Grid>

                    <>
                      {/* 2nd row i.e balance */}
                      <Grid item xs={12} sm={4} spacing={4}>
                        <Typography
                          variant="body1"
                          align="left"
                          style={{ color: "#359DB6" }}
                        >
                          Balance:
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={8}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          align="left"
                        >
                          {currencyFormatter(account.balance)}
                          {account.account_type == "Euro Transaction" ||
                          account.account_type == "Euro Rebate"
                            ? "€"
                            : "C"}
                        </Typography>
                      </Grid>
                    </>

                    {/* 3rd row over draft and edit fee value */}
                    {account?.overdraft_details != null ? (
                      <>
                        <Grid item xs={4} sm={12} spacing={4}>
                          <Typography
                            variant="body1"
                            align="left"
                            style={{
                              color: "#359DB6",
                              fontWeight: (cogMod || cogInvMod) && "bold",
                            }}
                          >
                            Overdraft Details:
                            <Typography
                              variant="body2"
                              align="left"
                              style={{
                                color: "#11CA73",
                                marginBottom: 5,
                                fontStyle: "italic",
                              }}
                            >
                              {(cogInvMod &&
                                account.account_type == "Cog Investment") ||
                              (cogMod &&
                                account.account_type == "Cog Transaction") ||
                              (euroMod &&
                                account.account_type == "Euro Transaction")
                                ? "NOTE: To change OD details, OD value and OD Fee value both are required."
                                : null}
                            </Typography>
                          </Typography>
                        </Grid>
                        {admins[1] && admins[1].Cogito ? (
                          <>
                            {/* start */}
                            <Grid
                              item
                              container
                              xs={12}
                              sm={
                                account.overdraft_details.length === 0 &&
                                (!cogMod || !euroMod || !cogInvMod)
                                  ? 3
                                  : 12
                              }
                            >
                              {account.account_type == "Cog Transaction" ? (
                                cogMod ? (
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ marginTop: 10, marginBottom: 5 }}
                                  >
                                    <CustomInput
                                      variant="outlined"
                                      label="overdraft"
                                      name="overdraft"
                                      id={
                                        account.overdraft_details.overdraft_id
                                      }
                                      type="number"
                                      error={
                                        errorMessages?.overdraftError?.length >
                                        0
                                      }
                                      helperText={errorMessages?.overdraftError}
                                      // defaultValue={
                                      //   account?.account_type ==
                                      //   "Cog Transaction"
                                      //     ? activeOverdraft?.overdraft_value
                                      //     : account?.overdraft_details
                                      //         ?.overdraft_value
                                      // }
                                      onChange={onOverdraftValueChange}
                                    />
                                  </Grid>
                                ) : (
                                  <>
                                    {account?.overdraft_details?.length > 0 ? (
                                      account?.overdraft_details?.map(
                                        (overdraft) => {
                                          return (
                                            <Grid
                                              item
                                              container
                                              xs={12}
                                              style={{
                                                marginTop: 20,
                                                borderRadius: 10,
                                                border: "1px solid #288EA6",
                                                padding: 10,
                                              }}
                                            >
                                              {Object.keys(overdraft).map(
                                                (key, index) => {
                                                  return (
                                                    <>
                                                      <Grid
                                                        item
                                                        container
                                                        key={index}
                                                      >
                                                        {overdraft[key] !==
                                                          null && (
                                                          <>
                                                            <Grid item xs={6}>
                                                              <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                align="left"
                                                              >
                                                                {key}
                                                              </Typography>
                                                            </Grid>

                                                            <Grid item xs={6}>
                                                              {key ==
                                                                "approval_docs" &&
                                                              overdraft[key] !==
                                                                null ? (
                                                                overdraft[
                                                                  key
                                                                ].map(
                                                                  (
                                                                    image,
                                                                    idx
                                                                  ) => {
                                                                    return (
                                                                      <>
                                                                        <Grid
                                                                          item
                                                                          xs={
                                                                            12
                                                                          }
                                                                          sm={6}
                                                                          key={
                                                                            idx
                                                                          }
                                                                        >
                                                                          <Typography
                                                                            variant="body2"
                                                                            color="textSecondary"
                                                                            align="left"
                                                                          >
                                                                            {true && (
                                                                              <button
                                                                                style={{
                                                                                  padding: 0,
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={() =>
                                                                                  handleOpen(
                                                                                    idx
                                                                                  )
                                                                                }
                                                                              >
                                                                                {
                                                                                  <img
                                                                                    width="150px"
                                                                                    height="100px"
                                                                                    src={`${process.env.REACT_APP_SPACE_LINK}/${image}`}
                                                                                  />
                                                                                }
                                                                              </button>
                                                                            )}
                                                                          </Typography>
                                                                        </Grid>

                                                                        <Modal
                                                                          aria-labelledby="transition-modal-title"
                                                                          aria-describedby="transition-modal-description"
                                                                          className={
                                                                            classes.modal
                                                                          }
                                                                          open={
                                                                            open[
                                                                              idx
                                                                            ]
                                                                              ? open[
                                                                                  idx
                                                                                ]
                                                                              : false
                                                                          }
                                                                          onClose={() =>
                                                                            handleClose(
                                                                              idx
                                                                            )
                                                                          }
                                                                          closeAfterTransition
                                                                          BackdropComponent={
                                                                            Backdrop
                                                                          }
                                                                          BackdropProps={{
                                                                            timeout: 500,
                                                                          }}
                                                                        >
                                                                          <Fade
                                                                            in={
                                                                              open[
                                                                                idx
                                                                              ]
                                                                                ? open[
                                                                                    idx
                                                                                  ]
                                                                                : false
                                                                            }
                                                                          >
                                                                            <div
                                                                              className={
                                                                                classes.paper1
                                                                              }
                                                                            >
                                                                              {
                                                                                <img
                                                                                  width="500px"
                                                                                  height="500px"
                                                                                  src={`${process.env.REACT_APP_SPACE_LINK}/${image}`}
                                                                                />
                                                                              }
                                                                            </div>
                                                                          </Fade>
                                                                        </Modal>
                                                                      </>
                                                                    );
                                                                  }
                                                                )
                                                              ) : (
                                                                <>
                                                                  <Typography
                                                                    variant="body2"
                                                                    style={{
                                                                      color:
                                                                        overdraft[
                                                                          key
                                                                        ] ==
                                                                        false
                                                                          ? "red"
                                                                          : overdraft[
                                                                              key
                                                                            ] ==
                                                                            true
                                                                          ? "#54B435"
                                                                          : "#fff",
                                                                    }}
                                                                    align="left"
                                                                  >
                                                                    {`${overdraft[key]} `}
                                                                    {key ===
                                                                    "overdraft_value"
                                                                      ? account.account_type ==
                                                                          "Euro Transaction" ||
                                                                        account.account_type ==
                                                                          "Euro Rebate"
                                                                        ? "€"
                                                                        : "C"
                                                                      : null}
                                                                  </Typography>
                                                                </>
                                                              )}
                                                            </Grid>
                                                          </>
                                                        )}
                                                      </Grid>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </Grid>
                                          );
                                        }
                                      )
                                    ) : (
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        align="left"
                                      >
                                        {`${0}`}
                                        {account.account_type ==
                                          "Euro Transaction" ||
                                        account.account_type == "Euro Rebate"
                                          ? "€"
                                          : "C"}
                                      </Typography>
                                    )}
                                  </>
                                )
                              ) : account.account_type == "Cog Investment" ? (
                                cogInvMod ? (
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ marginTop: 10, marginBottom: 5 }}
                                  >
                                    <CustomInput
                                      variant="outlined"
                                      label="overdraft"
                                      name="overdraft"
                                      id={
                                        account.overdraft_details.overdraft_id
                                      }
                                      type="number"
                                      error={
                                        errorMessages?.overdraftError?.length >
                                        0
                                      }
                                      helperText={errorMessages?.overdraftError}
                                      // defaultValue={
                                      //   account?.account_type ==
                                      //   "Cog Transaction"
                                      //     ? activeOverdraft?.overdraft_value
                                      //     : account?.overdraft_details
                                      //         ?.overdraft_value
                                      // }
                                      onChange={onOverdraftValueChange}
                                    />
                                  </Grid>
                                ) : (
                                  <>
                                    {account?.overdraft_details?.length > 0 ? (
                                      account?.overdraft_details?.map(
                                        (overdraft) => {
                                          return (
                                            <Grid
                                              item
                                              container
                                              xs={12}
                                              style={{
                                                marginTop: 20,
                                                borderRadius: 10,
                                                border: "1px solid #288EA6",
                                                padding: 10,
                                              }}
                                            >
                                              {Object.keys(overdraft).map(
                                                (key, index) => {
                                                  return (
                                                    <>
                                                      <Grid
                                                        item
                                                        container
                                                        key={index}
                                                      >
                                                        {overdraft[key] !==
                                                          null && (
                                                          <>
                                                            <Grid item xs={6}>
                                                              <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                align="left"
                                                              >
                                                                {key}
                                                              </Typography>
                                                            </Grid>

                                                            <Grid item xs={6}>
                                                              {key ==
                                                                "approval_docs" &&
                                                              overdraft[key] !==
                                                                null ? (
                                                                overdraft[
                                                                  key
                                                                ].map(
                                                                  (
                                                                    image,
                                                                    idx
                                                                  ) => {
                                                                    return (
                                                                      <>
                                                                        <Grid
                                                                          item
                                                                          xs={
                                                                            12
                                                                          }
                                                                          sm={6}
                                                                          key={
                                                                            idx
                                                                          }
                                                                        >
                                                                          <Typography
                                                                            variant="body2"
                                                                            color="textSecondary"
                                                                            align="left"
                                                                          >
                                                                            {true && (
                                                                              <button
                                                                                style={{
                                                                                  padding: 0,
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={() =>
                                                                                  handleOpen(
                                                                                    idx
                                                                                  )
                                                                                }
                                                                              >
                                                                                {
                                                                                  <img
                                                                                    width="150px"
                                                                                    height="100px"
                                                                                    src={`${process.env.REACT_APP_SPACE_LINK}/${image}`}
                                                                                  />
                                                                                }
                                                                              </button>
                                                                            )}
                                                                          </Typography>
                                                                        </Grid>

                                                                        <Modal
                                                                          aria-labelledby="transition-modal-title"
                                                                          aria-describedby="transition-modal-description"
                                                                          className={
                                                                            classes.modal
                                                                          }
                                                                          open={
                                                                            open[
                                                                              idx
                                                                            ]
                                                                              ? open[
                                                                                  idx
                                                                                ]
                                                                              : false
                                                                          }
                                                                          onClose={() =>
                                                                            handleClose(
                                                                              idx
                                                                            )
                                                                          }
                                                                          closeAfterTransition
                                                                          BackdropComponent={
                                                                            Backdrop
                                                                          }
                                                                          BackdropProps={{
                                                                            timeout: 500,
                                                                          }}
                                                                        >
                                                                          <Fade
                                                                            in={
                                                                              open[
                                                                                idx
                                                                              ]
                                                                                ? open[
                                                                                    idx
                                                                                  ]
                                                                                : false
                                                                            }
                                                                          >
                                                                            <div
                                                                              className={
                                                                                classes.paper1
                                                                              }
                                                                            >
                                                                              {
                                                                                <img
                                                                                  width="500px"
                                                                                  height="500px"
                                                                                  src={`${process.env.REACT_APP_SPACE_LINK}/${image}`}
                                                                                />
                                                                              }
                                                                            </div>
                                                                          </Fade>
                                                                        </Modal>
                                                                      </>
                                                                    );
                                                                  }
                                                                )
                                                              ) : (
                                                                <>
                                                                  <Typography
                                                                    variant="body2"
                                                                    style={{
                                                                      color:
                                                                        overdraft[
                                                                          key
                                                                        ] ==
                                                                        false
                                                                          ? "red"
                                                                          : overdraft[
                                                                              key
                                                                            ] ==
                                                                            true
                                                                          ? "#54B435"
                                                                          : "#fff",
                                                                    }}
                                                                    align="left"
                                                                  >
                                                                    {`${overdraft[key]} `}
                                                                    {key ===
                                                                    "overdraft_value"
                                                                      ? account.account_type ==
                                                                          "Euro Transaction" ||
                                                                        account.account_type ==
                                                                          "Euro Rebate"
                                                                        ? "€"
                                                                        : "C"
                                                                      : null}
                                                                  </Typography>
                                                                </>
                                                              )}
                                                            </Grid>
                                                          </>
                                                        )}
                                                      </Grid>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </Grid>
                                          );
                                        }
                                      )
                                    ) : (
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        align="left"
                                      >
                                        {`${0}`}
                                        {account.account_type ==
                                          "Euro Transaction" ||
                                        account.account_type == "Euro Rebate"
                                          ? "€"
                                          : "C"}
                                      </Typography>
                                    )}
                                  </>
                                )
                              ) : account.account_type == "Euro Transaction" ? (
                                euroMod ? (
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ marginTop: 10, marginBottom: 5 }}
                                  >
                                    <CustomInput
                                      variant="outlined"
                                      label="overdraft"
                                      name="overdraft"
                                      id={
                                        account.overdraft_details.overdraft_id
                                      }
                                      type="number"
                                      error={
                                        errorMessages?.overdraftError?.length >
                                        0
                                      }
                                      helperText={errorMessages?.overdraftError}
                                      // defaultValue={
                                      //   account?.account_type ==
                                      //   "Euro Transaction"
                                      //     ? activeOverdraft?.overdraft_value
                                      //     : account?.overdraft_details
                                      //         ?.overdraft_value
                                      // }
                                      onChange={onOverdraftValueChange}
                                    />
                                  </Grid>
                                ) : (
                                  <>
                                    {account?.overdraft_details?.length > 0 ? (
                                      account?.overdraft_details?.map(
                                        (overdraft) => {
                                          return (
                                            <Grid
                                              item
                                              container
                                              xs={12}
                                              style={{
                                                marginTop: 20,
                                                borderRadius: 10,
                                                border: "1px solid #288EA6",
                                                padding: 10,
                                              }}
                                            >
                                              {Object.keys(overdraft).map(
                                                (key, index) => {
                                                  return (
                                                    <>
                                                      <Grid
                                                        item
                                                        container
                                                        key={index}
                                                      >
                                                        {overdraft[key] !==
                                                          null && (
                                                          <>
                                                            <Grid item xs={6}>
                                                              <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                align="left"
                                                              >
                                                                {key}
                                                              </Typography>
                                                            </Grid>

                                                            <Grid item xs={6}>
                                                              {key ==
                                                                "approval_docs" &&
                                                              overdraft[key] !==
                                                                null ? (
                                                                overdraft[
                                                                  key
                                                                ].map(
                                                                  (
                                                                    image,
                                                                    idx
                                                                  ) => {
                                                                    return (
                                                                      <>
                                                                        <Grid
                                                                          item
                                                                          xs={
                                                                            12
                                                                          }
                                                                          sm={6}
                                                                          key={
                                                                            idx
                                                                          }
                                                                        >
                                                                          <Typography
                                                                            variant="body2"
                                                                            color="textSecondary"
                                                                            align="left"
                                                                          >
                                                                            {true && (
                                                                              <button
                                                                                style={{
                                                                                  padding: 0,
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={() =>
                                                                                  handleOpen(
                                                                                    idx
                                                                                  )
                                                                                }
                                                                              >
                                                                                {
                                                                                  <img
                                                                                    width="150px"
                                                                                    height="100px"
                                                                                    src={`${process.env.REACT_APP_SPACE_LINK}/${image}`}
                                                                                  />
                                                                                }
                                                                              </button>
                                                                            )}
                                                                          </Typography>
                                                                        </Grid>

                                                                        <Modal
                                                                          aria-labelledby="transition-modal-title"
                                                                          aria-describedby="transition-modal-description"
                                                                          className={
                                                                            classes.modal
                                                                          }
                                                                          open={
                                                                            open[
                                                                              idx
                                                                            ]
                                                                              ? open[
                                                                                  idx
                                                                                ]
                                                                              : false
                                                                          }
                                                                          onClose={() =>
                                                                            handleClose(
                                                                              idx
                                                                            )
                                                                          }
                                                                          closeAfterTransition
                                                                          BackdropComponent={
                                                                            Backdrop
                                                                          }
                                                                          BackdropProps={{
                                                                            timeout: 500,
                                                                          }}
                                                                        >
                                                                          <Fade
                                                                            in={
                                                                              open[
                                                                                idx
                                                                              ]
                                                                                ? open[
                                                                                    idx
                                                                                  ]
                                                                                : false
                                                                            }
                                                                          >
                                                                            <div
                                                                              className={
                                                                                classes.paper1
                                                                              }
                                                                            >
                                                                              {
                                                                                <img
                                                                                  width="500px"
                                                                                  height="500px"
                                                                                  src={`${process.env.REACT_APP_SPACE_LINK}/${image}`}
                                                                                />
                                                                              }
                                                                            </div>
                                                                          </Fade>
                                                                        </Modal>
                                                                      </>
                                                                    );
                                                                  }
                                                                )
                                                              ) : (
                                                                <>
                                                                  <Typography
                                                                    variant="body2"
                                                                    style={{
                                                                      color:
                                                                        overdraft[
                                                                          key
                                                                        ] ==
                                                                        false
                                                                          ? "red"
                                                                          : overdraft[
                                                                              key
                                                                            ] ==
                                                                            true
                                                                          ? "#54B435"
                                                                          : "#fff",
                                                                    }}
                                                                    align="left"
                                                                  >
                                                                    {`${overdraft[key]} `}
                                                                    {key ===
                                                                    "overdraft_value"
                                                                      ? account.account_type ==
                                                                          "Euro Transaction" ||
                                                                        account.account_type ==
                                                                          "Euro Rebate"
                                                                        ? "€"
                                                                        : "C"
                                                                      : null}
                                                                  </Typography>
                                                                </>
                                                              )}
                                                            </Grid>
                                                          </>
                                                        )}
                                                      </Grid>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </Grid>
                                          );
                                        }
                                      )
                                    ) : (
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        align="left"
                                      >
                                        {`${0}`}
                                        {account.account_type ==
                                          "Euro Transaction" ||
                                        account.account_type == "Euro Rebate"
                                          ? "€"
                                          : "C"}
                                      </Typography>
                                    )}
                                  </>
                                )
                              ) : (
                                <>
                                  {account?.overdraft_details?.length > 0 ? (
                                    account?.overdraft_details?.map(
                                      (overdraft) => {
                                        return (
                                          <Grid
                                            item
                                            container
                                            xs={12}
                                            style={{
                                              marginTop: 20,
                                              borderRadius: 10,
                                              border: "1px solid #288EA6",
                                              padding: 10,
                                            }}
                                          >
                                            {Object.keys(overdraft).map(
                                              (key, index) => {
                                                return (
                                                  <>
                                                    <Grid
                                                      item
                                                      container
                                                      key={index}
                                                    >
                                                      {overdraft[key] !==
                                                        null && (
                                                        <>
                                                          <Grid item xs={6}>
                                                            <Typography
                                                              variant="body2"
                                                              color="textSecondary"
                                                              align="left"
                                                            >
                                                              {key}
                                                            </Typography>
                                                          </Grid>

                                                          <Grid item xs={6}>
                                                            {key ==
                                                              "approval_docs" &&
                                                            overdraft[key] !==
                                                              null ? (
                                                              overdraft[
                                                                key
                                                              ].map(
                                                                (
                                                                  image,
                                                                  idx
                                                                ) => {
                                                                  return (
                                                                    <>
                                                                      <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={6}
                                                                        key={
                                                                          idx
                                                                        }
                                                                      >
                                                                        <Typography
                                                                          variant="body2"
                                                                          color="textSecondary"
                                                                          align="left"
                                                                        >
                                                                          {true && (
                                                                            <button
                                                                              style={{
                                                                                padding: 0,
                                                                                cursor:
                                                                                  "pointer",
                                                                              }}
                                                                              onClick={() =>
                                                                                handleOpen(
                                                                                  idx
                                                                                )
                                                                              }
                                                                            >
                                                                              {
                                                                                <img
                                                                                  width="150px"
                                                                                  height="100px"
                                                                                  src={`${process.env.REACT_APP_SPACE_LINK}/${image}`}
                                                                                />
                                                                              }
                                                                            </button>
                                                                          )}
                                                                        </Typography>
                                                                      </Grid>

                                                                      <Modal
                                                                        aria-labelledby="transition-modal-title"
                                                                        aria-describedby="transition-modal-description"
                                                                        className={
                                                                          classes.modal
                                                                        }
                                                                        open={
                                                                          open[
                                                                            idx
                                                                          ]
                                                                            ? open[
                                                                                idx
                                                                              ]
                                                                            : false
                                                                        }
                                                                        onClose={() =>
                                                                          handleClose(
                                                                            idx
                                                                          )
                                                                        }
                                                                        closeAfterTransition
                                                                        BackdropComponent={
                                                                          Backdrop
                                                                        }
                                                                        BackdropProps={{
                                                                          timeout: 500,
                                                                        }}
                                                                      >
                                                                        <Fade
                                                                          in={
                                                                            open[
                                                                              idx
                                                                            ]
                                                                              ? open[
                                                                                  idx
                                                                                ]
                                                                              : false
                                                                          }
                                                                        >
                                                                          <div
                                                                            className={
                                                                              classes.paper1
                                                                            }
                                                                          >
                                                                            {
                                                                              <img
                                                                                width="500px"
                                                                                height="500px"
                                                                                src={`${process.env.REACT_APP_SPACE_LINK}/${image}`}
                                                                              />
                                                                            }
                                                                          </div>
                                                                        </Fade>
                                                                      </Modal>
                                                                    </>
                                                                  );
                                                                }
                                                              )
                                                            ) : (
                                                              <>
                                                                <Typography
                                                                  variant="body2"
                                                                  style={{
                                                                    color:
                                                                      overdraft[
                                                                        key
                                                                      ] == false
                                                                        ? "red"
                                                                        : overdraft[
                                                                            key
                                                                          ] ==
                                                                          true
                                                                        ? "#54B435"
                                                                        : "#fff",
                                                                  }}
                                                                  align="left"
                                                                >
                                                                  {`${overdraft[key]} `}
                                                                  {key ===
                                                                  "overdraft_value"
                                                                    ? account.account_type ==
                                                                        "Euro Transaction" ||
                                                                      account.account_type ==
                                                                        "Euro Rebate"
                                                                      ? "€"
                                                                      : "C"
                                                                    : null}
                                                                </Typography>
                                                              </>
                                                            )}
                                                          </Grid>
                                                        </>
                                                      )}
                                                    </Grid>
                                                  </>
                                                );
                                              }
                                            )}
                                          </Grid>
                                        );
                                      }
                                    )
                                  ) : (
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      align="left"
                                    >
                                      {`${0}`}
                                      {account.account_type ==
                                        "Euro Transaction" ||
                                      account.account_type == "Euro Rebate"
                                        ? "€"
                                        : "C"}
                                    </Typography>
                                  )}
                                </>
                              )}
                            </Grid>
                          </>
                        ) : (
                          <>
                            {/* for other admins */}
                            <>
                              {account?.overdraft_details?.length > 0 ? (
                                account?.overdraft_details?.map((overdraft) => {
                                  return (
                                    <Grid
                                      item
                                      container
                                      xs={12}
                                      style={{
                                        marginTop: 20,
                                        borderRadius: 10,
                                        border: "1px solid #288EA6",
                                        padding: 10,
                                      }}
                                    >
                                      {Object.keys(overdraft).map(
                                        (key, index) => {
                                          return (
                                            <Grid item container key={index}>
                                              {overdraft[key] !== null && (
                                                <>
                                                  <Grid item xs={6}>
                                                    <Typography
                                                      variant="body2"
                                                      color="textSecondary"
                                                      align="left"
                                                    >
                                                      {key}
                                                    </Typography>
                                                  </Grid>

                                                  <Grid item xs={6}>
                                                    {key == "approval_docs" &&
                                                    overdraft[key] !== null ? (
                                                      overdraft[key].map(
                                                        (image, idx) => {
                                                          return (
                                                            <>
                                                              <Grid
                                                                item
                                                                xs={12}
                                                                sm={6}
                                                                key={idx}
                                                              >
                                                                <Typography
                                                                  variant="body2"
                                                                  color="textSecondary"
                                                                  align="left"
                                                                >
                                                                  {true && (
                                                                    <button
                                                                      style={{
                                                                        padding: 0,
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        handleOpen(
                                                                          idx
                                                                        )
                                                                      }
                                                                    >
                                                                      {
                                                                        <img
                                                                          width="150px"
                                                                          height="100px"
                                                                          src={`${process.env.REACT_APP_SPACE_LINK}/${image}`}
                                                                        />
                                                                      }
                                                                    </button>
                                                                  )}
                                                                </Typography>
                                                              </Grid>

                                                              <Modal
                                                                aria-labelledby="transition-modal-title"
                                                                aria-describedby="transition-modal-description"
                                                                className={
                                                                  classes.modal
                                                                }
                                                                open={
                                                                  open[idx]
                                                                    ? open[idx]
                                                                    : false
                                                                }
                                                                onClose={() =>
                                                                  handleClose(
                                                                    idx
                                                                  )
                                                                }
                                                                closeAfterTransition
                                                                BackdropComponent={
                                                                  Backdrop
                                                                }
                                                                BackdropProps={{
                                                                  timeout: 500,
                                                                }}
                                                              >
                                                                <Fade
                                                                  in={
                                                                    open[idx]
                                                                      ? open[
                                                                          idx
                                                                        ]
                                                                      : false
                                                                  }
                                                                >
                                                                  <div
                                                                    className={
                                                                      classes.paper1
                                                                    }
                                                                  >
                                                                    {
                                                                      <img
                                                                        width="500px"
                                                                        height="500px"
                                                                        src={`${process.env.REACT_APP_SPACE_LINK}/${image}`}
                                                                      />
                                                                    }
                                                                  </div>
                                                                </Fade>
                                                              </Modal>
                                                            </>
                                                          );
                                                        }
                                                      )
                                                    ) : (
                                                      <>
                                                        <Typography
                                                          variant="body2"
                                                          style={{
                                                            color:
                                                              overdraft[key] ==
                                                              false
                                                                ? "red"
                                                                : overdraft[
                                                                    key
                                                                  ] == true
                                                                ? "#54B435"
                                                                : "#fff",
                                                          }}
                                                          align="left"
                                                        >
                                                          {`${overdraft[key]} `}
                                                          {key ===
                                                          "overdraft_value"
                                                            ? account.account_type ==
                                                                "Euro Transaction" ||
                                                              account.account_type ==
                                                                "Euro Rebate"
                                                              ? "€"
                                                              : "C"
                                                            : null}
                                                        </Typography>
                                                      </>
                                                    )}
                                                  </Grid>
                                                </>
                                              )}
                                            </Grid>
                                          );
                                        }
                                      )}
                                    </Grid>
                                  );
                                })
                              ) : (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  align="left"
                                >
                                  {`${0}`}
                                  {account.account_type == "Euro Transaction" ||
                                  account.account_type == "Euro Rebate"
                                    ? "€"
                                    : "C"}
                                </Typography>
                              )}
                            </>
                          </>
                        )}

                        {/* Euro Charge for OD: */}
                        {overdraftFeeOpen &&
                        (account.account_type == "Cog Transaction" ||
                          account.account_type == "Cog Investment" ||
                          account.account_type == "Euro Transaction") ? (
                          <>
                            <Grid item xs={12} sm={12} spacing={4}>
                              <Typography
                                variant="body1"
                                align="left"
                                style={{ color: "#359DB6" }}
                              >
                                Euro Charge for OD:
                              </Typography>
                            </Grid>
                            {admins[1] && admins[1].Cogito ? (
                              <>
                                <Grid item xs={12} sm={12}>
                                  {cogMod ? (
                                    <>
                                      {account.account_type ==
                                        "Cog Transaction" && (
                                        <CustomInput
                                          variant="outlined"
                                          label="overdraft_fee_value"
                                          name="overdraft_fee_value"
                                          error={
                                            errorMessages?.inputOverdraftError
                                              ?.length > 0
                                          }
                                          helperText={
                                            errorMessages?.inputOverdraftError
                                          }
                                          id={
                                            account.overdraft_details
                                              .overdraft_id
                                          }
                                          type="number"
                                          defaultValue={overdraftFeeValue}
                                          onChange={onOverdraftFeeValueChange}
                                        />
                                      )}
                                    </>
                                  ) : cogInvMod ? (
                                    <>
                                      {account.account_type ==
                                        "Cog Investment" && (
                                        <CustomInput
                                          variant="outlined"
                                          label="overdraft_fee_value"
                                          name="overdraft_fee_value"
                                          error={
                                            errorMessages?.inputOverdraftError
                                              ?.length > 0
                                          }
                                          helperText={
                                            errorMessages?.inputOverdraftError
                                          }
                                          id={
                                            account.overdraft_details
                                              .overdraft_id
                                          }
                                          type="number"
                                          defaultValue={overdraftFeeValue}
                                          onChange={onOverdraftFeeValueChange}
                                        />
                                      )}
                                    </>
                                  ) : euroMod ? (
                                    <>
                                      {account.account_type ==
                                        "Euro Transaction" && (
                                        <CustomInput
                                          variant="outlined"
                                          label="overdraft_fee_value"
                                          name="overdraft_fee_value"
                                          error={
                                            errorMessages?.inputOverdraftError
                                              ?.length > 0
                                          }
                                          helperText={
                                            errorMessages?.inputOverdraftError
                                          }
                                          id={
                                            account.overdraft_details
                                              .overdraft_id
                                          }
                                          type="number"
                                          defaultValue={overdraftFeeValue}
                                          onChange={onOverdraftFeeValueChange}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      align="left"
                                    >
                                      {overdraftFeeValue == null
                                        ? "-"
                                        : overdraftFeeValue}
                                      &nbsp;
                                      {account.account_type ==
                                        "Euro Transaction" ||
                                      account.account_type == "Euro Rebate"
                                        ? "€"
                                        : "C"}
                                    </Typography>
                                  )}
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid item xs={12} sm={12}>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    align="left"
                                  >
                                    {overdraftFeeValue == null
                                      ? "-"
                                      : overdraftFeeValue}{" "}
                                    &nbsp;
                                    {account.account_type ==
                                      "Euro Transaction" ||
                                    account.account_type == "Euro Rebate"
                                      ? "€"
                                      : "C"}
                                  </Typography>
                                </Grid>
                              </>
                            )}
                          </>
                        ) : null}
                      </>
                    ) : null}

                    {/* 4th row i.e fee name under each accounts */}
                    {account?.fee_details?.length != 0 ? (
                      <>
                        {account?.fee_details?.length > 1 &&
                        // for cog txn part - 4th row onwards [contains editable sections also]
                        account?.account_type == "Cog Transaction" ? (
                          <>
                            {/* COG Transaction Buyer Tax */}
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography variant="body1" align="left">
                                {account?.fee_details[0]?.fee_name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              spacing={4}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography
                                variant="body1"
                                align="left"
                                style={{ color: "#359DB6" }}
                              >
                                Max Tax:
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={8}
                              style={{ marginTop: "0.75rem" }}
                            >
                              {!cogMod ? (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  align="left"
                                >
                                  {account?.fee_details[0]?.custom_value !==
                                  null
                                    ? account?.fee_details[0]?.custom_value
                                    : account?.fee_details[0]?.default_value}
                                  %
                                </Typography>
                              ) : (
                                <Grid
                                  item
                                  xs={12}
                                  sm={8}
                                  spacing={4}
                                  style={{ marginTop: "0.75rem" }}
                                >
                                  <CustomInput
                                    variant="outlined"
                                    label="Fee in %"
                                    name={"fees1"}
                                    id={account?.fee_details[0]?.fee_type_id}
                                    type="number"
                                    error={errorMessages?.feeError1?.length > 0}
                                    helperText={errorMessages?.feeError1}
                                    defaultValue={
                                      account?.fee_details[0]?.custom_value !==
                                      null
                                        ? account?.fee_details[0]?.custom_value
                                        : account?.fee_details[0]?.default_value
                                    }
                                    onChange={onFeeValueChange1}
                                  />
                                  <CustomButton
                                    value={account?.fee_details[0]?.fee_type_id}
                                    onClick={(e) =>
                                      onFeeDefault(e, inputFee1, onCogBack)
                                    }
                                    disabled={loadings.updateuser}
                                  >
                                    Make Default
                                  </CustomButton>
                                </Grid>
                              )}
                            </Grid>

                            {/* COG Transaction Seller Tax */}
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography variant="body1" align="left">
                                {account?.fee_details[1]?.fee_name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              spacing={4}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography
                                variant="body1"
                                align="left"
                                style={{ color: "#359DB6" }}
                              >
                                Max Tax:
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={8}
                              style={{ marginTop: "0.75rem" }}
                            >
                              {!cogMod ? (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  align="left"
                                >
                                  {account?.fee_details[1]?.custom_value !==
                                  null
                                    ? account?.fee_details[1]?.custom_value
                                    : account?.fee_details[1]?.default_value}
                                  %
                                </Typography>
                              ) : (
                                <Grid
                                  item
                                  xs={12}
                                  sm={8}
                                  style={{ marginTop: "0.75rem" }}
                                >
                                  <CustomInput
                                    variant="outlined"
                                    label="Fee in %"
                                    name={"fees2"}
                                    id={account?.fee_details[1]?.fee_type_id}
                                    type="number"
                                    error={errorMessages?.feeError2?.length > 0}
                                    helperText={errorMessages?.feeError2}
                                    defaultValue={
                                      account?.fee_details[1]?.custom_value !==
                                      null
                                        ? account?.fee_details[1]?.custom_value
                                        : account?.fee_details[1]?.default_value
                                    }
                                    onChange={onFeeValueChange2}
                                  />
                                  <CustomButton
                                    value={account?.fee_details[1]?.fee_type_id}
                                    onClick={(e) =>
                                      onFeeDefault(e, inputFee2, onCogBack)
                                    }
                                    disabled={loadings.updateuser}
                                  >
                                    Make Default
                                  </CustomButton>
                                </Grid>
                              )}
                            </Grid>
                          </>
                        ) : account?.account_type == "Cog Investment" ? (
                          <>
                            {/* Stock Withdrawal Tax */}
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography variant="body1" align="left">
                                {account?.fee_details[0]?.fee_name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              spacing={4}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography
                                variant="body1"
                                align="left"
                                style={{ color: "#359DB6" }}
                              >
                                Max Tax:
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={8}
                              style={{ marginTop: "0.75rem" }}
                            >
                              {!cogInvMod ? (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  align="left"
                                >
                                  {account?.fee_details[0]?.custom_value !==
                                  null
                                    ? account?.fee_details[0]?.custom_value
                                    : account?.fee_details[0]?.default_value}
                                  %
                                </Typography>
                              ) : (
                                <Grid
                                  item
                                  xs={12}
                                  sm={8}
                                  spacing={4}
                                  style={{ marginTop: "0.75rem" }}
                                >
                                  <CustomInput
                                    variant="outlined"
                                    label="Fee in %"
                                    name={"fees11"}
                                    id={account?.fee_details[0]?.fee_type_id}
                                    type="number"
                                    error={
                                      errorMessages?.feeError11?.length > 0
                                    }
                                    helperText={errorMessages?.feeError11}
                                    defaultValue={
                                      account?.fee_details[0]?.custom_value !==
                                      null
                                        ? account?.fee_details[0]?.custom_value
                                        : account?.fee_details[0]?.default_value
                                    }
                                    onChange={onFeeValueChange11}
                                  />
                                  <CustomButton
                                    value={account?.fee_details[0]?.fee_type_id}
                                    onClick={(e) =>
                                      onFeeDefault(e, inputFee1, onCogInvBack)
                                    }
                                    disabled={loadings.updateuser}
                                  >
                                    Make Default
                                  </CustomButton>
                                </Grid>
                              )}
                            </Grid>
                          </>
                        ) : account?.fee_details?.length > 1 &&
                          // for CBC part - 4th row onwards [contains editable sections also]
                          account?.account_type == "Cash Backed Cog" ? (
                          <>
                            {/* group of fee name [0], max tax */}
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                style={{ marginTop: "0.75rem" }}
                              >
                                <Typography variant="body1" align="left">
                                  {account?.fee_details[0]?.fee_name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                spacing={4}
                                style={{ marginTop: "0.75rem" }}
                              >
                                <Typography
                                  variant="body1"
                                  align="left"
                                  style={{ color: "#359DB6" }}
                                >
                                  Max Tax:
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={8}
                                style={{ marginTop: "0.75rem" }}
                              >
                                {!cbcBankMod ? (
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    align="left"
                                  >
                                    {account?.fee_details[0]?.custom_value !==
                                    null
                                      ? account?.fee_details[0]?.custom_value
                                      : account?.fee_details[0]?.default_value}
                                    %
                                  </Typography>
                                ) : (
                                  <Grid item xs={12} sm={8} spacing={4}>
                                    <CustomInput
                                      variant="outlined"
                                      label="Fee in %"
                                      name={"fees3"}
                                      id={account?.fee_details[0]?.fee_type_id}
                                      type="number"
                                      error={
                                        errorMessages?.feeError3?.length > 0
                                      }
                                      helperText={errorMessages?.feeError3}
                                      defaultValue={
                                        account?.fee_details[0]
                                          ?.custom_value !== null
                                          ? account?.fee_details[0]
                                              ?.custom_value
                                          : account?.fee_details[0]
                                              ?.default_value
                                      }
                                      onChange={onFeeValueChange3}
                                    />
                                    <CustomButton
                                      value={
                                        account?.fee_details[0]?.fee_type_id
                                      }
                                      onClick={(e) =>
                                        onFeeDefault(
                                          e,
                                          inputFee1,
                                          onCBCBackButton
                                        )
                                      }
                                      disabled={loadings.updateuser}
                                    >
                                      Make Default
                                    </CustomButton>
                                  </Grid>
                                )}
                              </Grid>
                            </>

                            {/* group of fee name [1], max tax */}
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                style={{ marginTop: "0.75rem" }}
                              >
                                <Typography variant="body1" align="left">
                                  {account?.fee_details[1]?.fee_name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                style={{ marginTop: "0.75rem" }}
                              >
                                <Typography
                                  variant="body1"
                                  align="left"
                                  style={{ color: "#359DB6" }}
                                >
                                  Max Tax:
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={8}
                                style={{ marginTop: "0.75rem" }}
                              >
                                {!cbcBankMod ? (
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    align="left"
                                  >
                                    {account?.fee_details[1]?.custom_value !==
                                    null
                                      ? account?.fee_details[1]?.custom_value
                                      : account?.fee_details[1]?.default_value}
                                    %
                                  </Typography>
                                ) : (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={8}
                                    style={{ marginTop: "0.75rem" }}
                                  >
                                    <CustomInput
                                      variant="outlined"
                                      label="Fee in %"
                                      name={"fees4"}
                                      id={account?.fee_details[1]?.fee_type_id}
                                      type="number"
                                      error={
                                        errorMessages?.feeError4?.length > 0
                                      }
                                      helperText={errorMessages?.feeError4}
                                      defaultValue={
                                        account?.fee_details[1]
                                          ?.custom_value !== null
                                          ? account?.fee_details[1]
                                              ?.custom_value
                                          : account?.fee_details[1]
                                              ?.default_value
                                      }
                                      onChange={onFeeValueChange4}
                                    />
                                    <CustomButton
                                      value={
                                        account?.fee_details[1]?.fee_type_id
                                      }
                                      onClick={(e) =>
                                        onFeeDefault(
                                          e,
                                          inputFee2,
                                          onCBCBackButton
                                        )
                                      }
                                      disabled={loadings.updateuser}
                                    >
                                      Make Default
                                    </CustomButton>
                                  </Grid>
                                )}
                              </Grid>
                            </>

                            {/* group of fee name [2], max tax */}
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                style={{ marginTop: "0.75rem" }}
                              >
                                <Typography variant="body1" align="left">
                                  {account?.fee_details[2]?.fee_name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                style={{ marginTop: "0.75rem" }}
                              >
                                <Typography
                                  variant="body1"
                                  align="left"
                                  style={{ color: "#359DB6" }}
                                >
                                  Max Tax:
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={8}
                                style={{ marginTop: "0.75rem" }}
                              >
                                {!cbcBankMod ? (
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    align="left"
                                  >
                                    {account?.fee_details[2]?.custom_value !==
                                    null
                                      ? account?.fee_details[2]?.custom_value
                                      : account?.fee_details[2]?.default_value}
                                    %
                                  </Typography>
                                ) : (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={8}
                                    style={{ marginTop: "0.75rem" }}
                                  >
                                    <CustomInput
                                      variant="outlined"
                                      label="Fee in %"
                                      name={"fees5"}
                                      id={account?.fee_details[2]?.fee_type_id}
                                      type="number"
                                      error={
                                        errorMessages?.feeError5?.length > 0
                                      }
                                      helperText={errorMessages?.feeError5}
                                      defaultValue={
                                        account?.fee_details[2]
                                          ?.custom_value !== null
                                          ? account?.fee_details[2]
                                              ?.custom_value
                                          : account?.fee_details[2]
                                              ?.default_value
                                      }
                                      onChange={onFeeValueChange5}
                                    />
                                    <CustomButton
                                      value={
                                        account?.fee_details[2]?.fee_type_id
                                      }
                                      onClick={(e) =>
                                        onFeeDefault(
                                          e,
                                          inputFee2,
                                          onCBCBackButton
                                        )
                                      }
                                      disabled={loadings.updateuser}
                                    >
                                      Make Default
                                    </CustomButton>
                                  </Grid>
                                )}
                              </Grid>
                            </>
                          </>
                        ) : account?.fee_details?.length > 1 &&
                          // for Euro Txn part - 4th row onwards [contains editable sections also]
                          account?.account_type == "Euro Transaction" ? (
                          <>
                            {/* first */}
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography variant="body1" align="left">
                                {account?.fee_details[0]?.fee_name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography
                                variant="body1"
                                align="left"
                                style={{ color: "#359DB6" }}
                              >
                                Max Tax:
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={8}
                              style={{ marginTop: "0.75rem" }}
                            >
                              {!euroMod ? (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  align="left"
                                >
                                  {account?.fee_details[0]?.custom_value !==
                                  null
                                    ? account?.fee_details[0]?.custom_value
                                    : account?.fee_details[0]?.default_value}
                                  %
                                </Typography>
                              ) : (
                                <Grid
                                  item
                                  xs={8}
                                  sm={8}
                                  style={{ marginTop: "0.75rem" }}
                                >
                                  <CustomInput
                                    variant="outlined"
                                    label="Fee in %"
                                    name={"fees6"}
                                    id={account?.fee_details[0]?.fee_type_id}
                                    type="number"
                                    error={errorMessages?.feeError6?.length > 0}
                                    helperText={errorMessages?.feeError6}
                                    defaultValue={
                                      account?.fee_details[0]?.custom_value !==
                                      null
                                        ? account?.fee_details[0]?.custom_value
                                        : account?.fee_details[0]?.default_value
                                    }
                                    onChange={onFeeValueChange6}
                                  />
                                  <CustomButton
                                    value={account?.fee_details[0]?.fee_type_id}
                                    onClick={(e) =>
                                      onFeeDefault(e, inputFee1, onEuroBack)
                                    }
                                    disabled={loadings.updateuser}
                                  >
                                    Make Default
                                  </CustomButton>
                                </Grid>
                              )}
                            </Grid>

                            {/* second */}
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography variant="body1" align="left">
                                {account?.fee_details[1]?.fee_name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography
                                variant="body1"
                                align="left"
                                style={{ color: "#359DB6" }}
                              >
                                Max Tax:
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={8}
                              style={{ marginTop: "0.75rem" }}
                            >
                              {!euroMod ? (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  align="left"
                                >
                                  {account?.fee_details[1]?.custom_value !==
                                  null
                                    ? account?.fee_details[1]?.custom_value
                                    : account?.fee_details[1]?.default_value}
                                  %
                                </Typography>
                              ) : (
                                <Grid
                                  item
                                  xs={8}
                                  sm={8}
                                  style={{ marginTop: "0.75rem" }}
                                >
                                  <CustomInput
                                    variant="outlined"
                                    label="Fee in %"
                                    name={"fees7"}
                                    id={account?.fee_details[1]?.fee_type_id}
                                    type="number"
                                    error={errorMessages?.feeError7?.length > 0}
                                    helperText={errorMessages?.feeError7}
                                    defaultValue={
                                      account?.fee_details[1]?.custom_value !==
                                      null
                                        ? account?.fee_details[1]?.custom_value
                                        : account?.fee_details[1]?.default_value
                                    }
                                    onChange={onFeeValueChange7}
                                  />
                                  <CustomButton
                                    value={account?.fee_details[1]?.fee_type_id}
                                    onClick={(e) =>
                                      onFeeDefault(e, inputFee1, onEuroBack)
                                    }
                                    disabled={loadings.updateuser}
                                  >
                                    Make Default
                                  </CustomButton>
                                </Grid>
                              )}
                            </Grid>

                            {/* third */}
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography variant="body1" align="left">
                                {account?.fee_details[2]?.fee_name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography
                                variant="body1"
                                align="left"
                                style={{ color: "#359DB6" }}
                              >
                                Max Tax:
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={8}
                              style={{ marginTop: "0.75rem" }}
                            >
                              {!euroMod ? (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  align="left"
                                >
                                  {account?.fee_details[2]?.custom_value !==
                                  null
                                    ? account?.fee_details[2]?.custom_value
                                    : account?.fee_details[2]?.default_value}
                                  %
                                </Typography>
                              ) : (
                                <Grid
                                  item
                                  xs={8}
                                  sm={8}
                                  style={{ marginTop: "0.75rem" }}
                                >
                                  <CustomInput
                                    variant="outlined"
                                    label="Fee in %"
                                    name={"fees8"}
                                    id={account?.fee_details[2]?.fee_type_id}
                                    type="number"
                                    error={errorMessages?.feeError8?.length > 0}
                                    helperText={errorMessages?.feeError8}
                                    defaultValue={
                                      account?.fee_details[2]?.custom_value !==
                                      null
                                        ? account?.fee_details[2]?.custom_value
                                        : account?.fee_details[2]?.default_value
                                    }
                                    onChange={onFeeValueChange8}
                                  />
                                  <CustomButton
                                    value={account?.fee_details[2]?.fee_type_id}
                                    onClick={(e) =>
                                      onFeeDefault(e, inputFee1, onEuroBack)
                                    }
                                    disabled={loadings.updateuser}
                                  >
                                    Make Default
                                  </CustomButton>
                                </Grid>
                              )}
                            </Grid>

                            {/* fourth */}
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography variant="body1" align="left">
                                {account?.fee_details[3]?.fee_name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography
                                variant="body1"
                                align="left"
                                style={{ color: "#359DB6" }}
                              >
                                Max Tax:
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={8}
                              style={{ marginTop: "0.75rem" }}
                            >
                              {!euroMod ? (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  align="left"
                                >
                                  {account?.fee_details[3]?.custom_value !==
                                  null
                                    ? account?.fee_details[3]?.custom_value
                                    : account?.fee_details[3]?.default_value}
                                  %
                                </Typography>
                              ) : (
                                <Grid
                                  item
                                  xs={8}
                                  sm={8}
                                  style={{ marginTop: "0.75rem" }}
                                >
                                  <CustomInput
                                    variant="outlined"
                                    label="Fee in %"
                                    name={"fees9"}
                                    id={account?.fee_details[3]?.fee_type_id}
                                    type="number"
                                    error={errorMessages?.feeError9?.length > 0}
                                    helperText={errorMessages?.feeError9}
                                    defaultValue={
                                      account?.fee_details[3]?.custom_value !==
                                      null
                                        ? account?.fee_details[3]?.custom_value
                                        : account?.fee_details[3]?.default_value
                                    }
                                    onChange={onFeeValueChange9}
                                  />
                                  <CustomButton
                                    value={account?.fee_details[3]?.fee_type_id}
                                    onClick={(e) =>
                                      onFeeDefault(e, inputFee1, onEuroBack)
                                    }
                                    disabled={loadings.updateuser}
                                  >
                                    Make Default
                                  </CustomButton>
                                </Grid>
                              )}
                            </Grid>

                            {/* fifth */}
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography variant="body1" align="left">
                                {account?.fee_details[4]?.fee_name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography
                                variant="body1"
                                align="left"
                                style={{ color: "#359DB6" }}
                              >
                                Max Tax:
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={8}
                              style={{ marginTop: "0.75rem" }}
                            >
                              {!euroMod ? (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  align="left"
                                >
                                  {account?.fee_details[4]?.custom_value !==
                                  null
                                    ? account?.fee_details[4]?.custom_value
                                    : account?.fee_details[4]?.default_value}
                                  %
                                </Typography>
                              ) : (
                                <Grid
                                  item
                                  xs={8}
                                  sm={8}
                                  style={{ marginTop: "0.75rem" }}
                                >
                                  <CustomInput
                                    variant="outlined"
                                    label="Fee in %"
                                    name={"fees10"}
                                    id={account?.fee_details[4]?.fee_type_id}
                                    type="number"
                                    error={
                                      errorMessages?.feeError10?.length > 0
                                    }
                                    helperText={errorMessages?.feeError10}
                                    defaultValue={
                                      account?.fee_details[4]?.custom_value !==
                                      null
                                        ? account?.fee_details[4]?.custom_value
                                        : account?.fee_details[4]?.default_value
                                    }
                                    onChange={onFeeValueChange10}
                                  />
                                  <CustomButton
                                    value={account?.fee_details[4]?.fee_type_id}
                                    onClick={(e) =>
                                      onFeeDefault(e, inputFee1, onEuroBack)
                                    }
                                    disabled={loadings.updateuser}
                                  >
                                    Make Default
                                  </CustomButton>
                                </Grid>
                              )}
                            </Grid>

                            {/* sixth  i.e fee id 12 */}
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography variant="body1" align="left">
                                {account?.fee_details[5]?.fee_name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography
                                variant="body1"
                                align="left"
                                style={{ color: "#359DB6" }}
                              >
                                Max Tax:
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={8}
                              style={{ marginTop: "0.75rem" }}
                            >
                              {!euroMod ? (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  align="left"
                                >
                                  {account?.fee_details[5]?.custom_value !==
                                  null
                                    ? account?.fee_details[5]?.custom_value
                                    : account?.fee_details[5]?.default_value}
                                  %
                                </Typography>
                              ) : (
                                <Grid
                                  item
                                  xs={8}
                                  sm={8}
                                  style={{ marginTop: "0.75rem" }}
                                >
                                  <CustomInput
                                    variant="outlined"
                                    label="Fee in %"
                                    name={"fees12"}
                                    id={account?.fee_details[5]?.fee_type_id}
                                    type="number"
                                    error={
                                      errorMessages?.feeError12?.length > 0
                                    }
                                    helperText={errorMessages?.feeError12}
                                    defaultValue={
                                      account?.fee_details[5]?.custom_value !==
                                      null
                                        ? account?.fee_details[5]?.custom_value
                                        : account?.fee_details[5]?.default_value
                                    }
                                    onChange={onFeeValueChange12}
                                  />
                                  <CustomButton
                                    value={account?.fee_details[5]?.fee_type_id}
                                    onClick={(e) =>
                                      onFeeDefault(e, inputFee1, onEuroBack)
                                    }
                                    disabled={loadings.updateuser}
                                  >
                                    Make Default
                                  </CustomButton>
                                </Grid>
                              )}
                            </Grid>

                            {/* seventh  i.e fee id 13 */}
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography variant="body1" align="left">
                                {account?.fee_details[6]?.fee_name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              style={{ marginTop: "0.75rem" }}
                            >
                              <Typography
                                variant="body1"
                                align="left"
                                style={{ color: "#359DB6" }}
                              >
                                Max Tax:
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={8}
                              style={{ marginTop: "0.75rem" }}
                            >
                              {!euroMod ? (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  align="left"
                                >
                                  {account?.fee_details[6]?.custom_value !==
                                  null
                                    ? account?.fee_details[6]?.custom_value
                                    : account?.fee_details[6]?.default_value}
                                  %
                                </Typography>
                              ) : (
                                <Grid
                                  item
                                  xs={8}
                                  sm={8}
                                  style={{ marginTop: "0.75rem" }}
                                >
                                  <CustomInput
                                    variant="outlined"
                                    label="Fee in %"
                                    name={"fees13"}
                                    id={account?.fee_details[6]?.fee_type_id}
                                    type="number"
                                    error={
                                      errorMessages?.feeError13?.length > 0
                                    }
                                    helperText={errorMessages?.feeError13}
                                    defaultValue={
                                      account?.fee_details[6]?.custom_value !==
                                      null
                                        ? account?.fee_details[6]?.custom_value
                                        : account?.fee_details[6]?.default_value
                                    }
                                    onChange={onFeeValueChange13}
                                  />
                                  <CustomButton
                                    value={account?.fee_details[6]?.fee_type_id}
                                    onClick={(e) =>
                                      onFeeDefault(e, inputFee1, onEuroBack)
                                    }
                                    disabled={loadings.updateuser}
                                  >
                                    Make Default
                                  </CustomButton>
                                </Grid>
                              )}
                            </Grid>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </Grid>
        {/* </Paper> */}
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    admin: adminReducer.admin,
    sponsorList: adminReducer.sponsorList,
    companies: adminReducer.companies,
    user: adminReducer.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
    getAdmin: () => dispatch(actionCreator.getAdmin()),
    getUser: (data) => dispatch(actionCreator.getUser(data)),
    updateUser: (data) => dispatch(actionCreator.updateUser(data)),
    updateCompany: (data) => dispatch(actionCreator.updateCompany(data)),
    updateUserDetails: (data) =>
      dispatch(actionCreator.updateUserDetails(data)),
    updatePassword: (data) => dispatch(actionCreator.updatePassword(data)),
    updateCustomFee: (data) => dispatch(actionCreator.updateCustomFee(data)),
    updateOverdraftValue: (data) =>
      dispatch(actionCreator.updateOverdraftValue(data)),
    addCbc: (data) => dispatch(actionCreator.addCbc(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Banking);
