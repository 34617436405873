import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
 root: {
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  [theme.breakpoints.up("sm")]: {
   paddingLeft: theme.spacing(8) + 1,
  },
 },

 flex: {
  display: "flex",
  alignItems: "center",
 },
 main: {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
 },

 justifyBetween: {
  justifyContent: "space-between",
 },

 modal: {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
 },

 listroot: {
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  [theme.breakpoints.up("sm")]: {
   paddingLeft: theme.spacing(8) + 1,
  },
  "& .MuiTable-root": {
   "& .MuiTableRow-root": {
    "&:nth-child(odd)": {
     background: theme.palette.background.paper,
    },
    "&:nth-child(even)": {
     background: theme.palette.background.default,
    },
   },
  },
 },

 listroot1: {
  marginTop: theme.spacing(1),
  padding: theme.spacing(8),
  [theme.breakpoints.up("sm")]: {
   paddingLeft: theme.spacing(8) + 1,
  },
  "&.MuiToolbar-root": {
   //color: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
   color: theme.palette.background.paper,
  },
  "& .MuiTable-root": {
   "& .MuiTableRow-root": {
    "&:nth-child(odd)": {
     background: theme.palette.background.default,
    },
    "&:nth-child(even)": {
     background: theme.palette.background.paper,
    },
   },
  },
 },

 paper: {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(3),
   borderRadius: 10,
   backgroundColor: theme.palette.background.default,
 },

 paper1: {
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
 },

 paper2: {
  display: "flex",
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
 },
 businessPaper: {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(3),
  borderRadius: 10,
 },
 resultContainer: {
  cursor: "pointer",
  // borderRadius: 10,
  width: "40%",
  // borderLeft: "4px solid #359DB6",
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(1),
  padding: "5rem",
  display: "flex",
  // justifyContent: "space-around",
  alignItems: "space-between",
  flexDirection: "row",
  marginTop: "25px",
  boxShadow: localStorage.getItem("dark")
   ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
   : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
 },

 hoverChild: {
  "&:hover": {
    "& $hoverDisplay":{display:"flex"},
   },
 },
 hoverDisplay: {display:"none"},
 annContainner: {
  cursor: "pointer",
  width: "100%",
  height: "38vh",
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  display: "flex",
  // justifyContent: "space-around",
  // alignItems: "space-between",
  flexDirection: "row",
  marginTop: "25px",
  marginBottom: "25px",
  boxShadow: localStorage.getItem("dark")
   ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
   : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
 },
 actionContainer: {
  cursor: "pointer",
  display: "flex",
  paddingTop: "1rem",
  // marginBottom: "1.5rem",
  justifyContent: "flex-end",

  // boxShadow: localStorage.getItem("dark")
  // 	? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
  // 	: "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
 },
 
 menuPaper: {
  borderRadius: 5,
  padding: theme.spacing(1),
  //minWidth:120
 },
 menuIcon: {
  color: "rgba(255, 255, 255, 0.6)",
  fontSize: 60,
  [theme.breakpoints.down("xs")]: {
   fontSize: 22,
  },
 },
 rowView: {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  [theme.breakpoints.down(1000)]: {
    justifyContent:"left"
   },
 },

 rowMenuView: {
  display: "flex",
  flexDirection: "row",
  paddingTop: "0.9rem",
 },
 menuText: {
  // marginTop: '1.5rem',
  fontWeight: "400",
  fontSize: "1.2rem",
  textAlign: "center",
  paddingLeft: "0.7rem",
  cursor: "pointer",
 },
 columnView: {
  display: "flex",
  flexDirection: "column",
  justifyItems: "center",
  alignItems: "center",
 },
 columnViewLeft: {
  display: "flex",
  flexDirection: "column",
 },
 linkBlock: {
  textDecoration: "none",
  color: "rgba(255, 255, 255, 0.6)",
 },
 yellowText: {
  color: "#FF7300",
 },
 smallView: {
  [theme.breakpoints.up("sm")]: {
   display: "none",
  },
 },
 blueTitle: {
  padding: "0.5rem",
 },
 iconButton: {
  border: "none",
  borderRadius: 14,
  marginBottom: 10,
  display: "flex",
  justifyContent: "start",
  padding: theme.spacing(0.8),
  alignItems: "center",
  background: "transparent",
  // color: "#2C94AC",
  boxShadow: "none",
  "&:hover": {
   background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
   boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
  },
 },
 iconSelected: {
  border: "none",
  borderRadius: 14,
  marginBottom: 10,
  padding: theme.spacing(1),
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  color: "rgba(255, 255, 255, 0.6)",
  background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
  boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
 },
 sidePlane: {
  position: "sticky",
  top: "8vh",
  justifyContent: "start",
  height: "75%",
  borderRadius: 8,
  padding: theme.spacing(5),
  background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
  // background: theme.palette.background.paper,
  boxShadow: localStorage.getItem("dark")
   ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
   : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
   [theme.breakpoints.down(1000)]: {
    flexDirection:"column",
    position:"relative",
    top:"0vh",
    justifyContent:"left"
   }
  },

 main: {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
 },
 businessPaper: {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(3),
  borderRadius: 10,
 },
 first_row: {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "-1rem",
 },
 second_row: {
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
 },
 third_row: {
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
 },
 midrow: {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "1rem",
  marginBottom: "1rem",
  [theme.breakpoints.down(1135)]: {
   flexDirection: "column",
  },
 },
 midrow1: {
  width: "20rem",
  [theme.breakpoints.down(1135)]: {
   width: "100%",
   marginBottom: "1rem",
  },
 },
 midrow2: {
  width: "20rem",
  marginLeft: "2rem",
  [theme.breakpoints.down(1135)]: {
   width: "100%",
   marginLeft:"0"
  },
 },
 prev: {
  border: "2px solid #359DB6",
  borderRadius: "20px",
  width: "6rem",
  height: "2.5rem",
 },
 save: {
  backgroundColor: "#359DB6",
  borderRadius: "20px",
  width: "6rem",
  height: "2.5rem",
  color: "#FFFFFF",
  marginLeft: "1rem",
  "&:hover": {
   color: "#1a1a1a",
  },
  [theme.breakpoints.down(790)]: {
   marginLeft: "-0.1rem",
   marginTop: "1rem",
  },
 },
 btn_box: {
  [theme.breakpoints.down(790)]: {
   display: "flex",
   flexDirection: "column",
   alignItems: "center",
  },
 },
 empty_image: {
  // backgroundImage : no_pics,
  position: "relative",
  height: "124px",
  width: "124px",
  borderRadius: "8px",
  cursor: "pointer",
 },
 image_filled: {
  position: "relative",
  height: "126px",
  width: "126px",
  border: "10px solid #359DB6",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
 },
 image_size: {
  height: "120px",
  width: "120px",
 },
 noLabel: {
  marginTop: theme.spacing(3),
 },
 intfld: {
  position: "absolute",
  top: "12.8rem",
  left: "1rem",
 },
 intfld1: {
  display: "none",
 },
 sel: {
  marginBottom: "0.5rem",
 },

 chip: {
  "& .MuiChip-root": {
   backgroundColor: "#363636 ",
   borderRadius: "3px",
  },
  "& .MuiSvgIcon-root": {
   // fill: "#363636",
   fill: "#ffffff",
  },
 },
 remove_member: {
  border: "2px solid #ff7300",
  borderRadius: "20px",
  width: "100%",
  height: "2.5rem",
  padding: "10px 5px",
 },

 staff: {
  border: "2px solid #359DB6",
  borderRadius: "20px",
  width: "8rem",
  height: "2.5rem",
  [theme.breakpoints.down(790)]: {
   marginTop: "2rem",
  },
 },
 invite: {
  backgroundColor: "#359DB6",
  borderRadius: "20px",
  width: "6rem",
  height: "2.5rem",
  color: "#FFFFFF",
  marginLeft: "1rem",
  "&:hover": {
   color: "#1a1a1a",
  },
  [theme.breakpoints.down(790)]: {
   marginLeft: "-0.1rem",
   marginTop: "1rem",
  },
 },
 btn_box: {
  [theme.breakpoints.down(790)]: {
   display: "flex",
   flexDirection: "column",
   alignItems: "center",
  },
 },
 content: {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  height: "fit-content",
  marginBottom: "3.5rem",
  [theme.breakpoints.down(530)]: {
   display: "flex",
   flexDirection: "column",
   alignItems: "center",
   width: "100%",
   height: "fit-content",
   marginBottom: "1.5rem",
  },
 },
 content1: {
  display: "flex",
  flexDirection: "column",
  width: "60%",
  [theme.breakpoints.down(1000)]: {
   width: "40%",
   wordWrap: "break-word",
  },
 },
 content2: {
  display: "flex",
  flexDirection: "column",
  width: "40%",
  alignItems: "center",
  [theme.breakpoints.down(1000)]: {
   width: "60%",
  },
 },
 new1: {
  border:
   localStorage.getItem("dark") == null ? "1px solid white" : "1px solid black",
  width: "100%",
 },
 BusinessSidePlane: {
  position: "sticky",
  top: "14vh",
  justifyContent: "start",
  height: "75%",
  borderRadius: 8,
  padding: theme.spacing(2),
  background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
  // background: theme.palette.background.paper,
  boxShadow: localStorage.getItem("dark")
   ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
   : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
 
   [theme.breakpoints.down(1000)]: {
    position:"relative",
    top:"0vh"

   },
  },

 justifyBetween: {
  justifyContent: "space-between",
 },

 modal: {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
 },

 listroot: {
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  [theme.breakpoints.up("sm")]: {
   paddingLeft: theme.spacing(8) + 1,
  },
  "& .MuiTable-root": {
   "& .MuiTableRow-root": {
    "&:nth-child(odd)": {
     background: theme.palette.background.paper,
    },
    "&:nth-child(even)": {
     background: theme.palette.background.default,
    },
   },
  },
 },

 listroot1: {
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  [theme.breakpoints.up("sm")]: {
   paddingLeft: theme.spacing(8) + 1,
  },
  "&.MuiToolbar-root": {
   //color: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
   color: theme.palette.background.paper,
  },
  "& .MuiTable-root": {
   "& .MuiTableRow-root": {
    "&:nth-child(odd)": {
     background: theme.palette.background.default,
    },
    "&:nth-child(even)": {
     background: theme.palette.background.paper,
    },
   },
  },
 },

 paper: {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(3),
  borderRadius: 10,
 },

 paper1: {
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
 },

 paper2: {
  display: "flex",
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
 },

 resultContainer: {
  cursor: "pointer",
  // borderRadius: 10,
  width: "40%",
  // borderLeft: "4px solid #359DB6",
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  display: "flex",
  // justifyContent: "space-around",
  alignItems: "space-between",
  flexDirection: "row",
  marginTop: "25px",
  boxShadow: localStorage.getItem("dark")
   ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
   : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
 },

 annContainner: {
  cursor: "pointer",
  width: "100%",
  height: "38vh",
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  display: "flex",
  // justifyContent: "space-around",
  // alignItems: "space-between",
  flexDirection: "row",
  marginTop: "25px",
  marginBottom: "25px",
  boxShadow: localStorage.getItem("dark")
   ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
   : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
 },
 actionContainer: {
  cursor: "pointer",
  display: "flex",
  paddingTop: "1rem",
  // marginBottom: "1.5rem",
  justifyContent: "flex-end",

  // boxShadow: localStorage.getItem("dark")
  // 	? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
  // 	: "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
 },
 menuPaper: {
  borderRadius: 5,
  padding: theme.spacing(1),
  //minWidth:120
 },
 menuIcon: {
  color: "rgba(255, 255, 255, 0.6)",
  fontSize: 60,
  [theme.breakpoints.down("xs")]: {
   fontSize: 22,
  },
 },
 rowView: {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
 },

 rowMenuView: {
  display: "flex",
  flexDirection: "row",
  paddingTop: "0.9rem",
 },
 menuText: {
  // marginTop: '1.5rem',
  fontWeight: "400",
  fontSize: "1.2rem",
  textAlign: "center",
  paddingLeft: "0.7rem",
  cursor: "pointer",
 },
 columnView: {
  display: "flex",
  flexDirection: "column",
  justifyItems: "center",
  alignItems: "center",
 },
 columnViewLeft: {
  display: "flex",
  flexDirection: "column",
 },
 linkBlock: {
  textDecoration: "none",
  color: "rgba(255, 255, 255, 0.6)",
 },
 yellowText: {
  color: "#FF7300",
 },
 smallView: {
  [theme.breakpoints.up("sm")]: {
   display: "none",
  },
 },
 blueTitle: {
  padding: "0.5rem",
 },
 iconButton: {
  border: "none",
  borderRadius: 14,
  marginBottom: 10,
  display: "flex",
  justifyContent: "start",
  padding: theme.spacing(0.8),
  alignItems: "center",
  background: "transparent",
  // color: "#2C94AC",
  boxShadow: "none",
  "&:hover": {
   background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
   boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
  },
 },
 iconSelected: {
  border: "none",
  borderRadius: 14,
  marginBottom: 10,
  padding: theme.spacing(1),
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  color: "rgba(255, 255, 255, 0.6)",
  background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
  boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
 },
 sidePlane: {
  position: "sticky",
  top: "8vh",
  justifyContent: "start",
  height: "75%",
  borderRadius: 8,
  padding: theme.spacing(5),
  background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
  // background: theme.palette.background.paper,
  boxShadow: localStorage.getItem("dark")
   ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
   : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
   [theme.breakpoints.down(1000)]: {
    position:"relative",
    top:"0vh",
   }
  },
 openmodal: {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  // height: "80vh",
  backgroundColor: "#303030",
  boxShadow: 24,
  display: "flex",
  justifyContent: "center",
  padding: 10,
 },
 openbutton: {
  border: "2px solid #359DB6",
  borderRadius: "10px",
  width: "6rem",
  height: "2rem",
  marginRight: "20px",

  backgroundColor: theme.palette.background.default,
 }
}));
