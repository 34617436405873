import React, { forwardRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actionCreator from "../../store/action/index";
import Spinner from "../../components/layout/Spinner/Spinner";
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8) + 1,
    },
    borderRadius: "15px",
    "&.MuiToolbar-root": {
      color: theme.palette.background.default,
    },
    "& .MuiTable-root": {
      "& .MuiTableRow-root": {
        "&:nth-child(even)": {
          background: theme.palette.background.paper,
        },
        "&:nth-child(odd)": {
          background: theme.palette.background.default,
        },
      },
    },
  },
  tableContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4),
  },
  tableBox: {
    width: "100%", // Increase the width of the table
  },
  customTableButton: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "2px 0px",
  },
  inputStyle: {
    borderRadius: 8,
    border: 0,
    fontFamily: "Manrope",
  },
  modelContainer: {
    padding: 10,
  },
  modelForm: {
    marginTop: "1.2rem",
  },
  modelSubmit: {
    marginTop: "1.2rem",
    textAlign: "center",
  },
  formContainer: {
    marginTop: 8,
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const StockCategory = (props) => {
  const {
    loadings,
    getStockCategories,
    createStockCategory,
    updateStockCategory,
    deleteStockCategory,
    stockCategories,
  } = props;

  const classes = useStyles();
  const [modelData, setModelData] = useState({});
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getStockCategories();
  }, [getStockCategories]);

  const handleOpenModel = () => {
    setOpenModal(true);
  };

  const handleCloseModel = () => {
    setOpenModal(false);
    setModelData({});
  };

  const onChangeFormText = (e, field) => {
    setModelData((prevData) => ({
      ...prevData,
      [field]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (modelData.id) {
      updateStockCategory(modelData);
    } else {
      createStockCategory(modelData);
    }
    handleCloseModel();
  };

  const columns = [
    { title: "ID", field: "id", editable: false },
    { title: "Name", field: "name" },
    { title: "Description", field: "description" },
  ];

  return (
    <Box className={classes.root}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          style={{ color: "#359DB6", fontWeight: "bold" }}
          className={"system-nav"}
        >
          Stock Category
        </Typography>
        <Button
          style={{ backgroundColor: "rgb(53, 157, 182)", color: "white" }}
          onClick={handleOpenModel}
        >
          Create Stock Category
        </Button>
      </Box>
      <Box className={classes.tableContainer}>
        <Box className={classes.tableBox}>
          <MaterialTable
            icons={tableIcons}
            title="Stock Category"
            options={{
              filtering: false,
              toolbar: true,
              sorting: false,
              pageSize: 5,
              pageSizeOptions: [5, 10, 15],
              emptyRowsWhenPaging: false,
              maxBodyHeight: 1450,
              padding: "default",
              search: false,
            }}
            editable={{
              onRowUpdate: (newData) =>
                new Promise((resolve) => {
                  updateStockCategory(newData);
                  resolve();
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  console.log("Deleting ID:", oldData.id); // Ensure the ID is logged correctly
                  deleteStockCategory(oldData.id) // Pass the id directly
                    .then(() => {
                      resolve();
                    })
                    .catch((error) => {
                      reject(error);
                    });
                }),
            }}
            columns={columns}
            data={stockCategories}
          />
        </Box>
      </Box>
      {openModal && (
        <Box className={classes.modelContainer}>
          <form onSubmit={onSubmit}>
            <Box className={classes.modelForm}>
              <Grid container spacing={1}>
                <Grid item md={6} sm={12} className={classes.formContainer}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    value={modelData.name || ""}
                    required
                    onChange={(e) => onChangeFormText(e, "name")}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item md={6} sm={12} className={classes.formContainer}>
                  <TextField
                    label="Description"
                    variant="outlined"
                    value={modelData.description || ""}
                    onChange={(e) => onChangeFormText(e, "description")}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.modelSubmit}>
              {loadings?.uploadDocs ? (
                <Spinner />
              ) : (
                <Button
                  style={{
                    backgroundColor: "rgb(53, 157, 182)",
                    color: "white",
                  }}
                  type="submit"
                >
                  {modelData.id ? "Update" : "Create"}
                </Button>
              )}
            </Box>
          </form>
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = ({ adminReducer }) => ({
  loadings: adminReducer.loadings,
  stockCategories: adminReducer.stockCategories,
});

const mapDispatchToProps = (dispatch) => ({
  getStockCategories: () => dispatch(actionCreator.getStockCategories()),
  createStockCategory: (data) =>
    dispatch(actionCreator.createStocksCategory(data)),
  updateStockCategory: (data) =>
    dispatch(actionCreator.updateStocksCategory(data)),
  deleteStockCategory: (id) => dispatch(actionCreator.deleteStocksCategory(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockCategory);
