function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const currencyFormatter = (price) => {
  if (Number(price)) {
    let val = Number(price).toFixed(3).toLocaleString(
      "en-US"
      // {
      //     style: 'currency',
      //     currency: 'USD',
      //   }
    );
    return numberWithCommas(val);
  } else return 0;
};

export const stocksCountFormater = (quantity) => {
  if (Number(quantity)) {
    return Number(quantity).toFixed(3);
  } else {
    return 0;
  }
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return ""; // Return an empty string if the input is falsy
  return string.charAt(0).toUpperCase() + string.slice(1);
};
