import React, { useState, useEffect, useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import TemplateMakerPage from "./addContentPage";
import SignaturePad from "./signature";
import "./tool.css";
import "./ToolBox.css";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Typography,
  Grid,
  Tabs,
  Tab,
} from "@material-ui/core";
import HtmlRenderer from "./htmlRenderer";
import * as actionCreator from "../../store/action/index";
import { connect } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  editorContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    fontFamily: "Arial, sans-serif",
    width: "100%",
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "10px",
    boxSizing: "border-box",
  },
  toolbarContainer: {
    width: "50%",
    backgroundColor: "#2c3e50",
    color: "white",
    // padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
  },
  toolbarOptions: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  toolbarButton: {
    color: "white",
    transition: "color 0.3s ease",
    "&:hover": {
      color: "#1abc9c",
    },
  },
  toolbarButtonActive: {
    color: "#1abc9c",
  },
  editorArea: {
    flexGrow: 1,
    padding: "20px",
    backgroundColor: "#f5f5f5",
    overflowY: "auto",
    position: "relative",
    width: "100%",
    maxWidth: "960px",
    boxSizing: "border-box",
    margin: "0 auto",
    minHeight: "100vh",
  },
  selectedElement: {
    border: "2px solid #00bcd4",
    borderRadius: "4px",
    padding: "5px",
    boxSizing: "border-box",
  },
  previewArea: {
    flexGrow: 1,
    padding: "0",
    backgroundColor: "#ecf0f1",
    overflow: "hidden",
    width: "100%",
    maxWidth: "960px",
    boxSizing: "border-box",
    margin: "0 auto",
    minHeight: "120vh",
  },
  iframePreview: {
    width: "100%",
    height: "100%",
    border: "none",
    backgroundColor: "white",
    display: "block",
    minHeight: "150vh",
  },
  exportButtonContainer: {
    textAlign: "center",
    marginTop: "20px",
  },
  exportButton: {
    backgroundColor: "#1abc9c",
    color: "white",
    "&:hover": {
      backgroundColor: "#16a085",
    },
  },
  saveDraftButtonContainer: {
    display: "flex",
    justifyContent: "center", // Center the button horizontally
    alignItems: "center", // Center the button vertically
    marginTop: theme.spacing(2), // Use theme.spacing for consistent spacing
  },
  saveButton: {
    backgroundColor: "#007bff", // Primary blue color
    color: "white", // White text color
    padding: theme.spacing(1.25, 2.5), // Padding: 10px 20px
    fontSize: "16px", // Font size
    textTransform: "none", // Keep the text case as is
    borderRadius: "8px", // Rounded corners
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow
    transition: "background-color 0.3s ease, box-shadow 0.3s ease", // Smooth transition
    "&:hover": {
      backgroundColor: "#0056b3", // Darker blue on hover
      boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)", // Larger shadow on hover
    },
    "&:active": {
      backgroundColor: "#003f7f", // Even darker blue when clicked
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)", // Smaller shadow on click
    },
  },
  colorPicker: {
    padding: "5px",
    borderRadius: "4px",
    backgroundColor: "white",
    border: "1px solid #ccc",
  },
}));

function ProspectusTool({
  saveProspectusData,
  updateProspectusData,
  getProspectusData,
  deleteProspectusData,
  prospectusDrafts,
}) {
  const classes = useStyles();
  const [isExported, setIsExported] = useState(false);
  const [color, setColor] = useState("#4F5A6E"); // Default color
  const [prospectusId, setProspectusId] = useState(null);
  const [offeringDocHtml, setOfferingDocHtml] = useState(null);
  const [activeTab, setActiveTab] = React.useState(0);
  const [isEdit, setEdit] = useState(false);

  const options = ["Saved Prospectus Drafts", "Create New Prospectus"];

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const handleActiveTab = (event, value) => {
    setActiveTab(value);
  };

  const [formData, setFormData] = useState({
    pdfHeading: "",
    releasedDate: "",
    company: {
      name: "",
      number: "",
      address: "",
      class: "",
      qtyCreated: "",
      qtyIssued: "",
      pricePerShare: "",
      overview: "",
    },
    shareType: "",
    offerShareSection: [
      {
        projectName: "",
        projectDescription: "",
        sharesOffered: {
          class: "",
          qtyOffered: "",
          pricePerShare: "",
          targetRaise: "",
        },
        votingRights: {
          yes: false,
          no: false,
          specify: "",
          notApplicable: false,
        },
        interestBearing: {
          yes: false,
          no: false,
          specify: "",
          notApplicable: false,
        },
        participatingInProfits: {
          yes: false,
          no: false,
          specify: "",
          notApplicable: false,
        },
        // dealingDates: "",
        // noticeForRedemption: "",
        minSubscription: "",
        maxSubscription: "",
        minimumAggregateSubscription: "",
        expiryDate: "",
        priceCommissions: {
          class: "",
          targetROI: "",
          commissionType: {
            subscriptionRate: "",
            subscriptionIncludedInSub: false,
            subscriptionAddedToSub: false,
            notApplicable: false,
            AUMRate: "",
            AUMPeriod: "",
            performanceRate: "",
            performancePeriod: "",
            // redemptionRate: "",
            // redemptionDeductedFromTop: {
            //   yes: false,
            //   notApplicable: false,
            // },
            otherRate: "",
            otherSpecification: "",
          },
        },
      },
    ],
    equityOwnerSection: {
      auditorName: "",
      auditorAddress: "",
      owners: [{ name: "", director: false, title: "", equityShare: "" }],
    },
  });

  const clearForm = () => {
    setOfferingDocHtml(null);
    setFormData({
      pdfHeading: "",
      releasedDate: "",
      company: {
        name: "",
        number: "",
        address: "",
        class: "",
        qtyCreated: "",
        qtyIssued: "",
        pricePerShare: "",
        overview: "",
      },
      shareType: "",
      offerShareSection: [
        {
          projectName: "",
          projectDescription: "",
          sharesOffered: {
            class: "",
            qtyOffered: "",
            pricePerShare: "",
            targetRaise: "",
          },
          votingRights: {
            yes: false,
            no: false,
            specify: "",
            notApplicable: false,
          },
          interestBearing: {
            yes: false,
            no: false,
            specify: "",
            notApplicable: false,
          },
          participatingInProfits: {
            yes: false,
            no: false,
            specify: "",
            notApplicable: false,
          },
          // dealingDates: "",
          // noticeForRedemption: "",
          minSubscription: "",
          maxSubscription: "",
          minimumAggregateSubscription: "",
          expiryDate: "",
          priceCommissions: {
            class: "",
            targetROI: "",
            commissionType: {
              subscriptionRate: "",
              subscriptionIncludedInSub: false,
              subscriptionAddedToSub: false,
              notApplicable: false,
              AUMRate: "",
              AUMPeriod: "",
              performanceRate: "",
              performancePeriod: "",
              // redemptionRate: "",
              // redemptionDeductedFromTop: {
              //   yes: false,
              //   notApplicable: false,
              // },
              otherRate: "",
              otherSpecification: "",
            },
          },
        },
      ],
      equityOwnerSection: {
        auditorName: "",
        auditorAddress: "",
        owners: [{ name: "", director: false, title: "", equityShare: "" }],
      },
    });
  };

  useEffect(() => {
    if (activeTab === 0) {
      setEdit(false);
      clearForm();
    }
  }, [activeTab]);

  // Function to make all inputs required
  const makeAllInputsRequired = () => {
    document.querySelectorAll("input, textarea").forEach((input) => {
      input.required = true;
    });
  };

  // Call this function after the component mounts
  useEffect(() => {
    // makeAllInputsRequired();
    function validateForm() {
      var inputs = document.getElementsByTagName("input");
      for (var i = 0; i < inputs.length; i++) {
        if (
          inputs[i].type === "text" ||
          inputs[i].type === "email" ||
          inputs[i].type === "password"
        ) {
          inputs[i].required = true;
        }
      }
      return true; // Prevent form submission if validation fails
    }
    validateForm();
    getProspectusData();
  }, []);

  const handleColorChange = (event) => {
    setColor(event.target.value);
  };

  const addOfficersSection = () => {
    setFormData((prevState) => ({
      ...prevState,
      equityOwnerSection: {
        ...prevState.equityOwnerSection,
        owners: [
          ...prevState.equityOwnerSection.owners,
          { name: "", director: false, title: "", equityShare: "" },
        ],
      },
    }));
  };

  const handleOwnerChange = (index, field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      equityOwnerSection: {
        ...prevState.equityOwnerSection,
        owners: prevState.equityOwnerSection.owners.map((owner, i) =>
          i === index ? { ...owner, [field]: value } : owner
        ),
      },
    }));
  };

  const addOfferShareSection = () => {
    setFormData((prevState) => ({
      ...prevState,
      offerShareSection: [
        ...prevState.offerShareSection,
        {
          projectName: "",
          projectDescription: "",
          sharesOffered: {
            class: "",
            qtyOffered: "",
            pricePerShare: "",
            targetRaise: "",
          },
          votingRights: {
            yes: false,
            no: false,
            specify: "",
            notApplicable: false,
          },
          interestBearing: {
            yes: false,
            no: false,
            specify: "",
            notApplicable: false,
          },
          participatingInProfits: {
            yes: false,
            no: false,
            specify: "",
            notApplicable: false,
          },
          // dealingDates: "",
          // noticeForRedemption: "",
          minSubscription: "",
          maxSubscription: "",
          minimumAggregateSubscription: "",
          expiryDate: "",
          priceCommissions: {
            class: "",
            targetROI: "",
            commissionType: {
              subscriptionRate: "",
              subscriptionIncludedInSub: false,
              subscriptionAddedToSub: false,
              notApplicable: false,
              AUMRate: "",
              AUMPeriod: "",
              performanceRate: "",
              performancePeriod: "",
              // redemptionRate: "",
              // redemptionDeductedFromTop: {
              //   yes: false,
              //   notApplicable: false,
              // },
              otherRate: "",
              otherSpecification: "",
            },
          },
        },
      ],
    }));
  };

  const removeOfferShareSection = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      sections: prevState.sections.filter((_, i) => i !== index),
    }));
  };

  function handleInputChange1(event, section, field) {
    const { value } = event.target;

    console.log(event, section, field);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [section]: {
        ...prevFormData[section],
        [field]: value,
      },
    }));
  }

  // useEffect(() => {
  //   console.log({ formData });
  // }, [formData]);

  function handleInputChange2(event, field) {
    const { value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  }

  function handleInputChange3(
    event,
    sectionName,
    index,
    subsectionName,
    fieldName
  ) {
    console.log(event, sectionName, index, subsectionName, fieldName);
    const { value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;

    setFormData((prevFormData) => {
      if (!Array.isArray(prevFormData[sectionName])) {
        console.error(`Section '${sectionName}' is not an array.`);
        return prevFormData;
      }

      // Update the specific section in the array
      const updatedSections = prevFormData[sectionName].map((section, idx) => {
        if (idx === index) {
          // Handle subsection update
          if (subsectionName && fieldName) {
            return {
              ...section,
              [subsectionName]: {
                ...(section[subsectionName] || {}),
                [fieldName]: inputValue,
              },
            };
          }
        }
        return section; // Return unchanged section if index doesn't match
      });

      // Return the updated form data
      return {
        ...prevFormData,
        [sectionName]: updatedSections,
      };
    });
  }

  const handleInputChange4 = (e, sectionName, index, fieldName) => {
    const { value } = e.target;

    console.log(e, sectionName, index, fieldName);
    setFormData((prevState) => {
      const updatedSections = [...prevState[sectionName]];
      updatedSections[index] = {
        ...updatedSections[index],
        [fieldName]: value,
      };

      return {
        ...prevState,
        [sectionName]: updatedSections,
      };
    });
  };

  function handleInputChange(
    event,
    sectionName,
    index,
    subsectionName,
    subsubsectionName,
    fieldName
  ) {
    const { value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;

    setFormData((prevFormData) => {
      if (!Array.isArray(prevFormData[sectionName])) {
        console.error(`Section '${sectionName}' is not an array.`);
        return prevFormData;
      }

      const updatedSections = prevFormData[sectionName].map((section, idx) => {
        if (idx === index) {
          // Handle sub-subsection update
          if (subsectionName && subsubsectionName && fieldName) {
            return {
              ...section,
              [subsectionName]: {
                ...(section[subsectionName] || {}),
                [subsubsectionName]: {
                  ...(section[subsectionName]?.[subsubsectionName] || {}),
                  [fieldName]: inputValue,
                },
              },
            };
          }

          // Handle subsection update
          else if (subsectionName && fieldName) {
            return {
              ...section,
              [subsectionName]: {
                ...(section[subsectionName] || {}),
                [fieldName]: inputValue,
              },
            };
          }

          // Handle direct field update
          else if (subsectionName) {
            return {
              ...section,
              [subsectionName]: inputValue,
            };
          }
        }
        return section;
      });

      return {
        ...prevFormData,
        [sectionName]: updatedSections,
      };
    });
  }

  const onceExported = () => {
    const hideSection = document.getElementById("hideToolbar");
    const hideSection1 = document.getElementById("hideToolbar1");
    const hideClearButton = document.getElementById("clearSignButton");

    if (hideSection) {
      hideSection.style.display = "block";
    } else {
      console.log("Element not found");
    }

    if (hideSection1) {
      hideSection1.style.display = "block";
    } else {
      console.log("Element not found");
    }

    if (hideClearButton) {
      hideClearButton.style.display = "block";
    } else {
      console.log("Element not found");
    }
  };

  const exportToPDF = async (onceExported) => {
    await saveProspectusData(
      { ...formData, offeringDocHtml, isExported: true },
      clearForm
    );
    const section1 = document.getElementById("section1");
    const section2 = document.getElementById("section2");

    if (!section1 || !section2) {
      console.error("Section elements not found");
      return;
    }

    try {
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const margin = 10;

      // Helper function to add content to the PDF
      const addSectionToPDF = async (section, pdf, pagePosition = 0) => {
        // Reduce scale for smaller image size
        const canvas = await html2canvas(section, { scale: 1.5 });
        // Use JPEG format instead of PNG and set quality to 0.8
        const imgData = canvas.toDataURL("image/jpeg", 0.8);
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = imgWidth / pdfWidth;
        const imgHeightInPdf = imgHeight / ratio;
        let position = pagePosition;

        while (position < imgHeightInPdf) {
          pdf.addImage(
            imgData,
            "JPEG", // Use JPEG instead of PNG
            margin,
            -position + margin,
            pdfWidth - 2 * margin,
            imgHeightInPdf,
            undefined,
            "FAST"
          );
          position += pdfHeight - 2 * margin;
          if (position < imgHeightInPdf) pdf.addPage();
        }
      };

      // Add Section 1 content
      await addSectionToPDF(section1, pdf);

      // Add a new page for Section 2
      pdf.addPage();

      // Add Section 2 content
      await addSectionToPDF(section2, pdf);

      // Compress the PDF
      // pdf.compressPdf(); // Apply jsPDF compression

      // Save the PDF
      pdf.save("Prospectus.pdf");
      onceExported();
    } catch (err) {
      console.error("Error generating PDF:", err);
    }
  };

  // Ref for company overview
  const editableDiv = useRef(null);

  // Ref for project descriptions, since there can be multiple descriptions, use an array of refs
  const editableDivProjectDesc = useRef([]);

  // Handle company overview text input change
  const handleTextInputChange = (e) => {
    const div = editableDiv.current;

    // Ensure text is updated and visible correctly
    const updatedValue = div.textContent;

    // Update the state with the new content
    handleInputChange1(
      { target: { value: updatedValue } },
      "company",
      "overview"
    );

    // Reset height to auto to measure scrollHeight accurately
    div.style.height = "auto";

    // Adjust height based on scrollHeight
    div.style.height = `${div.scrollHeight}px`;
  };

  useEffect(() => {
    if (editableDiv.current) {
      // Only update the textContent if it has changed
      if (editableDiv.current.textContent !== formData.company.overview) {
        editableDiv.current.textContent = formData.company.overview || "";

        // Adjust height based on initial content
        editableDiv.current.style.height = "auto";
        editableDiv.current.style.height = `${editableDiv.current.scrollHeight}px`;
      }
    }
  }, [formData.company.overview]);

  // Handle project description text input change
  const handleProjectInputChange = (e, index) => {
    const divProj = editableDivProjectDesc.current[index];

    // Ensure text is updated and visible correctly
    const updatedValue = divProj.textContent;

    // Update the state with the new content
    handleInputChange4(
      { target: { value: updatedValue } },
      "offerShareSection",
      index,
      "projectDescription"
    );

    // Reset height to auto to measure scrollHeight accurately
    divProj.style.height = "auto";

    // Adjust height based on scrollHeight
    divProj.style.height = `${divProj.scrollHeight}px`;
  };

  useEffect(() => {
    if (
      editableDivProjectDesc.current &&
      Array.isArray(editableDivProjectDesc.current)
    ) {
      editableDivProjectDesc.current.forEach((divProj, index) => {
        if (divProj && formData.offerShareSection[index]) {
          // Only update the textContent for the project description, not overview
          if (
            divProj.textContent !==
            formData.offerShareSection[index].projectDescription
          ) {
            divProj.textContent =
              formData.offerShareSection[index].projectDescription || "";

            // Adjust height based on initial content
            divProj.style.height = "auto";
            divProj.style.height = `${divProj.scrollHeight}px`;
          }
        }
      });
    }
  }, [formData.offerShareSection]);

  return (
    <div style={{ marginLeft: "70px", marginTop: "10px" }}>
      <Typography
        variant="h5"
        style={{
          color: "#359DB6",
          // textDecoration: "underline",
          fontWeight: "bold",
        }}
        className={"system-nav"}
      >
        <Tabs value={activeTab} onChange={handleActiveTab}>
          {options.map((data, index) => (
            <Tab label={data} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Typography>

      {activeTab === 0 ? (
        <Grid
          container
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 0", // Added padding for overall spacing
          }}
        >
          {/* <Grid
            item
            xs={12}
            style={{ marginBottom: "20px", textAlign: "left" }}
          >
            <Typography
              variant="h6"
              style={{ fontWeight: "bold", color: "#fff" }}
            >
              Saved Drafts
            </Typography>
          </Grid> */}
          {prospectusDrafts?.length ? (
            prospectusDrafts?.map((draft, index) => (
              <Grid
                container
                item
                xs={12}
                style={{
                  marginBottom: "12px", // Slightly increased margin for better spacing
                  padding: "8px", // Increased padding for more spacing inside the row
                  // border: "1px solid #ddd", // Light border to separate each draft
                  borderRadius: "8px", // Rounded corners
                  backgroundColor: index % 2 === 0 ? "#424242" : "#141414", // Subtle off-white background for better contrast
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.05)", // Added a soft shadow for depth
                  transition:
                    "box-shadow 0.3s ease, background-color 0.3s ease", // Smooth transitions
                }}
                key={index}
              >
                <Grid
                  item
                  xs={8}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography style={{ color: "#fff" }}>
                    <span style={{ fontWeight: "bold" }}>
                      {`${index + 1}. Company Name: `}
                    </span>
                    {draft?.company?.name}
                    <span style={{ fontWeight: "bold" }}>
                      &nbsp; &nbsp;
                      {` | Release Date: `}
                    </span>
                    {draft?.releasedDate ? `${draft?.releasedDate} ` : "------"}
                  </Typography>
                  &nbsp; &nbsp;
                  {draft?.isExported && (
                    <Typography style={{ color: "#fff" }}>
                      <span style={{ fontWeight: "bold" }}>
                        &nbsp;
                        {`| Exported`}
                      </span>
                    </Typography>
                  )}
                </Grid>

                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      setFormData(draft);
                      setProspectusId(draft?._id);
                      setActiveTab(1);
                      setEdit(true);
                    }}
                    style={{
                      color: "#ff7300",
                      marginRight: "10px", // Added margin between buttons
                      transition: "color 0.3s ease", // Smooth color transition
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.color = "#ff4500")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.color = "#ff7300")
                    }
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    style={{
                      color: "#ff7300",
                      transition: "color 0.3s ease", // Smooth color transition
                    }}
                    onClick={() =>
                      deleteProspectusData({ prospectusId: draft?._id })
                    }
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.color = "#ff4500")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.color = "#ff7300")
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))
          ) : (
            <Typography>No drafts available</Typography>
          )}
        </Grid>
      ) : (
        <div style={{ padding: "20px" }}>
          <div className="toolbox" style={{ width: "100%" }}>
            <h3>Toolbox</h3>
            <div className="toolbox-item">
              <label htmlFor="color-picker">Pick a color:</label>
              <input
                id="color-picker"
                type="color"
                value={color}
                onChange={handleColorChange}
              />
              <div
                className="color-display"
                style={{ backgroundColor: color }}
              />
            </div>
          </div>
          <div id="pdf-content">
            <div id="section1">
              {/* 1st section The Company*/}
              <>
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                  <h1 style={{ color: `${color}`, fontWeight: "bold" }}>
                    <input
                      value={formData.pdfHeading}
                      autoFocus
                      placeholder="Enter heading"
                      style={{
                        width: "auto",
                        height: "35px",
                        minWidth: "40vw",
                      }}
                      onChange={(e) => handleInputChange2(e, "pdfHeading")}
                    />
                  </h1>
                </div>

                <div
                  style={{
                    textAlign: "right",
                    marginBottom: "20px",
                  }}
                >
                  <label
                    for="dateInput"
                    style={{
                      background: "#fff",
                      color: "#000",
                      padding: "2px",
                    }}
                  >
                    Released Date:
                  </label>
                  <input
                    type="date"
                    id="dateInput"
                    name="dateInput"
                    required
                    value={formData.releasedDate}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        releasedDate: e.target.value,
                      })
                    }
                    // min="2024-01-01"
                    // max="2024-12-31"
                  />
                </div>
                {/* Disclaimer & Alerts */}
                <div
                  style={{
                    marginBottom: "20px",
                    border: "1px solid #000",
                    padding: "10px",
                    backgroundColor: "#F9F9F9",
                  }}
                >
                  <h2
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    DISCLAIMER & ALERTS
                  </h2>
                  <ol
                    style={{
                      color: "#000",
                      fontSize: "14px",
                      lineHeight: "1.5",
                    }}
                  >
                    <li>
                      The below information is provided by the officers of the
                      offering company. Neither the Principality of Cogito nor
                      Cogito Stock Exchange Limited accepts any responsibility
                      for the accuracy of the information or the performance of
                      the company or the offered shares.
                    </li>
                    <li>
                      The purchase cost of your investment will be debited to
                      the Wallet in your name that you select which must have
                      sufficient funds to cover the full purchase price. If your
                      funds are in one of your other Wallets or Accounts, you
                      will need to transfer them before purchase.
                    </li>
                    <li>
                      Proceeds of sale will be credited to the Wallet you select
                      for the sale.
                    </li>
                    <li>
                      Recurring fees and expenses will be debited to your Cog
                      account or your CBC account or part one and part the other
                      at the discretion of the Exchange.
                    </li>
                    <li>
                      If any Wallet is in overdraft exceeding the total value of
                      your shareholdings, the Exchange reserves the right, in
                      the following order of preference:
                      <ul style={{ marginLeft: "20px" }}>
                        <li>
                          a) To transfer funds from your Cogito Cog Account
                          and/or your Cogito CBC Account;
                        </li>
                        <li>
                          b) To transfer funds from one of your other Wallets;
                        </li>
                        <li>
                          c) To sell all or any of your shares on the Exchange
                          and apply the proceeds in reduction of the overdraft.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>

                <div>
                  <table
                    border="1"
                    cellPadding="5"
                    cellSpacing="0"
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      marginBottom: "20px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          colSpan="2"
                          style={{
                            backgroundColor: "#f2f2f2",
                            textAlign: "center",
                            padding: "10px",
                          }}
                        >
                          The Company
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Name:</td>
                        <td>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            value={formData.company.name}
                            onChange={(e) =>
                              handleInputChange1(e, "company", "name")
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>No:</td>
                        <td>
                          <input
                            type="number"
                            style={{ width: "100%" }}
                            value={formData.company.number}
                            onChange={(e) =>
                              handleInputChange1(e, "company", "number")
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Address:</td>
                        <td>
                          <input
                            type="text"
                            value={formData.company.address}
                            onChange={(e) =>
                              handleInputChange1(e, "company", "address")
                            }
                            style={{ width: "100%" }}
                          />
                        </td>
                      </tr>
                      <tr></tr>
                      <tr>
                        <td colSpan="2">
                          <table
                            border="1"
                            cellPadding="5"
                            cellSpacing="0"
                            style={{
                              width: "100%",
                              borderCollapse: "collapse",
                            }}
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    backgroundColor: `${color}`,
                                    color: "white",
                                  }}
                                  colspan="5"
                                >
                                  Existing Ordinary Shares:
                                </th>
                              </tr>
                              <tr>
                                <th>Class</th>
                                <th>Qty Created</th>
                                <th>Qty Issued</th>
                                <th>Price/Share</th>
                                <th>Total Equity</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    value={formData.company.class}
                                    onChange={(e) =>
                                      handleInputChange1(e, "company", "class")
                                    }
                                    style={{ width: "100%" }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    value={formData.company.qtyCreated}
                                    onChange={(e) =>
                                      handleInputChange1(
                                        e,
                                        "company",
                                        "qtyCreated"
                                      )
                                    }
                                    style={{ width: "100%" }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    value={formData.company.qtyIssued}
                                    onChange={(e) =>
                                      handleInputChange1(
                                        e,
                                        "company",
                                        "qtyIssued"
                                      )
                                    }
                                    style={{ width: "100%" }}
                                  />
                                </td>
                                <td>
                                  <div style={{ width: "100%" }}>
                                    {`€ `}
                                    <input
                                      type="number"
                                      value={formData.company.pricePerShare}
                                      onChange={(e) =>
                                        handleInputChange1(
                                          e,
                                          "company",
                                          "pricePerShare"
                                        )
                                      }
                                      style={{ width: "90%" }}
                                    />
                                  </div>
                                </td>
                                <td>
                                  {/* <input
                                type="number"
                                readOnly
                                value={
                                  Number(formData.company.qtyCreated) *
                                  Number(formData.company.pricePerShare)
                                    ? `€ ${
                                        Number(formData.company.qtyCreated) *
                                        Number(formData.company.pricePerShare)
                                      }`
                                    : ""
                                }
                                // onChange={(e) =>
                                //   handleInputChange1(e, "company", "totalEquity")
                                // }
                                style={{ width: "100%" }}
                              /> */}
                                  <Typography>
                                    {`€ `}
                                    {Number(formData.company.qtyCreated) *
                                    Number(formData.company.pricePerShare)
                                      ? Number(formData.company.qtyCreated) *
                                        Number(formData.company.pricePerShare)
                                      : ""}
                                  </Typography>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan="2"
                          style={{
                            backgroundColor: `${color}`,
                            color: "white",
                          }}
                        >
                          Overview/Vision/Mission
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <div
                            contentEditable="true"
                            style={{
                              width: "100%",
                              minHeight: "100px", // Set a minimum height
                              border: "1px solid #ccc",
                              padding: "8px",
                              overflow: "hidden", // Hide scrollbar
                              whiteSpace: "pre-wrap", // Allows text wrapping
                              wordWrap: "break-word", // Breaks long words to wrap
                              boxSizing: "border-box",
                            }}
                            onInput={handleTextInputChange}
                            ref={editableDiv}
                          >
                            {/* {formData.company.overview || ""} */}
                            {/* Content is set by React state */}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>

              {/* 2nd section the offer shares */}
              <table
                // border="1"
                cellPadding="5"
                cellSpacing="0"
                style={{ width: "100%", borderCollapse: "collapse" }}
              >
                <thead>
                  <tr>
                    <th
                      colSpan="4"
                      style={{
                        backgroundColor: "#f2f2f2",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      The Offer Shares
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="3">
                      <label>Share Type:</label>
                      <select
                        value={formData.shareType}
                        style={{ width: "100%", height: "35px" }}
                        onChange={(e) => handleInputChange2(e, "shareType")}
                      >
                        <option value="">Select Share Type</option>
                        <option value="same">
                          The same Class as existing Shares
                        </option>
                        <option value="single">
                          A single separate Class of Preference Shares
                        </option>
                        <option value="multiple">Ordinary Shares</option>
                      </select>
                    </td>
                  </tr>

                  {formData.offerShareSection.map((section, index) => (
                    <React.Fragment key={index}>
                      <>
                        <table
                          // border="2"
                          cellPadding="2"
                          cellSpacing="0"
                          style={{
                            width: "100%",
                            borderCollapse: "collapse",
                            marginBottom: "15px",
                            marginTop: "20px",
                          }}
                        >
                          <tbody>
                            {/* Row for Project Name */}
                            <tr>
                              <td style={{ padding: "10px", width: "25%" }}>
                                Project Name:
                              </td>
                              <td colSpan="3" style={{ padding: "10px" }}>
                                <input
                                  type="text"
                                  value={section.projectName}
                                  style={{ width: "100%", height: "35px" }}
                                  onChange={(e) =>
                                    handleInputChange4(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "projectName"
                                    )
                                  }
                                />
                              </td>
                            </tr>

                            {/* Row for Project Description */}
                            <tr>
                              <td
                                style={{
                                  padding: "10px",
                                  width: "25%",
                                  verticalAlign: "top",
                                }}
                              >
                                Project Description:
                              </td>
                              <td colSpan="3" style={{ padding: "10px" }}>
                                <div
                                  contentEditable="true"
                                  style={{
                                    width: "100%",
                                    minHeight: "100px", // Set a minimum height
                                    border: "1px solid #ccc",
                                    padding: "8px",
                                    overflow: "hidden", // Hide scrollbar
                                    whiteSpace: "pre-wrap", // Allows text wrapping
                                    wordWrap: "break-word", // Breaks long words to wrap
                                    boxSizing: "border-box",
                                  }}
                                  onInput={(e) =>
                                    handleProjectInputChange(e, index)
                                  }
                                  ref={(el) =>
                                    (editableDivProjectDesc.current[index] = el)
                                  }
                                >
                                  {/* {section.projectDescription || ""} */}
                                  {/* Content is set by React state */}
                                </div>
                                {/* <textarea
                              value={section.projectDescription}
                              style={{ width: "100%", height: "100px" }}
                              onChange={(e) =>
                                handleInputChange4(
                                  e,
                                  "offerShareSection",
                                  index,
                                  "projectDescription"
                                )
                              }
                            /> */}
                              </td>
                            </tr>

                            {/* Example of adding other rows as needed */}
                            {/* Add other rows here */}
                          </tbody>
                        </table>
                        <table
                          border="2"
                          cellPadding="5"
                          cellSpacing="0"
                          style={{
                            width: "100%",
                            borderCollapse: "collapse",
                            marginBottom: "15px",
                            marginTop: "20px",
                          }}
                        >
                          <thead style={{ textAlign: "left" }}>
                            <tr>
                              <th
                                colSpan="4"
                                style={{
                                  backgroundColor: `#${color}`,
                                  color: "white",
                                  textAlign: "center",
                                }}
                              >
                                Shares Offered:
                              </th>
                            </tr>
                            <tr>
                              <th>Class</th>
                              <th>Qty Offered</th>
                              <th>Price/Share</th>
                              <th>Target Raise</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  type="text"
                                  value={section.sharesOffered.class}
                                  onChange={(e) =>
                                    handleInputChange3(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "sharesOffered",
                                      "class"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={section.sharesOffered.qtyOffered}
                                  onChange={(e) =>
                                    handleInputChange3(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "sharesOffered",
                                      "qtyOffered"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                {`€ `}
                                <input
                                  type="number"
                                  value={section.sharesOffered.pricePerShare}
                                  onChange={(e) =>
                                    handleInputChange3(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "sharesOffered",
                                      "pricePerShare"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                {/* <input
                              type="number"
                              readOnly
                              value={
                                Number(section.sharesOffered.qtyOffered) *
                                Number(section.sharesOffered.pricePerShare)
                                  ? `€ ${
                                      Number(section.sharesOffered.qtyOffered) *
                                      Number(
                                        section.sharesOffered.pricePerShare
                                      )
                                    }`
                                  : ""
                              }
                              // onChange={(e) =>
                              //   handleInputChange1(e, "company", "totalEquity")
                              // }
                              style={{ width: "100%" }}
                            /> */}
                                <Typography>
                                  {`€ `}
                                  {Number(section.sharesOffered.qtyOffered) *
                                  Number(section.sharesOffered.pricePerShare)
                                    ? Number(section.sharesOffered.qtyOffered) *
                                      Number(
                                        section.sharesOffered.pricePerShare
                                      )
                                    : ""}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td>Voting Rights</td>
                              <td colSpan="3">
                                <input
                                  type="checkbox"
                                  checked={section.votingRights.no}
                                  onChange={(e) =>
                                    handleInputChange3(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "votingRights",
                                      "no"
                                    )
                                  }
                                />
                                No{" "}
                                <input
                                  type="checkbox"
                                  checked={section.votingRights.yes}
                                  onChange={(e) =>
                                    handleInputChange3(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "votingRights",
                                      "yes"
                                    )
                                  }
                                />
                                Yes{" "}
                                <input
                                  type="checkbox"
                                  checked={section.votingRights.notApplicable}
                                  onChange={(e) =>
                                    handleInputChange3(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "votingRights",
                                      "notApplicable"
                                    )
                                  }
                                />
                                Other{" "}
                                <input
                                  type="text"
                                  value={section.votingRights.specify}
                                  onChange={(e) =>
                                    handleInputChange3(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "votingRights",
                                      "specify"
                                    )
                                  }
                                />{" "}
                                Specify
                              </td>
                            </tr>
                            <tr>
                              <td>Interest Bearing</td>
                              <td colSpan="3">
                                <input
                                  type="checkbox"
                                  checked={section.interestBearing.no}
                                  onChange={(e) =>
                                    handleInputChange3(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "interestBearing",
                                      "no"
                                    )
                                  }
                                />
                                No{" "}
                                <input
                                  type="checkbox"
                                  checked={section.interestBearing.yes}
                                  onChange={(e) =>
                                    handleInputChange3(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "interestBearing",
                                      "yes"
                                    )
                                  }
                                />
                                Yes{" "}
                                <input
                                  type="checkbox"
                                  checked={
                                    section.interestBearing.notApplicable
                                  }
                                  onChange={(e) =>
                                    handleInputChange3(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "interestBearing",
                                      "notApplicable"
                                    )
                                  }
                                />
                                Other{" "}
                                <input
                                  type="text"
                                  value={section.interestBearing.specify}
                                  onChange={(e) =>
                                    handleInputChange3(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "interestBearing",
                                      "specify"
                                    )
                                  }
                                />{" "}
                                Specify
                              </td>
                            </tr>
                            <tr>
                              <td>Participating in Company's Own Profits</td>
                              <td colSpan="3">
                                <input
                                  type="checkbox"
                                  checked={section.participatingInProfits.no}
                                  onChange={(e) =>
                                    handleInputChange3(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "participatingInProfits",
                                      "no"
                                    )
                                  }
                                />
                                No{" "}
                                <input
                                  type="checkbox"
                                  checked={section.participatingInProfits.yes}
                                  onChange={(e) =>
                                    handleInputChange3(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "participatingInProfits",
                                      "yes"
                                    )
                                  }
                                />
                                Yes{" "}
                                <input
                                  type="checkbox"
                                  checked={
                                    section.participatingInProfits.notApplicable
                                  }
                                  onChange={(e) =>
                                    handleInputChange3(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "participatingInProfits",
                                      "notApplicable"
                                    )
                                  }
                                />
                                Other{" "}
                                <input
                                  type="text"
                                  value={section.participatingInProfits.specify}
                                  onChange={(e) =>
                                    handleInputChange3(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "participatingInProfits",
                                      "specify"
                                    )
                                  }
                                />{" "}
                                Specify
                              </td>
                            </tr>
                            {/* <tr>
                      <td>Subscription/Redemption Dealing Dates:</td>
                      <td colSpan="3">
                        <input
                          type="text"
                          value={section.dealingDates}
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              "offerShareSection",
                              index,
                              "dealingDates"
                            )
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Notice for Redemption:</td>
                      <td colSpan="3">
                        <input
                          type="text"
                          value={section.noticeForRedemption}
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              "offerShareSection",
                              index,
                              "noticeForRedemption"
                            )
                          }
                        />{" "}
                        days
                      </td>
                    </tr> */}
                            <tr>
                              <td>Minimum Subscription:</td>
                              <td colSpan="3">
                                {`€ `}
                                <input
                                  type="number"
                                  value={section.minSubscription}
                                  onChange={(e) =>
                                    handleInputChange4(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "minSubscription"
                                    )
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Maximum Subscription:</td>
                              <td colSpan="3">
                                {`€ `}
                                <input
                                  type="number"
                                  value={section.maxSubscription}
                                  onChange={(e) =>
                                    handleInputChange4(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "maxSubscription"
                                    )
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Minimum Aggregate Subscription:</td>
                              <td colSpan="3">
                                {`€ `}
                                <input
                                  type="number"
                                  value={section.minimumAggregateSubscription}
                                  onChange={(e) =>
                                    handleInputChange4(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "minimumAggregateSubscription"
                                    )
                                  }
                                />
                              </td>
                            </tr>
                            {/* <tr>
                      <td>Expiry Date:</td>
                      <td colSpan="3">
                        <input
                          type="text"
                          value={section.expiryDate}
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              "offerShareSection",
                              index,
                              "expiryDate"
                            )
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Class:</td>
                      <td>
                        <input
                          type="text"
                          value={section.priceCommissions.class}
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              "offerShareSection",
                              index,
                              "priceCommissions",
                              "class"
                            )
                          }
                        />
                      </td>
                      <td>Target ROI:</td>
                      <td>
                        <input
                          type="text"
                          value={section.priceCommissions.targetROI}
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              "offerShareSection",
                              index,
                              "priceCommissions",
                              "targetROI"
                            )
                          }
                        />
                      </td>
                    </tr> */}
                            <tr>
                              <th
                                style={{
                                  backgroundColor: `${color}`,
                                  color: "white",
                                }}
                              >
                                Commission Type
                              </th>
                              <th
                                style={{
                                  backgroundColor: `${color}`,
                                  color: "white",
                                }}
                              >
                                Rate
                              </th>
                              <th
                                colSpan="2"
                                style={{
                                  backgroundColor: `${color}`,
                                  color: "white",
                                }}
                              >
                                Specification
                              </th>
                            </tr>
                            <tr>
                              <td>Subscription</td>
                              <td>
                                <input
                                  type="text"
                                  value={
                                    section?.priceCommissions?.commissionType
                                      ?.subscriptionRate
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "priceCommissions",
                                      "commissionType",
                                      "subscriptionRate"
                                    )
                                  }
                                />
                              </td>
                              <td colSpan="2">
                                <input
                                  type="checkbox"
                                  checked={
                                    section?.priceCommissions?.commissionType
                                      ?.subscriptionIncludedInSub
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "priceCommissions",
                                      "commissionType",
                                      "subscriptionIncludedInSub"
                                    )
                                  }
                                />
                                Included in sub{" "}
                                <input
                                  type="checkbox"
                                  checked={
                                    section?.priceCommissions?.commissionType
                                      ?.subscriptionAddedToSub
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "priceCommissions",
                                      "commissionType",
                                      "subscriptionAddedToSub"
                                    )
                                  }
                                />
                                Added to sub{" "}
                                <input
                                  type="checkbox"
                                  checked={
                                    section?.priceCommissions?.commissionType
                                      ?.notApplicable
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "priceCommissions",
                                      "commissionType",
                                      "notApplicable"
                                    )
                                  }
                                />
                                Not Applicable{" "}
                              </td>
                            </tr>
                            <tr>
                              <td>Assest Under Management Fee</td>
                              <td>
                                <input
                                  type="text"
                                  value={
                                    section?.priceCommissions?.commissionType
                                      ?.AUMRate
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "priceCommissions",
                                      "commissionType",
                                      "AUMRate"
                                    )
                                  }
                                />
                              </td>
                              <td colSpan="2">
                                Period:{" "}
                                <input
                                  type="text"
                                  value={
                                    section?.priceCommissions?.commissionType
                                      ?.AUMPeriod
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "priceCommissions",
                                      "commissionType",
                                      "AUMPeriod"
                                    )
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Performance</td>
                              <td>
                                <input
                                  type="text"
                                  value={
                                    section?.priceCommissions?.commissionType
                                      ?.performanceRate
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "priceCommissions",
                                      "commissionType",
                                      "performanceRate"
                                    )
                                  }
                                />
                              </td>
                              <td colSpan="2">
                                Period:{" "}
                                <input
                                  type="text"
                                  value={
                                    section?.priceCommissions?.commissionType
                                      ?.performancePeriod
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "priceCommissions",
                                      "commissionType",
                                      "performancePeriod"
                                    )
                                  }
                                />
                              </td>
                            </tr>
                            {/* <tr>
                      <td>Redemption</td>
                      <td>
                        <input
                          type="text"
                          value={
                            section?.priceCommissions?.commissionType
                              ?.redemptionRate
                          }
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              "offerShareSection",
                              index,
                              "priceCommissions",
                              "commissionType",
                              "redemptionRate"
                            )
                          }
                        />
                      </td>
                      <td colSpan="2">
                        <input
                          type="checkbox"
                          checked={
                            section?.priceCommissions?.commissionType
                              ?.redemptionDeductedFromTop?.yes
                          }
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              "offerShareSection",
                              index,
                              "priceCommissions",
                              "commissionType",
                              "redemptionDeductedFromTop",
                              "yes"
                            )
                          }
                        />
                        Deducted from top{" "}
                        <input
                          type="checkbox"
                          checked={
                            section?.priceCommissions?.commissionType
                              ?.redemptionDeductedFromTop?.notApplicable
                          }
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              "offerShareSection",
                              index,
                              "priceCommissions",
                              "commissionType",
                              "redemptionDeductedFromTop",
                              "notApplicable"
                            )
                          }
                        />
                        Not Applicable{" "}
                      </td>
                    </tr> */}
                            <tr>
                              <td>Other</td>
                              <td>
                                <input
                                  type="text"
                                  value={
                                    section?.priceCommissions?.commissionType
                                      ?.otherRate
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "priceCommissions",
                                      "commissionType",
                                      "otherRate"
                                    )
                                  }
                                />
                              </td>
                              <td colSpan="2">
                                Specification:{" "}
                                <input
                                  type="text"
                                  value={
                                    section?.priceCommissions?.commissionType
                                      ?.otherSpecification
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "offerShareSection",
                                      index,
                                      "priceCommissions",
                                      "commissionType",
                                      "otherSpecification"
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    </React.Fragment>
                  ))}
                  <button
                    onClick={() => {
                      // setCount({ ...count, section2: count?.section2 + 1 });
                      addOfferShareSection();
                    }}
                  >
                    Add a class
                  </button>
                  {/* <button
                onClick={() => {
                  // setCount({ ...count, section2: count?.section2 + 1 });
                  addOfferShareSection();
                }}
              >
                Remove class
              </button> */}
                </tbody>
              </table>

              {/* 3rd section OFFICERS & EQUITY OWNERS */}
              <div style={{ marginTop: "20px" }}>
                <table
                  border="1"
                  style={{ width: "100%", borderCollapse: "collapse" }}
                >
                  <thead>
                    <tr>
                      <th
                        colSpan="4"
                        style={{
                          backgroundColor: "#f2f2f2",
                          textAlign: "center",
                          padding: "10px",
                        }}
                      >
                        OFFICERS & EQUITY OWNERS
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr style={{ backgroundColor: `${color}`, color: "white" }}>
                      <th>Name:</th>
                      <th>Director:</th>
                      <th>Officer/Title:</th>
                      <th>Equity Share:</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.equityOwnerSection.owners.map(
                      (section, index) => {
                        return (
                          <tr>
                            <td>
                              <input
                                type="text"
                                style={{ width: "100%" }}
                                value={section.name}
                                onChange={(e) =>
                                  handleOwnerChange(
                                    index,
                                    "name",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                style={{ width: "100%" }}
                                checked={section.director}
                                onChange={(e) =>
                                  handleOwnerChange(
                                    index,
                                    "director",
                                    e.target.checked
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                style={{ width: "100%" }}
                                value={section.title}
                                onChange={(e) =>
                                  handleOwnerChange(
                                    index,
                                    "title",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                style={{ width: "100%" }}
                                value={section.equityShare}
                                onChange={(e) =>
                                  handleOwnerChange(
                                    index,
                                    "equityShare",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
                <table
                  border="1"
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    marginTop: "10px",
                  }}
                >
                  <tbody>
                    {/* <tr>
                <td
                  style={{
                    backgroundColor: `${color}`,
                    color: "white",
                    textAlign: "right",
                  }}
                >
                  Upload Structure Chart Graphic:
                </td>
                <td>
                  <button>Upload</button>
                </td>
              </tr> */}
                    <tr>
                      <td
                        style={{
                          backgroundColor: `${color}`,
                          color: "white",
                        }}
                      >
                        Auditors Name:
                      </td>
                      <td>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          value={formData.equityOwnerSection.auditorName}
                          onChange={(e) =>
                            handleInputChange1(
                              e,
                              "equityOwnerSection",
                              "auditorName"
                            )
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          backgroundColor: `${color}`,
                          color: "white",
                        }}
                      >
                        Auditors Address:
                      </td>
                      <td>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          value={formData.equityOwnerSection.auditorAddress}
                          onChange={(e) =>
                            handleInputChange1(
                              e,
                              "equityOwnerSection",
                              "auditorAddress"
                            )
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button
                  style={{ marginTop: "15px", marginBottom: "15px" }}
                  onClick={() => {
                    // setCount({ ...count, section3: count.section3 + 1 });
                    addOfficersSection();
                  }}
                >
                  Add a officers/owners
                </button>
              </div>

              {/* 4th section material documents*/}
              <div style={{ marginTop: "20px" }}>
                <table
                  border="1"
                  style={{ width: "100%", borderCollapse: "collapse" }}
                >
                  <thead>
                    <tr>
                      <th
                        colSpan="4"
                        style={{
                          backgroundColor: "#f2f2f2",
                          textAlign: "center",
                          padding: "10px",
                        }}
                      >
                        Material Documents
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <Typography
                          style={{ textAlign: "center", padding: "20px" }}
                        >
                          Other support documents can be viewed in the links for
                          these shares in the Information section of the Cogito
                          Stock Exchange.
                        </Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div id="section2">
              {/* 5th section offering docs */}
              <div
                id="offeringDocs"
                style={{
                  marginTop: "30px",
                  paddingBottom: "30px",
                  // minHeight: "70vh",
                  height: "auto",
                  overflow: "visible",
                }}
              >
                <table
                  border="1"
                  style={{ width: "100%", borderCollapse: "collapse" }}
                >
                  <thead>
                    <tr>
                      <th
                        colSpan="4"
                        style={{
                          backgroundColor: "#f2f2f2",
                          textAlign: "center",
                          padding: "10px",
                        }}
                      >
                        Add Offering Documents
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ backgroundColor: "#fff" }}>
                    {/* <TemplateMakerPage
                setHtmlContent={setHtmlContent}
                htmlContent={htmlContent}
                isExported={isExported}
              /> */}
                    <HtmlRenderer
                      isEdit={isEdit}
                      setOfferingDocHtml={setOfferingDocHtml}
                      offeringDocHtml={formData?.offeringDocHtml}
                    />
                  </tbody>
                </table>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  background: "#fff",
                }}
              >
                <SignaturePad />
              </div>
            </div>
          </div>

          <div className={classes.saveDraftButtonContainer}>
            {prospectusId ? (
              <Button
                onClick={() => {
                  updateProspectusData(
                    { ...formData, offeringDocHtml },
                    clearForm
                  );
                }}
                className={classes.saveButton}
                variant="contained"
              >
                Update Draft
              </Button>
            ) : (
              <Button
                onClick={() => {
                  saveProspectusData(
                    { ...formData, offeringDocHtml },
                    clearForm
                  );
                }}
                className={classes.saveButton}
                variant="contained"
              >
                Save as Draft
              </Button>
            )}
          </div>
          <div className={classes.exportButtonContainer}>
            <Button
              // type="submit"
              onClick={() => {
                setIsExported(true);

                const hideSection = document.getElementById("hideToolbar");
                const hideSection1 = document.getElementById("hideToolbar1");
                const hideClearButton =
                  document.getElementById("clearSignButton");

                if (hideSection) {
                  hideSection.style.display = "none"; // Correct way to hide the element
                } else {
                  console.log("Element not found");
                }

                if (hideSection1) {
                  hideSection1.style.display = "none"; // Correct way to hide the element
                } else {
                  console.log("Element not found");
                }

                if (hideClearButton) {
                  hideClearButton.style.display = "none"; // Correct way to hide the element
                } else {
                  console.log("Element not found");
                }

                exportToPDF(onceExported);
              }}
              className={classes.exportButton}
              variant="contained"
            >
              Export to PDF
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = ({ adminReducer }) => {
  return {
    prospectusDrafts: adminReducer.prospectusDrafts,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveProspectusData: (data, callback) =>
      dispatch(actionCreator.saveProspectusDraft(data, callback)),
    updateProspectusData: (data, callback) =>
      dispatch(actionCreator.updateProspectusDraft(data, callback)),
    getProspectusData: (data) =>
      dispatch(actionCreator.getProspectusDraft(data)),
    deleteProspectusData: (data) =>
      dispatch(actionCreator.deleteProspectusDraft(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProspectusTool);
