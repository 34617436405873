import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Button,
  Typography,
  Divider,
  TextField,
} from "@material-ui/core";
import { updateObjectProperty } from "../../../../asset/utility";
import * as actionCreator from "../../../../store/action/index";
import Styles from "../../../../components/styles/index";
import CompanyPlans from "./companyInfo/companyPlans";
import { componentAccess } from "../../../../components/common/method";

const CustomInput = withStyles({
  root: {
    //background: "#eeededd7",
    borderRadius: 8,
    border: 0,
    width: "100%",
    fontFamily: "Manrope",
  },
})((props) => <TextField {...props} />);

const CustomButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#359DB6",
    "&:hover": {
      background: "#62c3db",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#8ab0b9",
  },
})((props) => <Button {...props} />);

const SuccessButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#10ca73",
    "&:hover": {
      background: "#87f891",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#758876",
  },
})((props) => <Button {...props} />);

const Company = (props) => {
  const classes = Styles();
  const { loadings, user, companies, updateCompany, admin } = props;

  const [id, setId] = useState(useParams().id || null);
  const [companyMod, setCompanyMod] = useState(false);
  const [inputUser, setInputUser] = useState(null);
  const [inputCompany, setInputCompany] = useState(null);

  const [companyErrors, setCompanyErrors] = useState({
    companyNameError: "",
    companyPhoneError: "",
    companyAddressError: "",
    companyCountryError: "",
    companyStateError: "",
    companyCityError: "",
    companyPincodeError: "",
    registrationError: "",
    country_of_registration: "",
    number_of_shareholders: "",
    number_of_directors: "",
  });

  const [validCompanyData, setValidCompanyData] = useState({
    validCompanyName: false,
    validCompanyPhone: false,
    validCompanyAddress: false,
    validCompanyCountry: false,
    validCompanyState: false,
    validCompanyCity: false,
    validCompanyPincode: false,
    validRegistration: false,
    validNumberOfDirectors: false,
    validNumberOfShareholders: false,
    validCountryOfRegistration: false,
  });

  useEffect(() => {
    if (inputUser) {
      for (const property in inputUser) {
        let patt = new RegExp(/[.]/g);
        let objectProp = patt.test(property);
        if (
          inputUser[property] == user[property] ||
          (objectProp &&
            inputUser[property] ==
              user[property.split(".")[0]][property.split(".")[1]])
        ) {
          delete inputUser[property];
        }
      }
      if (isEmpty(inputUser)) setInputUser(null);
    }
    if (inputCompany) {
      for (const property in inputCompany) {
        let patt = new RegExp(/[.]/g);
        let objectProp = patt.test(property);

        if (
          inputCompany[property] == companies[0][property] ||
          (objectProp &&
            inputCompany[property] ==
              companies[0][property.split(".")[0]][property.split(".")[1]])
        ) {
          delete inputCompany[property];
        }
      }
      if (isEmpty(inputCompany)) setInputCompany(null);
    }
  }, [user, inputUser, inputCompany]);

  const validateField = (field, value) => {
    let companyErrorsCopy = { ...companyErrors };
    let validCompanyDataCopy = { ...validCompanyData };

    if (field === "company_name") {
      if (value.trim().length === 0) {
        companyErrorsCopy.companyNameError = "Company name is required";
        validCompanyDataCopy.validCompanyName = false;
      } else {
        companyErrorsCopy.companyNameError = "";
        validCompanyDataCopy.validCompanyName = true;
      }
    } else if (field == "number_of_directors") {
      if (!value) {
        companyErrorsCopy.number_of_directors = "Input is required";
        validCompanyDataCopy.validNumberOfDirectors = false;
      } else {
        companyErrorsCopy.number_of_directors = "";
        validCompanyDataCopy.validNumberOfDirectors = true;
      }
    } else if (field === "number_of_shareholders") {
      if (!value) {
        companyErrorsCopy.number_of_shareholders = "Input is required";
        validCompanyDataCopy.validNumberOfShareholders = false;
      } else {
        companyErrorsCopy.number_of_shareholders = "";
        validCompanyDataCopy.validNumberOfShareholders = true;
      }
    }

    setCompanyErrors(companyErrorsCopy);
    setValidCompanyData(validCompanyDataCopy);
  };

  const onCompanyValueChanged = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setInputCompany(updateObjectProperty(inputCompany, name, value));

    validateField(name, value);
  };

  const onCompanyValueChanged1 = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    validateField(name, value);

    setInputCompany(updateObjectProperty(inputCompany, name, parseInt(value)));
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setInputCompany(updateObjectProperty(inputCompany, name, value));
    validateField(name, value);
  };

  const onCompanyBack = () => {
    setCompanyMod(!companyMod);
    setInputCompany(null);
    setCompanyErrors({
      companyNameError: "",
      registrationError: "",
      country_of_registration: "",
      number_of_directors: "",
      number_of_shareholders: "",
    });
    setValidCompanyData({
      validCompanyName: false,
      validRegistration: false,
      validNumberOfDirectors: false,
      validNumberOfShareholders: false,
      validCountryOfRegistration: false,
    });
  };

  const onCompanySave = () => {
    if (
      companyErrors?.companyNameError?.length === 0 &&
      companyErrors?.registrationError?.length === 0 &&
      companyErrors?.country_of_registration?.length === 0 &&
      companyErrors?.number_of_directors?.length === 0 &&
      companyErrors?.number_of_shareholders?.length === 0 &&
      companies &&
      inputCompany
    ) {
      let changeCompany = {};
      for (const property in inputCompany) {
        changeCompany["company." + property] = inputCompany[property];
      }

      updateCompany({
        id: id,
        changes: inputCompany,
      });
      onCompanyBack();
    }
  };

  return (
    <>
      <Grid item container justify="center">
        <Paper className={classes.paper1} id="company" elevation={5} >
          <Grid container spacing={1}>
            <Grid
              item
              container
              xs={12}
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                {companies?.length > 0 ? (
                  <>
                    <Typography variant="h5" align="left">
                      Company
                    </Typography>
                  </>
                ) : componentAccess(
                    admin?.level,
                    admin?.type,
                    "Users",
                    "AddCompany"
                  ) ? (
                  <>
                    <Typography variant="h5" align="left">
                      Company Registration
                    </Typography>
                  </>
                ) : null}
              </Grid>
              <Grid item>
                {companyMod ? (
                  <Grid container item spacing={1}>
                    <Grid item>
                      <CustomButton
                        onClick={onCompanyBack}
                        disabled={loadings.updateCompany}
                      >
                        Back
                      </CustomButton>
                    </Grid>
                    <Grid item>
                      <SuccessButton
                        onClick={onCompanySave}
                        disabled={
                          !(
                            companyErrors?.companyNameError?.length === 0 &&
                            companyErrors?.number_of_directors?.length === 0 &&
                            companyErrors?.number_of_shareholders?.length ===
                              0 &&
                            companies &&
                            inputCompany
                          )
                        }
                      >
                        Save
                      </SuccessButton>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    {companies?.length > 0 ? (
                      <>
                        <CustomButton
                          onClick={() => setCompanyMod(!companyMod)}
                          disabled={
                            loadings.updateCompany ||
                            !Boolean(companies) ||
                            companies[0]?.length === 0
                          }
                        >
                          Modify
                        </CustomButton>
                      </>
                    ) : null}
                  </>
                )}
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                <Divider />
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems="center" spacing={2}>
              {companies?.length > 0 ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6" align="left">
                      Profile Id
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" align="left">
                      {companies[0]?.profile_id}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6" align="left">
                      Company Type
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" align="left">
                      {companies[0]?.is_cogito_company
                        ? "Cogito Company"
                        : "Non-Cogito Company"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6" align="left">
                      Company Name
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {!companyMod ? (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="left"
                      >
                        {companies[0]?.company_name}
                      </Typography>
                    ) : (
                      <CustomInput
                        variant="outlined"
                        label="Company Name"
                        name="company_name"
                        type="text"
                        error={companyErrors?.companyNameError?.length > 0}
                        helperText={companyErrors?.companyNameError}
                        defaultValue={companies[0]?.company_name}
                        onChange={onCompanyValueChanged}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6" align="left">
                      Country Of Registration
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" align="left">
                      {companies[0]?.country_of_registration}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6" align="left">
                      Number Of Directors
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {!companyMod ? (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="left"
                      >
                        {companies[0]?.number_of_directors}
                      </Typography>
                    ) : (
                      <CustomInput
                        variant="outlined"
                        label="Company Director"
                        name="number_of_directors"
                        type="number"
                        error={companyErrors?.number_of_directors?.length > 0}
                        helperText={companyErrors?.number_of_directors}
                        defaultValue={companies[0]?.number_of_directors}
                        onChange={onCompanyValueChanged1}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6" align="left">
                      Registration Number
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" align="left">
                      {companies[0]?.registration_number
                        ? companies[0].registration_number
                        : "-"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6" align="left">
                      Number Of Shareholders
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {!companyMod ? (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="left"
                      >
                        {companies[0]?.number_of_shareholders}
                      </Typography>
                    ) : (
                      <CustomInput
                        variant="outlined"
                        label="Company Shareholders"
                        name="number_of_shareholders"
                        type="number"
                        error={
                          companyErrors?.number_of_shareholders?.length > 0
                        }
                        helperText={companyErrors?.number_of_shareholders}
                        defaultValue={companies[0]?.number_of_shareholders}
                        onChange={onCompanyValueChanged1}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6" align="left">
                      Is Authorized To Register
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="left"
                    >
                      {companies[0]?.is_authorized_to_register ? "yes" : "No"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6" align="left">
                      Fee paid
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="left"
                    >
                      {companies[0]?.is_fee_paid ? "Paid" : "Not Paid"}
                    </Typography>
                  </Grid>
                </>
              ) : componentAccess(
                  admin?.level,
                  admin?.type,
                  "Users",
                  "AddCompany"
                ) ? (
                <>
                  <Grid item xs={12} sm={12}>
                    <CompanyPlans />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={12}>
                    <Typography>No companies Registered</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    admin: adminReducer.admin,
    companies: adminReducer.companies,
    user: adminReducer.user,
    assestsTypeData: adminReducer.assestsTypeData,
    filledData: adminReducer.filledData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
    getAdmin: () => dispatch(actionCreator.getAdmin()),
    getUser: (data) => dispatch(actionCreator.getUser(data)),
    getCompany: (data) => dispatch(actionCreator.getCompany(data)),
    updateCompany: (data) => dispatch(actionCreator.updateCompany(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
