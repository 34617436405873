import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import * as actionCreator from "../../store/action/index";
import { connect } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    width: "35rem",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function CustomizedDialogs(props) {
  const { admin, updateAdmin, updateAdminEmail } = props;
  const [open, setOpen] = useState(props.open);
  const [rowData, setRowData] = useState(props.rowData);
  const [newEmail, setNewEmail] = useState(null);
  const [newLevel, setNewLevel] = useState(null);
  const [newType, setNewType] = useState(null);
  const [newStatus, setNewStatus] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(true);
  const [show1, setShow1] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [emailChanges, setEmailChanges] = useState(false);
  const [levelChanges, setLevelChanges] = useState(false);
  const [typeChanges, setTypeChanges] = useState(false);
  const [statusChanges, setStatusChanges] = useState(false);

  useEffect(() => {
    setOpen(props.open);
    setRowData(props.data);

    if (rowData) {
      if (
        rowData?.level === admin.level &&
        rowData?._id !== admin._id &&
        admin.level !== 1
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [props.open, rowData]);

  useEffect(() => {
    if (levelChanges || typeChanges || statusChanges) {
      setUpdating(true);
    } else {
      setUpdating(false);
    }
  }, [levelChanges, typeChanges, statusChanges]);

  const handleClose = () => {
    setShow(true);
    setShow1(false);
    setUpdating(false);
    props.parentMethod();
  };

  const handleOpen = () => {
    setShow(false);
    setShow1(true);
  };

  const emailHandler = (e) => {
    if (rowData?.email == e.target.value || e.target.value == "") {
      setUpdating(false);
    } else {
      setUpdating(true);
      setEmailChanges(true);
    }

    setNewEmail(e.target.value);
  };

  const levelHandler = (e) => {
    if (rowData?.level == e.target.value) {
      setLevelChanges(false);
    } else {
      setLevelChanges(true);
      setNewLevel(e.target.value);
    }
  };

  const typeHandler = (e) => {
    if (rowData?.type == e.target.value) {
      setTypeChanges(false);
    } else {
      setTypeChanges(true);
      setNewType(e.target.value);
    }
  };

  const statusHandler = (e) => {
    if (rowData?.active == (e.target.value === "Non-Active" ? false : true)) {
      setStatusChanges(false);
    } else {
      setStatusChanges(true);
      setNewStatus(e.target.value === "Non-Active" ? false : true);
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (emailChanges) {
      updateAdminEmail({
        id: rowData._id,
        email: rowData.email,
        level: rowData.level,
        changes: {
          email: newEmail !== null ? newEmail : rowData.email,
          level: Number(newLevel !== null ? newLevel : rowData.level),
        },
      });
    } else {
      updateAdmin({
        id: rowData._id,

        active: rowData.active,
        level: rowData.level,
        type: rowData.type,
        changes: {
          active: newStatus !== null ? newStatus : rowData.active,
          level: Number(newLevel !== null ? newLevel : rowData.level),
          type: newType !== null ? newType : rowData.type,
        },
      });
    }

    setShow(true);
    setShow1(false);
    setUpdating(false);
    setEmailChanges(false);
    setLevelChanges(false);
    setTypeChanges(false);
    setStatusChanges(false);
    props.parentMethod();
  };

  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={handleClose}
      >
        <form type="post" onSubmit={handleUpdate}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Update Admin
          </DialogTitle>
          <DialogContent dividers>
            {disabled ? (
              <>
                <TextField
                  variant="outlined"
                  autoFocus
                  margin="dense"
                  id="email"
                  label="Email"
                  type="email"
                  defaultValue={rowData?.email}
                  onChange={emailHandler}
                  fullWidth
                />
                <Select
                  id="demo-simple-select-outlined"
                  variant="outlined"
                  name="level"
                  fullWidth
                  defaultValue={rowData?.level}
                  onChange={levelHandler}
                  label="Level"
                  style={{ marginTop: "1rem", height: "2.5rem" }}
                  disabled={true}
                >
                  <MenuItem value={1}>Level 1</MenuItem>
                  <MenuItem value={2}>Level 2</MenuItem>
                  <MenuItem value={3}>Level 3</MenuItem>
                  <MenuItem value={4}>Level 4</MenuItem>
                </Select>
                <Select
                  id="demo-simple-select-outlined"
                  variant="outlined"
                  name="type"
                  fullWidth
                  defaultValue={rowData?.type}
                  onChange={typeHandler}
                  label="Type"
                  style={{ marginTop: "1rem", height: "2.5rem" }}
                  disabled={true}
                >
                  <MenuItem value={"Cogito"}>Cogito</MenuItem>
                  <MenuItem value={"Foundation Investor"}>
                    Foundation Investor
                  </MenuItem>
                  <MenuItem value={"Content Creator"}>Content Creator</MenuItem>
                  <MenuItem value={"Finance"}>Finance</MenuItem>
                  <MenuItem value={"Legal"}>Legal</MenuItem>
                  <MenuItem value={"Help Desk"}>Help Desk</MenuItem>
                </Select>
                <Select
                  id="demo-simple-select-outlined"
                  variant="outlined"
                  name="active"
                  fullWidth
                  defaultValue={
                    rowData?.active == false ? "Non-Active" : "Active"
                  }
                  onChange={statusHandler}
                  label="Admin Status"
                  style={{ marginTop: "1rem", height: "2.5rem" }}
                  disabled={true}
                >
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Non-Active"}>Non-Active</MenuItem>
                </Select>
              </>
            ) : (
              <>
                <>
                  <TextField
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email"
                    type="email"
                    defaultValue={rowData?.email}
                    onChange={emailHandler}
                    style={{ width: "80%" }}
                    disabled={show}
                  />
                  <IconButton
                    size="small"
                    onClick={handleOpen}
                    style={{
                      color: "#ff7300",
                      marginLeft: "20px",
                      marginTop: "12px",
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </>

                <Select
                  id="demo-simple-select-outlined"
                  variant="outlined"
                  name="level"
                  fullWidth
                  defaultValue={rowData?.level}
                  onChange={levelHandler}
                  label="Level"
                  style={{ marginTop: "1rem", height: "2.5rem" }}
                  disabled={show1}
                >
                  <MenuItem value={1}>Level 1</MenuItem>
                  <MenuItem value={2}>Level 2</MenuItem>
                  <MenuItem value={3}>Level 3</MenuItem>
                  <MenuItem value={4}>Level 4</MenuItem>
                </Select>
                <Select
                  id="demo-simple-select-outlined"
                  variant="outlined"
                  name="type"
                  fullWidth
                  defaultValue={rowData?.type}
                  onChange={typeHandler}
                  label="Type"
                  style={{ marginTop: "1rem", height: "2.5rem" }}
                  disabled={show1}
                >
                  <MenuItem value={"Cogito"}>Cogito</MenuItem>
                  <MenuItem value={"Foundation Investor"}>
                    Foundation Investor
                  </MenuItem>
                  <MenuItem value={"Content Creator"}>Content Creator</MenuItem>
                  <MenuItem value={"Finance"}>Finance</MenuItem>
                  <MenuItem value={"Legal"}>Legal</MenuItem>
                  <MenuItem value={"Help Desk"}>Help Desk</MenuItem>
                </Select>
                <Select
                  id="demo-simple-select-outlined"
                  variant="outlined"
                  name="active"
                  fullWidth
                  defaultValue={
                    rowData?.active == false ? "Non-Active" : "Active"
                  }
                  onChange={statusHandler}
                  label="Admin Status"
                  style={{ marginTop: "1rem", height: "2.5rem" }}
                  disabled={show1}
                >
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Non-Active"}>Non-Active</MenuItem>
                </Select>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleClose}
              style={{
                backgroundColor: "#359DB6",
                color: "#FFFFFF",
              }}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="contained"
              // style={{
              //   backgroundColor: "#359DB6",
              //   color: "#FFFFFF",
              // }}

              style={{
                backgroundColor: updating ? "#359DB6" : "#ccc",
                color: "#FFFFFF",
              }}
              autoFocus
              disabled={!updating}
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({ adminReducer }) => {
  return {
    admin: adminReducer.admin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateAdmin: (data) => dispatch(actionCreator.updateAdmin(data)),
    updateAdminEmail: (data) => dispatch(actionCreator.updateAdminEmail(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizedDialogs);
