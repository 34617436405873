import React, { forwardRef, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import * as d3 from "d3";

const useStyles = makeStyles(() => ({
  container: {
    padding: "0.5rem 0.5rem",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  labelContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "1rem 0",
  },
  dataPoint: {
    display: "flex",
    margin: "0 0.5rem",
  },
  colourLabel: {
    height: "1rem",
    width: "1rem",
    marginRight: "0.3rem",
    border: "0.1rem solid #ddd",
  },
  label: {
    color: "#fff",
    fontSize: "0.7rem",
    fontWeight: 600,
  },
}));

function LineChart(props) {
  const { height, width, data, showX, showY, bottomText } = props;
  const axesRef = React.useRef(null);

  // For each arc, use the path generator
  const MARGIN = { top: 30, right: 30, bottom: 50, left: 50 };

  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;

  const [min, max] = d3.extent(data, (d) => d.y);

  const yScale = React.useMemo(() => {
    return d3
      .scaleLinear()
      .domain([0, max || 0])
      .range([boundsHeight, 0]);
  }, [data, height]);

  const [xMin, xMax] = d3.extent(data, (d) => d.x);
  const xScale = React.useMemo(() => {
    return d3
      .scaleLinear()
      .domain([data?.[0]?.x || 0, xMax || 0])
      .range([0, boundsWidth]);
  }, [data, height]);
  const arcs = React.useMemo(() => {
    const lineBuilder = d3
      .line()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y));

    const linePath = lineBuilder(data);
    return (
      <>
        <g
          width={boundsWidth}
          height={boundsHeight}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        >
          <path
            d={linePath}
            opacity={1}
            stroke="#9a6fb0"
            fill="none"
            strokeWidth={2}
          />
        </g>
        <g
          width={boundsWidth}
          height={boundsHeight}
          ref={axesRef}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        />
      </>
    );
  }, [xScale, yScale]);

  const classes = useStyles();

  useEffect(() => {
    const svgElement = d3.select(axesRef.current);
    svgElement.selectAll("*").remove();
    if (showX) {
      const xAxisGenerator = d3.axisBottom(xScale);
      svgElement
        .append("g")
        .attr("transform", "translate(0," + boundsHeight + ")")
        .call(xAxisGenerator);
    }
    if (showY) {
      const yAxisGenerator = d3.axisLeft(yScale);
      svgElement.append("g").call(yAxisGenerator);
    }
  }, [xScale, yScale, boundsHeight]);

  return (
    <Box className={classes.container}>
      <svg width={width} height={height} style={{ display: "inline-block" }}>
        {arcs}
      </svg>
      {bottomText ? bottomText : null}
    </Box>
  );
}

LineChart.defaultProps = {
  height: 300,
  width: 500,
  showX: true,
  showY: true,
  data: [
    // { x: 100, y: 90 },
    // { x: 101, y: 12 },
    // { x: 105, y: 34 },
    // { x: 107, y: 53 },
    // { x: 108, y: 52 },
    // { x: 109, y: 9 },
    // { x: 110, y: 78 },
    // { x: 111, y: 28 },
    // { x: 112, y: 34 },
    // { x: 113, y: 18 },
  ],
  bottomText: "",
};

export default LineChart;
