import React, { useEffect, useState } from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreator from "../../../../store/action/index";
import Condition from "../escrow/Condition";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "1rem",
    overflow: "hidden",
  },
  subHeadStyle: {},
  contentStyle: {},
}));

const UserEscrowTransaction = (props) => {
  const { escrowTransaction, user } = props;

  const classes = useStyles();

  const [conditions, setConditions] = useState([]);

  const getAccountType = (type) => {
    switch (type) {
      case 1:
        return "COG";
      case 3:
        return "EURO";
      case 5:
        return "CBC";
      default:
        return "NA";
    }
  };

  useEffect(() => {
    let buyerCondition =
      escrowTransaction?.buyer_conditions?.map((data) => {
        let temp = data;
        temp.type = "Buyer";
        if (escrowTransaction?.seller_profile_info) {
          temp.user = {
            Email: user?.email,
            FirstName: user?.firstName,
            LastName: user?.lastName,
            ProfileID: user?._id,
            UserName: user?.userName,
            isMinor: user?.isMinor,
          };
        } else {
          temp.user = escrowTransaction?.buyer_profile_info;
        }
        return temp;
      }) || [];

    let sellerCondition =
      escrowTransaction?.seller_conditions?.map((data) => {
        let temp = data;
        temp.type = "Seller";
        if (escrowTransaction?.seller_profile_info) {
          temp.user = escrowTransaction?.seller_profile_info;
        } else {
          temp.user = {
            Email: user?.email,
            FirstName: user?.firstName,
            LastName: user?.lastName,
            ProfileID: user?._id,
            UserName: user?.userName,
            isMinor: user?.isMinor,
          };
        }
        return temp;
      }) || [];

    let allConditions = [...(buyerCondition || []), ...(sellerCondition || [])];
    console.log("allConditions: ", allConditions);
    setConditions(allConditions);
  }, [escrowTransaction, user]);

  return (
    <Box className={classes.container}>
      {escrowTransaction ? (
        <Grid container xs={12} spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              align="left"
              className={classes.subHeadStyle}
            >
              Escrow Id:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography
              variant="h6"
              color="textSecondary"
              align="left"
              className={classes.contentStyle}
            >
              {escrowTransaction.escrow_id}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              align="left"
              className={classes.subHeadStyle}
            >
              Title:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography
              variant="h6"
              color="textSecondary"
              align="left"
              className={classes.contentStyle}
            >
              {escrowTransaction.title || "NA"}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              align="left"
              className={classes.subHeadStyle}
            >
              Description:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography
              variant="h6"
              color="textSecondary"
              align="left"
              className={classes.contentStyle}
            >
              {escrowTransaction.description
                ? escrowTransaction.description
                : "NA"}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              align="left"
              className={classes.subHeadStyle}
            >
              Account Type:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography
              variant="h6"
              color="textSecondary"
              align="left"
              className={classes.contentStyle}
            >
              {escrowTransaction.account_type_id
                ? getAccountType(escrowTransaction.account_type_id)
                : "NA"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              align="left"
              className={classes.subHeadStyle}
            >
              {escrowTransaction.seller_profile_info
                ? "Seller Info"
                : "Buyer Info"}
              :
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8}>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                color="textSecondary"
                align="left"
                className={classes.contentStyle}
              >
                Username:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                color="textSecondary"
                align="left"
                className={classes.contentStyle}
              >
                {
                  (
                    escrowTransaction.seller_profile_info ||
                    escrowTransaction.buyer_profile_info
                  )?.UserName
                }
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="h6"
                color="textSecondary"
                align="left"
                className={classes.contentStyle}
              >
                First Name:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                color="textSecondary"
                align="left"
                className={classes.contentStyle}
              >
                {
                  (
                    escrowTransaction.seller_profile_info ||
                    escrowTransaction.buyer_profile_info
                  )?.FirstName
                }
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="h6"
                color="textSecondary"
                align="left"
                className={classes.contentStyle}
              >
                Last Name:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                color="textSecondary"
                align="left"
                className={classes.contentStyle}
              >
                {
                  (
                    escrowTransaction.seller_profile_info ||
                    escrowTransaction.buyer_profile_info
                  )?.LastName
                }
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="h6"
                color="textSecondary"
                align="left"
                className={classes.contentStyle}
              >
                Email
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                color="textSecondary"
                align="left"
                className={classes.contentStyle}
              >
                {
                  (
                    escrowTransaction.seller_profile_info ||
                    escrowTransaction.buyer_profile_info
                  )?.Email
                }
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              align="left"
              className={classes.subHeadStyle}
            >
              Status:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography
              variant="h6"
              color="textSecondary"
              align="left"
              className={classes.contentStyle}
            >
              {escrowTransaction.status ? escrowTransaction.status : "NA"}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              align="left"
              className={classes.subHeadStyle}
            >
              Amount:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography
              variant="h6"
              color="textSecondary"
              align="left"
              className={classes.contentStyle}
            >
              {escrowTransaction.amount ? escrowTransaction.amount : "NA"}
            </Typography>
          </Grid>

          {conditions?.length ? (
            <>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  align="left"
                  className={classes.subHeadStyle}
                >
                  Conditions:
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                <Grid container spacing={2}>
                  {conditions?.map((data, index) => (
                    <Grid item xs={12} key={index}>
                      <Condition conditionData={data} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
      ) : null}
    </Box>
  );
};
const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    user: adminReducer.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserEscrowTransaction);
