import React, { forwardRef, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as actionCreator from "../../store/action/index";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import StatsFrame from "./stats/StatsFrame";
import PieChart from "./stats/PieChart";
import LineChart from "./stats/LineChart";
import moment from "moment";
import CrFormStats from "./stats/CrFormStats";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8) + 1,
    },
    borderRadius: "15px",
    "&.MuiToolbar-root": {
      color: theme.palette.background.default,
    },
    "& .MuiTable-root": {
      "& .MuiTableRow-root": {
        "&:nth-child(even)": {
          background: theme.palette.background.paper,
        },
        "&:nth-child(odd)": {
          background: theme.palette.background.default,
        },
      },
    },
  },
  container: {
    backgroundColor: theme.palette.background.default,
    padding: "2rem",
    alignItems: "space-between",
    flexDirection: "row",
    marginTop: "25px",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
  },
  title: {
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: 600,
  },
}));

function Stats(props) {
  const { getStats, cogitoStats } = props;

  const classes = useStyles();

  const [stocksPie, setStocksPie] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [uriPie, setUriPie] = useState([]);
  const [uriGraph, seUriGraph] = useState([]);

  useEffect(() => {
    getStats();
  }, []);

  const getDate = (date) => {
    let newDate = new Date(date);
    newDate.setHours(0);
    newDate.setMilliseconds(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    return newDate.getTime();
  };

  useEffect(() => {
    let stocksData = { signed: 0, unsigned: 0 };
    let uriData = { signed: 0, unsigned: 0 };
    let uriGraphData = [];
    let count = 0;
    let uriCount = 0;
    (cogitoStats?.users || [])
      .sort(
        (a, b) =>
          new Date(a.uri.created_at || 0).getTime() -
          new Date(b.uri.created_at || 0).getTime()
      )
      .map((userData, index) => {
        count += 1;
        if (userData?.stockId) {
          stocksData["signed"] += 1;
        } else {
          stocksData["unsigned"] += 1;
        }
        if (userData?.uri?.id) {
          uriData["signed"] += 1;
          uriCount += 1;
          uriGraphData.push({
            x: new Date(userData?.uri?.created_at).getTime(),
            y: uriCount,
          });
        } else {
          uriData["unsigned"] += 1;
        }
      });
    setStocksPie([
      { name: "Registered user", value: stocksData.signed },
      { name: "Non-registered user", value: stocksData.unsigned },
    ]);
    setUriPie([
      { name: "User Opted URI", value: uriData.signed },
      { name: "User not Opted URI", value: uriData.unsigned },
    ]);
    seUriGraph(() => {
      if (uriGraphData?.length == 0) {
        return [];
      }
      let result = [{ x: getDate(uriGraphData[0].x), y: uriGraphData[0].y }];

      uriGraphData.map((date, index) => {
        if (index) {
          let a = getDate(date.x);
          let b = getDate(result[result.length - 1].x);
          if (a == b) {
            result[result.length - 1].y = date.y;
          } else {
            result.push({ x: a, y: date.y });
          }
        }
      });
      let currentDate = getDate(new Date());
      if (currentDate != result[result.length - 1].x) {
        result.push({ x: currentDate, y: result[result.length - 1].y });
      }
      return result;
    });

    setTotalUsers(count);
  }, [cogitoStats]);

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <Box className={classes.container}>
          <Typography className={classes.title}>Cogito Statistics</Typography>
          <CrFormStats />
          <StatsFrame title={"URI"}>
            <Box style={{ display: "flex", justifyContent: "space-around" }}>
              <LineChart
                data={uriGraph}
                showX={false}
                bottomText={
                  uriGraph?.length
                    ? `${moment(uriGraph[0].x).format("DD.M.YYYY")} - ${moment(
                        uriGraph[uriGraph.length - 1].x
                      ).format("DD.MM.YYYY")}`
                    : ""
                }
              />
              <PieChart data={uriPie} width={170} height={170} />
            </Box>
          </StatsFrame>
          <StatsFrame title={"Stocks"}>
            <PieChart data={stocksPie} />
          </StatsFrame>
        </Box>
      </Box>
    </React.Fragment>
  );
}

const mapStateToProps = ({ adminReducer }) => {
  return {
    cogitoStats: adminReducer.cogitoStats,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStats: () => dispatch(actionCreator.getStats()),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
