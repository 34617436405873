import React, { useState, useEffect, useRef } from "react";
import { Rnd } from "react-rnd";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Input,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
  Button,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  Title as TitleIcon,
  TextFields as TextFieldsIcon,
  Image as ImageIcon,
  VideoLibrary as VideoLibraryIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Link as LinkIcon,
  FormatListBulleted as FormatListBulletedIcon,
  FormatListNumbered as FormatListNumberedIcon,
  Delete as DeleteIcon,
  FormatBold as FormatBoldIcon,
  FormatItalic as FormatItalicIcon,
  FormatUnderlined as FormatUnderlinedIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  modalContent: {
    // Your styles for modal content here
    width: "100%",
    height: "100%",
  },
  editorContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    fontFamily: "Arial, sans-serif",
    width: "100%",
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "10px",
    boxSizing: "border-box",
  },
  toolbarContainer: {
    width: "70%",
    backgroundColor: "#2c3e50",
    color: "white",
    // padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
  },
  toolbarOptions: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  toolbarButton: {
    color: "white",
    transition: "color 0.3s ease",
    "&:hover": {
      color: "#1abc9c",
    },
  },
  toolbarButtonActive: {
    color: "#1abc9c",
  },
  editorArea: {
    flexGrow: 1,
    padding: "20px",
    // backgroundColor: "#f5f5f5",
    position: "relative",
    width: "100%",
    maxWidth: "960px",
    boxSizing: "border-box",
    margin: "0 auto",
    minHeight: "90vh", // Initial height
    height: "auto", // Allows height to adjust according to content
    overflowY: "visible", // Ensures no scroll, height adjusts with content
  },
  increaseHeightBox: {
    display: "flex",
    justifyContent: "center",
    padding: "20px",
    // background: "#ECF0F1",
  },
  increaseHeightIcon: {
    // position: "absolute",
    // bottom: "20px",
    // right: "20px",
    width: "100px",
    fontSize: "36px",
    color: "#2c3e50", // Darker shade for contrast against #ECF0F1
    cursor: "pointer",
    zIndex: 10,
    textAlign: "right",
  },
  selectedElement: {
    border: "2px solid #00bcd4",
    borderRadius: "4px",
    padding: "5px",
    boxSizing: "border-box",
  },
  previewArea: {
    flexGrow: 1,
    padding: "0",
    backgroundColor: "#ECF0F1",
    overflow: "hidden",
    width: "100%",
    maxWidth: "960px",
    boxSizing: "border-box",
    margin: "0 auto",
    minHeight: "100vh",
  },
  iframePreview: {
    width: "100%",
    height: "100%",
    border: "none",
    backgroundColor: "white",
    display: "block",
    minHeight: "150vh",
  },
  exportButtonContainer: {
    textAlign: "center",
    marginTop: "20px",
  },
  exportButton: {
    backgroundColor: "#1abc9c",
    color: "white",
    "&:hover": {
      backgroundColor: "#16a085",
    },
  },
  colorPicker: {
    padding: "5px",
    borderRadius: "4px",
    backgroundColor: "white",
    border: "1px solid #ccc",
  },
}));

const ELEMENT_TYPES = {
  HEADING: "h1",
  TEXT: "p",
  IMAGE: "img",
  VIDEO: "video",
  DOCUMENT: "document",
  LINK: "a",
  BULLET_LIST: "ul",
  NUMBERED_LIST: "ol",
};

const TemplateMakerPage = ({
  UploadDocs,
  notify,
  rowData,
  createStockTemplate,
  getStockTemplate,
  stockTemplate,
  updateStockTemplate,
  setHtmlContent,
  htmlContent,
  isExported,
}) => {
  const classes = useStyles();
  const [elements, setElements] = useState([]);
  const [selectedElementId, setSelectedElementId] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState("#ecf0f1");
  const [uploading, setUploading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const handleTabChange = (event, newValue) => {
    if (newValue === 1) {
      // Open modal for "Preview" tab
      setOpenModal(true);
    }
    setTabValue(newValue);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setTabValue(0);
  };

  useEffect(() => {
    if (rowData?.stockId) {
      const data = {
        id: "",
        stockId: rowData.stockId,
        createdBy: "",
        lastUpdatedBy: "",
        type: null,
        isArchived: false,
      };
      getStockTemplate(data);
    }
  }, [rowData, getStockTemplate]);

  useEffect(() => {
    if (isExported) {
      // setTabValue(0);
      // setHtmlContent(generateHtmlContent());
      setSelectedElementId(null);
    }
  }, [isExported]);

  useEffect(() => {
    if (stockTemplate) {
      console.log("Stock Template Data:", stockTemplate);

      if (
        Array.isArray(stockTemplate) &&
        stockTemplate.length > 0 &&
        stockTemplate[0].template
      ) {
        const template = stockTemplate[0].template;
        console.log("Template received:", template); // Debug log
        // Only show the template in preview if not updating
        if (!isUpdating) {
          setTabValue(1); // Switch to Preview Mode
        }
      } else {
        console.log("No template received or template is empty.");
      }
    }
  }, [stockTemplate, isUpdating]);

  const handleUpdateTemplate = () => {
    setIsUpdating(true);
    setTabValue(0); // Switch to Edit Mode
  };

  const handleCreateTemplate = () => {
    if (elements.length > 0) {
      createStockTemplate({
        stockId: rowData.stockId,
        template: generateHtmlContent(),
      }).then(() => {
        // Refresh the page after successful creation
        window.location.reload();
      });
    }
  };

  const generateId = () => "_" + Math.random().toString(36).substr(2, 9);

  const getSelectedElement = () =>
    elements.find((element) => element.id === selectedElementId);

  const handleStyleChange = (key, value) => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === selectedElementId
          ? { ...element, styles: { ...element.styles, [key]: value } }
          : element
      )
    );
  };

  const handleAddElement = (type) => {
    switch (type) {
      case ELEMENT_TYPES.IMAGE:
      case ELEMENT_TYPES.VIDEO:
      case ELEMENT_TYPES.DOCUMENT: {
        const choice = window.prompt(
          `Enter '1' to upload a ${type} from your device or '2' to provide a ${type} URL:`
        );

        if (choice === "1") {
          const input = document.createElement("input");
          input.type = "file";
          input.accept =
            type === ELEMENT_TYPES.VIDEO
              ? "video/*"
              : type === ELEMENT_TYPES.DOCUMENT
              ? "application/*"
              : "image/*";
          input.onchange = async (e) => {
            const file = e.target.files[0];
            uploadMedia(type, file);
          };
          input.click();
        } else if (choice === "2") {
          const fileUrl = window.prompt(`Please enter the ${type} URL:`);
          if (fileUrl) {
            addElementToEditor(type, fileUrl);
          } else {
            notify(`${type} URL cannot be empty`, "warning");
          }
        } else {
          notify("Invalid choice. Please enter '1' or '2'.", "warning");
        }
        break;
      }
      case ELEMENT_TYPES.BULLET_LIST:
      case ELEMENT_TYPES.NUMBERED_LIST:
        addElementToEditor(type, "", ["Item 1"]);
        break;
      case ELEMENT_TYPES.LINK:
        const linkUrl = window.prompt("Please enter the link URL:");
        const linkText = window.prompt("Please enter the link text:");
        if (linkUrl && linkText) {
          addElementToEditor(type, linkUrl, [], linkText);
        } else {
          notify("Link URL and text cannot be empty", "warning");
        }
        break;
      default:
        addElementToEditor(type);
    }
  };

  const uploadMedia = (type, file) => {
    const MAX_FILE_SIZE = 5 * 1024 * 1024;

    if (file.size > MAX_FILE_SIZE) {
      notify("File size should be less than 5MB", "error");
      return;
    }

    const path = "stockTemplates";
    setUploading(true);

    UploadDocs(file, path)
      .then((res) => {
        const { status, errorMessage, data } = res.data;
        if (status) {
          const uploadedDocUrl = data?.uploaded_docs_url[0];
          const fullUrl = `${process.env.REACT_APP_SPACE_LINK}/${uploadedDocUrl}`;
          addElementToEditor(type, fullUrl);
          notify("File uploaded successfully", "success");
        } else {
          notify(errorMessage, "error");
        }
      })
      .catch((err) => {
        let errorMessage =
          err?.response?.data?.error?.errorMessage ||
          err?.response?.data?.error;
        notify(
          typeof errorMessage === "string"
            ? errorMessage
            : "Error while uploading file!!!",
          "error"
        );
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const addElementToEditor = (type, url = "", items = [], text = "") => {
    const editorArea = document.getElementById(`editorArea`);
    const lastElement = editorArea?.lastElementChild;

    let yPos = 10; // Default Y position if there are no elements

    if (lastElement) {
      const lastElementRect = lastElement.getBoundingClientRect();
      yPos =
        lastElementRect.bottom - editorArea.getBoundingClientRect().top + 10;
    }

    const newElement = {
      id: generateId(),
      type,
      content: {
        text,
        url,
        items,
      },
      styles: {
        fontSize: "16px",
        color: "#000000",
        textAlign: "left",
        width: "200px",
        height: "auto",
        position: "absolute",
        backgroundColor: "transparent",
        borderRadius: "0px",
        border: "none",
        fontWeight: "normal",
        fontStyle: "normal",
        textDecoration: "none",
      },
      position: { x: 10, y: yPos },
    };

    setElements((prevElements) => [...prevElements, newElement]);
    setSelectedElementId(newElement.id);
  };

  const handleDragResize = (id, position, size) => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === id
          ? {
              ...element,
              position,
              styles: {
                ...element.styles,
                width: size.width,
                height: size.height,
              },
            }
          : element
      )
    );
  };

  const handleSelectElement = (id) => setSelectedElementId(id);

  const handleElementChange = (key, value) => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === selectedElementId
          ? { ...element, content: { ...element.content, [key]: value } }
          : element
      )
    );
  };

  const handleListChange = (index, value) => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === selectedElementId
          ? {
              ...element,
              content: {
                ...element.content,
                items: element.content.items.map((item, i) =>
                  i === index ? value : item
                ),
              },
            }
          : element
      )
    );
  };

  const handleAddListItem = () => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === selectedElementId
          ? {
              ...element,
              content: {
                ...element.content,
                items: [
                  ...element.content.items,
                  `Item ${element.content.items.length + 1}`,
                ],
              },
            }
          : element
      )
    );
  };

  const handleDeleteElement = () => {
    setElements((prevElements) =>
      prevElements.filter((element) => element.id !== selectedElementId)
    );
    setSelectedElementId(null);
  };

  const renderElement = (element) => {
    const ElementTag = element.type === "p" ? "div" : element.type;

    return (
      <Rnd
        key={element.id}
        size={{ width: element.styles.width, height: element.styles.height }}
        position={element.position}
        onDragStop={(e, d) =>
          handleDragResize(
            element.id,
            { x: d.x, y: d.y },
            { width: element.styles.width, height: element.styles.height }
          )
        }
        onResizeStop={(e, direction, ref, delta, position) =>
          handleDragResize(element.id, position, {
            width: ref.style.width,
            height: ref.style.height,
          })
        }
        bounds="parent"
        className={
          element.id === selectedElementId ? classes.selectedElement : ""
        }
        style={{
          ...element.styles,
          border: element.id === selectedElementId ? "2px solid #3498db" : "",
          borderRadius: "4px",
        }}
        onClick={() => handleSelectElement(element.id)}
      >
        {element.type === "img" ? (
          <img
            src={element.content.url}
            alt="Added element"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: element.styles.borderRadius,
              border: element.styles.border,
            }}
          />
        ) : element.type === "video" ? (
          <video controls style={{ width: "100%", height: "100%" }}>
            <source src={element.content.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : element.type === "document" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              textAlign: element.styles.textAlign,
            }}
          >
            <InsertDriveFileIcon
              onClick={() => window.open(element.content.url, "_blank")}
              style={{
                marginRight: "8px",
                color: "#2962ff",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "4px",
                padding: "4px",
                backgroundColor: "#ffffff",
                cursor: "pointer",
              }}
            />
            <span
              contentEditable
              suppressContentEditableWarning
              onBlur={(e) => handleElementChange("name", e.target.innerText)}
              style={{
                color: "#2962ff",
                textDecoration: "underline",
                cursor: "text",
              }}
            >
              {element.content.name || "Document"}
            </span>
          </div>
        ) : element.type === ELEMENT_TYPES.BULLET_LIST ? (
          <ul
            style={{
              width: "100%",
              height: "100%",
              padding: "5px",
              listStyleType: "disc",
              margin: "0",
            }}
          >
            {element.content.items.map((item, index) => (
              <li
                key={index}
                contentEditable
                suppressContentEditableWarning
                onBlur={(e) => handleListChange(index, e.target.innerText)}
              >
                {item}
              </li>
            ))}
            <li
              contentEditable={false}
              style={{ color: "#3498db", cursor: "pointer" }}
              onClick={handleAddListItem}
            >
              + Add Item
            </li>
          </ul>
        ) : element.type === ELEMENT_TYPES.NUMBERED_LIST ? (
          <ol
            style={{
              width: "100%",
              height: "100%",
              padding: "5px",
              listStyleType: "decimal",
              margin: "0",
            }}
          >
            {element.content.items.map((item, index) => (
              <li
                key={index}
                contentEditable
                suppressContentEditableWarning
                onBlur={(e) => handleListChange(index, e.target.innerText)}
              >
                {item}
              </li>
            ))}
            <li
              contentEditable={false}
              style={{ color: "#3498db", cursor: "pointer" }}
              onClick={handleAddListItem}
            >
              + Add Item
            </li>
          </ol>
        ) : element.type === ELEMENT_TYPES.LINK ? (
          <a
            href={element.content.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: element.styles.color,
              textDecoration: element.styles.textDecoration,
              fontSize: element.styles.fontSize,
              fontWeight: element.styles.fontWeight,
              fontStyle: element.styles.fontStyle,
            }}
          >
            {element.content.text}
          </a>
        ) : (
          <ElementTag
            contentEditable
            suppressContentEditableWarning
            style={{
              width: "100%",
              height: "100%",
              outline: "none",
              padding: "5px",
              boxSizing: "border-box",
            }}
            // onBlur={(e) => handleElementChange("text", e.target.innerText)}
          >
            {element.content.text}
          </ElementTag>
        )}
      </Rnd>
    );
  };

  const generateHtmlContent = () => {
    return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Generated HTML</title>
      </head>
      <body style="background-color: ${backgroundColor}; font-family: Arial, sans-serif; max-width: 960px; margin: 0 auto; padding: 10px; position: relative;">
        ${elements
          .map((element) => {
            const inlineStyles = `
              position: absolute;
              top: ${element.position.y}px;
              left: ${element.position.x}px;
              width: ${element.styles.width};
              height: ${element.styles.height};
              font-size: ${element.styles.fontSize};
              color: ${element.styles.color};
              text-align: ${element.styles.textAlign};
              background-color: ${
                element.styles.backgroundColor || "transparent"
              };
              font-weight: ${element.styles.fontWeight || "normal"};
              font-style: ${element.styles.fontStyle || "normal"};
              text-decoration: ${element.styles.textDecoration || "none"};
              border-radius: ${element.styles.borderRadius};
              border: ${element.styles.border};
              padding: 5px;
              box-sizing: border-box;
            `;

            if (element.type === "img") {
              return `<div style="${inlineStyles}"><img src="${element.content.url}" style="width: 100%; height: 100%; border-radius: ${element.styles.borderRadius}; border: ${element.styles.border};" alt="Image Element"/></div>`;
            } else if (element.type === "video") {
              return `<div style="${inlineStyles}"><video controls style="width: 100%; height: 100%; border-radius: ${element.styles.borderRadius}; border: ${element.styles.border};"><source src="${element.content.url}" type="video/mp4"/>Your browser does not support the video tag.</video></div>`;
            } else if (element.type === "document") {
              return `<div style="${inlineStyles} display: flex; align-items: center;" onclick="window.open('${
                element.content.url
              }', '_blank')">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px" fill="#2962ff">
                          <path d="M0 0h24v24H0z" fill="none"/><path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/>
                        </svg>
                        <span style="color: #2962ff; text-decoration: underline; margin-left: 8px;">${
                          element.content.name || "Document"
                        }</span>
                      </div>`;
            } else if (element.type === ELEMENT_TYPES.BULLET_LIST) {
              return `<div style="${inlineStyles}"><ul style="padding-left: 20px; margin: 0; list-style-position: inside;">
                        ${element.content.items
                          .map(
                            (item) =>
                              `<li style="background-color: ${
                                element.styles.backgroundColor || "transparent"
                              }; padding: 5px; border-radius: ${
                                element.styles.borderRadius
                              };">${item}</li>`
                          )
                          .join("")}
                      </ul></div>`;
            } else if (element.type === ELEMENT_TYPES.NUMBERED_LIST) {
              return `<div style="${inlineStyles}"><ol style="padding-left: 20px; margin: 0; list-style-position: inside;">
                        ${element.content.items
                          .map(
                            (item) =>
                              `<li style="background-color: ${
                                element.styles.backgroundColor || "transparent"
                              }; padding: 5px; border-radius: ${
                                element.styles.borderRadius
                              };">${item}</li>`
                          )
                          .join("")}
                      </ol></div>`;
            } else if (element.type === ELEMENT_TYPES.LINK) {
              return `<div style="${inlineStyles}"><a href="${element.content.url}" target="_blank" style="color: ${element.styles.color}; text-decoration: ${element.styles.textDecoration}; font-size: ${element.styles.fontSize}; font-weight: ${element.styles.fontWeight}; font-style: ${element.styles.fontStyle};">${element.content.text}</a></div>`;
            } else {
              return `<div style="${inlineStyles}"><${element.type} style="width: 100%; height: 100%; padding: 5px; box-sizing: border-box;">
                        ${element.content.text}
                      </${element.type}></div>`;
            }
          })
          .join("")}
      </body>
      </html>
    `;
  };

  return (
    <div className={classes.editorContainer} id="myOfferingDoc">
      <>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <AppBar
            position="static"
            className={classes.toolbarContainer}
            id="hideToolbar"
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="inherit"
            >
              <Tab label="Edit" />
              <Tab label="Preview" />
            </Tabs>
          </AppBar>
          <Dialog
            open={openModal}
            onClose={handleCloseModal}
            maxWidth="md"
            fullWidth
          >
            <DialogContent className={classes.modalContent}>
              {/* Your preview content goes here */}
              <div className={classes.previewArea}>
                <iframe
                  title="HTML Preview"
                  srcDoc={generateHtmlContent()}
                  className={classes.iframePreview}
                />
              </div>
            </DialogContent>
          </Dialog>
        </div>
        {tabValue === 0 && (
          <>
            <ToolbarComponent
              handleAddElement={handleAddElement}
              backgroundColor={backgroundColor}
              setBackgroundColor={setBackgroundColor}
              selectedElementId={selectedElementId}
              getSelectedElement={getSelectedElement}
              handleStyleChange={handleStyleChange}
              handleDeleteElement={handleDeleteElement}
            />
            <EditorArea
              elements={elements}
              selectedElementId={selectedElementId}
              setSelectedElementId={setSelectedElementId}
              renderElement={renderElement}
              backgroundColor={backgroundColor}
            />
          </>
        )}
      </>
    </div>
  );
};

const ToolbarComponent = ({
  handleAddElement,
  backgroundColor,
  setBackgroundColor,
  selectedElementId,
  getSelectedElement,
  handleStyleChange,
  handleDeleteElement,
}) => {
  const classes = useStyles();

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <AppBar
        position="static"
        className={classes.toolbarContainer}
        id="hideToolbar1"
      >
        <Toolbar className={classes.toolbarOptions}>
          <Tooltip title="Add Heading">
            <Button
              className={classes.toolbarButton}
              onClick={() => handleAddElement(ELEMENT_TYPES.HEADING)}
            >
              Add Heading
            </Button>
          </Tooltip>
          <Tooltip title="Add Paragraph">
            <Button
              className={classes.toolbarButton}
              onClick={() => handleAddElement(ELEMENT_TYPES.TEXT)}
            >
              Add Paragraph
            </Button>
          </Tooltip>
          {selectedElementId && getSelectedElement() && (
            <>
              <Tooltip title="Bold">
                <IconButton
                  className={
                    getSelectedElement().styles.fontWeight === "bold"
                      ? classes.toolbarButtonActive
                      : classes.toolbarButton
                  }
                  onClick={() =>
                    handleStyleChange(
                      "fontWeight",
                      getSelectedElement().styles.fontWeight === "bold"
                        ? "normal"
                        : "bold"
                    )
                  }
                >
                  <FormatBoldIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Italic">
                <IconButton
                  className={
                    getSelectedElement().styles.fontStyle === "italic"
                      ? classes.toolbarButtonActive
                      : classes.toolbarButton
                  }
                  onClick={() =>
                    handleStyleChange(
                      "fontStyle",
                      getSelectedElement().styles.fontStyle === "italic"
                        ? "normal"
                        : "italic"
                    )
                  }
                >
                  <FormatItalicIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Underline">
                <IconButton
                  className={
                    getSelectedElement().styles.textDecoration === "underline"
                      ? classes.toolbarButtonActive
                      : classes.toolbarButton
                  }
                  onClick={() =>
                    handleStyleChange(
                      "textDecoration",
                      getSelectedElement().styles.textDecoration === "underline"
                        ? "none"
                        : "underline"
                    )
                  }
                >
                  <FormatUnderlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Change Background Color">
                <input
                  type="color"
                  value={backgroundColor}
                  onChange={(e) => setBackgroundColor(e.target.value)}
                  className={classes.colorPicker}
                />
              </Tooltip>
              <Input
                type="number"
                value={getSelectedElement().styles.fontSize.replace("px", "")}
                onChange={(e) =>
                  handleStyleChange("fontSize", e.target.value + "px")
                }
                placeholder="Font Size"
                className={classes.input}
              />
              <FormControl className={classes.input}>
                <InputLabel>Text Alignment</InputLabel>
                <Select
                  value={getSelectedElement().styles.textAlign}
                  onChange={(e) =>
                    handleStyleChange("textAlign", e.target.value)
                  }
                >
                  <MenuItem value="left">Left</MenuItem>
                  <MenuItem value="center">Center</MenuItem>
                  <MenuItem value="right">Right</MenuItem>
                </Select>
              </FormControl>
              <Tooltip title="Delete Element">
                <IconButton
                  className={classes.toolbarButton}
                  onClick={handleDeleteElement}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

const EditorArea = ({
  elements,
  selectedElementId,
  renderElement,
  backgroundColor,
  setSelectedElementId,
}) => {
  const classes = useStyles();
  const editorAreaRef = useRef(null);

  const increaseHeight = () => {
    if (editorAreaRef.current) {
      const currentHeight = editorAreaRef.current.offsetHeight;
      editorAreaRef.current.style.height = `${currentHeight + 500}px`;
    }
  };

  // const handleClickOutside = (event) => {
  //   if (
  //     editorAreaRef.current &&
  //     !editorAreaRef.current.contains(event.target)
  //   ) {
  //     selectedElementId(null);
  //   }
  // };

  // useEffect(() => {
  //   // Add event listener for clicks outside
  //   document.addEventListener("mousedown", handleClickOutside);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  return (
    <>
      <div
        className={classes.editorArea}
        id="editorArea"
        style={{ backgroundColor }}
        ref={editorAreaRef}
      >
        {elements.map((element) => renderElement(element))}
      </div>
      <div className={classes.increaseHeightBox}>
        <Tooltip title="Increase height of editor area" arrow>
          <AddIcon
            className={classes.increaseHeightIcon}
            onClick={increaseHeight}
          />
        </Tooltip>
      </div>
    </>
  );
};

const mapStateToProps = ({ adminReducer }) => ({
  // stockTemplate: adminReducer.stockTemplates,
  // loadings: adminReducer.loadings,
});

const mapDispatchToProps = (dispatch) => ({
  // UploadDocs: (data, path) => dispatch(actionCreator.uploadDocs(data, path)),
  // notify: (message, variant) =>
  //   dispatch(actionCreator.notify(message, variant)),
  // getStockTemplate: (data) => dispatch(actionCreator.getStockTemplate(data)),
  // createStockTemplate: (data) =>
  //   dispatch(actionCreator.createStockTemplate(data)),
  // updateStockTemplate: (data) =>
  //   dispatch(actionCreator.updateStockTemplate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateMakerPage);
