import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import * as actionCreator from "../../../store/action/index";
import { Box, Button, Typography } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { DeleteOutline } from "@material-ui/icons";
import Spinner from "../../../components/layout/Spinner/Spinner";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "80%",
    maxWidth: 350,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
  },
  subTitle: {
    fontSize: 11,
    fontWeight: 600,
  },
  dropBoxContainer: {
    width: "96%",
    backgroundColor: "#144C59",
    borderRadius: 5,
    padding: 8,
    marginTop: 16,
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
  },
  fileContainer: {
    width: "96%",
    marginTop: 8,
  },
  fileBox: {
    padding: 8,
    marginTop: 4,
    width: "100%",
    backgroundColor: "#303030",
    border: "1px solid #8F8D8D",
    borderRadius: 5,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  deleteIcon: {
    color: "#FF7300",
    display: "flex",
    alignItems: "center",
  },
  fileBoxLeft: {
    display: "flex",
    alignItems: "center",
  },
  fileImg: {
    display: "flex",
    alignItems: "center",
  },
  fileName: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    fontWeight: 400,
    marginLeft: 5,
  },
}));

const ApprovalDocUpload = (props) => {
  const {
    approvalDetail,
    uploadApprovalDocs,
    deleteApprovalDocs,
    getApproval,
    notify,
  } = props;
  const classes = useStyles();
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const handleFileDelete = (files) => {
    deleteApprovalDocs({ fileUrls: files });
  };
  const Files = ({ file }) => {
    return (
      <Box className={classes.fileBox}>
        <Box className={classes.fileBoxLeft}>
          <Box className={classes.fileImg}>
            <img
              style={{ width: 50, height: 50 }}
              src={`${process.env.REACT_APP_SPACE_LINK}/${file.name}`}
            />
          </Box>
          <Box>
            <Typography className={classes.fileName}>{file.name}</Typography>
          </Box>
        </Box>
        <Box className={classes.deleteIcon}>
          <Button
            className={classes.deleteIcon}
            onClick={() => handleFileDelete([file.name])}
          >
            <DeleteOutline />
          </Button>
        </Box>
      </Box>
    );
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".png", ".jpeg", ".jpg"],
      "application/pdf": [],
    },
    maxSize: 5242880,
    onDrop: (acceptedFiles) => {
      uploadApprovalDocs({
        docs: acceptedFiles,
        approvalId: approvalDetail._id,
      });
    },
    onDropRejected: (files) => {
      if (files?.length) {
        files.map((data) => {
          let message = `${data?.file?.name || "File"}: ${
            data?.errors?.[0]?.message || "Error while uploading"
          }`;
          notify(message, "error");
        });
      }
    },
  });
  //   useEffect(() => {
  //     uploadedDocs.forEach((file) => URL.revokeObjectURL(file.preview));
  //   }, [uploadedDocs]);
  useEffect(() => {
    setUploadedDocs(
      approvalDetail?.approval_documents?.map((data) => ({ name: data })) || []
    );
  }, [approvalDetail]);
  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>Upload Documents</Typography>
      <Typography className={classes.subTitle}>in JPEG, PNG or PDF</Typography>
      {getApproval ? (
        <>
          <Spinner />
        </>
      ) : (
        <>
          <Box {...getRootProps({ className: classes.dropBoxContainer })}>
            <input {...getInputProps()} />
            <Typography className={classes.dropBoxText}>
              Add file or drag it here
            </Typography>
          </Box>
          <Box className={classes.fileContainer}>
            {uploadedDocs.map((file) => (
              <Files file={file} key={file.name} />
            ))}
          </Box>
          {approvalDetail.notes ? (
            <Box className={classes.fileContainer}>
              {`Notes: ${approvalDetail.notes}`}
            </Box>
          ) : null}
        </>
      )}
    </Box>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    approvalDetail: adminReducer.approvalDetail,
    getApproval: adminReducer.loadings.getApproval,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
    uploadApprovalDocs: (data) =>
      dispatch(actionCreator.uploadApprovalDocs(data)),
    deleteApprovalDocs: (data) =>
      dispatch(actionCreator.deleteApprovalDocs(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalDocUpload);
