import * as actionTypes from "./actionTypes";
import { API } from "../../asset/api";

import { setWithExpiry, getWithExpiry } from "../../asset/utility";
import { notify } from "./notifyAction";
import { OnLogout } from "./authAction";
import { date } from "yup/lib/locale";

const setAdminLoading = (data) => {
  return {
    type: actionTypes.SET_ADMIN_LOADING,
    payload: data,
  };
};

const setAsset = (data) => {
  return {
    type: actionTypes.SET_ASSET,
    payload: data,
  };
};

const setUserAccount = (data) => {
  return {
    type: actionTypes.SET_USER_ACCOUNT,
    payload: data,
  };
};

const setInstrument = (data) => {
  return {
    type: actionTypes.SET_INSTRUMENT,
    payload: data,
  };
};

const setAssets = (data) => {
  return {
    type: actionTypes.SET_ASSETS,
    payload: data,
  };
};

const setInstruments = (data) => {
  return {
    type: actionTypes.SET_INSTRUMENTS,
    payload: data,
  };
};
const setAdminReducerValue = (data) => {
  return {
    type: actionTypes.SET_ADMIN_REDUCER_VALUE,
    payload: data,
  };
};

const setApprovalLoading = (data) => {
  return {
    type: actionTypes.SET_APPROVAL_LOADING,
    payload: data,
  };
};

const setCompanyLoading = (data) => {
  return {
    type: actionTypes.SET_COMPANY_LOADING,
    payload: data,
  };
};

const setError = (data) => {
  return {
    type: actionTypes.SET_ERROR,
    payload: data,
  };
};

const setAdmin = (data) => {
  return {
    type: actionTypes.SET_ADMIN,
    payload: data,
  };
};

const setCompany = (data) => {
  return {
    type: actionTypes.SET_COMPANY,
    payload: data,
  };
};
// const setUserCompany = (data) => {
// 	return {
// 		type: actionTypes.SET_COMPANY,
// 		payload: data,
// 	};
// };
const setFlag = (data) => {
  return {
    type: actionTypes.SET_FLAG,
    payload: data,
  };
};

const setAnnouncements = (data) => {
  return {
    type: actionTypes.SET_ANNOUNCEMENTS,
    payload: data,
  };
};

const setAnnouncement = (data) => {
  return {
    type: actionTypes.SET_ANNOUNCEMENT,
    payload: data,
  };
};

const setNewsLetters = (data) => {
  return {
    type: actionTypes.SET_NEWSLETTERS,
    payload: data,
  };
};

const setBankAccount = (data) => {
  return {
    type: actionTypes.SET_BANK_ACCOUNT,
    payload: data,
  };
};

const setBusinessesLoading = (data) => {
  return {
    type: actionTypes.SET_BUSINESSES_LOADING,
    payload: data,
  };
};
export const getAdmin = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        getAdmin: true,
      })
    );
    API.post(
      "/GetAdmin",
      {},
      {
        headers: {
          token: storedToken,
        },
        withCredentials: false,
      }
    )
      .then((res) => {
        const { status, message, error, admin } = res.data;
        if (status) {
          dispatch(setAdmin({ user: admin }));
        } else {
          dispatch(notify(message, "error"));
        }
        dispatch(
          setAdminLoading({
            getAdmin: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            getAdmin: false,
          })
        );
      });
  };
};

const setAdmins = (data) => {
  return {
    type: actionTypes.SET_ADMINS,
    payload: data,
  };
};

export const getAllAdmins = (user) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        getAdmins: true,
      })
    );
    const reqBody = user;
    API.post("/GetAllAdmins", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, admins } = res.data;
        if (status) {
          dispatch(setAdmins({ admins: admins }));
        } else {
          dispatch(notify(message, "error"));
        }
        dispatch(
          setAdminLoading({
            getAdmins: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            getAdmins: false,
          })
        );
      });
  };
};

export const updateAdmin = ({ id, active, level, type, changes }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        updateAdmin: true,
      })
    );
    const reqBody = {
      id: id,
      active: active,
      level: level,
      type: type,
      changes: changes,
    };
    API.post("/UpdateAdmin", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error } = res.data;
        if (status) {
          dispatch(getAllAdmins());
          dispatch(getAdmin());
          dispatch(notify(message, "success"));
        } else {
          dispatch(notify(message, "error"));
        }
        dispatch(
          setAdminLoading({
            updateAdmin: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            updateAdmin: false,
          })
        );
      });
  };
};

export const updateCompany = ({ id, changes }) => {
  return async (dispatch, getState) => {
    let token = getWithExpiry("token");

    dispatch(
      setCompanyLoading({
        updateCompany: true,
      })
    );
    API.post(
      `/UpdateCompany`,
      {
        profile_id: id,
        update: changes,
      },
      {
        headers: {
          token: token,
        },
        withCredentials: false,
      }
    )
      .then((res) => {
        const { status, message, error } = res.data;
        if (status) {
          dispatch(getCompany(id));
          dispatch(notify(message, "success"));
        } else {
          dispatch(notify(message, "error"));
        }
        dispatch(
          setCompanyLoading({
            updateCompany: false,
          })
        );
      })
      .catch((err) => {
        notify(
          err?.response?.data?.message ||
            err?.response?.data?.error?.errorMessage ||
            "Coudn't update company data please try again",
          "error"
        );

        dispatch(
          setCompanyLoading({
            updateCompany: false,
          })
        );
      });
  };
};

export const registerCompany = (data) => {
  return async (dispatch, getState) => {
    let token = getWithExpiry("token");
    const reqBody = {
      id: data.id,
      data: data.data,
    };
    // dispatch(setUserLoading({ registerCompany: true }));
    API.post(`/registerCompany`, reqBody, {
      headers: {
        token: token,
      },
      withCredentials: false,
    })
      // callApi("registerCompany", "POST", reqBody)
      .then((res) => {
        const { status, errorMessage, successMessage, error } = res.data;
        if (status) {
          dispatch(getCompany(data.id));
          dispatch(notify(successMessage, "success"));
          // dispatch(setUserLoading({ registerCompany: false }));
        } else {
          dispatch(notify(errorMessage, "error"));
          // dispatch(setUserLoading({ registerCompany: false }));
        }
      })
      .catch((err) => {
        dispatch(notify(err?.response?.data?.errorMessage, "error"));
        // dispatch(setUserLoading({ registerCompany: false }));
      });
  };
};

export const getCompany = (id) => {
  return async (dispatch, getState) => {
    let token = getWithExpiry("token");
    dispatch(
      setCompanyLoading({
        getCompanies: true,
      })
    );
    API.post(
      `/getCompanies`,
      {
        profile_id: id,
      },
      {
        headers: {
          token: token,
        },
        withCredentials: false,
      }
    )
      .then((res) => {
        const { status, message, error, companies } = res.data;

        if (status) {
          if (companies?.length > 0) {
            dispatch(setCompany({ companies: companies }));
          } else {
            dispatch(notify("No companies found for this user", "info"));
          }
        }

        dispatch(
          setCompanyLoading({
            getCompany: false,
          })
        );
      })
      .catch((err) => {
        dispatch(
          setCompanyLoading({
            getCompany: false,
          })
        );
      });
  };
};

export const updateAdminEmail = ({ id, email, level, changes }) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        updateAdmin: true,
      })
    );
    const reqBody = {
      id: id,
      email: email,
      level: level,
      changes: changes,
    };
    API.post("/UpdateAdminEmail", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error } = res.data;
        if (status) {
          dispatch(getAllAdmins());
          dispatch(getAdmin());
          dispatch(notify(message, "success"));
        } else {
          dispatch(notify(message, "error"));
        }
        dispatch(
          setAdminLoading({
            updateAdmin: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            updateAdmin: false,
          })
        );
      });
  };
};

export const resetAdminEmailLink = ({ token, oldemail }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");

    dispatch(
      setAdminLoading({
        resetAdminEmailLink: true,
      })
    );
    const reqBody = {
      token: token,
      oldemail: oldemail,
    };
    API.post("/ResetAdminEmailLink", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error } = res.data;
        if (status) {
          dispatch(notify(message, "success"));
        } else {
          dispatch(notify(message, "error"));
        }
        dispatch(
          setAdminLoading({
            resetAdminEmailLink: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            resetAdminEmailLink: false,
          })
        );
      });
  };
};

export const UpdateAdminPassword = ({
  email,
  id,
  level,
  currentPassword,
  newPassword,
}) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        updateAdminPassword: true,
      })
    );
    const reqBody = {
      email: email,
      id: id,
      level: level,
      currentPassword: currentPassword,
      password: newPassword,
    };
    API.post("/UpdateAdminPassword", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error } = res.data;
        if (status) {
          dispatch(notify(message, "success"));
          dispatch(setFlag({ flag: true }));
        } else {
          dispatch(notify(message, "error"));
        }
        dispatch(
          setAdminLoading({
            updateAdminPassword: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(
          notify(err?.response?.data?.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            updateAdminPassword: false,
          })
        );
      });
  };
};

export const ResetAdminLink = ({ email, id, level }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        resetAdminLink: true,
      })
    );
    const reqBody = {
      email: email,
      id: id,
      level: level,
    };
    API.post("/ResetAdminLink", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error } = res.data;
        if (status) {
          dispatch(notify(message, "success"));
        } else {
          dispatch(notify(message, "error"));
        }
        dispatch(
          setAdminLoading({
            updateAdminPassword: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            updateAdminPassword: false,
          })
        );
      });
  };
};

export const ResetAdminPassword = ({ token, password }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        resetAdminPassword: true,
      })
    );
    const reqBody = {
      token,
      password,
    };
    API.post("/ResetAdminPassword", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error } = res.data;
        if (status) {
          dispatch(notify(message, "success"));
        } else {
          dispatch(notify(message, "error"));
        }
        dispatch(
          setAdminLoading({
            resetAdminPassword: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            resetAdminPassword: false,
          })
        );
      });
  };
};

export const sendAdminRequest = ({ email, level, type }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        sendAdminRequest: true,
      })
    );
    const reqBody = {
      email: email,
      level: level,
      type: type,
    };
    API.post("/AdminRequest", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error } = res.data;
        if (status) {
          dispatch(notify(message, "success"));
        } else {
          dispatch(notify(message, "error"));
        }
        dispatch(
          setAdminLoading({
            sendAdminRequest: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            sendAdminRequest: false,
          })
        );
      });
  };
};

export const sendAdminDeleteRequest = ({ email, archive }) => {
  return async (dispatch, getState) => {
    //   //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        sendAdminDeleteRequest: true,
      })
    );
    const reqBody = {
      email: email,
      archive: archive,
    };
    API.post("/DeleteAdminRequest", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error } = res.data;
        if (status) {
          dispatch(getAllAdmins());
          dispatch(notify(message, "success"));
          // window.location.reload();
        } else {
          dispatch(notify(message, "error"));
        }
        dispatch(
          setAdminLoading({
            sendAdminRequest: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            sendAdminRequest: false,
          })
        );
      });
  };
};

const setUsers = (data) => {
  return {
    type: actionTypes.SET_USERS,
    payload: data,
  };
};

const setUser = (data) => {
  return {
    type: actionTypes.SET_USER,
    payload: data,
  };
};

export const getUser = (id) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        getUser: true,
      })
    );
    const reqBody = {
      id: id,
    };
    API.post("/GetUserDetails", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, user } = res.data;
        if (status) {
          dispatch(setUser({ user: user }));
        } else {
          dispatch(notify(message, "error"));
        }
        dispatch(
          setAdminLoading({
            getUsers: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            getUser: false,
          })
        );
      });
  };
};

export const getUsers = (user) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        getUsers: true,
      })
    );
    const reqBody = user;
    API.post("/GetUsers", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, users } = res.data;
        if (status) {
          dispatch(setUsers({ users: users }));
        } else {
          dispatch(notify(message, "error"));
        }
        dispatch(
          setAdminLoading({
            getUsers: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            getUsers: false,
          })
        );
      });
  };
};

export const getSponsorList = (id) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      id: id,
    };

    API.post("/GetSponsorInfo", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, errorMessage, successMessage, list, error } = res.data;
        if (status) {
          dispatch(setSponsorList({ sponsorList: list }));
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(notify(err?.response?.data?.errorMessage, "error"));
        dispatch(
          setStatus({
            error: err,
          })
        );
      });
  };
};

const setSponsorList = (data) => {
  return {
    type: actionTypes.SET_SPONSOR_LIST,
    payload: data,
  };
};

const setStatus = (data) => {
  return {
    type: actionTypes.SET_STATUS,
    payload: data,
  };
};

export const getUserKycData = (id) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      profileId: id,
    };

    API.post("/getKyc", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, errorMessage, data, succesMessage, error } = res.data;
        if (status) {
          dispatch(setKycData({ kycData: data }));
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(setStatus({ error: err }));
      });
  };
};

// export const getKycDocsData = (id) => {
//  return async (dispatch, getState) => {
//   let storedToken = getWithExpiry("token");
//   const reqBody = {
//    id: id,
//   };

//   API.post("/getKycDocsData", JSON.stringify(reqBody), {
//    headers: {
//     token: storedToken,
//    },
//    withCredentials: false,
//   })
//    .then((res) => {
//     const { status, errorMessage, kycDocsData, error } = res.data;
//     if (status) {
//      dispatch(setKycDocsData({ kycDocsData: kycDocsData }));
//     } else {
//      dispatch(notify(errorMessage, "error"));
//     }
//     if (error) {
//      dispatch(
//       setStatus({
//        error: error,
//       })
//      );
//     }
//    })
//    .catch((err) => {
//     dispatch(notify(err?.response?.data?.message || err.message, "error"));
//     dispatch(setStatus({ error: err }));
//    });
//  };
// };

// export const getKycImageData = (data) => {
//  return async (dispatch, getState) => {
//   let storedToken = getWithExpiry("token");
//   const reqBody = {
//    image: data.image,
//    id: data.inspection_id,
//   };

//   API.post("/getKycImageData", reqBody, {
//    headers: {
//     token: storedToken,
//    },
//    withCredentials: false,
//   })
//    .then((res) => {
//     const { status, errorMessage, kycImageData, error } = res.data;
//     if (status) {
//      dispatch(setKycImageData({ kycImageData: kycImageData }));
//     } else {
//      dispatch(notify(errorMessage, "error"));
//     }
//     if (error) {
//      dispatch(
//       setStatus({
//        error: error,
//       })
//      );
//     }
//    })
//    .catch((err) => {
//     dispatch(notify(err?.response?.data?.message || err.message, "error"));
//     dispatch(setStatus({ error: err }));
//    });
//  };
// };

// export const getKycImageDataB = (data1) => {
//  return async (dispatch, getState) => {
//   let storedToken = getWithExpiry("token");
//   const reqBody = {
//    image: data1.image,
//    id: data1.inspection_id,
//   };

//   API.post("/getKycImageDataB", reqBody, {
//    headers: {
//     token: storedToken,
//    },
//    withCredentials: false,
//   })
//    .then((res) => {
//     const { status, errorMessage, kycImageDataB, error } = res.data;
//     if (status) {
//      dispatch(setKycImageDataB({ kycImageDataB: kycImageDataB }));
//     } else {
//      dispatch(notify(errorMessage, "error"));
//     }
//     if (error) {
//      dispatch(
//       setStatus({
//        error: error,
//       })
//      );
//     }
//    })
//    .catch((err) => {
//     dispatch(notify(err?.response?.data?.message || err.message, "error"));
//     dispatch(setStatus({ error: err }));
//    });
//  };
// };

// export const getKycImageDataS = (data) => {
//  return async (dispatch, getState) => {
//   let storedToken = getWithExpiry("token");
//   const reqBody = {
//    image: data.image,
//    id: data.inspection_id,
//   };

//   API.post("/getKycImageDataS", reqBody, {
//    headers: {
//     token: storedToken,
//    },
//    withCredentials: false,
//   })
//    .then((res) => {
//     const { status, errorMessage, kycImageDataS, error } = res.data;
//     if (status) {
//      dispatch(setKycImageDataS({ kycImageDataS: kycImageDataS }));
//     } else {
//      dispatch(notify(errorMessage, "error"));
//     }
//     if (error) {
//      dispatch(
//       setStatus({
//        error: error,
//       })
//      );
//     }
//    })
//    .catch((err) => {
//     dispatch(notify(err?.response?.data?.message || err.message, "error"));
//     dispatch(setStatus({ error: err }));
//    });
//  };
// };

const setKycData = (data) => {
  return {
    type: actionTypes.SET_KYC_DATA,
    payload: data,
  };
};

// const setKycDocsData = (data) => {
//  return {
//   type: actionTypes.SET_KYC_DOCS_DATA,
//   payload: data,
//  };
// };

// const setKycImageData = (data) => {
//  return {
//   type: actionTypes.SET_KYC_IMAGE_DATA,
//   payload: data,
//  };
// };

// const setKycImageDataB = (data) => {
//  return {
//   type: actionTypes.SET_KYC_IMAGE_DATAB,
//   payload: data,
//  };
// };

// const setKycImageDataS = (data) => {
//  return {
//   type: actionTypes.SET_KYC_IMAGE_DATAS,
//   payload: data,
//  };
// };

export const updateUser = ({ id, changes }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    const reqBody = {
      profileId: id,
      changes: changes,
    };

    //check if company changes

    API.post("/UpdateUserProfile", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error } = res.data;

        dispatch(notify(message, status ? "success" : "error"));
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(
          notify(err?.response?.data?.errorMessage || err.message, "error")
        );
      });
  };
};

export const updateUserDetails = ({ id, changes }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    const reqBody = {
      profileId: id,
      changes: changes,
    };

    //check if company changes

    API.post("/UpdateUserDetails", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error } = res.data;
        dispatch(getUser(id));
        dispatch(notify(message, status ? "success" : "error"));
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
      });
  };
};

export const updatePassword = ({ id, password }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    const reqBody = {
      profileId: id,
      password: password,
    };

    //check if company changes

    API.post("/UpdateUserPassword", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error } = res.data;
        dispatch(notify(message, status ? "success" : "error"));
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
      });
  };
};

export const updateCustomFee = ({ id, feeTypeId, feeValue }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    const reqBody = {
      profileId: id,
      feeTypeId: feeTypeId,
      feeValue: feeValue,
    };

    //check if company changes

    API.post("/SetCustomFee", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error } = res.data;
        dispatch(getUser(id));
        dispatch(notify(message, status ? "success" : "error"));
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
      });
  };
};

export const addCbc = ({ id, amount, feeValue, assetType }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    const reqBody = {
      profileId: id,
      amount: amount,
      feeValue: feeValue,
      assestTypeId: assetType,
    };

    //check if company changes

    API.post("/AddCBC", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res.data;
        if (status) {
          dispatch(notify(successMessage, "success"));
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
      });
  };
};

export const updateOverdraftValue = ({
  id,
  overdraftId,
  overdraftValue,
  overdraftFeeValue,
}) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    const reqBody = {
      profileId: id,
      overdraftId: overdraftId,
      overdraftValue: overdraftValue,
      overdraftFeeValue: overdraftFeeValue,
    };

    //check if company changes

    API.post("/UpdateOverdraftValue", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error } = res.data;
        dispatch(getUser(id));
        dispatch(notify(message, status ? "success" : "error"));
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
      });
  };
};

export const getAssectsType = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    const reqBody = {};

    API.post("/GetAssestsType", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, error, assestsTypeData } =
          res.data;

        if (status) {
          dispatch(setAssectsType({ assestsTypeData: assestsTypeData }));
        } else {
          dispatch(notify(errorMessage, "error"));
        }

        if (error) {
          dispatch(setStatus({ error: error }));
        }
      })
      .catch((err) => {
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(setStatus({ error: err }));
      });
  };
};

const setAssectsType = (data) => {
  return {
    type: actionTypes.SET_ASSECTS_TYPE_DATA,
    payload: data,
  };
};

export const allocateKeycontact = (user) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    const reqBody = {
      email: user.email,
      type: user.type,
      nodeType: user.nodeType,
      ref: user.ref,
    };

    API.post("/SignUp", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res.data;
        dispatch(
          notify(
            successMessage !== "" ? successMessage : errorMessage,
            status ? "success" : "error"
          )
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
      });
  };
};

export const deAllocateKeycontact = (ref) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    const reqBody = {
      referralId: ref,
    };

    API.post("/DeallocateKeyContactNodes", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error } = res.data;
        dispatch(notify(message, status ? "success" : "error"));
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
      });
  };
};

export const getAdminAnnouncementById = (data) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    const reqBody = {
      id: data.id,
    };
    // dispatch(
    //   setAdminLoading({
    //     announcements: true,
    //   })
    // );
    API.post("/getAnnouncement", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, announcements } = res.data;
        if (status) {
          dispatch(setAnnouncement({ announcement: announcements[0] }));
          dispatch(
            setAdminLoading({
              announcements: false,
            })
          );
        } else {
          dispatch(setError({ error: error }));
          dispatch(notify(error, "error"));
          dispatch(
            setAdminLoading({
              announcements: false,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(
          notify(err.response.data.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            announcements: false,
          })
        );
      });
  };
};

export const getAdminAnnouncements = (data) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    const reqBody = {
      archived: data.archived,
      page: data.page,
      limit: data.limit,
    };
    dispatch(
      setAdminLoading({
        announcements: true,
      })
    );

    API.post("/GetAnnouncements", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, data } = res.data;
        if (status) {
          dispatch(setAnnouncements({ announcements: data }));
          dispatch(
            setAdminLoading({
              announcements: false,
            })
          );
        } else {
          dispatch(setError({ error: error }));
          dispatch(notify(error, "error"));
          dispatch(
            setAdminLoading({
              announcements: false,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(
          notify(err.response.data.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            announcements: false,
          })
        );
      });
  };
};

export const createAnnouncement = (announcement, onSuccessCallBack) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      announcement: announcement,
    };
    let announcementReqData = {
      archived: false,
      page: 1,
      //  limit: 4,
    };
    dispatch(
      setAdminLoading({
        announcements: true,
      })
    );

    API.post("/createAnnouncement", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, errorMessage, successMessage, error, data } = res.data;
        if (status) {
          // dispatch(getAdminAnnouncements(announcementReqData));
          dispatch(notify("Announcement created succesfully", "success"));
          dispatch(
            setAdminLoading({
              announcements: false,
            })
          );
          if (onSuccessCallBack && typeof onSuccessCallBack == "function")
            onSuccessCallBack();
        } else {
          dispatch(setError({ error: error.error }));
          dispatch(notify(errorMessage, "error"));
          dispatch(
            setAdminLoading({
              announcements: false,
            })
          );
        }
        if (data?.announcement_id && announcement?.uploadPic) {
          let reqBodyData = new FormData();
          reqBodyData.append("id", data?.announcement_id);
          reqBodyData.append("pic", announcement?.uploadPic);
          reqBodyData.append("token", storedToken);

          dispatch(
            uploadAnnouncementPic({
              announcement_id: data?.announcement_id,
              uploadPic: announcement?.uploadPic,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.errorMessage, "error"));
        dispatch(
          setAdminLoading({
            announcements: false,
          })
        );
      });
  };
};

export const uploadAnnouncementPic = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    let announcementReqData = {
      archived: false,
      page: 1,
      // limit: 4,
    };
    let reqBodyData = new FormData();
    reqBodyData.append("id", data?.announcement_id);
    reqBodyData.append("pic", data?.uploadPic);
    reqBodyData.append("token", storedToken);
    API.post("/uploadAnnouncementPic", reqBodyData, {
      "Content-Type": "multipart/form-data",
    })
      .catch((err) => {
        dispatch(notify("Image uploaded Failed", "error"));
      })
      .then((res) => {
        if (res.status) {
          dispatch(notify("Image uploaded successfully", "success"));
          dispatch(getAdminAnnouncements(announcementReqData));
        }
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.errorMessage, "error"));
        dispatch(
          setAdminLoading({
            announcements: false,
          })
        );
      });
  };
};

export const removeAnnouncement = (announcementId) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    const reqBody = {
      announcementId: announcementId,
    };
    let announcementReqData = {
      archived: false,
      page: 1,
      // limit: 4,
    };
    dispatch(
      setAdminLoading({
        announcements: true,
      })
    );

    API.post("/removeAnnouncement", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, errorMessage, successMessage, error } = res.data;
        if (status) {
          dispatch(setAnnouncements([]));
          dispatch(getAdminAnnouncements(announcementReqData));
          dispatch(
            setAdminLoading({
              announcements: false,
            })
          );
        } else {
          dispatch(setError({ error: error.error }));
          dispatch(notify(errorMessage, "error"));
          dispatch(
            setAdminLoading({
              announcements: false,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(
          notify(err?.response?.data?.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            announcements: false,
          })
        );
      });
  };
};

export const updateAnnouncement = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      announcementId: data.announcementId,
      updates: data.updates,
    };
    let announcementReqData = {
      archived: data.getArchivedAnn,
      page: 1,
      // limit: 4,
    };
    dispatch(
      setAdminLoading({
        announcements: true,
      })
    );

    API.post("/updateAnnouncement", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, errorMessage, successMessage, error } = res.data;
        if (status) {
          dispatch(setAnnouncements([]));
          dispatch(getAdminAnnouncements(announcementReqData));
          dispatch(
            setAdminLoading({
              announcements: false,
            })
          );
          dispatch(notify("Announement updated successfully", "success"));
          if (
            data?.onSuccessUpdate &&
            typeof data?.onSuccessUpdate == "function"
          ) {
            data.onSuccessUpdate();
          }
        } else {
          dispatch(setError({ error: error.error }));
          dispatch(notify(errorMessage, "error"));
          dispatch(
            setAdminLoading({
              announcements: false,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err.response.data.errorMessage, "error"));
        dispatch(
          setAdminLoading({
            announcements: false,
          })
        );
      });
  };
};

export const setApprovalDetail = (data) => {
  return {
    type: actionTypes.SET_APPROVAL_DETAIL,
    payload: data,
  };
};

export const updateApprovalDetail = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      approvalId: data?.approvalId || "",
    };
    if (data?.adminId) reqBody["adminId"] = data.adminId;
    dispatch(
      setApprovalLoading({
        getApproval: true,
      })
    );

    API.post("/getApproval", JSON.stringify({ ...reqBody, test: "ok" }), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, errorMessage, successMessage, error, data } = res.data;
        if (status && data?.length) {
          dispatch(setApprovalDetail({ approvalDetail: data[0] }));
          dispatch(
            setApprovalLoading({
              getApproval: false,
            })
          );
          // dispatch(notify("Announement updated successfully", "success"));
        } else {
          dispatch(setError({ error: error.error }));
          dispatch(notify(error || errorMessage, "error"));
          dispatch(
            setApprovalLoading({
              getApproval: false,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(
          notify(
            err?.response?.data?.error || err?.response?.data?.errorMessage,
            "error"
          )
        );
        dispatch(
          setApprovalLoading({
            getApproval: false,
          })
        );
      });
  };
};

export const approvalAction = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    let approvalDetail = getState()?.adminReducer?.approvalDetail;
    const reqBody = {
      approvalId: data?.approvalId || approvalDetail?._id,
      action: data?.action || approvalDetail?.level_assign_to?.active_action,
      isApproved: data?.isApproved || false,
      isRejected: data?.isRejected || false,
      euroFees: data?.euroFees || 0,
      euroDepositRequired: data?.euroDepositRequired || 0,
      assignToAction: data?.assignToAction || "",
      notes: data?.notes || "",
    };
    dispatch(
      setApprovalLoading({
        getApproval: true,
      })
    );

    API.post("/approvalAction", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, errorMessage, successMessage, error, data } = res.data;
        if (status) {
          dispatch(
            updateApprovalDetail({
              approvalId: data?.id || approvalDetail?._id,
            })
          );
          dispatch(
            setApprovalLoading({
              getApproval: false,
            })
          );
          dispatch(notify("Successful", "success"));
        } else {
          dispatch(setError({ error: error }));
          dispatch(notify(error || errorMessage, "error"));
          dispatch(
            setApprovalLoading({
              getApproval: false,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(
          notify(
            err.response.data.error || err.response.data.errorMessage,
            "error"
          )
        );
        dispatch(
          setApprovalLoading({
            getApproval: false,
          })
        );
      });
  };
};

export const uploadApprovalDocsMethod = (data, dispatch) => {
  let reqBody = new FormData();
  reqBody.append("approvalId", data?.approvalId || data?.approvalDetail?._id);
  reqBody.append(
    "activeAction",
    data?.action || data?.approvalDetail?.level_assign_to?.active_action
  );
  reqBody.append("docs", data?.docs || "");
  return new Promise((resolve, reject) => {
    API.post("/uploadapprovaldocs", reqBody, {
      "Content-Type": "multipart/form-data",
      headers: {
        token: data.storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, errorMessage, successMessage, error, data } = res.data;
        if (status && data?.id) {
          dispatch(notify("Successful", "success"));
          resolve({ data });
        } else {
          dispatch(setError({ error: error }));
          dispatch(notify(error || errorMessage, "error"));
          reject({ error: error || errorMessage });
        }
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(
          notify(
            JSON.stringify(
              err?.response?.data?.error || err?.response?.data?.errorMessage
            ),
            "error"
          )
        );
        reject({ error: err?.response?.data?.errorMessage });
      });
  });
};

export const uploadApprovalDocs = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    let approvalDetail = getState()?.adminReducer?.approvalDetail;
    let response = [];
    dispatch(
      setApprovalLoading({
        getApproval: true,
      })
    );
    for (let i = 0; i < data?.docs?.length; i++) {
      let singleFileData = { ...data };
      singleFileData.docs = data?.docs?.[i];
      singleFileData.storedToken = storedToken;
      singleFileData.approvalDetail = approvalDetail;
      let temp;
      await uploadApprovalDocsMethod(singleFileData, dispatch)
        .then((res) => {
          temp = res;
          return;
        })
        .catch((err) => {
          return;
        });
      if (temp) response = [...response, temp];
    }
    dispatch(
      updateApprovalDetail({
        approvalId: data?.approvalId,
      })
    );
    dispatch(
      setApprovalLoading({
        getApproval: false,
      })
    );
  };
};

export const deleteApprovalDocs = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    let approvalDetail = getState()?.adminReducer?.approvalDetail;
    const reqBody = {
      approvalId: data?.approvalId || approvalDetail?._id,
      fileUrls: data?.fileUrls || [],
    };
    dispatch(
      setApprovalLoading({
        getApproval: true,
      })
    );

    API.post("/removeApprovalDocs", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, errorMessage, successMessage, error, data } = res.data;
        if (status && data?.id) {
          dispatch(
            updateApprovalDetail({
              approvalId: data?.id,
            })
          );
          dispatch(
            setApprovalLoading({
              getApproval: false,
            })
          );
          dispatch(notify("File deleted", "success"));
        } else {
          dispatch(setError({ error: error }));
          dispatch(notify(error || errorMessage, "error"));
          dispatch(
            setApprovalLoading({
              getApproval: false,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(
          notify(
            err.response.data.error || err.response.data.errorMessage,
            "error"
          )
        );
        dispatch(
          setApprovalLoading({
            getApproval: false,
          })
        );
      });
  };
};

const setApprovals = (data) => {
  return {
    type: actionTypes.SET_APPROVALS,
    payload: data,
  };
};

export const getApproval = (body) => {
  return async (dispatch, getState) => {
    let token = getWithExpiry("token");
    dispatch(
      setApprovalLoading({
        getApproval: true,
      })
    );
    const reqBody = { ...body };
    API.post("/getApproval", JSON.stringify(reqBody), {
      headers: {
        token: token,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res.data;

        if (status) {
          dispatch(setApprovals({ approvals: data ? data : [] }));
        } else {
          dispatch(notify("No Approvals found"));
        }
        dispatch(
          setApprovalLoading({
            getApproval: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setApprovalLoading({
            getApproval: false,
          })
        );
      });
  };
};

export const assignAdmin = (data) => {
  return async (dispatch, getState) => {
    let token = getWithExpiry("token");
    const reqBody = { ...data };
    API.post("/assignApprovalAdmin", JSON.stringify(reqBody), {
      headers: {
        token: token,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res.data;

        if (status) {
          dispatch(notify("Admin assigned successfully", "success"));

          dispatch(
            getApproval({
              // adminType: data.adminType,
              // adminLevel: data.adminLevel,
              adminId: data.adminId,
            })
          );
        } else {
          dispatch(notify("Failed to assign admin", "error"));
        }

        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
      });
  };
};

export const sendNewsLetter = (data, setTemplate) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      template: data.template,
      subject: data.subject,
      fromAddress: data.fromAddress,
    };
    dispatch(
      setAdminLoading({
        newsLetter: true,
      })
    );

    API.post("/SendNewsLetter", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, data } = res.data;
        if (status) {
          dispatch(
            setAdminLoading({
              newsLetter: false,
            })
          );
          dispatch(getNewsLetters());
          dispatch(notify("Template Saved in Database", "success"));
          if (setTemplate) setTemplate("");
        } else {
          dispatch(setError({ error: error }));
          dispatch(notify(error, "error"));
          dispatch(
            setAdminLoading({
              newsLetter: false,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(
          notify(err.response.data.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            newsLetter: false,
          })
        );
      });
  };
};

export const getNewsLetters = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      hideDetail: !data?.showDetails,
    };
    dispatch(
      setAdminLoading({
        newsLetter: true,
      })
    );

    API.post("/getNewsLetters", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, data } = res.data;
        dispatch(
          setAdminLoading({
            newsLetter: false,
          })
        );
        dispatch(setNewsLetters(data || []));
      })
      .catch((err) => {
        // dispatch(setError({ error: err }));
        // dispatch(
        //   notify(err.response.data.errorMessage || err.message, "error")
        // );
        dispatch(
          setAdminLoading({
            newsLetter: false,
          })
        );
      });
  };
};

const setTasks = (data) => {
  return {
    type: actionTypes.SET_TASKS,
    payload: data,
  };
};

export const getPocketMoneyTasks = (user) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        getTasks: true,
      })
    );
    const reqBody = user;
    API.post("/getTaskList", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, data } = res.data;
        if (status) {
          dispatch(setTasks({ tasks: data }));
        } else {
          dispatch(notify(message, "error"));
        }
        dispatch(
          setAdminLoading({
            getTasks: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            getTasks: false,
          })
        );
      });
  };
};

export const getAsset = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = { id: data.id };
    dispatch(
      setAdminLoading({
        stocks: true,
      })
    );

    API.post("/getAsset", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, data } = res.data;
        dispatch(
          setAdminLoading({
            stocks: false,
          })
        );
        dispatch(setAsset(data || {}));
      })
      .catch((err) => {
        dispatch(
          notify(err.response.data.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            stocks: false,
          })
        );
      });
  };
};

export const createPocketMoneyTask = ({
  id,
  taskName,
  reward,
  category,
  frequency,
  isActive,
}) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      id: id,
      taskName: taskName,
      reward: reward,
      category: category,
      frequency: frequency,
      isActive: isActive,
    };

    dispatch(
      setAdminLoading({
        getTasks: true,
      })
    );

    API.post("/createTask", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, errorMessage, successMessage, error, data } = res.data;
        if (status) {
          dispatch(setTasks([]));
          dispatch(getPocketMoneyTasks());
          dispatch(notify("tasks created succesfully", "success"));
          dispatch(
            setAdminLoading({
              getTasks: false,
            })
          );
        } else {
          dispatch(setError({ error: error.error }));
          dispatch(notify(errorMessage, "error"));
          dispatch(
            setAdminLoading({
              getTasks: false,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.errorMessage, "error"));
        dispatch(
          setAdminLoading({
            getTasks: false,
          })
        );
      });
  };
};

export const updatePocketMoneytasks = ({
  id,
  taskName,
  category,
  reward,
  frequency,
  isActive,
}) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        getTasks: true,
      })
    );
    const reqBody = {
      id: id,
      taskName: taskName,
      category: category,
      reward: reward,
      frequency: frequency,
      isActive: isActive,
    };
    API.post("/updateTask", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, successMessage, errorMessage } =
          res.data;
        if (status) {
          dispatch(notify(successMessage, "success"));
          dispatch(getPocketMoneyTasks());
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(
          setAdminLoading({
            getTasks: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            getTasks: false,
          })
        );
      });
  };
};

export const deletePocketMoneytasks = ({ id }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        getTasks: true,
      })
    );
    const reqBody = {
      id: id,
    };
    API.post("/deleteTask", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, error, successMessage, errorMessage } = res.data;
        if (status) {
          dispatch(notify(successMessage, "success"));
          dispatch(getPocketMoneyTasks());
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(
          setAdminLoading({
            getTasks: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            getTasks: false,
          })
        );
      });
  };
};

export const getAssets = () => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        stocks: true,
      })
    );

    API.post(
      "/getAssets",
      {},
      {
        headers: {
          token: storedToken,
        },
        withCredentials: false,
      }
    )
      .then((res) => {
        const { status, message, error, data } = res.data;
        dispatch(
          setAdminLoading({
            stocks: false,
          })
        );
        let assets = data?.data;
        if (Array.isArray(assets)) {
          dispatch(setAssets(assets));
        }
      })
      .catch((err) => {
        dispatch(
          notify(
            err.response?.data?.errorMessage ||
              err?.message ||
              "Failed to fetch Assets",
            "error"
          )
        );
        dispatch(
          setAdminLoading({
            stocks: false,
          })
        );
      });
  };
};

export const createAsset = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    let reqBody = {
      createAsset: data,
    };
    dispatch(
      setAdminLoading({
        stocks: true,
      })
    );

    API.post("/createAsset", reqBody, {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, data } = res.data;
        dispatch(
          setAdminLoading({
            stocks: false,
          })
        );
        if (status) {
          dispatch(notify("Asset created sucessfully", "success"));
          dispatch(getAssets());
        } else {
          dispatch(
            notify(
              message || error.message || "Failed to create asset",
              "error"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(
          notify(err.response.data.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            stocks: false,
          })
        );
      });
  };
};

export const updateAsset = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    let reqBody = {
      updateAsset: data,
    };
    dispatch(
      setAdminLoading({
        stocks: true,
      })
    );

    API.post("/updateAsset", reqBody, {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, data, successMessage, errorMessage } =
          res.data;
        dispatch(
          setAdminLoading({
            stocks: false,
          })
        );
        if (status && successMessage) {
          dispatch(getAssets());
          dispatch(
            notify(successMessage || "Asset updated sucessfully", "success")
          );
        } else {
          dispatch(notify(errorMessage || "Failed to update asset", "error"));
        }
      })
      .catch((err) => {
        dispatch(
          notify(err.response.data.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            stocks: false,
          })
        );
      });
  };
};

export const createInstrument = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    let reqBody = {
      createInstrument: data,
    };
    dispatch(
      setAdminLoading({
        stocks: true,
      })
    );

    API.post("/createInstrument", reqBody, {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, data } = res.data;
        dispatch(
          setAdminLoading({
            stocks: false,
          })
        );
        if (status) {
          dispatch(notify("Instrument created sucessfully", "success"));
          dispatch(getInstruments());
        } else {
          dispatch(
            notify(
              message || error.message || "Failed to create instrument",
              "error"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(
          notify(err.response.data.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            stocks: false,
          })
        );
      });
  };
};

export const updateInstrument = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    let reqBody = {
      updateInstrument: data,
    };
    dispatch(
      setAdminLoading({
        stocks: true,
      })
    );

    API.post("/updateInstrument", reqBody, {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, data, errorMessage, successMessage } =
          res.data;
        dispatch(
          setAdminLoading({
            stocks: false,
          })
        );
        if (status && successMessage) {
          dispatch(getInstruments());
          dispatch(
            notify(
              successMessage || "Instrument updated sucessfully",
              "success"
            )
          );
        } else {
          dispatch(
            notify(errorMessage || "Failed to update instrument", "error")
          );
        }
      })
      .catch((err) => {
        dispatch(
          notify(err.response.data.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            stocks: false,
          })
        );
      });
  };
};

export const getInstrument = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = { id: data.id };
    dispatch(
      setAdminLoading({
        stocks: true,
      })
    );

    API.post("/getInstrument", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, data } = res.data;
        dispatch(
          setAdminLoading({
            stocks: false,
          })
        );
        dispatch(setInstrument(data || {}));
      })
      .catch((err) => {
        dispatch(
          notify(err.response.data.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            stocks: false,
          })
        );
      });
  };
};

export const getInstruments = () => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        stocks: true,
      })
    );

    API.post(
      "/getInstruments",
      {},
      {
        headers: {
          token: storedToken,
        },
        withCredentials: false,
      }
    )
      .then((res) => {
        const { status, message, error, data } = res.data;
        dispatch(
          setAdminLoading({
            stocks: false,
          })
        );
        let instruments = data?.data;
        if (Array.isArray(instruments)) {
          dispatch(setInstruments(instruments || {}));
        }
      })
      .catch((err) => {
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              err?.message ||
              "Failed to fetch Instruments",
            "error"
          )
        );
        dispatch(
          setAdminLoading({
            stocks: false,
          })
        );
      });
  };
};

export const updateUserAccount = (reqData) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        stocks: true,
      })
    );

    API.post("/updateAccount", reqData, {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, data } = res.data;
        dispatch(
          setAdminLoading({
            stocks: false,
          })
        );
        if (status) {
          dispatch(notify("Stock account update sucessfull", "success"));
          if (reqData?.updateAccount?.userId) {
            dispatch(getUser(reqData?.updateAccount?.userId));
          }
        }
      })
      .catch((err) => {
        dispatch(
          notify(
            err.response?.data?.errorMessage ||
              err?.message ||
              "Failed to fetch Assets",
            "error"
          )
        );
        dispatch(
          setAdminLoading({
            stocks: false,
          })
        );
      });
  };
};

export const getAccount = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = { id: data.id };
    dispatch(
      setAdminLoading({
        userAccount: true,
      })
    );

    API.post("/getAccount", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, data } = res.data;
        dispatch(
          setAdminLoading({
            userAccount: false,
          })
        );
        dispatch(
          setUserAccount(
            { ...(data?.data || {}), ...(data?.userAsset || {}) } || {}
          )
        );
      })
      .then(() => {})
      .catch((err) => {
        dispatch(
          notify(err.response.data.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            userAccount: false,
          })
        );
      });
  };
};
export const getUserEscrowAccount = (req) => {
  return async (dispatch, getState) => {
    let token = getWithExpiry("token");
    dispatch(
      setBusinessesLoading({
        getEscrowAccount: true,
      })
    );
    const reqBody = {
      show_completed_transactions: true,
      id: req?.id,
    };
    API.post("/getEscrowAccounts", JSON.stringify(reqBody), {
      headers: {
        token: token,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res.data;

        if (status) {
          dispatch(setAdminReducerValue({ userEscrowAccount: data || [] }));
          // dispatch(notify(successMessage, "success"));
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(
          setBusinessesLoading({
            getEscrowAccount: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setBusinessesLoading({
            getEscrowAccount: false,
          })
        );
      });
  };
};

export const removeAnnouncementPic = ({ id }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let body = {
      announcement_id: id,
    };
    API.post("/removeAnnouncementPic", JSON.stringify(body), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, errorMessage, succesMessage } =
          res.data;
      })
      .catch((err) => {
        notify(
          err.response.data.message ||
            "Coudn't delete the pic. please try again",
          "error"
        );
      });
  };
};

// old exchange Directory
// export const createExchangeDirectory = (data = {}) => {
//   return async (dispatch, getState) => {
//     return new Promise((resolve, reject) => {
//       let storedToken = getWithExpiry("token");
//       const reqBody = {
//         name: data.name || null,
//         category: data.category || null,
//         route: data.route || null,
//         description: data.description || null,
//         isArchived: data.isArchived || false,
//         stockId: data.stockId,
//         logo: data.logo || null,
//         pdf: data.pdf || null,
//         prospectus: data.prospectus || null,
//       };
//       dispatch(
//         setAdminLoading({
//           userAccount: true,
//         })
//       );

//       API.post("/createExchangeDirectory", JSON.stringify(reqBody), {
//         headers: {
//           token: storedToken,
//         },
//         withCredentials: false,
//       })
//         .then((res) => {
//           const { status, message, err, data } = res.data;
//           dispatch(
//             setAdminLoading({
//               userAccount: false,
//             })
//           );
//           if (status) {
//             dispatch(notify("Exchange directory created.", "success"));
//             dispatch(getExchangeDirectory());
//             resolve(data);
//           } else {
//             notify(err.response.data.error || err.message, "error");
//           }
//         })
//         .catch((err) => {
//           dispatch(notify(err.response.data.error || err.message, "error"));
//           dispatch(
//             setAdminLoading({
//               userAccount: false,
//             })
//           );
//         });
//     });
//   };
// };

// export const deleteExchangeDirectory = (data = {}) => {
//   return async (dispatch, getState) => {
//     let storedToken = getWithExpiry("token");
//     const reqBody = {
//       id: data.id || null,
//     };
//     dispatch(
//       setAdminLoading({
//         userAccount: true,
//       })
//     );

//     API.post("/deleteExchangeDirectory", JSON.stringify(reqBody), {
//       headers: {
//         token: storedToken,
//       },
//       withCredentials: false,
//     })
//       .then((res) => {
//         const { status, message, err, data } = res.data;
//         dispatch(
//           setAdminLoading({
//             userAccount: false,
//           })
//         );
//         if (status) {
//           notify("Exchange directory deleted.", "success");
//         } else {
//           notify(err.response.data.errorMessage || err.message, "error");
//         }
//       })
//       .catch((err) => {
//         dispatch(
//           notify(err.response.data.errorMessage || err.message, "error")
//         );
//         dispatch(
//           setAdminLoading({
//             userAccount: false,
//           })
//         );
//       });
//   };
// };

// const setExchangeDirectory = (data) => {
//   return {
//     type: actionTypes.SET_EXCHANGE_DIRECTORY,
//     payload: data,
//   };
// };

// export const getExchangeDirectory = (data = {}) => {
//   return async (dispatch, getState) => {
//     let storedToken = getWithExpiry("token");
//     const reqBody = {
//       id: data.id || null,
//     };
//     dispatch(
//       setAdminLoading({
//         userAccount: true,
//       })
//     );

//     API.post("/getExchangeDirectory", JSON.stringify(reqBody), {
//       headers: {
//         token: storedToken,
//       },
//       withCredentials: false,
//     })
//       .then((res) => {
//         const { status, message, err, data } = res.data;
//         dispatch(
//           setAdminLoading({
//             userAccount: false,
//           })
//         );
//         if (status) {
//           dispatch(setExchangeDirectory(data));
//         } else {
//           notify(err.response.data.errorMessage || err.message, "error");
//         }
//       })
//       .catch((err) => {
//         dispatch(
//           notify(err.response.data.errorMessage || err.message, "error")
//         );
//         dispatch(
//           setAdminLoading({
//             userAccount: false,
//           })
//         );
//       });
//   };
// };

// export const updateExchangeDirectory = (data = {}) => {
//   return async (dispatch, getState) => {
//     let storedToken = getWithExpiry("token");
//     const reqBody = {
//       id: data.id || null,
//       name: data.name || null,
//       route: data.route || null,
//       description: data.description || null,
//       logo: data.logo || null,
//       pdf: data.pdf || null,
//       isArchived: data.isArchived
//         ? data.isArchived == "true"
//           ? true
//           : false
//         : null,
//       prospectus: data?.prospectus || [],
//     };
//     if (typeof data.category == "string") {
//       reqBody.category = data?.category?.split(",") || [];
//     } else {
//       reqBody.category = data.category;
//     }
//     dispatch(
//       setAdminLoading({
//         userAccount: true,
//         updateDirectory: true,
//       })
//     );

//     API.post("/updateExchangeDirectory", JSON.stringify(reqBody), {
//       headers: {
//         token: storedToken,
//       },
//       withCredentials: false,
//     })
//       .then((res) => {
//         const { status, message, err, data } = res.data;
//         dispatch(
//           setAdminLoading({
//             userAccount: false,
//           })
//         );
//         dispatch(
//           setAdminLoading({
//             updateDirectory: false,
//           })
//         );
//         if (status) {
//           dispatch(getExchangeDirectory());
//         } else {
//           notify(err.response.data.errorMessage || err.message, "error");
//         }
//       })
//       .catch((err) => {
//         dispatch(notify(err.response.data.error || err.message, "error"));
//         dispatch(
//           setAdminLoading({
//             userAccount: false,
//           })
//         );
//         dispatch(
//           setAdminLoading({
//             updateDirectory: false,
//           })
//         );
//       });
//   };
// };

export const uploadDocs = (formData, path) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let storedToken = getWithExpiry("token");
      let reqBody = new FormData();
      reqBody.append("path", path);
      reqBody.append("token", storedToken);
      reqBody.append("file", formData);
      dispatch(
        setAdminLoading({
          uploadDocs: true,
        })
      );
      API.post("/UploadDocs", reqBody, {
        "Content-Type": "multipart/form-data",
      })
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
            dispatch(
              setAdminLoading({
                uploadDocs: false,
              })
            );
          } else {
            reject(errorMessage);
            dispatch(
              setAdminLoading({
                uploadDocs: false,
              })
            );
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(
            setAdminLoading({
              uploadDocs: false,
            })
          );
        });
    });
  };
};

export const uploadStockDoc = (file, stockId) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let storedToken = getWithExpiry("token");
      let formData = new FormData();
      formData.append("stockId", stockId);
      formData.append("stockDoc", file);
      formData.append("token", storedToken);

      dispatch(
        setAdminLoading({
          uploadStockDoc: true,
        })
      );

      API.post("/uploadStockDoc", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res.data;
          if (status) {
            dispatch(notify("Doc Uploaded Successfully", "success"));
            resolve(res.data);
            dispatch(
              setAdminLoading({
                uploadStockDoc: false,
              })
            );
            // Optionally trigger an action to reload documents
            // dispatch(getStockDoc(stockId)); // Assuming fetchStockDocs action exists
          } else {
            reject(errorMessage || error);
            dispatch(
              setAdminLoading({
                uploadStockDoc: false,
              })
            );
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(
            setAdminLoading({
              uploadStockDoc: false,
            })
          );
        });
    });
  };
};

export const deleteStockDoc = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      _id: data?._id || "",
      stock_id: data?.stock_id || "",
    };
    dispatch(setAdminLoading({ uploadStockDoc: true }));
    API.post("/deleteStockDoc", JSON.stringify(reqBody), {
      headers: { token: storedToken },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, err, data } = res.data;
        dispatch(setAdminLoading({ uploadStockDoc: false }));
        if (status) {
          dispatch(notify("Stock Doc deleted successfully", "success"));
          dispatch(getStockDoc({ stockId: reqBody?.stock_id }));
        } else {
          dispatch(
            notify(err?.response?.data?.errorMessage || err.message, "error")
          );
        }
      })
      .catch((err) => {
        dispatch(
          notify(err?.response?.data?.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            uploadStockDoc: false,
          })
        );
      });
  };
};

// ULM Quiz Actions
export const createULMQuizQuestion = (req) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      module: req?.module,
      submodule: req?.submodule,
      question: req?.question,
      answer: req?.answer,
      option: req?.option,
    };

    dispatch(
      setAdminLoading({
        getTasks: true,
      })
    );

    API.post("/createUriQuizQuestion", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, errorMessage, successMessage, error, data } = res.data;
        if (status) {
          dispatch(getULMQuestions());
          dispatch(notify("Question created succesfully", "success"));
          dispatch(
            setAdminLoading({
              getTasks: false,
            })
          );
        } else {
          dispatch(setError({ error: error.error }));
          dispatch(notify(errorMessage, "error"));
          dispatch(
            setAdminLoading({
              getTasks: false,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.errorMessage, "error"));
        dispatch(
          setAdminLoading({
            getTasks: false,
          })
        );
      });
  };
};

export const getULMQuestions = (req) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        getTasks: true,
      })
    );
    const reqBody = {
      module: req?.module,
      submodule: req?.submodule,
      question: req?.question,
      id: req?.id,
    };
    API.post("/getUriQuizQuestion", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, data, message, error, successMessage, errorMessage } =
          res.data;
        if (status) {
          dispatch(setULMQuestionList(data));
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(
          setAdminLoading({
            getTasks: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            getTasks: false,
          })
        );
      });
  };
};

export const updateULMQuestion = (req) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        getTasks: true,
      })
    );
    const reqBody = {
      id: req?.id,
      module: req?.module,
      submodule: req?.submodule,
      question: req?.question,
      answer: req?.answer,
      option: req?.option,
    };
    API.post("/updateUriQuizQuestion", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, successMessage, errorMessage } =
          res.data;
        if (status) {
          dispatch(notify(successMessage, "success"));
          dispatch(getULMQuestions());
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(
          setAdminLoading({
            getTasks: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            getTasks: false,
          })
        );
      });
  };
};

export const deleteULMQuestion = (req) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        getTasks: true,
      })
    );
    const reqBody = {
      id: req?.id,
    };
    API.post("/deleteUriQuizQuestion", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, error, successMessage, errorMessage } = res.data;
        if (status) {
          dispatch(notify(successMessage, "success"));
          dispatch(getULMQuestions());
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(
          setAdminLoading({
            getTasks: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            getTasks: false,
          })
        );
      });
  };
};

const setULMQuestionList = (data) => {
  return {
    type: actionTypes.SET_ULM_QUESTION,
    payload: data,
  };
};

export const getOdType = ({ profileId }) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let body = {
      profileId,
    };
    API.post("/GetOverdraftType", JSON.stringify(body), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const {
          status,
          message,
          error,
          errorMessage,
          succesMessage,
          overdrafts,
        } = res.data;
        if (status) {
          let obj = {};
          (overdrafts || []).map((od) => {
            obj[od.id] = od;
          });
          dispatch(setOdType(obj));
        } else {
          notify(errorMessage || "Failed to fetch overdraft type.", "error");
        }
      })
      .catch((err) => {
        notify(
          err.response.data.message || "Failed to fetch overdraft type.",
          "error"
        );
      });
  };
};

const setOdType = (data) => {
  return { type: actionTypes.SET_OD_TYPE, payload: data };
};

const setCogitoStats = (data) => {
  return { type: actionTypes.SET_COGITO_STATS, payload: data };
};
const setCryptopiaStats = (data) => {
  return { type: actionTypes.SET_CRYPTOPIA_STATS, payload: data };
};

export const setCbcTransfers = (data) => {
  return {
    type: actionTypes.SET_CBC_TRANSFERS,
    payload: data,
  };
};

export const markSellAsCompleted = (data) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      //# HERE MAKE ASYNC CALLS.
      let storedToken = getWithExpiry("token");

      let reqBody = {
        profileId: data?.profileId,
        transfer_id: data?.transferId,
      };
      API.post("/markSellAsCompleted", JSON.stringify(reqBody), {
        headers: {
          token: storedToken,
        },
        withCredentials: false,
      })
        .then((res) => {
          const { status, message, error, successMessage, errorMessage } =
            res.data;
          if (status) {
            resolve(res.data);
            if (data?.userId) {
              dispatch(getcbcbanktransfers(data?.userId));
            } else {
              dispatch(getcbcbanktransfers());
            }
            dispatch(notify(successMessage, "success"));
          } else {
            reject(res.data);
            dispatch(notify(errorMessage, "error"));
          }
          if (error) dispatch(setError({ error: error }));
        })
        .catch((err) => {
          dispatch(setError({ error: err }));
          dispatch(
            notify(err?.response?.data?.message || err.message, "error")
          );
        });
    });
  };
};

export const updateCbcNote = (data) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      //# HERE MAKE ASYNC CALLS.
      let storedToken = getWithExpiry("token");

      let reqBody = {
        profileId: data?.profileId,
        transferId: data?.transferId,
        note: data?.note,
      };
      API.post("/updateCbcNote", JSON.stringify(reqBody), {
        headers: {
          token: storedToken,
        },
        withCredentials: false,
      })
        .then((res) => {
          const { status, message, error, successMessage, errorMessage } =
            res.data;
          if (status) {
            resolve(res.data);
            dispatch(getcbcbanktransfers());
            dispatch(notify(successMessage, "success"));
          } else {
            reject(res.data);
            dispatch(notify(errorMessage, "error"));
          }
          if (error) dispatch(setError({ error: error }));
        })
        .catch((err) => {
          dispatch(setError({ error: err }));
          dispatch(
            notify(err?.response?.data?.message || err.message, "error")
          );
        });
    });
  };
};

const addRecommend = (data) => {
  return {
    type: actionTypes.ADD_RECOMMENDS,
    payload: data,
  };
};
export const getRecommends = (searchUser) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");

    dispatch(
      setAdminLoading({
        getRecommendation: true,
      })
    );
    const reqBody = {
      searchUser: searchUser,
    };
    API.post("/getRecommends", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      // callApi("getRecommends", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, user, error } = res.data;

        dispatch(
          addRecommend({
            user: user,
          })
        );
        dispatch(
          setAdminLoading({
            getRecommendation: false,
          })
        );
      })
      .catch((err) => {
        dispatch(
          setAdminLoading({
            getRecommendation: false,
          })
        );

        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

// stocks
const setStocks = (data) => {
  return {
    type: actionTypes.SET_STOCKS,
    payload: data,
  };
};

export const getStocks = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    let reqBody;
    if (data) {
      reqBody = {
        page: 0,
        limit: 100,
        stockName: data?.stockName,
        stockId: data?.stockId,
        currentPrice: data?.currentPrice,
      };
    } else {
      reqBody = {
        page: 0,
        limit: 100,
      };
    }
    dispatch(setAdminLoading({ userAccount: true }));
    API.post("/getStock", JSON.stringify(reqBody), {
      headers: { token: storedToken },
      withCredentials: false,
    })
      .then((res) => {
        console.log("rspnse", res);
        const { status, message, err, data } = res.data;
        dispatch(setAdminLoading({ userAccount: false }));
        if (status) {
          dispatch(setStocks(data?.data));
        } else {
          notify(err?.response?.data?.errorMessage || err.message, "error");
        }
      })
      .catch((err) => {
        console.log(5, err);
        dispatch(
          notify(err?.response?.data?.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            userAccount: false,
          })
        );
      });
  };
};

export const createStock = (data, onSuccess) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let storedToken = getWithExpiry("token");
      let reqBodyData = new FormData();
      reqBodyData.append("stockName", data?.stockName);
      reqBodyData.append("COG100", data?.COG100);
      reqBodyData.append("CBC100", data?.CBC100);
      reqBodyData.append("CB75CO25", data?.CB75CO25);
      reqBodyData.append("CB50CO50", data?.CB50CO50);
      reqBodyData.append("CB25CO75", data?.CB25CO75);
      reqBodyData.append("stockLogo", data?.stockImage);
      reqBodyData.append("ownerId", data?.ownerId);
      reqBodyData.append("totalQuantity", data?.totalQuantity);
      if (data?.stockCategory) {
        data.stockCategory.map((category, index) => {
          reqBodyData.append("category", category.id);
        });
      }

      dispatch(setAdminLoading({ createStock: true }));
      API.post("/createStock", reqBodyData, {
        "Content-Type": "multipart/form-data",
        headers: { token: storedToken },
        withCredentials: false,
      })
        .then((res) => {
          const { status, err, data } = res.data;
          dispatch(setAdminLoading({ createStock: false }));
          if (status) {
            dispatch(notify("Stock created", "success"));
            onSuccess();
            dispatch(getStocks());
            resolve(data);
          } else {
            notify(err?.response?.data?.error || err?.message, "error");
          }
        })
        .catch((err) => {
          dispatch(notify(err?.response?.data?.error || err?.message, "error"));
          dispatch(setAdminLoading({ createStock: false }));
        });
    });
  };
};

export const updateStocks = (data, onSuccess) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      stockId: data.stockId,
      stockName: data.stockName,
      totalQuantity: data.totalQuantity,
      addStockCategory: data.addStockCategory,
      removeStockCategory: data.removeStockCategory,
      isActive: data?.isActive,
      // COG100: data.COG100,
      // CBC100: data.CBC100,
      // CB75CO25: data.CB75CO25,
      // CB50CO50: data.CB50CO50,
      // CB25CO75: data.CB25CO75
    };
    dispatch(
      setAdminLoading({
        updateStocks: true,
      })
    );
    API.post("/updateStock", JSON.stringify(reqBody), {
      headers: { token: storedToken },
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, err } = res.data;
        dispatch(setAdminLoading({ updateStocks: false }));
        if (status) {
          onSuccess();
          dispatch(notify(successMessage, "success"));
          dispatch(getStocks());
        } else {
          notify(err?.errorMessage || err?.message, "error");
        }
      })
      .catch((err) => {
        console.log(err?.errorMessage || err.message, "error");
        dispatch(dispatch(setAdminLoading({ updateStocks: false })));
      });
  };
};

export const updateStockLogo = (data) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let storedToken = getWithExpiry("token");
      let reqBodyData = new FormData();
      reqBodyData.append("stockId", data?.stockId);
      reqBodyData.append("stockLogo", data?.stockImage);

      dispatch(setAdminLoading({ createStock: true }));
      API.post("/updateStockLogo", reqBodyData, {
        "Content-Type": "multipart/form-data",
        headers: { token: storedToken },
        withCredentials: false,
      })
        .then((res) => {
          const { status, err, data } = res.data;
          dispatch(setAdminLoading({ createStock: false }));
          if (status) {
            dispatch(notify("Stock Logo Updated", "success"));
            //  onSuccess();
            dispatch(getStocks());
            resolve(data);
          } else {
            notify(err?.response?.data?.error || err?.message, "error");
          }
        })
        .catch((err) => {
          dispatch(notify(err?.response?.data?.error || err?.message, "error"));
          dispatch(setAdminLoading({ createStock: false }));
        });
    });
  };
};

export const assignStock = (data, onSuccess) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    let reqBody;
    if (data) {
      reqBody = {
        profileId: data?.profile_id,
        stockId: data?.stockId,
        quantity: data?.quantity,
      };
    } else {
      reqBody = {
        // page: 0,
        // limit: 30,
      };
    }
    dispatch(setAdminLoading({ updateStocks: true }));
    API.post("/addUserStock", JSON.stringify(reqBody), {
      headers: { token: storedToken },
      withCredentials: false,
    })
      .then((res) => {
        const { status, err, data, successMessage, errorMessage } = res.data;
        dispatch(setAdminLoading({ updateStocks: false }));
        if (status) {
          dispatch(notify(successMessage, "success"));
          onSuccess();
          dispatch(getStocks());
        } else {
          dispatch(
            notify(err?.response?.data?.errorMessage || err.message, "error")
          );
        }
      })
      .catch((err) => {
        dispatch(
          notify(err?.response?.data?.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            updateStocks: false,
          })
        );
      });
  };
};

export const getPortfolio = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    let reqBody;
    if (data) {
      reqBody = {
        profileId: data?.profile_id,
        stockId: data?.stockId,
        page: 0,
        limit: 100,
      };
    } else {
      reqBody = {
        // page: 0,
        // limit: 30,
      };
    }
    dispatch(setAdminLoading({ updateStocks: true }));
    API.post("/getPortfolio", JSON.stringify(reqBody), {
      headers: { token: storedToken },
      withCredentials: false,
    })
      .then((res) => {
        const { status, err, data, successMessage, errorMessage } = res.data;
        dispatch(setAdminLoading({ updateStocks: false }));
        if (status) {
          // dispatch(notify(successMessage, "success"));
          // dispatch(getStocks());
          dispatch(setUserStocks(data?.data));
        } else {
          dispatch(
            notify(err?.response?.data?.errorMessage || err.message, "error")
          );
        }
      })
      .catch((err) => {
        dispatch(
          notify(err?.response?.data?.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            updateStocks: false,
          })
        );
      });
  };
};

const setUserStocks = (data) => {
  return {
    type: actionTypes.SET_USER_STOCKS,
    payload: data,
  };
};

export const getUserStocks = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      profileId: data?.id || null,
      stockId: data?.stockId || null,
      limit: 500,
      // page: data?.page || null,
      page: 0,
    };
    dispatch(setAdminLoading({ userAccount: true }));
    API.post("/getPortfolio", JSON.stringify(reqBody), {
      headers: { token: storedToken },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, err, data } = res.data;
        dispatch(setAdminLoading({ userAccount: false }));
        if (status) {
          dispatch(setUserStocks(data));
        } else {
          notify(err?.response?.data?.errorMessage || err?.message, "error");
        }
      })
      .catch((err) => {
        dispatch(
          notify(err?.response?.data?.errorMessage || err?.message, "error")
        );
        dispatch(
          setAdminLoading({
            userAccount: false,
          })
        );
      });
  };
};

export const saveProspectusDraft = (data, callback) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      ...data,
    };
    dispatch(setAdminLoading({ userAccount: true }));
    API.post("/saveProspectusDraft", JSON.stringify(reqBody), {
      headers: { token: storedToken },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, err, data, successMessage } = res.data;
        dispatch(setAdminLoading({ userAccount: false }));
        if (status) {
          dispatch(notify(successMessage, "success"));
          dispatch(getProspectusDraft({}));
          callback();
        } else {
          notify(err?.response?.data?.errorMessage || err?.message, "error");
        }
      })
      .catch((err) => {
        dispatch(
          notify(err?.response?.data?.errorMessage || err?.message, "error")
        );
        dispatch(
          setAdminLoading({
            userAccount: false,
          })
        );
      });
  };
};

export const updateProspectusDraft = (data, callback) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      ...data,
    };
    dispatch(setAdminLoading({ userAccount: true }));
    API.post("/updateProspectusDraft", JSON.stringify(reqBody), {
      headers: { token: storedToken },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, err, data, successMessage } = res.data;
        dispatch(setAdminLoading({ userAccount: false }));
        if (status) {
          dispatch(notify(successMessage, "success"));
          dispatch(getProspectusDraft({}));
          callback();
        } else {
          notify(err?.response?.data?.errorMessage || err?.message, "error");
        }
      })
      .catch((err) => {
        dispatch(
          notify(err?.response?.data?.errorMessage || err?.message, "error")
        );
        dispatch(
          setAdminLoading({
            userAccount: false,
          })
        );
      });
  };
};

const setProspectusDrafts = (data) => {
  return {
    type: actionTypes.SET_PROSPECTUS,
    payload: data,
  };
};

export const getProspectusDraft = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    let reqBody = {};
    if (data) {
      reqBody = { _id: data.prospectusId };
    }
    dispatch(setAdminLoading({ userAccount: true }));
    API.post("/getProspectusDraft", JSON.stringify(reqBody), {
      headers: { token: storedToken },
      withCredentials: false,
    })
      .then((res) => {
        const { status, errorMessage, err, data, successMessage } = res.data;
        dispatch(setAdminLoading({ userAccount: false }));
        if (status) {
          // dispatch(notify(successMessage, "success"));
          dispatch(setProspectusDrafts(data));
        } else {
          notify(err?.response?.data?.errorMessage || err?.message, "error");
        }
      })
      .catch((err) => {
        dispatch(
          notify(err?.response?.data?.errorMessage || err?.message, "error")
        );
        dispatch(
          setAdminLoading({
            userAccount: false,
          })
        );
      });
  };
};

export const deleteProspectusDraft = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    let reqBody = {};
    if (data) {
      reqBody = { _id: data.prospectusId };
    }
    dispatch(setAdminLoading({ userAccount: true }));
    API.post("/deleteProspectusDraft", JSON.stringify(reqBody), {
      headers: { token: storedToken },
      withCredentials: false,
    })
      .then((res) => {
        const { status, errorMessage, err, successMessage } = res.data;
        dispatch(setAdminLoading({ userAccount: false }));
        if (status) {
          dispatch(notify(successMessage, "success"));
          dispatch(getProspectusDraft({}));
          // dispatch(setProspectusDrafts(data));
        } else {
          notify(err?.response?.data?.errorMessage || err?.message, "error");
        }
      })
      .catch((err) => {
        dispatch(
          notify(err?.response?.data?.errorMessage || err?.message, "error")
        );
        dispatch(
          setAdminLoading({
            userAccount: false,
          })
        );
      });
  };
};

// bank accounts
export const getBankAccount = (data) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        getAccount: true,
      })
    );
    let reqBody = {};
    if (data?.id) {
      reqBody = {
        profileId: data?.id,
      };
    }
    API.post("/getBankAccount", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, data } = res.data;
        if (status) {
          dispatch(setBankAccount(data));
        } else {
          dispatch(notify(message, "error"));
        }
        dispatch(
          setAdminLoading({
            getAccount: false,
          })
        );
        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            getAccount: false,
          })
        );
      });
  };
};

export const updateBankAccount = ({ id, status }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setAdminLoading({
        getAccount: true,
      })
    );
    const reqBody = {
      id: id,
      status: status,
    };
    API.post("/updateBankAccount", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, successMessage, errorMessage } =
          res.data;
        if (status) {
          dispatch(notify(successMessage, "success"));
          dispatch(getBankAccount());
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(
          setAdminLoading({
            getAccount: false,
          })
        );

        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
        dispatch(
          setAdminLoading({
            getAccount: false,
          })
        );
      });
  };
};

export const getcbcbanktransfers = (id) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      //# HERE MAKE ASYNC CALLS.
      let storedToken = getWithExpiry("token");

      let reqBody = {};
      if (id) {
        reqBody = {
          profileId: id,
        };
      }
      API.post("/getcbcbanktransfers", JSON.stringify(reqBody), {
        headers: {
          token: storedToken,
        },
        withCredentials: false,
      })
        .then((res) => {
          const { status, message, error, successMessage, errorMessage, data } =
            res.data;
          if (status) {
            resolve(data);
            dispatch(setCbcTransfers(data));
            // dispatch(notify(successMessage, "success"));
          } else {
            reject(res.data);
            dispatch(notify(errorMessage, "error"));
          }
          if (error) dispatch(setError({ error: error }));
        })
        .catch((err) => {
          dispatch(setError({ error: err }));
          dispatch(
            notify(err?.response?.data?.message || err.message, "error")
          );
        });
    });
  };
};

export const approveCbcTransfer = (data) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      //# HERE MAKE ASYNC CALLS.
      let storedToken = getWithExpiry("token");

      let reqBody = {
        profileId: data.profile_id,
        transferId: data.transferId,
      };
      API.post("/approvecbcbanktransfer", JSON.stringify(reqBody), {
        headers: {
          token: storedToken,
        },
        withCredentials: false,
      })
        .then((res) => {
          const { status, message, error, successMessage, errorMessage } =
            res.data;
          if (status) {
            resolve(res.data);
            dispatch(notify(successMessage, "success"));
          } else {
            reject(res.data);
            dispatch(notify(errorMessage, "error"));
          }
          if (error) dispatch(setError({ error: error }));
        })
        .catch((err) => {
          dispatch(setError({ error: err }));
          dispatch(
            notify(err?.response?.data?.message || err.message, "error")
          );
        });
    });
  };
};

export const declineCbcTransfer = (data) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      //# HERE MAKE ASYNC CALLS.
      let storedToken = getWithExpiry("token");

      let reqBody = {
        profileId: data.profile_id,
        transferId: data.transferId,
      };
      API.post("/cancelcbcbanktransfer", JSON.stringify(reqBody), {
        headers: {
          token: storedToken,
        },
        withCredentials: false,
      })
        .then((res) => {
          const { status, message, error, successMessage, errorMessage } =
            res.data;
          if (status) {
            resolve(res.data);
            dispatch(notify(successMessage, "success"));
          } else {
            reject(res.data);
            dispatch(notify(errorMessage, "error"));
          }
          if (error) dispatch(setError({ error: error }));
        })
        .catch((err) => {
          dispatch(setError({ error: err }));
          dispatch(
            notify(err?.response?.data?.message || err.message, "error")
          );
        });
    });
  };
};

export const refreshToken = () => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let body = {};
    API.post("/RefreshToken", JSON.stringify(body), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, token, errorMessage } = res.data;
        if (status) {
          setWithExpiry("token", token, 1);
        } else {
          dispatch(notify(errorMessage || "Failed to refresh token.", "error"));
          OnLogout();
        }
      })
      .catch((err) => {
        dispatch(
          notify(
            err.response.data.message || "Failed to refresh token.",
            "error"
          )
        );
        OnLogout();
      });
  };
};

const setUserStockTransactions = (data) => {
  return {
    type: actionTypes.SET_USER_STOCK_TRANSACTIONS,
    payload: data,
  };
};

export const getUserStocksTransaction = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      profileId: data?.id || null,
      stockId: data?.stockId || null,
      orderType: data?.orderType || null,
      orderStatus: data?.orderStatus || null,
      quantity: data?.quantity || null,
      limit: 5000,
      // page: data?.page || null,
      page: 0,
    };
    dispatch(setAdminLoading({ stockTransactions: true }));
    API.post("/getStockTransactions", JSON.stringify(reqBody), {
      headers: { token: storedToken },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, err, data } = res.data;
        dispatch(setAdminLoading({ stockTransactions: false }));
        if (status) {
          dispatch(setUserStockTransactions(data?.data));
        } else {
          console.log(err);
          notify(err?.response?.data?.errorMessage || err?.message, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          notify(err?.response?.data?.errorMessage || err?.message, "error")
        );
        dispatch(
          setAdminLoading({
            userAccount: false,
          })
        );
      });
  };
};

export const getAllStocksTransaction = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      profileId: data?.id || null,
      stockId: data?.stockId || null,
      orderType: data?.orderType || null,
      orderStatus:
        data?.orderStatus === "All" || null ? null : data?.orderStatus,
      quantity: data?.quantity || null,
      limit: 5000,
      // page: data?.page || null,
      page: 0,
    };
    dispatch(setAdminLoading({ stockTransactions: true }));
    API.post("/getStockTransactions", JSON.stringify(reqBody), {
      headers: { token: storedToken },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, err, data } = res.data;
        dispatch(setAdminLoading({ stockTransactions: false }));
        if (status) {
          dispatch(setUserStockTransactions(data?.data));
        } else {
          console.log(err);
          notify(err?.response?.data?.errorMessage || err?.message, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          notify(err?.response?.data?.errorMessage || err?.message, "error")
        );
        dispatch(
          setAdminLoading({
            userAccount: false,
          })
        );
      });
  };
};

const setCryptopiaAgreementForm = (data) => {
  return {
    type: actionTypes.SET_CRYPTOPIA_FORM,
    payload: data,
  };
};

const setCryptopiaAgreementFormTotalCount = (data) => {
  return {
    type: actionTypes.SET_CRYPTOPIA_FORM_TOTAL_COUNT,
    payload: data,
  };
};

const setCRUsers = (data) => {
  return {
    type: actionTypes.SET_CR_USERS,
    payload: data,
  };
};

export const getCryptopiaAgreementForm = (data) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let storedToken = getWithExpiry("token");

      const reqBody = data
        ? {
            // profileId: data?.id || null,
            username: data.username,
            email: data.email,
            cryptopiaEmail: data.cryptopiaEmail,
            coinsValueGT: data.coinsValueGT,
            coinsValueLT: data.coinsValueLT,
            saleOption: data.saleOption,
            limit: data.limit,
            page: data.page,
            isCoinReceivedByLiquidator: data.isCoinReceivedByLiquidator,
            isCoinTransferedToUser: data.isCoinTransferedToUser,
            isAdditionalDocs: data.isAdditionalDocs,
            search: data.search,
          }
        : {};
      // dispatch(setAdminLoading({ userAccount: true }));
      API.post("/getCryptopiaAgreementForm", JSON.stringify(reqBody), {
        headers: { token: storedToken },
        withCredentials: false,
      })
        .then((res) => {
          const { status, message, err, data, totalCount } = res.data;

          // dispatch(setAdminLoading({ userAccount: false }));
          if (status) {
            dispatch(setCryptopiaAgreementForm(data));
            dispatch(setCryptopiaAgreementFormTotalCount(totalCount));
            resolve(data);
          } else {
            notify(err?.response?.data?.errorMessage || err?.message, "error");
            reject(err);
          }
        })
        .catch((err) => {
          dispatch(
            notify(err?.response?.data?.errorMessage || err?.message, "error")
          );
          reject(err);
          // dispatch();
          // setAdminLoading({
          //   userAccount: false,
          // })
        });
    });
  };
};
export const deleteCryptopiaAgreementForm = (data) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let storedToken = getWithExpiry("token");

      const reqBody = {
        id: data?.id || null,
      };
      dispatch(setAdminLoading({ userAccount: true }));
      API.post("/optoutCryptopiaAgreementForm", JSON.stringify(reqBody), {
        headers: { token: storedToken },
        withCredentials: false,
      })
        .then(async (res) => {
          const { status, err } = res.data;

          dispatch(setAdminLoading({ userAccount: false }));
          if (status) {
            resolve(res.data);
          } else {
            reject(res.data);
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(setAdminLoading({ userAccount: false }));
        });
    });
  };
};

export const getStats = () => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    API.post("/getStats", JSON.stringify({}), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, error, errorMessage, data } = res.data;
        if (status) {
          dispatch(setCogitoStats(data));
        } else {
          notify(errorMessage || "Failed to fetch cogito stats.", "error");
        }
      })
      .catch((err) => {
        notify(
          err.response.data.message || "Failed to fetch cogito stats.",
          "error"
        );
      });
  };
};

export const getCryptopiaFormStats = () => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    API.post("/getCryptopiaAggrementStats", JSON.stringify({}), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, error, errorMessage, data } = res.data;
        if (status) {
          dispatch(setCryptopiaStats(data));
        } else {
          notify(
            errorMessage || "Failed to fetch cryptopia form stats.",
            "error"
          );
        }
      })
      .catch((err) => {
        notify(
          err.response.data.message || "Failed to fetch cryptopia form stats.",
          "error"
        );
      });
  };
};

// business acitivity

export const getBusinessActivities = () => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let body = {};

    // Add 'createdBy' and 'id' to the request body only if they are provided
    // if (createdBy !== null) {
    //  body.createdBy = createdBy;
    // }

    // if (id !== null) {
    //  body.id = id;
    // }

    API.post("/getPmActivity", JSON.stringify(body), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, succesMessage, errorMessage, data, error } = res.data;
        if (status) {
          dispatch(setBusinessActivities(data));
        } else {
          dispatch(
            notify(
              errorMessage || "Failed to fetch pm business activities",
              "error"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(
          notify(
            err?.response?.data?.message ||
              "Failed to fetch pm business activities.",
            "error"
          )
        );
      });
  };
};

const setBusinessActivities = (data) => {
  return { type: actionTypes.SET_BUSINESS_ACTIVITY, payload: data };
};

export const deleteBusinessActivity = ({ id = null }) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let body = {
      id,
    };

    API.post("/deletePmActivity", JSON.stringify(body), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, succesMessage, errorMessage, error } = res.data;
        if (status) {
          dispatch(
            notify(succesMessage || "Avtivity Deleted Successfully", "success")
          );
        } else {
          dispatch(
            notify(
              errorMessage || "Failed to delete business activity",
              "error"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(
          notify(err?.response?.data?.errorMessage || err?.message, "error")
        );
        // dispatch();
        // setAdminLoading({
        //   userAccount: false,
        // })
      });
  };
};

export const pmActivityApproval = ({ id, note, status }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
  console.log("id, note, status", id, note, status);
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");

    const reqBody = {
      id: id,
      note: note,
      status: status,
    };
    API.post("/pmActivityApproval", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error, successMessage, errorMessage } =
          res.data;
        if (status) {
          dispatch(notify(successMessage, "success"));
          dispatch(getBusinessActivities());
        } else {
          dispatch(notify(errorMessage, "error"));
        }

        if (error) dispatch(setError({ error: error }));
      })
      .catch((err) => {
        dispatch(setError({ error: err }));
        dispatch(notify(err?.response?.data?.message || err.message, "error"));
      });
  };
};

export const getCRUsers = (data) => {
  console.log("adminAction Data", data);
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");

    const reqBody = data
      ? {
          formSubmitted: data.formSubmitted,
        }
      : {};

    API.post("/getCrUsers", JSON.stringify(reqBody), {
      headers: { token: storedToken },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, err, data } = res.data;

        // dispatch(setAdminLoading({ userAccount: false }));
        if (status) {
          dispatch(setCRUsers(data));
        } else {
          notify(err?.response?.data?.errorMessage || err?.message, "error");
        }
      })
      .catch((err) => {
        dispatch(
          notify(err?.response?.data?.errorMessage || err?.message, "error")
        );
      });
  };
};

export const submitUserCryptopiaForm = (data) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let storedToken = getWithExpiry("token");
      const reqBody = data;

      API.post("/submitUserCryptopiaForm", JSON.stringify(reqBody), {
        headers: { token: storedToken },
        withCredentials: false,
      })
        .then(async (response) => {
          const { status, successMessage, errorMessage, error } = response.data;

          if (status) {
            resolve(status);
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          const errorMessage =
            err?.response?.data?.errorMessage || err?.message;
          reject(errorMessage);
        });
    });
  };
};

export const deleteAdditionalCryptopiaDocs = (data) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let storedToken = getWithExpiry("token");
      const reqBody = data;

      API.post("/deleteAdditionalCryptopiaDocs", JSON.stringify(reqBody), {
        headers: { token: storedToken },
        withCredentials: false,
      })
        .then(async (response) => {
          const { status, successMessage, errorMessage, error } = response.data;

          if (status) {
            resolve(status);
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          const errorMessage =
            err?.response?.data?.errorMessage || err?.message;
          reject(errorMessage);
        });
    });
  };
};

export const updateCryptopiaCoinsStatus = (data) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let storedToken = getWithExpiry("token");
      const reqBody = data;

      API.post("/updateCryptopiaCoinsStatus", JSON.stringify(reqBody), {
        headers: { token: storedToken },
        withCredentials: false,
      })
        .then(async (response) => {
          const { status, successMessage, errorMessage, error } = response.data;

          if (status) {
            resolve(response);
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          const errorMessage =
            err?.response?.data?.errorMessage || err?.message;
          reject(errorMessage);
        });
    });
  };
};

export const setCurrentPage = (data) => {
  return {
    type: actionTypes.SET_CURRENT_PAGE,
    payload: data,
  };
};

export const updateKyc = (data) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let storedToken = getWithExpiry("token");
      const reqBody = data;

      API.post("/updateKyc", JSON.stringify(reqBody), {
        headers: { token: storedToken },
        withCredentials: false,
      })
        .then(async (response) => {
          const { status, successMessage, errorMessage, error } = response.data;

          if (status) {
            resolve(response);
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          const errorMessage =
            err?.response?.data?.errorMessage || err?.message;
          reject(errorMessage);
        });
    });
  };
};

const setStocksCategpory = (data) => {
  return {
    type: actionTypes.SET_STOCKS_CATEGORY,
    payload: data,
  };
};

export const getStockCategories = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      name: data?.name || "",
      search: data?.search || "",
      id: data?.id || "",
      stockId: data?.stockId || "",
    };
    dispatch(setAdminLoading({ userAccount: true }));
    API.post("/getStockCategory", JSON.stringify(reqBody), {
      headers: { token: storedToken },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, err, data } = res.data;
        dispatch(setAdminLoading({ userAccount: false }));
        if (status) {
          dispatch(setStocksCategpory(data));
        } else {
          notify(err?.response?.data?.errorMessage || err.message, "error");
        }
      })
      .catch((err) => {
        dispatch(
          notify(err?.response?.data?.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            userAccount: false,
          })
        );
      });
  };
};

export const createStocksCategory = (data) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let storedToken = getWithExpiry("token");

      // Validate that the name field is not empty
      if (!data?.name) {
        const errorMessage = "Name field is required";
        dispatch(notify(errorMessage, "error"));
        return reject(new Error(errorMessage));
      }

      const reqBody = {
        name: data.name,
        description: data?.description || "",
      };

      dispatch(setAdminLoading({ userAccount: true }));

      API.post("/createStockCategory", JSON.stringify(reqBody), {
        headers: { token: storedToken },
        withCredentials: false,
      })
        .then((res) => {
          const { status, message, err, data } = res.data;
          dispatch(setAdminLoading({ userAccount: false }));
          if (status) {
            dispatch(notify("Stocks category created", "success"));
            // Dispatch getStocks
            dispatch(getStockCategories());
            resolve(data);
          } else {
            const errorMsg = err?.response?.data?.error || err?.message;
            dispatch(notify(errorMsg, "error"));
            reject(new Error(errorMsg));
          }
        })
        .catch((err) => {
          const errorMsg = err?.response?.data?.error || err?.message;
          dispatch(notify(errorMsg, "error"));
          dispatch(setAdminLoading({ userAccount: false }));
          reject(new Error(errorMsg));
        });
    });
  };
};

export const updateStocksCategory = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      id: data?.id || null,
      name: data?.name || null,
      description: data?.description || null,
    };
    dispatch(
      setAdminLoading({
        userAccount: true,
        updateStocksCategory: true,
      })
    );
    API.post("/updateStockCategory", JSON.stringify(reqBody), {
      headers: { token: storedToken },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, err, data } = res.data;
        dispatch(setAdminLoading({ userAccount: false }));
        dispatch(setAdminLoading({ updateStocksCategory: false }));
        if (status) {
          dispatch(getStockCategories());
        } else {
          notify(err?.response?.data?.errorMessage || err?.message, "error");
        }
      })
      .catch((err) => {
        dispatch(notify(err.response.data.error || err.message, "error"));
        dispatch(
          setAdminLoading({ userAccount: false }),
          dispatch(setAdminLoading({ updateStocksCategory: false }))
        );
      });
  };
};

export const deleteStocksCategory = (id) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");

    if (!id) {
      dispatch(notify("ID is required to delete a category", "error"));
      return;
    }

    dispatch(
      setAdminLoading({
        userAccount: true,
      })
    );

    try {
      const res = await API.post(
        "/deleteStockCategory",
        JSON.stringify({ id }),
        {
          headers: {
            token: storedToken,
          },
          withCredentials: false,
        }
      );

      const { status, err } = res.data;
      dispatch(
        setAdminLoading({
          userAccount: false,
        })
      );

      if (status) {
        dispatch(getStockCategories());
        dispatch(notify("Category deleted successfully!", "success"));
      } else {
        dispatch(
          notify(err?.response?.data?.errorMessage || err?.message, "error")
        );
      }
    } catch (err) {
      dispatch(
        notify(err?.response?.data?.errorMessage || err?.message, "error")
      );
      dispatch(
        setAdminLoading({
          userAccount: false,
        })
      );
    }
  };
};

export const createStockTemplate = (data) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let storedToken = getWithExpiry("token");

      const reqBody = {
        stockId: data.stockId,
        template: data.template,
      };

      dispatch(setAdminLoading({ userAccount: true }));

      API.post("/createStockTemplate", JSON.stringify(reqBody), {
        headers: { token: storedToken },
        withCredentials: false,
      })
        .then((res) => {
          const { status, message, err, data } = res.data;
          dispatch(setAdminLoading({ userAccount: false }));
          if (status) {
            dispatch(notify("Stock template created", "success"));
            resolve(data);
          } else {
            const errorMsg = err?.response?.data?.error || err?.message;
            dispatch(notify(errorMsg, "error"));
            reject(new Error(errorMsg));
          }
        })
        .catch((err) => {
          const errorMsg = err?.response?.data?.error || err?.message;
          dispatch(notify(errorMsg, "error"));
          dispatch(setAdminLoading({ userAccount: false }));
          reject(new Error(errorMsg));
        });
    });
  };
};

export const updateStockTemplate = (data) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let storedToken = getWithExpiry("token");

      const reqBody = {
        id: data.id,
        template: data.template,
      };

      dispatch(setAdminLoading({ userAccount: true }));

      API.post("/updateStockTemplate", JSON.stringify(reqBody), {
        headers: { token: storedToken },
        withCredentials: false,
      })
        .then((res) => {
          const { status, message, err, data } = res.data;
          console.log("update response");
          dispatch(setAdminLoading({ userAccount: false }));
          if (status) {
            dispatch(notify("Stock template updated", "success"));
            resolve(data);
          } else {
            const errorMsg = err?.response?.data?.error || err?.message;
            dispatch(notify(errorMsg, "error"));
            reject(new Error(errorMsg));
          }
        })
        .catch((err) => {
          const errorMsg = err?.response?.data?.error || err?.message;
          dispatch(notify(errorMsg, "error"));
          dispatch(setAdminLoading({ userAccount: false }));
          reject(new Error(errorMsg));
        });
    });
  };
};

const setStockTemplate = (data) => {
  return {
    type: actionTypes.SET_STOCK_TEMPLATE,
    payload: data,
  };
};

export const getStockTemplate = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      id: data?.id || "",
      stockId: data?.stockId || "",
      createdBy: data?.createdBy || "",
      lastUpdatedBy: data?.lastUpdatedBy || "",
      type: data?.type || null,
      isArchived: data?.isArchived || false,
    };
    console.log("result");

    dispatch(setAdminLoading({ userAccount: true }));

    API.post("/getStockTemplate", JSON.stringify(reqBody), {
      headers: { token: storedToken },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, err, data } = res.data;

        dispatch(setAdminLoading({ userAccount: false }));
        if (status) {
          dispatch(setStockTemplate(data));
        } else {
          dispatch(
            notify(err?.response?.data?.errorMessage || err.message, "error")
          );
        }
      })
      .catch((err) => {
        dispatch(
          notify(err?.response?.data?.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            userAccount: false,
          })
        );
      });
  };
};

const setStockOrderBook = (data) => {
  return {
    type: actionTypes.SET_STOCK_ORDER_BOOK,
    payload: data,
  };
};

export const getStockOrderBook = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      stockId: data?.stockId || "",
    };
    dispatch(setAdminLoading({ userAccount: true }));
    API.post("/getStockOrderBookDetail", JSON.stringify(reqBody), {
      headers: { token: storedToken },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, err, data } = res.data;
        dispatch(setAdminLoading({ userAccount: false }));
        if (status) {
          dispatch(setStockOrderBook(data));
        } else {
          notify(err?.response?.data?.errorMessage || err.message, "error");
        }
      })
      .catch((err) => {
        dispatch(
          notify(err?.response?.data?.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            userAccount: false,
          })
        );
      });
  };
};

const setStockDoc = (data) => {
  return {
    type: actionTypes.SET_STOCK_DOC,
    payload: data,
  };
};

export const getStockDoc = (data) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      stockId: data?.stockId || "",
    };
    dispatch(setAdminLoading({ uploadStockDoc: true }));
    API.post("/getStockDoc", JSON.stringify(reqBody), {
      headers: { token: storedToken },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, err, data } = res.data;
        dispatch(setAdminLoading({ uploadStockDoc: false }));
        if (status) {
          dispatch(setStockDoc(data));
        } else {
          notify(err?.response?.data?.errorMessage || err.message, "error");
        }
      })
      .catch((err) => {
        dispatch(
          notify(err?.response?.data?.errorMessage || err.message, "error")
        );
        dispatch(
          setAdminLoading({
            uploadStockDoc: false,
          })
        );
      });
  };
};
