import React, { useState, useEffect, forwardRef } from "react";
import { connect } from "react-redux";

// import { makeStyles, withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import * as actionCreator from "../../store/action/index";

import {
 Select,
 MenuItem,
 FormControl,
 InputLabel,
 Grid,
 Button,
 makeStyles,
} from "@material-ui/core";
import {
 AddBox,
 ArrowDownward,
 Check,
 ChevronLeft,
 ChevronRight,
 Clear,
 DeleteOutline,
 Edit,
 FilterList,
 FirstPage,
 LastPage,
 Remove,
 SaveAlt,
 Search,
 ViewColumn,
} from "@material-ui/icons";
import OpennModal from "./opennModal";
import { approvalType } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
 FormControl: {
  minWidth: 100,
 },
 container: {
  width: "100%",
  marginTop: theme.spacing(1),
  padding: "1% 0% 2% 3.6%",
  flexGrow: 1,
  [theme.breakpoints.up("sm")]: {
   paddingLeft: theme.spacing(8) + 1,
  },
  borderRadius: "15px",
  "&.MuiToolbar-root": {
   color: theme.palette.background.default,
  },
  "& .MuiTable-root": {
   "& .MuiTableRow-root": {
    "&:nth-child(even)": {
     background: theme.palette.background.paper,
    },
    "&:nth-child(odd)": {
     background: theme.palette.background.default,
    },
   },
  },
 },
}));

const AdminApprovalPage = (props) => {
 const classes = useStyles();
 const { loadings, admin, approvals, getApproval, assignAdmin } = props;
 //  const [onSelect, setOnSelect] = useState();
 const [appType, setAppType] = useState("AssignedApproval");
 const [approvalDetail, setApprovalDetail] = useState(false);

 const handleOpenApproval = (rowData) => {
  setApprovalDetail(rowData);
 };

 const handleAssigned = () => {
  // setOnSelect(e.target.value);
  if (admin?._id) {
   getApproval({ adminId: admin?._id });
  }
 };

 const handleOpen = () => {
  // setOnSelect(e.target.value);
  if (admin?.type && admin?.level) {
   getApproval({
    adminType: admin?.type,
    adminLevel: admin?.level,
    getUserRequest: { status: { $ne: "Cancelled" } },
   });
  }
  setAppType("openApproval");
 };

 const handleAll = () => {
  getApproval({
   // adminType: admin?.type,
   // adminLevel: admin?.level,
   getUserRequest: { status: { $ne: "Cancelled" } },
  });
  setAppType("AllApproval");
 };

 const assignCallBack = () => {
  setAppType("AssignedApproval");
 };

 useEffect(() => {
  if (admin?._id) {
   getApproval({ adminId: admin?._id });
  }
 }, [admin]);

 const rowClick = (e, rowData) => {
  window.open(`${window.location.origin}/approval/${rowData._id}`);
 };

 const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
   <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
   <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
 };

 const columns = [
  { title: "Request ID", field: "_id" },
  { title: "User ID", field: "profile_id" },
  {
   title: "Request Type",
   field: "req_type",
   render: (data) => {
    return approvalType?.[data?.req_type]?.name || "";
   },
  },
  {
   title: "Admin Level",
   field: "level_assign_to.current_level",
  },
  {
   title: "Admin Taken By",
   field: "level_assign_to.active_admin_id",
  },
  {
   title: "Status",
   field: "status",
  },
  {
   title: "OD Amount",
   field: "req_details.od_detail.od_amount",
  },
  {
   title: "Created Date",
   field: "created_at",
   filtering: false,
  },
 ];

 return (
  <React.Fragment>
   <Grid item xs={12} style={{ marginTop: "1rem" }}>
    <InputLabel id="type"> Type</InputLabel>
    <Select
     labelId="approval type"
     id="type"
     style={{ width: 160 }}
     value={appType}
     onChange={(e) => setAppType(e.target.value)}
    >
     <MenuItem value="AssignedApproval" onClick={handleAssigned} name={2}>
      Assigned Approval
     </MenuItem>
     <MenuItem value="openApproval" onClick={handleOpen} name={1}>
      Open Approval
     </MenuItem>
     {admin?.type == "Cogito" ? (
      <MenuItem value="AllApproval" onClick={handleAll} name={3}>
       All Approval
      </MenuItem>
     ) : null}
    </Select>

    <MaterialTable
     icons={tableIcons}
     title="Request"
     options={{
      filtering: false,
      toolbar: true,
      sorting: false,
      pageSize: 5,
      pageSizeOptions: [5, 10, 15],
      emptyRowsWhenPaging: false,
      maxBodyHeight: 1450,
      //  padding: "default",
      search: false,
     }}
     columns={columns}
     onRowClick={(e, rowData) =>
      rowData?.status == "Approved" ||
      rowData?.level_assign_to?.active_admin_id != ""
       ? rowClick(e, rowData)
       : handleOpenApproval(rowData)
     }
     data={approvals.sort(() => -1)}
    />
    <OpennModal open={approvalDetail} callBack={assignCallBack} />
   </Grid>
   {/* <OpennModal open={true}/> */}
  </React.Fragment>
 );
};
const mapStateToProps = ({ adminReducer }) => {
 return {
  loadings: adminReducer.loadings,
  approvals: adminReducer.approvals,
  admin: adminReducer.admin,
 };
};
const mapDispatchToProps = (dispatch) => {
 return {
  getAllAdmins: (data) => dispatch(actionCreator.getAllAdmins(data)),
  getApproval: (data) => dispatch(actionCreator.getApproval(data)),
  assignAdmin: (data) => dispatch(actionCreator.assignAdmin(data)),
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminApprovalPage);
