import React, { useState } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
// import Divider from "@material-ui/core/Divider";
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
  Tooltip,
  Link,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuRounded from "@material-ui/icons/MenuRounded";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import { DockRounded, Filter7 } from "@material-ui/icons";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import { useLocation } from "react-router-dom";
import { Assignment } from "@material-ui/icons";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import BallotIcon from "@material-ui/icons/Ballot";

import {
  AccountCircle,
  AccountTree,
  Dashboard,
  AccountBox,
  Brightness4,
  Brightness7,
  Business,
  GroupAddRounded,
} from "@material-ui/icons";

import * as actionCreator from "../../store/action/index";

import Logo from "./Logo1";
import { sideMenuAccess } from "../common/method";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  icon: {
    paddingRight: "5px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // backgroundColor:"#359DB6",
    backgroundColor: theme.palette.background.default,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    //width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down("sm")]: {
      zIndex: theme.zIndex.drawer - 1,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: "#359DB6",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    //width: theme.spacing(6) + 1,
    width: 0,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuItem: {
    color: "#359DB6",
  },
  smallView: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  largeView: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  iconButton: {
    cursor: "pointer",
    // width: 50,
    // height: 50,
    // border: "none",
    // borderRadius: 14,
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // paddingRight: 50,
    background: "transparent",
    color: "rgba(255, 255, 255, 0.6)",
    boxShadow: "none",
    "&:hover": {
      background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
      boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
    },
    "&:disabled": {
      cursor: "default",
    },
  },

  iconSelected: {
    cursor: "pointer",
    // width: 50,
    // height: 50,
    // border: "none",
    // borderRadius: 14,
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    color: "rgba(255, 255, 255, 0.6)",
    background: "#5f615f",
    boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
  },

  iconDisabled: {
    cursor: "default",
    border: "none",
    borderRadius: 14,
    // width: 50,
    // height: 50,
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    backgroundColor: "transparent",
  },
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // backgroundColor:"#359DB6",
    backgroundColor: theme.palette.background.default,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    //width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down("sm")]: {
      zIndex: theme.zIndex.drawer - 1,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: "#359DB6",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.background.default,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    //width: theme.spacing(6) + 1,
    width: 0,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
    backgroundColor: theme.palette.background.default,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuItem: {
    color: "#359DB6",
  },
  smallView: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  largeView: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

const MenuAppBar = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const { logout, admin, darkMode, changeMode, user } = props;
  const location = useLocation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuOpen = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  };

  const handleProfile = () => {
    window.location.pathname = "/profile";
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuRounded />
          </IconButton>
          <Link
            component={RouterLink}
            to="/"
            style={{ textDecoration: "none" }}
          >
            <Logo />
          </Link>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Tooltip title={admin ? admin.email : "account"}>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                style={{ color: "#359DB6" }}
              >
                <AccountCircle fontSize="large" />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ marginTop: 50 }}
              open={menuOpen}
              onClose={handleClose}
            >
              <MenuItem className={classes.menuItem}>
                <EmailIcon className={classes.icon} />
                {admin ? admin.email : "account"}
              </MenuItem>
              <Divider />

              <MenuItem onClick={handleProfile} className={classes.menuItem}>
                <PersonIcon className={classes.icon} />
                Profile
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleLogout}
                className={classes.menuItem}
                style={{ paddingRight: 2 }}
              >
                <ExitToAppIcon className={classes.icon} />
                Logout
              </MenuItem>
              <MenuItem
                onClick={changeMode}
                className={clsx(classes.menuItem, classes.smallView)}
              >
                {darkMode ? "Light Mode" : "Dark Mode"}
              </MenuItem>
            </Menu>
            <Tooltip title={darkMode ? "Light Mode" : "Dark Mode"}>
              <IconButton
                aria-label="theme"
                onClick={changeMode}
                className={clsx(classes.menuItem, classes.largeView)}
              >
                {darkMode ? <Brightness7 /> : <Brightness4 />}
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {/* <ListItem
            button
            component={RouterLink}
            to="/"
            key="home"
            onClick={handleDrawerClose}>
            <Tooltip title="Home">
             <ListItemIcon className={clsx(classes.menuItem, location.pathname === “/profile” ? classes.iconSelected : classes.iconButton)}>
                <Home />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Home" />
          </ListItem> */}
          {/* <ListItem
      button
      component={RouterLink}
      to="/profile"
      key="profile"
      onClick={handleDrawerClose}
      className={clsx(
       location.pathname === "/profile"
        ? classes.iconSelected
        : classes.iconButton
      )}
     >
      <Tooltip title="Profile">
       <ListItemIcon
        className={classes.menuItem}
        //   className={clsx(
        //    classes.menuItem,
        //    location.pathname === "/profile"
        //     ? classes.iconSelected
        //     : classes.iconButton
        //   )
        // }
        // style={{ minWidth: "100%" }}
       >
        <AccountBox />
       </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Profile" />
     </ListItem> */}

          {sideMenuAccess(admin, "Admins") ? (
            <>
              <ListItem
                button
                component={RouterLink}
                to="/admins"
                key="admins"
                onClick={handleDrawerClose}
                className={clsx(
                  location.pathname === "/admins"
                    ? classes.iconSelected
                    : classes.iconButton
                )}
              >
                <Tooltip title="Admins">
                  <ListItemIcon className={classes.menuItem}>
                    <HowToRegIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Admins" />
              </ListItem>
              <Divider />
            </>
          ) : null}

          {sideMenuAccess(admin, "Users") ? (
            <ListItem
              button
              component={RouterLink}
              to="/users"
              key="users"
              onClick={handleDrawerClose}
              className={clsx(
                location.pathname === "/users"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
            >
              <Tooltip title="users">
                <ListItemIcon className={classes.menuItem}>
                  <PeopleAltIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Users" />
            </ListItem>
          ) : null}

          {sideMenuAccess(admin, "BankAccounts") ? (
            <ListItem
              button
              component={RouterLink}
              to="/bankAccounts"
              key="bankAccounts"
              onClick={handleDrawerClose}
              className={clsx(
                location.pathname === "/bankAccounts"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
            >
              <Tooltip title="Bank Accounts ">
                <ListItemIcon className={classes.menuItem}>
                  <AccountBalanceIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Bank Accounts" />
            </ListItem>
          ) : null}

          {sideMenuAccess(admin, "Businesses") ? (
            <ListItem
              button
              component={RouterLink}
              to="/businesses"
              key="businesses"
              onClick={handleDrawerClose}
              className={clsx(
                location.pathname === "/businesses"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
            >
              <Tooltip title="business">
                <ListItemIcon className={classes.menuItem}>
                  <BusinessCenterIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Business" />
            </ListItem>
          ) : null}

          {sideMenuAccess(admin, "Companies") ? (
            <ListItem
              button
              component={RouterLink}
              to="/companies"
              key="companies"
              onClick={handleDrawerClose}
              className={clsx(
                classes.menuItem,
                location.pathname === "/companies"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
            >
              <Tooltip title="companies">
                <ListItemIcon className={classes.menuItem}>
                  <Business />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Companies" />
            </ListItem>
          ) : null}

          {sideMenuAccess(admin, "Nodes") ? (
            <ListItem
              button
              component={RouterLink}
              to="/nodes"
              key="nodes"
              onClick={handleDrawerClose}
              className={clsx(
                classes.menuItem,
                location.pathname === "/nodes"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
            >
              <Tooltip title="nodes">
                <ListItemIcon className={classes.menuItem}>
                  <AccountTree />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Nodes" />
            </ListItem>
          ) : null}

          {sideMenuAccess(admin, "AdminApproval") ? (
            <ListItem
              button
              component={RouterLink}
              to="/approval"
              key="/approval"
              onClick={handleDrawerClose}
              className={clsx(
                classes.menuItem,
                location.pathname === "/approval"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
            >
              <Tooltip title="Admin Approval">
                <ListItemIcon className={classes.menuItem}>
                  <Assignment />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Request" />
            </ListItem>
          ) : null}
          {sideMenuAccess(admin, "System") ? (
            <ListItem
              button
              component={RouterLink}
              to="/system"
              key="System"
              onClick={handleDrawerClose}
              className={clsx(
                classes.menuItem,
                location.pathname === "/system"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
            >
              <Tooltip title="System">
                <ListItemIcon className={classes.menuItem}>
                  <SystemUpdateAltIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="System" />
            </ListItem>
          ) : null}

          {sideMenuAccess(admin, "Stocks") ? (
            <ListItem
              button
              component={RouterLink}
              to="/stocks"
              key="/stocks"
              onClick={handleDrawerClose}
              className={clsx(
                classes.menuItem,
                location.pathname === "/stocks"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
            >
              <Tooltip title="Stocks">
                <ListItemIcon className={classes.menuItem}>
                  <ShowChartIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Stocks" />
            </ListItem>
          ) : null}
          {sideMenuAccess(admin, "Cryptopia") ? (
            <ListItem
              button
              component={RouterLink}
              to="/cryptopia"
              key="cryptopia"
              onClick={handleDrawerClose}
              className={clsx(
                classes.menuItem,
                location.pathname === "/cryptopia"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
            >
              <Tooltip title="Cryptopia">
                <ListItemIcon className={classes.menuItem}>
                  <GroupAddRounded />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Cryptopia" />
            </ListItem>
          ) : null}
          <ListItem
            button
            component={RouterLink}
            to="/stats"
            key="/stats"
            onClick={handleDrawerClose}
            className={clsx(
              classes.menuItem,
              location.pathname === "/stats"
                ? classes.iconSelected
                : classes.iconButton
            )}
          >
            <Tooltip title="Stats">
              <ListItemIcon className={classes.menuItem}>
                <EqualizerIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Stats" />
          </ListItem>
          {sideMenuAccess(admin, "Kyc") ? (
            <ListItem
              button
              component={RouterLink}
              to="/kyc"
              key="kyc"
              onClick={handleDrawerClose}
              className={clsx(
                classes.menuItem,
                location.pathname === "/kyc"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
            >
              <Tooltip title="Kyc">
                <ListItemIcon className={classes.menuItem}>
                  <BallotIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Kyc" />
            </ListItem>
          ) : null}
        </List>
      </Drawer>
      <div className={classes.toolbar} />
    </div>
  );
  {
    /* {admin?.level == 1 || admin?.type == "Foundation Investor" ? (
						<ListItem
							button
							component={RouterLink}
							to="/nodes"
							key="nodes"
							onClick={handleDrawerClose}
						>
							<Tooltip title="nodes">
								<ListItemIcon className={classes.menuItem}>
									<AccountTree />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="Nodes" />
						</ListItem>
					) : null} */
  }

  {
    /* {admin?.type == "Content Creater" ? ( */
  }
  //  <ListItem
  //   button
  //   component={RouterLink}
  //   to="/system"
  //   key="System"
  //   onClick={handleDrawerClose}
  //  >
  //   <Tooltip title="System">
  //    <ListItemIcon className={classes.menuItem}>
  //     <SystemUpdateAltIcon />
  //    </ListItemIcon>
  //   </Tooltip>
  //   <ListItemText primary="System" />
  //  </ListItem>;
  {
    /* ) : null} */
  }
  // 			</List>
  // 		</Drawer>
  // 		<div className={classes.toolbar} />
  // 	</div>
  // );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    admin: adminReducer.admin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actionCreator.OnLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuAppBar);
