import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Button,
  Typography,
  Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// import ThemeButton from "../../common/button";
import * as actionCreator from "../../../../../store/action";
import { connect } from "react-redux";
import CogitoCompany from "./cogitoCompany";
import NonCogitoCompany from "./nonCogitoCompany";
import CompanyRegisteredInfo from "./companyRegisteredInfo";

const CustomButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#359DB6",
    margin: "1.2rem",
    "&:hover": {
      background: "#62c3db",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#8ab0b9",
  },
})((props) => <Button {...props} />);

const SuccessButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#10ca73",
    "&:hover": {
      background: "#87f891",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#758876",
  },
})((props) => <Button {...props} />);
const useStyles = makeStyles((theme) => ({
  container: {
    // margin: "5% 0",
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    padding: "5% 0 0 0",
    textAlign: "center",
    borderRadius: "10%",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgb(58 58 58 / 70%), 4px 4px 10px rgb(0 0 0 / 70%) ",
    margin: "1%",
  },

  divider: {
    marginTop: "6%",
    marginBottom: "10%",
  },
  outerbox: {
    width: "100%",
    marginTop: "2.5rem",
    display: "flex",
    justifyContent: "space-around",
  },
}));

function CompanyPlans(props) {
  const { user, companies, filledData, getCompanyDetails } = props;
  const classes = useStyles();

  const [choosedCompany, setChoosedCompany] = useState("");
  const [component, setComponent] = useState();
  const [filledCompanyData, setFilledCompanyData] = useState(filledData);

  useEffect(() => {
    if (filledData.is_cogito_company && filledData.is_cogito_company === true) {
      setChoosedCompany("cogitoCompany");
    }
    if (
      filledData.is_cogito_company &&
      filledData.is_cogito_company === false
    ) {
      setChoosedCompany("nonCogitoCompany");
    }
  }, []);

  const setCogitoCompany = () => {
    setChoosedCompany("cogitoCompany");
    let companyInfo = filledCompanyData;
    companyInfo.is_cogito_company = true;
    setFilledCompanyData(companyInfo);
  };

  const setNonCogitoCompany = () => {
    setChoosedCompany("nonCogitoCompany");
    let companyInfo = filledCompanyData;
    companyInfo.is_cogito_company = false;
    setFilledCompanyData(companyInfo);
  };

  const cogitoCompanyOnBack = () => {
    setChoosedCompany("");
    let companyData = filledCompanyData;
    companyData.is_cogito_company = null;
    companyData.trust_name = null;
    companyData.beneficiaries = null;
    companyData.company_name = null;
    companyData.isPrivatelyHeld = null;
    companyData.number_of_shares = null;
    setFilledCompanyData(companyData);
  };

  const nonCogitoCompanyOnBack = () => {
    setChoosedCompany("");
    let companyData = filledCompanyData;
    companyData.is_cogito_company = null;
    setFilledCompanyData(companyData);
  };

  useEffect(() => {
    if (choosedCompany === "" && !filledData.is_cogito_company) {
      setComponent(
        <CompanyPlans
          cogitoCompany={setCogitoCompany}
          nonCogitoCompany={setNonCogitoCompany}
        />
      );
    }
  }, [choosedCompany]);

  useEffect(() => {
    if (companies) {
      if (companies[0].is_cogito_company && !companies[0].is_fee_paid) {
        setComponent(<CogitoCompany />);
      } else {
        setComponent(<CompanyRegisteredInfo companies={companies} />);
      }
    }
  }, [companies]);

  return (
    <>
      {user?.kyc?.status && user?.kyc?.id != null ? (
        <>
          {choosedCompany == "" ? (
            <Grid>
              <Grid container className={classes.outerbox} xs={12}>
                <Grid item xs={12} md={6} style={{ marginBottom: "1rem" }}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6" style={{ color: "#359DB6" }}>
                      Cogito company with trusts
                    </Typography>
                    <Divider className={classes.divider} />
                    <Typography variant="body1">
                      Create trust and company in Cogito Metaverse
                    </Typography>
                    <Typography variant="body1">
                      Fee for multinational company is 50,000 C
                    </Typography>
                    <Typography variant="body1">
                      Fee for privately-held company is 2500 C
                    </Typography>
                    <Typography variant="body1">
                      Control the timing of any tax due
                    </Typography>
                    <Typography variant="body1">
                      Protect assets and income
                    </Typography>
                    <Typography variant="body1">
                      Security and Privacy
                    </Typography>

                    <CustomButton
                      style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
                      onClick={setCogitoCompany}
                    >
                      Choose Plan
                    </CustomButton>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6" style={{ color: "#359DB6" }}>
                      Non-Cogito company
                    </Typography>
                    <Divider className={classes.divider} />
                    <Typography variant="body1">
                      Use an exiisting business in your local jurisdiction
                    </Typography>
                    <Typography variant="body1">
                      Account for COG transactions in your account
                    </Typography>
                    <Typography variant="body1">
                      Budget, plan and allow for COG
                    </Typography>
                    <Typography variant="body1">
                      Manage your tax obligations
                    </Typography>
                    <Typography variant="body1">
                      Security and Privacy
                    </Typography>
                    <Typography variant="body1">No Fee Applicable</Typography>

                    <CustomButton
                      style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
                      onClick={setNonCogitoCompany}
                    >
                      Choose Plan
                    </CustomButton>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          ) : choosedCompany == "cogitoCompany" ? (
            <CogitoCompany onBack={cogitoCompanyOnBack} />
          ) : (
            <NonCogitoCompany onBack={nonCogitoCompanyOnBack} />
          )}
        </>
      ) : (
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ color: "#359DB6", textAlign: "center" }}
          >
            Please Complete your KYC to register a company
          </Typography>
        </Grid>
      )}
    </>
  );
}

const mapStateToProps = ({ adminReducer }) => {
  return {
    user: adminReducer.user,
    companies: adminReducer.companies,
    filledData: adminReducer.filledData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyDetails: (data) => dispatch(actionCreator.getCompany(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPlans);
