import React, { useEffect, useState } from "react";

const HtmlRenderer = ({ setOfferingDocHtml, offeringDocHtml, isEdit }) => {
  const [htmlInput, setHtmlInput] = useState("");

  const handleRender = () => {
    if (htmlInput) {
      setOfferingDocHtml(htmlInput); // Update parent state
    }
  };

  // Initialize htmlInput with offeringDocHtml if it's in edit mode
  useEffect(() => {
    if (isEdit && offeringDocHtml) {
      setHtmlInput(offeringDocHtml);
    }
  }, [isEdit, offeringDocHtml]);

  // Update parent component's state when htmlInput changes
  useEffect(() => {
    setOfferingDocHtml(htmlInput);
  }, [htmlInput, setOfferingDocHtml]);

  return (
    <div
      style={{
        padding: "20px",
        border: "1px solid #ccc",
      }}
    >
      <div id="hideToolbar">
        <h3>HTML Input</h3>
        <textarea
          rows="40"
          style={{ width: "100%", padding: "10px" }}
          value={htmlInput}
          onChange={(e) => setHtmlInput(e.target.value)}
          placeholder="Enter HTML here..."
        />
        <button
          onClick={() => handleRender()}
          style={{
            marginTop: "10px",
            padding: "10px 20px",
            backgroundColor: "#007BFF",
            color: "#FFF",
            border: "none",
            cursor: "pointer",
          }}
        >
          Render HTML
        </button>
      </div>

      <div
        style={{
          border: "1px solid #ddd",
          padding: "10px",
          background: "#ECF0F1",
          maxWidth: "960px",
          margin: "auto",
          marginTop: "20px",
        }}
        dangerouslySetInnerHTML={{ __html: htmlInput }}
      />
    </div>
  );
};

export default HtmlRenderer;
