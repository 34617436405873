import React, { useState, useEffect, useRef, forwardRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import Spinner from "../../components/layout/Spinner/Spinner";
import * as actionCreator from "../../store/action/index";
import { connect } from "react-redux";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import DeleteFormModal from "./deleteFormModal";
import CustomizedDialog from "../../pages/dashboard/component/CustomizedCryptopiaDialog";
import { API } from "../../asset/api";
import { getWithExpiry } from "../../asset/utility";

import {
 Box,
 Button,
 FormControl,
 Grid,
 MenuItem,
 Select,
 Tab,
 Tabs,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
 detailPanel: {
  padding: theme.spacing(3),
 },
 root: {
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  flexGrow: 1,
  [theme.breakpoints.up("sm")]: {
   paddingLeft: theme.spacing(8) + 1,
  },
  borderRadius: "15px",
  "&.MuiToolbar-root": {
   //color: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
   color: theme.palette.background.default,
  },
  "& .MuiTable-root": {
   "& .MuiTableRow-root": {
    "&:nth-child(even)": {
     background: theme.palette.background.paper,
    },
    "&:nth-child(odd)": {
     background: theme.palette.background.default,
    },
   },
  },
 },
 modal: {},
 customTableButton: {
  display: "flex",
  justifyContent: "flex-end",
  padding: "2px 0px",
 },
 inputStyle: {
  //background: "#eeededd7",
  borderRadius: 8,
  border: 0,
  fontFamily: "Manrope",
 },
}));

const Cryptopia = (props) => {
 const {
  getCRUsers,
  notify,
  cryptopiaData,
  deleteCryptopiaAgreementForm,
  crUsersData,
 } = props;

 const classes = useStyles();
 const [loading, setLoading] = useState(false);
 //  const [data, setData] = useState({});
 const [open, setOpen] = useState(null);

 const [tabValue, setTabValue] = useState(0);
 const [formUserData, setFormUserData] = useState([]);

 const [filterValue, setFilterValue] = useState(null);
 const [openDialog, setDialogOpen] = React.useState(false);
 const [rowData, setRowdata] = React.useState(null);
 const [filterVal, setFilterVal] = useState(null);
 const [liquidatorCoinVal, setLiquidatorCoinVal] = useState(null);
 const [userCoinVal, setUserCoinVal] = useState(null);
 const [rowId, setRowId] = useState(null);
 const [pageSize, setPageSize] = useState(10);
 const [totalCount, setTotalCount] = useState(null);
 const [records, setRecords] = useState([]);
 const [search, setSearch] = useState(false);
 const tableRef = useRef();
 const firstLoad = useRef(true);
 const rowDataRef = useRef(null);

 useEffect(() => {
  if (totalCount >= 0) {
   if (![10, 20, 50, 100].includes(pageSize)) {
    setPageSize(totalCount);
   }
  }
 }, [totalCount]);

 useEffect(() => {
  if (!firstLoad.current) {
   tableRef.current.onQueryChange({ page: 0 });
  }
  firstLoad.current = false;
 }, [filterVal, liquidatorCoinVal, userCoinVal]);

 const getFormData = (request) =>
  new Promise((resolve, reject) => {
   let token = getWithExpiry("token");
   let body = {
    page: request.page,
    search: request.search,
    limit: request.pageSize,
    isAdditionalDocs: filterVal,
    isCoinReceivedByLiquidator: liquidatorCoinVal,
    isCoinTransferedToUser: userCoinVal,
   };
   API.post(`/getCryptopiaAgreementForm`, JSON.stringify(body), {
    headers: {
     token: token,
    },
    withCredentials: false,
   })
    .then((res) => {
     if (res.data.status) {
      if (res.data.data != null) {
       let filteredData = res.data.data.filter((item) => item._id == rowId);
       let convertedObject = {};
       filteredData.forEach((item) => {
        let keys = Object.keys(item);
        keys.forEach((key) => {
         convertedObject[key] = item[key];
        });
       });

       //  setRowdata(convertedObject);
       //  setTotalCount(res.data.totalCount);
       //  setPageSize(res?.data?.data?.length);

       resolve({
        data: res?.data?.data,
        page: request.page,
        totalCount: res?.data?.totalCount,
        pageSize: request?.pageSize,
        rowData: convertedObject,
        search: request.search,
       });
      } else {
       //  setPageSize(0);
       resolve({
        data: [],
        page: 0,
        totalCount: 0,
        search: request.search,
       });
      }
     } else {
      reject();
     }
    })
    .catch((err) => {
     setLoading(false);
     reject();
     notify(
      err?.response?.data?.message || "Couldn't fetch users try again",
      "error"
     );
    });
  });

 const handleChange1 = (event) => {
  setFilterValue(event.target.value);
 };

 const handleTabChange = (event, newValue) => {
  setTabValue(newValue);
 };

 const columns = [
  {
   title: "Kyc Status",
   field: "kycStatus",
   align: "center",
   searchable: true,
   filtering: false,
  },
  {
   title: "Document Uploaded",
   align: "center",
   searchable: true,
   field: "additionalDocs",
   render: (rowData) => {
    return rowData?.additionalDocs && rowData?.additionalDocs?.length > 0
     ? "true"
     : "false";
   },

   filterComponent: () => (
    <Select
     style={{ width: "70px" }}
     value={filterVal}
     variant={"standard"}
     onChange={(e) => {
      setFilterVal(e.target.value);
     }}
    >
     <MenuItem key={2} value={null}>
      None
     </MenuItem>
     <MenuItem key={1} value={true}>
      True
     </MenuItem>
     <MenuItem key={0} value={false}>
      False
     </MenuItem>
    </Select>
   ),
  },
  {
   title: "Verified",
   field: "isVerified",
   align: "flex-start",
   searchable: true,
   filtering: false,
  },
  {
    title: "Mail Sent",
    field: "isMailSent",
    align: "flex-start",
    searchable: true,
    filtering: false,
   },
  {
   title: "Name",
   field: "name",
   align: "flex-start",
   searchable: true,
   filtering: false,
  },
  {
   title: "Username",
   field: "username",
   align: "center",
   sorting: false,
   searchable: true,
   filtering: false,
  },
  {
   title: "Email",
   field: "email",
   align: "center",
   sorting: false,
   searchable: true,
   filtering: false,
  },
  {
   title: "Coin Received by Liquidator",
   field: "isCoinReceivedByLiquidator",
   align: "flex-start",
   searchable: true,
   sorting: false,
   filterComponent: () => (
    <Select
     style={{ width: "70px" }}
     value={liquidatorCoinVal}
     variant={"standard"}
     onChange={(e) => {
      setLiquidatorCoinVal(e.target.value);
     }}
    >
     <MenuItem key={2} value={null}>
      None
     </MenuItem>
     <MenuItem key={1} value={true}>
      True
     </MenuItem>
     <MenuItem key={0} value={false}>
      False
     </MenuItem>
    </Select>
   ),
  },
  {
   title: "Final Cog Settlement Paid",
   field: "isCoinTransferedToUser",
   align: "flex-start",
   searchable: true,
   sorting: false,
   render: (rowData) => {
    return rowData?.isCoinTransferedToUser
     ? new Date(rowData.settlementDate).toLocaleString()
     : "-";
   },
   filterComponent: () => (
    <Select
     style={{ width: "70px" }}
     value={userCoinVal}
     variant={"standard"}
     onChange={(e) => {
      setUserCoinVal(e.target.value);
     }}
    >
     <MenuItem key={2} value={null}>
      None
     </MenuItem>
     <MenuItem key={1} value={true}>
      True
     </MenuItem>
     <MenuItem key={0} value={false}>
      False
     </MenuItem>
    </Select>
   ),
  },
  {
   title: "Cryptopia Email",
   field: "cryptopiaEmail",
   align: "center",
   sorting: false,
   searchable: true,
   editable: "never",
   filtering: false,
  },
  {
   title: "Cryptopia Email Status",
   align: "center",
   searchable: true,
   filtering: false,
   render: (rowData) => {
    return rowData?.cryptopiaEmailStatus ? "Verified" : "Not Verified";
   },
  },
  {
   title: "Payment Status",
   field: "isPaymentDone",
   align: "center",
   sorting: false,
   searchable: true,
   editable: "never",
   filtering: false,
  },
  {
   title: "Documents",
   align: "center",
   sorting: false,
   searchable: true,
   render: (rowData) => {
    return rowData?.docs?.[0]?.docUrl ? (
     <a
      href={`${process.env.REACT_APP_SPACE_LINK}/${rowData?.docs?.[0]?.docUrl}`}
      target="_blank"
     >
      View Form
     </a>
    ) : (
     ""
    );
   },
   editable: false,
   filtering: false,
  },
  {
   title: "Coins Value",
   field: "coinsValue",
   align: "center",
   sorting: false,
   searchable: true,
   filtering: false,
  },
  {
   title: "Coins Type",
   field: "coinsType",
   align: "center",
   sorting: false,
   searchable: true,
   filtering: false,
  },
  {
   title: "Coins Value Matched in DB(NZD)",
   field: "cryptopiaDetail.NZD Equivalent",
   align: "center",
   sorting: false,
   searchable: false,
   filtering: false,
  },
  {
   title: "Date",
   field: "date",
   align: "center",
   sorting: false,
   searchable: true,
   filtering: false,
  },
  {
   title: "Sale Option",
   field: "saleOption",
   align: "center",
   sorting: false,
   searchable: true,
   filtering: false,
  },
 ];
 const columns1 = [
  {
   title: "First Name",
   field: "firstName",
   align: "center",
   searchable: true,
  },
  {
   title: "Last Name",
   field: "lastName",
   align: "center",
   sorting: false,
   searchable: true,
  },

  {
   title: "CR Email",
   field: "crEmail",
   align: "center",
   sorting: false,
   searchable: true,
  },
  {
   title: "Cryptopia Email",
   field: "cryptopiaEmail",
   align: "center",
   sorting: false,
   searchable: true,
   editable: "never",
  },
 ];

 const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),

  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
   <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
 };

 useEffect(() => {
  getCRUsers({ formSubmitted: filterValue });
 }, [filterValue]);

 useEffect(() => {
  setFormUserData(formUserData);
 }, [formUserData]);

 useEffect(() => {
  if (
   Object.keys(crUsersData).length === 0 &&
   crUsersData.constructor === Object
  ) {
   setFormUserData([]);
  } else {
   setFormUserData(crUsersData);
  }
 }, [crUsersData]);

 const handleOpenDeleteModal = (rowData) => {
  if (!rowData?.isOptedOut) {
   setOpen(rowData?._id);
   setRowId(rowData?._id);
  } else {
   notify("Form is already opted out.", "error");
  }
 };

 const onDelete = () => {
  deleteCryptopiaAgreementForm({ id: open })
   .then((res) => {
    const { status, err } = res;
    if (status) {
     setTotalCount(totalCount - 1);
     setPageSize(pageSize - 1);
     tableRef.current.onQueryChange();
     notify("Deleted", "success");
    } else {
     notify(err?.response?.data?.errorMessage || err?.message, "error");
    }
   })
   .catch((err) => {
    notify(err?.response?.data?.errorMessage || err?.message, "error");
   });
  setOpen(null);
 };

 const onClose = () => {
  setOpen(null);
 };
 const handleClose = () => {
  setOpen(false);
  setDialogOpen(false);
  setRowdata(null);
 };

 const handleMainDialogOpen = (rowData) => {
  setDialogOpen(true);
  setRowdata(rowData);
  setRowId(rowData?._id);
 };

 const newDataCB = (filteredData) => {
  setRowdata(filteredData);
 };

 const updateTable = () => {
  tableRef.current.onQueryChange();
 };

 return (
  <Box className={classes.root}>
   <div className={classes.root1}>
    <Tabs
     value={tabValue}
     onChange={handleTabChange}
     TabIndicatorProps={{
      style: {
       backgroundColor: "#359DB6",
      },
     }}
    >
     <Tab label="  Cryptopia Form Users" />
     <Tab label="CR All Users" />
    </Tabs>

    {tabValue === 1 && (
     <>
      <div>
       <FormControl
        style={{
         width: "30%",
         height: "6%",
         marginTop: "2em",
         paddingTop: "1em",
         paddingBottom: "1em",
         // paddingBottom: "2em",
         // paddingTop: "2em",
        }}
       >
        <Select
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         value={filterValue}
         label="Age"
         onChange={handleChange1}
         displayEmpty
         style={{
          paddingTop: "1em",
          paddingBottom: "1em",
         }}
        >
         <MenuItem value={null}>All Users</MenuItem>
         <MenuItem value={false}>Form Not Submitted</MenuItem>
         <MenuItem value={true}>Form Submitted</MenuItem>
        </Select>
       </FormControl>
      </div>
      <Grid container style={{ maxWidth: "100%" }}>
       <DeleteFormModal
        open={open}
        title={`Optout user with application id: ${open}`}
        onClose={onClose}
        deleteTask={onDelete}
       />
       <Grid item xs={12}>
        {!loading ? (
         <MaterialTable
          icons={tableIcons}
          title="Users"
          columns={columns1}
          options={{
           pageSize: 10,
           pageSizeOptions: [10, 25, 50, 100, 200],
           exportAllData: true,
           exportButton: true,
           emptyRowsWhenPaging: false,
           maxBodyHeight: 450,
           columnsButton: true,
           showFirstLastPageButtons: false,
           search: true,
           padding: "dense",
          }}
          data={formUserData}
         />
        ) : (
         <Spinner />
        )}
       </Grid>
      </Grid>
     </>
    )}

    {tabValue === 0 && (
     <Grid>
      <DeleteFormModal
       open={open}
       title={`Optout user with application id: ${open}`}
       onClose={onClose}
       deleteTask={onDelete}
      />

      <Grid item xs={12}>
       {!loading ? (
        <>
         <MaterialTable
          icons={tableIcons}
          title="Cryptopia Users"
          columns={columns}
          tableRef={tableRef}
          onChangeRowsPerPage={setPageSize}
          options={{
           pageSize: pageSize,
           debounceInterval: 500,
           pageSizeOptions: !search
            ? [10, 20, 50, 100, { value: totalCount, label: "All" }]
            : [10, 20, 50, 100],
           exportAllData: true,
           exportButton: {
            csv: true,
            pdf: false,
           },
           emptyRowsWhenPaging: false,
           maxBodyHeight: 450,
           columnsButton: true,
           showFirstLastPageButtons: false,
           search: true,
           padding: "dense",
           filtering: true,
           exportCsv: (columns, data) => {
            const exportData = data.map((row) => ({
             "Kyc Status": row?.kycStatus,
             "Document Uploaded":
              row?.additionalDocs && row?.additionalDocs?.length > 0
               ? "true"
               : "false",
             Verified: row?.isVerified,
             Name: row?.name,
             Username: row?.username,
             Email: row?.email,
            'Mail Sent':row?.isMailSent,
             "Coin Received by Liquidator": row?.isCoinReceivedByLiquidator,
             "Final Cog Settlement Paid": row?.isCoinTransferedToUser
              ? new Date(row.settlementDate)
              : "-",
             "Cryptopia Email": row?.cryptopiaEmail,
             "Cryptopia Email Status": row?.cryptopiaEmailStatus
              ? "Verified"
              : "Not Verified",
             "Payment Status": row?.isPaymentDone,
             Documents: row?.docs?.[0]?.docUrl
              ? `<a href="${process.env.REACT_APP_SPACE_LINK}/${row?.docs[0].docUrl}" target="_blank">View Form</a>`
              : "",
             "Coins Value": row?.coinsValue,
             "Coins Type": row?.coinsType,
             "Coins Value Matched in DB(NZD)":
              row.cryptopiaDetail?.["NZD Equivalent"],
             Date: row?.date,
             "Sale Option": row?.saleOption,
            }));

            const csvColumns = columns.map((col) => col.title);

            const csvData = exportData.map((row) =>
           
             csvColumns.map((col) => row[col])
            );

            csvData.unshift(csvColumns);

            const csvContent = csvData.map((row) => row.join(",")).join("\n");

            const blob = new Blob([csvContent], {
             type: "text/csv;charset=utf-8;",
            });

            const link = document.createElement("a");
            if (link.download !== undefined) {
             const url = URL.createObjectURL(blob);
             link.setAttribute("href", url);
             link.setAttribute("download", "export.csv");
             link.style.visibility = "hidden";
             document.body.appendChild(link);
             link.click();
             document.body.removeChild(link);
            }
           },

           rowStyle: (rowData) => ({
            backgroundColor: rowData.isCoinTransferedToUser ? "#773D31" : "",
           }),
          }}
          actions={[
           {
            icon: () => <DeleteOutline />,
            tooltip: "Delete Application",
            onClick: (event, rowData) => handleOpenDeleteModal(rowData),
           },
           (rowData) => ({
            icon: () => <Edit />,
            tooltip: "Edit Admin",
            onClick: (event, rowData) => {
             handleMainDialogOpen(rowData);
            },
           }),
          ]}
          data={(query) =>
           getFormData(query)
            .then((result) => {
             if (result?.data) {
              setTotalCount(result.totalCount);
              setRecords(result.data);
              setSearch(result?.search?.length > 0);
              setRowdata(result?.rowData);
              return result;
             }
            })
            .catch((err) => {
             console.log(err);
            })
          }
         />
        </>
       ) : (
        <Spinner />
       )}
      </Grid>

      <CustomizedDialog
       open={openDialog}
       data={rowData}
       parentMethod={handleClose}
       newDataCB={newDataCB}
       updateTable={updateTable}
      />
     </Grid>
    )}
   </div>
  </Box>
 );
};

const mapStateToProps = ({ adminReducer }) => {
 return {
  admin: adminReducer.admin,
  cryptopiaData: adminReducer.cryptopiaData,
  cryptopiaAgreementCount: adminReducer.cryptopiaAgreementCount,
  crUsersData: adminReducer.crUsersData,
  loadings: adminReducer.loadings,
  currentPage: adminReducer.currentPage,
 };
};

const mapDispatchToProps = (dispatch) => {
 return {
  logout: () => dispatch(actionCreator.OnLogout()),
  notify: (message, varient) =>
   dispatch(actionCreator.notify(message, varient)),
  getCryptopiaAgreementForm: (data) =>
   dispatch(actionCreator.getCryptopiaAgreementForm(data)),
  setCurrentPage: (data) => dispatch(actionCreator.setCurrentPage(data)),
  deleteCryptopiaAgreementForm: (data) =>
   dispatch(actionCreator.deleteCryptopiaAgreementForm(data)),
  getCRUsers: (data) => dispatch(actionCreator.getCRUsers(data)),
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cryptopia);
