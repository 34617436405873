import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import * as actionCreator from "../../../store/action/index";
import { Box, Button, Typography } from "@material-ui/core";
import ApprovalDocUpload from "./ApprovalDocUpload";
import UploadFees from "./UploadFees";
import DocumentCheck from "./DocumentCheck";
import ApprovalStatus from "./ApprovalStatus";
import { approvalType } from "../../../utils/constants";
import UserDetailContainer from "./UserDetailContainer";

const useStyles = makeStyles((theme) => ({
  detailPanel: {
    padding: theme.spacing(3),
  },
  root: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8) + 1,
    },
    borderRadius: "15px",
    "&.MuiToolbar-root": {
      //color: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
      color: theme.palette.background.default,
    },
    "& .MuiTable-root": {
      "& .MuiTableRow-root": {
        "&:nth-child(even)": {
          background: theme.palette.background.paper,
        },
        "&:nth-child(odd)": {
          background: theme.palette.background.default,
        },
      },
    },
  },
  container: {
    minHeight: 50,
    borderRadius: 10,
    overflow: "hidden",
    width: "100%",
    backgroundColor: "#272727",
    padding: 8,
  },
  innerContainer: {
    width: "100%",
    padding: 12,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  navigationRow: {
    width: "80%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  detailRow: {
    marginBottom: 16,
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  detailTextStyle: {
    fontSize: 14,
    fontWeight: 500,
  },
  back: {
    fontSize: 12,
    fontStyle: "normal",
    color: "#FF7300",
    textTransform: "capitalize",
    fontWeight: 600,
  },
  next: {
    fontSize: 12,
    fontStyle: "normal",
    color: "#359DB6",
    border: "1px solid #359DB6",
    borderRadius: 5,
    padding: 2,
    textTransform: "capitalize",
    fontWeight: 600,
  },
  modal: {},
  customTableButton: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "2px 0px",
  },
  inputStyle: {
    borderRadius: 8,
    border: 0,
    fontFamily: "Manrope",
  },
}));

const ApprovalContent = (props) => {
  const { approvalDetail, admin, approvalAction, getUser, user } = props;
  const classes = useStyles();
  const backStatusArray = ["Approved", "Rejected", "Cancelled"];
  const nextStatusArray = ["update_process_fee", "document_check", "status"];
  const activeStep = backStatusArray.includes(approvalDetail?.status)
    ? "status"
    : approvalDetail?.level_assign_to?.active_action;
  const activeStepIndex = approvalDetail?.actions?.[activeStep]?.index;
  const nextStep = Object.keys(approvalDetail?.actions || {})
    ?.map((action) => approvalDetail?.actions?.[action])
    ?.filter((action) => action.index == activeStepIndex + 1)[0];
  const nextStepHandler = () => {
    approvalAction();
  };
  const backStepHandler = () => {
    approvalAction({
      action: "assign_to_lower",
    });
  };

  const redirectToUserPage = () => {
    window.open(`${window.location.origin}/user/${user?._id}`);
  };

  const isAuthorisedAdmin =
    (approvalDetail?.level_assign_to?.current_level &&
      approvalDetail?.level_assign_to?.admin_type &&
      approvalDetail?.level_assign_to?.current_level === admin?.level &&
      approvalDetail?.level_assign_to?.admin_type === admin?.type) ||
    admin?.type == "Cogito";

  useEffect(() => {
    approvalDetail?.profile_id && getUser(approvalDetail?.profile_id);
  }, [approvalDetail]);

  return (
    <Box className={classes.container}>
      <Box className={classes.innerContainer}>
        <Box className={classes.navigationRow}>
          {approvalDetail &&
          activeStepIndex &&
          !backStatusArray.includes(approvalDetail?.status || "") &&
          isAuthorisedAdmin ? (
            <Button className={classes.back} onClick={backStepHandler}>
              Back
            </Button>
          ) : (
            <Box></Box>
          )}
          {!nextStatusArray.includes(activeStep) && isAuthorisedAdmin && (
            //   ||
            //     nextStep?.admin_assigned?.admin_id
            <Button className={classes.next} onClick={nextStepHandler}>
              Next
            </Button>
          )}
        </Box>
        {user ? (
          <>
            <Box className={classes.detailRow}>
              <UserDetailContainer
                index={1}
                title="User Id:"
                value={user._id}
                onClick={redirectToUserPage}
              />
            </Box>
            <Box className={classes.detailRow}>
              <UserDetailContainer
                index={2}
                title="User Email:"
                value={user.email}
              />
            </Box>
            <Box className={classes.detailRow}>
              <UserDetailContainer
                index={3}
                title="User Name:"
                value={`${user.firstName} ${user.lastName}`}
              />
            </Box>
            <Box className={classes.detailRow}>
              <UserDetailContainer
                index={4}
                title="Approval Type:"
                value={approvalType?.[approvalDetail.req_type]?.name || ""}
              />
            </Box>
            {approvalDetail?.req_details?.od_detail?.od_amount ? (
              <Box className={classes.detailRow}>
                <UserDetailContainer
                  index={5}
                  title="Overdraft Amount:"
                  value={approvalDetail?.req_details?.od_detail?.od_amount}
                />
              </Box>
            ) : null}
          </>
        ) : null}

        {isAuthorisedAdmin ? (
          <>
            {activeStep == "contact_user" && <ApprovalDocUpload />}
            {activeStep == "update_process_fee" && <UploadFees />}
            {activeStep == "document_check" && <DocumentCheck />}
            {activeStep == "status" && <ApprovalStatus />}
          </>
        ) : (
          <Typography>
            Authorised Admin will make changes on this step
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    approvalDetail: adminReducer.approvalDetail,
    admin: adminReducer.admin,
    user: adminReducer.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
    approvalAction: (data) => dispatch(actionCreator.approvalAction(data)),
    getUser: (data) => dispatch(actionCreator.getUser(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalContent);
