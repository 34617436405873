
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
} from "@material-ui/core";
import {
  AccountCircle,
} from "@material-ui/icons";
import MaterialTable from "material-table";
import { forwardRef, useRef } from "react";

import * as actionCreator from "../../../../store/action/index";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline"
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { TablePagination } from "@material-ui/core";
import Styles from "../../../../components/styles";

const useStyles = makeStyles((theme) => ({
	listroot: {
    marginTop: theme.spacing(1),
     marginLeft:"1rem",
    padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
     paddingLeft: theme.spacing(8) + 1,
    },
    "&.MuiToolbar-root": {
			//color: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
			color: theme.palette.background.default,
		},
		"& .MuiTable-root": {
			"& .MuiTableRow-root": {
				"&:nth-child(even)": {
					background: theme.palette.background.paper,
				},
				"&:nth-child(odd)": {
					background: theme.palette.background.default,
				},
			},
		},
  

		boxShadow: localStorage.getItem("dark")
   ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
   : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
	},

}));
const SponsorList = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const {
    admin,
    getAdmin,
    ResetErrors,
    sponsorList,
    getSponsorList,
  } = props
  const [id, setId] = useState(useParams().id || null);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <AccountCircle {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const tableRef = useRef();
  const rowClick1 = (e, rowData) => {
    window.open(`${window.location.origin}/user/${rowData.profile_id}`);
  };

  useEffect(() => {
    if (!admin) getAdmin();
    if (!sponsorList) getSponsorList(id);

    return () => {
      ResetErrors();
    };
  }, [ResetErrors]);


  return (
    <React.Fragment>
      <Grid container  className={classes.listroot} >
        <Grid item xs={11}>
          <MaterialTable
          
            tableRef={tableRef}
            icons={tableIcons}
            title="Sponsor List"
            columns={[
              {
                title: "Name",
                render: (rowData) =>
                  rowData.first_name + " " + rowData.last_name,
                headerStyle: {
                  backgroundColor: theme.palette.background.paper,
                },
              },
              {
                title: "Username",
                field: "user_name",
                cellStyle: {
                  color: "#FF7300",
                },
                headerStyle: {
                  backgroundColor: theme.palette.background.paper,
                },
              },

              {
                title: "Affiliates",
                field: "sponsored_count",
                cellStyle: {
                  color: "#359DB6",
                  textAlign: "left",
                },
                headerStyle: {
                  backgroundColor: theme.palette.background.paper,
                },
              },
            ]}
            options={{
              exportAllData: true,
              exportButton: true,
              emptyRowsWhenPaging: false,
              maxBodyHeight: 450,
              columnsButton: true,
              showFirstLastPageButtons: false,
              search: true,
              rowStyle: (rowData) => ({
                fontWeight:
                  rowData.sponsored_count >= 1 ? "bold" : "normal",
              }),
            }}
            onRowClick={(e, rowData) => rowClick1(e, rowData)}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                />
              ),
            }}
            data={sponsorList ? sponsorList : []}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )



}
const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    admin: adminReducer.admin,
    sponsorList: adminReducer.sponsorList,
    user: adminReducer.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
    getAdmin: () => dispatch(actionCreator.getAdmin()),
    getUser: (data) => dispatch(actionCreator.getUser(data)),
    getSponsorList: (data) => dispatch(actionCreator.getSponsorList(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SponsorList);