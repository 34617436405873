import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Select, TextField } from "@material-ui/core";
import { Edit, DeleteForeverOutlined } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ReactHtmlParser from "react-html-parser";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    position: "relative",
  },
  editIconButton: {
    // position: "absolute",
    top: 0,
    right: "1rem",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  closeIconButton: {
    // position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
  },
  iconStyle: {
    color: "#aaa",
  },
  inputFieldStyle: {
    width: "80%",
  },
}));

function BulletpointFieldAnnouncementTemplate(props) {
  const {
    data,
    handleUpdate,
    handleDelete,
    index,
    customContainerStyle,
    isUpdate,
  } = props;

  const classes = useStyles();

  const [editList, setEditList] = useState();

  const handleDeleteList = (i) => {
    if (data?.text?.length == 1) {
      handleDelete(index);
    } else {
      let list = (data.text || [])?.filter((_, index) => i != index);
      handleUpdate(list, index);
    }
  };

  const handleEditText = (value, i) => {
    let list = (data.text || [])?.map((data, index) => {
      if (i != index) {
        return data;
      } else {
        return { ...data, text: value };
      }
    });
    handleUpdate(list, index);
  };

  const handleChangeBulletType = (value) => {
    if ((value?.length || 0) <= 1) {
      handleUpdate(value, index, "bulletType");
    }
  };

  const handleAddList = () => {
    let list = data.text || [];
    list.push(individualBulletList);
    handleUpdate(list, index);
  };

  const individualBulletList = {
    text: "",
    type: null,
    placeholder: "Empty Bullet Point",
    style: {},
  };

  useEffect(() => {
    if (!data?.text?.length) {
      handleAddList();
    }
  }, []);

  return (
    <Box
      className={classes.container}
      style={{
        ...(customContainerStyle || { display: "flex", alignItems: "center" }),
      }}
    >
      <text style={{ ...data.style }}>
        {
          <ol
            style={{
              listStyleType: data.bulletType ? `"${data.bulletType}"` : null,
            }}
          >
            {data.text?.map((d, i) => {
              return (
                <li key={i}>
                  {editList == i ? (
                    <>
                      <Box className={classes.editIconButton}>
                        <TextField
                          value={d.text}
                          className={clsx(
                            classes.inputFieldStyle,
                            "announcemenrtTenplateInput"
                          )}
                          onChange={(e) => {
                            handleEditText(e.target.value, i);
                          }}
                          focused={editList == i}
                          placeholder={d.placeholder}
                        />
                        <Button className={classes.closeIconButton}>
                          <CloseIcon
                            className={classes.iconStyle}
                            onClick={() => setEditList(null)}
                          />
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Box className={classes.editIconButton}>
                      {ReactHtmlParser(
                        `${data.bulletType ? "&nbsp;" : ""}${d.text}`
                      )}
                      <Box className={classes.editIconButton}>
                        {handleUpdate && isUpdate ? (
                          <Button
                            onClick={() => setEditList(i)}
                            style={{ maxWidth: 25, minWidth: 0 }}
                          >
                            <Edit className={classes.iconStyle} />
                          </Button>
                        ) : null}
                        {handleDelete && isUpdate ? (
                          <Button
                            className={classes.deleteIconButton}
                            onClick={() => handleDeleteList(i)}
                            style={{ maxWidth: 25, minWidth: 0 }}
                          >
                            <DeleteForeverOutlined
                              className={classes.iconStyle}
                            />
                          </Button>
                        ) : null}
                      </Box>
                    </Box>
                  )}
                </li>
              );
            })}
          </ol>
        }
      </text>

      <Box className={classes.editIconButton}>
        {/* {handleDelete && isUpdate ? (
          <Button
            className={classes.deleteIconButton}
            onClick={() => handleDelete(index)}
            style={{ maxWidth: 25, minWidth: 0 }}
          >
            <DeleteForeverOutlined className={classes.iconStyle} />
          </Button>
        ) : null} */}
        {handleUpdate && isUpdate ? (
          <Button
            className={classes.deleteIconButton}
            onClick={() => handleAddList()}
            style={{ maxWidth: 25, minWidth: 0 }}
          >
            <AddCircleOutlineIcon className={classes.iconStyle} />
          </Button>
        ) : null}
        {handleUpdate && isUpdate ? (
          <TextField
            value={data.bulletType}
            className={clsx(
              classes.inputFieldStyle,
              "announcemenrtTenplateInput"
            )}
            onChange={(e) => {
              handleChangeBulletType(e?.target?.value);
            }}
            style={{ maxWidth: 30 }}
            focused={true}
            placeholder={data.placeholder}
          />
        ) : null}
      </Box>
    </Box>
  );
}
BulletpointFieldAnnouncementTemplate.defaultProps = {
  isUpdate: true,
};
export default BulletpointFieldAnnouncementTemplate;
