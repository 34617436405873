import React, { useState, useEffect, useRef, forwardRef } from "react";
import { connect } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Paper,
  Divider,
  TextField,
  Select,
  MenuItem,
  Typography,
  Container,
  FormControl,
  IconButton,
  Link,
} from "@material-ui/core";
import { PublishSharp, CloudUpload, Delete } from "@material-ui/icons";
import { isEmpty } from "lodash";
import moment from "moment";
import { getWithExpiry, updateObjectProperty } from "../../asset/utility";
import { API } from "../../asset/api";

import * as actionCreator from "../../store/action/index";
import { componentAccess } from "../common/method";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8) + 1,
    },
    borderRadius: "15px",
    "&.MuiToolbar-root": {
      //color: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
      color: theme.palette.background.default,
    },
    "& .MuiTable-root": {
      "& .MuiTableRow-root": {
        "&:nth-child(even)": {
          background: theme.palette.background.paper,
        },
        "&:nth-child(odd)": {
          background: theme.palette.background.default,
        },
      },
    },
  },
  paper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3),
    borderRadius: 10,
    marginTop: 10,
  },
}));

const CustomButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#359DB6",
    "&:hover": {
      background: "#62c3db",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#8ab0b9",
  },
})((props) => <Button {...props} />);

const SuccessButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#10ca73",
    "&:hover": {
      background: "#87f891",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#758876",
  },
})((props) => <Button {...props} />);
const CompanyDetails = (props) => {
  const { admin, getAdmin, ResetErrors, notify, companyData } = props;
  const classes = useStyles();
  const [allCompanies, setAllCompanies] = useState(null);
  const [activeCompany, setActiveCompany] = useState(companyData);
  const [type, setType] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [nameChecking, setNameChecking] = useState(false);
  const [inputCompany, setInputCompany] = useState(null);
  const [companyErrors, setCompanyErrors] = useState({
    company_name: "",
    number_of_directors: "",
    number_of_shareholders: "",
  });
  const [companyMod, setCompanyMod] = useState(false);
  const [countries, setCountries] = useState();

  const getCompanies = () => {
    let token = getWithExpiry("token");
    API.post(
      `/getCompanies`,
      {},
      {
        headers: {
          token: token,
        },
        withCredentials: false,
      }
    )
      .then((res) => {
        if (res.data.status) {
          if (res.data.companies?.length > 0) {
            setAllCompanies(res.data.companies);
            setActiveCompany(companyData);
          } else {
            notify("Currently their are no companies", "error");
          }
        }
      })
      .catch((err) => {
        notify(
          err?.response?.data?.message ||
            err?.response?.data?.error?.errorMessage ||
            "Coudn't fetch companies please try again",
          "error"
        );
      });
  };
  const sendFile = () => {
    //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

    let storedToken = getWithExpiry("token");
    let data = new FormData();
    data.append("file", uploadFile);
    data.append("type", type);
    data.append("profile_id", activeCompany.profile_id);
    data.append("token", storedToken);
    setUploading(true);

    API.post("/UploadCompanyFile", data, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        const { status, message, error } = res.data;
        notify(message, status ? "success" : "error");
        getCompanies();
        setUploading(false);
        setUploadFile(null);
        setType("");
      })
      .catch((err) => {
        notify(
          err?.response?.data?.message ||
            err?.response?.data?.error?.errorMessage ||
            "Coudn't upload file... please try again",
          "error"
        );
        setUploading(false);
      });
  };

  const removeFile = (type) => {
    let token = getWithExpiry("token");
    setUploading(true);
    API.post(
      `/RemoveCompanyDoc`,
      {
        type: type,
        profile_id: activeCompany.profile_id,
      },
      {
        headers: {
          token: token,
        },
        withCredentials: false,
      }
    )
      .then((res) => {
        const { status, message, error } = res.data;
        notify(message, status ? "success" : "error");
        getCompanies();
        setUploading(false);
      })
      .catch((err) => {
        notify(
          err?.response?.data?.message ||
            err?.response?.data?.error?.errorMessage ||
            "Coudn't remove company document please try again",
          "error"
        );
        setUploading(false);
      });
  };

  const checkNameExists = (key, name) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      key: key,
      name: name,
    };
    API.post("/CheckNameExists", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, errorMessage, successMessage, error, exists, key } =
          res.data;
        if (status) {
          if (!exists) {
            if (key === "company") {
              setCompanyErrors(
                updateObjectProperty(companyErrors, "company_name", "")
              );
            }
          } else {
            if (key === "company") {
              setCompanyErrors(
                updateObjectProperty(
                  companyErrors,
                  "company_name",
                  "Company of this name already exists"
                )
              );
            }
          }
        } else {
          notify(errorMessage, "error");
        }
        setNameChecking(false);
      })
      .catch((err) => {
        notify(err?.response?.data?.errorMessage, "error");
        setNameChecking(false);
      });
  };

  const updateCompany = () => {
    let token = getWithExpiry("token");

    API.post(
      `/UpdateCompany`,
      {
        profile_id: activeCompany.profile_id,
        update: inputCompany,
      },
      {
        headers: {
          token: token,
        },
        withCredentials: false,
      }
    )
      .then((res) => {
        const { status, message, error } = res.data;
        notify(message, status ? "success" : "error");
        getCompanies();
      })
      .catch((err) => {
        notify(
          err?.response?.data?.message ||
            err?.response?.data?.error?.errorMessage ||
            "Coudn't update company data please try again",
          "error"
        );

        onBack();
      });
  };

  const validateField = (field, value) => {
    let companyErrorsCopy = { ...companyErrors };

    if (field === "company_name") {
      if (value.trim().length === 0) {
        companyErrorsCopy.company_name = "Company name is required";
      } else if (value === activeCompany?.company_name) {
        companyErrorsCopy.company_name = "";
      }
      //   else {
      //     checkNameExists("company",value.trim());
      //   }
    } else if (field === "number_of_directors") {
      if (!value) {
        companyErrorsCopy.number_of_directors = "Director count is required";
      } else {
        companyErrorsCopy.number_of_directors = "";
      }
    } else if (field === "number_of_shareholders") {
      if (!value) {
        companyErrorsCopy.number_of_shareholders =
          "Shareholder count is required";
      } else {
        companyErrorsCopy.number_of_shareholders = "";
      }
    }

    setCompanyErrors(companyErrorsCopy);
  };

  useEffect(() => {
    var headers = new Headers();
    headers.append("X-CSCAPI-KEY", process.env.REACT_APP_ADDRESS_API_KEY);

    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
      .then((response) => response.json())
      .then((response) => {
        let countries = response;
        countries.push({ name: "Cogito", iso2: "COG" });
        setCountries(countries);
      })
      .catch((error) =>
        notify("Coudn't fetch countries please try again", "error")
      );
  }, []);

  useEffect(() => {
    if (!admin) {
      getAdmin();
    }
    if (!allCompanies && admin?.level <= 4) {
      getCompanies();
    }
    return () => {
      ResetErrors();
    };
  }, [ResetErrors, admin]);

  useEffect(() => {
    if (inputCompany) {
      for (const property in inputCompany) {
        if (inputCompany[property] == activeCompany[property]) {
          delete inputCompany[property];
        }
      }
      if (isEmpty(inputCompany)) setInputCompany(null);
    }
  }, [activeCompany, inputCompany]);

  useEffect(() => {
    if (inputCompany?.company_name) {
      setNameChecking(true);
      const timeoutId = setTimeout(() => {
        checkNameExists("company", inputCompany.company_name);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [inputCompany?.company_name]);

  const onTypeValueChanged = (e) => {
    let value = e.target.value;
    setType(value);
  };

  const onFileChange = (e) => {
    if (e.target.files[0].size / 1024 / 1024 < 5) {
      setUploadFile(e.target.files[0]);
    } else {
      notify("Document Size should be less than 5 MB", "error");
    }
  };

  const fileUpload = (e) => {
    e.preventDefault();
    if (activeCompany?.documents && type in activeCompany?.documents) {
      notify(
        "The Document already exists. Please delete the old one to upload a new one",
        "error"
      );
    } else if (type.length == 0)
      notify("Please Select a document type", "error");
    else if (activeCompany && type?.length) sendFile();
  };

  const onCompanyValueChanged = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setInputCompany(updateObjectProperty(inputCompany, name, value.trim()));
    validateField(name, value);
  };

  const onCompanyValueChanged1 = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setInputCompany(
      updateObjectProperty(inputCompany, name, parseInt(value.trim()))
    );
    validateField(name, value);
  };

  const onBack = () => {
    setCompanyMod(!companyMod);
    setInputCompany(null);
    setCompanyErrors({
      company_name: "",
      number_of_directors: "",
      number_of_shareholders: "",
    });
  };

  const UpdateactiveCompany = () => {
    if (activeCompany && inputCompany != null) {
      updateCompany();
      setInputCompany(null);
      setCompanyMod(!companyMod);
    } else {
      notify("Improper data", "error");
    }
    getCompanies();
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper} elevation={5}>
        <Container>
          <Grid container spacing={2}>
            <Grid item container xs={12} justify="space-between">
              <Grid item>
                <Typography variant="h5" align="left">
                  Company Details
                </Typography>
              </Grid>
              <Grid item>
                {companyMod ? (
                  <Grid item container spacing={1}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={onBack}
                        // disabled={nameChecking}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid item>
                      <SuccessButton
                        disabled={
                          !(
                            companyErrors?.company_name?.length === 0 &&
                            companyErrors?.number_of_directors?.length === 0 &&
                            companyErrors?.number_of_shareholders?.length ===
                              0 &&
                            allCompanies &&
                            inputCompany
                          )
                        }
                        onClick={UpdateactiveCompany}
                      >
                        Save
                      </SuccessButton>
                    </Grid>
                  </Grid>
                ) : componentAccess(
                    admin?.level,
                    admin?.type,
                    "Companies",
                    "updateCompanyDetails"
                  ) ? (
                  <Grid item>
                    <CustomButton
                      onClick={() => setCompanyMod(!companyMod)}
                      disabled={!Boolean(activeCompany)}
                    >
                      Modify
                    </CustomButton>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 5, marginBottom: 10 }}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" align="left">
                Profile Id
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" align="left">
                {activeCompany?.profile_id}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" align="left">
                Company Type
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" align="left">
                {activeCompany?.is_cogito_company
                  ? "Cogito Company"
                  : "Non-Cogito Company"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" align="left">
                Company Name
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {!companyMod ? (
                <Typography variant="body2" align="left">
                  {activeCompany?.company_name}
                </Typography>
              ) : (
                <TextField
                  variant="outlined"
                  type="text"
                  fullWidth
                  name="company_name"
                  label="Company Name"
                  defaultValue={activeCompany?.company_name}
                  onChange={onCompanyValueChanged}
                  error={companyErrors?.company_name?.length > 0}
                  helperText={companyErrors?.company_name}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" align="left">
                Country Of Registration
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" align="left">
                {activeCompany?.country_of_registration}
              </Typography>
            </Grid>
            {activeCompany?.is_cogito_company ? null : (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" align="left">
                    Registration Number
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" align="left">
                    {activeCompany?.registration_number}
                  </Typography>
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={6}>
              <Typography variant="h6" align="left">
                Number Of Directors
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {!companyMod ? (
                <Typography variant="body2" align="left">
                  {activeCompany?.number_of_directors}
                </Typography>
              ) : (
                <TextField
                  variant="outlined"
                  type="number"
                  fullWidth
                  name="number_of_directors"
                  label="Company Director"
                  defaultValue={activeCompany?.number_of_directors}
                  onChange={onCompanyValueChanged1}
                  error={companyErrors?.number_of_directors?.length > 0}
                  helperText={companyErrors?.number_of_directors}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" align="left">
                Number Of Shareholders
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              {!companyMod ? (
                <Typography variant="body2" align="left">
                  {activeCompany?.number_of_shareholders}
                </Typography>
              ) : (
                <TextField
                  variant="outlined"
                  type="number"
                  fullWidth
                  name="number_of_shareholders"
                  label="Company Shareholders"
                  defaultValue={activeCompany?.number_of_shareholders}
                  onChange={onCompanyValueChanged1}
                  error={companyErrors?.number_of_shareholders?.length > 0}
                  helperText={companyErrors?.number_of_shareholders}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" align="left">
                Is Authorized To Register
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" align="left">
                {activeCompany?.is_authorized_to_register ? "yes" : "No"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" align="left">
                Fee paid
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" align="left">
                {activeCompany?.is_fee_paid ? "Paid" : "Not Paid"}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
              <Divider />
            </Grid>

            {activeCompany?.is_cogito_company ? (
              <>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6" align="left">
                    <strong>Director Details</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
                  <Divider />
                </Grid>

                {activeCompany?.director_details
                  ? activeCompany.director_details?.map((node) => {
                      return (
                        <Grid item container key={node.first_name}>
                          <Grid item xs={12} sm={6}>
                            Name
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {node?.first_name}
                            {node?.last_name ? " " + node?.last_name : ""}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            Date of Birth
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {node?.dob
                              ? moment(node?.dob).utc().format("Do MMMM YYYY")
                              : "N/A"}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            Address
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {node?.address_details?.address}
                          </Grid>
                          <Grid item xs={12} sm={6} />
                          <Grid item xs={12} sm={6}>
                            {node?.address_details?.country}
                            {node?.address_details?.state
                              ? " , " + node?.address_details?.state
                              : ""}
                          </Grid>
                          <Grid item xs={12} sm={6} />
                          <Grid item xs={12} sm={6}>
                            {node?.address_details?.city}
                            {node?.address_details?.pincode
                              ? " , " + node?.address_details?.pincode
                              : ""}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{ marginTop: 5, marginBottom: 5 }}
                          >
                            <Divider />
                          </Grid>
                        </Grid>
                      );
                    })
                  : null}

                <Grid item xs={12} sm={12}>
                  <Typography variant="h6" align="left">
                    <strong>Shareholder Details</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
                  <Divider />
                </Grid>
                {activeCompany?.shareholder_details
                  ? activeCompany.shareholder_details?.map((node) => {
                      return (
                        <Grid item container key={node.first_name}>
                          <Grid item xs={12} sm={6}>
                            Name
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {node?.first_name}
                            {node?.last_name ? " " + node?.last_name : ""}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            Date of Birth
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {node?.dob
                              ? moment(node?.dob).utc().format("Do MMMM YYYY")
                              : "N/A"}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            Address
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {node?.address_details?.address}
                          </Grid>
                          <Grid item xs={12} sm={6} />
                          <Grid item xs={12} sm={6}>
                            {node?.address_details?.country}
                            {node?.address_details?.state
                              ? " , " + node?.address_details?.state
                              : ""}
                          </Grid>
                          <Grid item xs={12} sm={6} />
                          <Grid item xs={12} sm={6}>
                            {node?.address_details?.city}
                            {node?.address_details?.pincode
                              ? " , " + node?.address_details?.pincode
                              : ""}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{ marginTop: 5, marginBottom: 5 }}
                          >
                            <Divider />
                          </Grid>
                        </Grid>
                      );
                    })
                  : null}

                <Grid item xs={12} sm={12}>
                  <Typography variant="h6" align="left">
                    <strong>Trust Details</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
                  <Divider />
                </Grid>
                {activeCompany?.trust_details ? (
                  <React.Fragment>
                    <Grid item xs={12} sm={6}>
                      Trust Name
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {activeCompany?.trust_details?.trust_name}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <strong>Protector Details</strong>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      Name
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {activeCompany?.trust_details?.protector?.first_name}
                      {activeCompany?.trust_details?.protector?.last_name
                        ? " " +
                          activeCompany?.trust_details?.protector?.last_name
                        : ""}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      Date of Birth
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {activeCompany?.trust_details?.protector?.dob
                        ? moment(activeCompany?.trust_details?.protector?.dob)
                            .utc()
                            .format("Do MMMM YYYY")
                        : "N/A"}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      Address
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {
                        activeCompany?.trust_details?.protector?.address_details
                          ?.address
                      }
                    </Grid>
                    <Grid item xs={12} sm={6} />
                    <Grid item xs={12} sm={6}>
                      {
                        activeCompany?.trust_details?.protector?.address_details
                          ?.country
                      }
                      {activeCompany?.trust_details?.protector?.address_details
                        ?.state
                        ? " , " +
                          activeCompany?.trust_details?.protector
                            ?.address_details?.state
                        : ""}
                    </Grid>
                    <Grid item xs={12} sm={6} />
                    <Grid item xs={12} sm={6}>
                      {
                        activeCompany?.trust_details?.protector?.address_details
                          ?.city
                      }
                      {activeCompany?.trust_details?.protector?.address_details
                        ?.pincode
                        ? " , " +
                          activeCompany?.trust_details?.protector
                            ?.address_details?.pincode
                        : ""}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      Trustee
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {activeCompany?.trust_details?.trustee}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      Settlor
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {activeCompany?.trust_details?.settlor}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <strong>Beneficiaries</strong>
                    </Grid>
                    {activeCompany?.trust_details?.beneficiaries
                      ? activeCompany.trust_details.beneficiaries?.map(
                          (node) => {
                            return (
                              <Grid item container key={node.first_name}>
                                <Grid item xs={12} sm={6}>
                                  Name
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  {node?.first_name}
                                  {node?.last_name ? " " + node?.last_name : ""}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  Date of Birth
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  {node?.dob
                                    ? moment(node?.dob)
                                        .utc()
                                        .format("Do MMMM YYYY")
                                    : "N/A"}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  Address
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  {node?.address_details?.address}
                                </Grid>
                                <Grid item xs={12} sm={6} />
                                <Grid item xs={12} sm={6}>
                                  {node?.address_details?.country}
                                  {node?.address_details?.state
                                    ? " , " + node?.address_details?.state
                                    : ""}
                                  {node?.address_details?.country +
                                    " " +
                                    node?.address_details?.state}
                                </Grid>
                                <Grid item xs={12} sm={6} />
                                <Grid item xs={12} sm={6}>
                                  {node?.address_details?.city}
                                  {node?.address_details?.pincode
                                    ? " , " + node?.address_details?.pincode
                                    : ""}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    marginTop: 5,
                                    marginBottom: 5,
                                  }}
                                >
                                  <Divider />
                                </Grid>
                              </Grid>
                            );
                          }
                        )
                      : null}
                  </React.Fragment>
                ) : null}
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6" align="left">
                    <strong>Documents</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
                  <Divider />
                </Grid>
                {activeCompany?.documents
                  ? Object.keys(activeCompany?.documents).map((key) => {
                      return (
                        <React.Fragment key={key}>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant="body1"
                              align="left"
                              gutterBottom
                            >
                              <Link
                                href={
                                  process.env.REACT_APP_SPACE_LINK +
                                  "/" +
                                  activeCompany?.documents[key]
                                }
                                underline="hover"
                                target="_blank"
                                color="inherit"
                              >
                                <strong>{key}</strong>
                              </Link>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Button
                              variant="outlined"
                              startIcon={<Delete />}
                              onClick={() => removeFile(key)}
                              fullWidth
                              disabled={uploading}
                            >
                              Delete
                            </Button>
                          </Grid>
                        </React.Fragment>
                      );
                    })
                  : null}
                {componentAccess(
                  admin?.level,
                  admin?.type,
                  "Companies",
                  "updateCompanyDetails"
                ) ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      style={{ marginTop: 5, marginBottom: 5 }}
                    >
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" align="left">
                        Upload File
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ marginTop: 5, marginBottom: 5 }}
                    >
                      <Divider />
                    </Grid>

                    <form
                      onSubmit={fileUpload}
                      method="post"
                      style={{ width: "100%" }}
                    >
                      <Grid item container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <FormControl style={{ width: "100%" }}>
                            <Select
                              variant="outlined"
                              id="fileType"
                              name="fileType"
                              label="Select Type"
                              value={type}
                              onChange={onTypeValueChanged}
                            >
                              <MenuItem value={"INCORPORATION_CERTIFICATE"}>
                                Certificate Of Incorporation
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <>
                          <Grid item xs={12} md={6}>
                            <FormControl style={{ width: "100%" }}>
                              <input
                                accept=".pdf,"
                                style={{ display: "none", width: "100%" }}
                                id="file"
                                name="file"
                                onChange={onFileChange}
                                type="file"
                              />
                              <label htmlFor="file">
                                <IconButton
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <PublishSharp />
                                </IconButton>
                                {uploadFile ? (
                                  <Typography
                                    variant="body1"
                                    display="block"
                                    gutterBottom
                                  >
                                    {uploadFile.name}
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                  >
                                    File shouldn't me more than 5 MB
                                  </Typography>
                                )}
                                {/* <Typography variant="caption" display="block" gutterBottom>
																																		File shouldn't me more than 5 MB
																																</Typography> */}
                              </label>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl style={{ width: "100%" }}>
                              <CustomButton
                                size="large"
                                type="submit"
                                fullWidth
                                disabled={
                                  uploading ||
                                  !Boolean(uploadFile) ||
                                  !type.length
                                }
                                startIcon={<CloudUpload />}
                              >
                                Upload
                              </CustomButton>
                            </FormControl>
                          </Grid>
                        </>
                        //
                      </Grid>
                    </form>
                  </>
                ) : null}
              </>
            ) : null}
          </Grid>
        </Container>
      </Paper>
    </React.Fragment>
  );
};
const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    admin: adminReducer.admin,
    admins: adminReducer.admins,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
    getAdmin: () => dispatch(actionCreator.getAdmin()),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
