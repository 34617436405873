import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab/";

import * as actionCreator from "../../store/action/index";
import { getWithExpiry } from "../../asset/utility";
import { API } from "../../asset/api";
import { componentAccess } from "../common/method";

const useStyles = makeStyles({
 root: {
  // marginTop: "3%",
  marginBottom: "3%",
 },
});

const AddresInput = (props) => {
 const {
  notify,
  getData,
  defaultCountry,
  defaultState,
  defaultCity,
  defaultPincode,
  country,
  state,
  city,
  postcode,
  admin,
 } = props;

 const [countries, setCountries] = useState();
 const [countriesId, setCountriesId] = useState();
 const [selectedCountry, setSelectedCountry] = useState();

 const [states, setStates] = useState();
 const [statesId, setStatesId] = useState();
 const [selectedState, setSelectedState] = useState();

 const [cities, setCities] = useState();
 const [selectedCity, setSelectedCity] = useState();

 const [pincode, setPincode] = useState();

 const [ans, setAns] = useState({
  country: country ? country : "",
  state: state ? state : "",
  city: city ? city : "",
  pincode: postcode ? postcode : "",
 });
 const [changedData, setChangedData] = useState({});
 const classes = useStyles();

 useEffect(() => {
  API.post(
   "/getCountries",
   {},
   {
    headers: {},
    withCredentials: false,
   }
  )
   .then((res) => {
    const { status, successMessage, errorMessage, countries, error } = res.data;
    if (status) {
     setCountries(countries);
     let countryCode = {};
     if (countries) {
      countries.map((country) => {
       countryCode[country.name] = country.id;
      });
      setCountriesId(countryCode);
     }

     res.successMessage = successMessage;
    } else {
     res.errorMessage = errorMessage;
    }
   })
   .catch((err) => {
    notify(err?.response?.data?.errorMessage, "error");
   });
 }, []);

 useEffect(() => {
  if (selectedCountry && selectedCountry !== "Cogito") {
   var selectedCountryId = countriesId[selectedCountry];

   let reqBody = {
    countryId: selectedCountryId,
   };
   API.post("/getStates", JSON.stringify(reqBody), {
    headers: {},
    withCredentials: false,
   }).then((res) => {
    const { status, successMessage, errorMessage, states, error } = res.data;
    if (status) {
     setStates(states);
     if (props?.statesList) props.statesList(states);
     let statesCode = {};
     if (states) {
      states &&
       states.map((state) => {
        statesCode[state.name] = state.id;
       });
      setStatesId(statesCode);
     }

     res.successMessage = successMessage;
    } else {
     setStates(["Cogito"]);
    }
   });
  }
 }, [selectedCountry, countriesId]);

 useEffect(() => {
  if (selectedCountry && selectedState && selectedCountry !== "Cogito") {
   var selectedCountryId = countriesId[selectedCountry];
   var selectedStateId = statesId[selectedState];

   let reqBody = {
    countryId: selectedCountryId,
    stateId: selectedStateId,
   };

   API.post("/getCities", JSON.stringify(reqBody), {
    headers: {},
    withCredentials: false,
   }).then((res) => {
    const { status, successMessage, errorMessage, cities, error } = res.data;
    if (status) {
     setCities(cities);
     if (props?.citiesList) props.citiesList(cities);
     res.successMessage = successMessage;
    } else {
     setStates(["Cogito"]);
    }
   });
  }
 }, [selectedState]);

 useEffect(() => {
  if (props?.citiesList) props.citiesList(cities);
 }, [cities]);
 useEffect(() => {
  if (props?.statesList) props.statesList(states);
 }, [states]);

 const handleChange = (name, value) => {
  if (name === "country") {
   setSelectedCountry(value);
   setAns({ ...ans, country: value });
   setChangedData({ ...changedData, country: value });
  } else if (name === "state") {
   setSelectedState(value);
   setAns({ ...ans, state: value });
   setChangedData({ ...changedData, state: value });
  } else if (name === "city") {
   setSelectedCity(value);
   setAns({ ...ans, city: value });
   setChangedData({ ...changedData, city: value });
  } else if (name === "pincode") {
   setPincode(value);
   setAns({ ...ans, pincode: value });
   setChangedData({ ...changedData, pincode: value });
  }
 };

 //sending data to parent component
 useEffect(() => {
  // only changed Data
  if (props.getChangedData) {
   props.getChangedData(changedData);
  }

  // both changed and unchanged Data
  if (props.getData) {
   props.getData(ans);
  }
  if (props.dataToParent) {
   props.dataToParent(ans);
  }
 }, [ans]);

 return (
  <>
   {countries ? (
    <>
     <Grid
      item
      className={classes.root}
      container
      xs={12}
      justifyContent="space-between"
     >
      <Grid item xs={6}>
       <Autocomplete
        disabled={false}
        defaultValue={props.country}
        id="country"
        name="country"
        onChange={(e, value) => handleChange("country", value)}
        options={countries ? countries?.map((country) => country.name) : []}
        noOptionsText={"No country"}
        renderInput={(params) => (
         <TextField
          {...params}
          variant="outlined"
          label="Country"
          required
          style={{ width: "95%" }}
          disabled={
           !componentAccess(
            admin?.level,
            admin?.type,
            "Businesses",
            "updateBusinessDetails"
           )
          }
         />
        )}
       />
      </Grid>
      <Grid item xs={6}>
       <Autocomplete
        disabled={false}
        defaultValue={props.state}
        id="state"
        options={
         states
          ? states?.map((state) => state.name)
          : props.renderStatesList
          ? props.renderStatesList?.map((state) => state.name)
          : []
        }
        onChange={(e, value) => handleChange("state", value)}
        noOptionsText={"Select country"}
        renderInput={(params) => (
         <TextField
          style={{ width: "95%" }}
          {...params}
          variant="outlined"
          label="State"
          disabled={
           !componentAccess(
            admin?.level,
            admin?.type,
            "Businesses",
            "updateBusinessDetails"
           )
          }
         />
        )}
       />
      </Grid>
     </Grid>
     <Grid
      item
      className={classes.root}
      container
      xs={12}
      justifyContent="space-between"
     >
      <Grid item xs={6}>
       <Autocomplete
        disabled={false}
        defaultValue={props.city}
        id="city"
        options={
         cities
          ? cities?.map((city) => city.name)
          : props.renderCitiesList
          ? props.renderCitiesList?.map((city) => city.name)
          : []
        }
        onChange={(e, value) => handleChange("city", value)}
        noOptionsText={"select state"}
        renderInput={(params) => (
         <TextField
          style={{ width: "95%" }}
          {...params}
          variant="outlined"
          label="City"
          disabled={
           !componentAccess(
            admin?.level,
            admin?.type,
            "Businesses",
            "updateBusinessDetails"
           )
          }
         />
        )}
       />
      </Grid>
      <Grid item xs={6}>
       <TextField
        style={{ width: "95%" }}
        fullWidth
        disabled={
         !componentAccess(
          admin?.level,
          admin?.type,
          "Businesses",
          "updateBusinessDetails"
         )
        }
        defaultValue={postcode}
        label="Postcode"
        onChange={(e, value) => handleChange("pincode", Number(e.target.value))}
        type="number"
        variant="outlined"
        required
       />
      </Grid>
     </Grid>
    </>
   ) : null}
  </>
 );
};

const mapStateToProps = ({ adminReducer }) => {
 return {
  admin: adminReducer.admin,
 };
};
// const mapDispatchToProps = (dispatch) => {
//   return {
//     notify: (message, varient) =>
//       dispatch(actionCreator.notify(message, varient)),
//   };
// };

export default connect(mapStateToProps)(AddresInput);
