import { connect } from "react-redux";
import React, { useEffect } from "react";
import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import * as actionCreator from "../../../store/action/index";
import moment from "moment";

const useStyles = makeStyles((theme) => ({}));

const NewsLettersList = (props) => {
  const classes = useStyles();

  const { newsLetters } = props;
  const [newsLettersList, setNewsLettersList] = React.useState([]);
  useEffect(() => {
    setNewsLettersList([...newsLetters.filter((row) => row.sent_at)]);
  }, [newsLetters]);
  return (
    <Box>
      {newsLettersList.length ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Newsletter Id</TableCell>
                <TableCell align="center">Admin Id</TableCell>
                <TableCell align="center">Sent At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newsLettersList.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row._id}</TableCell>
                  <TableCell align="center">{row.admin_id}</TableCell>
                  <TableCell align="center">
                    {row?.sent_at
                      ? moment(row?.sent_at).format("YYYY-MM-DD HH:mm:ss")
                      : "NA"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </Box>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    newsLetters: adminReducer.newsLetters,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsLettersList);
