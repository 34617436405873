import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import { Delete as DeleteIcon, Add as AddIcon } from "@material-ui/icons";
import "./ImageSlider.css";
const useStyles = makeStyles((theme) => ({
  imageSlider: {
    margin: theme.spacing(2, "auto"),
  },
  carousel: {
    maxWidth: 800,
    margin: "0 auto",
    height: 400,
  },
  slide: {
    height: "100%",
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  imageContainer: {
    position: "relative",
    height: "100%",
  },
  deleteButton: {
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(5),
    objectFit: "contain",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
    },
  },
  addImageButton: {
    display: "block",
    margin: theme.spacing(2, "auto"),
  },
  controlArrow: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: theme.palette.common.white,
    // padding: theme.spacing(1),
    transition: theme.transitions.create(["background-color"]),
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
  },
  carouselStatus: {
    fontSize: 14,
    textShadow: "1px 1px 1px rgba(0, 0, 0, 0.5)",
  },
  controlDots: {
    bottom: 0,
    padding: theme.spacing(1, 0),
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  dot: {
    boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.9)",
  },
}));
const ImageSlider = ({
  images,
  onImageUpload,
  onImageDelete,
  UploadDocs,
  notify,
}) => {
  const classes = useStyles();

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      onImageUpload(file);
    }
  };

  return (
    <div className={classes.imageSlider} style={{ margin: "20px auto" }}>
      <Carousel
        showThumbs={false}
        infiniteLoop
        useKeyboardArrows
        autoPlay
        dynamicHeight={false}
        className={classes.carousel}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              className={`control-arrow control-prev `}
            >
              &lt;
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              className={`control-arrow control-next `}
            >
              &gt;
            </button>
          )
        }
        statusFormatter={(current, total) => (
          <span className={classes.carouselStatus}>
            {current} of {total}
          </span>
        )}
      >
        {images.map((img, index) => (
          <div key={index} className={classes.imageContainer}>
            <img
              src={img}
              alt={`Slide ${index + 1}`}
              className={classes.image}
            />
            <IconButton
              onClick={() => onImageDelete(index)}
              className={classes.deleteButton}
              title="Delete Image"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      </Carousel>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={handleImageUpload}
      />
      <label htmlFor="raised-button-file">
        <Button
          variant="contained"
          component="span"
          startIcon={<AddIcon />}
          // className={classes.addImageButton}
        >
          Add Image
        </Button>
      </label>
    </div>
  );
};

export default ImageSlider;
