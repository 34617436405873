import React, { forwardRef, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import * as actionCreator from "../../store/action/index";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
  IconButton,
  Tooltip,
  Switch,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MaterialTable from "material-table";
import { Autocomplete } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

import "jspdf-autotable";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  VerifiedUserRounded,
  ViewColumn,
  Description,
} from "@material-ui/icons";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import TransitionsModal from "../../components/common/modal";
import Smallspinner from "../../components/common/smallSpinner";
import StockListingTemplatePage from "../../components/common/StockListingTemplatePage";
import StockCategory from "../../components/common/StockCategory"; // Import the StockCategory component
import { API } from "../../asset/api";
import { getWithExpiry } from "../../asset/utility";
import UserStockTxns from "./user/components/userStockTxn";
import DescriptionIcon from "@material-ui/icons/Description";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8) + 1,
    },
    borderRadius: "15px",
    "&.MuiToolbar-root": {
      color: theme.palette.background.default,
    },
    "& .MuiTable-root": {
      "& .MuiTableRow-root": {
        "&:nth-child(even)": {
          background: theme.palette.background.paper,
        },
        "&:nth-child(odd)": {
          background: theme.palette.background.default,
        },
      },
    },
  },
  customTableButton: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "2px 0px",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
  },
  cloudIcon: {
    fontSize: 60,
    color: "#359DB6",
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
    },
  },
  image_size: {
    height: "60px",
    width: "60px",
  },
  empty_image: {
    position: "relative",
    cursor: "pointer",
    height: "120px",
    width: "220px",
    maxWidth: "100%",
    // background:'red',
    borderRadius: "5px",
    border: "1px dashed #359DB6",
    cursor: "pointer",
    // marginLeft: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  inputStyle: {
    //background: "#eeededd7",
    borderRadius: 8,
    border: 0,
    fontFamily: "Manrope",
  },
  modelContainer: {
    padding: 10,
  },
  modelForm: {
    marginTop: "1.2rem",
  },
  modelSubmit: {
    marginTop: "1.2rem",
    textAlign: "center",
  },
  formContainer: {
    marginTop: 8,
  },
  dropBoxContainer: {
    width: "100%",
    backgroundColor: "#144C59",
    borderRadius: 5,
    padding: 8,
    marginTop: 16,
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
  },
  fileContainer: {
    width: "96%",
    marginTop: 8,
  },
  fileBox: {
    padding: 8,
    marginTop: 4,
    width: "100%",
    backgroundColor: "#303030",
    border: "1px solid #8F8D8D",
    borderRadius: 5,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  addButton: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "white",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    margin: "auto",
    marginBottom: "10px",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.4)",
    },
  },
  deleteIcon: {
    color: "#FF7300",
    display: "flex",
    alignItems: "center",
  },
  fileBoxLeft: {
    display: "flex",
    alignItems: "center",
  },
  fileImg: {
    display: "flex",
    alignItems: "center",
  },
  fileName: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    fontWeight: 400,
    margin: "0% 2%",
  },
  detailsModelForm: {
    // height: "60vh",
    // width: "auto",
    // overflowY: "scroll",
  },
  ExchangeDetails: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  detailsTitle: {
    // margin: "0 6px",
    fontSize: "1rem",
    fontWeight: "bolder",
    // textDecoration: "underline",
  },
  detailBox: {
    padding: 8,
    marginTop: 4,
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #8F8D8D",
    borderRadius: 5,
  },
  detailBox2: {
    padding: 8,
    marginTop: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  prospectusContainer: {
    marginTop: 8,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  createStockButton: {
    backgroundColor: "rgb(53, 157, 182)",
    color: "white",
    [`&.disable`]: {
      backgroundColor: "#9e9d9d",
    },
  },
  formData: {
    [`& .MuiFormHelperText-contained {
      `]: {
      marginLeft: "0",
      marginRight: "0",
    },
  },
  docRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "#2c2c2c", // Dark background to match your theme
    borderRadius: "6px",
    marginBottom: "10px",
  },
  docName: {
    color: "#ffffff",
    fontSize: "16px",
    flex: 1, // Flex-grow to take up space
  },
  fileIcon: {
    border: "1px solid #1a1a1a",
    position: "relative",
    color: "#ff5252", // Red color for the delete icon
    transition: "transform 0.2s ease",
    "&:hover": {
      transform: "scale(1.1)", // Slight scale effect on hover
    },
  },
  deleteBtn: {
    position: "absolute",
    bottom: 0,
    right: 0,
    color: "#ff5252", // Red color for the delete icon
    transition: "transform 0.2s ease",
    "&:hover": {
      transform: "scale(1.1)", // Slight scale effect on hover
    },
  },
  noDocs: {
    color: "#bdbdbd",
    textAlign: "center",
    marginTop: "20px",
  },
  libraryHeading: {
    fontSize: "22px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  documentList: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
    flexGrow: 1,
    overflowY: "auto",
  },
  documentItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 0",
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)", // Light border for glass effect
  },
  documentIcon: {
    width: "30px",
    height: "30px",
    backgroundColor: "rgba(255, 255, 255, 0.3)", // Semi-transparent background
    borderRadius: "4px",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  documentDetails: {
    display: "flex",
    flexDirection: "column",
  },
  documentName: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  documentDate: {
    fontSize: "14px",
    color: "#ccc", // Light text for glass effect
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function Stocks(props) {
  const {
    notify,
    loadings,
    uploadDocs,
    getRecommends,
    recommendations,
    admin,
    stocks,
    createStock,
    getStocks,
    updateStocks,
    updateStockLogo,
    assignStock,
    stockCategoryList,
    getStockCategory,
    getAllStocksTransaction,
    getPortfolio,
    userStocks,
    allStockTxns,
    getStockDoc,
    uploadStockDoc,
    deleteStockDoc,
    stockDocs,
  } = props;

  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);
  const [openModal, setOpenModal] = React.useState("");
  const [modelData, setModelData] = React.useState({ status: "Disabled" });
  const options = [
    "Stocks",
    "Stock Listing",
    "Stock Category",
    "Stock Transactions",
  ];
  const [showError, setShowError] = useState(false);
  const [validCheck, setValidCheck] = useState(false);
  const [imageURLforFront, setImageURLforFront] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [openDialog, setDialogOpen] = React.useState(false);
  const [assignStockModal, setAssignStockModal] = useState(false);
  const [assignTo, setAssignTo] = useState(null);
  const [assignToID, setAssignToID] = useState(null);
  const [stockToAssign, setStockToAssign] = useState(null);
  const [exportCalled, setExportCalled] = useState(false);
  const [aStockCategory, setaStockCategories] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null); // State for selected row data
  const [orderStatus, setOrderStatus] = useState("completed");

  const fileInputRef = useRef(null);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    if (activeTab === 0) {
      getStocks();
    }
    if (activeTab === 3) {
      getAllStocksTransaction({
        orderStatus: orderStatus,
      });
    }
  }, [activeTab, orderStatus]);

  const onFileChange = (e, field) => {
    //    976 kb
    if (e.target?.files?.[0]?.size <= 5242880) {
      let keyName = "";
      setImageURLforFront(URL.createObjectURL(e.target.files[0]));
      keyName = e.target.name;

      setUploadFile(e.target?.files?.[0]);
      // setUploadFileKey(keyName);
    } else {
      notify("Document Size should be less than 5 MB", "warning");
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files?.[0];
    if (file && modelData) {
      console.log({ modelData });
      await uploadStockDoc(file, modelData?.stockId);
      await getStockDoc({ stockId: modelData?.stockId });
    } else {
      console.error("Stock ID is missing or no file selected.");
    }
  };

  const handleMainDialogOpen = (rowData) => {
    setDialogOpen(true);
  };

  const handleCreateTemplate = (rowData) => {
    setSelectedRowData(rowData);
    setActiveTab(1); // Switch to the second tab (Stock Listing)
  };

  const exportCSV = async (rowData) => {
    setExportCalled(true);
    await getPortfolio({ stockId: rowData?.stockId });
  };

  // useEffect(() => {
  //   const doc = new jsPDF();
  //   if (userStocks.length > 0 && exportCalled) {
  //     doc.autoTable({
  //       head: [["StockId", "Stock Name", "Owner Id", "Quantity", "Invested"]],
  //       body: userStocks?.map((row) => [
  //         row.stockId,
  //         row.stockName,
  //         row.profileId,
  //         row.quantity,
  //         row.avgInvested,
  //       ]),
  //     });

  //     doc.save("table.pdf");
  //     setExportCalled(false);
  //   } else if (exportCalled) {
  //     setExportCalled(false);
  //     notify("No stock owner found", "warning");
  //   }
  // }, [userStocks]);

  useEffect(() => {
    if (userStocks.length > 0 && exportCalled) {
      // Export to CSV
      const headers = [
        // "StockId",
        "Stock Name",
        "Owner Username",
        "Quantity Owned",
        "Invested",
      ];
      const rows = userStocks.map((row) => [
        // row.stockId,
        `${row.stockName}`,
        row.username,
        row.quantity,
        row.avgInvested,
      ]);

      let csvContent =
        "data:text/csv;charset=utf-8," +
        headers.join(",") +
        "\n" +
        rows.map((e) => e.join(",")).join("\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "stocks.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setExportCalled(false);
    } else if (exportCalled) {
      setExportCalled(false);
      notify("No stock owner found", "warning");
    }
  }, [userStocks]);

  const columns =
    activeTab === 0
      ? [
          // {
          //   title: "Stock ID",
          //   field: "StockId",
          //   render: (rowData) => rowData?.stockId || "",
          //   editable: false,
          // },
          {
            title: "Stock Name",
            field: "stockName",
            render: (rowdata) => rowdata?.stockName,
            editable: true,
          },
          {
            title: "Stock Logo",
            field: "stockLogo",
            render: (rowdata) => (
              <img
                src={`${process.env.REACT_APP_SPACE_LINK}/${rowdata?.stockLogo}`}
                style={{ width: 65, height: 65, objectFit: "cover" }}
              />
            ),
            editable: false,
          },
          // {
          //   title: "Stock Category",
          //   field: "stock_category",
          //   render: (rowdata) => rowdata.stock_category?.join(", ") || "No Category",
          //   editable: true,
          // },
          {
            title: "Total Quantity",
            field: "totalQuantity",
            render: (rowdata) => rowdata.totalQuantity || "-",
            editable: true,
          },
          {
            title: "Assigned Quantity",
            field: "assignedQuantity",
            render: (rowdata) => rowdata.assignedQuantity || "0",
            editable: true,
          },
          {
            title: "Stock Price CB75CO25",
            field: "stockPriceInCB75CO25",
            render: (rowdata) => rowdata?.stockPrice[0]?.currentPrice,
            editable: true,
          },
          {
            title: "Stock Price CB50CO50",
            field: "stockPriceInCB50CO50",
            render: (rowdata) => rowdata?.stockPrice[1]?.currentPrice,
            editable: true,
          },
          {
            title: "Stock Price CB25CO75",
            field: "stockPriceInCB25CO75",
            render: (rowdata) => rowdata?.stockPrice[2]?.currentPrice,
            editable: true,
          },
          {
            title: "Stock Price COG100",
            field: "stockPriceInCOG100",
            render: (rowdata) => rowdata?.stockPrice[3]?.currentPrice,
            editable: true,
          },
          {
            title: "Stock Price CBC100",
            field: "stockPriceInCBC100",
            render: (rowdata) => rowdata?.stockPrice[4]?.currentPrice,
            editable: true,
          },
          {
            title: "Actions",
            field: "actions",
            editable: false,
            render: (rowData) => (
              <IconButton
                className={classes.createTemplateButton}
                onClick={() => handleCreateTemplate(rowData)}
              >
                <Description />
              </IconButton>
            ),
          },
          {
            title: "Stock Owners List",
            field: "abc",
            render: (rowdata) => (
              <Button
                variant="contained"
                color="primary"
                style={{ fontSize: "0.6rem" }}
                onClick={() => exportCSV(rowdata)}
              >
                Export to CSV
              </Button>
            ),
            editable: false,
          },
        ]
      : null;

  // const data = activeTab === 0 ? stocks : activeTab === 1 ? stocks : [];

  const handleActiveTab = (event, value) => {
    setActiveTab(value);
  };

  const handleOpenModel = (type, rowData) => {
    setOpenModal(type || options[activeTab]);
  };

  const handleCloseModel = () => {
    resetData();
  };

  const resetData = () => {
    setModelData({ status: "Disabled" });
    setUploadFile(null);
    setAssignTo(null);
    setAssignToID(null);
    setOpenModal("");
    setShowError(false);
    setDialogOpen(false);
    setAssignStockModal(false);
    setStockToAssign(null);
    setImageURLforFront(null);
  };

  useEffect(() => {
    if (activeTab === 0) {
      getStocks();
      getStockCategory();
    }
  }, [activeTab]);

  const getAStockCategoryList = (rowData) => {
    let storedToken = getWithExpiry("token");

    let reqBody = {
      stockId: rowData?.stockId,
    };
    API.post("/getStockCategory", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, error, data, errorMessage } = res.data;
        console.log(11, res.data);
        if (status) {
          setaStockCategories(data ? data : []);
        } else {
          setaStockCategories([]);
        }
      })
      .catch((err) => {
        console.log("axios error:- ", err);
      });
  };

  // recommendations
  useEffect(() => {
    setShowError(false);
    setValidCheck(false);
    if (assignTo) {
      const timeoutId = setTimeout(() => {
        getRecommends(assignTo);
      }, 1500);
      return () => clearTimeout(timeoutId);
    }
  }, [assignTo]);

  useEffect(() => {
    if (
      assignTo &&
      recommendations?.length == 0
      // ||
      // (recommendations?.[0]?.isMinor &&
      //   // !avoidisMinor &&
      //   !recommendations?.[0]?.kyc &&
      //   !recommendations?.[0]?.parentApprovedKYCStatus) ||
      // recommendations?.[0]?.ProfileID == admin?.user_profile?._id
    ) {
      setShowError(true);
      setValidCheck(false);
    } else {
      if (recommendations?.length) {
        setAssignToID(recommendations?.[0]?.ProfileID);
        setValidCheck(true);
      }
    }
  }, [recommendations]);

  let tempUser = recommendations?.[0];
  // setAssignTo(recommendations?.[0]?.ProfileID);

  let user = null;
  let validUserToPay = null;

  if (tempUser) {
    validUserToPay = tempUser?.ProfileID;
    user = tempUser;
    // if (tempUser?.isMinor && !avoidisMinor) {
    // if (tempUser?.isMinor) {
    //   if (tempUser?.hasOwnProperty("parentApprovedKYCStatus")) {
    //     validUserToPay = tempUser?.ProfileID;
    //     user = tempUser;
    //   } else {
    //     user = tempUser;
    //     validUserToPay = false;
    //   }
    // } else {
    //   validUserToPay = tempUser?.ProfileID;
    //   user = tempUser;
    // }
  } else {
    user = null;
    validUserToPay = null;
  }

  // const isMinorError =
  //   user &&
  //   // !avoidisMinor &&
  //   user?.isMinor &&
  //   !user?.kyc &&
  //   !user?.parentApprovedKYCStatus &&
  //   showError;

  // const isSameUserError =
  //   validUserToPay === admin?.user_profile?._id && showError;

  const noUserError = user == null && validUserToPay == null && showError;

  // const isError = isMinorError || isSameUserError || noUserError;
  const isError = noUserError;
  const errorText = noUserError ? "No such user" : null;
  // isMinorError
  //   ? "Recipients KYC not approved"
  //    : isSameUserError
  //   ? "Cannot send money to yourself"

  const handleMultipleInputChange = (event, value) => {
    // setStockCategories(prevCategories => {
    //     // Check if the category with the same id already exists
    //     if (prevCategories.some(category => category.id === value.id)) {
    //         return prevCategories; // If it exists, return the previous state unchanged
    //     } else {
    //         return [...prevCategories, value]; // Otherwise, add the new value
    //     }
    // });
    setModelData({
      ...modelData,
      ["stock_category"]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let data = modelData;

    console.log({ data });

    if (openModal == "Stocks") {
      console.log("stock opened");
      const req = {
        stockName: data?.stockName,
        stockImage: uploadFile,
        COG100: data?.stockPriceInCOG100,
        CBC100: data?.stockPriceInCBC100,
        CB75CO25: data?.stockPriceInCB75CO25,
        CB50CO50: data?.stockPriceInCB50CO50,
        CB25CO75: data?.stockPriceInCB25CO75,
        stockCategory: data?.stock_category,
        ownerId: assignToID,
        totalQuantity: Number(data?.totalQuantity),
      };
      createStock(req, handleCloseModel);
    } else {
      console.log("update stock opened");
      // update stock

      const firstArrayIds = new Set(aStockCategory.map((item) => item.id));
      const secondArrayIds = new Set(
        data?.stock_category?.map((item) => item.id)
      );

      // Filter to find extra elements in the second array
      const extraInSecondArray = data?.stock_category
        ?.filter((item) => !firstArrayIds.has(item.id))
        .map((item) => item.id);

      // Filter to find missing elements in the second array
      const missingInSecondArray = aStockCategory
        ?.filter((item) => !secondArrayIds.has(item.id))
        .map((item) => item.id);

      console.log(
        "isidetical",
        aStockCategory,
        data?.stock_category,
        JSON.stringify(aStockCategory) === JSON.stringify(data?.stock_category)
      );
      console.log("beforeapi", data);
      const req = {
        stockId: data?.stockId,
        stockName: data?.stockName,
        totalQuantity: Number(data?.totalQuantity),
        addStockCategory:
          data?.stock_category === undefined ? [] : extraInSecondArray,
        removeStockCategory:
          data?.stock_category === undefined ? [] : missingInSecondArray,
      };
      updateStocks(req, handleCloseModel);
      if (uploadFile) {
        console.log(9, modelData);
        updateStockLogo({ stockImage: uploadFile, stockId: data?.stockId });
      }
    }
  };
  const fieldWidth = "100%";

  const onChangeFormText = (e, field, condition, isUploadDoc) => {
    if (condition && !condition(e?.target?.value)) return;
    setModelData((prevData) => {
      if (isUploadDoc) {
        return {
          ...prevData,
          [field]: [
            ...(prevData[field] || []),
            ...(e.target.value || []).map((file) => ({ file: file, name: "" })),
          ],
        };
      } else {
        return {
          ...prevData,
          [field]: e?.target?.value || "",
        };
      }
    });
  };

  const handleToggleStatus = async (rowData) => {
    const newStatus = !rowData?.isActive;
    console.log("Rowdata", rowData);
    const req = {
      stockId: rowData?.stockId,
      isActive: newStatus,
    };
    try {
      await updateStocks(req, handleCloseModel);
      // console.log("Status updated successfully", req);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  const CreateStock = () => {
    return (
      <Box className={classes.modelContainer}>
        <form onSubmit={onSubmit}>
          <Box className={classes.modalform}>
            <>
              <Grid item container spacing={1}>
                <Grid
                  item
                  xs={12}
                  className={classes.formContainer}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <FormControl>
                    <input
                      accept=".jpeg,.jpg,.png"
                      style={{ display: "none", width: "100%" }}
                      id="stockImage"
                      name="stockImage"
                      onChange={(e) => onFileChange(e)}
                      type="file"
                    />
                    <label htmlFor="stockImage">
                      <div className={classes.empty_image}>
                        {imageURLforFront ? (
                          <>
                            <img
                              src={imageURLforFront}
                              className={classes.image_size}
                            />
                          </>
                        ) : (
                          <CloudUploadIcon className={classes.cloudIcon} />
                        )}
                        <Typography className={classes.uploadText}>
                          Stock Image
                        </Typography>
                        <Typography
                          style={{
                            color: "#B2B2B2",
                            fontSize: 12,
                            color: "#359DB6",
                          }}
                        >
                          max 5mb png, jpeg, jpg, pdf
                        </Typography>
                        {/* {Boolean(uploadFileKey) &&
                      uploadFileKey == "profile_pic" ? null : (
                        <img
                          src={plus_icon}
                          alt="add Image"
                          className={classes.plus_icon}
                        />
                      )} */}
                      </div>
                    </label>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formContainer}>
                  <FormControl fullWidth>
                    <TextField
                      label={"Stock Name"}
                      variant="outlined"
                      required
                      placeholder="Enter stock name"
                      type="text"
                      value={modelData.stockName}
                      onChange={(e) => onChangeFormText(e, "stockName")}
                      style={{ width: fieldWidth }}
                      error={modelData?.stockName === ""}
                      helperText={
                        modelData?.stockPrice === ""
                          ? "Please enter stock name"
                          : null
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12} className={classes.formContainer}>
                  <Autocomplete
                    className={classes.cat_list}
                    multiple
                    id="tags-standard"
                    options={stockCategoryList}
                    getOptionLabel={(option) => option.name}
                    value={modelData.stock_category}
                    onChange={handleMultipleInputChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Stock Category..."
                        name="stock_category"
                        position="relative"
                        // error={errorObj.hasOwnProperty("industry_category")}
                        // helperText={errorObj?.industry_category}
                        disabled={
                          false
                          // !componentAccess(
                          //   admin?.level,
                          //   admin?.type,
                          //   "Businesses",
                          //   "updateBusinessDetails"
                          // )
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} className={classes.formContainer}>
                  <FormControl fullWidth>
                    <TextField
                      className={classes.textField}
                      // input={<BootstrapInput />}
                      fullWidth
                      variant="outlined"
                      type="text"
                      id="PayTo"
                      margin="dense"
                      placeholder="Username/Email"
                      value={assignTo}
                      onChange={(e) =>
                        // handleChange("Pay To", e.currentTarget.value)
                        setAssignTo(e.currentTarget.value)
                      }
                      error={isError}
                      helperText={errorText}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!loadings?.getRecommendation &&
                            assignTo &&
                            validCheck ? (
                              <VerifiedUserRounded
                                style={{
                                  color: "#1ba12b",
                                }}
                              />
                            ) : loadings?.getRecommendation ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              <VerifiedUserRounded
                                style={{
                                  color: "#73777B",
                                }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.formContainer}>
                  <FormControl fullWidth>
                    <TextField
                      label={"Total Quantity"}
                      variant="outlined"
                      required
                      placeholder="Enter total quantity"
                      type="number"
                      value={modelData?.totalQuantity}
                      onChange={(e) => onChangeFormText(e, "totalQuantity")}
                      style={{ width: fieldWidth }}
                      error={
                        modelData?.totalQuantity === "" ||
                        modelData?.totalQuantity < 0
                      }
                      helperText={
                        modelData?.totalQuantity === "" ||
                        modelData?.totalQuantity < 0
                          ? "Please enter total quantity greater than 0"
                          : null
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.formContainer}>
                  <FormControl fullWidth>
                    <TextField
                      label={"Stock Price in CBC100"}
                      variant="outlined"
                      required
                      placeholder="Enter price per stock"
                      type="number"
                      value={modelData.stockPriceInCBC100}
                      onChange={(e) =>
                        onChangeFormText(e, "stockPriceInCBC100")
                      }
                      style={{ width: fieldWidth }}
                      error={modelData?.stockPriceInCBC100 < 0}
                      helperText={
                        modelData?.stockPriceInCBC100 < 0
                          ? "  Please enter positive value"
                          : null
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12} className={classes.formContainer}>
                  <FormControl fullWidth>
                    <TextField
                      label={"Stock Price in COG100"}
                      variant="outlined"
                      type="number"
                      required
                      value={modelData.stockPriceInCOG100 || ""}
                      onChange={(e) =>
                        onChangeFormText(e, "stockPriceInCOG100")
                      }
                      style={{ width: fieldWidth }}
                      error={modelData?.stockPriceInCOG100 < 0}
                      helperText={
                        modelData?.stockPriceInCOG100 < 0
                          ? "  Please enter positive value"
                          : null
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12} className={classes.formContainer}>
                  <FormControl fullWidth>
                    <TextField
                      label={"Stock Price in CB75CO25"}
                      variant="outlined"
                      type="number"
                      placeholder="Enter Stock Price in CB75CO25"
                      required
                      value={modelData.stockPriceInCB75CO25 || ""}
                      onChange={(e) =>
                        onChangeFormText(e, "stockPriceInCB75CO25")
                      }
                      style={{ width: fieldWidth }}
                      error={modelData?.stockPriceInCB75CO25 < 0}
                      helperText={
                        modelData?.stockPriceInCB75CO25 < 0
                          ? "  Please enter positive value"
                          : null
                      }
                    />
                  </FormControl>
                  {/* {error !== null ? <Typography>{error}</Typography> : null} */}
                </Grid>

                <Grid item sm={6} xs={12} className={classes.formContainer}>
                  <FormControl fullWidth>
                    <TextField
                      label={"Stock Price in CB50CO50"}
                      variant="outlined"
                      type="number"
                      placeholder="Enter Stock Price in CB50CO50"
                      required
                      value={modelData.stockPriceInCB50CO50 || ""}
                      onChange={(e) =>
                        onChangeFormText(e, "stockPriceInCB50CO50")
                      }
                      style={{ width: fieldWidth }}
                      error={modelData?.stockPriceInCB50CO50 < 0}
                      helperText={
                        modelData?.stockPriceInCB50CO50 < 0
                          ? "  Please enter positive value"
                          : null
                      }
                    />
                  </FormControl>
                  {/* {error !== null ? <Typography>{error}</Typography> : null} */}
                </Grid>

                <Grid item sm={6} xs={12} className={classes.formContainer}>
                  <FormControl fullWidth>
                    <TextField
                      label={"Stock Price in CB25CO75"}
                      variant="outlined"
                      type="number"
                      placeholder="Enter Stock Price in CB25CO75"
                      required
                      value={modelData.stockPriceInCB25CO75 || ""}
                      onChange={(e) =>
                        onChangeFormText(e, "stockPriceInCB25CO75")
                      }
                      style={{ width: fieldWidth }}
                      error={modelData?.stockPriceInCB25CO75 < 0}
                      helperText={
                        modelData?.stockPriceInCB25CO75 < 0
                          ? "  Please enter positive value"
                          : null
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Box className={classes.modelSubmit}>
                <Button
                  className={classes.createStockButton}
                  type={"submit"}
                  disabled={
                    !validCheck ||
                    !modelData?.stockName ||
                    !uploadFile ||
                    !modelData?.stockPriceInCOG100 < 0 ||
                    !modelData?.stockPriceInCBC100 < 0 ||
                    !modelData?.stockPriceInCB25CO75 < 0 ||
                    !modelData?.stockPriceInCB50CO50 < 0 ||
                    !modelData?.stockPriceInCB75CO25 < 0 ||
                    modelData?.totalQuantity < 0 ||
                    modelData?.stock_category?.length === 0 ||
                    modelData?.stock_category?.length === undefined
                  }
                >
                  {loadings.createStock ? <Smallspinner /> : `Create`}
                </Button>
              </Box>
            </>
          </Box>
        </form>
      </Box>
    );
  };

  const UpdateStock = () => {
    return (
      <Box className={classes.modelContainer}>
        <form onSubmit={onSubmit}>
          <Box className={classes.modalform}>
            <>
              <Grid item container spacing={1}>
                <Grid
                  item
                  xs={12}
                  className={classes.formContainer}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <FormControl>
                    <input
                      accept=".jpeg,.jpg,.png"
                      style={{ display: "none", width: "100%" }}
                      id="stockImage"
                      name="stockImage"
                      onChange={(e) => onFileChange(e)}
                      type="file"
                    />
                    <label htmlFor="stockImage">
                      <div className={classes.empty_image}>
                        {imageURLforFront ? (
                          <>
                            <img
                              src={imageURLforFront}
                              className={classes.image_size}
                            />
                          </>
                        ) : (
                          <CloudUploadIcon className={classes.cloudIcon} />
                        )}
                        <Typography className={classes.uploadText}>
                          Stock Image
                        </Typography>
                        <Typography
                          style={{
                            color: "#B2B2B2",
                            fontSize: 12,
                            color: "#359DB6",
                          }}
                        >
                          max 5mb png, jpeg, jpg, pdf
                        </Typography>
                        {/* {Boolean(uploadFileKey) &&
                      uploadFileKey == "profile_pic" ? null : (
                        <img
                          src={plus_icon}
                          alt="add Image"
                          className={classes.plus_icon}
                        />
                      )} */}
                      </div>
                    </label>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formContainer}>
                  <FormControl fullWidth>
                    <TextField
                      label={"Stock Name"}
                      variant="outlined"
                      required
                      placeholder="Enter stock name"
                      type="text"
                      value={modelData.stockName}
                      onChange={(e) => onChangeFormText(e, "stockName")}
                      style={{ width: fieldWidth }}
                      error={modelData?.stockName === ""}
                      helperText={
                        modelData?.stockPrice === ""
                          ? "Please enter stock name"
                          : null
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.formContainer}>
                  <FormControl fullWidth>
                    <TextField
                      label={"Total Quantity"}
                      variant="outlined"
                      required
                      placeholder="Enter total quantity"
                      type="number"
                      value={modelData?.totalQuantity}
                      onChange={(e) => onChangeFormText(e, "totalQuantity")}
                      style={{ width: fieldWidth }}
                      error={
                        modelData?.totalQuantity === "" ||
                        modelData?.totalQuantity < 0
                      }
                      helperText={
                        modelData?.totalQuantity === "" ||
                        modelData?.totalQuantity < 0
                          ? "Please enter total quantity greater than 0"
                          : null
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} className={classes.formContainer}>
                  <Autocomplete
                    className={classes.cat_list}
                    multiple
                    id="tags-standard"
                    options={stockCategoryList}
                    getOptionLabel={(option) => option.name}
                    // noOptionsText={"No country"}
                    value={
                      modelData?.stock_category
                        ? modelData.stock_category
                        : aStockCategory
                    }
                    onChange={handleMultipleInputChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Stock Category..."
                        name="stock_category"
                        position="relative"
                        // error={errorObj.hasOwnProperty("industry_category")}
                        // helperText={errorObj?.industry_category}
                        disabled={
                          false
                          // !componentAccess(
                          //   admin?.level,
                          //   admin?.type,
                          //   "Businesses",
                          //   "updateBusinessDetails"
                          // )
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} className={classes.formContainer}>
                  <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>
                    Library
                  </Typography>

                  {stockDocs?.length ? (
                    <>
                      <ol className={classes.documentList}>
                        {stockDocs?.map((doc, index) => (
                          <li
                            key={doc._id}
                            className={classes.documentItem}
                            style={{
                              background:
                                index % 2 === 0 ? "#1a1a1a" : "#2b2b2b",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div className={classes.documentIcon}>
                                <InsertDriveFileIcon
                                  style={{ color: "white" }}
                                />
                              </div>
                              <div className={classes.documentDetails}>
                                <span className={classes.documentName}>
                                  {doc.name}
                                </span>
                              </div>
                              <IconButton
                                onClick={() =>
                                  deleteStockDoc({
                                    _id: doc?._id,
                                    stock_id: doc?.stock_id,
                                  })
                                }
                                aria-label={`delete ${doc?.name}`}
                                className={classes.deleteBtn}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </li>
                        ))}
                      </ol>
                      {loadings?.uploadStockDoc ? (
                        <div className={classes.loader}>
                          <CircularProgress />
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <Typography>No Docs Found</Typography>
                  )}
                  <div
                    className={classes.addButton}
                    onClick={() => fileInputRef.current.click()}
                  >
                    <Tooltip title="Add Docs">
                      <AddIcon />
                    </Tooltip>
                  </div>

                  {/* Hidden file input */}
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </Grid>
              </Grid>

              <Box className={classes.modelSubmit}>
                <Button
                  className={classes.createStockButton}
                  type={"submit"}
                  disabled={
                    // !validUser ||
                    // !recommendations[0]?.StockAccount ||
                    // !modelData?.stockName || !uploadFile
                    false
                  }
                >
                  {loadings.createStock ? <Smallspinner /> : "Update"}
                </Button>
              </Box>
            </>
          </Box>
        </form>
      </Box>
    );
  };

  const AssignStock = () => {
    return (
      <Box className={classes.modelContainer}>
        {console.log("assignstockmodeldata", modelData)}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            console.log({ stockToAssign });
            assignStock(
              {
                profile_id: recommendations?.[0]?.ProfileID,
                quantity: Number(modelData?.quantity),
                stockId: stockToAssign.stockId,
              },
              handleCloseModel
            );
            setAssignStockModal(false);
          }}
        >
          <Box className={classes.modalform}>
            <>
              <Grid item container spacing={1}>
                <Grid item xs={12} className={classes.formContainer}>
                  <Autocomplete
                    disablePortal
                    openOnFocus={true}
                    id="stock"
                    noOptionsText={"No Stock"}
                    options={stocks}
                    getOptionLabel={(option) => option.stockName}
                    defaultValue={null}
                    onChange={(e, value) => {
                      console.log(e.target, value);
                      setStockToAssign(value);
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Stock Name"
                        // error={errorMessages?.countryError?.length > 0}
                        // helperText={errorMessages?.countryError}
                        // disabled={show1}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} className={classes.formContainer}>
                  <FormControl fullWidth>
                    <TextField
                      className={classes.textField}
                      // input={<BootstrapInput />}
                      fullWidth
                      variant="outlined"
                      type="text"
                      id="PayTo"
                      margin="dense"
                      placeholder="Username/Email"
                      value={assignTo}
                      onChange={(e) =>
                        // handleChange("Pay To", e.currentTarget.value)
                        setAssignTo(e.currentTarget.value)
                      }
                      error={isError}
                      helperText={errorText}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!loadings?.getRecommendation &&
                            assignTo &&
                            validCheck ? (
                              <VerifiedUserRounded
                                style={{
                                  color: "#1ba12b",
                                }}
                              />
                            ) : loadings?.getRecommendation ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              <VerifiedUserRounded
                                style={{
                                  color: "#73777B",
                                }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={classes.formContainer}>
                  <FormControl fullWidth>
                    <TextField
                      label={"Quantity"}
                      variant="outlined"
                      required
                      placeholder="Enter quantity"
                      type="number"
                      value={modelData.quantity}
                      onChange={(e) => onChangeFormText(e, "quantity")}
                      style={{ width: fieldWidth }}
                      error={
                        modelData?.quantity === "" ||
                        modelData?.quantity <= 0 ||
                        Number(stockToAssign?.totalQuantity) -
                          Number(stockToAssign?.assignedQuantity) <
                          Number(modelData?.quantity)
                      }
                      helperText={
                        modelData?.quantity === "" || modelData?.quantity <= 0
                          ? "Please enter quantity greater than 0"
                          : Number(stockToAssign?.totalQuantity) -
                              Number(stockToAssign?.assignedQuantity) <
                            Number(modelData?.quantity)
                          ? "Not Enough Stocks"
                          : null
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <InputAdornment position="end">
                              {/* <AccountBalanceWallet />€{" "}*/}
                              {stockToAssign
                                ? `Available ${
                                    Number(stockToAssign?.totalQuantity) -
                                    Number(stockToAssign?.assignedQuantity)
                                  }`
                                : "-"}
                            </InputAdornment>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Box className={classes.modelSubmit}>
                <Button
                  className={classes.createStockButton}
                  type={"submit"}
                  disabled={
                    !(
                      validCheck &&
                      modelData?.quantity &&
                      stockToAssign &&
                      Number(stockToAssign?.totalQuantity) -
                        Number(stockToAssign?.assignedQuantity) >=
                        Number(modelData?.quantity)
                    )
                    // !recommendations[0]?.StockAccount ||
                    // !modelData?.stockName || !uploadFile
                  }
                >
                  {loadings.createStock ? <Smallspinner /> : "Assign"}
                  {/* {console.log(validCheck, modelData?.quantity, stockToAssign)} */}
                </Button>
              </Box>
            </>
          </Box>
        </form>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            style={{
              color: "#359DB6",
              // textDecoration: "underline",
              fontWeight: "bold",
            }}
            className={"system-nav"}
          >
            <Tabs value={activeTab} onChange={handleActiveTab}>
              {options.map((data, index) => (
                <Tab label={data} {...a11yProps(index)} />
              ))}
            </Tabs>
          </Typography>
          {activeTab === 0 && (
            <Box>
              <Button
                id={options[activeTab]}
                style={{
                  backgroundColor: "rgb(53, 157, 182)",
                  color: "white",
                  marginRight: 10,
                }}
                onClick={() => setAssignStockModal(true)}
              >
                Assign Stock
              </Button>
              <Button
                id={options[activeTab]}
                style={{
                  backgroundColor: "rgb(53, 157, 182)",
                  color: "white",
                  marginRight: 10,
                }}
                onClick={() => handleOpenModel(options[activeTab])}
              >
                Create Stock
              </Button>
              <Button
                id={`prospectus_tool`}
                style={{ backgroundColor: "rgb(53, 157, 182)", color: "white" }}
                onClick={() => (window.location = "/tool")}
              >
                Create Prospectus
              </Button>
            </Box>
          )}
        </Box>
        <Box>
          {activeTab === 1 ? (
            <StockListingTemplatePage rowData={selectedRowData} />
          ) : activeTab === 2 ? (
            <StockCategory />
          ) : activeTab === 3 ? (
            <UserStockTxns
              orderStatus={orderStatus}
              setOrderStatus={setOrderStatus}
              userStockTxns={allStockTxns}
              title={`All Stock Transactions`}
            />
          ) : (
            <MaterialTable
              icons={tableIcons}
              title={options[activeTab]}
              options={{
                filtering: false,
                toolbar: true,
                sorting: false,
                pageSize: 5,
                pageSizeOptions: [5, 10, 15],
                emptyRowsWhenPaging: false,
                maxBodyHeight: 1450,
                padding: "default",
                search: false,
              }}
              actions={[
                (rowData) => ({
                  icon: () => <Edit />,
                  tooltip: "Edit Stock",
                  onClick: (event, rowData) => {
                    getAStockCategoryList(rowData);
                    getStockDoc({ stockId: rowData?.stockId });
                    setImageURLforFront(
                      `${process.env.REACT_APP_SPACE_LINK}/${rowData.stockLogo}`
                    );
                    console.log(rowData);
                    setModelData(rowData);
                    handleMainDialogOpen(rowData);
                  },
                }),
                (rowData) => ({
                  icon: () => (
                    <Switch
                      checked={rowData?.isActive}
                      onChange={() => handleToggleStatus(rowData)}
                      color="primary"
                    />
                  ),
                  tooltip: " Status (Active/Inactive)",
                }),
              ]}
              columns={columns}
              data={activeTab === 0 ? stocks : null}
            />
          )}
        </Box>
      </Box>
      <TransitionsModal
        open={openModal}
        title={openModal}
        handleClose={handleCloseModel}
        disableHeight={true}
        disableMargin={true}
        disableCenterBody={true}
        element={CreateStock()}
      />
      <TransitionsModal
        open={openDialog}
        title={"Update Stock"}
        handleClose={handleCloseModel}
        disableHeight={true}
        disableMargin={true}
        disableCenterBody={true}
        element={UpdateStock()}
      />
      <TransitionsModal
        open={assignStockModal}
        title={"Assign Stock"}
        handleClose={handleCloseModel}
        disableHeight={true}
        disableMargin={true}
        disableCenterBody={true}
        element={AssignStock()}
      />
    </React.Fragment>
  );
}

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    recommendations: adminReducer.recommendations,
    admin: adminReducer.admin,
    stocks: adminReducer.stocks,
    stockCategoryList: adminReducer.stockCategories,
    userStocks: adminReducer.userStocks,
    allStockTxns: adminReducer.userStockTxns,
    stockDocs: adminReducer.stockDocs,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
    uploadDocs: (data, path) => dispatch(actionCreator.uploadDocs(data, path)),
    getRecommends: (searchUser) =>
      dispatch(actionCreator.getRecommends(searchUser)),
    getStocks: () => dispatch(actionCreator.getStocks()),
    createStock: (data, onSuccess) => {
      dispatch(actionCreator.createStock(data, onSuccess));
    },
    updateStocks: (data, onSuccess) => {
      dispatch(actionCreator.updateStocks(data, onSuccess));
    },
    updateStockLogo: (data) => {
      dispatch(actionCreator.updateStockLogo(data));
    },
    assignStock: (data, onSuccess) => {
      dispatch(actionCreator.assignStock(data, onSuccess));
    },
    getStockCategory: (data) => {
      dispatch(actionCreator.getStockCategories(data));
    },
    getPortfolio: (data) => {
      dispatch(actionCreator.getPortfolio(data));
    },
    getAllStocksTransaction: (data) =>
      dispatch(actionCreator.getAllStocksTransaction(data)),
    getStockDoc: (data) => dispatch(actionCreator.getStockDoc(data)),
    uploadStockDoc: (file, stockId) =>
      dispatch(actionCreator.uploadStockDoc(file, stockId)),
    deleteStockDoc: (data) => dispatch(actionCreator.deleteStockDoc(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stocks);
