import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Grid } from "@material-ui/core";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Grid item container xs={12}>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.default,
    },
    tabText: {
        fontSize: "1.2rem",
        textTransform: "none",
    },
    customTabRoot: {
        fontSize: "5rem",
        textTransform: "none",
    },
    customTabIndicator: {
        backgroundColor: "#359DB6",
    },
}));

export default function FullWidthTabsButtonForce(props) {
    const { headings, contents } = props;
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    TabIndicatorProps={{
                        style: {
                            background: "#359DB6",
                            height: "7px",
                            borderRadius: "2px",
                        },
                    }}
                    classes={{
                        root: classes.customTabRoot,
                        indicator: classes.customTabIndicator,
                    }}
                    aria-label="full width tabs example"
                >
                    {headings.map((heading, index) => {
                        return (
                            <Tab
                                key={index}
                                label={<span className={classes.tabText}>{heading}</span>}
                                {...a11yProps(index)}
                            />
                        );
                    })}
                </Tabs>
            </Grid>
            <Grid item xs={12}>
                {contents.map((content, index) => {
                    return (
                        <TabPanel key={index} value={value} index={index}>
                            {content}
                        </TabPanel>
                    );
                })}
            </Grid>
        </Grid>
    );
}
