import React from "react";
import { StaticGoogleMap, Marker } from "react-static-google-map";

const key = process.env.REACT_APP_GOOGLE_API_KEY;

function StaticMap({ size, coordinates }) {
 return (
  <StaticGoogleMap
   size={size}
   zoom={14}
   apiKey={key}
   center={coordinates.center}
  >
   {coordinates?.businesses?.map((data, index) => (
    <Marker
     location={data.coordinates}
     iconURL={data.icon || null}
     // scale={coordinates.center == data.coordinates ? 2 :4}
     key={index}
    />
   ))}
  </StaticGoogleMap>
 );
}

export default StaticMap;
