import {
 Box,
 Typography,
 Button,
 Dialog,
 DialogActions,
 DialogContent,
 DialogTitle,
 Grid,
 withStyles,
 Divider,
 TextField,
 FormControlLabel,
 FormControl,
 Radio,
 RadioGroup,
 Paper,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import { CloseOutlined } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

import React, { useEffect, useState } from "react";
import { emailValidator } from "../../../utils/validator/emailValidator";
import AddresInput from "../../../components/layout/addressInput";
import GoogleMaps from "../../../components/auth/addressInput";
import { notify } from "../../../store/action";
import { updateObjectProperty } from "../../../asset/utility";
import { validateField } from "../../../asset/businessUtility";
import moment from "moment";

const CustomButton = withStyles({
 root: {
  borderRadius: 8,
  justifySelf: "right",
  border: 0,
  color: "white",
  fontStyle: "normal",
  background: "#359DB6",
  "&:hover": {
   background: "#62c3db",
  },
 },
 label: {
  textTransform: "capitalize",
 },
 disabled: {
  background: "#8ab0b9",
 },
})((props) => <Button {...props} />);

const useStyles = makeStyles((theme) => ({
 formControl4: { width: "95%" },
 dialogBox: {
  "& .MuiDialog-paperWidthSm": {
   maxWidth: "755px",
  },
 },
 InfoContainer: {
  padding: "5px 5px 1px 5px",
 },
 rootSwitch: {
  width: 100,
  height: 48,
  padding: 8,
 },
 switchBase: {
  padding: 11,
  color: "#ff6a00",
 },
 thumb: {
  width: 26,
  height: 26,
  backgroundColor: "#fff",
 },
 track: {
  background: "#C49C39",
  opacity: "1 !important",
  borderRadius: 20,
  position: "relative",
  "&:before, &:after": {
   display: "inline-block",
   position: "absolute",
   top: "50%",
   width: "50%",
   transform: "translateY(-50%)",
   color: "#fff",
   textAlign: "center",
  },
  "&:before": {
   content: '"Online"',
   left: 4,
   opacity: 0,
  },
  "&:after": {
   content: '"Offline"',
   right: 4,
  },
 },
 checked: {
  "&$switchBase": {
   color: "#185a9d",
   transform: "translateX(107.5%)",
   "&:hover": {
    backgroundColor: "rgba(24,90,257,0.08)",
   },
  },
  "& $thumb": {
   backgroundColor: "#fff",
  },
  "& + $track": {
   background: "linear-gradient(to right, #5FB794, #185a9d)",
   "&:before": {
    opacity: 1,
   },
   "&:after": {
    opacity: 0,
   },
  },
 },
}));

const CreateActivityModal = (props) => {
 const classes = useStyles();
 const {
  createBusinessActivity,
  business_id,
  addressDetails,
  profileId,
  activityData,
  updatePMActivity,
 } = props;
 const [selectedSkills, setSelectedSkills] = useState([]);
 const [onlineTask, setOnlineTask] = useState(false);
 const [countries, setCountries] = useState("");
 const [error, setError] = useState(false);

 const skills = ["React", "Golang", "Python", "Javascript"];
 const initialData = {
  profileId: profileId,
  businessId: business_id,
  title: "",
  onlineActivityLink: "",
  email: "",
  phoneNumber: "",
  duration: "",
  threshold: "",
  startDate: "",
  endDate: "",
  startTime: "",
  rewards: "",
  educationPoints: "",
  description: "",
  endTime: "",
  ageGroup: [],
  skills: [],
  categories: [],
  isMultiple: false,
  location: {
   country: addressDetails?.country,
   state: addressDetails?.state,
   city: addressDetails?.city,
   pincode: addressDetails?.pincode,
   address: addressDetails?.address,
   coordinates: addressDetails?.coordinates || [],
  },
 };
 const [modalData, setModalData] = useState(initialData);
 const [selectedDate, setSelectedDate] = useState("");
 const [selectedTime, setSelectedTime] = useState("00:00");
 const [countriesIso2, setCountriesIso2] = useState("");
 const [selectedCountry, setSelectedCountry] = useState();
 const [editData, setEditData] = useState();
 const [statesIso2, setStatesIso2] = useState();
 const [states, setStates] = useState([]);
 const [cities, setCities] = useState([]);
 const [AddressField, setAddressField] = useState("");
 const [selectedState, setSelectedState] = useState();
 const [selectedCity, setSelectedCity] = useState();
 const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });

 const categories = ["Category1", "Category2", "Category3"];
 const age = ["Age 0-6", "Age 7-11", "Age 12-16"];
 const ageGrpForEdit = editData?.ageGroup?.map((ageGroup, key) => {
  if (ageGroup === 1) {
   return "Age 0-6";
  } else if (ageGroup === 2) {
   return "Age 6-11";
  } else return "Age 11-16";
 });

 //  create activity on submit form
 const onSave = (e) => {
  e.preventDefault();
  const ageGrpArray = modalData?.ageGroup?.map((ageGroup, key) => {
   if (ageGroup === "Age 0-6") {
    return 1;
   } else if (ageGroup === "Age 7-11") {
    return 2;
   } else return 3;
  });
  if (editActivity) {
   const updatedData = {
    ...editData,
   };

   updatePMActivity(updatedData);
  } else {
   const updatedData = {
    ...modalData,
    businessId: business_id,
    isOnline: onlineTask,
    url: modalData?.onlineActivityLink ? modalData?.onlineActivityLink : "",
    title: modalData?.title,
    scheduledAt: selectedDate + "T" + selectedTime + ":00.000Z",
    skills: modalData?.skills,
    description: modalData?.description,
    rewards: modalData?.rewards,
    educationPoints: modalData?.educationPoints,
    ageGroup: ageGrpArray,
    categories: modalData?.categories,
    openingDate: modalData?.startDate + "T" + modalData?.startTime + ":00.000Z",
    closingDate: modalData?.endDate + "T" + modalData?.endTime + ":00.000Z",
    email: modalData?.email,
    phone: modalData?.phoneNumber,
    duration: modalData?.duration,
    isMultiple: modalData?.isMultiple,
    threshold: parseInt(modalData?.threshold),
    duration: modalData?.duration,
    location: onlineTask
     ? null
     : {
        ...modalData?.location,
        address: AddressField,
        coordinates: [coordinates?.lat, coordinates?.lng],
        pincode: parseInt(modalData?.location?.pincode),
       },
   };
   createBusinessActivity(updatedData);
  }

  props.handleClose();
 };
 //  switch function for online offline task
 const handleSwitchChange = () => {
  setOnlineTask(!onlineTask);
 };
 // Check if edit activity
 const editActivity = props?.title == "Edit Activity";

 //  input field change function
 const handleInputFieldChange = (e, field, value) => {
  let updatedData = {};
  if (editActivity) {
   setEditData((prevData) => {
    if (field == "isMultiple") {
     updatedData = {
      ...prevData,
      [field]: e.target.value == "true",
     };
    } else if (
     field == "skills" ||
     field == "ageGroup" ||
     field == "categories"
    ) {
     updatedData = {
      ...prevData,
      [field]: value,
     };
    } else {
     updatedData = {
      ...prevData,
      [field]: e?.target?.value,
     };
    }

    //  check to see if the value has actually changed
    if (prevData[field] === e?.target?.value) {
     return prevData; // If unchanged, return the previous state
    } else {
     return updatedData;
    }
   });
  } else {
   setModalData((prevData) => {
    if (field == "isMultiple") {
     return {
      ...prevData,
      [field]: e.target.value == "true",
     };
    } else if (
     field == "skills" ||
     field == "ageGroup" ||
     field == "categories"
    ) {
     return {
      ...prevData,
      [field]: value,
     };
    }

    return { ...prevData, [field]: e?.target?.value };
   });
  }
 };
 //  address field value
 const onUserAddressValueChanged = (name, value) => {
  if (name === "address_details.country") {
   setSelectedCountry(value);
  } else if (name === "address_details.state") {
   setSelectedState(value);
  } else if ("address_details.city") {
   setSelectedCity(value);
  }
  if (value) {
   setModalData(updateObjectProperty(modalData?.location, name, value));
  } else {
   setModalData(updateObjectProperty(modalData?.location, name, ""));
  }

  validateField(name, value);
 };

 //  email validation check
 useEffect(() => {
  if (!emailValidator(modalData?.email) && modalData?.email?.length > 4) {
   setError(true);
  } else {
   setError(false);
  }
 }, [modalData?.email]);

 const today = new Date().toISOString().split("T")[0];

 const closeModalHandler = () => {
  props.handleClose();
  setModalData("");
 };
 // api for country
 useEffect(() => {
  var headers = new Headers();
  headers.append("X-CSCAPI-KEY", process.env.REACT_APP_ADDRESS_API_KEY);
  var requestOptions = {
   method: "GET",
   headers: headers,
   redirect: "follow",
  };
  fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
   .then((response) => response.json())
   .then((response) => {
    if (response) {
     let countries = response;
     countries.push({ name: "Cogito", iso2: "COG" });
     setCountries(countries);
     let countriesIso = {};
     response.map((country) => {
      countriesIso[country.name] = country.iso2;
     });
     setCountriesIso2(countriesIso);
    }
   })
   .catch((error) =>
    notify("Coudn't fetch countries please try again", "error")
   );
 }, []);
 // api fot state
 useEffect(() => {
  var selectedCountryCode = countriesIso2[selectedCountry];
  var headers = new Headers();
  headers.append("X-CSCAPI-KEY", process.env.REACT_APP_ADDRESS_API_KEY);
  var requestOptions = {
   method: "GET",
   headers: headers,
   redirect: "follow",
  };
  fetch(
   `https://api.countrystatecity.in/v1/countries/${selectedCountryCode}/states`,
   requestOptions
  )
   .then((response) => response.json())
   .then((response) => {
    let states = [];
    let statesIso = {};
    if (response) {
     response.map((state) => {
      states.push(state.name);
      statesIso[state.name] = state.iso2;
     });
     setStates(states);
     setStatesIso2(statesIso);
    }
   })
   .catch((error) => console.log("error", error));
 }, [selectedCountry]);
 // api for city
 useEffect(() => {
  var selectedCountryCode = countriesIso2[selectedCountry];
  if (selectedState) {
   var selectedStateCode = statesIso2[selectedState];
  }
  var headers = new Headers();
  headers.append("X-CSCAPI-KEY", process.env.REACT_APP_ADDRESS_API_KEY);
  var requestOptions = {
   method: "GET",
   headers: headers,
   redirect: "follow",
  };
  fetch(
   `https://api.countrystatecity.in/v1/countries/${selectedCountryCode}/states/${selectedStateCode}/cities`,
   requestOptions
  )
   .then((response) => response.json())
   .then((response) => {
    let cities = [];
    if (response) {
     response.map((city) => {
      cities.push(city.name);
     });
     setCities(cities);
    }
   })
   .catch((error) => console.log("error", error));
 }, [selectedState]);

 useEffect(() => {
  setEditData(activityData);
 }, [activityData]);
 return (
  <div className={classes.root}>
   <Dialog
    // aria-labelledby="customized-dialog-title"
    open={props?.open}
    onClose={closeModalHandler}
    fullWidth
    PaperProps
    className={classes.dialogBox}
   >
    {/* {props.open == true ? ( */}
    <Grid
     container
     //  xs={12}
     spacing={2}
     style={{
      display: "flex",
      justifyContent: "space-between",
      padding: "2% 5%",
     }}
    >
     <DialogTitle>{props.title}</DialogTitle>
     <CloseOutlined onClick={closeModalHandler} style={{ textAlign: "end" }} />
    </Grid>
    <DialogContent>
     <>
      <form onSubmit={onSave}>
       <Box className={classes.InfoContainer}>
        <Divider style={{ marginBottom: "1em" }} />
        <Grid container spacing={2}>
         <Grid item xs={12}>
          <Switch
           classes={{
            root: classes.rootSwitch,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
           }}
           onChange={handleSwitchChange}
           checked={onlineTask}
           //  disabled={props?.title == "Edit Activity" && !editData?.isOnline}
          />
          {/* website link for online task */}
          {onlineTask ? (
           props.title == "Edit Activity" ? (
            <TextField
             required
             fullWidth
             label={
              props?.title == "Edit Activity" && editData?.url
               ? "Website Link"
               : "Mention the website link here"
             }
             value={
              props?.title == "Edit Activity"
               ? editData?.url
               : modalData?.onlineActivityLink
             }
             onChange={(e) => {
              handleInputFieldChange(e, "onlineActivityLink");
             }}
             disabled={editData}
            />
           ) : (
            <TextField
             required
             fullWidth
             label={"Mention the website link here"}
             value={
              props?.title == "Edit Activity"
               ? editData?.url
               : modalData?.onlineActivityLink
             }
             onChange={(e) => {
              handleInputFieldChange(e, "onlineActivityLink");
             }}
             disabled={editData}
            />
           )
          ) : null}
         </Grid>
         <Grid item xs={12}>
          <TextField
           required
           fullWidth
           value={
            props?.title == "Edit Activity" ? editData?.title : modalData?.title
           }
           label="Activity Title"
           variant="outlined"
           onChange={(e) => handleInputFieldChange(e, "title")}
          />
         </Grid>
         <Grid item xs={12} sm={6}>
          <Autocomplete
           className={classes.cat_list}
           multiple
           id="tags-standard"
           options={skills}
           value={
            props?.title == "Edit Activity"
             ? editData?.skills
             : modalData?.skills || []
           }
           onChange={(e, value) => handleInputFieldChange(e, "skills", value)}
           PaperComponent={({ children }) => (
            <Paper
             style={{
              backgroundColor: localStorage.getItem("dark")
               ? "#fff"
               : "#212121",
             }}
            >
             {children}
            </Paper>
           )}
           renderInput={(params) => (
            <TextField
             className={classes.menu}
             {...params}
             variant="outlined"
             label="Skills To Learn"
             name="skills_to_learn"
             position="relative"
            />
           )}
          />
         </Grid>
         <Grid item xs={12} md={6}>
          <TextField
           required
           label="Duration in hrs"
           variant="outlined"
           fullWidth
           type="number"
           step="0.01"
           value={
            props?.title == "Edit Activity"
             ? editData?.duration
             : modalData?.duration
           }
           onChange={(e) => handleInputFieldChange(e, "duration")}
          />
         </Grid>
         <Grid item xs={12} md={6}>
          <TextField
           required={modalData?.phoneNumber ? false : true}
           label="Email"
           variant="outlined"
           fullWidth
           type="email"
           value={
            props?.title == "Edit Activity" ? editData?.email : modalData?.email
           }
           //  onChange={handleEmail}
           helperText={error ? "Please enter valid email" : null}
           error={error}
           onChange={(e) => handleInputFieldChange(e, "email")}
          />
         </Grid>

         {/* Phone Number */}
         <Grid item xs={12} md={6}>
          <TextField
           required={editData?.email || modalData?.email ? false : true}
           label="Phone Number"
           variant="outlined"
           fullWidth
           type="number"
           value={
            props?.title == "Edit Activity"
             ? editData?.phone
             : modalData?.phoneNumber
           }
           error={error}
           helperText={error ? "Please enter correct phone Number" : null}
           onChange={(e) => handleInputFieldChange(e, "phoneNumber")}
          />
         </Grid>
        </Grid>

        {/* address */}
        {onlineTask ? null : (
         <Grid container spacing={2} style={{ paddingTop: "4%" }}>
          <Grid item xs={12}>
           <GoogleMaps
            addressForCoordinates={AddressField}
            onValueChange={(value) => setAddressField(value)}
            defaultAddress={
             props?.title == "Edit Activity" && !editData?.isOnline
              ? editData?.location?.address
              : modalData?.location?.address
            }
            error={error?.length > 0}
            helperText={error?.addressError}
            setCoordinates={setCoordinates}
            // show1={show1}
           />
          </Grid>
          <Grid item xs={12} sm={6}>
           <Autocomplete
            disablePortal
            openOnFocus={true}
            id="country"
            noOptionsText={"No country"}
            options={countries && countries?.map((country) => country.name)}
            defaultValue={
             props?.title == "Edit Activity"
              ? editData?.location?.country
              : modalData?.location?.country
            }
            onChange={(e, value) =>
             onUserAddressValueChanged("address_details.country", value)
            }
            sx={{ width: 300 }}
            renderInput={(params) => (
             <TextField
              {...params}
              variant="outlined"
              label="country"
              // error={errorMessages?.countryError?.length > 0}
              // helperText={errorMessages?.countryError}
              // disabled={show1}
             />
            )}
           />
          </Grid>
          <Grid item xs={12} sm={6}>
           <Autocomplete
            disablePortal
            openOnFocus={true}
            id="state"
            options={states}
            defaultValue={
             props?.title == "Edit Activity"
              ? editData?.location?.state
              : modalData?.location?.state
            }
            noOptionsText={"Select country"}
            onChange={(e, value) =>
             onUserAddressValueChanged("address_details.state", value)
            }
            sx={{ width: 300 }}
            renderInput={(params) => (
             <TextField
              {...params}
              variant="outlined"
              label="State"
              // error={errorMessages?.stateError?.length > 0}
              // helperText={errorMessages?.stateError}
              // disabled={show1}
             />
            )}
           />
          </Grid>
          <Grid item xs={12} sm={6}>
           <Autocomplete
            disablePortal
            openOnFocus={true}
            id="city"
            options={cities}
            defaultValue={
             props?.title == "Edit Activity"
              ? editData?.location?.city
              : modalData?.location?.city
            }
            noOptionsText={"Select state"}
            // onChange={(e, value) =>
            //  onUserAddressValueChanged("address_details.city", value)
            // }
            sx={{ width: 300 }}
            renderInput={(params) => (
             <TextField {...params} variant="outlined" label="city" />
            )}
           />
          </Grid>
          <Grid item xs={12} sm={6}>
           <TextField
            variant="outlined"
            label="pincode"
            defaultValue={
             props?.title == "Edit Activity"
              ? editData?.location?.pincode
              : modalData?.location?.pincode
            }
            onChange={(event) =>
             onUserAddressValueChanged(
              modalData?.location?.pincode,
              event.target.value
             )
            }
            inputProps={{
             inputMode: "numeric",
             pattern: "[0-9]*",
            }}
           />
          </Grid>
         </Grid>
        )}

        {/* Activity Repeat Question */}

        <Grid container spacing={2}>
         <Grid
          item
          xs={12}
          md={4}
          style={{
           display: "flex",
           alignItems: "center",
          }}
         >
          <Typography>Do you want this activity to repeat?</Typography>
         </Grid>
         {/* Radio Buttons*/}
         <Grid item xs={12} md={4}>
          <FormControl>
           <RadioGroup
            row
            aria-label="position"
            name="position"
            value={modalData?.isMultiple ? "true" : "false"}
            onChange={(e) => handleInputFieldChange(e, "isMultiple")}
           >
            <FormControlLabel
             value="true"
             control={<Radio color="primary" />}
             label="Yes"
            />
            <FormControlLabel
             value="false"
             control={<Radio color="primary" />}
             label="No"
            />
           </RadioGroup>
          </FormControl>
         </Grid>

         {/* Threshold */}

         {modalData?.isMultiple ? (
          <Grid item xs={12} md={12} style={{ marginBottom: "1em" }}>
           <TextField
            required
            type="number"
            fullWidth
            variant="outlined"
            id="threshold"
            label="After how many days after they can repeat..."
            value={modalData?.threshold}
            onChange={(e) => handleInputFieldChange(e, "threshold")}
           />
          </Grid>
         ) : null}

         {/* Start Date */}
         <Grid item xs={12} md={6}>
          <TextField
           required
           onChange={(e) => handleInputFieldChange(e, "startDate")}
           placeholder="Start Date"
           fullWidth
           value={
            props?.title == "Edit Activity"
             ? editData?.openingDate
               ? moment(editData?.openingDate).format("YYYY-MM-DD")
               : ""
             : modalData?.startDate
           }
           id="startDate"
           variant="outlined"
           label="Start Date"
           type="date"
           className={classes.textField}
           InputLabelProps={{
            shrink: true,
            placeholder: "Select a date",
           }}
           inputProps={{ min: today }}
          />
         </Grid>

         {/* Start Time */}
         <Grid item xs={12} md={6}>
          <TextField
           required
           value={
            props?.title == "Edit Activity"
             ? editData?.openingDate
               ? moment(editData?.openingDate).format("HH:mm")
               : "00:00"
             : modalData?.startTime
           }
           onChange={(e) => handleInputFieldChange(e, "startTime")}
           fullWidth
           variant="outlined"
           id="startTime"
           label="Start Time"
           type="time"
           className={classes.textField}
           InputLabelProps={{
            shrink: true,
           }}
           inputProps={{
            step: 300, // 5 min
           }}
          />
         </Grid>

         {/* End Date */}
         <Grid item xs={12} md={6}>
          <TextField
           required
           onChange={(e) => handleInputFieldChange(e, "endDate")}
           value={
            props?.title == "Edit Activity"
             ? editData?.closingDate
               ? moment(editData?.closingDate).format("YYYY-MM-DD")
               : ""
             : modalData?.endDate
           }
           placeholder="End Date"
           fullWidth
           id="endDate"
           variant="outlined"
           label="End Date"
           type="date"
           className={classes.textField}
           InputLabelProps={{
            shrink: true,
            placeholder: "Select a date",
           }}
           inputProps={{ min: today }}
          />
         </Grid>

         {/* End Time */}
         <Grid item xs={12} md={6}>
          <TextField
           required
           value={
            props?.title == "Edit Activity"
             ? editData?.closingDate
               ? moment(editData?.closingDate).format("HH:mm")
               : "00:00"
             : modalData?.endTime
           }
           onChange={(e) => handleInputFieldChange(e, "endTime")}
           fullWidth
           variant="outlined"
           id="endTime"
           label="End Time"
           type="time"
           className={classes.textField}
           InputLabelProps={{
            shrink: true,
           }}
           inputProps={{
            step: 300, // 5 min
           }}
          />
         </Grid>

         {/* Category of Activity*/}
         <Grid item xs={12} md={6}>
          <Autocomplete
           className={classes.cat_list}
           multiple
           id="tags-standard"
           options={categories}
           value={
            props?.title == "Edit Activity"
             ? editData?.categories
               ? editData?.categories
               : ""
             : modalData?.categories || []
           } // You can set the value to control the selected skills
           onChange={(e, value) => {
            handleInputFieldChange(e, "categories", value);
           }}
           PaperComponent={({ children }) => (
            <Paper
             style={{
              backgroundColor: localStorage.getItem("dark")
               ? "#fff"
               : "#212121",
             }}
            >
             {children}
            </Paper>
           )}
           renderInput={(params) => (
            <TextField
             className={classes.menu}
             {...params}
             variant="outlined"
             label="Category of Activity"
             name="Category of Activity"
             position="relative"
            />
           )}
          />
         </Grid>

         {/* Age Group */}
         <Grid item xs={12} md={6}>
          <Autocomplete
           required
           className={classes.cat_list}
           multiple
           id="tags-standard"
           options={age}
           value={
            props?.title == "Edit Activity"
             ? editData?.ageGroup
               ? ageGrpForEdit
               : ""
             : modalData?.ageGroup || []
           }
           onChange={(e, value) => {
            handleInputFieldChange(e, "ageGroup", value);
           }}
           PaperComponent={({ children }) => (
            <Paper
             style={{
              backgroundColor: localStorage.getItem("dark")
               ? "#fff"
               : "#212121",
             }}
            >
             {children}
            </Paper>
           )}
           renderInput={(params) => (
            <TextField
             className={classes.menu}
             {...params}
             variant="outlined"
             label="Age Group"
             name="Age Group"
             position="relative"
            />
           )}
          />
         </Grid>

         {/* Rewards */}
         <Grid item xs={12} md={6}>
          <TextField
           fullWidth
           className={classes.roundedTextField}
           name="Rewards"
           variant="outlined"
           label="Rewards"
           type="number"
           step="0.01"
           style={{ borderRadius: "1px" }}
           value={
            props?.title == "Edit Activity"
             ? editData?.rewards
               ? editData?.rewards
               : ""
             : modalData?.rewards
           }
           onChange={(e) => {
            handleInputFieldChange(e, "rewards");
           }}
          />
         </Grid>
         <Grid item xs={12} md={6}>
          <TextField
           fullWidth
           className={classes.roundedTextField}
           name="EducationPoints"
           variant="outlined"
           label="Education Points"
           type="number"
           step="0.01"
           style={{ borderRadius: "1px" }}
           value={
            props?.title == "Edit Activity"
             ? editData?.educationPoints
               ? editData?.educationPoints
               : ""
             : modalData?.educationPoints
           }
           onChange={(e) => {
            handleInputFieldChange(e, "educationPoints");
           }}
          />
         </Grid>

         {/* Description */}
         <Grid item xs={12} md={12}>
          <TextField
           required
           className={classes.roundedTextField}
           fullWidth
           name="Description"
           variant="outlined"
           label="Description"
           type="text"
           multiline
           rows={4}
           style={{ borderRadius: "1px" }}
           value={
            props?.title == "Edit Activity"
             ? editData?.description
               ? editData?.description
               : ""
             : modalData?.description
           }
           onChange={(e) => handleInputFieldChange(e, "description")}
          />
         </Grid>
        </Grid>
        {/*Create Button */}
        <Grid item xs={12} style={{ textAlign: "center", marginTop: "2em" }}>
         <CustomButton width="20%" margin="2%" type="Submit">
          {props?.title == "Edit Activity" ? "Update Activity" : "Create"}
         </CustomButton>
        </Grid>
       </Box>
      </form>
     </>
    </DialogContent>

    {/* ) : null} */}
   </Dialog>
  </div>
 );
};

export default CreateActivityModal;
