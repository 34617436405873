import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import * as actionCreator from "../../../store/action/index";
import { Box, Button, TextField, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "80%",
    maxWidth: 350,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
  },
  inputContainer: {
    width: "96%",
    marginTop: 20,
  },
  inputStyle: {
    marginTop: 12,
    width: "100%",
    borderRadius: 8,
    border: 0,
    fontFamily: "Manrope",
  },
  submitButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 16,
  },
  submitButton: {
    padding: 8,
    width: 220,
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: "#359DB6",
    borderRadius: 50,
  },
}));

const UploadApprovalFees = (props) => {
  const { approvalAction, notify, approvalDetail } = props;
  const classes = useStyles();
  const [amount, setAmount] = useState({ euro: "", depositEuro: "" });
  const requiredEuro = props.odType?.[approvalDetail?.req_type]
    ?.euro_deposit_required
    ? `(Minimum ${
        props.odType?.[approvalDetail?.req_type]?.euro_deposit_required
      } euro required)`
    : "";
  const submitHandler = (e) => {
    e.preventDefault();
    if (parseFloat(amount.euro) > parseFloat(amount.depositEuro))
      notify("Euro deposit is less than euro amount", "error");
    else {
      if (
        !props.odType?.[approvalDetail?.req_type]?.euro_deposit_required ||
        parseInt(amount.depositEuro) >=
          parseInt(
            props.odType?.[approvalDetail?.req_type]?.euro_deposit_required
          )
      ) {
        approvalAction({
          isApproved: true,
          euroFees: Number(amount.euro),
          euroDepositRequired: Number(amount.depositEuro),
        });
      } else if (
        props.odType?.[approvalDetail?.req_type]?.euro_deposit_required &&
        parseInt(amount.depositEuro) <
          parseInt(
            props.odType?.[approvalDetail?.req_type]?.euro_deposit_required
          )
      ) {
        notify(
          `Minimum ${parseInt(
            props.odType?.[approvalDetail?.req_type]?.euro_deposit_required
          )} euro deposit required.`,
          "error"
        );
      }
    }
  };
  useEffect(() => {
    setAmount({
      euro: approvalDetail?.req_details?.od_detail?.euro_fees || "",
      depositEuro:
        approvalDetail?.req_details?.od_detail?.euro_deposit_required || "",
    });
  }, [approvalDetail]);
  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>Upload Euro Fees</Typography>
      <Box className={classes.inputContainer}>
        <form onSubmit={submitHandler}>
          <TextField
            label={`Euro Deposit ${requiredEuro}`}
            value={amount.depositEuro}
            type="number"
            onChange={(e) => {
              let enteredText = e?.target?.value;
              if (!(enteredText < 0))
                setAmount((s) => ({
                  ...s,
                  depositEuro: enteredText,
                }));
            }}
            className={classes.inputStyle}
            required
            fullWidth
          />
          <TextField
            label="Euro Fee"
            value={amount.euro}
            type="number"
            onChange={(e) => {
              let enteredText = e?.target?.value;
              if (!(enteredText < 0))
                setAmount((s) => ({
                  ...s,
                  euro: enteredText,
                }));
            }}
            className={classes.inputStyle}
            required
            fullWidth
          />
          <Box className={classes.submitButtonContainer}>
            <Button type={"submit"} className={classes.submitButton}>
              Upload
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    approvalDetail: adminReducer.approvalDetail,
    odType: adminReducer.odType,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
    approvalAction: (data) => dispatch(actionCreator.approvalAction(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadApprovalFees);
