import React, { useState, useEffect, forwardRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import * as actionCreator from "../../../../store/action/index";
import MaterialTable from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Spinner from "../../../../components/layout/Spinner/Spinner";
import TransitionsModal from "../../../../components/common/modal";
import UserEscrowTransaction from "./UserEscrowTransaction";
import {
  Backdrop,
  Box,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import CommonModel from "../../../../components/common/CommonModel";

const useStyles = makeStyles((theme) => ({}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),

  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const UserEscrowAccount = (props) => {
  const { notify, loadings, escrowAccount, getEscrowAccount } = props;

  const [id, setId] = useState(useParams().id || null);
  const [selectedRow, setSelectedRow] = useState();

  const classes = useStyles();

  useEffect(() => {
    getEscrowAccount({ id });
  }, []);

  const column = [
    {
      title: "Id",
      field: "escrow_id",
      align: "center",
    },
    {
      title: "Title",
      field: "title",
      align: "center",
    },
    {
      title: "Description",
      field: "description",
      align: "center",
    },
    {
      title: "Account Type",
      field: "account_type_id",
      align: "center",
      render: (rowData) => {
        switch (rowData.account_type_id) {
          case 1:
            return "COG";
          case 3:
            return "EURO";
          case 5:
            return "CBC";
          default:
            return "NA";
        }
      },
    },
    {
      title: "Seller Info",
      field: "seller_profile_info.UserName",
      align: "center",
      render: (rowData) => {
        return (
          <>
            {rowData.seller_profile_info
              ? rowData.seller_profile_info.UserName
              : "NA"}
          </>
        );
      },
    },
    {
      title: "Buyer Info",
      field: "buyer_profile_info.UserName",
      align: "center",
      render: (rowData) => {
        return (
          <>
            {rowData.buyer_profile_info
              ? rowData.buyer_profile_info.UserName
              : "NA"}
          </>
        );
      },
    },
    {
      title: "Status",
      field: "status",
      align: "center",
    },
    {
      title: "Amount",
      field: "amount",
      align: "center",
    },
    {
      title: "Balance",
      field: "balance",
      align: "center",
    },
  ];

  return (
    <>
      {selectedRow ? (
        <CommonModel
          open={selectedRow}
          handleClose={() => setSelectedRow(null)}
          title={"Escrow Transaction"}
          body={<UserEscrowTransaction escrowTransaction={selectedRow} />}
        />
      ) : null}

      {!loadings.getEscrowAccount ? (
        <MaterialTable
          title="Escrow Account"
          icons={tableIcons}
          columns={column}
          options={{
            pageSize: 5,
            pageSizeOptions: [5, 10, 25, 50, 100, 200],
            showFirstLastPageButtons: false,
          }}
          onRowClick={(e, rowData) => {
            setSelectedRow(rowData);
          }}
          data={escrowAccount || []}
        />
      ) : (
        <Spinner />
      )}
    </>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    escrowAccount: adminReducer.userEscrowAccount,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getEscrowAccount: (data) =>
      dispatch(actionCreator.getUserEscrowAccount(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEscrowAccount);
