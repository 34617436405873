import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { forwardRef } from "react";

import { getWithExpiry } from "../../asset/utility";
import { API } from "../../asset/api";
import * as actionCreator from "../../store/action/index";

import Spinner from "../../components/layout/Spinner/Spinner";
import ApprovalHead from "./approval/ApprovalHead";
import ApprovalContent from "./approval/ApprovalContent";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  detailPanel: {
    padding: theme.spacing(3),
  },
  root: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8) + 1,
    },
    borderRadius: "15px",
    "&.MuiToolbar-root": {
      //color: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
      color: theme.palette.background.default,
    },
    "& .MuiTable-root": {
      "& .MuiTableRow-root": {
        "&:nth-child(even)": {
          background: theme.palette.background.paper,
        },
        "&:nth-child(odd)": {
          background: theme.palette.background.default,
        },
      },
    },
  },
  modal: {},
  customTableButton: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "2px 0px",
  },
  inputStyle: {
    //background: "#eeededd7",
    borderRadius: 8,
    border: 0,
    fontFamily: "Manrope",
  },
}));

const ApprovalFlow = (props) => {
  const { getApproval, updateApprovalDetail, admin, approvalDetail } = props;
  const params = useParams();
  const classes = useStyles();

  useEffect(() => {
    if (admin) {
      updateApprovalDetail({
        approvalId: params?.approvalId,
      });
    }
  }, [admin]);

  useEffect(() => {
    if (approvalDetail?.profile_id) {
      props.setOdType({ profileId: approvalDetail?.profile_id });
    }
  }, [approvalDetail]);
  return (
    <Grid
      container
      className={classes.root}
      spacing={3}
      style={{ maxWidth: "100%" }}
    >
      <Grid item xs={12}>
        <ApprovalHead />
      </Grid>
      <Grid item xs={12}>
        {getApproval && !approvalDetail ? <Spinner /> : <ApprovalContent />}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    getApproval: adminReducer.loadings.getApproval,
    approvalData: adminReducer.approvalDetail,
    admin: adminReducer.admin,
    approvalDetail: adminReducer.approvalDetail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
    updateApprovalDetail: (data) =>
      dispatch(actionCreator.updateApprovalDetail(data)),
    setOdType: (data) => dispatch(actionCreator.getOdType(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalFlow);
