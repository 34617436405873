import React, { useState, useEffect,useRef} from 'react';
import { connect } from "react-redux";
import { useParams } from "react-router";
import './css/common.css';
import { Grid,Container,Button,Typography,TextField,Stepper,Step,StepLabel,StepContent,
  FormControlLabel,Checkbox,List,ListItem,ListItemAvatar,ListItemText,Avatar,Backdrop,CircularProgress} from '@material-ui/core';
import { makeStyles ,withStyles } from '@material-ui/core/styles';
import { green,red } from '@material-ui/core/colors';
import {Error,Done} from '@material-ui/icons';
import {Alert} from '@material-ui/lab';

import MuiPhoneNumber from "material-ui-phone-number";
import phone from "phone";

import GoogleMaps from '../../components/auth/addressInput';

import Logo from '../../components/layout/Logo1';
import Spinner from '../../components/layout/Spinner/Spinner';

import * as actionCreator from "../../store/action/index";
import { updateObjectProperty } from "../../asset/utility";

const useStyles = makeStyles((theme) => ({
  icon:{
    fontSize: 150
  },
  errorPhone : {
    border: "1px solid red",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const CustomButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    height: 50,
    width: "100%",
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    background: "#359DB6",
    '&:hover': {
      background: "#62c3db",
    }
  },
  label: {
    textTransform: "capitalize"
  },
  disabled :{
    background: "#8ab0b9"
  }
})(props => <Button {...props} />);

const CustomInput = withStyles({
  root: {
    //background: "#eeededd7",
    borderRadius: 8,
    border: 0,
    width: "100%",
    fontFamily: "Manrope",
  },

})(props => <TextField {...props} />);

const Register = (props) => {
  const [steps,setSteps] = useState(['Verify Email', 'Create a Profile', 'Agree to Terms' , 'Create a Password']);
  const [activeStep,setActiveStep] = useState(0);
  const [token,setToken] = useState(useParams().token || null);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    phone:"",
    address:"",
    password: "",
    re_password: "",
    termsAgreed:false
  });  
  const [errorMessages, setErrorMessages] = useState({
    firstNameError : "",
    lastNameError : "",
    phoneError : "",
    addressError : "",
    passwordError : "",
    rePasswordError:""
  });
  const [validData, setValidData] = useState({
    validFirstName : false,
    validLastName : false,
    validPhone : false,
    validAddress : false,
    validPassword : false,
    validRePassword : false
  });

  const {history,ResetErrors,loading,error,regUser,registered,
    confirmEmailToken,registerAsync} = props;

  useEffect(
    () => {
      if(token) confirmEmailToken({token : token});
      return ()=>{
        ResetErrors();
      }
    },
    [confirmEmailToken,token,ResetErrors]
  );
  
  useEffect(
    () => {
      if(regUser) setActiveStep(1);
      if(registered) history.push("/");
    },
    [history,regUser,registered]
  );

  const validateField = (field,value) => {
    let errorMessagesCopy = { ...errorMessages };
    let validDataCopy = { ...validData };
    

    if(field === "firstName" || field === "lastName"){
      if(value.trim().length === 0){
        if(field === "firstName"){
          errorMessagesCopy.firstNameError = "First name is required";
          validDataCopy.validFirstName = false;
        }
        else if(field === "lastName"){
          errorMessagesCopy.lastNameError = "Last name is required";
          validDataCopy.validLastName = false;
        }
      }else if(!/^[A-Za-z ]+$/.test(value.trim())){
        if(field === "firstName"){
          errorMessagesCopy.firstNameError = "Name should contain only alphabets";
          validDataCopy.validFirstName = false;
        }
        else if(field === "lastName"){
          errorMessagesCopy.lastNameError = "Name should contain only alphabets";
          validDataCopy.validLastName = false;
        }
      }else{
        if(field === "firstName"){
          validDataCopy.validFirstName = true;
          errorMessagesCopy.firstNameError = "";
        }
        else if(field === "lastName"){
          validDataCopy.validLastName = true;
          errorMessagesCopy.lastNameError = "";
        }
      }
    }else if(field === "password"){
      let passwordRegEX = /(?=.*\d).{8,}/;
        if(value.trim().length===0){
          errorMessagesCopy.passwordError = "Password is required";
          validDataCopy.validPassword = false;
        }else if ( !passwordRegEX.test(value.trim())){
          errorMessagesCopy.passwordError = "Password must contain at least 8 characters, including a number";
          validDataCopy.validPassword = false;
        }else{
          errorMessagesCopy.passwordError = "";
          validDataCopy.validPassword = true;
        }
    }else if(field === "re_password"){
      if(value !== user.password){
        errorMessagesCopy.rePasswordError = "Passwords don't match";
        validDataCopy.validRePassword = false;
      }else{
        errorMessagesCopy.rePasswordError = "";
        validDataCopy.validRePassword = true;
      }
    }else if(field === "userName"){
      let userIdRegEX = /^[a-z0-9]+$/i;
        if(value.trim().length===0){
          errorMessagesCopy.userIdError = "User Name is required";
          validDataCopy.validuserId = false;
        }else if ( !userIdRegEX.test(value.trim())){
          errorMessagesCopy.userIdError = "User Name must contain alphabets and numbers only";
          validDataCopy.validuserId = false;
        }else{
          errorMessagesCopy.userIdError = "";
          validDataCopy.validuserId = true;
        }
    }else if(field === "phone" ){
      let result = phone(value, "",true);
      if(value === ""){
          errorMessagesCopy.phoneError = "Phone number is required ";
          validDataCopy.validPhone= false;
      }else if(result.length){
          errorMessagesCopy.phoneError = "";
          validDataCopy.validPhone = true;
      }else{
          errorMessagesCopy.phoneError = "Put the correct phone number";
          validDataCopy.validPhone = false;
      }
    }else if(field === "address"){
      if(value === ""){
          errorMessagesCopy.addressError = "Address is required";
          validDataCopy.validAddress= false;
      }else{
          errorMessagesCopy.addressError = "";
          validDataCopy.validAddress = true;
      }
    }
    setErrorMessages(errorMessagesCopy);
    setValidData(validDataCopy);
  }

  const onUserValueChanged = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setUser(updateObjectProperty(user,name,value));
    validateField(name,value);
  }

  const onPhoneValueChanged = (value) => {
    let name = "phone";
    setUser(updateObjectProperty(user,name,value));
    validateField(name,value);
  }

  const onAddressValueChanged = (value) => {
    let name = "address";
    setUser(updateObjectProperty(user,name,value));
    validateField(name,value);
  }

  const profileFormSubmitHandler = (e) => {
    e.preventDefault();
    if(validData.validFirstName && 
      validData.validLastName && 
      validData.validPhone && 
      validData.validAddress
      ){
        //if(regUser.type === "personal"){
          setActiveStep(2);
        //}
      }
  }

  const setTermsAgreed = (value) =>{
    setUser(updateObjectProperty(user,"termsAgreed",value));
  }

  const passwordFormSubmitHandler = (e) => {
    e.preventDefault();
    let data = {...regUser, ...user};
    if(regUser &&
      validData.validFirstName && 
      validData.validLastName && 
      validData.validPhone && 
      validData.validAddress &&
      validData.validPassword &&
      validData.validRePassword){
        //if(regUser.type === "personal"){
        //}
        registerAsync(data);
        
      }
  }

  const nextStep = () => {
    setActiveStep(activeStep + 1);
  }

  const previousStep = () => {
    setActiveStep(activeStep - 1);
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container>
            {!loading ?
              <Grid item container justify="center" spacing={1}>
                <Grid item>
                  <Done style={{ color: green[500] }} className={classes.icon}/>
                </Grid>
                <Grid item xs={12}>
                  <Alert severity="success">
                    <strong>{"Verified !!"}</strong>
                  </Alert>
                </Grid>
              </Grid>
            :
            <Grid item xs={12}>
              <Spinner/>
            </Grid>
            }
          </Grid>
        )
      case 1:
        return (
          <form onSubmit={profileFormSubmitHandler} method="post">
            <Grid item container spacing={2}>
              <Grid item sm={6} xs={12}>
                <CustomInput 
                  variant="outlined"
                  placeholder={'First Name'}
                  label="First Name"
                  type="text"
                  name="firstName"
                  required
                  error = {errorMessages?.firstNameError?.length > 0}
                  helperText = {errorMessages?.firstNameError}
                  value = {user.firstName}
                  onChange={onUserValueChanged}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <CustomInput 
                  variant="outlined"
                  placeholder={'Last Name'}
                  label="Last Name"
                  type="text"
                  name="lastName"
                  required
                  error = {errorMessages?.lastNameError?.length > 0}
                  helperText = {errorMessages?.lastNameError}
                  value = {user.lastName}
                  onChange={onUserValueChanged}
                />
              </Grid>
              <Grid item xs={12}>
                <MuiPhoneNumber
                  variant="outlined"
                  name="phone"
                  label="Phone Number"
                  //data-cy="user-phone"
                  //style={{background: '#eeededd7'}}
                  defaultCountry={"in"}
                  required
                  fullWidth
                  error = {errorMessages?.phoneError?.length > 0}
                  helperText = {errorMessages?.phoneError}
                  value={user.phone}
                  onChange={onPhoneValueChanged}
                />
              </Grid>
              <Grid item xs={12}>
                <GoogleMaps 
                  onValueChange={onAddressValueChanged}
                  defaultAddress = {user.address}
                  error = {errorMessages?.addressError?.length > 0}
                  helperText = {errorMessages?.addressError}
                />
              </Grid>
              <Grid item xs={6}/>
              <Grid item xs={6}>
                <CustomButton
                    type = "submit"
                    disabled={loading || 
                              !validData.validFirstName || 
                              !validData.validLastName || 
                              !validData.validPhone ||
                              !validData.validAddress
                            }
                  >
                    Next
                  </CustomButton>
              </Grid>
            </Grid>
          </form>
        );
      case 2:
        return (
            <Grid item container direction="column" alignItems="center">
              <Grid item xs={12}>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Done />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      Agree to appoint Accendos Group NZ Limited as Power of Attorney
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Done />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                    Agree to have Accendos Group NZ Limited to obtain copy of the data that was released as part of the High Court of Christchurch data breach in April 2020.
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Done />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      Agreed to be added to any class action related to this data breach.
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
              <Grid item>
                  <FormControlLabel
                      control={<Checkbox checked={user.termsAgreed} color="primary" />}
                      label="I agree to the above terms and conditions."
                      onChange={() => setTermsAgreed(!user.termsAgreed)}
                  />
              </Grid>
              <Grid item container direction="row" justify="space-between" xs={12} spacing={1} >
                <Grid item xs={6}>
                    <CustomButton
                      disabled={loading}
                      onClick={previousStep}
                    >
                      Back
                    </CustomButton>
                </Grid>
                <Grid item xs={6}>
                    <CustomButton
                      disabled={loading || !user.termsAgreed}
                      onClick={nextStep}
                    >
                      Next
                    </CustomButton>
                </Grid>
              </Grid>
            </Grid>
        );
        case 3:
          return (
            <form onSubmit={passwordFormSubmitHandler} method="post">
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12}>
                  <CustomInput 
                    variant="outlined"
                    placeholder={'Password'}
                    label="Password"
                    type="password"
                    name="password"
                    required
                    error = {errorMessages?.passwordError?.length > 0}
                    helperText = {errorMessages?.passwordError}
                    value = {user.password}
                    onChange={onUserValueChanged}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomInput 
                    variant="outlined"
                    placeholder={'Re enter passworrd'}
                    label="Re enter password"
                    type="password"
                    name="re_password"
                    required
                    error = {errorMessages?.rePasswordError?.length > 0}
                    helperText = {errorMessages?.rePasswordError}
                    value = {user.re_password}
                    onChange={onUserValueChanged}
                  />
                </Grid>
                <Grid item xs={6}>
                    <CustomButton
                      disabled={loading}
                      onClick={previousStep}
                    >
                      Back
                    </CustomButton>
                </Grid>
                <Grid item xs={6}>
                  <CustomButton
                    type = "submit"
                    disabled={loading || 
                              !validData.validFirstName || 
                              !validData.validLastName || 
                              !validData.validPhone || 
                              !validData.validAddress || 
                              !validData.validPassword || 
                              !validData.validRePassword}
                  >
                    Submit
                  </CustomButton>
                </Grid>
              </Grid>
            </form>
          );
      default:
        return 'Unknown step';
    }
  }

  const classes = useStyles();

  return (
    <Container component="main" maxWidth="sm">
      <Logo/>
      {!regUser ?
      <Grid item container justify="center" spacing={1}>
        {!loading ?
          <Grid item>
            <Error style={{ color: red[500] }} className={classes.icon}/>
          </Grid>
        :
        <Grid item xs={12}>
          <Spinner/>
        </Grid>
        }
      </Grid>
      :
      <div className="cogito-container">
        <Typography variant="subtitle2" component="h6" align="center" style={{marginTop : 10}}>
          Please complete registration process for <strong>{regUser.email}</strong>
        </Typography>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps?.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {getStepContent(index)}
              </StepContent>
            </Step>
          ))}
        </Stepper>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      }
    </Container>
  );

}

const mapStateToProps = ({ authReducer }) => {
  return {
    loading: authReducer.loading,
    error: authReducer.error,
    regUser : authReducer.regUser,
    registered : authReducer.registered
  };
};
const mapDispatchToProps = dispatch => {
  return {
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
    confirmEmailToken: (data) => dispatch(actionCreator.confirmEmailToken(data)),
    registerAsync: userData => dispatch(actionCreator.RegisterAsync(userData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register)