import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
	Link,
	Grid,
	Paper,
	Button,
	Typography,
	Divider,
	TextField,
	Table,
	TableBody,
	TableHead,
	TableRow,
	TableCell,
} from "@material-ui/core";

import * as actionCreator from "../../store/action/index";
import { updateObjectProperty } from "../../asset/utility";

import MuiPhoneNumber from "material-ui-phone-number";
import phone from "phone";
import GoogleMaps from "../../components/auth/addressInput";

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const CustomInput = withStyles({
	root: {
		//background: "#eeededd7",
		borderRadius: 8,
		border: 0,
		width: "100%",
		fontFamily: "Manrope",
	},
})((props) => <TextField {...props} />);

const CustomButton = withStyles({
	root: {
		borderRadius: 8,
		border: 0,
		color: "white",
		fontFamily: "Manrope",
		fontStyle: "normal",
		background: "#359DB6",
		"&:hover": {
			background: "#62c3db",
		},
	},
	label: {
		textTransform: "capitalize",
	},
	disabled: {
		background: "#8ab0b9",
	},
})((props) => <Button {...props} />);

const SuccessButton = withStyles({
	root: {
		borderRadius: 8,
		border: 0,
		color: "white",
		fontFamily: "Manrope",
		fontStyle: "normal",
		background: "#10ca73",
		"&:hover": {
			background: "#87f891",
		},
	},
	label: {
		textTransform: "capitalize",
	},
	disabled: {
		background: "#758876",
	},
})((props) => <Button {...props} />);

const useStyles = makeStyles((theme) => ({
	paper: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(3),
		borderRadius: 10,
	},
	smallView: {
		[theme.breakpoints.up("sm")]: {
			display: "none",
		},
	},
	table: {
		width: "100%",
	},
}));

const EditAdminProfile1 = (props) => {
	const classes = useStyles();
	const {
		profile,
		loadings,
		updateAdmin,
		UpdateAdminPassword,
		ResetAdminLink,
		admin,
	} = props;
	const [profileMod, setProfileMod] = useState(false);
	const [inputUser, setInputUser] = useState(null);
	const [inputPassword, setInputPassword] = useState({
		newPassword: "",
		rePassword: "",
	});
	const [errorMessages, setErrorMessages] = useState({
		firstNameError: "",
		lastNameError: "",
		emailError: "",
		phoneError: "",
		addressError: "",
		passwordError: "",
		newPasswordError: "",
		rePasswordError: "",
	});
	const [validData, setValidData] = useState({
		validFirstName: false,
		validLastName: false,
		validEmail: false,
		validPhone: false,
		validAddress: false,
		validPassword: false,
		validNewPassword: false,
		validRePassword: false,
	});

	useEffect(() => {
		for (const property in inputUser) {
			if (profile[property] !== inputUser[property]) {
			} else {
				delete inputUser[property];
				if (isEmpty(inputUser)) setInputUser(null);
			}
		}
	}, [profile, inputUser]);

	const validateField = (field, value) => {
		let errorMessagesCopy = { ...errorMessages };
		let validDataCopy = { ...validData };

		if (field === "firstName" || field === "lastName") {
			if (value.trim().length === 0) {
				if (field === "firstName") {
					errorMessagesCopy.firstNameError = "First name is required";
					validDataCopy.validFirstName = false;
				} else if (field === "lastName") {
					errorMessagesCopy.lastNameError = "Last name is required";
					validDataCopy.validLastName = false;
				}
			} else if (!/^[A-Za-z ]+$/.test(value.trim())) {
				if (field === "firstName") {
					errorMessagesCopy.firstNameError = "Name should contain only alphabets";
					validDataCopy.validFirstName = false;
				} else if (field === "lastName") {
					errorMessagesCopy.lastNameError = "Name should contain only alphabets";
					validDataCopy.validLastName = false;
				}
			} else {
				if (field === "firstName") {
					validDataCopy.validFirstName = true;
					errorMessagesCopy.firstNameError = "";
				} else if (field === "lastName") {
					validDataCopy.validLastName = true;
					errorMessagesCopy.lastNameError = "";
				}
			}
		} else if (field === "email") {
			let emailRegEX =
				/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
			if (value.trim().length === 0) {
				errorMessagesCopy.emailError = "This field is required";
				validDataCopy.validEmail = false;
			} else if (!emailRegEX.test(value.trim())) {
				errorMessagesCopy.emailError = "please enter a valid email address";
				validDataCopy.validEmail = false;
			} else {
				errorMessagesCopy.emailError = "";
				validDataCopy.validEmail = true;
			}
		} else if (field === "currentPassword") {
			if (value.trim().length === 0) {
				errorMessagesCopy.passwordError = "Password is required";
				validDataCopy.validPassword = false;
			} else {
				errorMessagesCopy.passwordError = "";
				validDataCopy.validPassword = true;
			}
		} else if (field === "newPassword") {
			let passwordRegEx1 = /[A-Z]/;
			let passwordRegEx2 = /[a-z]/;
			let passwordRegEx3 = /[0-9]/;
			if (value.trim().length === 0) {
				errorMessagesCopy.newPasswordError = "Password is required";
				validDataCopy.validNewPassword = false;
			} else if (value.trim().length < 8) {
				errorMessagesCopy.newPasswordError =
					"Password should be of minimum 8 characters";
				validDataCopy.validPassword = false;
			} else if (!passwordRegEx1.test(value.trim())) {
				errorMessagesCopy.newPasswordError = "one uppercase required!";
				validDataCopy.validPassword = false;
			} else if (!passwordRegEx2.test(value.trim())) {
				errorMessagesCopy.newPasswordError = "one lowercase required!";
				validDataCopy.validPassword = false;
			} else if (!passwordRegEx3.test(value.trim())) {
				errorMessagesCopy.newPasswordError = "one number required!";
				validDataCopy.validPassword = false;
			} else {
				errorMessagesCopy.newPasswordError = "";
				validDataCopy.validNewPassword = true;
			}
		} else if (field === "rePassword") {
			if (value !== inputPassword.newPassword) {
				errorMessagesCopy.rePasswordError = "Passwords don't match";
				validDataCopy.validRePassword = false;
			} else {
				errorMessagesCopy.rePasswordError = "";
				validDataCopy.validRePassword = true;
			}
		} else if (field === "phone") {
			let result = phone(value, "", true);
			if (value === "") {
				errorMessagesCopy.phoneError = "Phone number is required ";
				validDataCopy.validPhone = false;
			} else if (result.length) {
				errorMessagesCopy.phoneError = "";
				validDataCopy.validPhone = true;
			} else {
				errorMessagesCopy.phoneError = "Put the correct phone number";
				validDataCopy.validPhone = false;
			}
		} else if (field === "address") {
			if (value === "") {
				errorMessagesCopy.addressError = "Address is required";
				validDataCopy.validAddress = false;
			} else {
				errorMessagesCopy.addressError = "";
				validDataCopy.validAddress = true;
			}
		}
		setErrorMessages(errorMessagesCopy);
		setValidData(validDataCopy);
	};

	const onUserValueChanged = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		setInputUser(updateObjectProperty(inputUser, name, value));
		validateField(name, value);
	};

	const onUserPhoneValueChanged = (value) => {
		let name = "phone";
		setInputUser(updateObjectProperty(inputUser, name, value));
		validateField(name, value);
	};

	const onUserAddressValueChanged = (value) => {
		let name = "address";
		setInputUser(updateObjectProperty(inputUser, name, value));
		validateField(name, value);
	};

	const onProfileBack = () => {
		setProfileMod(!profileMod);
		setInputUser(null);
		setErrorMessages({
			firstNameError: "",
			lastNameError: "",
			emailError: "",
			phoneError: "",
			addressError: "",
			passwordError: "",
			newPasswordError: "",
			rePasswordError: "",
		});
		setValidData({
			validFirstName: false,
			validLastName: false,
			validEmail: false,
			validPhone: false,
			validAddress: false,
			validPassword: false,
			validNewPassword: false,
			validRePassword: false,
		});
	};

	const onProfileSave = () => {
		if (
			errorMessages?.firstNameError?.length === 0 &&
			errorMessages?.lastNameError?.length === 0 &&
			errorMessages?.phoneError?.length === 0 &&
			errorMessages?.addressError?.length === 0 &&
			profile &&
			inputUser
		) {
			updateAdmin({
				id: profile._id,
				changes: { level: profile.level, inputUser },
			});
		}
	};

	const onPasswordValueChanged = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		setInputPassword(updateObjectProperty(inputPassword, name, value));
		validateField(name, value);
	};

	const onPasswordSave = () => {
		if (profile && validData.validNewPassword && validData.validRePassword) {
			UpdateAdminPassword({
				email: profile.email,
				id: profile._id,
				level: profile.level,
				currentPassword: inputPassword.currentPassword,
				newPassword: inputPassword.newPassword,
			});
			setInputPassword({
				newPassword: "",
				rePassword: "",
			});
		}
	};

	const onPasswordReset = () => {
		ResetAdminLink({
			id: profile.id,
			email: profile.email,
		});
	};

	return (
		<React.Fragment>
			<Paper className={classes.paper} elevation={5}>
				<Grid container spacing={2}>
					<Grid item container xs={12} alignItems="center" spacing={2}>
						<Grid item container xs={12} justify="space-between" alignItems="center">
							<Grid item>
								<Typography variant="h5" align="left">
									Admin Profile
								</Typography>
							</Grid>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
							<Divider />
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography variant="h6" align="left">
								Email
							</Typography>
						</Grid>
						<Grid item xs={12} sm={8}>
							<Typography variant="body2" color="textSecondary" align="left">
								{profile.email}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography variant="h6" align="left">
								Status
							</Typography>
						</Grid>
						<Grid item xs={12} sm={8}>
							<Typography variant="body2" color="textSecondary" align="left">
								{profile.active ? "Active" : "Not Active"}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography variant="h6" align="left">
								Level
							</Typography>
						</Grid>
						<Grid item xs={12} sm={8}>
							<Typography variant="body2" color="textSecondary" align="left">
								{profile.level}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography variant="h6" align="left">
								Type
							</Typography>
						</Grid>
						<Grid item xs={12} sm={8}>
							<Typography variant="body2" color="textSecondary" align="left">
								{profile.type}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography variant="h6" align="left">
								Parent Admin Id
							</Typography>
						</Grid>
						<Grid item xs={12} sm={8}>
							<Typography variant="body2" color="textSecondary" align="left">
								{profile.parent_id}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography variant="h6" align="left">
								Last Login
							</Typography>
						</Grid>
						<Grid item xs={12} sm={8}>
							<Typography variant="body2" color="textSecondary" align="left">
								{profile.activity_time?.last_login}
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography variant="h6" align="left">
							Registered
						</Typography>
					</Grid>
					<Grid item xs={12} sm={8}>
						<Typography variant="body2" color="textSecondary" align="left">
							{profile.activity_time?.registration_time}
						</Typography>
					</Grid>
					<Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
						<Divider />
					</Grid>
					{profile?.user_profile ? (
						<Grid item container xs={12} alignItems="center" spacing={2}>
							<Grid item container xs={12} justify="space-between" alignItems="center">
								<Grid item>
									<Typography variant="h5" align="left">
										User Profile
									</Typography>
								</Grid>
							</Grid>
							<Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
								<Divider />
							</Grid>
							<Grid item container xs={12} justify="center">
								<Grid item xs={10}>
									<Table className={classes.table} aria-label="customized table">
										<TableHead>
											<TableRow>
												<StyledTableCell>Profile Id</StyledTableCell>
												<StyledTableCell align="center">Profile UserName</StyledTableCell>
												<StyledTableCell align="center">Profile Name</StyledTableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<StyledTableRow key={profile?.user_profile?._id}>
												<StyledTableCell component="th" scope="row">
													{profile?.user_profile?._id}
												</StyledTableCell>
												<StyledTableCell component="th" scope="row">
													{profile?.user_profile?.userName}
												</StyledTableCell>
												<StyledTableCell component="th" scope="row">
													{profile?.user_profile?.firstName +
														" " +
														profile?.user_profile?.lastName}
												</StyledTableCell>
											</StyledTableRow>
										</TableBody>
									</Table>
								</Grid>
							</Grid>
							<Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
								<Divider />
							</Grid>
						</Grid>
					) : null}
					<Grid item container xs={12} alignItems="center" spacing={2}>
						<Grid item container xs={12} justify="space-between" alignItems="center">
							<Grid item>
								<Typography variant="h5" align="left">
									Personal Data
								</Typography>
							</Grid>
							<Grid item>
								{profileMod ? (
									<Grid container item spacing={1}>
										<Grid item>
											<CustomButton
												onClick={onProfileBack}
												disabled={loadings.updateAdmin}
											>
												Back
											</CustomButton>
										</Grid>
										<Grid item>
											<SuccessButton
												onClick={onProfileSave}
												disabled={
													!(
														errorMessages?.firstNameError?.length === 0 &&
														errorMessages?.lastNameError?.length === 0 &&
														errorMessages?.phoneError?.length === 0 &&
														errorMessages?.addressError?.length === 0 &&
														profile &&
														inputUser
													) || loadings.updateAdmin
												}
											>
												Save
											</SuccessButton>
										</Grid>
									</Grid>
								) : (
									<></>
									// <CustomButton
									//   onClick={() => setProfileMod(!profileMod)}
									//   disabled={
									//     loadings.updateAdmin ||
									//     !Boolean(profile) ||
									//     profile.email !== admin.email

									//   }
									// >
									//   Modify
									// </CustomButton>
								)}
							</Grid>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
							<Divider />
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography variant="h6" align="left">
								Name
							</Typography>
						</Grid>
						<Grid container item xs={12} sm={8}>
							{!profileMod ? (
								<Grid item xs={12}>
									<Typography variant="body2" color="textSecondary" align="left">
										{profile.firstName + " " + profile.lastName}
									</Typography>
								</Grid>
							) : (
								<Grid container item spacing={1}>
									<Grid item xs={12} sm={6}>
										<CustomInput
											variant="outlined"
											type="text"
											name="firstName"
											label="First Name"
											error={errorMessages?.firstNameError?.length > 0}
											helperText={errorMessages?.firstNameError}
											//value = {inputUser.firstName}
											defaultValue={profile.firstName}
											onChange={onUserValueChanged}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<CustomInput
											variant="outlined"
											type="text"
											name="lastName"
											label="Last Name"
											error={errorMessages?.lastNameError?.length > 0}
											helperText={errorMessages?.lastNameError}
											//value = {inputUser.lastName}
											defaultValue={profile.lastName}
											onChange={onUserValueChanged}
										/>
									</Grid>
								</Grid>
							)}
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography variant="h6" align="left">
								Phone
							</Typography>
						</Grid>
						<Grid item xs={12} sm={8}>
							{!profileMod ? (
								<Typography variant="body2" color="textSecondary" align="left">
									{profile.phone}
								</Typography>
							) : (
								<MuiPhoneNumber
									variant="outlined"
									name="phone"
									label="Phone Number"
									//data-cy="user-phone"
									//style={{background: '#eeededd7'}}
									fullWidth
									error={errorMessages?.phoneError?.length > 0}
									helperText={errorMessages?.phoneError}
									value={profile.phone}
									//defaultValue = {profile.phone}
									onChange={onUserPhoneValueChanged}
								/>
							)}
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography variant="h6" align="left">
								Address
							</Typography>
						</Grid>
						<Grid item xs={12} sm={8}>
							{!profileMod ? (
								<Typography variant="body2" color="textSecondary" align="left">
									{profile.address}
								</Typography>
							) : (
								<GoogleMaps
									onValueChange={onUserAddressValueChanged}
									defaultAddress={profile.address}
									error={errorMessages?.addressError?.length > 0}
									helperText={errorMessages?.addressError}
								/>
							)}
						</Grid>
						{profileMod ? (
							<Grid
								container
								item
								spacing={1}
								justify="space-between"
								className={classes.smallView}
							>
								<Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
									<Divider />
								</Grid>
								<Grid item>
									<CustomButton onClick={onProfileBack} disabled={loadings.updateAdmin}>
										Back
									</CustomButton>
								</Grid>
								<Grid item>
									<SuccessButton
										onClick={onProfileSave}
										disabled={
											!(
												errorMessages?.firstNameError?.length === 0 &&
												errorMessages?.lastNameError?.length === 0 &&
												errorMessages?.phoneError?.length === 0 &&
												errorMessages?.addressError?.length === 0 &&
												profile &&
												inputUser
											) || loadings.updateAdmin
										}
									>
										Save
									</SuccessButton>
								</Grid>
							</Grid>
						) : (
							<Grid container item justify="flex-end" className={classes.smallView}>
								<Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
									<Divider />
								</Grid>
								<Grid item>
									<CustomButton
										fullWidth
										onClick={() => setProfileMod(!profileMod)}
										disabled={loadings.updateAdmin || !Boolean(profile)}
									>
										Modify
									</CustomButton>
								</Grid>
							</Grid>
						)}
					</Grid>
					<Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
						<Divider />
					</Grid>
					{admin.email !== profile.email && admin.level < profile.level ? (
						<Grid item container xs={12} alignItems="center" spacing={2}>
							<Grid item container xs={12} justify="space-between" alignItems="center">
								<Grid item>
									<Typography variant="h5" align="left">
										Change Password
									</Typography>
								</Grid>
							</Grid>
							<Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
								<Divider />
							</Grid>
							<Grid
								item
								container
								xs={12}
								justify="flex-end"
								alignItems="center"
								spacing={2}
							>
								{/* <Grid item xs={12} sm={4}>
                  <CustomInput
                    error={errorMessages.passwordError.length > 0}
                    helperText={errorMessages.passwordError}
                    variant="outlined"
                    name="currentPassword"
                    type="password"
                    label="Current Password"
                    placeholder="Current Password"
                    required
                    value={inputPassword.currentPassword}
                    onChange={onPasswordValueChanged}
                  />
                </Grid> */}
								<Grid item xs={12} sm={6}>
									<CustomInput
										error={errorMessages?.newPasswordError?.length > 0}
										helperText={errorMessages?.newPasswordError}
										variant="outlined"
										name="newPassword"
										type="password"
										label="New Password"
										placeholder="New Password"
										required
										value={inputPassword.newPassword}
										onChange={onPasswordValueChanged}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<CustomInput
										error={errorMessages?.rePasswordError?.length > 0}
										helperText={errorMessages?.rePasswordError}
										variant="outlined"
										name="rePassword"
										type="password"
										label="Re-New Password"
										placeholder="Re-New Password"
										required
										value={inputPassword.rePassword}
										onChange={onPasswordValueChanged}
									/>
								</Grid>
								<Grid item>
									<SuccessButton
										onClick={onPasswordSave}
										disabled={
											!(validData.validNewPassword && validData.validRePassword) ||
											(inputPassword.newPassword == "" &&
												inputPassword.rePassword == "") ||
											loadings.updateAdminPassword
										}
										fullWidth
									>
										Save
									</SuccessButton>
								</Grid>
								<Grid item>
									<SuccessButton onClick={onPasswordReset}>Reset</SuccessButton>
								</Grid>
							</Grid>
						</Grid>
					) : admin.email !== profile.email && admin.level === profile.level ? (
						<Grid item container xs={12} alignItems="center" spacing={2}>
							<Grid item container xs={12} justify="space-between" alignItems="center">
								<Grid item>
									<Typography variant="h5" align="left">
										Change Password
									</Typography>
								</Grid>
							</Grid>
							<Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
								<Divider />
							</Grid>
							<Grid container item justify="center">
								<SuccessButton onClick={onPasswordReset}>Reset</SuccessButton>
							</Grid>
						</Grid>
					) : null}
				</Grid>
			</Paper>
		</React.Fragment>
	);
};
const mapStateToProps = ({ adminReducer }) => {
	return {
		loadings: adminReducer.loadings,
		admin: adminReducer.admin,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		updateAdmin: (data) => dispatch(actionCreator.updateAdmin(data)),
		UpdateAdminPassword: (data) =>
			dispatch(actionCreator.UpdateAdminPassword(data)),
		ResetAdminLink: (data) => dispatch(actionCreator.ResetAdminLink(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAdminProfile1);
