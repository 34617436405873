import React from 'react';
import { makeStyles,useTheme  } from '@material-ui/core/styles';
import logo from '../../asset/images/logo.png';
import logoDark from '../../asset/images/cogito-logo_blue.png';


const useStyles = makeStyles((theme) => ({
    logo: {
        marginTop: theme.spacing(1),
    },
    image :{
        height: 50,
        width : 50
    }
  }));

const Logo = () => {
    const classes = useStyles();
    const theme = useTheme();

  return <div className={classes.logo}><img src={theme.palette.type == "dark" ? logoDark : logo} className={classes.image}/></div>;
}

export default Logo;