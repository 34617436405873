import { Divider, Grid, Typography } from "@material-ui/core";
import {
 AddLocation,
 BusinessCenter,
 PermMedia,
 SupervisedUserCircle,
} from "@material-ui/icons";
import React from "react";
import styles from "../../../components/styles";
import clsx from "clsx";

const SideMenu = (props) => {
 const { activeSection, handleSelection } = props;
 const classes = styles();

 return (
  <div>
   <Grid item>
    <Grid
     item
     className={clsx(
      classes.linkBlock,
      classes.rowMenuView,
      activeSection == "Business Details"
       ? classes.iconSelected
       : classes.iconButton
     )}
     onClick={() => handleSelection("Business Details")}
     style={{ cursor: "pointer" }}
    >
     <Grid item>
      <BusinessCenter elevation={5} className={clsx(classes.menuIcon)} />
     </Grid>
     <Grid item>
      <Typography className={classes.menuText}>Business Details</Typography>
     </Grid>
    </Grid>
    <Divider />
    <Grid
     item
     className={clsx(
      classes.linkBlock,
      classes.rowMenuView,
      activeSection == "Business Locations"
       ? classes.iconSelected
       : classes.iconButton
     )}
     onClick={() => handleSelection("Business Locations")}
     style={{ cursor: "pointer" }}
    >
     <Grid item>
      <AddLocation elevation={5} className={clsx(classes.menuIcon)} />
     </Grid>
     <Grid item>
      <Typography className={classes.menuText}> Business Location</Typography>
     </Grid>
    </Grid>
    <Divider />
    <Grid
     item
     className={clsx(
      classes.linkBlock,
      classes.rowMenuView,
      activeSection == "Business Staff"
       ? classes.iconSelected
       : classes.iconButton
     )}
     onClick={() => handleSelection("Business Staff")}
     style={{ cursor: "pointer" }}
    >
     <Grid item>
      <SupervisedUserCircle elevation={5} className={clsx(classes.menuIcon)} />
     </Grid>
     <Grid item>
      <Typography className={classes.menuText}> Business Staff</Typography>
     </Grid>
    </Grid>
    <Divider />
    <Grid
     item
     className={clsx(
      classes.linkBlock,
      classes.rowMenuView,
      activeSection == "Business Images"
       ? classes.iconSelected
       : classes.iconButton
     )}
     onClick={() => handleSelection("Business Images")}
     style={{ cursor: "pointer" }}
    >
     <Grid item>
      <PermMedia elevation={5} className={clsx(classes.menuIcon)} />
     </Grid>
     <Grid item>
      <Typography className={classes.menuText}> Business Images</Typography>
     </Grid>
    </Grid>

    <Divider />
    <Grid
     item
     className={clsx(
      classes.linkBlock,
      classes.rowMenuView,
      activeSection == "Business Activity"
       ? classes.iconSelected
       : classes.iconButton
     )}
     onClick={() => handleSelection("Business Activity")}
     style={{ cursor: "pointer" }}
    >
     <Grid item>
      <PermMedia elevation={5} className={clsx(classes.menuIcon)} />
     </Grid>
     <Grid item>
      <Typography className={classes.menuText}> Business Activity</Typography>
     </Grid>
    </Grid>
   </Grid>
  </div>
 );
};

export default SideMenu;
