const admins = {
 1: {
  Cogito: {
   Authorized: {
    Admins: [
     "adddAdmin",
     "editAdminDetails",
     "deleteAdmin",
     "changePassword",
     "addAdmin",
    ],
    Users: [
     "user",
     "userDetails",
     "editUserDetails",
     "kyc",
     "stocks",
     "banking",
     "modifyBankingData",
     "Escrow",
     "cbc",
     "scanQrCode",
     "sponsorList",
     "addCompany",
     "company",
     "password",
    ],
    Businesses: [
     "updateBusinessDetails",
     "businessDetails",
     "addStaff",
     "BlockStaff",
     "removeStaff",
    ],
    Companies: ["updateCompanyDetails", "AddCompany"],
    Nodes: ["Nodes"],
    AdminApproval: ["update"],
    System: ["Announcements", "NewsLetter", "PocketMoneyTable"],
    Stocks: [],
    BankAccounts: [],
    CBCApproval: ["approveCBCTransfer"],
    Cryptopia: [],
    Kyc: [],
   },
  },
 },

 2: {},
 3: {
  "Foundation Investor": {
   Authorized: {
    Admins: [
     "adddAdmin",
     "editAdminDetails",
     "deleteAdmin",
     "changePassword",
     "addAdmin",
    ],
    Users: [
     "user",
     "userDetails",
     "editUserDetails",
     "kyc",
     "stocks",
     "banking",
     "modifyBankingData",
     "Escrow",
     "cbc",
     "scanQrCode",
     "sponsorList",
     "addCompany",
     "company",
     "password",
    ],
    Businesses: [
     "updateBusinessDetails",
     "businessDetails",
     "addStaff",
     "BlockStaff",
     "removeStaff",
    ],
    Companies: ["updateCompanyDetails", "AddCompany"],
    Nodes: ["Nodes"],
    AdminApproval: ["update"],
    System: ["Announcements", "NewsLetter", "PocketMoneyTable"],
   },
  },
 },
 4: {
  Legal: {
   Authorized: {
    Users: [
     "user",
     "userDetails",
     "editUserDetails",
     "kyc",
     "stocks",
     "banking",
     "modifyBankingData",
     "Escrow",
     "cbc",
     "scanQrCode",
     "sponsorList",
     "addCompany",
     "company",
     "password",
    ],
    Companies: [],
    Businesses: ["businessDetails", "addStaff", "BlockStaff", "removeStaff"],
   },
  },
  Finance: {
   Authorized: {
    Users: [
     "user",
     "userDetails",
     "kyc",
     "stocks",
     "banking",
     "Escrow",
     "cbc",
     "scanQrCode",
     "sponsorList",
     "company",
     "password",
    ],
    Companies: [],
    Businesses: ["businessDetails"],
    AdminApproval: [],
    Nodes: [],
   },
  },

  "Content Creator": {
   Authorized: {
    System: ["Announcements", "NewsLetter", "PocketMoneyTable"],
   },
  },
  "Help Desk": {
   Authorized: {
    Users: [
     "user",
     "userDetails",
     "editUserDetails",
     "kyc",
     "stocks",
     "banking",
     "modifyBankingData",
     "Escrow",
     "cbc",
     "scanQrCode",
     "sponsorList",
     "addCompany",
     "company",
     "password",
    ],
    Companies: ["AddCompany"],
    Businesses: ["businessDetails", "addStaff", "BlockStaff", "removeStaff"],
    Nodes: [],
   },
  },
 },
};

export default admins;
