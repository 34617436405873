import React, { useState, useEffect, forwardRef } from "react";
import { connect } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { Grid, Button } from "@material-ui/core";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import { isEmpty } from "lodash";
import { getWithExpiry, updateObjectProperty } from "../../asset/utility";
import { API } from "../../asset/api";

import * as actionCreator from "../../store/action/index";
import CompanyDetails from "../../components/layout/companyDetails";
import admins from "../../components/auth/permissions";
import {
  componentAccess,
  sideMenuAccess,
} from "../../components/common/method";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8) + 1,
    },
    borderRadius: "15px",
    "&.MuiToolbar-root": {
      //color: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
      color: theme.palette.background.default,
    },
    "& .MuiTable-root": {
      "& .MuiTableRow-root": {
        "&:nth-child(even)": {
          background: theme.palette.background.paper,
        },
        "&:nth-child(odd)": {
          background: theme.palette.background.default,
        },
      },
    },
  },
  paper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3),
    borderRadius: 10,
    marginTop: 10,
  },
}));

const Companies = (props) => {
  const { admin, getAdmin, ResetErrors, notify } = props;
  const classes = useStyles();
  const [allCompanies, setAllCompanies] = useState(null);
  const [activeCompany, setActiveCompany] = useState(null);
  const [nameChecking, setNameChecking] = useState(false);
  const [inputCompany, setInputCompany] = useState(null);
  const [companyErrors, setCompanyErrors] = useState({
    company_name: "",
    number_of_directors: "",
    number_of_shareholders: "",
  });

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const columns = [
    {
      title: "Id",
      field: "profile_id",
      align: "center",
      searchable: true,
      editable: "never",
    },
    {
      title: "Cogito Company",
      field: "is_cogito_company",
      align: "center",
      sorting: false,
      searchable: true,
      lookup: { false: "No", true: "Yes" },
    },
    {
      title: "Company Name",
      field: "company_name",
      align: "center",
      sorting: false,
      searchable: true,
      editable: "never",
    },
  ];

  const getCompanies = () => {
    let token = getWithExpiry("token");
    API.post(
      `/getCompanies`,
      {},
      {
        headers: {
          token: token,
        },
        withCredentials: false,
      }
    )
      .then((res) => {
        if (res.data.status) {
          if (res.data.companies?.length > 0) {
            setAllCompanies(res.data.companies);
            setActiveCompany(null);
          } else {
            notify("Currently their are no companies", "error");
          }
        }
      })
      .catch((err) => {
        notify(
          err?.response?.data?.message ||
            err?.response?.data?.error?.errorMessage ||
            "Coudn't fetch companies please try again",
          "error"
        );
      });
  };

  const checkNameExists = (key, name) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      key: key,
      name: name,
    };
    API.post("/CheckNameExists", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, errorMessage, successMessage, error, exists, key } =
          res.data;
        if (status) {
          if (!exists) {
            if (key === "company") {
              setCompanyErrors(
                updateObjectProperty(companyErrors, "company_name", "")
              );
            }
          } else {
            if (key === "company") {
              setCompanyErrors(
                updateObjectProperty(
                  companyErrors,
                  "company_name",
                  "Company of this name already exists"
                )
              );
            }
          }
        } else {
          notify(errorMessage, "error");
        }
        setNameChecking(false);
      })
      .catch((err) => {
        notify(err?.response?.data?.errorMessage, "error");
        setNameChecking(false);
      });
  };

  useEffect(() => {
    if (!admin) {
      getAdmin();
    }
    if (!allCompanies && admin?.level <= 4) {
      getCompanies();
    }
    return () => {
      ResetErrors();
    };
  }, [ResetErrors, admin]);

  useEffect(() => {
    if (inputCompany) {
      for (const property in inputCompany) {
        if (inputCompany[property] == activeCompany[property]) {
          delete inputCompany[property];
        }
      }
      if (isEmpty(inputCompany)) setInputCompany(null);
    }
  }, [inputCompany]);

  useEffect(() => {
    if (inputCompany?.company_name) {
      setNameChecking(true);
      const timeoutId = setTimeout(() => {
        checkNameExists("company", inputCompany.company_name);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [inputCompany?.company_name]);
  return (
    <Grid
      container
      className={classes.root}
      spacing={3}
      style={{ maxWidth: "100%" }}
    >
      <Grid item xs={12}>
        <MaterialTable
          icons={tableIcons}
          title="Companies"
          columns={columns}
          detailPanel={[
            {
              tooltip: "Show Details",
              render: (activeCompany) => {
                return (
                  <Grid container style={{ padding: "1rem" }}>
                    <Grid item xs={12}>
                      {activeCompany ? (
                        <CompanyDetails companyData={activeCompany} />
                      ) : null}
                    </Grid>
                  </Grid>
                );
              },
            },
          ]}
          options={{
            //filtering:true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 20],
            //doubleHorizontalScroll:true,
            emptyRowsWhenPaging: false,
            padding: "dense",
            columnsButton: true,
            //search: false,
          }}
          onRowClick={(e, rowData, rowClick) => rowClick()}
          data={allCompanies ? allCompanies : []}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    admin: adminReducer.admin,
    admins: adminReducer.admins,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
    getAdmin: () => dispatch(actionCreator.getAdmin()),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
