import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import * as actionCreator from "../../../../store/action/index";
import CustomTabs from "../components/Tabs/index";
import UserStockTxn from "./userStockTxn";
import UserStockList from "./userStockList";

const Stocks = (props) => {
  const {
    user,
    getUserStocks,
    userStocks,
    userStockTxns,
    getUserStocksTransaction,
  } = props;

  console.log({ userStocks });
  const headings = ["Portfolio", "Transactions"];
  const [orderStatus, setOrderStatus] = useState(null);
  useEffect(() => {
    getUserStocks({ id: user?._id });
    getUserStocksTransaction({ id: user?._id, orderStatus: orderStatus });
  }, [user, orderStatus]);

  const contents = [
    <UserStockList userStocks={userStocks?.data} />,
    <UserStockTxn
      orderStatus={orderStatus}
      setOrderStatus={setOrderStatus}
      userStockTxns={userStockTxns}
      title={`User Stock Transactions`}
    />,
  ];
  return (
    <React.Fragment>
      <CustomTabs headings={headings} contents={contents} />
    </React.Fragment>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    admin: adminReducer.admin,
    user: adminReducer.user,
    stocks: adminReducer.stocks,
    userStocks: adminReducer.userStocks,
    userStockTxns: adminReducer.userStockTxns,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
    getUserStocks: (data) => dispatch(actionCreator.getUserStocks(data)),
    getStocks: (data) => dispatch(actionCreator.getStocks(data)),
    getUserStocksTransaction: (data) =>
      dispatch(actionCreator.getUserStocksTransaction(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stocks);
