import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { useParams } from "react-router";

//material
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
  InputAdornment,
  Container,
  Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import moment from "moment";
import VerifiedUserRounded from "@material-ui/icons/VerifiedUserRounded";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import RegisterPage from "../../../../../components/layout/RegisterPage";
import Spinner from "../../../../../components/layout/Spinner/Spinner";

//component
import UserSelect from "../../../../../components/layout/select";
import { setWithExpiry, getWithExpiry } from "../../../../../asset/utility";
import { API } from "../../../../../asset/api";

import * as actionCreator from "../../../../../store/action";
import { ContactsOutlined } from "@material-ui/icons";
const CustomInput = withStyles({
  root: {
    //background: "#eeededd7",
    borderRadius: 8,
    border: 0,
    width: "100%",
    fontFamily: "Manrope",
  },
})((props) => <TextField {...props} />);

const CustomButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#359DB6",
    "&:hover": {
      background: "#62c3db",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#8ab0b9",
  },
})((props) => <Button {...props} />);

const SuccessButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#10ca73",
    "&:hover": {
      background: "#87f891",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#758876",
  },
})((props) => <Button {...props} />);

const useStyles = makeStyles((theme) => ({
  formControl4: { width: "95%" },
  comapanyInfo: {
    borderRadius: 10,
    marginTop: 32,
    height: "auto",
    width: "100%",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
  },
  companyInfoContainer: {
    padding: "5px 5px 1px 5px",
  },
  companyForm: {
    marginBottom: 14,
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    margin: 0,
  },
  banking: {},
  bankingContainer: {},
  main_acordian: {
    boxShadow: "-4px -4px 10px rgba(58, 58, 58, 0.7)",
    borderRadius: 10,
  },
  accordian: {
    background: theme.palette.background.default,
    boxShadow: "-4px -4px 10px rgba(58, 58, 58, 0.7)",
    borderRadius: 10,
  },
  accordianContainer: {
    display: "flex",
    justifyContent: "center",
  },
  formField: {
    marginBottom: 48,
  },
  accordianDetailsRoot: {
    display: "block",
  },
  formFieldHeading: {
    color: "#B8B8B8",
    fontWeight: 600,
    display: "flex",
    justifyContent: "space-between",
    fontSize: 18,
    marginBottom: 32,
  },
  text: {
    display: "flex",
    justifyContent: "center",
    fontSize: 16,
    fontWeight: 600,
    // color: "#FFFFFF",
  },
  root: {
    color: "white",
    fontSize: 14,
    top: -3,
  },
  rootInput: {
    color: "white",
    height: "50px",
  },
  notchedOutline: {
    borderColor: "#DCDCDC",
  },
  input: {
    padding: "10px 14px",
  },
  textInput: {
    width: "100%",
  },
  main_acc: {
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    width: "100%",
    flexWrap: "wrap",
  },
  row1: {
    display: "flex",
    justifyContent: "space-between",
  },
  row2: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down(985)]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },

  formControl: {
    width: "45%",
    [theme.breakpoints.down(985)]: {
      width: "95%",
      marginTop: "1rem",
    },
  },
  formControl1: {
    width: "45%",
    [theme.breakpoints.down(985)]: {
      width: "95%",
      marginTop: "1rem",
    },
  },
  formControl2: {
    width: "45%",
    [theme.breakpoints.down(985)]: {
      width: "95%",
      marginTop: "0.5rem",
    },
  },
  formControl3: {
    width: "45%",
    [theme.breakpoints.down(985)]: {
      width: "95%",
      marginTop: "0.5rem",
    },
  },

  row3: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down(985)]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  row4: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "space-around",
  },
}));

const CustomCheckBox = withStyles({
  root: {
    color: "#FF7300",
    margin: "1rem",
  },
})((props) => <Checkbox {...props} />);

//const accordionTab =
const CogitoCompany = (props) => {
  const {
    user,
    notify,
    registerCompany,
    companies,
    getCompanyDetails,
    filledData,
    loadings,
  } = props;
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  const [panel1Image, setPanel1Image] = useState("/assets/tobefilled.svg");
  const [panel2Image, setPanel2Image] = useState("/assets/tobefilled.svg");
  const [panel3Image, setPanel3Image] = useState("/assets/tobefilled.svg");

  // trust states
  const [trustNameIsUnique, setTrustNameIsUnique] = useState(false);
  const [trustNameError, setTrustNameError] = useState("");
  const [trustName, setTrustname] = useState(null);
  //company states
  const [companyNameIsUnique, setCompanyNameIsUnique] = useState(false);
  const [companyNameError, setCompanyNameError] = useState();
  const [companyName, setCompanyName] = useState();
  const [isPrivatelyHeld, setIsPrivatelyHeld] = useState(null);
  const [isPrivatelyHeldError, setIsPrivatelyHeldError] = useState();
  const [numberOfShares, setNumberOfShares] = useState(null);
  const [numberOfSharesError, setNumberOfSharesError] = useState();

  // state to check if userinfo form is skipped or not
  const [skipped, setSkipped] = useState(false);

  //Add Beneficiaries States
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [addBeneficiary, setAddBeneficiary] = useState(false);
  const [addressFields, setAddressFields] = useState({
    country: "",
    state: "",
    city: "",
    pincode: "",
  });

  // Payment states
  const [amountToPay, setAmountToPay] = useState();
  const [taxInEuro, setTaxInEuro] = useState();
  const [pin, setPin] = useState();
  const [pinError, setPinError] = useState();
  const [id, setId] = useState(useParams().id);

  // Storing filled company data from redux state

  const [filledCompanyData, setFilledCompanyData] = useState(filledData);

  // updating data from redux state to local state

  useEffect(() => {
    if (filledData.trust_name) {
      setTrustname(filledData.trust_name);
      setPanel1Image(`/assets/filled.svg`);
    }
    if (filledData.company_name) {
      setCompanyName(filledData.company_name);
    }
    if (filledData.number_of_shares) {
      setNumberOfShares(filledData.number_of_shares);
    }
    if (filledData.isPrivatelyHeld) {
      setIsPrivatelyHeld(filledData.isPrivatelyHeld);
    }
    if (
      filledData.company_name &&
      filledData.number_of_shares &&
      filledData.isPrivatelyHeld
    ) {
      setPanel3Image(`/assets/filled.svg`);
    }
    if (filledData.beneficiaries && filledData.beneficiaries.length > 0) {
      setBeneficiaries(filledData.beneficiaries);
      setPanel2Image(`/assets/filled.svg`);
    }
  }, []);

  // Dummy

  const handleChange = (panel) => (event, isExpanded) => {
    event.stopPropagation();
    setExpanded(isExpanded ? panel : false);
  };

  const onTrustDetailsSave = () => {
    if (trustName && !trustNameError) {
      setPanel1Image(`/assets/filled.svg`);
      setExpanded("panel2");

      let companyData = filledCompanyData ? filledCompanyData : {};
      companyData.trust_name = trustName;
      setFilledCompanyData(companyData);
    } else if (trustName == null) {
      setTrustNameError("Enter a valid name");
      setTrustNameIsUnique(false);
    }
  };

  const onCompanyDetailsSave = () => {
    if (isPrivatelyHeld === null) {
      setIsPrivatelyHeldError("Please Enter a valid option");
    }
    if (!companyName) {
      setCompanyNameError("Please Enter a valid name");
    }
    if (!numberOfShares) {
      setNumberOfSharesError("Please Enter a valid number");
    }
    if (
      companyName &&
      numberOfShares &&
      !companyNameError &&
      !numberOfSharesError &&
      !isPrivatelyHeldError
    ) {
      setPanel3Image(`/assets/filled.svg`);
      setExpanded("panel4");

      let companyData = filledCompanyData;
      companyData.isPrivatelyHeld = isPrivatelyHeld;
      companyData.company_name = companyName;
      companyData.number_of_shares = numberOfShares;

      setFilledCompanyData(companyData);
    }
  };

  const onBeneficiariesSave = () => {
    if (beneficiaries !== []) {
      setPanel2Image(`/assets/filled.svg`);
      setExpanded("panel3");
    }
  };

  const onBeneficiariesBack = () => {
    setExpanded("panel1");
  };
  const onCompanyBack = () => {
    setExpanded("panel2");
  };
  const onPaymentBack = () => {
    setExpanded("panel3");
  };

  const payCogitoCompanyFee = () => {
    if (companies) {
      if (pin) {
        let storedToken = getWithExpiry("token");
        const reqBody = {
          pin: Number(pin),
        };
        API.post("/payCompanyFee", reqBody, {
          headers: {
            token: storedToken,
          },
          withCredentials: false,
        })
          // callApi("/payCompanyFee", "POST", reqBody)
          .then((res) => {
            const { status, errorMessage, successMessage, error } = res;
            if (status) {
              notify(successMessage, "success");
              getCompanyDetails();
            } else {
              notify(errorMessage, "error");
            }
          })
          .catch((err) => {
            notify(err?.response?.data?.errorMessage, "error");
          });
      } else {
        setPinError("Please provide the pin");
      }
    } else {
      notify(
        "Please fill the above details and register a company before paying the fees",
        "error"
      );
    }
  };

  const companyInfoCategory = [
    "Add Trust Detail",
    "Add Beneficieries",
    "Create a Cogito Company",
  ];

  const companyInfoCategoryFunctions = {
    "Add Trust Detail": {
      onSave: onTrustDetailsSave,
      onBack: props.onBack,
      image: panel1Image,
    },
    "Add Beneficieries": {
      onSave: onBeneficiariesSave,
      onBack: onBeneficiariesBack,
      image: panel2Image,
    },
    "Create a Cogito Company": {
      onSave: onCompanyDetailsSave,
      onBack: onCompanyBack,
      image: panel3Image,
    },
  };

  const trustDetailsForm = {
    protector: [
      {
        title: "Protector first name",
        width: 12,
        type: "text",
        defaultValue: user?.firstName,
      },
      {
        title: "Protector last name",
        width: 12,
        type: "text",
        defaultValue: user?.lastName,
      },
      {
        title: "dd/mm/yy",
        width: 12,
        type: "text",
        defaultValue: user?.dateOfBirth,
      },
    ],
    address: [
      {
        title: "Address",
        width: 12,
        type: "text",
        defaultValue: user?.address_details?.address,
      },
      {
        title: "Country",
        width: 6,
        type: "text",
        defaultValue: user?.address_details?.country,
      },
      {
        title: "State",
        width: 6,
        type: "text",
        defaultValue: user?.address_details?.state,
      },
      {
        title: "City",
        width: 6,
        type: "text",
        defaultValue: user?.address_details?.city,
      },
      {
        title: "Postcode",
        width: 6,
        type: "text",
        defaultValue: user?.address_details?.pincode,
      },
    ],
    trustName: [
      {
        title: "Trust Name",
        name: "trust_name",
        width: 12,
        type: "text",
        disabled: false,
        defaultValue: filledData.trust_name ? filledData.trust_name : "",
        errorMessage: trustNameError,
        endProps: trustNameIsUnique,
      },
    ],
    trustee: [
      {
        title: "Trustee",
        width: 12,
        type: "text",
        defaultValue: "Cogito Corporate Trustee Ltd",
      },
    ],
    settlor: [
      {
        title: "Settlor",
        width: 12,
        type: "text",
        defaultValue: "Cogito Settlements Ltd",
      },
    ],
  };

  const benficiaries = {
    benficiaries: [
      { title: "Benficiaries christian name", width: 12, type: "text" },
      { title: "Benficiaries given name", width: 12, type: "text" },
      { title: "dd/mm/yyyy", width: 12, type: "text" },
    ],
    address: [
      { title: "Address", width: 12, type: "text" },
      { title: "Country", width: 6, type: "text" },
      { title: "State", width: 6, type: "text" },
      { title: "City", width: 6, type: "text" },
      { title: "Postcode", width: 6, type: "text" },
    ],
  };

  const cogitoCompany = {
    "Company Ownership": [
      {
        title: "Company Ownership",
        name: "is_privately_held",
        width: 12,
        type: "select",
        options: [
          { name: "Is Privately held", value: true },
          { name: "Multinational Company", value: false },
        ],
        errorMessage: isPrivatelyHeldError,
      },
    ],
    "Company name": [
      {
        title: "Company Name",
        name: "company_name",
        width: 12,
        type: "text",
        disabled: false,
        errorMessage: companyNameError,
        endProps: companyNameIsUnique,
      },
    ],
    "Director name": [
      {
        title: "Director christian name",
        width: 12,
        type: "text",
        defaultValue: user?.firstName,
      },
      {
        title: "Director given name",
        width: 12,
        type: "text",
        defaultValue: user?.lastName,
      },
      {
        title: "dd/mm/yyyy",
        width: 12,
        type: "text",
        defaultValue: user?.dateOfBirth,
      },
    ],
    Address: [
      {
        title: "Address",
        width: 12,
        type: "text",
        defaultValue: user?.address_details?.address,
      },
      {
        title: "Country",
        width: 6,
        type: "text",
        defaultValue: user?.address_details?.country,
      },
      {
        title: "State",
        width: 6,
        type: "text",
        defaultValue: user?.address_details?.state,
      },
      {
        title: "City",
        width: 6,
        type: "text",
        defaultValue: user?.address_details?.city,
      },
      {
        title: "Postcode",
        width: 6,
        type: "text",
        defaultValue: user?.address_details?.pincode,
      },
    ],
    "Director 2": [
      {
        title: "Director 2",
        width: 12,
        type: "text",
        defaultValue: "Cogito Management Ltd",
      },
    ],
    Shareholders: [
      {
        title: "Shareholders",
        width: 12,
        type: "text",
        defaultValue: "Cogito Corporate Trustee Ltd",
      },
    ],
    "Number of shares": [
      {
        title: "Number of shares",
        name: "number_of_shares",
        width: 12,
        type: "text",
        disabled: false,
        errorMessage: numberOfSharesError,
      },
    ],
  };

  useEffect(() => {
    let taxPercentage;
    if (user !== null) {
      user.accounts.map((account) => {
        if (account.account_type === "Cog Transaction") {
          taxPercentage = account.fee * 0.01;
        }
      });
    }

    if (companies) {
      if (companies[0].is_privately_held) {
        setAmountToPay(2500);
        setTaxInEuro(2500 * taxPercentage);
      } else {
        setAmountToPay(50000);
        setTaxInEuro(50000 * taxPercentage);
      }
    } else {
      if (isPrivatelyHeld) {
        setAmountToPay(2500);
        setTaxInEuro(2500 * taxPercentage);
      } else {
        setAmountToPay(50000);
        setTaxInEuro(50000 * taxPercentage);
      }
    }
  }, [isPrivatelyHeld, companies]);

  const payment = {
    pay: [
      {
        title: "Amount",
        width: 12,
        type: "text",
        value: amountToPay + "C Fee for registration",
      },
      {
        title: "Tax in Euro",
        width: 12,
        type: "text",
        value: taxInEuro + "€ Tax for transaction",
      },
      {
        title: "PIN",
        width: 12,
        name: "pin",
        type: "password",
        disabled: false,
        errorMessage: pinError,
      },
    ],
  };
  const dataSet = [trustDetailsForm, benficiaries, cogitoCompany, payment];

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const checkNameExists = (key, name, id) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      id: id,
      key: key,
      name: name,
    };

    API.post("/checkNameExists", reqBody, {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      // callApi("checkNameExists", "POST", reqBody)
      .then((res) => {
        const { status, errorMessage, successMessage, error, exists, key } =
          res.data;

        if (status) {
          if (!exists) {
            if (key === "trust") {
              setTrustNameIsUnique(true);
            } else if (key === "company") {
              setCompanyNameIsUnique(true);
            }
          } else {
            if (key === "trust") {
              setTrustNameError("Name already exists");
            } else if (key === "company") {
              setCompanyNameError("Name already exists");
            }
          }
        } else {
          notify(errorMessage, "error");
        }
      })
      .catch((err) => {
        notify(err?.response?.data?.errorMessage, "error");
      });
  };

  const handleCogitoCompanyDetailsChange = (name, value) => {
    if (name === "trust_name") {
      if (value !== "") {
        setTrustname(value);
        setTrustNameError("");
      } else {
        setTrustNameError("Enter a valid name");
        setTrustNameIsUnique(false);
      }
    }
    if (name === "company_name") {
      if (value !== "") {
        setCompanyName(value);
        setCompanyNameError("");
      } else {
        setCompanyNameError("Enter a valid name");
        setCompanyNameIsUnique(false);
      }
    }
    if (name === "is_privately_held") {
      setIsPrivatelyHeld(value);
      setIsPrivatelyHeldError("");
    }
    if (name === "number_of_shares") {
      if (value < 1) {
        setNumberOfSharesError("Enter valid shares");
      } else {
        setNumberOfSharesError("");
        setNumberOfShares(value);
      }
    }

    if (name === "pin") {
      setPin(value);
    }
  };

  useEffect(() => {
    setTrustNameError("");
    if (id !== null) {
      if (trustName && trustName !== "") {
        const timeoutId = setTimeout(() => {
          checkNameExists("trust", trustName, id);
        }, 1500);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [trustName]);

  useEffect(() => {
    setCompanyNameError("");
    if (companyName && companyName !== "") {
      const timeoutId = setTimeout(() => {
        checkNameExists("company", companyName, id);
      }, 1500);
      return () => clearTimeout(timeoutId);
    }
  }, [companyName]);

  //Add Beneficiaries form
  const handleAddressInput = (data) => {
    if (data.country !== "") {
      setAddressFields(data);
    } else if (data.pincode !== "") {
      setAddressFields({ ...addressFields, pincode: data.pincode });
    }
  };

  //Add beneficiary

  const handleRegPageData = (data) => {
    let beneficiary = {
      first_name: data.firstName,
      last_name: data.lastName,
      dob: moment(data.dateOfBirth).toISOString(),
      address_details: { ...addressFields, address: data.address },
    };
    if (data.firstName && data.lastName && data.phone) {
      beneficiary[
        "id"
      ] = `${beneficiary.last_name}${beneficiary.first_name}${beneficiary.dob}`;

      let addedBeneficiaries = [...beneficiaries];
      addedBeneficiaries.push(beneficiary);
      setBeneficiaries(addedBeneficiaries);
      setAddBeneficiary(false);
    }
  };

  useEffect(() => {
    if (filledCompanyData) {
      let companyData = filledCompanyData;
      companyData.beneficiaries = beneficiaries;
      setFilledCompanyData(companyData);
    }
  }, [beneficiaries]);

  // Delete beneficiary

  const removeBeneficiary = (id) => {
    let beneficiariesArray = beneficiaries;
    let newBeneficiariesArray = beneficiariesArray.filter((beneficiary) => {
      return beneficiary.id !== id;
    });
    setBeneficiaries(newBeneficiariesArray);
  };

  useEffect(() => {
    if (beneficiaries.length === 0) {
      setPanel2Image(`/assets/tobefilled.svg`);
    }
  }, [beneficiaries]);

  // If all details are given API call should be made to register company

  useEffect(() => {
    if (
      panel1Image === `/assets/filled.svg` &&
      panel2Image === `/assets/filled.svg` &&
      panel3Image === `/assets/filled.svg`
    ) {
      let data = {
        is_cogito_company: true,
        is_privately_held: isPrivatelyHeld,
        company_name: companyName,
        country_of_registration: "Cogito",
        number_of_directors: 2,
        number_of_shareholders: 1,
        number_of_shares: Number(numberOfShares),
        director_details: [
          { first_name: "Cogito Management Ltd" },
          {
            first_name: user?.firstName,
            last_name: user?.lastName,
            dob: moment(user?.dateOfBirth).toISOString(),
            address_details: {
              address: user?.address_details.address,
              country: user?.address_details.country,
              state: user?.address_details.state,
              city: user?.address_details.city,
              pincode: Number(user?.address_details.pincode),
            },
          },
        ],
        shareholder_details: [{ first_name: "Cogito Corporate Trustee Ltd" }],
        trust_details: {
          trust_name: trustName,
          protector: {
            first_name: user?.firstName,
            last_name: user?.lastName,
            dob: moment(user?.dateOfBirth).toISOString(),
            email: user?.email,
            phone: user?.phone,
            address_details: {
              address: user?.address_details.address,
              country: user?.address_details.country,
              state: user?.address_details.state,
              city: user?.address_details.city,
              pincode: Number(user?.address_details.pincode),
            },
          },
          trustee: "Cogito Corporate Trustee Ltd",
          settlor: "Cogito Settlements Ltd",
          beneficiaries: beneficiaries,
        },
      };
      let combData = {
        data: data,
        id: id,
      };
      registerCompany(combData);
      let companyFilledInfo = filledCompanyData;
      companyFilledInfo = {};
      setFilledCompanyData(companyFilledInfo);
    }
  }, [panel1Image, panel2Image, panel3Image]);

  return (
    <Grid>
      <CustomButton
        style={{
          paddingLeft: "1.5rem",
          paddingRight: "1.5rem",
          marginRight: "1rem",
        }}
        onClick={props.onBack}
      >
        Back
      </CustomButton>
      <Box className={classes.comapanyInfo}>
        <Box className={classes.companyInfoContainer}>
          {companies &&
          companies[0].is_cogito_company &&
          !companies[0].is_fee_paid ? (
            <Container style={{ marginTop: "3%" }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h6">Company Type</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtite1">Cogito Company</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">Company Ownership</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtite1">
                    {companies[0].is_privately_held
                      ? "Privately Held"
                      : "Multinational Company"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">Company Name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtite1">
                    {companies[0].company_name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">Trust Name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtite1">
                    {companies[0].trust_details.trust_name}
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          ) : null}
          {companyInfoCategory.map((item, index) => {
            let formData = dataSet[index];
            return (
              <Box className={classes.companyForm}>
                {!companies ||
                (companies &&
                  companies[0]?.is_cogito_company &&
                  !companies[0].is_fee_paid &&
                  item === "Complete the Payment") ? (
                  <Accordion
                    className={classes.accordian}
                    expanded={expanded === "panel" + (index + 1)}
                    onChange={handleChange("panel" + (index + 1))}
                  >
                    <AccordionSummary
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      className={classes.accordianContainer}
                      classes={{ content: classes.content }}
                      sx={18}
                    >
                      <Typography className={classes.text}>
                        <img
                          style={{ marginRight: "1rem" }}
                          src={`${process.env.REACT_APP_SPACE_LINK}${companyInfoCategoryFunctions[item].image}`}
                        />
                        {item}
                      </Typography>
                      <img
                        src={
                          "https://cogito.sgp1.digitaloceanspaces.com/assets/polygon.svg"
                        }
                      />
                    </AccordionSummary>
                    <AccordionDetails
                      classes={{ root: classes.accordianDetailsRoot }}
                      id={index}
                    >
                      {item === "Add Beneficieries" ? (
                        <>
                          <Grid item container xs={12}>
                            <Grid item xs={6} style={{ textAlign: "left" }}>
                              <Typography
                                variant="h6"
                                style={{ color: "#359DB6" }}
                              >
                                Beneficiaries
                              </Typography>
                            </Grid>

                            <Grid item xs={6} style={{ textAlign: "right" }}>
                              {!addBeneficiary ? (
                                <CustomButton
                                  onClick={() => setAddBeneficiary(true)}
                                >
                                  Add Beneficiary
                                </CustomButton>
                              ) : (
                                <CustomButton
                                  onClick={() => setAddBeneficiary(false)}
                                >
                                  Back
                                </CustomButton>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{ textAlign: "left", marginBottom: "2%" }}
                            >
                              <Typography
                                variant="subtitle2"
                                style={{ color: "red" }}
                              >
                                {beneficiaries.length === 0
                                  ? "* Atleast 1 beneficiary is mandatory"
                                  : beneficiaries.length === 15
                                  ? "Maximum 15 beneficiaries"
                                  : null}
                              </Typography>
                            </Grid>
                          </Grid>
                          {beneficiaries.length !== [] ? (
                            <Grid
                              item
                              container
                              xs={12}
                              spacing={2}
                              style={{ textAlign: "left" }}
                            >
                              {beneficiaries.map((item) => {
                                return (
                                  <>
                                    <Grid item xs={3}>
                                      <Typography>
                                        {item.first_name} {item.last_name}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography>
                                        {item.address_details.address}{" "}
                                        {item.address_details.country}{" "}
                                        {item.address_details.pincode}{" "}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                      <Button
                                        id={item.id}
                                        onClick={(e) =>
                                          removeBeneficiary(e.currentTarget.id)
                                        }
                                      >
                                        <DeleteIcon
                                          style={{ color: "#359DB6" }}
                                        />
                                      </Button>
                                    </Grid>
                                  </>
                                );
                              })}
                            </Grid>
                          ) : null}
                          {addBeneficiary ? (
                            <RegisterPage
                              location={"beneficiary"}
                              spacing={2}
                              loadData={handleRegPageData}
                              getAddressInputData={handleAddressInput}
                              skipped={skipped}
                              setSkipped={setSkipped}
                            />
                          ) : null}
                        </>
                      ) : (
                        Object.keys(formData).map((item, index) => {
                          return (
                            <Box className={classes.formField}>
                              <Box className={classes.formFieldHeading}>
                                {capitalizeFirstLetter(item)}
                              </Box>
                              <Grid container spacing={2}>
                                {formData[item]?.map((formInput, index) => {
                                  return (
                                    <>
                                      <Grid
                                        item
                                        xs={formInput.width}
                                        md={formInput.width}
                                        lg={formInput.width}
                                        sm={formInput.width}
                                      >
                                        <Box sx={{ marginBottom: 1 }}>
                                          {formInput.type === "text" ||
                                          formInput.type === "password" ? (
                                            // <p>{formInput.type}</p>
                                            <TextField
                                              type={formInput.type}
                                              error={formInput.errorMessage}
                                              helperText={
                                                formInput.errorMessage
                                              }
                                              label={formInput.title}
                                              value={formInput.value}
                                              name={
                                                formInput.name
                                                  ? formInput.name
                                                  : ""
                                              }
                                              defaultValue={
                                                formInput.defaultValue
                                                  ? formInput.defaultValue
                                                  : ""
                                              }
                                              disabled={
                                                formInput.disabled === false
                                                  ? false
                                                  : true
                                              }
                                              InputLabelProps={{
                                                classes: {
                                                  root: classes.root,
                                                },
                                              }}
                                              InputProps={{
                                                classes: {
                                                  root: classes.rootInput,
                                                  //focused: classes.notchedOutline,
                                                  input: classes.input,
                                                  notchedOutline:
                                                    classes.notchedOutline,
                                                },
                                                endAdornment:
                                                  formInput.endProps ? (
                                                    <React.Fragment>
                                                      <InputAdornment position="end">
                                                        <VerifiedUserRounded
                                                          style={{
                                                            color: "#1ba12b",
                                                          }}
                                                        />
                                                      </InputAdornment>
                                                    </React.Fragment>
                                                  ) : null,
                                              }}
                                              margin="dense"
                                              className={classes.textInput}
                                              variant="outlined"
                                              onChange={(e) =>
                                                handleCogitoCompanyDetailsChange(
                                                  e.target.name,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          ) : (
                                            <UserSelect
                                              title={formInput.title}
                                              name={formInput.name}
                                              error={formInput.errorMessage}
                                              helperText={
                                                formInput.errorMessage
                                              }
                                              id={formInput.id}
                                              onChange={(e) =>
                                                handleCogitoCompanyDetailsChange(
                                                  formInput.name,
                                                  e.target.value
                                                )
                                              }
                                              defaultValue={
                                                formInput.defaultValue
                                                  ? formInput.defaultValue
                                                  : ""
                                              }
                                              options={formInput.options}
                                            />
                                          )}
                                        </Box>
                                      </Grid>
                                    </>
                                  );
                                })}
                              </Grid>
                            </Box>
                          );
                        })
                      )}
                    </AccordionDetails>
                    {item === "Add Beneficieries" && addBeneficiary ? null : (
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "center", marginBottom: "1rem" }}
                      >
                        <CustomButton
                          style={{
                            marginRight: "1rem",

                            paddingLeft: "1.5rem",
                            paddingRight: "1.5rem",
                          }}
                          onClick={companyInfoCategoryFunctions[item].onBack}
                        >
                          Back
                        </CustomButton>

                        {item === "Add Beneficieries" &&
                        beneficiaries.length === 0 ? null : (
                          <CustomButton
                            style={{
                              paddingLeft: "1.5rem",
                              paddingRight: "1.5rem",
                            }}
                            onClick={companyInfoCategoryFunctions[item].onSave}
                          >
                            Save
                          </CustomButton>
                        )}
                      </Grid>
                    )}
                  </Accordion>
                ) : null}
                {loadings.registerCompany ? <Spinner /> : null}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Grid>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    companies: adminReducer.companies,
    user: adminReducer.user,
    accounts: adminReducer.accounts,
    filledData: adminReducer.filledData,
    loadings: adminReducer.loadings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
    registerCompany: (data) => dispatch(actionCreator.registerCompany(data)),
    getCompanyDetails: (data) => dispatch(actionCreator.getCompany(data)),
    // saveCompanyData: (data) => dispatch(actionCreator.saveCompanyData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CogitoCompany);
