import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import * as actionCreator from "../../../store/action/index";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { DeleteOutline } from "@material-ui/icons";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Spinner from "../../../components/layout/Spinner/Spinner";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "80%",
    maxWidth: 350,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
  },
  subTitle: {
    fontSize: 11,
    fontWeight: 600,
  },
  dropBoxContainer: {
    width: "96%",
    backgroundColor: "#144C59",
    borderRadius: 5,
    padding: 8,
    marginTop: 16,
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
  },
  fileContainer: {
    width: "96%",
    marginTop: 8,
  },
  fileBox: {
    padding: 8,
    marginTop: 4,
    width: "100%",
    backgroundColor: "#303030",
    border: "1px solid #8F8D8D",
    borderRadius: 5,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  deleteIcon: {
    color: "#FF7300",
    display: "flex",
    alignItems: "center",
  },
  fileBoxLeft: {
    display: "flex",
    alignItems: "center",
  },
  fileImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#474646",
    borderRadius: 50,
    padding: 6,
  },
  fileData: {
    marginLeft: 5,
  },
  fileName: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    fontWeight: 400,
  },
  fileDetail: {
    fontSize: 8,
    fontWeight: 400,
  },
  anchorTag: {
    textDecoration: "none",
    color: "#fff",
  },
  approve: {
    fontSize: 14,
    fontStyle: "normal",
    color: "#359DB6",
    border: "1px solid #359DB6",
    borderRadius: 5,
    padding: 4,
    textTransform: "capitalize",
    fontWeight: 600,
  },
  reject: {
    fontSize: 14,
    fontStyle: "normal",
    color: "#FF7300",
    border: "1px solid #FF7300",
    borderRadius: 5,
    padding: 4,
    textTransform: "capitalize",
    fontWeight: 600,
  },
  approvalContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginTop: 12,
  },
}));

const ApprovalDocCheck = (props) => {
  const { deleteApprovalDocs, approvalDetail, getApproval, approvalAction } =
    props;
  const classes = useStyles();
  const history = useHistory();
  const [notes, setNotes] = useState("");
  const uploadedDocs = approvalDetail?.approval_documents || [];
  const handleFileDelete = (files) => {
    deleteApprovalDocs({ fileUrls: files });
  };
  const Files = ({ file }) => {
    return (
      <Box className={classes.fileBox}>
        <Box className={classes.fileBoxLeft}>
          <Box className={classes.fileImg}>
            <InsertDriveFileIcon />
          </Box>
          <Box className={classes.fileData}>
            <a
              href={`${process.env.REACT_APP_SPACE_LINK}/${file}`}
              target="_blank"
              className={classes.anchorTag}
            >
              <Typography className={classes.fileName}>{file}</Typography>
            </a>
            {/* <Typography className={classes.fileDetail}>
              {"2 Pages"} <FiberManualRecordIcon style={{ fontSize: 3 }} />{" "}
              {"2.3mb"}
            </Typography> */}
          </Box>
        </Box>
        {/* <Box className={classes.fileBoxLeft}>
          <Box>
            <Typography className={classes.fileName}>{file.date}</Typography>
          </Box>
        </Box> */}
        <Box className={classes.deleteIcon}>
          {/* <Button
            className={classes.deleteIcon}
            onClick={() => handleFileDelete([file])}
          >
            <DeleteOutline />
          </Button> */}
        </Box>
      </Box>
    );
  };

  const approveHandler = () => {
    approvalAction({
      isApproved: true,
      isRejected: false,
      notes: notes,
    });
  };
  const rejectHandler = () => {
    approvalAction({
      isApproved: false,
      isRejected: true,
      notes: notes,
    }).then(() => {
      history.push("/approval");
    });
  };
  const cancelHandler = () => {
    approvalAction({
      assignToAction: "contact_user",
      notes: notes,
    });
  };
  useEffect(() => {
    setNotes(approvalDetail.notes);
  }, [approvalDetail.notes]);
  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>Documents Check</Typography>
      {getApproval ? (
        <>
          <Spinner />
        </>
      ) : (
        <>
          <Box className={classes.fileContainer}>
            {uploadedDocs.map((file) => (
              <Files file={file} key={file} />
            ))}
          </Box>
          <Box className={classes.approvalContainer}>
            <TextField
              label="Notes* (Minimum 10 characters)"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              variant="outlined"
              size="small"
              style={{ width: "96%" }}
              placeholder="Notes* (Minimum 10 characters)"
            />
          </Box>
          <Box className={classes.approvalContainer}>
            <Button
              disabled={notes.length < 10}
              className={classes.reject}
              onClick={rejectHandler}
            >
              Reject
            </Button>
            <Button
              disabled={notes.length < 10}
              className={classes.approve}
              onClick={cancelHandler}
            >
              Cancel
            </Button>
            <Button
              disabled={notes.length < 10}
              className={classes.approve}
              onClick={approveHandler}
            >
              Approve
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    approvalDetail: adminReducer.approvalDetail,
    getApproval: adminReducer.loadings.getApproval,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
    deleteApprovalDocs: (data) =>
      dispatch(actionCreator.deleteApprovalDocs(data)),
    approvalAction: (data) => dispatch(actionCreator.approvalAction(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalDocCheck);
