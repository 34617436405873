import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
  withStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreator from "../../../../store/action/index";
import React, { forwardRef, useEffect } from "react";
import { useState } from "react";
import { updateObjectProperty } from "../../../../asset/utility";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/Close";

const CustomButton = withStyles({
  root: {
    borderRadius: 8,
    justifySelf: "right",
    border: 0,
    color: "white",
    fontStyle: "normal",
    background: "#359DB6",
    "&:hover": {
      background: "#62c3db",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#8ab0b9",
  },
})((props) => <Button {...props} />);

const SuccessButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#10ca73",
    "&:hover": {
      background: "#87f891",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#758876",
  },
})((props) => <Button {...props} />);

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    textAlign: "right",
  },
  addIconStyle: {
    marginLeft: "0.3rem",
    fontSize: "1.3rem",
  },
}));
const QuestionModal = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [task, setTask] = useState();

  const onTaskValueChanged = (e) => {
    if (e?.persist) e.persist();
    let name = e.target.name;
    let value = e.target.value;
    if (name === "reward" && value !== "") {
      value = Number(value);
    }
    setTask(updateObjectProperty(task, name, value));
  };

  const onTaskOptionChanged = (e, index) => {
    e.persist();
    let value = e?.target?.value;
    setTask((data) => {
      let optionList =
        data?.option?.map((option, i) => (index != i ? option : value)) || [];
      return { ...data, option: optionList };
    });
  };

  const addOption = () => {
    setTask((data) => {
      let optionList = data.option || [];
      optionList.push("");
      return { ...data, option: optionList };
    });
  };

  const deleteOption = (id) => {
    setTask((data) => {
      let optionList = data?.option?.filter((option, i) => i != id) || [];
      return { ...data, option: optionList };
    });
  };

  const createTask = () => {
    props.createTask({
      ...task,
      module: parseInt(task.module),
      submodule: parseInt(task.submodule),
    });
    props.onClose();
  };

  const editTask = () => {
    props.editTask({
      ...task,
      module: parseInt(task.module),
      submodule: parseInt(task.submodule),
    });
    props.onClose();
  };

  const onDelete = () => {
    props.deleteTask();
    props.onClose();
  };
  useEffect(() => {
    setTask({
      question: props.rowData ? props.rowData.question : "",
      module: props.rowData ? props.rowData.module : 0,
      submodule: props.rowData ? props.rowData.submodule : 0,
      option: props.rowData ? props.rowData.option : [],
      answer: props.rowData ? props.rowData.answer : 0,
    });
  }, [props.rowData]);

  useEffect(() => {
    if (task?.option?.length < task?.answer) {
      onTaskValueChanged({ target: { name: "answer", value: 0 } });
    }
  }, [task]);

  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={props?.open}
        onClose={props.onClose}
      >
        {props.title !== "Delete Question" ? (
          <Grid>
            <DialogTitle>{props.title}</DialogTitle>
          </Grid>
        ) : null}
        {props.open == true ? (
          <>
            {" "}
            <DialogContent>
              {props.title == "Delete Question" ? (
                <>
                  <DialogTitle>{`Do you want to delete "${props.rowData.id}"?`}</DialogTitle>
                  <Grid style={{ textAlign: "center", padding: "8px 15px" }}>
                    <CustomButton
                      //   className={classes.confirmButton}
                      onClick={onDelete}
                    >
                      Confirm
                    </CustomButton>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        type="text"
                        value={task.question}
                        name="question"
                        label="Question"
                        onChange={onTaskValueChanged}
                        fullWidth
                      />
                    </Grid>
                    <Grid item container xs={12}>
                      <Grid item xs={12}>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <Typography>Add Options </Typography>
                          <ButtonBase onClick={addOption}>
                            <AddCircleOutlineIcon
                              className={classes.addIconStyle}
                            />
                          </ButtonBase>
                          <Typography>(Minimum Two*)</Typography>
                        </Box>
                      </Grid>
                      {(task?.option || []).map((option, index) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <TextField
                              value={option}
                              type="text"
                              name={`${index}`}
                              label={`Option-${index + 1}`}
                              onChange={(e) => onTaskOptionChanged(e, index)}
                              fullWidth
                            />
                            <ButtonBase onClick={() => deleteOption(index)}>
                              <CloseIcon />
                            </ButtonBase>
                          </Grid>
                        );
                      })}
                    </Grid>
                    {task.option?.length ? (
                      <Grid item xs={12}>
                        <Typography>Select Answer</Typography>
                        <Select
                          name="answer"
                          value={task.answer}
                          required
                          onChange={onTaskValueChanged}
                          fullWidth
                        >
                          {task.option.map((data, index) => {
                            return (
                              <MenuItem value={index + 1}>{`${
                                index + 1
                              }. ${data}`}</MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                    ) : null}
                    <Grid item xs={12}>
                      <TextField
                        value={task.module}
                        type="number"
                        name="module"
                        label="Module"
                        onChange={onTaskValueChanged}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={task.submodule}
                        type="number"
                        name="submodule"
                        label="Submodule"
                        onChange={onTaskValueChanged}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: "center", margin: "3% 0 0 0" }}
                    >
                      {!props.rowData ? (
                        <SuccessButton
                          variant="contained"
                          type="submit"
                          onClick={
                            props.title == "Add Question"
                              ? createTask
                              : editTask
                          }
                          disabled={
                            !task?.option?.length ||
                            task?.option?.length < 2 ||
                            task?.option?.filter((option) => !option)?.length ||
                            !task.answer ||
                            parseInt(task.module || 0) <= 0 ||
                            parseInt(task.submodule || 0) <= 0 ||
                            !task.question
                          }
                        >
                          {"Submit "}
                        </SuccessButton>
                      ) : (
                        <SuccessButton
                          variant="contained"
                          type="submit"
                          onClick={
                            props.title == "Add Question"
                              ? createTask
                              : editTask
                          }
                          disabled={
                            !task?.option?.length ||
                            task?.option?.length < 2 ||
                            task?.option?.filter((option) => !option)?.length ||
                            !task.answer ||
                            parseInt(task.module || 0) <= 0 ||
                            parseInt(task.submodule || 0) <= 0 ||
                            !task.question ||
                            (task.answer == props.rowData?.answer &&
                              task.module == props.rowData?.module &&
                              task.submodule == props.rowData?.submodule &&
                              task.question == props.rowData?.question &&
                              task.option?.length ==
                                props.rowData?.option?.length &&
                              task.option?.filter(
                                (val, index) =>
                                  val == props.rowData?.option?.[index]
                              )?.length == task.option?.length)
                          }
                        >
                          {"Update "}
                        </SuccessButton>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </DialogContent>
          </>
        ) : null}
      </Dialog>
    </>
  );
};

export default QuestionModal;
