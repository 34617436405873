import * as actionTypes from "../action/actionTypes";
import { updateObject, arrayUnique } from "../../asset/utility";

const initialState = {
  admin: null,
  admins: [],
  businesses: [],
  users: [],
  user: null,
  userAccount: null,
  companies: null,
  kycData: undefined,
  kycDocsData: null,
  kycImageData: null,
  kycImageData1: null,
  assectsType: null,
  announcements: [],
  announcement: null,
  approvals: [],
  approvalDetail: null,
  filledData: {},
  newsLetters: [],
  assets: [],
  selectedAsset: {},
  instruments: [],
  selectedInstrument: {},
  pMTasks: [],
  userEscrowAccount: [],
  //  exchangeDirectory: [],
  //  selectedExchangeDirectory: {},
  ulmQuestionList: [],
  odType: {},
  cogitoStats: {},
  recommendations: [],
  stocks: [],
  bankAccount: [],
  userStocks: [],
  userStockTxns: [],
  cbcTransfers: [],
  stockTransactions: [],
  cogitoStats: {},
  cryptopiaStats: {},
  crUsersData: {},
  cryptopiaAgreementCount: 0,
  currentPage: 0,
  businessActivities: [],
  business: [],
  pmActivity: [],
  stockCategories: [],
  stockTemplates: [],
  stocktemplate: [],
  stockCategories: [],
  prospectusDrafts: [],
  stockOrderBook: [],
  stockDocs: [],

  loadings: {
    getAdmin: false,
    getAdmins: false,
    getBusinesses: false,
    updateAdmin: false,
    resetAdminEmailLink: false,
    updateAdminPassword: false,
    resetAdminLink: false,
    resetAdminPassword: false,
    sendAdminRequest: false,
    sendAdminDeleteRequest: false,
    getUsers: false,
    getUser: false,
    sponsorList: null,
    announcements: null,
    getCompanies: false,
    updateCompany: false,
    getApproval: false,
    newsLetter: false,
    stocks: false,
    userAccount: false,
    getTasks: false,
    getEscrowAccount: false,
    getRecommendation: false,
    uploadDocs: false,
    // updateDirectory: false,
    updateStocks: false,
    getAccount: false,
    userStocks: false,
    stockTransactions: false,
    cryptopiaData: false,
    businessActivities: false,
    crUsersData: false,
    getBusiness: false,
    getPmActivity: false,
    createStock: false,
    createStockTemplate: false,
    getStockOrderBook: false,
    uploadStockDoc: false,
  },
};
//# HERE IS THE REDUCER OR CASE'S
const adminReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_ADMIN_LOADING:
      return setAdminLoading(state, payload);
    case actionTypes.SET_COMPANY_LOADING:
      return setCompanyLoading(state, payload);
    case actionTypes.SET_BUSINESSES_LOADING:
      return setBusinessesLoading(state, payload);
    case actionTypes.SET_ADMIN:
      return setAdmin(state, payload);
    case actionTypes.SET_ADMINS:
      return setAdmins(state, payload);
    case actionTypes.SET_COMPANY:
      return setCompany(state, payload);
    case actionTypes.SET_BUSINESSES:
      return setBusinesses(state, payload);
    case actionTypes.SET_KYC_DATA:
      return setKycData(state, payload);
    case actionTypes.SET_KYC_DOCS_DATA:
      return setKycDocsData(state, payload);
    case actionTypes.SET_KYC_IMAGE_DATA:
      return setKycImageData(state, payload);
    case actionTypes.SET_KYC_IMAGE_DATAB:
      return setKycImageDataB(state, payload);
    case actionTypes.SET_KYC_IMAGE_DATAS:
      return setKycImageDataS(state, payload);
    case actionTypes.SET_ASSECTS_TYPE_DATA:
      return setAssectsType(state, payload);
    case actionTypes.SET_FLAG:
      return setFlag(state, payload);
    case actionTypes.SET_ANNOUNCEMENTS:
      return setAnnouncements(state, payload);
    case actionTypes.SET_ANNOUNCEMENT:
      return setAnnouncement(state, payload);
    case actionTypes.SET_USERS:
      return setUsers(state, payload);
    case actionTypes.SET_USER:
      return setUser(state, payload);
    case actionTypes.SET_USER_ACCOUNT:
      return setUserAccount(state, payload);
    case actionTypes.SET_SPONSOR_LIST:
      return setSponsorList(state, payload);
    case actionTypes.LOGOUT:
      return logout(state, payload);
    case actionTypes.SET_APPROVAL_DETAIL:
      return setApprovalDetail(state, payload);
    case actionTypes.SET_APPROVAL_LOADING:
      return setApprovalLoading(state, payload);
    case actionTypes.SET_APPROVALS:
      return setApprovals(state, payload);
    case actionTypes.SET_NEWSLETTERS:
      return setNewsLetters(state, payload);
    case actionTypes.SET_ASSET:
      return setAssect(state, payload);
    case actionTypes.SET_INSTRUMENT:
      return setInstrument(state, payload);
    case actionTypes.SET_ASSETS:
      return setAssects(state, payload);
    case actionTypes.SET_ADMIN_REDUCER_VALUE:
      return setAdminReducerValue(state, payload);
    case actionTypes.SET_INSTRUMENTS:
      return setInstruments(state, payload);
    case actionTypes.SET_ADMIN_REDUCER_VALUE:
      return setAdminReducerValue(state, payload);
    // case actionTypes.SET_EXCHANGE_DIRECTORY:
    //  return setExchangeDirectory(state, payload);
    case actionTypes.SET_TASKS:
      return setTask(state, payload);
    case actionTypes.SET_ULM_QUESTION:
      return setULMQuestion(state, payload);
    case actionTypes.SET_OD_TYPE:
      return setOdType(state, payload);
    case actionTypes.SET_COGITO_STATS:
      return setCogitoStats(state, payload);
    case actionTypes.ADD_RECOMMENDS:
      return addRecommends(state, payload);
    case actionTypes.SET_STOCKS:
      return setStocks(state, payload);
    case actionTypes.SET_BANK_ACCOUNT:
      return setBankAccount(state, payload);
    case actionTypes.SET_USER_STOCKS:
      return setUserStocks(state, payload);
    case actionTypes.SET_CBC_TRANSFERS:
      return setCbcTransfers(state, payload);
    case actionTypes.SET_USER_STOCK_TRANSACTIONS:
      return setUserStockTxns(state, payload);
    case actionTypes.SET_CRYPTOPIA_FORM:
      return setCryptopiaAgreementForm(state, payload);
    case actionTypes.SET_CRYPTOPIA_FORM_TOTAL_COUNT:
      return setCryptopiaAgreementFormTotalCount(state, payload);
    case actionTypes.SET_COGITO_STATS:
      return setCogitoStats(state, payload);
    case actionTypes.SET_CRYPTOPIA_STATS:
      return setCryptopiaStats(state, payload);
    case actionTypes.SET_BANK_ACCOUNT:
      return setBankAccount(state, payload);
    case actionTypes.SET_CBC_TRANSFERS:
      return setCbcTransfers(state, payload);
    case actionTypes.SET_CR_USERS:
      return setCRUsers(state, payload);
    case actionTypes.SET_CURRENT_PAGE:
      return setCurrentPage(state, payload);
    case actionTypes.SET_BUSINESS_ACTIVITY:
      return setBusinessActivities(state, payload);

    case actionTypes.SET_CR_USERS:
      return setCRUsers(state, payload);
    case actionTypes.SET_BUSINESS:
      return setBusiness(state, payload);
    case actionTypes.SET_PM_ACTIVITY:
      return setPmActivity(state, payload);
    case actionTypes.SET_STOCKS_CATEGORY:
      return setStocksCategory(state, payload);
    case actionTypes.SET_STOCK_TEMPLATE:
      return setStockTemplate(state, payload);
    case actionTypes.SET_PROSPECTUS:
      return setProspectusDrafts(state, payload);
    case actionTypes.SET_STOCK_ORDER_BOOK:
      return setStockOrderBook(state, payload);
    case actionTypes.SET_STOCK_DOC:
      return setStockDoc(state, payload);
    default:
      return state;
  }
};

/*--- CASES ARE HERE DEFINED */
// UTILITY FUNCTION CALCULATE UTILIZATION
const setApprovalDetail = (state, data) => {
  const { approvalDetail } = data;
  return updateObject(state, {
    approvalDetail: approvalDetail,
  });
};

const setApprovalLoading = (state, data) => {
  return updateObject(state, {
    loadings: updateObject(state.loadings, data),
  });
};

const setAdminLoading = (state, data) => {
  return updateObject(state, {
    loadings: updateObject(state.loadings, data),
  });
};

const setCompanyLoading = (state, data) => {
  return updateObject(state, {
    loadings: updateObject(state.loadings, data),
  });
};

const setBusinessesLoading = (state, data) => {
  return updateObject(state, {
    loadings: updateObject(state.loadings, data),
  });
};

const setAdmin = (state, data) => {
  const { user } = data;
  return updateObject(state, {
    admin: user,
  });
};

const setAdmins = (state, data) => {
  const { admins } = data;
  return updateObject(state, {
    admins: admins,
  });
};

const setCompany = (state, data) => {
  const { companies } = data;
  return updateObject(state, {
    companies: companies,
  });
};

const setBusinesses = (state, data) => {
  const { businesses } = data;
  return updateObject(state, {
    businesses: businesses,
  });
};

const setKycData = (state, data) => {
  const { kycData } = data;
  return updateObject(state, {
    kycData: kycData,
  });
};

const setKycDocsData = (state, data) => {
  const { kycDocsData } = data;
  return updateObject(state, {
    kycDocsData: kycDocsData,
  });
};

const setKycImageData = (state, data) => {
  const { kycImageData } = data;
  return updateObject(state, {
    kycImageData: kycImageData,
  });
};

const setKycImageDataB = (state, data) => {
  const { kycImageDataB } = data;
  return updateObject(state, {
    kycImageDataB: kycImageDataB,
  });
};

const setKycImageDataS = (state, data) => {
  const { kycImageDataS } = data;
  return updateObject(state, {
    kycImageDataS: kycImageDataS,
  });
};

const setAssectsType = (state, data) => {
  const { assestsTypeData } = data;
  return updateObject(state, {
    assestsTypeData: assestsTypeData,
  });
};

const setFlag = (state, data) => {
  const { flag } = data;
  return updateObject(state, {
    flag: flag,
  });
};

const setUser = (state, data) => {
  const { user } = data;
  return updateObject(state, {
    user: user,
  });
};

const setUserAccount = (state, data) => {
  return updateObject(state, {
    userAccount: data,
  });
};

const setUsers = (state, data) => {
  const { users } = data;
  return updateObject(state, {
    users: users,
  });
};

const setAnnouncements = (state, data) => {
  const { announcements } = data;
  return updateObject(state, {
    announcements: announcements,
  });
};

const setAnnouncement = (state, data) => {
  const { announcement } = data;
  return updateObject(state, {
    announcement: announcement,
  });
};

const setSponsorList = (state, data) => {
  let { sponsorList } = data;
  return updateObject(state, {
    sponsorList: sponsorList,
  });
};

const logout = (state, data) => {
  return updateObject(state, initialState);
};

const setApprovals = (state, data) => {
  let { approvals } = data;

  return updateObject(state, {
    approvals: approvals,
  });
};

const setNewsLetters = (state, data) => {
  return updateObject(state, { newsLetters: data || [] });
};

const setAssect = (state, data) => {
  return updateObject(state, { selectedAsset: data || {} });
};

const setInstrument = (state, data) => {
  return updateObject(state, { selectedInstrument: data || {} });
};

const setAssects = (state, data) => {
  return updateObject(state, { assets: data || [] });
};

const setInstruments = (state, data) => {
  return updateObject(state, { instruments: data || [] });
};

const setAdminReducerValue = (state, data) => {
  return updateObject(state, data);
};

const setExchangeDirectory = (state, data) => {
  return updateObject(state, { exchangeDirectory: data || [] });
};

// const setExchangeDirectory = (state, data) => {
//  return updateObject(state, { exchangeDirectory: data || [] });
// };

const setTask = (state, data) => {
  return updateObject(state, { pMTasks: data || [] });
};

const setULMQuestion = (state, data) => {
  return updateObject(state, { ulmQuestionList: data || [] });
};

const setOdType = (state, data) => {
  return updateObject(state, { odType: data || {} });
};

const setBankAccount = (state, data) => {
  return updateObject(state, { bankAccount: data || [] });
};

const addRecommends = (state, data) => {
  let { user } = data;
  return updateObject(state, {
    // recommendations: arrayUnique(state.recommendations.concat(user)),
    recommendations: user,
  });
};
const setStocks = (state, data) => {
  return updateObject(state, { stocks: data || [] });
};

const setUserStockTransactions = (state, data) => {
  return updateObject(state, { stockTransactions: data || [] });
};
const setStocksCategory = (state, data) => {
  return updateObject(state, { stockCategories: data || [] });
};
const setStockTemplate = (state, data) => {
  return updateObject(state, { stockTemplates: data || [] });
};

const setProspectusDrafts = (state, data) => {
  return updateObject(state, { prospectusDrafts: data || [] });
};

const setCbcTransfers = (state, data) => {
  return updateObject(state, { cbcTransfers: data || [] });
};
const setUserStocks = (state, data) => {
  return updateObject(state, { userStocks: data || [] });
};
const setUserStockTxns = (state, data) => {
  return updateObject(state, { userStockTxns: data || [] });
};

const setCogitoStats = (state, data) => {
  return updateObject(state, { cogitoStats: data || {} });
};

const setCryptopiaStats = (state, data) => {
  return updateObject(state, { cryptopiaStats: data || {} });
};

const setCryptopiaAgreementForm = (state, data) => {
  return updateObject(state, { cryptopiaData: data || [] });
};

const setCryptopiaAgreementFormTotalCount = (state, data) => {
  return updateObject(state, { cryptopiaAgreementCount: data || [] });
};

const setCurrentPage = (state, data) => {
  return updateObject(state, { currentPage: data });
};

const setCRUsers = (state, data) => {
  return updateObject(state, { crUsersData: data || [] });
};
const setBusiness = (state, data) => {
  return updateObject(state, { business: data?.business || {} });
};

const setBusinessActivities = (state, data) => {
  return updateObject(state, { businessActivities: data || {} });
};

const setPmActivity = (state, payload) => {
  let data = payload;
  return updateObject(state, {
    pMActivity: data || null,
  });
};

const setStockOrderBook = (state, data) => {
  return updateObject(state, { stockOrderBook: data || [] });
};

const setStockDoc = (state, data) => {
  return updateObject(state, { stockDocs: data || [] });
};

export default adminReducer;
