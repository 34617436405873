import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { forwardRef, useRef } from "react";
import MuiPhoneNumber from "material-ui-phone-number";
import GoogleMaps from "../../components/layout/googleMap";
import AddressInput from "../../components/layout/addressInput";
import Geocode from "react-geocode";
import {
 makeStyles,
 withStyles,
 useTheme,
 styled,
} from "@material-ui/core/styles";
import {
 Link,
 Box,
 Button,
 Grid,
 Paper,
 Typography,
 Divider,
 TextField,
} from "@material-ui/core";
import { API } from "../../asset/api";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import { getWithExpiry, updateObject } from "../../asset/utility";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import clsx from "clsx";
import plus_icon from "../../asset/images/plus.png";
import no_pics from "../../asset/images/no_pics.png";
import delete_icon from "../../asset/images/delete.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { UAParser } from "ua-parser-js";
import { validateField } from "../../asset/businessUtility";
import * as actionCreator from "../../store/action/index";
import Businesses from "../dashboard/businesses";
import Styles from "../../components/styles";
import StaffList from "./staffList";
import StaticMap from "../dashboard/StaticMap";
import no_address from "../../asset/images/modal/no_address_listed.svg";
import { googleMapUrl } from "../../utils/googleMaps/googleMapUrl";
import {
 componentAccess,
 sideMenuAccess,
} from "../../components/common/method";
import SideMenu from "./businessPage/SideMenu";
import BusinessData from "./businessPage/businessData";

const Business = (props) => {
 const result = new UAParser().getResult();
 const classes = Styles();
 const theme = useTheme();
 const { admin, getBusinesses, business, getBusiness } = props;
 const [id, setId] = useState(useParams().id || null);

 const [profileId, setProfileId] = useState(useParams().profileId || null);
 const [business_details, setBusiness_details] = useState({
  name: "",
  email: "",
  website: "",
  phone: "",
  description: "",
  is_hidden: false,
  industry_category: [],
  link_with_company: false,
 });
 const [filters, setFilters] = useState({});

 const [address_details, setAddress_details] = useState({
  address: "",
  city: "",
  country: "",
  state: "",
  pincode: "",
 });
 const [staff_details, setStaff_details] = useState({
  email: "",
  role: "",
 });
 const [currLogo, setCurrLogo] = useState({
  logo: null,
 });

 const [view_staff, setView_Staff] = useState(null);

 const [activeSection, setActiveSection] = useState("Business Details");
 //  console.log(id);
 // ,
 const getStaffList = () => {
  let storedToken = getWithExpiry("token");
  const reqBody = {
   profileId: profileId,
   business_id: id,
  };
  API.post("/GetStaffList", JSON.stringify(reqBody), {
   headers: {
    token: storedToken,
   },
   withCredentials: false,
  })
   .then((res) => {
    const { status, staffs, errorMessage, successMessage, error } = res.data;
    if (status) {
     if (staffs?.length > 0) {
      setView_Staff(staffs);
     } else {
      setView_Staff(null);
     }
    }
   })
   .catch((err) => {
    console.log("axios error: " + err);
   });
 };

 useEffect(() => {
  if (staff_details.email == "" && staff_details.role == "") {
   getStaffList();

   return () => {};
  }
 }, [activeSection == "Business Staff"]);

 const handleSelection = (name) => {
  setActiveSection(name);
 };
 useEffect(() => {
  getBusiness({ business_id: id });
 }, []);
 return (
  <React.Fragment>
   {componentAccess(
    admin?.level,
    admin?.type,
    "Businesses",
    "businessDetails"
   ) ? (
    <Grid
     container
     className={clsx(classes.root, classes.rowView)}
     // spacing={2}
    >
     <Grid
      item
      direction="column"
      // xs={2.5}
      className={classes.sidePlane}
     >
      <SideMenu
       activeSection={activeSection}
       handleSelection={handleSelection}
      />
     </Grid>
     <Grid item xs={9}>
      <BusinessData
       activeSection={activeSection}
       //  getBusiness={getBusinesses}
       //  id={id}
      />
     </Grid>
    </Grid>
   ) : null}
  </React.Fragment>
 );
};
const mapStateToProps = ({ adminReducer }) => {
 return {
  loadings: adminReducer.loadings,
  admin: adminReducer.admin,
  sponsorList: adminReducer.sponsorList,
  businesses: adminReducer.businesses,
  business: adminReducer.business,
 };
};
const mapDispatchToProps = (dispatch) => {
 return {
  ResetErrors: () => dispatch(actionCreator.ResetErrors()),
  getAdmin: () => dispatch(actionCreator.getAdmin()),
  getSponsorList: (data) => dispatch(actionCreator.getSponsorList(data)),
  updateUser: (data) => dispatch(actionCreator.updateUser(data)),
  updatePassword: (data) => dispatch(actionCreator.updatePassword(data)),
  updateCustomFee: (data) => dispatch(actionCreator.updateCustomFee(data)),
  updateOverdraftValue: (data) =>
   dispatch(actionCreator.updateOverdraftValue(data)),
  notify: (message, varient) =>
   dispatch(actionCreator.notify(message, varient)),
  getBusinesses: () => dispatch(actionCreator.getBusinesses()),
  getBusiness: (data) => dispatch(actionCreator.getBusiness(data)),
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(Business);
