import React, { forwardRef, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as actionCreator from "../../../store/action/index";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    padding: "1rem 0.5rem",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "600",
    color: "#fff",
    margin:"0.5rem 0"
  },
  childrenContainer:{
    padding: "1rem 0.5rem",
  }
}));

function StatsFrame(props) {
  const { children, title } = props;

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {title ? (
        <Typography className={classes.title}>{title}</Typography>
      ) : null}
      <Box className={classes.childrenContainer}>{children}</Box>
    </Box>
  );
}

const mapStateToProps = ({ adminReducer }) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatsFrame);
